// import * as Const from '../Const'
// import { trace } from '../common/index'
// import clsx from 'clsx'
import React from 'react'

import { useAppDispatch } from '../store'
import { fetchFavoriteUsersByStaffId } from '../modules/StaffModule'
import { useFacilityUsers, fetchFacilityUsersWithLessons } from '../modules/FacilityModule'

// Material-UI
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Box, Grid } from '@material-ui/core'

// functional
import * as DateUtil from 'date-fns'
import { isUndefined } from 'util'

// User
import { User, FavoriteUser, Staff, StatusUser, Lesson, LessonStatus } from '../Types'
import TitleBar from '../components/TitleBar'
import StaffUserListSearchBar from '../components/StaffUserListSearchBar'
import StaffUserListCell from '../components/StaffUserListCell'
import AvatarLabel from './AvatarLabel'
import { useProfile } from 'modules/AppModule'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { getImageCacheContext } from 'modules/ImageCacheProvider'

// スタイルを適用する
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            padding: '10px 0',
            width: 960,
            margin: '30px auto',
        },
        paper: {
            padding: theme.spacing(0),
            textAlign: 'left',
            height: '270px',
            width: '100%',
        },
        button: {
            height: '100%',
            width: '100%',
        },
        search: {
            height: '20%',
            padding: '20px',
        },
        searchbar: {
            padding: '10px',
        },
        guide: {
            alignItems: 'center',
            justifyContent: 'flex-start',
        },
        guide_item: {
            marginRight: 30,
        },
        textfield: {
            width: '100%',
        },
        left: {
            width: '250px',
            height: '270px',
            flexShrink: 0,
            // backgroundColor: '#0000ff',
            marginRight: '10px',
        },
        right: {
            height: '270px',
            // width: "1000px",
            // backgroundColor: '#ff0000',
            width: '100%',
        },
        content: {
            padding: '10px 0',
            width: 960,
            margin: '0 auto',
        },

        // list: {
        //     maxHeight: 500,
        //     overflow: 'auto',
        // }

        today_button: {
            marginLeft: 10,
            color: '#ffffff',
            borderColor: '#ffffff',
            height: 20,
            width: 30,
        },
        bgColor1: {
            background: '#CDEEF3',
            margin: 4,
        },
        bgColor2: {
            background: '#E2E2E2',
            margin: 4,
        },
    }),
)
type StaffUserListProps = {
    facility_id: string
}
export default function StaffUserList(props: StaffUserListProps) {
    console.log('##### StaffUserList')

    const imageCache = React.useContext(getImageCacheContext())
    const dispatch = useAppDispatch()
    const classes = useStyles()

    const history = useHistory()
    const match = useRouteMatch()
    const listPageUrl = match.url

    const [date] = React.useState(new Date())
    const [filter, setFilter] = React.useState({
        searchText: '',
        favoriteUser: FavoriteUser.all,
        status: StatusUser.all,
    })

    const profile = useProfile() as Staff
    const staff_id = isUndefined(profile) || isUndefined(profile.staff_id) ? '' : profile.staff_id
    const facility_id = props.facility_id

    const isUserDuringTraining = (user: User) => {
        if (user && user.lessons && user.lessons.length) {
            const lessonDuringTraining = user.lessons.find((lessonItem: Lesson) => {
                return lessonItem.status === LessonStatus.progressing
            })
            return lessonDuringTraining ? true : false
        }
        return false
    }

    //
    React.useEffect(() => {
        dispatch(
            fetchFacilityUsersWithLessons({
                facility_id: facility_id,
                user_id: '-',
                start: DateUtil.format(date, 'yyyyMMdd'),
                end: DateUtil.format(date, 'yyyyMMdd'),
            }),
        ).then((res) => {
            imageCache.addUsers(res.payload)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, date, facility_id])

    // Favorite Users.
    const [favoriteUsers, setFavoriteUsers] = React.useState<string[]>([])
    React.useEffect(() => {
        if (staff_id === undefined) return
        dispatch(fetchFavoriteUsersByStaffId(staff_id)).then((res) => {
            setFavoriteUsers(res.payload)
        })
    }, [dispatch, staff_id])

    let facilityUsers = useFacilityUsers()
    // Filter

    const getFacilityUsersFilters = () => {
        const facilityUsersFilters = [...facilityUsers]
        return facilityUsersFilters.filter((facilityUser) => {
            if (isUndefined(facilityUser.user_id)) return false
            const isUserNameFound = facilityUser.user_name.indexOf(filter.searchText) !== -1

            let checkFavoriteUser = true
            if (filter.favoriteUser === FavoriteUser.favorite) {
                checkFavoriteUser = favoriteUsers.includes(facilityUser.user_id)
            }
            if (filter.favoriteUser === FavoriteUser.noFavorite) {
                checkFavoriteUser = !favoriteUsers.includes(facilityUser.user_id)
            }

            let passStatusFilter = true
            switch (filter.status) {
                case StatusUser.inTraining:
                    passStatusFilter = isUserDuringTraining(facilityUser)
                    break
                case StatusUser.offline:
                    passStatusFilter = !isUserDuringTraining(facilityUser)
                    break
            }

            return isUserNameFound && checkFavoriteUser && passStatusFilter
        })
    }

    // Search
    const handleSearch = (filter: { searchText: string; favoriteUser: FavoriteUser; status: StatusUser }) => {
        setFilter(filter)
    }

    //
    const handleClickCell = (user: User) => {
        history.push(`${listPageUrl}/${user?.user_id}/summary`)
    }
    const handleClickEvaluation = (user: User) => {
        history.push(`${listPageUrl}/${user?.user_id}/evaluation`)
    }

    return (
        <React.Fragment>
            <Box display="flex" className={classes.root}>
                <StaffUserListSearchBar onClickHandler={handleSearch} />
            </Box>
            <Box className={classes.content}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Box display="flex" className={classes.guide}>
                            <Box className={classes.guide_item}>
                                <AvatarLabel title="トレーニング中" type="active" />
                            </Box>
                            <Box className={classes.guide_item}>
                                <AvatarLabel title="その他" type="offline" />
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <TitleBar label="ユーザリスト" bgBlue />
                    </Grid>

                    {getFacilityUsersFilters().map((item: User, index) => {
                        return (
                            <Grid item xs={12} key={item.user_id} className={isUserDuringTraining(item) ? classes.bgColor1 : classes.bgColor2}>
                                <StaffUserListCell
                                    item={item}
                                    favoriteUsers={favoriteUsers}
                                    onClickCellHandler={handleClickCell}
                                    onClickEvaluationHandler={handleClickEvaluation}
                                />
                            </Grid>
                        )
                    })}
                </Grid>
            </Box>
        </React.Fragment>
    )
}
