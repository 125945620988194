// import * as Const from '../Const'
// import { trace } from '../common/index'
// import clsx from 'clsx'
import React, { useState } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import Pulldown from './Pulldown'
import { TextField, Box, Button } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { NarrowCondition } from 'Types'

// スタイルを適用する
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'center',
            alignItems: 'center',
        },
        textfield: {
            width: '100%',
            marginRight: 'auto',
        },
        input: {
            height: 40,
            verticalAlign: 'center',
            paddingLeft: '10px',
            borderRadius: 5,
        },
        pulldown: {
            marginLeft: 10,
        },
        button: {
            marginLeft: 10,
            margin: 0,
            width: 170, //'100%',
            height: 40,
            background: '#05A9C2',
            color: '#fff',
        },
    }),
)

type SearchProps = {
    searchText: string
    narrowCondition: NarrowCondition
}

type StaffSearchBarProps = {
    onClickHandler?: (prop: SearchProps) => void
}

export default function StaffSearchBar(props: StaffSearchBarProps) {
    // CSSを適用する。
    const classes = useStyles()
    const [filter, setFilter] = useState({
        searchText: '',
        narrowCondition: NarrowCondition.all,
    })

    const handleClickSearch = () => {
        if (props.onClickHandler) {
            props.onClickHandler(filter)
        }
    }

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter({
            ...filter,
            searchText: e.target.value,
        })
    }

    const handleResponsibleChange = (index: NarrowCondition) => {
        setFilter({
            ...filter,
            narrowCondition: index,
        })
    }

    return (
        <Box className={classes.root}>
            <TextField
                InputProps={{
                    className: classes.input,
                }}
                variant="outlined"
                fullWidth
                placeholder="検索テキスト"
                className={classes.textfield}
                value={filter.searchText}
                onChange={handleSearchChange}
            />
            <Box className={classes.pulldown}>
                <Pulldown
                    style={{ width: 165 }}
                    options={[
                        { value: NarrowCondition.all, label: 'すべて' },
                        { value: NarrowCondition.favorite, label: '担当（お気に入り）' },
                        { value: NarrowCondition.inTraining, label: 'トレーニング中' },
                        { value: NarrowCondition.offline, label: 'その他' },
                        { value: NarrowCondition.reserved, label: '予約あり' },
                        { value: NarrowCondition.request, label: '予約承認待ち' },
                        { value: NarrowCondition.noReservation, label: '予約なし' },
                    ]}
                    defaultValue={filter.narrowCondition}
                    onChangeHandler={handleResponsibleChange}
                />
                {/* <Menu open={false}></Menu> */}
            </Box>
            {/* <Box className={classes.pulldown}>
                <Pulldown
                    style={{ width: 180 }}
                    options={[
                        { value: 0, label: 'トレーニング中のみ' },
                        { value: 1, label: 'その他のみ' },
                        { value: 2, label: '全て' },
                    ]}
                />
                <Menu open={false}></Menu>
            </Box> */}
            <Button variant="outlined" className={classes.button} onClick={handleClickSearch}>
                検索
                <SearchIcon />
            </Button>
        </Box>
    )
}
