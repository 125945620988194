import React from 'react'
import { Box, Checkbox, createStyles, FormControlLabel, FormGroup, makeStyles, Menu, Theme } from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { OPTION_ALL } from 'Const'
import { getOptionLabelById } from 'modules/Util'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formGroup: {
            paddingLeft: 12,
        },
        formControlLabel: {
            '& .MuiFormControlLabel-label': {
                display: 'inline-block',
                width: '100%',
            },
        },
        labelGroup: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        avatarLabel: {
            marginLeft: 10,
        },
        filterIconWrapper: {
            display: 'inline-flex',
            cursor: 'pointer',
        },
        filterImg: {
            display: 'inline-block',
            width: 12,
            height: 12,
            margin: '2px 4px 0 4px',
        },
    }),
)

interface FilterListIconProps {
    id?: string
    optionValues: (number | string)[]
    avatarLabels?: { [key: string]: JSX.Element }
    filterValue: (number | string)[]
    setFilter: (value: (number | string)[]) => void
}

export default function FilterList({ id, avatarLabels, optionValues, filterValue, setFilter }: FilterListIconProps) {
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

    const handlePopperClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const onChange = (e: any) => {
        let value = e.target.name.toString()
        // convert to number if a string is a valid number (Ex: '0' to 0)
        if (id === 'lesson_type' && !isNaN(+value)) {
            value = +value
        }

        if (filterValue && filterValue.includes(value)) {
            if (value === OPTION_ALL.value) {
                setFilter([])
            } else {
                setFilter(filterValue.filter((val: number | string) => val !== value && val !== OPTION_ALL.value))
            }
        } else {
            if (value === OPTION_ALL.value) {
                setFilter(optionValues)
            } else {
                const isFullSelected = optionValues.every(
                    (optionValue) => optionValue === OPTION_ALL.value || optionValue === value || filterValue.includes(optionValue),
                )
                if (isFullSelected) {
                    setFilter([...filterValue, value, OPTION_ALL.value])
                } else {
                    setFilter([...filterValue, value])
                }
            }
        }
    }

    return (
        <>
            <Box className={classes.filterIconWrapper} onClick={handlePopperClick}>
                {filterValue && filterValue.includes(OPTION_ALL.value) ? (
                    <ArrowDropDownIcon />
                ) : (
                    <img src="/assets/images/filter.png" className={classes.filterImg} alt="filter" />
                )}
            </Box>
            <Menu
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                PaperProps={{
                    style: {
                        maxHeight: 350,
                    },
                }}
            >
                <FormGroup className={classes.formGroup}>
                    {optionValues.map((option: any, index: any) => {
                        const optionLabel = getOptionLabelById(option, id)

                        return (
                            <FormControlLabel
                                key={index}
                                className={classes.formControlLabel}
                                control={<Checkbox checked={filterValue?.includes(option)} onChange={onChange} name={option} color="default" />}
                                label={
                                    <Box className={classes.labelGroup}>
                                        <Box>{optionLabel}</Box>
                                        {avatarLabels && avatarLabels[option] && <Box className={classes.avatarLabel}>{avatarLabels[option]}</Box>}
                                    </Box>
                                }
                            />
                        )
                    })}
                </FormGroup>
            </Menu>
        </>
    )
}
