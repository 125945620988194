// React
// import * as Const from '../Const'
// import { trace } from '../common/index'
import clsx from 'clsx'
import React from 'react'
import { useLocation, useParams } from 'react-router-dom'

// for validation
import { useForm } from 'react-hook-form'

// redux
import { useAppDispatch } from '../store'
import { login } from '../modules/AuthModule'

// Material-UI
import CssBaseline from '@material-ui/core/CssBaseline'
import {
    Container,
    Avatar,
    Button,
    TextField,
    FormControlLabel,
    Checkbox,
    Box,
    Typography,
    Paper,
    InputAdornment,
    IconButton,
    FormControl,
    FormHelperText,
    InputBase,
} from '@material-ui/core'
import {
    Alert,
    // AlertTitle,
} from '@material-ui/lab'
import { LockOutlined as LockOutlinedIcon } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'

// User's components
import { Auth } from '../Types'
import { isUndefined, isNull } from 'util'
// import { ApiResponse } from 'modules/AuthClient'
import { unwrapResult } from '@reduxjs/toolkit'

// qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).
// import { stringify } from "querystring";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    header: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        padding: theme.spacing(2, 0, 2),
        background: 'transparent url(../assets/images/logo.png) no-repeat center center',
        height: 64,
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        fontSize: 'calc(20px)', // default: 16px
        margin: theme.spacing(3, 0, 2), // 上下マージン.
        padding: theme.spacing(1, 0, 1, 0), // パディング
    },
    margin: {
        marginTop: theme.spacing(2),
    },
    withoutLabel: {
        marginTop: theme.spacing(3),
    },
    textField: {
        width: '100%', // Fix IE 11 issue.
    },
    // Warning
    warning: {
        color: '#ff0000',
        marginTop: theme.spacing(2),
    },
    textInput: {
        margin: '5px 0 15px 0',
        '& .MuiInput-root, .MuiInputBase-root': {
            boxShadow: '1px 1px 5px #e3e3e3',
            height: 40,
            padding: '0 10px',
            justifyContent: 'center',
        },
        '& .MuiInput-underline:before': {
            borderBottom: 0,
        },
        '& .MuiInputBase-root.Mui-disabled': {
            boxShadow: 'none',
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottom: 0,
        },
        '& .MuiInput-underline:after': {
            borderBottom: 0,
        },
    },
}))

type LocalState = {
    showPassword: boolean // パスワードを表示するか.
    showWarning: boolean // ワーニングを表示するか.
    errorMessages: string[] // エラーメッセージ.
    showRememberMe: boolean // RememberMe を表示するか.
}

type SignInProps = {
    type: 'admin' | 'staff' | 'user' // 動作モード
    next: string // 成功時に遷移するページ.
}
export default function SignIn(props: SignInProps) {
    let account_title: string

    // Set title of the document
    switch (props.type) {
        case 'admin':
            document.title = 'Realize システム管理'
            account_title = '管理者ID'
            break
        case 'staff':
            document.title = 'Realize 施設職員ポータル'
            account_title = '職員ID'
            break
        case 'user':
            document.title = 'Realize ユーザポータル'
            account_title = 'ユーザID'
            break
    }

    const dispatch = useAppDispatch()
    const location = useLocation()
    const classes = useStyles()

    const params = new URLSearchParams(location.search)

    const { facility } = useParams()
    // console.log('Facility', facility)
    const facilityId = isUndefined(facility) ? params.get('facility') : facility
    // console.log('FacilityId', facilityId)

    // window.history.pushState(null, '', props.next + '/signin' + location.search)
    // const [values, setValues] = React.useState<Auth>({
    //     facility: facilityId != null ? facilityId : '',
    //     account: '',
    //     password: '',
    // })

    const { register, handleSubmit, errors } = useForm<Auth & LocalState>({
        defaultValues: {
            facility_id: isNull(facilityId) ? '' : facilityId,
            account: '',
            password: '',
        },
    })

    // const handleChange = (prop: keyof Auth) => (
    //     event: React.ChangeEvent<HTMLInputElement>,
    // ) => {
    //     setValues({ ...values, [prop]: event.target.value })
    // }
    const handleClickShowPassword = () => {
        setState({ ...state, showPassword: !state.showPassword })
    }
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
    }

    /**
     *　ログインボタンが押された時の処理.
     * @param {React.FormEvent} e
     * @returns
     */
    const onSubmit = (data: Auth) => {
        // e.preventDefault()
        if (state.showWarning) {
            // return
        }

        console.log('認証リクエスト', data)

        dispatch(login({ mode: props.type, auth: data }))
            .then(unwrapResult)
            .then((res) => {
                console.log('認証成功')
            })
            .catch((error: any) => {
                // console.log( "### Error:", error.message );
                const result = JSON.parse(error.message)
                console.log('認証失敗', result.status_code)
                if (result.status_code === 403) {
                    setState({ ...state, showWarning: true, errorMessages: ['IDまたはパスワードが違います'] })
                } else if (result.status_code === 0) {
                    setState({ ...state, showWarning: true, errorMessages: ['ネットワークエラー'] })
                } else {
                    setState({ ...state, showWarning: true, errorMessages: ['認証に失敗しました'] })
                }
            })
    }

    // function setError(message: string) {
    //     setState({
    //         ...state,
    //         showWarning: true,
    //         errorMessages: [message],
    //     })
    // }

    const [state, setState] = React.useState<LocalState>({
        showPassword: false,
        showWarning: false,
        errorMessages: [],
        showRememberMe: false,
    })

    function Result() {
        return (
            <Paper>
                {state.errorMessages.map((text, index) => {
                    return (
                        <Alert key={index} severity="error">
                            {text}
                        </Alert>
                    )
                })}
            </Paper>
        )
    }

    return (
        <React.Fragment>
            <Container component="main" maxWidth="md">
                <Paper className={classes.header}></Paper>
            </Container>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    {state.showWarning ? <Result /> : null}
                    <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
                        <input type="hidden" name="next" value={props.next} ref={register} />

                        {/* 施設ID. */}
                        {props.type === 'staff' || props.type === 'user' ? (
                            <Box>
                                施設ID
                                <TextField
                                    disabled={!isNull(facilityId)}
                                    required
                                    fullWidth
                                    id="facility_id"
                                    name="facility_id"
                                    autoComplete=""
                                    autoFocus
                                    // defaultValue={values.facility}
                                    // onChange={handleChange('facility')}
                                    // validation
                                    inputRef={register({
                                        required: '施設IDは必須です',
                                    })}
                                    error={Boolean(errors.facility_id)}
                                    helperText={errors.facility_id && errors.facility_id.message}
                                    className={classes.textInput}
                                />
                            </Box>
                        ) : null}
                        {/* ユーザID. */}
                        <Box>
                            {account_title}
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="account"
                                name="account"
                                autoComplete="on"
                                autoFocus
                                // defaultValue={values.account}
                                // onChange={handleChange('account')}

                                // validation
                                inputRef={register({
                                    required: `${account_title}は必須です`,
                                })}
                                error={Boolean(errors.account)}
                                helperText={errors.account && errors.account.message}
                                className={classes.textInput}
                            />
                        </Box>
                        {/* パスワード */}
                        {/* <PasswordField label="パスワード" value={values.password} onChange={handleChage"password"} /> */}
                        <div>
                            パスワード
                            <FormControl fullWidth className={clsx(classes.margin, classes.textField, classes.textInput)} error={Boolean(errors.password)}>
                                <InputBase
                                    id="password"
                                    name="password"
                                    // aria-describedby="my-helper-text"
                                    type={state.showPassword ? 'text' : 'password'}
                                    // defaultValue={values.password}
                                    // onChange={handleChange('password')}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {state.showPassword ? (
                                                    <img src="/assets/images/eye_open64.png" width="20px" alt="eye_open64" />
                                                ) : (
                                                    <img src="/assets/images/eye_close64.png" width="20px" alt="eye_close64" />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    inputRef={register({
                                        required: 'パスワードは必須です',
                                    })}
                                />
                                <FormHelperText id="password">{errors.password && errors.password.message}</FormHelperText>
                            </FormControl>
                        </div>
                        {/* チェックボックス (Remember me) */}
                        {state.showRememberMe ? <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" /> : null}
                        {/* サインイン. */}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            // onClick={handleSubmit}
                        >
                            ログイン
                        </Button>
                    </form>
                </div>
            </Container>
        </React.Fragment>
    )
}
