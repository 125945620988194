// 職員ポータル/トップ/ユーザリスト
// 日毎
// ユーザ単位表示
// 時系列表示
// import * as Const from '../Const'
// import { trace } from '../common/index'
import clsx from 'clsx'
import React from 'react'
// Redux

// Material-UI
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Paper, Grid, Popover, Card } from '@material-ui/core'

// Functional
import * as DateUtil from 'date-fns'

// User
import * as DataUtil from '../modules/DataUtil'
import { User, Lesson } from '../Types'
import FullnameLabel from './FullnameLabel'
import FavoriteStaff from './FavoriteStaff'
import AvatarLabel from './AvatarLabel'
import { isUndefined } from 'util'
import { getImageCacheContext } from 'modules/ImageCacheProvider'

// スタイルを適用する
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: '8px 0 8px 8px',
            // height: '100px',
            // overflow: 'hidden',

            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: '0 1px 1px #b3b3b3',
            '&:hover': {
                cursor: 'pointer',
            },
        },
        user_element: {
            // float: 'left',
        },
        circle: {
            width: 20,
            height: 20,
            borderRadius: '50%',
            backgroundColor: theme.palette.primary.main,
        },
        circle_offline: {
            backgroundColor: theme.palette.secondary.main,
        },

        container: {
            // justifyContent: 'center',
            textAlign: 'center',
            // alignItems: 'center',
            // height: "100%",
        },
        avatar: {
            width: 80,
            height: 80,
            borderRadius: 0,
        },
        smallAvatar: {
            margin: '0px',
            width: 20,
            height: 20,
            fontSize: '12px',
        },
        avatar_name: {
            marginLeft: '10px',
        },
        time: {
            borderColor: theme.palette.common.black,
            textAlign: 'center',
            padding: '20px,220px',
        },
        chip: {
            width: '100%',
            padding: '0 15px',
            height: '60px',
            fontWeight: 'bold',
            color: theme.palette.common.white,
            backgroundColor: '#05A9C2',
            borderRadius: 0,
            display: 'table-cell',
            verticalAlign: 'middle',
        },
        reservation_status: {
            backgroundColor: '#fff',
            color: theme.palette.common.black,
            lineHeight: '20px',
            borderRadius: 0,
            display: 'table-footer-group',
        },
        offline: {
            backgroundColor: theme.palette.secondary.main,
        },
        active: {
            backgroundColor: theme.palette.primary.main,
        },
        popover: {
            pointerEvents: 'none',
        },
        paper: {
            padding: theme.spacing(1),
            backgroundColor: '#cff',
        },
        name: {
            padding: '0 10px 0 15px',
        },
    }),
)
// レッスンが存在するか.
function hasLesson(user: User | undefined): boolean {
    return isUndefined(user) || isUndefined(user.lessons) || user.lessons.length === 0
}

type LocalState = {
    user_name: string
    staff_name: string
    event: string
    favorite: boolean
    badge: number
    icon?: string
}

// Props.
type StaffUserCellProps = {
    item?: User
    favoriteUsers?: string[]
    onClickHandler?: () => void
    onFavoriteChange?: (favorite: boolean) => void
    bgColor?: string
}

export default function StaffUserCell(props: StaffUserCellProps) {
    const classes = useStyles()

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

    // popover
    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setAnchorEl(event.currentTarget)
    }
    const handlePopoverClose = () => {
        setAnchorEl(null)
    }
    const open = Boolean(anchorEl)

    const [item] = React.useState<User>(props.item ? props.item : DataUtil.initUser())

    function getNextLesson(user: User): Lesson | undefined {
        if (isUndefined(user) || isUndefined(user.lessons) || user.lessons.length === 0) return undefined
        return user.lessons[0]
    }

    function getUserName(user: User): string {
        return isUndefined(user) || isUndefined(user.user_name) ? '' : user.user_name
    }
    function getStaffName(user: User): string {
        const lesson = getNextLesson(user)
        return isUndefined(lesson) || isUndefined(lesson.staff) || isUndefined(lesson.staff.staff_name) ? '' : lesson.staff.staff_name
    }
    function getStartTime(user: User): string {
        const lesson = getNextLesson(user)
        return isUndefined(lesson) ? '予定なし' : lessonStartTime(lesson) + '  開始'
    }
    function lessonStartTime(lesson: Lesson): string {
        return isUndefined(lesson) || isUndefined(lesson.start)
            ? '--:--'
            : DateUtil.format(DateUtil.parse(lesson.start, 'yyyy-MM-dd HH:mm:ss', new Date()), 'HH:mm')
    }
    function getBadgeCount(user: User): number {
        if (isUndefined(user) || isUndefined(user.lessons)) return 0
        return user.lessons.length < 2 ? 0 : user.lessons.length
    }

    const imageCache = React.useContext(getImageCacheContext())
    function getIconUrl(user: User): string | undefined {
        return imageCache.getUserImage(user)
    }

    // Display factor
    const [state, setState] = React.useState<LocalState>({
        user_name: getUserName(item),
        staff_name: getStaffName(item),
        event: getStartTime(item),
        favorite: false,
        badge: getBadgeCount(item),
        icon: getIconUrl(item),
    })

    React.useEffect(() => {
        if (!isUndefined(props.favoriteUsers) && props.favoriteUsers.includes(item.user_id || '')) {
            setState({
                ...state,
                favorite: true,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.favoriteUsers])

    const handleClick = (event: React.MouseEvent<unknown, MouseEvent>) => {
        if (props.onClickHandler !== undefined) {
            props.onClickHandler()
        }
    }

    return (
        <Paper variant="outlined" elevation={3} className={clsx(classes.root, props.bgColor)} onClick={handleClick}>
            <Grid container className={classes.container}>
                <Grid item xs={3}>
                    <FavoriteStaff favorite={state.favorite} onFavoriteChange={props.onFavoriteChange} icon={state.icon} avatar_class={classes.avatar} />
                </Grid>
                <Grid item xs={6} className={classes.name}>
                    <Grid container>
                        <FullnameLabel fullname="氏名" />
                        <Grid item xs={12}>
                            <AvatarLabel title={state.user_name} type="no_photo" fontBold />
                        </Grid>
                        <Grid item xs={12}>
                            {hasLesson(props.item) ? null : <AvatarLabel title={state.staff_name} type="avatar_on_right" />}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={3}>
                    <Grid item xs={11}>
                        <React.Fragment>
                            <Card className={classes.chip} onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
                                {state.event}
                            </Card>
                            <Card className={classes.reservation_status}>予約状況</Card>
                            {hasLesson(props.item) ? null : (
                                <Popover
                                    id="mouse-over-popover"
                                    className={classes.popover}
                                    open={open}
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    onClose={handlePopoverClose}
                                    disableRestoreFocus
                                >
                                    <Paper variant="outlined" className={classes.paper}>
                                        {props.item && props.item.lessons
                                            ? props.item.lessons.map((lesson: Lesson, index) => {
                                                  return (
                                                      <div key={index}>
                                                          {lessonStartTime(lesson)} {lesson.training?.training_name}
                                                      </div>
                                                  )
                                              })
                                            : null}
                                    </Paper>
                                </Popover>
                            )}
                        </React.Fragment>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    )
}
