// 勤怠モジュール
// import * as Const from '../Const'
// import { trace } from '../common/index'
import { useSelector } from 'react-redux'
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from '../rootReducer'
import * as ApiClient from './ApiClient'
import * as DateUtil from 'date-fns'
import { Staff, Attendance } from '../Types'
import { isUndefined } from 'util'

// ステート定義
type AttendanceState = {
    timestamp: number
    staff?: Staff
    items: Attendance[]
    staff_id?: string
    year: number
    month: number
}

// 非同期アクション

// 全て取得.
export const fetchAttendances = createAsyncThunk('attendance/fetchAll', async (arg, thunkAPI) => {
    const response = await ApiClient.get('/attendance')
    return response.data
})

// 職員ID + 期間指定
export const fetchAttendanceByStaffId = createAsyncThunk(
    'attendance/fetchAttendanceByStaffId',
    async (item: { staff_id: string; year?: number; month?: number }, thunkAPI) => {
        let url = `/attendances/${item.staff_id}`
        if (!isUndefined(item.year)) {
            url = `${url}/${item.year}`
            if (!isUndefined(item.month)) {
                const month = item.month
                url = `${url}/${month}`
                // console.log('item:' + item.month + '->' + month + ':' + url)
            }
        }
        // console.log('URL:', url)
        const response = await ApiClient.get(url)
        return response.data
    },
)

// 出勤.
export const updateAttendanceEnter = createAsyncThunk('attendance/updateAttendanceEnter', async (staff_id: string, thunkAPI) => {
    const now = new Date()
    const params = {
        staff_id: staff_id,
        date: DateUtil.format(now, 'yyyy-MM-dd'),
        enter_time: DateUtil.format(now, 'HH:mm'),
    }
    console.log(`出勤:${JSON.stringify(params)}`)
    const response = await ApiClient.put(`/staff/${staff_id}/attendances`, params)
    return response.data
})
// 退勤.
export const updateAttendanceLeave = createAsyncThunk('attendance/updateAttendanceLeave', async (staff_id: string, thunkAPI) => {
    const now = new Date()
    const params = {
        staff_id: staff_id,
        date: DateUtil.format(now, 'yyyy-MM-dd'),
        leave_time: DateUtil.format(now, 'HH:mm'),
    }
    console.log(`退勤:${JSON.stringify(params)}`)
    const response = await ApiClient.put(`/staff/${staff_id}/attendances`, params)
    return response.data
})
// 勤怠編集.
export const putAttendances = createAsyncThunk('attendance/updateAttendanceLeave', async (arg: { staff_id: string; item: Attendance }, thunkAPI) => {
    const params = {
        staff_id: arg.staff_id,
        date: arg.item.date,
        enter_time: arg.item.enter_time,
        leave_time: arg.item.leave_time,
        content: arg.item.content,
    }
    console.log('勤怠編集:', params)
    const url = `/staff/${arg.staff_id}/attendances`

    const response = await ApiClient.put(url, params)
    return response.data
})

// ステート初期値.
const initialState: AttendanceState = {
    timestamp: DateUtil.getUnixTime(new Date()),
    staff: undefined,
    items: [],

    year: 2020,
    month: 7,
}

// Slice を作成.
const AttendanceModule = createSlice({
    name: 'attendance',
    initialState,
    reducers: {
        changeStaff(state: AttendanceState, action: PayloadAction<{ staff_id: string; ts: Date }>) {
            state.staff_id = action.payload.staff_id
        },
        nextMonth(state: AttendanceState, action: PayloadAction<undefined>) {
            let date = DateUtil.fromUnixTime(state.timestamp)
            date = DateUtil.addMonths(date, 1)
            state.timestamp = DateUtil.getUnixTime(date)
        },
        prevMonth(state: AttendanceState, action: PayloadAction<undefined>) {
            let date = DateUtil.fromUnixTime(state.timestamp)
            date = DateUtil.addMonths(date, -1)
            state.timestamp = DateUtil.getUnixTime(date)
        },
        today(state: AttendanceState, action: PayloadAction<undefined>) {
            let date = new Date()
            date = DateUtil.addMonths(date, 1)
            state.timestamp = DateUtil.getUnixTime(date)
        },
    },
    extraReducers: (builder) => {
        // fetch
        builder.addCase(fetchAttendances.fulfilled, (state, action) => {
            console.log('fulfilled')
            state.items = action.payload
        })
        builder.addCase(fetchAttendances.pending, (state, action) => {
            console.log('pending')
        })
        builder.addCase(fetchAttendances.rejected, (state, action) => {
            console.log('rejected')
        })
    },
})

export const useAttendances = () => {
    return useSelector((state: RootState) => state.attendance.items)
}
export const useDate = () => {
    const timestamp = useSelector((state: RootState) => state.attendance.timestamp)
    const date = DateUtil.fromUnixTime(timestamp)
    return date
}

// Action Creatorsをエクスポート
export const { changeStaff, nextMonth, prevMonth, today } = AttendanceModule.actions

// Slice をエクスポートする
export default AttendanceModule
