import { isNull, isUndefined } from 'util'
import * as DateUtil from 'date-fns'
import { getLessonStatusText, getLessonTypeText } from './DataUtil'
import { LessonStatus, LessonType } from 'Types'
import { OPTION_ALL, OPTION_CONCURRENT } from 'Const'

/**
 * オブジェクトが空かどうか.
 *
 * @param {*} obj
 * @returns
 */
export function isEmpty(obj: any) {
    return obj !== undefined && Object.keys(obj).length > 0
}

export function generateRandomString(length: number) {
    var seed: string = ''
    seed = seed + 'abcdefghijklmnopqrstuvwxyz'
    seed = seed + 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    seed = seed + '0123456789'
    var N = 16
    let str
    do {
        str = Array.from(Array(N))
            .map(() => seed[Math.floor(Math.random() * seed.length)])
            .join('')
    } while (isNull(str.match(/\d+[a-zA-Z]+/)) && isNull(str.match(/[a-zA-Z]+\d+/)))
    return str
}

/**
 * Format yyyy-MM-dd HH:mm:ss to yyyy/MM/dd HH:mm
 *
 * @param {string} dateTime
 */
export function formatDateTime(dateTime: string | undefined) {
    if (isUndefined(dateTime)) return ''
    return DateUtil.format(DateUtil.parse(dateTime, 'yyyy-MM-dd HH:mm:ss', new Date()), 'yyyy/MM/dd HH:mm')
}

/**
 * base64 encode ( string -> base64 )
 *
 * @export
 * @param {string} str
 * @returns {string}
 */
export function base64Encode(str: string): string {
    var buffer1 = Buffer.from(str, 'ascii')
    var base64 = buffer1.toString('base64')
    return base64
}

/**
 * base64 decode ( base64 -> string )
 *
 * @export
 * @param {string} base64
 * @returns {string}
 */
export function base64Decode(base64: string): string {
    const buffer = Buffer.from(base64, 'base64')
    return buffer.toString('ascii')
}

/**
 * React-Table
 * Get option label by id
 */
export function getOptionLabelById(option: string | number, id: string | undefined): string | number {
    let optionLabel
    switch (id) {
        case 'status':
            optionLabel = getLessonStatusText(option as LessonStatus)
            optionLabel = optionLabel || option
            break
        case 'lesson_type':
            optionLabel = getLessonTypeText(option as LessonType)
            optionLabel = optionLabel || option
            break
        default:
            optionLabel = option
            break
    }
    return optionLabel === OPTION_ALL.value ? OPTION_ALL.label : optionLabel === OPTION_CONCURRENT.value ? OPTION_CONCURRENT.label : optionLabel
}

/**
 * Is not empty string
 *
 * @param value
 * @returns {boolean}
 */
export function isNotEmptyString(value: string): string | boolean {
    if (value.length) {
        return value.trim() !== '' || '空白の登録はできません'
    }
    return true
}
