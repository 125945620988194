// import * as Const from '../Const'
// import { trace } from '../common/index'
import clsx from 'clsx'

import React from 'react'
// Redux
import {
    Container,
    Typography,
    TextField,
    Box,
    Paper,
    Grid,
    FormControlLabel,
    MenuItem,
    FormControl,
    Select,
    RadioGroup,
    Radio,
    TableContainer,
    TableHead,
    Table,
    TableBody,
    Button,
} from '@material-ui/core'
// import {
//     // Mic as MicIcon,
//     MicOff as MicOffIcon,
//     Mic as MicIcon,
//     Videocam as VideocamIcon,
//     VideocamOff as VideocamOffIcon,
// } from '@material-ui/icons'

// import { NavigateNext as NavigateNextIcon, NavigateBefore as NavigatePrevIcon } from '@material-ui/icons'
// import { usePagination } from '@material-ui/lab/Pagination'

import {
    Examination,
    ExaminationAnswerMethod,
    GrowthRecord,
    Lesson,
    LessonStatus,
    LessonType,
    MspaEvaluationId,
    MspaExpectation,
    User,
    LessonComment,
    Staff,
} from '../Types'
import { TrendMark } from '../components/TrendMark'
import TitleBar from '../components/TitleBar'
import { CloseButton } from './Buttons'
import { InputCheckBox, GreyLabelInputField } from './Inputs'
import StaffUserInfoCell from '../components/StaffUserInfoCell'
import Video360 from './Video360'
import VideoControlPanel from './VideoControlPanel'
import * as VideoControlPanelTask from './VideoControlPanel'
import * as DataUtil from 'modules/DataUtil'
import { useAppDispatch } from 'store'
import {
    putLessonCommentAndGrowthRecord,
    useLessonById,
    setLessonMessage,
    patchLessonStatus,
    patchLessonExaminationAnswer,
    setLessonExaminationAnswer,
    setLessonCommentAndRecords,
    fetchLessonsByUser,
    setLessonStatus,
} from 'modules/LessonModule'
import { useParams, useRouteMatch, useHistory } from 'react-router-dom'
import {
    setFacilityLessonCommentAndRecords,
    setFacilityLessonExaminationAnswer,
    setFacilityLessonStatus,
    useFacilityLessonById,
    useFacilityUsers,
} from 'modules/FacilityModule'
import { useHomePageUrl, useProfile } from 'modules/AppModule'
import { isUndefined } from 'util'
// import CameraView from 'components/CameraView'
import VideoChat from './VideoChat'
import { LightStripedTableCell, LightStripedTableRow } from './Tables'
import * as WS from 'modules/WebSocket'
import * as LessonLogProvider from 'modules/LessonLogProvider'
import { getDialogContext } from 'modules/DialogProvider'
import AvatarLabel from './AvatarLabel'
import { getImageCacheContext } from 'modules/ImageCacheProvider'
import { useSnackbar } from 'notistack'
import { v4 as uuidv4 } from 'uuid'
import * as VideoController from 'modules/VideoController'

import { getVideoStateContext } from 'modules/VideoStateProvider'
import VideoStateProvider from 'modules/VideoStateProvider'
// スタイルを適用する
import { useStyles } from '../components/Staff/Instruction/Lesson/Stylex'

type MSPA = {
    mspa_evaluation_id: string
    enabled: boolean // 有効/無効.
    label: string // ラベル.
    comment: string // コメント.
    expectation: number // 傾向
    achievement: number // 達成度(1..10)
}

type Comment = Omit<LessonComment, 'lesson_id' | 'user_id'>
const initialCommentValue = {
    comment_prev: '',
    comment_current: '',
    comment_next: '',
    comment_of_parent: '',
}

const selectBox = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

type StaffInstructionProps = {}

export default function StaffInstruction(props: StaffInstructionProps) {
    return (
        <VideoStateProvider>
            <StaffInstructionBody />
        </VideoStateProvider>
    )
}

function StaffInstructionBody(props: StaffInstructionProps) {
    const dispatch = useAppDispatch()
    const classes = useStyles()
    const { showDialog } = React.useContext(getDialogContext())
    const { enqueueSnackbar } = useSnackbar()

    const homePageUrl = useHomePageUrl()
    const history = useHistory()
    const match = useRouteMatch()
    const currentPath = match.path

    const users = useFacilityUsers()

    const { lessonId } = useParams()
    let lesson: undefined | Lesson = undefined
    const lesson1 = useLessonById(lessonId)
    const lesson2 = useFacilityLessonById(lessonId)
    const staffProfile = useProfile() as Staff
    const showCommentOfParent = staffProfile?.show_comment_of_parent ?? 0

    // redirect to list page if id isn't loaded
    if (lessonId && isUndefined(lesson1) && isUndefined(lesson2)) {
        handleClose()
    }

    if (currentPath === `${homePageUrl}/instructions/:lessonId`) {
        lesson = lesson2
    } else {
        lesson = lesson1
    }
    // console.log(`Lesson: ${lessonId}:${lesson?.status}`)

    // console.log(`### LessonType:${lesson?.lesson_type === LessonType.concurrent ? '一斉指導' : '個別指導'}`)

    // ユーザ
    let lesson_user = lesson?.user
    if (lesson?.lesson_type === LessonType.concurrent) {
        if (lesson?.users !== undefined && lesson?.users.length > 0) {
            lesson_user = lesson?.users[0]
        }
    }
    const [userLessons, setUserLessons] = React.useState<Lesson[]>([])
    const [lessonUser, setLessonUser] = React.useState<User | undefined>(lesson_user)
    const [lessonUsers, setLessonUsers] = React.useState<any>({})

    const useEffectOnMount = (effect: React.EffectCallback) => {
        React.useEffect(effect, [lessonUser])
    }
    useEffectOnMount(() => {
        handleFetchUserLessons()
    })
    const handleFetchUserLessons = () => {
        if (lessonUser && lessonUser.user_id) {
            const userId: string = lessonUser.user_id
            if (lessonUsers[userId]) {
                setUserLessons(lessonUsers[userId])
            } else {
                dispatch(
                    fetchLessonsByUser({
                        user_id: userId,
                        start: '-',
                        end: '-',
                    }),
                ).then((res) => {
                    const data = res.payload as Lesson[]
                    if (data && data.length > 0) {
                        setUserLessons(data)
                        setLessonUsers({
                            ...lessonUsers,
                            [userId]: data,
                        })
                    }
                })
            }
        }
    }

    // TODO: 一斉指導では、user_id を動的に管理する
    const [lessonComment, setLessonComment] = React.useState<Comment>(initialCommentValue)

    // console.log(`### LessonUser:${lessonUser?.user_id}`)
    const [selectedIndex, setSelectedIndex] = React.useState(0)

    React.useEffect(() => {
        if (lessonId && isUndefined(lesson)) {
            return
        }
        if (lesson?.lesson_type !== LessonType.concurrent) {
            const message = DataUtil.getLessonMessage(lesson?.status)
            dispatch(setLessonMessage(message))
        } else {
            dispatch(setLessonMessage(''))
            return
        }

        console.log('####レッスンログメッセージ')
        // レッスンログ
        if (lesson?.status === LessonStatus.finish) {
            const name = lessonId
            LessonLogProvider.load(name)
                .then((res) => {
                    dispatch(setLessonMessage('ユーザのレッスンを再生してください'))
                    // 再生はマニュアル操作で行う為、ここでは再生開始しない. (VideoControlPanelで処理する)
                })
                .catch((err) => {
                    console.log('LessonLog:', err)
                    dispatch(setLessonMessage('レッスンログがありません'))
                    // レッスンログが見つからない.
                    showDialog({
                        title: '',
                        message: 'このレッスンは正常終了していないため、レッスン状況を再現することができません。\n視聴した動画の確認のみ可能です。',
                        items: [{ label: 'OK' }],
                    })
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lessonId])

    const [examinations, setExaminations] = React.useState<Examination[] & { answer?: number }>(
        lesson?.training?.examinations === undefined ? [] : lesson?.training?.examinations,
    )

    // Add answer's property if it's existed
    function updateExam(user: User | undefined) {
        const examinationAnswers = lesson?.examination_answers === undefined ? [] : lesson?.examination_answers
        if (user === undefined) return

        if (examinations.length && examinationAnswers.length) {
            const results = examinations.map((examination) => {
                let answer = undefined
                for (const examinationAnswer of examinationAnswers) {
                    if (examinationAnswer.user_id === user?.user_id && examinationAnswer.examination_id === examination.id) {
                        answer = examinationAnswer.answer
                        break
                    }
                }
                return {
                    ...examination,
                    answer: answer,
                    key: uuidv4(),
                }
            })
            console.log(results)
            setExaminations(results)
        }
    }

    function updateLessonComment(user: User | undefined) {
        const userId = user?.user_id
        if (!userId) return
        const currentComment = lesson?.lesson_comments?.find((lessonComment) => lessonComment.user_id === userId)
        if (!currentComment) {
            setLessonComment(initialCommentValue)
        } else {
            setLessonComment({
                ...lessonComment,
                ...currentComment,
            })
        }
    }

    React.useEffect(() => {
        updateExam(lessonUser)
        updateLessonComment(lessonUser)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [attendees, setAttendees] = React.useState<string[]>([])

    React.useEffect(() => {
        WS.setCallback({ key: 'SI', onMessage: onMessage })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attendees])

    const onMessage = (message: string) => {
        // console.log(`メッセージ:${message}`)
        const json = JSON.parse(message)

        // WebSocket からコマンドがあるときは、マニュアル操作不可.
        // state.isOperable = false;
        if (json.command === 'attendees') {
            console.log('#### attendees', json.data)
            const attendees = json.data
            if (attendees.vr !== undefined && attendees.vr.length > 0) {
                // 参加者情報更新.
                setAttendees(
                    attendees.vr.map((attendee: any) => {
                        return attendee.id
                    }),
                )
                // Snackbar 表示
                const state = lesson?.lesson_type === LessonType.concurrent ? '待機中' : '視聴中'

                const count = attendees.vr.length
                const foo = attendees.vr[0]
                const user = users.find((user) => user.user_id === foo.id)
                const name = user?.user_name === undefined ? '' : user?.user_name
                if (count === 1) {
                    showNotice(`${name} さんが${state}です`, 'info')
                } else {
                    showNotice(`${name} さん 他${count - 1}人が${state}です`, 'info')
                }
            }
        } else if (json.command === 'enter') {
            const item = json.data

            // Do not show notice because this enter command for gettting info
            if (item?.is_internal) return

            const user = users.find((user) => user.user_id === item.id)
            if (item.from === 'vr') {
                setAttendees([...attendees, item.id])
            }
            let name
            switch (item.from) {
                case 'vr':
                    name = user?.user_name
                    break
                case 'user':
                    name = user?.parent_1
                    break
                case 'staff':
                    name = lesson?.staff?.staff_name
            }
            name = name === undefined ? '' : name
            showNotice(`${name}さんが入室しました`, 'info')
        } else if (json.command === 'leave') {
            const item = json.data
            if (item.from === 'vr') {
                setAttendees(attendees.filter((attendee) => attendee !== item.id))
            }
            const user = users.find((user) => user.user_id === item.id)
            let name
            switch (item.from) {
                case 'vr':
                    name = user?.user_name
                    break
                case 'user':
                    name = user?.parent_1
                    break
                case 'staff':
                    name = lesson?.staff?.staff_name
            }
            name = name === undefined ? '' : name
            showNotice(`${name}さんが退室しました`, 'info')
        }
    }
    const items = makeLocalState(lesson, lesson_user?.user_id)
    const [records, setRecords] = React.useState<MSPA[]>(items)

    const handleCommentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLessonComment({
            ...lessonComment,
            [e.target.name]: e.target.value,
        })
    }

    // event handler.
    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        console.log('送信', records)
        const lessonId = lesson?.lesson_id
        const userId = lessonUser?.user_id
        if (isUndefined(lessonId) || isUndefined(userId)) {
            return
        }
        const items = records
            .filter((record) => record.enabled)
            .map((record) => {
                return {
                    user_id: userId,
                    mspa_evaluation_id: record.mspa_evaluation_id as MspaEvaluationId,
                    achievement: record.achievement,
                    comment: record.comment,
                }
            })
        console.log('送信2', items)
        const param = {
            lesson_id: lessonId,
            user_id: userId,
            comment_prev: lessonComment.comment_prev,
            comment_current: lessonComment.comment_current,
            comment_next: lessonComment.comment_next,
            growth_records: items,
        }
        dispatch(putLessonCommentAndGrowthRecord({ lesson_id: lessonId, item: param }))
            .then((res) => {
                console.log(res)
                // history.goBack()
                // history.push(`${homePageUrl}/facility-users/${lesson?.user?.user_id}/summary`)
                if (res.payload === undefined) {
                    showNotice('登録に失敗しました', 'error')
                    return
                }
                showNotice('登録しました', 'info')
                if (currentPath === `${homePageUrl}/instructions/:lessonId`) {
                    dispatch(setFacilityLessonCommentAndRecords({ lesson_id: lessonId, item: param }))
                } else {
                    dispatch(setLessonCommentAndRecords({ lesson_id: lessonId, item: param }))
                }
            })
            .catch((error) => {
                showNotice('登録に失敗しました', 'error')
            })
    }
    function showNotice(message: string, variant: any) {
        console.log(`Notice:${message}`)
        enqueueSnackbar(message, { variant })
    }

    // reset to default.
    const handleReset = (e: React.FormEvent) => {
        e.preventDefault()

        const items: MSPA[] = makeLocalState(lesson, lesson_user?.user_id)
        setRecords(items)
        updateLessonComment(lessonUser)
    }
    // close
    function handleClose() {
        VideoControlPanelTask.onExit()
        // WS.sendCommand('terminate', '')
        WS.stop()
        history.goBack()
        // history.push(`${homePageUrl}/facility-users/${lesson?.user?.user_id}/summary`)
    }

    const { Pause } = React.useContext(getVideoStateContext())

    // レッスン終了(一斉指導時)
    const handleTerminate = (event: any) => {
        // 強制中断を送信.
        WS.sendCommand('terminate', '')

        // コンパネをポーズ
        Pause()
        // 動画をポーズ
        VideoController.doPause()
        // レッスン中フラグをオフに.
        setIsStart(false)

        if (lesson?.status === LessonStatus.finish) {
            return
        }

        // 受講環境ステータスを送信.
        dispatch(patchLessonStatus({ lesson_id: lesson?.lesson_id === undefined ? '' : lesson?.lesson_id, status: LessonStatus.finish }))
            .then((res) => {
                console.log('ステータス送信完了', res)
                WS.sendCommand('status', LessonStatus.finish)
                handleSetLessonStatus(LessonStatus.finish)
            })
            .catch((err) => {
                console.log('ステータス送信エラー', err.message)
            })
            .finally(() => {})
    }

    const [isStart, setIsStart] = React.useState<boolean>(false)
    const videoOnStart = () => {
        if (isStart) {
            return
        }
        console.log('############ OnStart()')
        setIsStart(true)
        if (lesson?.status === LessonStatus.finish || lesson?.lesson_type === LessonType.individual) {
            return
        }
        dispatch(patchLessonStatus({ lesson_id: lesson?.lesson_id === undefined ? '' : lesson?.lesson_id, status: LessonStatus.progressing }))
            .then((res) => {
                console.log('ステータス送信完了', res)
                WS.sendCommand('status', LessonStatus.progressing)
                handleSetLessonStatus(LessonStatus.progressing)
            })
            .catch((err) => {
                console.log('ステータス送信エラー', err.message)
            })
    }

    // MSPA Cell changed.
    const handleChange = (index: number) => (value: MSPA) => {
        console.log('handleChange@setRecords')
        // replace records
        setRecords(
            records.map((record, idx) => {
                return index === idx ? value : record
            }),
        )
    }

    // 映像の説明.
    function getVideoTitleByStatus() {
        switch (lesson?.status) {
            case LessonStatus.finish:
                return 'ユーザが視聴した映像'
            case LessonStatus.progressing:
                return 'ユーザが視聴中の映像'
            case LessonStatus.request:
                return 'ユーザが予約中の映像'
            case LessonStatus.reserved:
                return 'ユーザが予約済みの映像'
        }
        return 'ユーザが視聴中の映像'
    }

    function getVideoControlMode() {
        if (lesson?.lesson_type === LessonType.concurrent) {
            return VideoControlPanelTask.VideoControlMode.concurrent
        }
        switch (lesson?.status) {
            case LessonStatus.finish:
                return VideoControlPanelTask.VideoControlMode.replay
            case LessonStatus.progressing:
                return VideoControlPanelTask.VideoControlMode.remote
            case LessonStatus.request:
                return VideoControlPanelTask.VideoControlMode.remote
            case LessonStatus.reserved:
                return VideoControlPanelTask.VideoControlMode.remote
        }
        return VideoControlPanelTask.VideoControlMode.local
    }
    const imageCache = React.useContext(getImageCacheContext())

    function userIconUrl(user: User | undefined): string | undefined {
        if (user === undefined) {
            return undefined
        }
        return imageCache.getUserImage(user)
    }

    const handleAnswerExamination = (examinationId: string, answer: number) => {
        if (lessonUser?.user_id === undefined) return
        dispatch(
            patchLessonExaminationAnswer({
                lesson_id: lessonId,
                user_id: lessonUser.user_id,
                examination_id: examinationId,
                item: { answer: answer, method: ExaminationAnswerMethod.portal },
            }),
        ).then(async () => {
            if (lessonUser?.user_id === undefined) return
            const param = {
                lesson_id: lessonId,
                item: { user_id: lessonUser.user_id, examination_id: examinationId, answer: answer, method: ExaminationAnswerMethod.portal },
            }
            let action
            if (currentPath === `${homePageUrl}/instructions/:lessonId`) {
                action = setFacilityLessonExaminationAnswer(param)
            } else {
                action = setLessonExaminationAnswer(param)
            }
            dispatch(action)
        })
    }

    const isUserDuringTraining = () => {
        if (lessonUser && userLessons && userLessons.length) {
            const lessonDuringTraining = userLessons.find((lessonItem: Lesson) => {
                return lessonItem.status === LessonStatus.progressing
            })
            return lessonDuringTraining ? true : false
        }
        return false
    }

    const handleSetLessonStatus = (status: LessonStatus) => {
        let action = null
        const param = {
            lesson_id: lessonId,
            status: status,
        }
        if (currentPath === `${homePageUrl}/instructions/:lessonId`) {
            action = setFacilityLessonStatus(param)
        } else {
            action = setLessonStatus(param)
        }
        action && dispatch(action)
    }

    return (
        <React.Fragment>
            <Grid container spacing={1} className={classes.root}>
                <Grid item xs={12} className={classes.staff_user_cell}>
                    <StaffUserInfoCell user={lessonUser} active={!!isUserDuringTraining()} />
                </Grid>
                <Grid item xs={12} className={classes.video_container}>
                    <Box className={classes.view}>
                        <Paper className={classes.left_view}>
                            <Typography variant="h5" className={classes.header_title}>
                                {getVideoTitleByStatus()}
                            </Typography>

                            <Box className={clsx(classes.box, classes.header)}>
                                <Box className={classes.title_label}>
                                    <Typography variant="subtitle1">title</Typography>
                                </Box>
                                <Box className={classes.title_video}>
                                    <Typography variant="subtitle1">{lesson?.training === undefined ? '' : lesson.training.training_name}</Typography>
                                </Box>
                            </Box>

                            <Box className={classes.video}>
                                <Video360 height="325" url={lesson?.training?.content_url} />
                            </Box>
                            <Box className={classes.control_panel}>
                                {/* <VideoStateProvider> */}
                                <VideoControlPanel
                                    mode={getVideoControlMode()}
                                    lesson={lesson}
                                    from="staff"
                                    name={lesson?.staff?.staff_name}
                                    client_id={lesson?.staff?.staff_id}
                                    onStart={videoOnStart}
                                    onSetLessonStatus={handleSetLessonStatus}
                                />
                                {/* </VideoStateProvider> */}
                            </Box>
                        </Paper>
                        {lesson?.lesson_type === LessonType.individual ? (
                            <Paper className={classes.right_view}>
                                {/* <TitleBar label="あなたの映像" /> */}
                                <Typography variant="h5" className={classes.header_title}>
                                    オンライン指導
                                </Typography>
                                <Paper className={classes.video_on_right}>
                                    <Box className={classes.videochat}>
                                        {/* <CameraView camera={cameraState.enable_camera} audio={cameraState.enable_mic} /> */}
                                        <VideoChat lesson_id={lesson?.lesson_id} />{' '}
                                    </Box>
                                    {/* <DeviceControlPanel/> */}
                                </Paper>
                                <Box className={classes.button_area}>
                                    <CloseButton className={classes.return} onClick={handleClose}>
                                        戻る
                                    </CloseButton>
                                </Box>
                            </Paper>
                        ) : (
                            <Paper className={classes.right_view}>
                                <Typography variant="h5" className={classes.header_title}>
                                    ユーザリスト
                                </Typography>
                                <Paper className={classes.video_on_right}>
                                    <Box className={classes.videochat}>
                                        <React.Fragment>
                                            <Grid container xs={12} className={classes.wrap_container}>
                                                <UserSelectCell
                                                    users={lesson?.users}
                                                    selectedIndex={selectedIndex}
                                                    onClick={(index: number) => {
                                                        const user = lesson?.users === undefined ? undefined : lesson?.users[index]
                                                        console.log(`選択:${index}`)
                                                        setSelectedIndex(index)
                                                        setLessonUser(user)
                                                        const items: MSPA[] = makeLocalState(lesson, user?.user_id)
                                                        setRecords(items)
                                                        updateExam(user)
                                                        updateLessonComment(user)
                                                    }}
                                                />
                                            </Grid>
                                        </React.Fragment>
                                    </Box>
                                </Paper>
                                <Box className={classes.button_area}>
                                    {isStart && lesson?.status !== LessonStatus.finish ? (
                                        <CloseButton className={classes.return} onClick={handleTerminate}>
                                            終了
                                        </CloseButton>
                                    ) : (
                                        <CloseButton className={classes.return} onClick={handleClose}>
                                            戻る
                                        </CloseButton>
                                    )}
                                </Box>
                            </Paper>
                        )}
                    </Box>
                </Grid>

                <Grid item xs={12} className={classes.userLesson}>
                    <TitleBar bgBlack label="テスト" />
                    <TableContainer className={classes.listUserLesson}>
                        <Table aria-label="customized table">
                            <TableHead>
                                <LightStripedTableRow>
                                    <LightStripedTableCell width="10%" align="center">
                                        時間
                                    </LightStripedTableCell>
                                    <LightStripedTableCell width="40%" align="center">
                                        設問
                                    </LightStripedTableCell>
                                    <LightStripedTableCell width="10%" align="center">
                                        正解
                                    </LightStripedTableCell>
                                    <LightStripedTableCell align="center">ユーザの回答</LightStripedTableCell>
                                </LightStripedTableRow>
                            </TableHead>
                            <TableBody>
                                {examinations.map((item, index) => {
                                    return <UserLessonCell key={index} data={item} onAnswerExamination={handleAnswerExamination} />
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

                <Grid item xs={12} className={classes.growth_record}>
                    <TitleBar bgBlue label="成長記録" />
                    {/* </Grid> */}

                    {/* ヘッダ */}
                    {/* <Grid item xs={12}> */}
                    <Box className={clsx(classes.box, classes.header)}>
                        <Box className={classes.checkbox}>
                            <Typography variant="subtitle1">MSPA評価指標</Typography>
                        </Box>
                        <Box className={classes.trend}>
                            <Typography variant="subtitle1">期待効果</Typography>
                        </Box>
                        <Box className={classes.comment}>
                            <Typography variant="subtitle1">コメント</Typography>
                        </Box>
                        <Box className={classes.achievement}>
                            <Typography variant="subtitle1">達成度(1-10)</Typography>
                        </Box>
                    </Box>
                    {/* </Grid>
                <Grid item xs={12}> */}
                    {records.map((row, index) => (
                        <MSPACell
                            key={index}
                            onChangeHandler={handleChange(index)}
                            row={row}
                            bgColor={index % 2 === 0 ? classes.boxBgColor1 : classes.boxBgColor2}
                        />
                    ))}
                </Grid>

                {/* Comment fields */}
                <Grid item xs={12} className={classes.lessonComment}>
                    <GreyLabelInputField
                        fullWidth
                        label="前回の課題"
                        multiline
                        name="comment_prev"
                        placeholder="Oculusには興味を示していて、頭につけたまま走り回ることがあった。動画が流れると集中していて、質問に対しての返答もしていた。動画が始まるまではほかのゲームをOculusでできるようにしておくと立ち歩く抑止効果につながり、本児のOculusへの興味を上手く活用できる。"
                        value={lessonComment.comment_prev}
                        onChange={handleCommentChange}
                    />
                    <GreyLabelInputField
                        fullWidth
                        label="受講状況"
                        multiline
                        name="comment_current"
                        placeholder="今回は、「会話中の距離感と声の大きさ」の受講をおこなった。Oculusへ興味があり早くやりたい様子で笑顔があふれていた。動画では、自分の伝えたい思いや聞いて欲しい気持ちが先行して声が大きくなってしまうことがある。Oculusの音量を変更して適切な音の大きさにしてから、同じくらいの声の大きさで伝えてみるように練習した。"
                        value={lessonComment.comment_current}
                        onChange={handleCommentChange}
                    />
                    <GreyLabelInputField
                        fullWidth
                        label="次回への課題"
                        multiline
                        name="comment_next"
                        placeholder="この動画を始める前には、声の大きさを確認してから始める。また、今回はOculusの音が大きかったので事前に確認をして調整をしておく。質問に関しては答えることがほとんどなく、事前にいくつかの質問があることを伝えておく。"
                        value={lessonComment.comment_next}
                        onChange={handleCommentChange}
                    />
                    {!!showCommentOfParent && (
                        <GreyLabelInputField
                            fullWidth
                            label="保護者からのコメント"
                            multiline
                            name="comment_of_parent"
                            value={lessonComment.comment_of_parent}
                            disabled
                        />
                    )}
                </Grid>
            </Grid>
            <Container component="main" maxWidth="xs" className={classes.footer}>
                <Footer></Footer>
            </Container>
        </React.Fragment>
    )

    function UserSelectCell(props: any) {
        const users = props.users === undefined || props.users.length === 0 ? null : props.users
        if (users === null) return null

        console.log('参加者', attendees)

        return users.map((user: any, index: number) => {
            const selected = index === props.selectedIndex
            let userName = user?.user_name === undefined ? '' : user?.user_name
            if (attendees.indexOf(user?.user_id) !== -1) {
                userName = userName + '※'
            }
            return (
                <Grid item xs={6}>
                    <Button
                        style={selected ? { background: '#eeeeff' } : { background: '#ffffff' }}
                        className={classes.wrap_cell}
                        onClick={(event: any) => {
                            console.log(`Click: ${index}`)
                            if (props.onClick !== undefined) {
                                props.onClick(index)
                            }
                        }}
                    >
                        <AvatarLabel fontBold={selected} title={userName} type="avatar" url={userIconUrl(user)} />
                    </Button>
                </Grid>
            )
        })
    }

    // function Pagination(props: any) {
    //     const { items } = usePagination({
    //         count: Math.min(5, props.users.length),
    //     })
    //     if (props.users === undefined || props.users.length === 0) {
    //         return null
    //     }
    //     return (
    //         <nav>
    //             <ul className={classes.pagination_ul}>
    //                 {items.map(({ page, type, selected, ...item }, index) => {
    //                     let children = null

    //                     if (type === 'start-ellipsis' || type === 'end-ellipsis') {
    //                         children = <div style={{ width: 100 }}>{'...'}</div>
    //                     } else if (type === 'page') {
    //                         children = (
    //                             <IconButton style={{ width: 100 }} aria-label="next" className={clsx(classes.navigate, classes.navigate_right)} {...item}>
    //                                 <Typography style={{ fontWeight: selected ? 'bold' : undefined }}>
    //                                     {props.users[page - 1] === undefined ? '' : props.users[page - 1].user_name}
    //                                 </Typography>
    //                             </IconButton>
    //                             // <button type="button" style={{ fontWeight: selected ? 'bold' : undefined }} {...item}>
    //                             //     { props.users[page-1] === undefined ? "" : props.users[page-1].user_name }
    //                             // </button>
    //                         )
    //                     } else if (type === 'previous') {
    //                         children = (
    //                             <IconButton aria-label="next" className={clsx(classes.navigate, classes.navigate_right)} {...item}>
    //                                 <NavigatePrevIcon fontSize="large" />
    //                             </IconButton>
    //                         )
    //                     } else if (type === 'next') {
    //                         children = (
    //                             <IconButton aria-label="next" className={clsx(classes.navigate, classes.navigate_right)} {...item}>
    //                                 <NavigateNextIcon fontSize="large" />
    //                             </IconButton>
    //                         )
    //                     } else {
    //                         children = (
    //                             <button type="button" {...item}>
    //                                 {type}
    //                             </button>
    //                         )
    //                     }

    //                     return <li key={index}>{children}</li>
    //                 })}
    //             </ul>
    //         </nav>
    //     )
    // }
    // Footer
    function Footer() {
        // if ( isUndefined( item ) ) {
        if (true) {
            return (
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        {/* 更新. */}
                        <CloseButton className={classes.submit_button} onClick={handleSubmit}>
                            登録
                        </CloseButton>
                    </Grid>
                    <Grid item xs={6}>
                        {/* 元に戻す. */}
                        <CloseButton className={classes.undo_button} onClick={handleReset}>
                            元に戻す
                        </CloseButton>
                    </Grid>
                </Grid>
            )
        }
    }
}
/**
 * make local state
 *
 * @param {(Lesson|undefined)} lesson
 * @returns {MSPA[]}
 */
function makeLocalState(lesson: Lesson | undefined, user_id: string | undefined): MSPA[] {
    const mspa_items = DataUtil.getMspaTable()

    if (user_id === undefined) {
        return []
    }
    if (lesson?.growth_records === undefined) {
        return []
    }
    if (lesson?.training?.mspa_expectations === undefined) {
        return []
    }
    let expectations: MspaExpectation[] = []
    expectations = lesson.training.mspa_expectations
    let expectation_ids = expectations.map((row) => row.mspa_evaluation_id)
    let growth_records: GrowthRecord[] = []
    growth_records = lesson.growth_records.filter((record) => {
        return record.user_id === user_id
    })
    let growth_ids = growth_records.map((row) => row.mspa_evaluation_id)

    const items: MSPA[] = mspa_items.map((row) => {
        const idx = expectation_ids.indexOf(row.mspa_evaluation_id)
        const expectation = idx < 0 ? 0 : expectations[idx].expectation
        const index = growth_ids.indexOf(row.mspa_evaluation_id)
        if (index >= 0) {
            // data exist
            const record = growth_records[index]
            return {
                ...row,
                enabled: true,
                comment: record.comment,
                achievement: record.achievement,
                expectation: expectation,
            }
        } else {
            return {
                ...row,
                enabled: false,
                comment: '',
                achievement: 1,
                expectation: expectation,
            }
        }
    })
    return items
}

// MSPA Cell
type MSPACellProps = {
    row: MSPA
    bgColor: string
    onChangeHandler: (value: MSPA) => void
}
function MSPACell(props: MSPACellProps) {
    const [state, setState] = React.useState<MSPA>(props.row)

    React.useEffect(() => {
        setState(props.row)
    }, [props.row])

    const classes = useStyles()

    const handleCheck = (event: any) => {
        const newState = { ...state, enabled: !state.enabled }
        setState(newState)
        props.onChangeHandler(newState)
    }
    const handleComment = (event: any) => {
        const newState = { ...state, comment: event.target.value }
        setState(newState)
        props.onChangeHandler(newState)
    }

    const handleAchievement = (event: React.ChangeEvent<any>) => {
        const newState = { ...state, achievement: event.target.value }
        setState(newState)
        props.onChangeHandler(newState)
    }

    return (
        <Box className={clsx(classes.box, props.bgColor)}>
            <Box className={classes.checkbox}>
                <FormControlLabel
                    control={<InputCheckBox checked={state.enabled} onChange={handleCheck} value="checkedA" />}
                    label={state.label}
                    // className={classes.checkbox}
                />
            </Box>
            <Box className={classes.trend}>
                <TrendMark trend={state.expectation} disabled={!state.enabled} className={classes.trendColor} />
            </Box>
            <TextField
                onChange={handleComment}
                disabled={!state.enabled}
                value={state.comment}
                className={classes.comment}
                InputProps={{
                    className: clsx(classes.textfield, state.enabled ? '' : classes.disabled),
                }}
            />
            <Box className={classes.achievement}>
                <Box className={classes.pulldown}>
                    <FormControl className={classes.form_control}>
                        <Select value={state.enabled ? state.achievement : '-'} disabled={!state.enabled} onChange={handleAchievement}>
                            {!state.enabled ? <MenuItem value="-">-</MenuItem> : null}
                            {selectBox.map((val, index) => (
                                <MenuItem key={index} value={val}>
                                    {'' + val}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </Box>
        </Box>
    )
}

type UserLessonCellProps = {
    data: Examination & { answer?: number }
    onAnswerExamination: (examinationId: string, answer: number) => void
}

function UserLessonCell(props: UserLessonCellProps) {
    const classes = useStyles()
    const [value, setValue] = React.useState(0)

    React.useEffect(() => {
        setValue(props.data.answer || 0)
    }, [props.data.answer])

    const handleAnswer = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!props.data.id) return
        const value = +(e.target as HTMLInputElement).value
        setValue(value)
        props.onAnswerExamination(props.data.id, value)
    }

    return (
        <LightStripedTableRow>
            <LightStripedTableCell className={classes.cell}>{props.data.time}</LightStripedTableCell>
            <LightStripedTableCell className={classes.cell}>{props.data.question}</LightStripedTableCell>
            <LightStripedTableCell className={classes.cell}>{props.data.correct}</LightStripedTableCell>
            <LightStripedTableCell className={classes.cell}>
                <FormControl className={classes.radioForm} component="fieldset">
                    <RadioGroup aria-label="response" name="response" value={value} onChange={handleAnswer}>
                        {props.data.options.map((option, index) => {
                            return <FormControlLabel key={index} className={classes.radio} value={option.value} control={<Radio />} label={option.label} />
                        })}
                    </RadioGroup>
                </FormControl>
            </LightStripedTableCell>
        </LightStripedTableRow>
    )
}
