import React from 'react'
import 'aframe'
import { makeStyles } from '@material-ui/core/styles'
import Hls from 'hls.js'
import { CircularProgress, Typography } from '@material-ui/core'
import { setLessonMessage, useLessonMessage } from 'modules/LessonModule'
import { useAppDispatch } from 'store'
interface Video360Props {
    url?: string
    height?: string
    message?: string
}

const useStyles = makeStyles((theme) => ({
    aframe: {
        border: '2px #e0e0e0 solid',
        backgroundColor: '#c0c0c0',
        width: '100%',
        // height: '240px',
        '& .a-loader-title': {
            display: 'none !important',
        },
    },
    relative: {
        position: 'relative',
    },
    absolute: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        backgroundColor: 'rgba(0,0,0,0.3)',
        zIndex: 1000,
    },
    spinner: {
        position: 'absolute',
        color: '#80ffff',
        top: '50%',
        left: '50%',
        transform: `translate(-50%,-50%)`,
        zIndex: 1001,
    },
    message: {
        border: '1px solid white',
        background: '#000040',
        paddingTop: 15,
        paddingBottom: 15,
        position: 'absolute',
        color: '#ffff80',
        left: 20,
        right: 20,
        textAlign: 'center',
        top: '50%',
        transform: `translate(0,-50%)`,
        zIndex: 1001,
    },
}))

export default function Video360(props: Video360Props) {
    const dispatch = useAppDispatch()
    const [busy, setBusy] = React.useState<boolean>(false)

    // const message = React.useState<string|undefined>(useLessonMessage())
    const message = useLessonMessage()

    const classes = useStyles()
    const HTML = `
    <style>
        a-scene {
            height: 100%;
            width: 100%;
        }
    </style>
    <a-scene embedded vr-mode-ui="enabled: false" background="color:black">
        <a-videosphere id="videosphere" rotation="0 270 0" src="#video"></a-videosphere>
        <a-assets>
            <video id="video"  style="display:none;" autoload preload="auto" crossorigin="anonymous" playsinline webkit-playsinline>
            </video>
        </a-assets>
    </a-scene>
  `
    function createMarkup() {
        const url = props.url === undefined ? '' : props.url
        return {
            __html: HTML.replace('#####', url).replace('#####', url),
        }
    }
    // ビデオリソースの設定.
    React.useEffect(() => {
        var video = document.getElementById('video') as HTMLMediaElement
        if (video === null || props.url === undefined) {
            return
        }
        var videoSrc = props.url

        // 拡張子 mp4 のときは、そのまま設定
        if (videoSrc.indexOf('.mp4') !== -1) {
            video.src = videoSrc
            return
        }
        // HLS 対応.
        if (video.canPlayType('application/vnd.apple.mpegurl')) {
            // HLS 対応ブラウザ(Safari, Edge..)
            console.log('#### can play: application/vnd.apple.mpegurl')
            video.src = videoSrc
            return
        }
        // Chrome / Firefox では hls.js を利用.
        if (Hls.isSupported()) {
            // カスタムローダ用のConfig. Credential 等を設定する
            // var config = {
            //     xhrSetup: function (xhr, url) {
            //         xhr.withCredentials = true // do send cookie
            //         xhr.setRequestHeader('Authorization', 'hogehoge') // 認証トークンなど
            //         xhr.setRequestHeader('Cookie', signedCookie ); // 署名付きCookie
            //     },
            // }
            // var hls = new Hls(config)

            console.log('#### use hls.js')

            var hls = new Hls()

            hls.loadSource(videoSrc)
            hls.attachMedia(video)
        }
        video.onplay = function (event: any) {
            console.log('video:play')
        }
        video.onplaying = function (event: any) {
            console.log('video:playing')
            // if (message !== undefined) {
            console.log('clear message')
            dispatch(setLessonMessage(undefined))
            // }
        }
        video.onseeking = function (event: any) {
            setBusy(true)
        }
        video.onseeked = function (event: any) {
            setBusy(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.url])

    return (
        <div className={classes.relative}>
            <div style={{ height: 300, opacity: 1.0 }} className={classes.aframe} dangerouslySetInnerHTML={createMarkup()} />
            {busy ? (
                <div className={classes.absolute}>
                    <div className={classes.spinner}>
                        <CircularProgress size={75} style={{ color: 'white' }} />
                    </div>
                </div>
            ) : (
                ''
            )}
            {message ? (
                <div className={classes.absolute}>
                    <div className={classes.message}>
                        <Typography variant="h4">{message}</Typography>
                    </div>
                </div>
            ) : (
                ''
            )}
        </div>
    )
}
