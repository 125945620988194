// TODO: 職員スケジュールとの整合性確認を詳細に検討

// import * as Const from '../Const'
// import { trace } from '../common/index'
import React from 'react'
import clsx from 'clsx'
import { isUndefined, isNull } from 'util'

// Redux & Reducer Modules
import { useAppDispatch } from 'store'
import { useFacility, usePortal, useProfile } from '../modules/AppModule'
import { createLesson, updateLesson, LessonParam } from '../modules/LessonModule'
import { fetchCoursesByFacilityIdWithUserId } from '../modules/CourseModule'
import { fetchStaffByFacilityId } from '../modules/StaffModule'

// for validation
import { useForm, Controller } from 'react-hook-form'

// functional
import * as DateUtil from 'date-fns'

// Material-UI
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

import { Container, Grid, MenuItem, Typography, Box } from '@material-ui/core'

// User
import * as DataUtil from '../modules/DataUtil'

import { OptionValue, Lesson, Course, Training, Staff, User, LessonStatus, LessonType } from '../Types'
import { MainButton } from '../components/Buttons'
import { InputTextField } from './Inputs'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import './reactdatepicker.css'
import { useParams } from 'react-router-dom'
import { getImageCacheContext } from 'modules/ImageCacheProvider'

//----------------------------------------------------------------------------------------
// スタイルを適用する
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            margin: '0 auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: 480,
        },
        form: {
            width: '100%', // Fix IE 11 issue.
            marginTop: theme.spacing(4),
        },
        footer: {
            // backgroundColor: "#ff0000",
            margin: theme.spacing(3, 0, 2), // 上下マージン.
            padding: theme.spacing(1, 0, 1, 0), // パディング
        },
        menuItem: {
            display: 'flex',
            justifyContent: 'space-between',
            minHeight: 32,
            padding: '8px 30px 8px 12px',
            borderBottom: '1px solid #B6B6B6',
            '&:last-child': {
                borderBottom: 'none',
            },
        },
        menuList: {
            '& .MuiPaper-rounded': {
                borderRadius: 'unset',
            },
            '& .MuiMenu-list': {
                border: '1px solid #B6B6B6',
                borderRadius: 'unset',
                width: 480,
            },
            '& .MuiList-padding': {
                padding: 0,
            },
        },
        input: {
            marginBottom: theme.spacing(2),
            '& .MuiSelect-select': {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                minHeight: 32,
                boxSizing: 'border-box',
                padding: '8px 30px 8px 12px',
                borderColor: '#B6B6B6',
            },
            '& .MuiSelect-icon': {
                boxShadow: 'none !important',
                position: 'absolute',
                top: 12,
            },
            '& .MuiSelect-iconOpen': {
                transform: 'none',
                zIndex: 9999,
            },
            '& .MuiInputLabel-root': {
                display: 'block',
                color: '#05A9C2',
                '&.Mui-error': {
                    color: '#f44336',
                },
                '&::before': {
                    content: '""',
                    display: 'block',
                    backgroundColor: '#05A9C2',
                    width: 22,
                    height: 22,
                    float: 'left',
                    borderRadius: '50%',
                    margin: '-2px 16px 0 0',
                },
            },
        },
        box: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        picker: {
            padding: 10,
            border: '1px solid #B6B6B6',
        },
        datePicker: {
            width: 250,
        },
        timePicker: {
            width: 100,
        },
        headerCalendar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginLeft: 10,
            marginRight: 10,
        },
        buttonCalendar: {
            border: 'none',
            backgroundColor: 'transparent',
            '&:focus': {
                border: 'none',
            },
        },
        label: {
            color: '#05A9C2',
            '&::before': {
                content: '""',
                display: 'block',
                backgroundColor: '#05A9C2',
                width: 16,
                height: 16,
                float: 'left',
                borderRadius: '50%',
                margin: '2px 11px 0 0',
            },
        },
        trainingName: {
            width: 300,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    }),
)
// date-fns
// 日付の書式.
// function formatDate(date: Date): string {
//     return DateUtil.format(new Date(), 'yyyy-MM-dd')
// }
// // 時刻の書式
// function formatTime(date: Date): string {
//     return DateUtil.format(new Date(), 'HH:mm')
// }

type LocalState = {
    courses: Course[]
    courseOptions: OptionValue[]
    trainingOptions: OptionValue[]
    staffOptions: OptionValue[]
}

type FormState = {
    lesson_id: string
    course_id: string
    training_id: string
    staff_id: string
    user_id: string
}

type ReservationEditProps = {
    onClickHandler?: (log: Lesson | undefined) => void
    onCloseHandler?: () => void
    onAfterAddOrEditHandler: () => void
    recordForEdit: Lesson | null
}

type FooterProps = {
    isSubmitting: boolean
    onCloseHandler?: () => void
}
function Footer(props: FooterProps) {
    const handleClose = () => {
        if (props.onCloseHandler !== undefined) {
            props.onCloseHandler()
        }
    }
    return (
        <Grid container spacing={1}>
            <Grid item xs={6}>
                {/* 更新. */}
                <MainButton type="submit" fullWidth disabled={props.isSubmitting}>
                    登録
                </MainButton>
            </Grid>
            <Grid item xs={6}>
                {/* 閉じる. */}
                <MainButton fullWidth onClick={handleClose}>
                    閉じる
                </MainButton>
            </Grid>
        </Grid>
    )
}
//----------------------------------------------------------------------------------------
export default function ReservationEdit(props: ReservationEditProps) {
    const { recordForEdit, onAfterAddOrEditHandler, onCloseHandler } = props
    const isAddMode = !recordForEdit
    const imageCache = React.useContext(getImageCacheContext())
    const classes = useStyles()
    const dispatch = useAppDispatch()
    const portal = usePortal()
    const profile = useProfile()
    const facility = useFacility()
    const { userId } = useParams()

    const [selectedDate, setSelectedDate] = React.useState<Date>(() => new Date(new Date().setHours(10, 0, 0, 0)))
    const [state, setState] = React.useState<LocalState>({
        courses: [],
        courseOptions: [],
        trainingOptions: [],
        staffOptions: [],
    })

    // form validation
    const {
        register,
        handleSubmit,
        errors,
        formState: { isSubmitting },
        control,
        reset,
        setValue,
    } = useForm<FormState>({
        defaultValues: {
            lesson_id: '',
            course_id: '',
            training_id: '',
            staff_id: '',
            user_id: '',
        },
    })

    React.useEffect(() => {
        if (recordForEdit !== null) {
            // Edit mode
            const trainingId = getLessonTrainingId(recordForEdit)
            setValue('lesson_id', getLessonId(recordForEdit))
            setValue('course_id', getLessonCourseId(recordForEdit))
            setValue('training_id', trainingId)
            setValue('staff_id', getLessonStaffId(recordForEdit))
            setValue('user_id', getLessonUserId(recordForEdit))
            setSelectedDate(new Date(getLessonStart(recordForEdit)))
            setup(recordForEdit)
        } else {
            setup()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recordForEdit])

    let currentUserId = ''

    if (isAddMode) {
        if (portal === 'staff') {
            let staffProfile = profile as Staff
            let staffId = isUndefined(staffProfile) || isUndefined(staffProfile.staff_id) ? '' : staffProfile.staff_id
            setValue('staff_id', staffId)
            setValue('user_id', userId)
            currentUserId = userId
        }
        if (portal === 'user') {
            let userProfile = profile as User
            let userId = isUndefined(userProfile) || isUndefined(userProfile.user_id) ? '' : userProfile.user_id
            setValue('user_id', userId)
            currentUserId = userId
        }
    }

    async function setup(lesson?: Lesson) {
        try {
            const facility_id = isUndefined(facility) || isUndefined(facility.facility_id) ? '' : facility.facility_id

            const user_id = isUndefined(lesson) || isUndefined(lesson.user) ? currentUserId : getUserId(lesson.user)

            console.log('## UserID:', user_id)
            console.log('## Lesson:', lesson)

            if (!facility_id || !user_id) return

            let resStaff
            let resCourses
            try {
                ;[resCourses, resStaff] = await Promise.all([
                    // コース情報の取得.（施設のみ)
                    dispatch(fetchCoursesByFacilityIdWithUserId({ facility_id: facility_id, user_id: user_id })),
                    // 施設職員情報の取得
                    dispatch(fetchStaffByFacilityId(facility_id)),
                ])
            } catch (err) {
                console.error(err)
                throw err // １つでもエラーになったら、関数を抜ける
            }
            console.log('############', resCourses, resStaff)

            imageCache.addStaffs(resStaff.payload)

            // 全てが正常データを取得できたとき、以下を実行
            const courses: Course[] = resCourses.payload
            const staff: Staff[] = resStaff.payload

            console.log(courses)
            console.log(staff)

            // 職員オプション.
            const staffOptions = staff.map((staff) => {
                return {
                    label: getStaffName(staff),
                    value: getStaffId(staff),
                }
            })
            // コースオプション.
            const courseOptions = courses.map((course) => {
                const trainingSum = course.trainings.length
                const finished = course.trainings.filter((training) => {
                    return training.option_user === undefined ? false : training.option_user.lesson_status === LessonStatus.finish
                }).length

                return {
                    label: getCourseName(course),
                    optionLabel: finished === 0 ? '未受講' : `${finished}/${trainingSum}受講済み`,
                    value: getCourseId(course),
                }
            })
            console.log('コースオプション', courseOptions)
            // トレーニングオプション
            const course_id = getLessonCourseId(lesson)
            const trainingOptions = getTrainingOptions(courses, course_id)
            // スタッフオプション.

            setState({
                ...state,
                courses: courses,
                staffOptions: staffOptions,
                courseOptions: courseOptions,
                trainingOptions: trainingOptions,
            })
        } catch (err) {
            return
        }
    }

    function getTrainingOptions(courses: Course[], course_id: string) {
        console.log('##### getTrainingOptions', course_id)
        // コース情報を検索.
        const course = courses.find((course) => course.course_id === course_id)
        // コースに属するトレーニング情報.(append TestTrainings)
        const trainings = [...getCourseTrainings(course), ...getCourseTestTrainings(course)]

        // オプションバリュー。
        const options: OptionValue[] = [...trainings].map((training) => {
            let option = ''
            if (training.option_user !== undefined) {
                option = DataUtil.getLessonStatusText(training.option_user.lesson_status as LessonStatus)
            }
            return {
                label: getTrainingName(training),
                value: getTrainingId(training),
                optionLabel: option,
            }
        })
        return options
    }

    const handleDateChange = (date: Date | null) => {
        if (isNull(date)) {
            console.log('date is null')
            date = new Date()
        } else {
            console.log('DATE', DateUtil.format(date, 'yyyy-MM-dd'))
        }
        setSelectedDate(date)
    }

    const handleTimeChange = (date: Date | null) => {
        if (isNull(date)) {
            console.log('time is null')
            date = new Date()
        } else {
            console.log('TIME:', DateUtil.format(date, 'HH:mm'))
        }
        setSelectedDate(date)
    }

    // 更新された コース選択、トレーニング選択.
    const handleCourseChange = (event: React.ChangeEvent<{ value: string }>) => {
        const course_id = event.target.value
        const options = getTrainingOptions(state.courses, course_id)
        // Reset value of training_id when course was changed
        setValue('training_id', '')
        setState({
            ...state,
            // course_id: course_id,
            trainingOptions: options,
        })
    }

    /**
     *　登録ボタンが押された時の処理.
     * react-hooks-from 処理後
     * select 系は material-ui の ref 対象とならないので、state から取得する.
     * @param {React.FormEvent} event
     * @returns
     */
    const onSubmit = async (data: FormState) => {
        console.log('=== 送信処理', data)

        const lessonStatus = portal === 'staff' ? LessonStatus.reserved : LessonStatus.request
        const param: LessonParam = {
            lesson_type: LessonType.individual,
            lesson_id: data.lesson_id,
            user_id: data.user_id,
            user_ids: undefined,
            // Use data of React-hook-form for handling validation
            course_id: data.course_id,
            staff_id: data.staff_id,
            training_id: data.training_id,

            start: DateUtil.format(selectedDate, 'yyyy-MM-dd HH:mm'),
            status: lessonStatus,
        }

        console.log('送信パラメータ', param)

        if (!param.lesson_id) {
            await dispatch(createLesson(param))
        } else {
            await dispatch(updateLesson(param))
        }
        reset()
        onAfterAddOrEditHandler()
    }

    function handleClose() {
        if (!isUndefined(onCloseHandler)) {
            onCloseHandler()
        }
    }

    return (
        <React.Fragment>
            <Container component="main">
                <div className={classes.paper}>
                    <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
                        <input type="hidden" name="lesson_id" ref={register} />
                        <input type="hidden" name="user_id" ref={register} />
                        {/* カリキュラム */}
                        <Controller
                            render={({ onChange, value }) => (
                                <InputTextField
                                    className={classes.input}
                                    margin="none"
                                    fullWidth
                                    required
                                    label="カリキュラム"
                                    value={value}
                                    onChange={(e: any) => {
                                        onChange(e.target.value)
                                        handleCourseChange(e)
                                    }}
                                    error={Boolean(errors.course_id)}
                                    helperText={errors.course_id && errors.course_id.message}
                                    select
                                    SelectProps={{
                                        MenuProps: {
                                            className: classes.menuList,
                                            getContentAnchorEl: null,
                                        },
                                    }}
                                >
                                    {state.courseOptions.map((option, index) => (
                                        <MenuItem key={index} value={option.value} className={classes.menuItem}>
                                            <Typography>{option.label}</Typography>
                                            <Typography>{option.optionLabel}</Typography>
                                        </MenuItem>
                                    ))}
                                </InputTextField>
                            )}
                            name="course_id"
                            rules={{ required: 'カリキュラムは必須です' }}
                            control={control}
                        />
                        {/* トレーニング. */}
                        <Controller
                            as={
                                <InputTextField
                                    className={classes.input}
                                    margin="none"
                                    fullWidth
                                    required
                                    label="トレーニング"
                                    error={Boolean(errors.training_id)}
                                    helperText={errors.training_id && errors.training_id.message}
                                    select
                                    SelectProps={{
                                        MenuProps: {
                                            className: classes.menuList,
                                            getContentAnchorEl: null,
                                        },
                                    }}
                                >
                                    {state.trainingOptions.map((option, index) => (
                                        <MenuItem key={index} value={option.value} className={classes.menuItem}>
                                            <Typography className={classes.trainingName}>{option.label}</Typography>
                                            <Typography>{option.optionLabel}</Typography>
                                        </MenuItem>
                                    ))}
                                </InputTextField>
                            }
                            name="training_id"
                            rules={{ required: 'トレーニングは必須です' }}
                            control={control}
                        />
                        {/* スタッフ. */}
                        {portal === 'user' ? (
                            <Controller
                                as={
                                    <InputTextField
                                        className={classes.input}
                                        margin="none"
                                        fullWidth
                                        required
                                        label="支援スタッフ"
                                        error={Boolean(errors.staff_id)}
                                        helperText={errors.staff_id && errors.staff_id.message}
                                        select
                                        SelectProps={{
                                            MenuProps: {
                                                className: classes.menuList,
                                                getContentAnchorEl: null,
                                            },
                                        }}
                                    >
                                        {state.staffOptions.map((option, index) => (
                                            <MenuItem key={index} value={option.value} className={classes.menuItem}>
                                                <Typography>{option.label}</Typography>
                                            </MenuItem>
                                        ))}
                                    </InputTextField>
                                }
                                name="staff_id"
                                rules={{ required: '支援スタッフは必須です' }}
                                control={control}
                            />
                        ) : (
                            <input type="hidden" name="staff_id" ref={register} />
                        )}

                        <Box>
                            <Typography className={classes.label}>予約日時</Typography>
                            <Box display="flex" marginTop="5px">
                                <Box marginRight="20px">
                                    <DatePicker
                                        id="date"
                                        name="date"
                                        className={clsx(classes.picker, classes.datePicker)}
                                        selected={selectedDate}
                                        onChange={handleDateChange}
                                        locale="ja"
                                        dateFormat="yyyy/MM/dd"
                                        dateFormatCalendar={'yyyy年 MMM'}
                                        renderCustomHeader={({
                                            date,
                                            decreaseMonth,
                                            increaseMonth,
                                            prevMonthButtonDisabled,
                                            nextMonthButtonDisabled,
                                            decreaseYear,
                                            increaseYear,
                                            prevYearButtonDisabled,
                                            nextYearButtonDisabled,
                                        }) => (
                                            <div className={classes.headerCalendar}>
                                                <Box>
                                                    <button
                                                        type="button"
                                                        onClick={decreaseYear}
                                                        disabled={prevYearButtonDisabled}
                                                        className={classes.buttonCalendar}
                                                    >
                                                        «
                                                    </button>
                                                    <button
                                                        type="button"
                                                        onClick={decreaseMonth}
                                                        disabled={prevMonthButtonDisabled}
                                                        className={classes.buttonCalendar}
                                                    >
                                                        ‹
                                                    </button>
                                                </Box>
                                                {DateUtil.getYear(date)}年{DateUtil.getMonth(date) + 1}月
                                                <Box>
                                                    <button
                                                        type="button"
                                                        onClick={increaseMonth}
                                                        disabled={nextMonthButtonDisabled}
                                                        className={classes.buttonCalendar}
                                                    >
                                                        ›
                                                    </button>
                                                    <button
                                                        type="button"
                                                        onClick={increaseYear}
                                                        disabled={nextYearButtonDisabled}
                                                        className={classes.buttonCalendar}
                                                    >
                                                        »
                                                    </button>
                                                </Box>
                                            </div>
                                        )}
                                        popperPlacement="none"
                                    />
                                </Box>
                                <Box>
                                    <DatePicker
                                        id="time"
                                        name="time"
                                        className={clsx(classes.picker, classes.timePicker)}
                                        selected={selectedDate}
                                        onChange={handleTimeChange}
                                        locale="ja"
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={30}
                                        dateFormat="HH:mm"
                                        timeFormat="HH:mm"
                                        popperPlacement="none"
                                    />
                                </Box>
                            </Box>
                        </Box>

                        <div className={classes.footer}>
                            <Footer onCloseHandler={handleClose} isSubmitting={isSubmitting} />
                        </div>
                    </form>
                </div>
            </Container>
        </React.Fragment>
    )
}

//----------------------------------------------------------------------------------------
function getCourseId(course: Course): string {
    return isUndefined(course) || isUndefined(course.course_id) ? '' : course.course_id
}
function getCourseTrainings(course: Course | undefined): Training[] {
    return isUndefined(course) || isUndefined(course.trainings) ? [] : course.trainings
}
function getCourseTestTrainings(course: Course | undefined): Training[] {
    return isUndefined(course) || isUndefined(course.test_trainings) ? [] : course.test_trainings
}

function getTrainingId(training: Training): string {
    return isUndefined(training) || isUndefined(training.training_id) ? '' : training.training_id
}
function getStaffId(staff: Staff): string {
    return isUndefined(staff) || isUndefined(staff.staff_id) ? '' : staff.staff_id
}
function getLessonId(lesson: Lesson): string {
    return isUndefined(lesson) || isUndefined(lesson.lesson_id) ? '' : lesson.lesson_id
}
function getLessonStart(lesson: Lesson): string {
    return isUndefined(lesson) || isUndefined(lesson.start) ? '' : lesson.start
}
function getCourseName(course: Course): string {
    return isUndefined(course) || isUndefined(course.course_name) ? '' : course.course_name
}
function getTrainingName(training: Training): string {
    return isUndefined(training) || isUndefined(training.training_name) ? '' : training.training_name
}
function getStaffName(staff: Staff): string {
    return isUndefined(staff) || isUndefined(staff.staff_name) ? '' : staff.staff_name
}

function getUserId(user: User | undefined): string {
    return isUndefined(user) || isUndefined(user.user_id) ? '' : user.user_id
}
function getLessonUserId(lesson: Lesson | undefined): string {
    return isUndefined(lesson) || isUndefined(lesson.user) ? '' : getUserId(lesson.user)
}

function getLessonCourseId(lesson: Lesson | undefined): string {
    return isUndefined(lesson) || isUndefined(lesson.course) ? '' : getCourseId(lesson.course)
}
// function getLessonCourseName(lesson: Lesson): string {
//     return isUndefined(lesson) || isUndefined(lesson.course)
//         ? ''
//         : getCourseName(lesson.course)
// }
function getLessonTrainingId(lesson: Lesson): string {
    return isUndefined(lesson) || isUndefined(lesson.training) ? '' : getTrainingId(lesson.training)
}
// function getLessonTrainingName(lesson: Lesson): string {
//     return isUndefined(lesson) || isUndefined(lesson.training)
//         ? ''
//         : getTrainingName(lesson.training)
// }
function getLessonStaffId(lesson: Lesson): string {
    return isUndefined(lesson) || isUndefined(lesson.staff) ? '' : getStaffId(lesson.staff)
}
// function getLessonStaffName(lesson: Lesson): string {
//     return isUndefined(lesson) || isUndefined(lesson.staff)
//         ? ''
//         : getStaffName(lesson.staff)
// }
// function getLessonStatus(lesson: Lesson): string {
//     return isUndefined(lesson) || isUndefined(lesson.status)
//         ? undefined
//         : lesson.status
// }
