// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import { useEffect } from 'react'
// import { useHistory } from 'react-router-dom';

import { MeetingStatus, useNotificationDispatch, Severity, ActionType, useMeetingStatus } from 'amazon-chime-sdk-component-library-react'
// import routes from '../constants/routes';
import { useAppDispatch } from 'store'
import { endChat } from 'modules/ChatModule'

const useMeetingEndRedirect = () => {
    const appDispatch = useAppDispatch()
    // const history = useHistory();
    const dispatch = useNotificationDispatch()
    const meetingStatus = useMeetingStatus()

    useEffect(() => {
        if (meetingStatus === MeetingStatus.Ended) {
            console.log('[useMeetingEndRedirect] Meeting ended')
            dispatch({
                type: ActionType.ADD,
                payload: {
                    severity: Severity.INFO,
                    message: 'The meeting was ended by another attendee',
                    autoClose: true,
                    replaceAll: true,
                },
            })

            // history.push(routes.HOME);
            appDispatch(endChat(null))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [meetingStatus])
}

export default useMeetingEndRedirect
