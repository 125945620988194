import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Grid, Paper } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            border: 'unset',
            alignItems: 'center',
            justifyContent: 'center',
        },
        container: {
            minHeight: 40,
            color: '#ffffff',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            textAlign: 'center',
        },
        itemLeft: {
            flexGrow: 1,
        },
    }),
)

type SearchBarProps = {
    left?: JSX.Element
    right?: JSX.Element
}

export default function SearchBar(props: SearchBarProps) {
    const classes = useStyles()

    return (
        <Paper variant="outlined" square elevation={0} className={classes.root}>
            <Grid container className={classes.container}>
                <Grid item className={classes.itemLeft}>
                    {props.left !== undefined ? props.left : null}
                </Grid>
                <Grid item>{props.right !== undefined ? props.right : null}</Grid>
            </Grid>
        </Paper>
    )
}
