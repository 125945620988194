// import * as Const from '../Const'
// import {trace} from '../common/index'
import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Typography, TableHead } from '@material-ui/core'
import { Admin } from '../Types'
import { StripedTableRow, StripedTableCell } from './Tables'
import { formatDateTime } from '../modules/Util'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        row: {
            '&:hover': {
                cursor: 'pointer',
            },
        },
    }),
)

/**
 * Props
 *
 * @interface AdminCellProps
 */
interface AdminCellProps {
    data: Admin
    onClickHandler?: (event: React.MouseEvent<unknown, MouseEvent>) => void
}

/**
 * 管理者セル.
 * @export
 * @param {AdminCellProps} props
 * @returns セルのJSX 要素
 */
export function AdminCell(props: AdminCellProps) {
    const classes = useStyles()
    const handleClick = (event: React.MouseEvent<unknown, MouseEvent>) => {
        if (props.onClickHandler !== undefined) {
            props.onClickHandler(event)
        }
    }
    return (
        <StripedTableRow className={classes.row} onClick={(event) => handleClick(event)}>
            <StripedTableCell align="center">
                <Typography variant="h5">{props.data.admin_id}</Typography>
            </StripedTableCell>
            <StripedTableCell align="center">
                <Typography variant="h5">{formatDateTime(props.data.created_at)}</Typography>
            </StripedTableCell>
            <StripedTableCell align="center">
                <Typography variant="h5">{formatDateTime(props.data.updated_at)}</Typography>
            </StripedTableCell>
        </StripedTableRow>
    )
}

/**
 * 管理者セルヘッダ
 *
 * @export
 * @returns
 */
export function AdminHeaderCell() {
    //   const handleClick = (event: React.MouseEvent<unknown, MouseEvent>) => {
    //     props.onClickHandler(event);
    //   };
    return (
        <TableHead>
            <StripedTableRow>
                <StripedTableCell width="40%" align="center">
                    管理者ID
                </StripedTableCell>
                <StripedTableCell width="30%" align="center">
                    作成日時
                </StripedTableCell>
                <StripedTableCell width="30%" align="center">
                    更新日時
                </StripedTableCell>
            </StripedTableRow>
        </TableHead>
    )
}
