// ### Import
import * as Const from '../Const'
import { isUndefined, isNullOrUndefined } from 'util'

import React from 'react'

// import DateFnsUtils from '@date-io/date-fns'
import * as DateUtil from 'date-fns'
// import jaLocale from 'date-fns/locale/ja'
// import format from 'date-fns/format'

import { useAppDispatch } from '../store'
import { createUser, updateUserById, deleteUser, useUserById, useIsMaxCreatedUser } from '../modules/UserModule'
import { useFacility, usePortal, useProfile, setProfile, useHomePageUrl } from '../modules/AppModule'
import * as ImageUploader from '../modules/ImageUploader'

// for validation
import { useForm } from 'react-hook-form'

// Material-UI
import { Grid, Box, Typography, FormControl, FormControlLabel, FormLabel, RadioGroup, MenuItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
// import {
// MuiPickersUtilsProvider,
// KeyboardTimePicker,
// KeyboardDatePicker,
// } from '@material-ui/pickers'

//### Import MyClass
import { User, Gender, OriginalField } from '../Types'
import * as DataUtil from '../modules/DataUtil'

import StyledRadio from '../components/StyledRadio'
import { DeleteButton, MainButton } from '../components/Buttons'
import { InputTextField, SelectTextField } from './Inputs'
import PreviewInput2 from './PreviewInput2'
import MainDatePicker from './MainDatePicker'
import { useParams, useRouteMatch } from 'react-router-dom'
import { getSignedUrl } from 'modules/ImageModule'
import { getImageCacheContext } from 'modules/ImageCacheProvider'
import PasswordField from './form-controls/PasswordField'
import { isNotEmptyString } from 'modules/Util'

// スタイル定義.
const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(0),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    header: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        padding: theme.spacing(2, 0, 2),
    },
    title: {
        fontWeight: 'bold',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    submit: {
        fontSize: 'calc(20px)', // default: 16px
        margin: theme.spacing(3, 0, 2), // 上下マージン.
        padding: theme.spacing(1, 0, 1, 0), // パディング
    },
    margin: {
        marginTop: theme.spacing(2),
    },
    withoutLabel: {
        marginTop: theme.spacing(3),
    },
    textField: {
        width: '100%', // Fix IE 11 issue.
    },
    // Warning
    warning: {
        color: '#ff0000',
        marginTop: theme.spacing(2),
    },

    levelbar: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        justifyContent: 'center',
        // paddingTop: theme.spacing(2),
        height: 'calc(50px)',
        backgroundColor: 'transparent',
        borderColor: theme.palette.grey[400],
        borderWidth: '1px',
        '&:hover': {
            borderColor: 'black', //`${theme.palette.primary.main} !important`,
            borderWidth: '1px',
        },
        '&$focused': {
            borderColor: `${theme.palette.primary.main} !important`,
            borderWidth: '2px',
        },
        display: 'flex',
        flexDirection: 'column',
    },
    labelName: {
        paddingLeft: 'calc(8px)',
        transform: `translate(-15px,-15px) scale(0.8)`,
        backgroundColor: theme.palette.background.default,
        color: theme.palette.grey[500],

        width: '60px',
    },
    form: {
        width: 730,
        display: 'flex',
        justifyContent: 'space-between',
        margin: '0 auto',
    },
    container: {
        width: 960,
        margin: '45px auto 150px',
    },
    left: {
        width: 480,
    },
    right: {
        width: 205,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    input: {
        marginBottom: 12,
        '& > label': {
            color: '#05A9C2',
        },
        '& input, .MuiSelect-select': {
            borderColor: '#B6B6B6',
        },
    },
    menuList: {
        width: 480,
    },
    btnGenerate: {
        border: 'unset',
    },
    btnDelete: {
        background: '#D02927',
        color: '#FFFFFF',
        border: 'unset',
        '&:hover': {
            background: '#D02927',
        },
    },
    label: {
        color: '#05A9C2',
        marginBottom: 6,
    },
    cell: {
        display: 'flex',
        margin: '0 -5px 12px 0',
    },
    item: {
        marginRight: 10,
        display: 'flex',
        alignItems: 'flex-end',
    },
    select: {
        width: 190,
        marginRight: 10,
        '& > label': {
            color: '#05A9C2',
        },
        '& .MuiInputBase-input': {
            borderColor: '#B6B6B6',
            padding: '12px 12px 8px',
        },
    },
    staffUserInfo: {
        padding: '35px 0 23px',
    },
}))

// class ExtendedDateFnsUtils extends DateFnsUtils {
//     getCalendarHeaderText(date: Date) {
//         return format(date, 'yyyy年 MMM', { locale: this.locale })
//     }
//     getDatePickerHeaderText(date: Date) {
//         return format(date, 'MMMd日', { locale: this.locale })
//     }
// }

type LocalState = {
    showPassword: boolean // パスワードを表示するか.
    password2?: string
}
type UserEditProps = {
    onClickHandler?: (item?: User) => void
    onCloseHandler?: () => void
    item?: User
}
// 編集タイプ定義
enum EditType {
    new = 'new',
    edit = 'edit',
}

/**
/**
 * 編集.
 *
 * @returns
 */
export default function UserEdit(props: UserEditProps) {
    const imageCache = React.useContext(getImageCacheContext())

    const facility = useFacility()
    const portal = usePortal()
    const facility_id = isUndefined(facility) || isUndefined(facility.facility_id) ? '' : facility.facility_id

    const classes = useStyles()
    const dispatch = useAppDispatch()
    const form = useForm<User & LocalState & OriginalField>()
    const { register, handleSubmit, errors, watch, getValues } = form

    const homePageUrl = useHomePageUrl()
    const match = useRouteMatch()
    const currentPath = match.url
    let item: User | undefined = undefined
    const { userId } = useParams()
    let editedUser = useUserById(userId)
    if (!isUndefined(editedUser)) {
        editedUser = { ...editedUser, icon: imageCache.getUserImage(editedUser) } as User
    }
    // redirect to list page if id isn't loaded
    if (userId && isUndefined(editedUser)) {
        handleClose()
    }

    const userProfile = useProfile() as User
    if (portal === 'user' && currentPath === `${homePageUrl}/profile`) {
        item = userProfile
    } else {
        item = editedUser
    }
    const editType = isUndefined(item) ? EditType.new : EditType.edit

    const isMaxCreatedUser = useIsMaxCreatedUser()
    if (isUndefined(item)) {
        // Create user setting page
        if (portal === 'staff' && currentPath === `${homePageUrl}/users/create` && isMaxCreatedUser) {
            handleClose()
        }
        item = DataUtil.initUser() as User
    }

    // console.log('### USER', item)

    const [values, setValues] = React.useState<User>({ ...item, password: '' })
    const [iconFiles, setIconFiles] = React.useState<FileList | null>()
    const profile = useProfile() as User

    const [passwordValidation, setPasswordValidation] = React.useState<boolean>(editType === EditType.new ? true : false)
    const watchPassword = watch('password')

    React.useEffect(() => {
        if (editType === EditType.new) {
            return
        }
        if (watchPassword === '') {
            setPasswordValidation(false)
        } else {
            setPasswordValidation(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchPassword])

    // 内容変更.
    const handleChange = (prop: keyof User) => (event: React.ChangeEvent<HTMLInputElement> | null) => {
        console.log('changed' + prop + ':' + event?.target.value)
        const levelValue = +(event?.target?.value || 0)
        switch (prop) {
            case 'min_level':
                if (levelValue > values.max_level) {
                    setValues({ ...values, min_level: values.max_level, max_level: levelValue })
                } else {
                    setValues({ ...values, min_level: levelValue })
                }
                break
            case 'max_level':
                if (levelValue < values.min_level) {
                    setValues({ ...values, min_level: levelValue, max_level: values.min_level })
                } else {
                    setValues({ ...values, max_level: levelValue })
                }
                break
            case 'icon':
                if (event === null || event.target.files === null) {
                    setIconFiles(null)
                    setValues({ ...values, icon: '', icon_url: '' })
                } else {
                    setIconFiles(event.target.files)
                }
                break
            default:
                setValues({ ...values, [prop]: event === null ? null : event.target.value })
                break
        }
        console.log('changed:', values)
    }

    const onSubmit = (data: User & LocalState & OriginalField) => {
        console.log('=== 送信処理')

        if (editType === EditType.edit && data.password === '') {
            delete data.password
        }

        console.log('### data', data)
        console.log('### values', values)

        let user = { ...data }

        // console.log('ICON:', values?.icon)

        // 確認用パスワードを除去.
        delete user.password2
        user.birth_date = DateUtil.format(selectedDate, 'yyyy-MM-dd')

        // handicaps
        user.handicaps = values.handicaps
        user.gender_id = values.gender_id
        user.min_level = values.min_level
        user.max_level = values.max_level

        if (portal === 'user' && currentPath === `${homePageUrl}/profile`) {
            delete user.min_level
            delete user.max_level
        }

        // console.log('##### USER: ', user)

        const file = isNullOrUndefined(iconFiles) ? null : iconFiles[0]

        uploadImage(file)
            .then((res) => {
                user.icon_url = res
            })
            .catch((error) => {
                console.log('upload image error:', error)
                user.icon_url = values.icon_url
            })
            .finally(() => {
                if (values.user_id === undefined || values.user_id.length === 0) {
                    console.log('新規')
                    dispatch(createUser({ ...user, facility_id: facility_id })).then(() => {
                        handleClose()
                    })
                } else {
                    console.log('更新:')
                    dispatch(updateUserById({ ...user, original_id: data.original_id })).then(async (res: any) => {
                        let icon = values?.icon
                        let newUser = res.payload.item
                        if (newUser.user_id === profile.user_id) {
                            if (file !== null) {
                                try {
                                    icon = ((await ImageUploader.makeBinaryImage(file, true)) as unknown) as string
                                } catch (e) {
                                    console.log(e)
                                }
                            }
                            console.log('new profile')
                            dispatch(setProfile(newUser))

                            newUser = { ...newUser, icon: icon }
                            imageCache.addUsers([newUser])
                        }
                        handleClose()
                    })
                }
            })
    }

    // 画像のアップロード.
    function uploadImage(file: File | null | undefined): Promise<string> {
        return new Promise((resolve, reject) => {
            if (isNullOrUndefined(file)) {
                reject('no file')
                return
            }
            console.log(file)
            console.log('file.name', file.name)
            console.log('file.type', file.type)

            dispatch(getSignedUrl(file.type))
                .then((res) => {
                    console.log('$$$$ SignedURL', res)
                    const url = res.payload.url
                    const filename = res.payload.name
                    ImageUploader.upload(url, file)
                        .then((res) => {
                            console.log('SUCCESS', res, filename)
                            resolve(filename)
                        })
                        .catch((error) => {
                            console.log('ERROR', error)
                            reject(error)
                        })
                })
                .catch((error) => {
                    console.log('ERROR', error)
                    reject(error)
                })
        })
    }

    /**
     *　閉じるボタンが押された時の処理.
     * - バリデーション
     * - axios で POST
     * @returns
     */
    function handleClose() {
        if (props.onCloseHandler) {
            props.onCloseHandler()
        }
    }

    /** 削除 */
    function handleDelete() {
        console.log('##### DELETE #####')

        const user_id: string | undefined = getValues('user_id')
        console.log(user_id)
        if (user_id) {
            dispatch(deleteUser(user_id))
        }
        if (props.onClickHandler !== undefined) {
            props.onClickHandler(undefined)
        }
    }
    // Date Picker state
    const [selectedDate, setSelectedDate] = React.useState<Date>(item.birth_date ? new Date(item.birth_date) : new Date())
    const handleDateChange = (date: Date | null) => {
        console.log(date)
        if (date) {
            setSelectedDate(date)
        }
    }

    // レベルのオプション.
    const level_options: { value: number; label: string }[] = []
    for (var i = 1; i <= Const.Setting.LEVEL_MAX; i++) {
        level_options.push({ value: i, label: i.toString() })
    }
    // レンダリング
    return (
        <React.Fragment>
            <Box className={classes.container}>
                <ButtonDelete />
                <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
                    <Box className={classes.left}>
                        {/* オリジナルID */}
                        <input type="hidden" name="original_id" value={values.user_id} ref={register} />
                        {/* ID. */}
                        {portal === 'user' ? (
                            <input type="hidden" name="user_id" value={values.user_id} ref={register} />
                        ) : (
                            <InputTextField
                                className={classes.input}
                                required
                                fullWidth
                                id="user_id"
                                label="ユーザID"
                                name="user_id"
                                autoComplete=""
                                autoFocus
                                defaultValue={values.user_id}
                                // onChange={handleChange('title')}

                                // validation
                                inputRef={register({
                                    required: 'IDは必須です',
                                    pattern: {
                                        value: /^[A-Za-z.\-_\d]{6,16}$/,
                                        message: '英数字または記号『.-_』6〜16文字で入力してください',
                                    },
                                })}
                                error={Boolean(errors.user_id)}
                                helperText={errors.user_id && errors.user_id.message}
                            />
                        )}
                        {/* 氏名. */}
                        <InputTextField
                            className={classes.input}
                            fullWidth
                            required
                            label="氏名"
                            id="user_name"
                            name="user_name"
                            defaultValue={values.user_name}
                            // validation
                            inputRef={register({
                                required: '氏名は必須です', // 必須
                                maxLength: {
                                    value: 20,
                                    message: '氏名は20文字以内にしてください',
                                },
                                validate: isNotEmptyString,
                            })}
                            error={Boolean(errors.user_name)}
                            helperText={errors.user_name && errors.user_name.message}
                        />
                        {/* ふりがな. */}
                        <InputTextField
                            className={classes.input}
                            fullWidth
                            required
                            label="ふりがな"
                            id="user_ruby"
                            name="user_ruby"
                            defaultValue={values.user_ruby}
                            // validation
                            inputRef={register({
                                required: 'ふりがなは必須です', // 必須
                                maxLength: {
                                    value: 20,
                                    message: 'ふりがなは20文字以内にしてください',
                                },
                                validate: isNotEmptyString,
                            })}
                            error={Boolean(errors.user_ruby)}
                            helperText={errors.user_ruby && errors.user_ruby.message}
                        />
                        {/* 性別 */}
                        <FormControl variant="outlined" component="fieldset">
                            {/* <InputLabel htmlFor="outlined-radiogroup" >性別 </InputLabel> */}
                            <FormLabel component="legend" className={classes.label}>
                                性別
                            </FormLabel>
                            <RadioGroup
                                defaultValue={isUndefined(values.gender_id) ? '0' : values.gender_id.toString()}
                                aria-label="gender"
                                name="customized-radios"
                                onChange={handleChange('gender_id')}
                            >
                                <div style={{ display: 'flex' }}>
                                    <FormControlLabel value={Gender.male.toString()} control={<StyledRadio />} label="男性" />
                                    <FormControlLabel value={Gender.female.toString()} control={<StyledRadio />} label="女性" />
                                    <FormControlLabel value={Gender.unknown.toString()} control={<StyledRadio />} label="回答しない" />
                                </div>
                            </RadioGroup>
                        </FormControl>
                        {/* 生年月日 */}
                        {/* <MuiPickersUtilsProvider utils={ExtendedDateFnsUtils} locale={jaLocale}>
                            <Grid container justify="space-around">
                                <KeyboardDatePicker
                                    fullWidth
                                    disableToolbar
                                    variant="inline"
                                    format="yyyy/MM/dd"
                                    margin="normal"
                                    id="date-picker-inline"
                                    label="生年月日"
                                    value={selectedDate}
                                    onChange={handleDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider> */}
                        <Box marginBottom="12px">
                            <Typography className={classes.label}>生年月日</Typography>
                            <MainDatePicker id="birthDate" name="birthDate" selected={selectedDate} onChange={handleDateChange} />
                        </Box>

                        {/* 保護者名1 */}
                        <InputTextField
                            className={classes.input}
                            fullWidth
                            required
                            label="保護者名1"
                            id="parent_1"
                            name="parent_1"
                            defaultValue={values.parent_1}
                            // validation
                            inputRef={register({
                                required: '保護者名1は必須です', // 必須
                                validate: isNotEmptyString,
                            })}
                            error={Boolean(errors.parent_1)}
                            helperText={errors.parent_1 && errors.parent_1.message}
                        />
                        {/* 保護者名2 */}
                        <InputTextField
                            className={classes.input}
                            fullWidth
                            // required
                            label="保護者名2"
                            id="parent_2"
                            name="parent_2"
                            defaultValue={values.parent_2}
                            // validation
                            inputRef={register({
                                // required: '保護者名2は必須です', // 必須
                                validate: isNotEmptyString,
                            })}
                            error={Boolean(errors.parent_2)}
                            helperText={errors.parent_2 && errors.parent_2.message}
                        />
                        {/* 電話番号. */}
                        <InputTextField
                            className={classes.input}
                            fullWidth
                            required
                            label="電話番号"
                            id="tel"
                            name="tel"
                            defaultValue={values.tel}
                            // validation
                            inputRef={register({
                                required: '電話番号は必須です', // 必須
                                pattern: {
                                    value: /^0\d{2,3}-?\d{1,4}-?\d{4}$/,
                                    message: '正しい電話番号を入力してください。',
                                },
                            })}
                            error={Boolean(errors.tel)}
                            helperText={errors.tel && errors.tel.message}
                        />
                        {/* メールアドレス. */}
                        <InputTextField
                            className={classes.input}
                            fullWidth
                            required
                            label="メールアドレス"
                            id="mail"
                            name="mail"
                            defaultValue={values.mail}
                            // validation
                            inputRef={register({
                                required: 'メールアドレスは必須です', // 必須
                                pattern: {
                                    // value: /^[a-zA-Z0-9-_\.]+@[a-zA-Z0-9-_\.]+$/,
                                    value: /^[a-zA-Z0-9-_.]+@[a-zA-Z0-9-_.]+$/,
                                    message: '正しいメールアドレスを入力してください。',
                                },
                            })}
                            error={Boolean(errors.mail)}
                            helperText={errors.mail && errors.mail.message}
                        />
                        {/* ハンディキャップ */}
                        {/* トレーニングレベル */}
                        {/* <Paper
                            variant="outlined"
                            style={{
                                backgroundColor: 'transparent',
                                padding: 10,
                            }}
                        >
                            <Box style={{ marginRight: 'auto' }}>
                                <Typography variant="h6">レベル</Typography>
                            </Box>
                            <Box
                                display="flex"
                                style={{
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Box style={{ width: 160 }}>
                                    <Pulldown style={{ width: 160 }} options={level_options} />
                                </Box>
                                <Box
                                    style={{
                                        paddingLeft: 10,
                                        paddingRight: 10,
                                    }}
                                >
                                    <Typography variant="h6">以上</Typography>
                                </Box>
                                <Box style={{ width: 160 }}>
                                    <Pulldown style={{ width: 160 }} options={level_options} defaultValue={10} />
                                </Box>
                                <Box style={{ paddingLeft: 10 }}>
                                    <Typography variant="h6">以下</Typography>
                                </Box>
                            </Box>
                        </Paper> */}
                        {portal !== 'user' && (
                            <Box className={classes.cell}>
                                <Box className={classes.item}>
                                    <SelectTextField
                                        className={classes.select}
                                        id="min_level"
                                        name="min_level"
                                        label="トレーニングレベル"
                                        value={values.min_level}
                                        defaultValue={values.min_level}
                                        onChange={handleChange('min_level')}
                                    >
                                        {level_options.map((option) => (
                                            <MenuItem key={option.label} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </SelectTextField>
                                    <Typography variant="h5">以上</Typography>
                                </Box>
                                <Box className={classes.item}>
                                    <SelectTextField
                                        className={classes.select}
                                        id="max_level"
                                        name="max_level"
                                        value={values.max_level}
                                        defaultValue={values.max_level}
                                        onChange={handleChange('max_level')}
                                    >
                                        {level_options.map((option) => (
                                            <MenuItem key={option.label} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </SelectTextField>
                                    <Typography variant="h5">以下</Typography>
                                </Box>
                            </Box>
                        )}
                        {/* パスワード */}
                        <PasswordField
                            className={classes.input}
                            form={form}
                            name="password"
                            label={editType === EditType.edit ? '新しいパスワード' : 'パスワード'}
                            defaultValue={values.password}
                            required={editType === EditType.new}
                            rules={
                                passwordValidation
                                    ? {
                                          required: 'パスワードは必須です', // 必須
                                          pattern: {
                                              value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)[A-Za-z\d]{8,16}$/,
                                              message: '英大文字・英小文字・数字をそれぞれ一つ以上含む、8〜16文字で入力してください',
                                          },
                                      }
                                    : { required: false }
                            }
                            eye
                        />
                        {/* パスワード(確認) */}
                        <PasswordField
                            className={classes.input}
                            form={form}
                            name="password2"
                            label="パスワード(確認)"
                            defaultValue={values.password}
                            required={editType === EditType.new}
                            rules={{
                                required: passwordValidation ? 'パスワードを再入力してください' : false, // 必須
                                validate: {
                                    matchesPreviousPassword: (value: string) => {
                                        return value === watchPassword || 'パスワードが一致しません'
                                    },
                                },
                            }}
                            eye
                        />
                        {/* アイコン */}
                    </Box>
                    <Box className={classes.right}>
                        <Box width="100%">
                            <PreviewInput2 src={values.icon} onChangeHandler={handleChange('icon')} />
                        </Box>
                        <Box width="175px" marginBottom="12px">
                            <Footer />
                        </Box>
                    </Box>
                </form>
            </Box>
        </React.Fragment>
    )

    function Footer() {
        if (editType === EditType.new) {
            return (
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        {/* 登録. */}
                        <MainButton
                            type="submit"
                            fullWidth
                            // onClick={handleSubmit}
                        >
                            登録
                        </MainButton>
                    </Grid>
                    <Grid item xs={12}>
                        {/* 閉じる. */}
                        <MainButton fullWidth onClick={handleClose}>
                            閉じる
                        </MainButton>
                    </Grid>
                </Grid>
            )
        }
        return (
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    {/* 更新. */}
                    <MainButton
                        type="submit"
                        fullWidth
                        // onClick={handleSubmit}
                    >
                        更新
                    </MainButton>
                </Grid>
                <Grid item xs={12}>
                    {/* 閉じる. */}
                    <MainButton fullWidth onClick={handleClose}>
                        閉じる
                    </MainButton>
                </Grid>
            </Grid>
        )
    }

    function ButtonDelete() {
        // ユーザポータルでは、削除禁止.
        if (editType === EditType.new || portal === 'user') {
            return <></>
        }
        return (
            <Box display="flex" justifyContent="flex-end" width="730px" margin="0px auto 10px" padding="0 30px">
                {/* 削除 */}
                <DeleteButton className={classes.btnDelete} onClick={handleDelete}>
                    削除
                </DeleteButton>
            </Box>
        )
    }
}
