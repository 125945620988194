import React from 'react'
import Draggable from 'react-draggable'
import { Dialog, DialogTitle, DialogContent, Typography, makeStyles, createStyles, Theme } from '@material-ui/core'
import Paper, { PaperProps } from '@material-ui/core/Paper'
import { COLOR } from 'Const'

function PaperComponent(props: PaperProps) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    )
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogTitle: { color: COLOR.WHITE, backgroundColor: COLOR.BLUE, cursor: 'move' },
        title: {
            textAlign: 'center',
        },
    }),
)

export default function Popup(props: any) {
    const { title, children, openPopup, setOpenPopup } = props
    const classes = useStyles()

    return (
        <Dialog open={openPopup} onClose={() => setOpenPopup(false)} PaperComponent={PaperComponent} aria-labelledby="draggable-dialog-title">
            <DialogTitle className={classes.dialogTitle} id="draggable-dialog-title">
                <Typography variant="h3" component="div" className={classes.title}>
                    {title}
                </Typography>
            </DialogTitle>
            <DialogContent dividers>{children}</DialogContent>
        </Dialog>
    )
}
