import { combineReducers, PayloadAction } from '@reduxjs/toolkit'

import AdminModule from './modules/AdminModule'
import FacilityModule from './modules/FacilityModule'
import AppModule from './modules/AppModule'
import StaffModule from 'modules/StaffModule'
import UserModule from 'modules/UserModule'
import CourseModule from 'modules/CourseModule'
import TrainingModule from './modules/TrainingModule'
import LessonModule from 'modules/LessonModule'
import AuthModule from 'modules/AuthModule'
import AttendanceModule from 'modules/AttendanceModule'
import ChatModule from './modules/ChatModule'
import ImageCacheModule from './modules/ImageModule'
import ErrorModule from './modules/ErrorModule'
import { isUndefined } from 'util'

const LOCAL_STORAGE_KEY = () => {
    let names = ['Realize']
    const pathArr = window.location.pathname.split('/')
    switch (pathArr[1]) {
        case 'admin':
        case 'staff':
        case 'user':
            names.push(pathArr[1])
            break
        default:
            names.push(pathArr[2])
            // names.push(pathArr[1]);
            break
    }
    return names.join('.')
}

// 　Reducer を結合.
const appReducer = combineReducers({
    app: AppModule.reducer,
    auth: AuthModule.reducer,
    admins: AdminModule.reducer,
    facility: FacilityModule.reducer,
    staff: StaffModule.reducer,
    user: UserModule.reducer,
    course: CourseModule.reducer,
    training: TrainingModule.reducer,
    lesson: LessonModule.reducer,
    attendance: AttendanceModule.reducer,
    chat: ChatModule.reducer,
    imageCache: ImageCacheModule.reducer,
    error: ErrorModule.reducer,
})
enum PerformanceNavigation {
    TYPE_NAVIGATE = 0,
    TYPE_RELOAD = 1,
    TYPE_BACK_FORWARD = 2,
    TYPE_RESERVED = 255,
}

const rootReducer = (state: any, action: PayloadAction<any>) => {
    // console.log('ActionType', action.type)

    // 初期化時に、リロード判定し、ステートを復元する.
    if (action.type.indexOf('@@INIT') === 0 || action.type.indexOf('@@redux/INIT') === 0) {
        console.log('起動タイプ', window.performance.navigation.type)
        if (window.performance.navigation.type === PerformanceNavigation.TYPE_RELOAD) {
            // load state from local storage
            console.log('Load:', LOCAL_STORAGE_KEY())

            const loadState = localStorage.getItem(LOCAL_STORAGE_KEY())
            if (loadState !== null) {
                // console.log( "Load:", loadState )
                const newState = JSON.parse(loadState)
                state = newState
            }
        }
    }

    if (action.type === 'auth/logout') {
        state = undefined
        // console.log( "ログアウト", state )
    } else if (action.type === 'app/focusChanged') {
        // console.log('focusChanged')
        const loadState = localStorage.getItem(LOCAL_STORAGE_KEY())
        if (loadState !== null) {
            const newState = JSON.parse(loadState)
            // console.log('ステートチェック', state?.auth?.auth, newState?.auth?.auth)

            // 複数のウィンドウでログインした状態で
            // アカウント情報に不整合が発生した場合は、ログアウトさせる
            // 正しいアカウントのタブ挙動をどうするか？
            // ↓だけだと、ローカルストレージが破棄されてしまうので、おかしい
            if (newState?.auth?.auth && state?.auth?.auth) {
                if (newState?.auth?.auth.account !== state?.auth?.auth.account) {
                    state = { ...state, app: { ...state.app, isNeedLogin: true } }
                    return appReducer(state, action)
                }
            }
        } else {
            console.log('*** local storage empty')
        }
    }

    // save state to local storage
    if (isUndefined(state)) {
        localStorage.removeItem(LOCAL_STORAGE_KEY())
        // console.log( "ローカルストレージ削除" );
    } else {
        // console.log( "LS size:", JSON.stringify(state).length );
        try {
            localStorage.removeItem(LOCAL_STORAGE_KEY())
            localStorage.setItem(LOCAL_STORAGE_KEY(), JSON.stringify(state))
        } catch (err) {
            console.log('LS Error', err.message)
        }
    }

    return appReducer(state, action)
}

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
