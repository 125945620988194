// import * as Const from '../Const'
// import { trace } from '../common/index'
// import clsx from 'clsx'

import React from 'react'

// Material-UI
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

import { Grid } from '@material-ui/core'

// User Component
import StaffUserInfoCell from './StaffUserInfoCell'
import { isUndefined } from 'util'
import { useParams, useHistory } from 'react-router-dom'
import { useFacilityUserById } from 'modules/FacilityModule'
import { useFacility, useHomePageUrl } from 'modules/AppModule'
import CoursePanel from './CoursePanel'
import UserTrainingList from './UserTrainingList'
import { useAppDispatch } from 'store'
import { fetchLessonsByUser } from 'modules/LessonModule'
import { Lesson, LessonStatus } from 'Types'
//----------------------------------------------------------------------------------------
// スタイルを適用する
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            justifyContent: 'center',
            alignItems: 'center',
            width: 960,
            margin: '0 auto',
        },
        staffUserInfo: {
            padding: '35px 0 23px',
        },
    }),
)

//----------------------------------------------------------------------------------------
export default function StaffUserSummary() {
    const classes = useStyles()
    const homePageUrl = useHomePageUrl()
    const history = useHistory()
    const facility = useFacility()
    const { userId } = useParams()
    const user = useFacilityUserById(userId)
    const dispatch = useAppDispatch()
    const [userLessons, setUserLessons] = React.useState<Lesson[]>([])

    // redirect to list page if id isn't loaded
    if (userId && isUndefined(user)) {
        handleClose()
    }

    let facility_id = isUndefined(facility) || isUndefined(facility.facility_id) ? undefined : facility.facility_id

    const [defaultCourseId, setDefaultCourseId] = React.useState('')

    // Get default course id through latest lesson reservation
    const getDefaultCourseId = React.useCallback((courseId: string) => {
        setDefaultCourseId(courseId)
    }, [])

    function handleClose() {
        history.push(`${homePageUrl}/facility-users`)
    }
    const useEffectOnMount = (effect: React.EffectCallback) => {
        React.useEffect(effect, [])
    }

    useEffectOnMount(() => {
        handleFetchUserLessons()
    })

    const handleFetchUserLessons = () => {
        dispatch(
            fetchLessonsByUser({
                user_id: userId,
                start: '-',
                end: '-',
            }),
        ).then((res) => {
            const data = res.payload as Lesson[]
            if (data && data.length > 0) {
                setUserLessons(data)
            }
        })
    }

    const isUserDuringTraining = () => {
        if (user && userLessons && userLessons.length) {
            const lessonDuringTraining = userLessons.find((lessonItem: Lesson) => {
                return lessonItem.status === LessonStatus.progressing
            })
            return lessonDuringTraining ? true : false
        }
        return false
    }

    return (
        <React.Fragment>
            <Grid container className={classes.root}>
                {/* ユーザ情報 */}
                <Grid item xs={12} className={classes.staffUserInfo}>
                    <StaffUserInfoCell user={user} active={isUserDuringTraining() ? true : false} />
                </Grid>
                {/* カリキュラム */}
                <Grid item xs={12}>
                    <CoursePanel user={user} facilityId={facility_id} defaultCourseId={defaultCourseId} />
                </Grid>
                {/* トレーニングリスト */}
                <Grid item xs={12}>
                    <UserTrainingList user_id={userId} onGetDefaultCourseId={getDefaultCourseId} />
                </Grid>
            </Grid>
        </React.Fragment>
    )
}
