import { isUndefined } from 'util'

import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

import { Box, Button, ButtonGroup, Grow, Paper, Popper, MenuItem, MenuList, ClickAwayListener } from '@material-ui/core'
import { ButtonProps } from '@material-ui/core'
import { ArrowDropDown as ArrowDropDownIcon } from '@material-ui/icons'
import { OptionValue } from '../Types'

// スタイルを適用する
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        button: {
            margin: 0,
            height: 40,
        },
        dropdown: {
            justityContent: 'right',
        },
        option: {
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderTop: 0,
            '& .MuiList-padding': {
                padding: 0,
            },
            '& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover': {
                background: 'none',
            },
            '& .MuiMenuItem-root': {
                borderBottom: '1px solid #E2E2E2',
                margin: '0 5px',
            },
            '& .MuiMenuItem-root:last-child': {
                borderBottom: 0,
            },
        },
        arrowDown: {
            position: 'absolute',
            right: 5,
            zIndex: 11,
        },
    }),
)

type PulldownProps = {
    onChangeHandler?: (selectedIndex: number) => void
    options: OptionValue[]
    width: number
    index?: number
} & ButtonProps

export default function Pulldown2(props: PulldownProps) {
    const classes = useStyles()

    const [options] = React.useState(props.options)
    const [open, setOpen] = React.useState(false)
    const anchorRef = React.useRef<HTMLDivElement>(null)

    const [selectedIndex, setSelectedIndex] = React.useState(findValue(props.defaultValue))

    function findValue(value: any): number {
        const index = props.options.findIndex((item) => item.value === value)
        if (index < 0) return 0
        return index
    }

    const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
        setSelectedIndex(index)
        setOpen(false)
        if (!isUndefined(props.onChangeHandler)) {
            props.onChangeHandler(index)
        }
    }

    React.useEffect(() => {
        if (!isUndefined(props.index)) {
            setSelectedIndex(props.index)
        }
    }, [props.index])

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen)
    }

    const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return
        }

        setOpen(false)
    }

    const buttonProps = { ...props }
    delete buttonProps['onChangeHandler']
    return (
        <Box>
            <ButtonGroup variant="outlined" color="default" ref={anchorRef} aria-label="split button" className={classes.button}>
                <Button {...buttonProps} onClick={handleToggle} variant="outlined" className={classes.button}>
                    {options[selectedIndex].label}
                    <ArrowDropDownIcon className={classes.arrowDown} />
                </Button>
            </ButtonGroup>
            <Popper style={{ zIndex: 5 }} open={open} anchorEl={anchorRef.current} role={undefined} transition>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            top: -39,
                            position: 'relative',
                        }}
                    >
                        <Paper className={classes.option} style={{ width: props.width }}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="pulldown-menu">
                                    {options.map((option, index) => (
                                        <MenuItem key={option.value} selected={index === selectedIndex} onClick={(event) => handleMenuItemClick(event, index)}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </Box>
    )
}
