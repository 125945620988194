import isEmail from 'validator/lib/isEmail'
import { STAFF_IMPORT_FIELDS } from 'config/main'
import { Staff } from 'Types'

interface DataFilter {
    staffs: Staff[]
}

export const validate = (data: any, dataFilter: DataFilter) => {
    return {
        staff_id: !data['staff_id']
            ? `ID${messages.required}`
            : !/^[A-Za-z.\-_\d]{6,16}$/.test(data['staff_id'])
            ? '英数字または記号『.-_』6〜16文字で入力してください。'
            : dataFilter.staffs.find((staff) => staff.staff_id === data['staff_id'])
            ? `${getFieldLabel('staff_id')}データベースに存在します`
            : true,
        staff_name: data['staff_name'] ? true : `${getFieldLabel('staff_name')}${messages.required}`,
        staff_ruby: !data['staff_ruby'] ? 'ふりがなは必須です' : data['staff_ruby'].length > 20 ? 'ふりがなは20文字以内にして下さい。' : true,
        job_title: !data['job_title'] ? true : data['job_title'].length > 20 ? '役職は20文字以内にして下さい。' : true,
        tel: !data['tel'] ? true : /^0\d{2,3}-?\d{1,4}-?\d{4}$/.test(data['tel']) ? true : '正しい電話番号を入力してください。',
        mail: !data['mail'] ? true : isEmail(data['mail']) ? true : `${getFieldLabel('mail')}${messages.invalid}`,
        calendar_id: !data['calendar_id']
            ? true
            : /^[a-zA-Z0-9-_.]+@[a-zA-Z0-9-_.]+$/.test(data['calendar_id'])
            ? true
            : '正しいカレンダーIDを入力してください。',
        role_id: !data['role_id']
            ? `${getFieldLabel('role_id')}${messages.required}`
            : /^[1-2]$/.test(data['role_id'])
            ? true
            : `${getFieldLabel('role_id')}${messages.invalid}`,
        show_comment_of_parent:
            data['show_comment_of_parent'] === null
                ? `${getFieldLabel('show_comment_of_parent')}${messages.required}`
                : /^[0-1]$/.test(data['show_comment_of_parent'])
                ? true
                : `${getFieldLabel('show_comment_of_parent')}${messages.invalid}`,
        password: !data['password']
            ? `${getFieldLabel('password')}${messages.required}`
            : /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)[A-Za-z\d]{8,16}$/.test(data['password'])
            ? true
            : `英大文字・英小文字・数字をそれぞれ一つ以上含む、8〜16文字で入力してください`,
        password_confirm: data['password_confirm'] !== data['password'] ? `パスワードが一致しません` : true,
    }
}

export const getFieldLabel = (item: string) => {
    const field: any = STAFF_IMPORT_FIELDS.find((field) => field.field === item)
    return field ? field.label : item
}

const messages = {
    required: 'は必須です',
    invalid: '無効です',
}
