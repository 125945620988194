// import * as Const from '../Const'
// import { trace } from '../common/index'
import React from 'react'

import { useAppDispatch } from '../store'
import { fetchAttendanceByStaffId } from '../modules/AttendanceModule'

import { Grid } from '@material-ui/core'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

import * as DateUtil from 'date-fns'

import { Attendance } from '../Types'
import { AttendanceCell, AttendanceHeaderCell } from './AttendanceCell'
import { isUndefined } from 'util'

const useListStyles = makeStyles((theme: Theme) =>
    createStyles({
        list: {
            flexGrow: 1,
            paddingTop: 5,
            display: 'table',
        },
        listheader: {
            flexGrow: 1,
            marginTop: 'calc(0px)',
            marginBottom: 'calc(0px)',
        },
        listitem: {
            flexGrow: 1,
            background: '#F0F0F0',
            borderBottom: '1px solid #fff',
        },
        listfooter: {
            flexGrow: 1,
            marginTop: 'calc(8px)',
            marginBottom: 'calc(0px)',
        },
        scrollable: {
            overflow: 'auto',
            // maxHeight: 1000,
        },
        wrapper: {
            marginTop: 8,
        },
        content: {
            width: 960,
            margin: '8px auto 0',
        },
    }),
)

/**
 * プロパティ
 *
 * @interface AttendanceListProps
 */
interface AttendanceListProps {
    staff_id: string // スタッフID.
    date: Date // 日付.
    editable?: boolean
    onClickHandler?: (item: Attendance) => void
}

export default function AttendanceList(props: AttendanceListProps) {
    console.log('###AttendanceList', props.staff_id, props.date)
    const dispatch = useAppDispatch()
    const classes = useListStyles()

    const year = DateUtil.getYear(props.date)
    const month = 1 + DateUtil.getMonth(props.date)

    const [items, setItems] = React.useState<Attendance[]>([])

    React.useEffect(() => {
        const year = DateUtil.getYear(props.date)
        const month = 1 + DateUtil.getMonth(props.date)
        dispatch(fetchAttendanceByStaffId({ staff_id: props.staff_id, year: year, month: month })).then((res) => {
            // 月間データを用意.
            // 登録されていない日付を空データでセット.
            const attendances = res.payload as Attendance[]
            var items: Attendance[] = []

            const days = DateUtil.getDaysInMonth(props.date)
            for (var day = 1; day <= days; day++) {
                const date = DateUtil.format(new Date(year, month - 1, day), 'yyyy-MM-dd')
                let item = attendances.find((item) => item.date === date)
                if (isUndefined(item)) {
                    item = {
                        date: date,
                        enter_time: '',
                        leave_time: '',
                        content: '',
                    }
                }
                items.push(item)
            }
            setItems(items)
        })
    }, [dispatch, props.staff_id, props.date, year, month])

    // セルがクリックされた.
    const handleClick = (event: React.MouseEvent<unknown>, item: Attendance) => {
        // trace('Cell selected:' + item.date)
        if (props.onClickHandler !== undefined) {
            props.onClickHandler(item)
        }
    }

    return (
        <Grid container className={classes.list}>
            {/* header */}
            <Grid item xs={12} className={classes.listheader}>
                <AttendanceHeaderCell />
            </Grid>
            {/* List */}
            {/* <Grid item xs={12} style={{ overflow: 'auto' }}> */}
            <Grid item xs={12}>
                {items.map((item, index) => {
                    return (
                        <Grid item xs={12} className={classes.listitem} key={index}>
                            <AttendanceCell
                                staff_id={props.staff_id}
                                item={item}
                                editable={props.editable}
                                onClickHandler={(event) => handleClick(event, item)}
                            />
                        </Grid>
                    )
                })}
            </Grid>
        </Grid>
    )
}
