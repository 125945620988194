// import * as Const from '../Const'
// import { trace } from '../common/index'
// import clsx from 'clsx'
import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Typography, TableHead } from '@material-ui/core'
import { Course, Filter, FilterSorting } from '../Types'
import { StripedTableRow, StripedTableCell } from './Tables'
import { formatDateTime } from '../modules/Util'
import { HeadCellItemLabel } from './bases/HeadCellItemLabel'
import clsx from 'clsx'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        row: {
            '&:hover': {
                cursor: 'pointer',
            },
        },
        headerItem: {
            display: 'block',
            width: '100%',
            textAlign: 'center',
        },
        headerCell: {
            cursor: 'pointer',
        },
    }),
)

/**
 * Props
 *
 * @interface CourseCellProps
 */
interface CourseCellProps {
    data: Course
    onClickHandler?: (event: React.MouseEvent<unknown, MouseEvent>) => void
}

/**
 * トレーニングセル.
 * @export
 * @param {CourseCellProps} props
 * @returns セルのJSX 要素
 */
export function CourseCell(props: CourseCellProps) {
    const classes = useStyles()
    const handleClick = (event: React.MouseEvent<unknown, MouseEvent>) => {
        if (props.onClickHandler !== undefined) {
            props.onClickHandler(event)
        }
    }
    return (
        <StripedTableRow className={classes.row} onClick={(event) => handleClick(event)}>
            <StripedTableCell>
                <Typography variant="h5">{props.data.course_name}</Typography>
            </StripedTableCell>
            <StripedTableCell align="center">
                <Typography variant="h5">{formatDateTime(props.data.updated_at)}</Typography>
            </StripedTableCell>
        </StripedTableRow>
    )
}

/**
 * Props
 *
 * @interface HeaderCellProps
 */
type HeaderCellProps = {
    onRequestSort: (newSorting: FilterSorting) => void
    filter: Filter
}

/**
 * 施設セルヘッダ
 *
 * @export
 * @param {HeaderCellProps} props
 * @returns
 */
export function CourseHeaderCell(props: HeaderCellProps) {
    const classes = useStyles()

    return (
        <TableHead>
            <StripedTableRow>
                <StripedTableCell width="70%" align="center" className={clsx(classes.headerCell)}>
                    <HeadCellItemLabel label="コース名" field={'course_name'} filter={props.filter} onRequestSort={props.onRequestSort} />
                </StripedTableCell>
                <StripedTableCell width="30%" align="center" className={clsx(classes.headerCell)}>
                    <HeadCellItemLabel label="更新日時" field={'updated_at'} filter={props.filter} onRequestSort={props.onRequestSort} />
                </StripedTableCell>
            </StripedTableRow>
        </TableHead>
    )
}
