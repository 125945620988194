import React from 'react'
// import { useAppDispatch } from '../store'

// chime
import { ThemeProvider } from 'styled-components'
import { lightTheme, MeetingProvider, NotificationProvider, darkTheme, GlobalStyles, useMeetingManager } from 'amazon-chime-sdk-component-library-react'

import { AppStateProvider, useAppState } from '../chime/providers/AppStateProvider'
import ErrorProvider from '../chime/providers/ErrorProvider'
import { NavigationProvider } from '../chime/providers/NavigationProvider'
import { ChimeMeeting } from '../chime/views'
import Notifications from '../chime/containers/Notifications'
import NoMeetingRedirect from '../chime/containers/NoMeetingRedirect'
import meetingConfig from '../chime/meetingConfig'
// functional
import { useChatPage, ChatPage, endChat, startChat } from 'modules/ChatModule'
import { Box, createStyles, makeStyles, Theme, CircularProgress } from '@material-ui/core'
import { CloseButton } from './Buttons'
import { useAppDispatch } from 'store'
import { createGetAttendeeCallback, fetchMeeting } from 'chime/utils/api'
import { usePortal, useProfile } from 'modules/AppModule'
import { Staff, User } from 'Types'
import { useParams } from 'react-router-dom'
import { fetchLessonMeeting, fetchLessonMeetingNew } from 'modules/LessonModule'

// スタイルを適用する
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button_area: {
            // marginTop: 10,
            marginTop: 0,
            marginBottom: 0,
            padding: 6,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            // padding: theme.spacing(1),
            // backgroundColor:"#8080ff"
        },
        stop_teaching: {
            background: 'linear-gradient(0deg, #5a5a5a 10%, #707070 80%, #5F5F5F 100%)',
            border: '1px solid #6e6e6e',
            color: '#fff',
            marginRight: 15,
            '& .MuiTypography-h4': {
                fontSize: 15,
            },
        },
        video: {
            alignSelf: 'flex-start',
            minHeight: 316,
        },
    }),
)
const Theme2: React.FC = ({ children }) => {
    const { theme } = useAppState()

    return (
        <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
            <GlobalStyles />
            {children}
        </ThemeProvider>
    )
}
export default function VideoChat(props: any) {
    const page = useChatPage()
    const classes = useStyles()

    let chatContent
    // console.log('chat page:', page)
    switch (page) {
        case ChatPage.HOME:
            chatContent = null
            break
        case ChatPage.MEETING:
            chatContent = (
                <NoMeetingRedirect>
                    <ChimeMeeting />
                </NoMeetingRedirect>
            )
            break
        default:
            chatContent = null
    }
    return (
        <AppStateProvider>
            <Theme2>
                <NotificationProvider>
                    <Notifications />
                    <ErrorProvider>
                        <MeetingProvider {...meetingConfig}>
                            <VideoChatProvider>
                                <div style={{ zIndex: 30 }} className={classes.video}>
                                    <NavigationProvider>{chatContent}</NavigationProvider>
                                </div>
                            </VideoChatProvider>
                        </MeetingProvider>
                    </ErrorProvider>
                </NotificationProvider>
            </Theme2>
        </AppStateProvider>
    )
}
type Props = {
    children: React.ReactNode
}

function VideoChatProvider({ children }: Props) {
    const [meetingState, setMeetingState] = React.useState(false)

    const appDispatch = useAppDispatch()
    const classes = useStyles()
    const portal = usePortal()
    const profile = useProfile()
    const { lessonId } = useParams()

    const meetingManager = useMeetingManager()
    const { setAppMeetingInfo, region: appRegion } = useAppState()
    // const [meetingId, setMeetingid] = React.useState(appMeetingId)
    // const [meetingErr, setMeetingErr] = React.useState(false)
    const [name, setName] = React.useState('')
    // const [nameErr, setNameErr] = React.useState(false)
    const [region, setRegion] = React.useState(appRegion)
    const [isLoading, setIsLoading] = React.useState(false)
    // const { errorMessage, updateErrorMessage } = React.useContext(getErrorContext())

    React.useEffect(() => {
        if (profile === undefined) {
            setName('unknown')
        } else {
            if (portal === 'staff') {
                const staff = profile as Staff
                setName(staff?.staff_name === undefined ? '' : staff?.staff_name)
            } else if (portal === 'user') {
                const user = profile as User
                setName(user?.parent_1 === undefined ? '' : user.parent_1)
            }
        }
        setRegion('ap-northeast-1')
    }, [portal, profile, lessonId, appDispatch])

    // ミーティング入室.
    const handleJoinMeeting = async () => {
        appDispatch(fetchLessonMeeting(lessonId)).then(async (res) => {
            console.log('ミーティング:', res.payload)
            await join(res.payload)
                .then((res) => {
                    setIsLoading(false)
                })
                .catch((error) => {
                    setIsLoading(false)

                    appDispatch(fetchLessonMeetingNew(lessonId)).then(async (res) => {
                        console.log('ミーティング:', res.payload)
                        await join(res.payload)
                    })
                })
        })
    }
    async function join(meetingId: string) {
        return new Promise(async (resolve, reject) => {
            const id = meetingId.trim().toLocaleLowerCase()
            const attendeeName = name.trim()

            if (!id || !attendeeName) {
                // if (!attendeeName) {
                //     setNameErr(true)
                // }

                // if (!id) {
                //     setMeetingErr(true)
                // }
                reject('parameter error')
            }

            setIsLoading(true)
            meetingManager.getAttendee = createGetAttendeeCallback(id)

            try {
                const { JoinInfo } = await fetchMeeting(id, attendeeName, region)
                await meetingManager.join({
                    meetingInfo: JoinInfo.Meeting,
                    attendeeInfo: JoinInfo.Attendee,
                })

                setAppMeetingInfo(id, attendeeName, region)

                const meetingId = JoinInfo.Meeting.MeetingId
                await meetingManager.start()
                appDispatch(startChat(meetingId))
                // history.push(`${routes.MEETING}/${meetingId}`);
                // history.push(routes.DEVICE);
                resolve()
            } catch (error) {
                console.log(error)
                reject(error)
                // updateErrorMessage(error.message)
            }
        })
    }

    const handleToggleMeeting = async (): Promise<void> => {
        if (meetingState) {
            handleLeaveMeeting()
        } else {
            handleJoinMeeting()
        }
        setMeetingState(!meetingState)
    }

    // ミーティング入室.
    // const handleJoinMeeting = async (): Promise<void> => {
    //     console.log( "入室", region );

    //     // appDispatch(startChat(null))
    // }
    // ミーティング退出.
    const handleLeaveMeeting = async (): Promise<void> => {
        console.log('退室')
        meetingManager.leave()
        // history.push(routes.HOME);
        appDispatch(endChat(null))
    }
    // ミーティング終了.
    // const handleEndMeeting = async (): Promise<void> => {
    //     try {
    //         if (meetingId) {
    //             await endMeeting(meetingId)
    //             await meetingManager.leave()
    //             // history.push(routes.HOME);
    //             appDispatch(endChat(null))
    //         }
    //     } catch (e) {
    //         console.log('Could not end meeting', e)
    //     }
    // }
    return (
        <React.Fragment>
            <div style={{ height: 316, backgroundColor: '#cccccc' }}>
                {isLoading ? (
                    <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress size={80} />
                    </div>
                ) : (
                    children
                )}
            </div>
            {/* {children} */}
            <div style={{ zIndex: 10 }}>
                <Box className={classes.button_area}>
                    <CloseButton className={classes.stop_teaching} onClick={handleToggleMeeting}>
                        {meetingState ? 'オンライン指導をやめる' : 'オンライン指導を始める'}
                    </CloseButton>
                </Box>
            </div>
        </React.Fragment>
    )
}
