import { isUndefined } from 'util'
import * as DateUtil from 'date-fns'

import {
    Admin,
    Facility,
    Staff,
    User,
    Gender,
    Role,
    Course,
    Training,
    Lesson,
    LessonStatus,
    MspaEvaluationId,
    Information,
    InformationType,
    InformationImportance,
    LessonType,
    Display,
    LessonComment,
} from '../Types'

/**
 * 権限名を返す
 *
 * @param {(Role|undefined)} role_id
 * @returns 権限
 */
export function roleText(role_id: Role | undefined): string {
    if (isUndefined(role_id)) {
        return '未定義'
    }
    switch (+role_id) {
        case Role.manager:
            return '管理者'
        case Role.staff:
            return '一般職員'
        case Role.unknown:
            return '未設定'
    }
    return `未定義(${role_id})`
}
/**
 * レッスンの状態名を返す.
 */
export function getLessonStatusText(status: LessonStatus): string {
    switch (status) {
        case LessonStatus.request:
            return '承認待ち'
        case LessonStatus.reserved:
            return '予約済み'
        case LessonStatus.finish:
            return '受講済み'
        case LessonStatus.progressing:
            return '受講中'
        default:
            return `${status}`
    }
}

export function getLessonTypeText(type: LessonType): string {
    switch (type) {
        case LessonType.concurrent:
            return '一斉'
        case LessonType.individual:
            return '個別'
        default:
            return ''
    }
}

/**
 * レッスン開始時のメッセージを返す.
 *
 * @export
 * @param {(LessonStatus | undefined)} status レッスンステータス
 * @returns {(string | undefined)}
 */
export function getLessonMessage(status: LessonStatus | undefined): string | undefined {
    switch (status) {
        case LessonStatus.finish:
            return 'ユーザのレッスンログを確認中'
        case LessonStatus.progressing:
            return 'ユーザがレッスンを開始しています'
        case LessonStatus.request:
        case LessonStatus.reserved:
            return 'ユーザのレッスン開始を待っています'
    }
    return undefined
}

/**
 * レッスン開始時のメッセージを返す.
 *
 * @export
 * @param {(LessonStatus | undefined)} status レッスンステータス
 * @returns {(string | undefined)}
 */
export function getUserLessonMessage(status: LessonStatus | undefined): string | undefined {
    switch (status) {
        case LessonStatus.finish:
            return 'ユーザのレッスンログを確認中'
        case LessonStatus.progressing:
            return 'ユーザがレッスンを開始しています'
        case LessonStatus.request:
        case LessonStatus.reserved:
            return 'レッスンの開始を待っています'
    }
    return undefined
}

// MSPA評価指標定義テーブル.
export function getMspaTable(): {
    mspa_evaluation_id: MspaEvaluationId
    label: string
}[] {
    return [
        { mspa_evaluation_id: MspaEvaluationId.communication, label: 'コミュニケーション' },
        { mspa_evaluation_id: MspaEvaluationId.group_adaptability, label: '集団適応力' },
        { mspa_evaluation_id: MspaEvaluationId.empathy, label: '共感性' },
        { mspa_evaluation_id: MspaEvaluationId.commitment, label: 'こだわり' },
        { mspa_evaluation_id: MspaEvaluationId.sense, label: '感覚' },
        { mspa_evaluation_id: MspaEvaluationId.repetitive_exercise, label: '反復運動' },
        { mspa_evaluation_id: MspaEvaluationId.oversized_movement, label: '粗大運動' },
        { mspa_evaluation_id: MspaEvaluationId.fine_coordination, label: '微細協調運動' },
        { mspa_evaluation_id: MspaEvaluationId.carelessness, label: '不注意' },
        { mspa_evaluation_id: MspaEvaluationId.hyperactivity, label: '多動性' },
        { mspa_evaluation_id: MspaEvaluationId.impulsivity, label: '衝動性' },
        { mspa_evaluation_id: MspaEvaluationId.sleep_rhythm, label: '睡眠リズム' },
        { mspa_evaluation_id: MspaEvaluationId.learning, label: '学習' },
        { mspa_evaluation_id: MspaEvaluationId.language_development_history, label: '言語発達歴' },
    ]
}
// 性別の文字列を取得する.
export function getGenderName(gender_id: Gender): string {
    switch (+gender_id) {
        case Gender.male:
            return '男性'
        case Gender.female:
            return '女性'
        case Gender.unknown:
            return '回答なし'
    }
    return '不明'
}

// 年齢を計算する.
export function getAge(date: string): number {
    if (isUndefined(date)) return 0
    const now = new Date()
    const birth = DateUtil.parse(date, 'yyyy-MM-dd', new Date())

    const ny = DateUtil.getYear(now)
    const nm = DateUtil.getMonth(now)
    const nd = DateUtil.getDate(now)

    const by = DateUtil.getYear(birth)
    const bm = DateUtil.getMonth(birth)
    const bd = DateUtil.getDate(birth)

    let age = ny - by
    // 誕生日以前なら 年齢を -1;
    if (nm < bm || (nm === bm && nd < bd)) {
        age = age - 1
    }
    return age
}

//====================================================================

// モデルの初期化
export function initAdmin(): Admin {
    return {
        admin_id: undefined,
        admin_name: '',
        admin_ruby: '',
        password: '',
    }
}
export function initFacility(): Facility {
    return {
        facility_id: undefined, // 施設ID.
        facility_name: '', // 施設名.
        facility_ruby: '', // 施設名ふりがな.
        manager_id: '', // 管理者ID.
        manager_name: '', // 管理者氏名
        zip_code: '', // 郵便番号.
        address: '', // 住所.
        tel: '', // 電話番号.
        fax: '', // FAX番号.
        mail: '', // メールアドレス.
        url: '', // ホームページ.
        training_setting: {
            // トレーニング設定.
            min_level: 1,
            max_level: 10,
            min_license: 1,
            max_license: 15,
        },
        staff: [], // 所属職員.
    }
}
export function initStaff(): Staff {
    return {
        staff_id: undefined, // 職員ID
        staff_name: '', // 氏名.
        staff_ruby: '', // ふりがな.
        job_title: '', // 役職
        tel: '', // 電話番号.
        mail: '', // メールアドレス.
        role_id: Role.staff, // 権限ID.
        show_comment_of_parent: Display.hide, // 保護者からのコメント
        password: '', // パスワード.
        icon: undefined, // アイコンURL.
        icon_url: '', // アイコン名
        attendances: [], // 出退勤記録[].

        favorite_users: [], // ユーザ[].
        lessons: [], // 指導予約[]
        facility: undefined, // 所属施設
        calendar_id: undefined, // カレンダーID
    }
}
export function initUser(): User {
    return {
        user_id: undefined, // ユーザID.
        user_name: '', // 氏名.
        user_ruby: '', // 振り仮名.
        gender_id: Gender.unknown, // 性別.
        birth_date: undefined, // 生年月日.
        parent_1: '', // 保護者#1
        parent_2: '', // 保護者#2
        tel: '', // 電話番号.
        mail: '', // メールアドレス.
        handicaps: [], // 障害種類.
        min_level: 1, // 最低レベル.
        max_level: 10, // 最高レベル.
        password: '', // パスワード.
        icon_url: '', // アイコン名
        course: undefined, // 受講中コース.
        last_course_date: undefined, // 最終受講日.
        facility_id: '', // 最終受講日.

        lessons: [], // レッスン
        mspa_evaluation: {}, // MSPA 評価
    }
}
export function initCourse(): Course {
    return {
        course_id: undefined,
        course_name: '',
        description: '',
        min_level: 1,
        max_level: 1,
        trainings: [],
    }
}
export function initTraining(): Training {
    return {
        training_id: undefined, // トレーニングID.
        training_name: '', // トレーニング名.
        content_url: '', // コンテンツURL.
        duration: 0, // 所用時間.
        description: '', // 詳細説明.
        min_level: 1, // 最低レベル
        max_level: 10, // 最高レベル.
        required_license: 1, // 必要ライセンス.
        tags: [], // タグ.
        mspa_expectations: [], // MSPA 期待効果.
        examinations: [], // テスト.
    }
}
export function initLesson(): Lesson {
    return {
        lesson_id: undefined,
        user: undefined, // ユーザ.
        staff: undefined, // スタッフ.
        training: undefined, // トレーニング.
        course: undefined, // コース(カリキュラム)
        start: DateUtil.format(new Date(), 'yyyy-MM-dd HH:mm:ss'), // 開始日時.
        end: undefined, // 終了日時.
        status: LessonStatus.request, // 状態.
    }
}
export function initInformation(): Information {
    return {
        id: undefined,
        type: InformationType.FACILITY,
        importance: InformationImportance.DEFAULT,
        facility_id: null,
        author: null,
        release_date: DateUtil.format(new Date(), 'yyyy-MM-dd HH:mm:ss'), // リリース日時.
        expire_date: DateUtil.format(new Date(), 'yyyy-MM-dd HH:mm:ss'), // 掲載期限.
        title: '',
        content: '',
    }
}
export function initLessonComment(): LessonComment {
    return {
        lesson_id: '',
        user_id: '',
        comment_prev: '',
        comment_current: '',
        comment_next: '',
        comment_of_parent: '',
    }
}
