import React from 'react'
import { isUndefined } from 'util'
import { Button, Modal } from '@material-ui/core'
import { useStyles } from '../Stylex'
import { importUsers, fetchAllUsers } from 'modules/UserModule'
import { useAppDispatch } from 'store'
import * as XLSX from 'xlsx'
import { USER_IMPORT_FIELDS } from 'config/main'
import { validate } from './validation'
import { format, add } from 'date-fns'
import { User } from 'Types'
import { TableRowError } from './TableRowError'
import { useFacility } from 'modules/AppModule'
import { useSnackbar } from 'notistack'
import { useHistory } from 'react-router-dom'
import { getFieldFromLabel } from 'utils/helpers'
import { MainButton } from 'components/Buttons'
import { useDropzone } from 'react-dropzone'

export function ImportUsers() {
    const history = useHistory()
    const facility = useFacility()
    const facility_id = isUndefined(facility) || isUndefined(facility.facility_id) ? '' : facility.facility_id
    const { enqueueSnackbar } = useSnackbar()

    const dispatch = useAppDispatch()
    const classes = useStyles()
    const [open, setOpen] = React.useState(false)
    const [file, setFile] = React.useState<any>(null)
    const [isImporting, setIsImporting] = React.useState<boolean>(false)
    const [errors, setErrors] = React.useState<any>({})
    const [users, setUsers] = React.useState<User[]>([])
    const [rowErrors, setRowErrors] = React.useState<any[]>([])
    const [showRowError, setShowRowError] = React.useState<boolean>(false)

    const useEffectOnFile = (effect: React.EffectCallback) => {
        React.useEffect(effect, [file])
    }
    useEffectOnFile(() => {
        handleResetData()
    })

    const handleResetData = () => {
        setRowErrors([])
        setShowRowError(false)
        setErrors({})
    }

    const useEffectOnmount = (effect: React.EffectCallback) => {
        React.useEffect(effect, [])
    }
    useEffectOnmount(() => {
        handleFetchUsers()
    })

    const handleFetchUsers = () => {
        dispatch(fetchAllUsers({ params: { fields: 'user_id' } }))
            .then((res) => {
                if (res.payload && res.payload.length) {
                    setUsers(res.payload)
                }
            })
            .catch((error) => {
                console.error(error)
            })
    }

    const handleToggleShowRowError = () => {
        setShowRowError((showRowError) => !showRowError)
    }

    const handleOpen = () => {
        setOpen(true)
        handleFetchUsers()
    }

    const handleClose = () => {
        setOpen(false)
        const fileInput: any = document.getElementById('user-file')
        if (fileInput) {
            fileInput.value = null
        }
        setFile(null)
        setShowRowError(false)
        setRowErrors([])
        setErrors({})
        setIsImporting(false)
    }

    const handleOnFileChange = (event: React.FormEvent<HTMLInputElement>) => {
        const target = event.target as HTMLInputElement
        if (target.files && target.files.length) {
            const file = target.files[0]
            setFile(file)
        }
    }

    const handleImport = () => {
        setIsImporting(true)
        return hanldeImportFrontend()
    }

    const hanldeImportFrontend = () => {
        setRowErrors([])
        var reader = new FileReader()
        reader.onload = function (e: any) {
            var data = new Uint8Array(e?.target?.result)
            var workbook = XLSX.read(data, { type: 'array', cellDates: true })
            console.log('workbook.SheetNames', workbook.SheetNames)
            if (workbook.SheetNames.length > 1) {
                const wsname = workbook.SheetNames[1]
                const ws = workbook.Sheets[wsname]
                /* Convert array to json*/
                const dataParse: Array<Array<any>> = XLSX.utils.sheet_to_json(ws, { header: 1 })
                dataParse.splice(0, 2) // remove header
                let data = parseUserData(dataParse)

                data = handleCheckUniqueUserId(data)
                const dataFilter = data.filter((item) => !!item)
                data = data.map((item) => {
                    if (!item) {
                        return item
                    }
                    const validation: any = validate(item, { users: users })
                    for (const field of USER_IMPORT_FIELDS) {
                        if (validation[field.field] !== true) {
                            item.errors.push({
                                field: field.field,
                                label: getFieldFromLabel(USER_IMPORT_FIELDS, field.field),
                                message: validation[field.field],
                            })
                        }
                    }
                    return item
                })
                const inValidRows = data.filter((item) => item && item.errors.length)
                if (inValidRows.length) {
                    setRowErrors(inValidRows)
                    setErrors({
                        ...errors,
                        error: `${inValidRows.length}件のエラーが見つかりました`,
                    })
                    setIsImporting(false)
                    return
                }

                if (!facility) {
                    return
                }
                const maxUserNumber = facility ? facility.max_users : 0
                const numberUserInFacility = users.filter((user) => user.facility_id === facility_id).length

                if (maxUserNumber && dataFilter.length + numberUserInFacility > maxUserNumber) {
                    setErrors({
                        ...errors,
                        error: `登録可能ユーザ数（上限：${maxUserNumber}）を超えています。`,
                    })
                    return false
                }

                return hanldeImportAPI(dataFilter)
            } else {
                setErrors({
                    ...errors,
                    error: 'The sheet format was wrong',
                })
                setIsImporting(false)
            }
        }

        reader.readAsArrayBuffer(file)
    }

    const handleCheckUniqueUserId = (rows: any[]) => {
        let data: any = []
        for (const i in rows) {
            const row = rows[i]
            if (!row || !row.user_id) {
                continue
            }
            if (data[row.user_id]) {
                data[row.user_id] = data[row.user_id] + 1
                row.errors.push({ field: 'user_id', label: 'ID', message: '[ユーザID]シート内で重複しています' })
                rows[i] = row
            } else {
                data[row.user_id] = 1
            }
        }
        return rows
    }

    const parseUserData = (data: any) => {
        const result = []
        for (let index = 0; index < data.length; index++) {
            result.push(parseRowItem(data[index], index))
        }
        return result
    }

    const parseGenderValue = (value: any) => {
        value = value ? value.trim() : null
        switch (value) {
            case '男性':
                return 1
            case '女性':
                return 2
            case '回答しない':
                return 0
            case null:
                return 0
            default:
                return null
        }
    }

    const parseMinLevel = (value: any) => {
        return value ? value : 1
    }
    const parseMaxLevel = (value: any) => {
        return value ? value : 10
    }

    const parseRowItem = (row: any, index: number) => {
        if (!row.length) {
            return null
        } else {
            const result: any = {}
            result.errors = []
            result.line_number = index + 1
            result.facility_id = facility_id
            for (let index = 0; index < USER_IMPORT_FIELDS.length; index++) {
                if (USER_IMPORT_FIELDS[index]) {
                    let data = row[index]
                    if (USER_IMPORT_FIELDS[index].field === 'birth_date') {
                        if (!data) {
                            data = format(new Date(), 'yyyy-MM-dd')
                        } else {
                            if (typeof data === 'object') {
                                data = format(add(data, { hours: 1 }), 'yyyy-MM-dd')
                            }
                        }
                    }
                    if (USER_IMPORT_FIELDS[index].field === 'gender_id') {
                        data = parseGenderValue(data)
                    }
                    if (USER_IMPORT_FIELDS[index].field === 'min_level') {
                        data = parseMinLevel(data)
                    }
                    if (USER_IMPORT_FIELDS[index].field === 'max_level') {
                        data = parseMaxLevel(data)
                    }
                    result[USER_IMPORT_FIELDS[index].field] = data
                }
            }
            return result
        }
    }

    const hanldeImportAPI = (data: any[]) => {
        dispatch(importUsers({ data }))
            .then((res: any) => {
                setIsImporting(false)
                console.log('res', res)
                handleClose()
                // User import successful
                enqueueSnackbar('ユーザーのインポートに成功しました', { variant: 'success' })
                history.goBack()
            })
            .catch((error) => {
                console.error('error', error)
            })
    }

    const renderErrors = () => {
        const errorItems: any[] = []
        for (const [key, value] of Object.entries(errors)) {
            errorItems.push({ name: key, label: value })
        }
        return (
            <ul className={classes.error}>
                {errorItems.map((item) => (
                    <li key={item.name}>{item.label}</li>
                ))}
            </ul>
        )
    }

    const { acceptedFiles, fileRejections, getRootProps, getInputProps } = useDropzone({
        maxFiles: 1,
        accept: '.xls, .xlsx',
    })

    React.useEffect(() => {
        const file = acceptedFiles[0]
        setFile(file)
    }, [acceptedFiles])
    React.useEffect(() => {
        console.log('fileRejections', fileRejections)
    }, [fileRejections])

    return (
        <>
            <div className={classes.buttonGroup}>
                <div className={classes.buttonItem}>
                    <MainButton variant="outlined" className={classes.buttonImport} onClick={handleOpen}>
                        一括登録
                    </MainButton>
                </div>
                <div className={classes.buttonItem}>
                    <Button size="large" className={classes.buttonDownloadTemplate}>
                        <a href="/templates/user/新規ユーザ登録フォーマット.xlsx" className={classes.link} download>
                            フォーマットダウンロード
                        </a>
                    </Button>
                </div>
            </div>

            <Modal open={open} onClose={handleClose} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
                <div
                    style={{
                        top: `${50}%`,
                        left: `${51}%`,
                        transform: `translate(-${50}%, -${51}%)`,
                    }}
                    className={classes.paper}
                >
                    <h2 id="simple-modal-title" className={classes.modalTitle}>
                        ユーザ一括登録
                    </h2>
                    <div>
                        <input type="file" className={classes.inputFile} id="user-filea" accept=".xls,.xlsx" onChange={handleOnFileChange} />
                        <label className={classes.formLabel}>ファイルアップロード</label>
                        <div>
                            <label htmlFor="user-file" className={classes.fileInput}>
                                <div {...getRootProps({ className: 'dropzone' })} id="user-file">
                                    <input {...getInputProps()} accept=".xls,.xlsx" />
                                    {file ? file.name : 'ここにドラッグ＆ドロップするか、ここをクリックしてファイルを選択してください'}
                                </div>
                            </label>
                        </div>
                    </div>
                    <div className={classes.modalFooter}>
                        <MainButton className={classes.button} disabled={isImporting || !file} variant="contained" color="primary" onClick={handleImport}>
                            インポートを確認
                        </MainButton>
                    </div>
                    <div>{renderErrors()}</div>
                    <div>
                        {rowErrors.length ? (
                            <MainButton className={classes.button} variant="contained" color="primary" onClick={handleToggleShowRowError} size="small">
                                {showRowError ? 'エラーを隠す' : `上位${Math.min(10, rowErrors.length)}件のエラーを表示`}
                            </MainButton>
                        ) : (
                            ''
                        )}
                    </div>
                    {showRowError ? <TableRowError rows={rowErrors} /> : ''}
                </div>
            </Modal>
        </>
    )
}
