// ### Import
// import * as Const from '../Const'
import { trace } from '../common/index'
import { isUndefined } from 'util'

import React from 'react'

import { useAppDispatch } from '../store'
import { createFacility, updateFacilityById, deleteFacility, generateFacilityId, useFacilityById } from '../modules/FacilityModule'

// for validation
import { useForm } from 'react-hook-form'

// Material-UI
import { Grid, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

//### Import MyClass
import { Facility } from '../Types'
import { DeleteButton, MainButton } from '../components/Buttons'
import { InputTextField } from './Inputs'
import { useParams } from 'react-router-dom'

// スタイル定義.
const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(0),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: 480,
        margin: '0 auto',
    },
    container: {
        width: 960,
        margin: '45px auto 20px',
    },
    input: {
        marginBottom: 12,
        '& > label': {
            color: '#05A9C2',
        },
        '& input': {
            borderColor: '#B6B6B6',
        },
    },
    btnGenerate: {
        border: 'unset',
        position: 'absolute',
        top: 22,
        left: 500,
        width: 150,
    },
    btnDelete: {
        background: '#D02927',
        color: '#FFFFFF',
        border: 'unset',
        '&:hover': {
            background: '#D02927',
        },
    },
    facilityId: {
        position: 'relative',
    },
    footer: {
        textAlign: 'center',
        width: '100%',
        margin: '30px 0',
    },
}))

type LocalState = {
    showPassword: boolean // パスワードを表示するか.
    showRememberMe: boolean // RememberMe を表示するか.
    password2?: string
}
/**
 * 編集プロパティ
 *
 * @interface FacilityEditProps
 */
interface FacilityEditProps {
    onClickHandler?: (item?: Facility) => void
    onCloseHandler?: () => void
    item?: Facility
}

/**
 * トレーニング編集.
 *
 * @returns
 */
export default function FacilityEdit(props: FacilityEditProps) {
    const dispatch = useAppDispatch()

    const classes = useStyles()
    const { register, handleSubmit, errors } = useForm<Facility & LocalState & { original_id: string }>()

    const { facilityId } = useParams()
    let item = useFacilityById(facilityId)

    // redirect to list page if id isn't loaded
    if (facilityId && isUndefined(item)) {
        handleClose()
    }

    // let item = useSelectedFacility()
    if (isUndefined(item)) {
        item = {
            facility_id: undefined,
            max_staffs: 15,
            max_users: 30,
            // facility_name: '施設',
            // facility_ruby: '',
            // tel: '000-0000-0000',
            // mail: 'a@a',
            // zip_code: '0000000',
            // address: '住所',
            // fax: '000-0000-0000',
            // manager_name: 'カンリシャ',
        }
    }
    const [original_id] = React.useState<string | undefined>(item.facility_id)

    // 取得中に TextField のプレースホルダが表示されてしまうための対策.
    if (isUndefined(item.facility_id)) {
        item.facility_id = ' '
    }
    const [values, setValues] = React.useState<Facility>(item)

    React.useEffect(() => {
        if (isUndefined(original_id)) {
            genId()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [original_id])

    function genId() {
        dispatch(generateFacilityId()).then((res) => {
            const facility_id = res.payload
            console.log('ランダム', facility_id)
            setValues({ ...values, facility_id: facility_id })
        })
    }

    // 内容変更.
    // const handleChange = (prop: keyof Facility) => (
    //     event: React.ChangeEvent<HTMLInputElement>,
    // ) => {
    //     trace('changed')
    //     setValues({ ...values, [prop]: event.target.value })
    // }

    /**
     *　登録ボタンが押された時の処理.
     * react-hooks-from 処理後
     * @param {React.FormEvent} event
     * @returns
     */
    const onSubmit = (data: any) => {
        trace('=== 送信処理')

        console.log(data)
        console.log(values)
        if (isUndefined(original_id)) {
            console.log('施設：新規作成')
            dispatch(createFacility(data)).then(() => {
                console.log('登録完了')
                handleClose()
            })
        } else {
            console.log('施設: 更新')
            dispatch(updateFacilityById({ ...data, original_id: original_id })).then(() => {
                console.log('登録完了')
                handleClose()
            })
        }

        return
    }
    const onDelete = () => {
        console.log('サクジョ')
        if (isUndefined(original_id)) return
        dispatch(deleteFacility(original_id)).then(() => {
            console.log('登録完了')
            handleClose()
        })
    }

    /**
     *　閉じるボタンが押された時の処理.
     * - axios で POST
     * @param {FormEvent} event
     * @returns
     */
    function handleClose() {
        if (props.onCloseHandler !== undefined) {
            props.onCloseHandler()
        }
    }
    const handleGenerateFacilityId = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        genId()
    }

    return (
        <React.Fragment>
            <Box className={classes.container}>
                <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
                    {/* {state.showWarning ? <Result /> : null} */}

                    <Box>
                        <ButtonDelete originalId={original_id} onDeleteHandler={onDelete} />
                        {/* ID. */}
                        <Box className={classes.facilityId}>
                            <InputTextField
                                className={classes.input}
                                required
                                disabled
                                fullWidth
                                id="facility_id"
                                label="施設ID"
                                name="facility_id"
                                autoComplete=""
                                // autoFocus
                                value={values.facility_id}
                                // validation
                                inputRef={register({
                                    required: '施設IDは必須です', // 必須
                                })}
                                error={Boolean(errors.facility_id)}
                                helperText={errors.facility_id && errors.facility_id.message}
                            />
                            {isUndefined(original_id) ? (
                                <MainButton className={classes.btnGenerate} fullWidth onClick={handleGenerateFacilityId}>
                                    再生成する
                                </MainButton>
                            ) : null}
                        </Box>
                        {/* 施設名. */}
                        <InputTextField
                            className={classes.input}
                            required
                            fullWidth
                            id="facility_name"
                            label="施設名"
                            name="facility_name"
                            autoComplete=""
                            // autoFocus
                            defaultValue={values.facility_name}
                            // validation
                            inputRef={register({
                                required: '施設名は必須です', // 必須
                            })}
                            error={Boolean(errors.facility_name)}
                            helperText={errors.facility_name && errors.facility_name.message}
                        />
                        {/* 施設名ふりがな. */}
                        <InputTextField
                            className={classes.input}
                            required
                            fullWidth
                            id="facility_ruby"
                            label="施設名（ふりがな）"
                            name="facility_ruby"
                            autoComplete=""
                            // autoFocus
                            defaultValue={values.facility_ruby}
                            // // validation
                            inputRef={register({
                                required: '施設名（ふりがな）は必須です', // 必須
                            })}
                            error={Boolean(errors.facility_ruby)}
                            helperText={errors.facility_ruby && errors.facility_ruby.message}
                        />
                        {/* 管理者氏名. */}
                        <InputTextField
                            className={classes.input}
                            required
                            fullWidth
                            id="manager_name"
                            label="管理者氏名"
                            name="manager_name"
                            autoComplete=""
                            // autoFocus
                            defaultValue={values.manager_name}
                            // validation
                            inputRef={register({
                                required: '管理者氏名は必須です', // 必須
                            })}
                            error={Boolean(errors.manager_name)}
                            helperText={errors.manager_name && errors.manager_name.message}
                        />
                        {/* 郵便番号. */}
                        <InputTextField
                            className={classes.input}
                            fullWidth
                            required
                            label="郵便番号"
                            id="zip_code"
                            name="zip_code"
                            defaultValue={values.zip_code}
                            // validation
                            inputRef={register({
                                required: '郵便番号は必須です', // 必須
                                pattern: {
                                    value: /^\d{3}-?\d{4}$/,
                                    message: '正しい郵便番号を入力してください。',
                                },
                            })}
                            error={Boolean(errors.zip_code)}
                            helperText={errors.zip_code && errors.zip_code.message}
                        />
                        {/* 住所. */}
                        <InputTextField
                            className={classes.input}
                            fullWidth
                            required
                            label="住所"
                            id="address"
                            name="address"
                            defaultValue={values.address}
                            // validation
                            inputRef={register({
                                required: '住所は必須です', // 必須
                            })}
                            error={Boolean(errors.address)}
                            helperText={errors.address && errors.address.message}
                        />
                        {/* 電話番号. */}
                        <InputTextField
                            className={classes.input}
                            fullWidth
                            required
                            label="電話番号"
                            id="tel"
                            name="tel"
                            defaultValue={values.tel}
                            // validation
                            inputRef={register({
                                required: '電話番号は必須です', // 必須
                                pattern: {
                                    value: /^0\d{2,3}-?\d{1,4}-?\d{4}$/,
                                    message: '正しい電話番号を入力してください。',
                                },
                            })}
                            error={Boolean(errors.tel)}
                            helperText={errors.tel && errors.tel.message}
                        />
                        {/* FAX  */}
                        <InputTextField
                            className={classes.input}
                            fullWidth
                            label="FAX"
                            id="fax"
                            name="fax"
                            defaultValue={values.fax}
                            // validation
                            inputRef={register({
                                pattern: {
                                    value: /^0\d{2,3}-?\d{1,4}-?\d{4}$/,
                                    message: '正しいFAX番号を入力してください。',
                                },
                            })}
                            error={Boolean(errors.fax)}
                            helperText={errors.fax && errors.fax.message}
                        />
                        {/* メールアドレス. */}
                        <InputTextField
                            className={classes.input}
                            fullWidth
                            required
                            label="メールアドレス"
                            id="mail"
                            name="mail"
                            defaultValue={values.mail}
                            // validation
                            inputRef={register({
                                required: 'メールアドレスは必須です', // 必須
                                pattern: {
                                    value: /^[a-zA-Z0-9-_.]+@[a-zA-Z0-9-_.]+$/,
                                    message: '正しいメールアドレスを入力してください。',
                                },
                            })}
                            error={Boolean(errors.mail)}
                            helperText={errors.mail && errors.mail.message}
                        />
                        {/* ホームページ . */}
                        <InputTextField
                            className={classes.input}
                            fullWidth
                            label="ホームページ"
                            id="url"
                            name="url"
                            defaultValue={values.url}
                            // validation
                            inputRef={register({
                                pattern: {
                                    value: /^https?:\/\/[\w!?/+\-_~;.,*&@#$%()'[\]]+$/,
                                    message: '正しいホームページを入力してください。',
                                },
                            })}
                            error={Boolean(errors.url)}
                            helperText={errors.url && errors.url.message}
                        />
                        {/* 登録職員上限数 . */}
                        <InputTextField
                            className={classes.input}
                            required
                            fullWidth
                            label="登録職員上限数"
                            id="max_staffs"
                            name="max_staffs"
                            defaultValue={values.max_staffs}
                            // validation
                            inputRef={register({
                                required: '登録職員上限数は必須です。',
                                validate: (value) => (value > 0 && value <= 100) || '1から100 までの数値を入力してください。',
                            })}
                            error={Boolean(errors.max_staffs)}
                            helperText={errors.max_staffs && errors.max_staffs.message}
                        />
                        {/* 登録ユーザ上限数 . */}
                        <InputTextField
                            className={classes.input}
                            required
                            fullWidth
                            label="登録ユーザ上限数"
                            id="max_users"
                            name="max_users"
                            defaultValue={values.max_users}
                            // validation
                            inputRef={register({
                                required: '登録ユーザ上限数は必須です。',
                                validate: (value) => (value > 0 && value <= 100) || '1から100 までの数値を入力してください。',
                            })}
                            error={Boolean(errors.max_users)}
                            helperText={errors.max_users && errors.max_users.message}
                        />
                        <Footer type={isUndefined(original_id) ? 0 : 1} onCloseHandler={handleClose} />
                    </Box>
                </form>
            </Box>
        </React.Fragment>
    )
}

type FooterProps = {
    type: number
    onCloseHandler?: (event: React.FormEvent<Element>) => void
}
// &  JSX.IntrinsicElements["button"];

function Footer(props: FooterProps) {
    const classes = useStyles()
    const handleClose = (event: React.FormEvent<Element>) => {
        if (props.onCloseHandler) {
            props.onCloseHandler(event)
        }
    }

    switch (props.type) {
        case 0:
            return (
                <Grid container spacing={1} className={classes.footer}>
                    <Grid item xs={6}>
                        {/* 更新. */}
                        <MainButton type="submit" fullWidth>
                            登録
                        </MainButton>
                    </Grid>
                    <Grid item xs={6}>
                        {/* 閉じる. */}
                        <MainButton fullWidth onClick={handleClose}>
                            閉じる
                        </MainButton>
                    </Grid>
                </Grid>
            )
        default:
            return (
                <Grid container spacing={1} className={classes.footer}>
                    <Grid item xs={6}>
                        {/* 更新. */}
                        <MainButton type="submit" fullWidth>
                            更新
                        </MainButton>
                    </Grid>
                    <Grid item xs={6}>
                        {/* 閉じる. */}
                        <MainButton fullWidth onClick={handleClose}>
                            閉じる
                        </MainButton>
                    </Grid>
                </Grid>
            )
    }
}

type ButtonDeleteProps = {
    onDeleteHandler?: () => void
    originalId?: string
}

function ButtonDelete(props: ButtonDeleteProps) {
    const classes = useStyles()
    const handleDelete = () => {
        if (props.onDeleteHandler) {
            props.onDeleteHandler()
        }
    }
    if (isUndefined(props.originalId)) {
        return <></>
    }
    return (
        <Box display="flex" justifyContent="flex-end" marginBottom="20px">
            {/* 削除 */}
            <DeleteButton className={classes.btnDelete} onClick={handleDelete}>
                削除
            </DeleteButton>
        </Box>
    )
}
