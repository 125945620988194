//
// 管理者ポータル.
// 管理者管理.
//
// import * as Const from '../Const'
import { trace } from '../common/index'
import React from 'react'

// User's Component.
import TitleBar from './TitleBar'

// 管理者一覧
import AdminList from './AdminList'
import AdminEdit from './AdminEdit'

// スタイルを適用する
// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     root: {
//       flexGrow: 1,
//       padding: "10px",
//     },
//   })
// );
import { Admin } from '../Types'
import { NewButton } from '../components/Buttons'
import { Box } from '@material-ui/core'
import SearchBar2 from './SearchBar2'
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom'

export default function AdminContent() {
    trace('### AdminContent')

    const history = useHistory()
    const match = useRouteMatch()
    const listPageUrl = match.url

    // 一覧：セル選択
    const handleClickCell = (item?: Admin) => {
        trace('=== AdminList Clicked')
        trace(item)
        history.push(`${listPageUrl}/${item?.admin_id}`)
    }
    // 検索バー：新規作成
    const handleClickNew = () => {
        trace('--- Admin New')
        history.push(`${listPageUrl}/create`)
    }
    // 詳細: 閉じる
    const handleClickEditClose = () => {
        trace('--- Admin Close')
        history.push(listPageUrl)
    }

    // CSSを適用する。
    // const classes = useStyles();
    return (
        <React.Fragment>
            <Box width="960px" margin="0 auto">
                <Switch>
                    <Route
                        exact
                        path={`${listPageUrl}`}
                        render={() => (
                            <>
                                <Box marginBottom="30px">
                                    <SearchBar2 right={<NewButton onClickHandler={handleClickNew}>新規登録</NewButton>} />
                                </Box>
                                <TitleBar bgBlue label="管理者一覧" />
                                <AdminList onClickHandler={handleClickCell} />
                            </>
                        )}
                    />
                    <Route
                        path={`${listPageUrl}/create`}
                        render={() => (
                            <>
                                <TitleBar bgBlue label="管理者登録" />
                                <AdminEdit onCloseHandler={handleClickEditClose} />
                            </>
                        )}
                    />
                    <Route
                        path={`${listPageUrl}/:adminId`}
                        render={() => (
                            <>
                                <TitleBar bgBlue label="管理者詳細" />
                                <AdminEdit onCloseHandler={handleClickEditClose} />
                            </>
                        )}
                    />
                </Switch>
            </Box>
        </React.Fragment>
    )
}
