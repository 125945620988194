import { useSelector } from 'react-redux'
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from '../rootReducer'
import * as ApiClient from './ApiClient'
import { User, OriginalField, LessonStatus } from '../Types'
import { useAppDispatch } from 'store'
import { useState, useEffect } from 'react'
import qs from 'query-string'

// ステート定義
type UserState = {
    // contentPage: string
    // page: string
    // selectedItem?: User
    users: User[]
}

// ステート初期値.
const initialState: UserState = {
    // contentPage: 'user_top',
    // page: 'list',
    // selectedItem: undefined,
    users: [],
}

// React Thunk
export const fetchUserById = createAsyncThunk('user/fetchById', async (id: string, thunkAPI) => {
    const response = await ApiClient.get('/users/' + id)
    return response.data
})
export const fetchUsers = createAsyncThunk('user/fetchAll', async (arg: { params?: object }, thunkAPI) => {
    let queryString = qs.stringify(arg.params ? arg.params : {})
    const response = await ApiClient.get(`/users?${queryString}`)
    return response.data
})
export const fetchAllUsers = createAsyncThunk('user/fetchAllUsers', async (arg: { params?: object }, thunkAPI) => {
    let queryString = qs.stringify(arg.params ? arg.params : {})
    const response = await ApiClient.get(`/users?${queryString}`)
    return response.data
})

export const fetchUsersByFacilityId = createAsyncThunk('user/fetchUsersByFacilityId', async (facility_id: string, thunkAPI) => {
    const url = `/facilities/${facility_id}/users`
    const response = await ApiClient.get(url)
    return response.data
})

export const fetchUserLessonByStatus = createAsyncThunk('user/fetchUserLessonByStatus', async (arg: { user_id: string; status: LessonStatus }, thunkAPI) => {
    const url = `/users/${arg.user_id}/status/${arg.status}`
    console.log('URL:', url)
    const response = await ApiClient.get(url)
    return response.data
})

export const importUsers = createAsyncThunk('user/importUsers', async (arg: { data: any[] }, thunkAPI) => {
    const response = await ApiClient.post('users/import', { data: arg.data })
    return response
})

/**
 * 作成
 * method: POST
 * path: /user
 */
export const createUser = createAsyncThunk('user/create', async (user: User & OriginalField & { facility_id: string }, thunkAPI) => {
    delete user.original_id
    console.log('createUser:', user)

    const response = await ApiClient.post('/users', user)
    return { ...response, item: user }
})

// 更新.
// method: PUT
// path: /user/:id
// @note id の更新にも対応。
// @params  { original_id, user }
//  original_id: オリジナルのID.（検索用）
//  user: 更新する内容
export const updateUserById = createAsyncThunk('user/updateById', async (item: User & OriginalField, thunkAPI) => {
    const user = { ...item, original_id: undefined } //excludeOriginalField(item)
    console.log('### updateUserById:', user)
    const response = await ApiClient.put('/users/' + item.original_id, user)
    return { ...response, item: user }
})
// 更新.
// method: PUT
// path: /user/:id
// @note id の更新にも対応。
// @params  { original_id, user }
//  original_id: オリジナルのID.（検索用）
//  user: 更新する内容
export const patchUserHandicap = createAsyncThunk('user/patchHandicapById', async (item: any, thunkAPI) => {
    const user = { ...item }
    console.log('### patchHandicapById:', user)
    const response = await ApiClient.put('/users/' + user.user_id, user)
    return { ...response, item: user }
})
// function excludeOriginalField(item: User & OriginalField): User {
//     console.log('exclude前: ', item)
//     let state: User = DataUtil.initUser()
//     for (const [key, value] of Object.entries(item)) {
//         if (key !== 'original_id') {
//             state = { ...state, [key]: value }
//         }
//     }
//     console.log('exclude結果: ', state)
//     return state
// }

// 削除
// @path: /user/:id
export const deleteUser = createAsyncThunk('user/delete', async (id: string, thunkAPI) => {
    const response = await ApiClient.delete_('/users/' + id)
    return { ...response, id: id }
})

export const fetchUserExport = createAsyncThunk(
    'user/fetchUserExport',
    async (arg: { user_id: string; staff_ids: string[]; start: string; end: string }, thunkAPI) => {
        const url = `/users/${arg.user_id}/staffs/${arg.staff_ids}/lessons/${arg.start}/${arg.end}`
        const response = await ApiClient.get(url)
        return response.data
    },
)

// Slice を作成.
const UserModule = createSlice({
    name: 'user',
    initialState,
    reducers: {
        // ユーザページ変更.
        // changeContentPage(state: UserState, action: PayloadAction<string>) {
        //     state.contentPage = action.payload
        // },
        // // ユーザコンテントページ変更.
        // changeUserContentPage(state: UserState, action: PayloadAction<string>) {
        //     state.contentPage = action.payload
        // },
        // // ユーザページ変更.
        // changeUserPage(state: UserState, action: PayloadAction<string>) {
        //     state.page = action.payload
        // },
        // // 選択中アイテム.
        // setSelectedUser(state: UserState, action: PayloadAction<User | undefined>) {
        //     state.selectedItem = action.payload
        // },
        resetState(state: UserState, action: PayloadAction<undefined>) {
            state = { ...initialState }
            console.log('## RESET')
            console.log(state)
        },
    },
    extraReducers: (builder) => {
        // fetch
        builder.addCase(fetchUsersByFacilityId.fulfilled, (state, action) => {
            const users = action.payload.map((user: User) => {
                return { ...user, icon: undefined }
            })

            return { ...state, users: users }
        })
        builder.addCase(fetchUsersByFacilityId.pending, (state, action) => {
            // console.log('pending')
        })
        builder.addCase(fetchUsersByFacilityId.rejected, (state, action) => {
            // console.log('rejected')
        })

        // update handicaps
        builder.addCase(patchUserHandicap.fulfilled, (state, action) => {
            let user = state.users.find((t) => t.user_id === action.payload.item.user_id)
            if (user !== undefined) {
                // set new value
                user.handicaps = action.payload.item.handicaps
                // users except target user.
                let users = state.users.filter((t) => t.user_id !== action.payload.item.user_id)
                // merge user,
                state.users = [...users, user]
                // [for check console]
                // state.users.map((user) => {
                //     console.log('user:', user.user_name, user.handicaps)
                // })
            }
        })

        // fetch
        builder.addCase(fetchUsers.fulfilled, (state, action) => {
            // console.log( 'fulfilled' );
            return { ...state, users: action.payload }
        })
        builder.addCase(fetchUsers.pending, (state, action) => {
            // console.log('pending')
        })
        builder.addCase(fetchUsers.rejected, (state, action) => {
            // console.log('rejected')
        })
        // fetch by id
        builder.addCase(fetchUserById.fulfilled, (state, action) => {
            // console.log('fulfilled')
            // return { ...state, users: users }
        })
        builder.addCase(fetchUserById.pending, (state, action) => {
            // console.log('pending')
        })
        builder.addCase(fetchUserById.rejected, (state, action) => {
            // console.log('rejected')
        })
        // add
        builder.addCase(createUser.fulfilled, (state, action) => {
            // console.log('fulfilled:', action.payload)
            const users = state.users.filter((t) => t.user_id !== action.payload.item.user_id)
            state.users = [...users, action.payload.item]
            // state.page = 'list'
        })
        builder.addCase(createUser.pending, (state, action) => {
            // console.log('pending')
        })
        builder.addCase(createUser.rejected, (state, action) => {
            // console.log('rejected')
        })
        // update
        builder.addCase(updateUserById.fulfilled, (state, action) => {
            console.log('fulfilled:', action.payload)
            // return {　...state,　users: action.payload　}
            let users = state.users.filter((t) => t.user_id !== action.payload.item.user_id)
            state.users = [...users, action.payload.item]
            // state.page = 'list'
        })
        builder.addCase(updateUserById.pending, (state, action) => {
            console.log('pending')
        })
        builder.addCase(updateUserById.rejected, (state, action) => {
            console.log('rejected')
        })
        // delete
        builder.addCase(deleteUser.fulfilled, (state, action) => {
            console.log('fulfilled')
            console.log(action.payload)
            state.users = state.users.filter((t) => t.user_id !== action.payload.id)
            // state.page = 'list'
        })
        builder.addCase(deleteUser.pending, (state, action) => {
            console.log('pending')
        })
        builder.addCase(deleteUser.rejected, (state, action) => {
            console.log('rejected')
        })
    },
})

export const useUserItems = () => {
    const { users } = useSelector((state: RootState) => state.user)
    return users
    //    return useSelector((state: ReturnType<typeof UserModule.reducer>) => state.users);
}
export const useUserItemsWithId = (user_id: string) => {
    const { users } = useSelector((state: RootState) => state.user)
    const items = users.filter((user) => user.user_id === user_id)
    if (items === null || items.length === 0) {
        return undefined
    }
    return items[0]
}
export const useUserById = (id: string) => {
    return useSelector((state: RootState) => state.user.users.find((user: User) => user?.user_id === id))
}
// export const useUserPage = () => {
//     return useSelector((state: RootState) => state.user.page)
//     //    return useSelector((state: ReturnType<typeof UserModule.reducer>) => state.users);
// }
// export const useSelectedUser = () => {
//     return useSelector((state: RootState) => state.user.selectedItem)
// }

// export const useContentPage = () => {
//     return useSelector((state: RootState) => state.user.contentPage)
//     //    return useSelector((state: ReturnType<typeof UserModule.reducer>) => state.users);
// }

export const useIsMaxCreatedUser = () => {
    const dispatch = useAppDispatch()
    const [isMax, setIsMax] = useState<boolean>(false)
    const {
        app: { facility },
        user: { users },
    } = useSelector((state: RootState) => state)
    const facilityId = facility?.facility_id
    const maxUser = facility?.max_users
    const totalUser = users.length
    useEffect(() => {
        if (facilityId) {
            dispatch(fetchUsersByFacilityId(facilityId))
        }
    }, [dispatch, facilityId])
    useEffect(() => {
        if (maxUser) {
            setIsMax(totalUser >= maxUser)
        }
    }, [maxUser, totalUser])

    return isMax
}

// Action Creatorsをエクスポート
export const {
    // changeUserPage, setSelectedUser, changeContentPage, changeUserContentPage,
    resetState,
} = UserModule.actions

// Slice をエクスポートする
export default UserModule
