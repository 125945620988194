/**
 * Lesson Log Provider.
 *
 * Data Version:
 * v0: simple sequence / deflate
 */

// import * as AppConfig from '../config'
import * as ApiClient from './ApiClient'
import * as VideoControl from 'modules/VideoController'

// 動作定義.
const LESSON_LOG_CONTINUOUS = true // レッスンログがない場合にも継続可能か.

// レッスンログのデータバージョン.
enum LessonLogVersion {
    v0,
}
// 圧縮タイプ.
// export enum EncodingType {
//     gzip = 'gzip', // Lempel-Ziv coding (LZ77) を使用し、32ビットの CRC が付いた形式です。これは UNIX の gzip プログラムの独自形式です。
//     compress = 'compress', // Lempel-Ziv-Welch (LZW) アルゴリズムを使用した形式
//     deflate = 'deflate', // zlib 構造 (RFC 1950 で定義) の deflate 圧縮アルゴリズム (RFC 1951 で定義)
//     identity = 'identity', // 等価関数 (つまり、圧縮も変更もなし)
//     br = 'br', // Brotli アルゴリズム
// }
export enum EncodingType {
    gzip = 0, // Lempel-Ziv coding (LZ77) を使用し、32ビットの CRC が付いた形式です。これは UNIX の gzip プログラムの独自形式です。
    compress, // Lempel-Ziv-Welch (LZW) アルゴリズムを使用した形式
    deflate, // zlib 構造 (RFC 1950 で定義) の deflate 圧縮アルゴリズム (RFC 1951 で定義)
    identity, // 等価関数 (つまり、圧縮も変更もなし)
    br, // Brotli アルゴリズム
}

// レッスンログ（時系列の視線ベクトル）
export type LessonLog = {
    t: number // 先頭からの時刻.(msec)
    v: string // 視線ベクトル( "x,y,z" )
}
// レッスンログパケット(受信データの構造定義)
type LessonLogPacket = {
    version: LessonLogVersion // データバージョン.
    meta: {
        lessonId: string // レッスンID.
        date: string // 作成日時.(yyyy-MM-dd HH:mm:ss)
        encoding: string // 圧縮のメディア種別
    }
    data: any // ログデータ.
}

// レッスンログの状態.
export enum LessonLogState {
    not_ready,
    loading,
    playing,
    paused,
    end,
}

// ログ.
var logs: LessonLog[] | null = null
// 現在のインデックス.
var index: number = 0
// タイマーオブジェクト.
var intervalObj: number | null = null
// コールバックエントリ.
var onEndCallback: (() => void) | null = null
// レッスンログの状態.
var lessonLogState: LessonLogState = LessonLogState.not_ready

// ------------------------------------------------------------------
// Public Functions.
// ------------------------------------------------------------------
// レッスンログを継続可能にするか.
export function isAvailable() {
    console.log(`isAvailable: state=${LessonLogState[lessonLogState]}`)
    if (LESSON_LOG_CONTINUOUS) {
        // case ビデオ操作継続可能.
        return true
    } else {
        // case 強制停止 -> レッスンログがない部分を再生できないようにする.
        return lessonLogState !== LessonLogState.end
    }
}
export function hasData() {
    return logs !== null
}

// レッスンログの取得.（ダウンロード）
export async function load(lesson_id: string) {
    return new Promise(async (resolve, reject) => {
        try {
            logs = null
            console.log(`LessonLog: loading ${lesson_id}`)
            const url = `/lessons/${lesson_id}/log`
            const response = await ApiClient.get(url)
            // console.log('####', response)
            if (response.error) {
                throw new Error('Not Found')
            }
            const packet = response.data as LessonLogPacket
            console.log(`LessonLog: version=${packet.version}`, packet.meta)
            const enctype: EncodingType = (EncodingType as any)[packet.meta.encoding]

            console.log(`LessonLog:enctype:${enctype}:${packet.meta.encoding}`)
            // logs = packet.data
            logs = await decoder(packet.data, enctype)
            // console.log('Inflated:', inflated)
            console.log(`LessonLog: detect ${logs?.length} log(s)`)
            // console.log('logs ', logs)
            // logs = inflated as LessonLog[];
            index = 0
            return resolve('OK')
        } catch (e) {
            setState(LessonLogState.end)
            console.log('LessonLog: ダウンロードに失敗', e)
            return reject('NG')
        }
    })
}

// 終了コールバックの登録.
export function registerEndListener(callback: () => void) {
    // console.log('LessonLog: onEnd()')
    onEndCallback = callback
}
// レッスンログ再生開始.
export function start() {
    // console.log(`LessonLog: start at ${index}`)
    setState(LessonLogState.playing)

    if (intervalObj !== null) {
        console.log('already running')
        return
    }
    if (logs !== null) {
        if (index >= logs.length) {
            // ログ終端以降では開始しない.
            return
        }

        // タイマー制御開始.F
        intervalObj = setInterval(() => {
            const packet = getNext()
            if (packet === 'end') {
                stop()
                setState(LessonLogState.end)
                if (onEndCallback !== null) {
                    console.log('終了コールバック')
                    onEndCallback()
                }
                return
            }
            if (packet === null) {
                return
            }
            VideoControl.doPosture(packet.v)
        }, 1000 / 30)
    }
}
// レッスンログ再生停止.
export function stop() {
    // console.log('LessonLog: stop')
    // タイマー停止.
    if (intervalObj !== null) {
        console.log('timer off')
        clearInterval(intervalObj)
        intervalObj = null
    }
    setState(LessonLogState.paused)
}
// レッスンログ終了.
export function end() {
    // console.log('### LessonLog: END')
    stop()
    setState(LessonLogState.end)
    // データを解放
    logs = null
}
// レッスンログの再生インデックスを先頭に戻す.
export function rewind() {
    index = 0
    start()
}

// ------------------------------------------------------------------
//  Private Functions
// ------------------------------------------------------------------
/**
 * 時刻: t の視線ベクトルを取得.
 * @export
 * @param {number} t
 * @returns {number[]}
 */
// function getLogVector(t: number): number[] {
//     var v = [1.0, 1.0, 1.0]
//     return v
// }

// 状態の設定.
function setState(state: LessonLogState) {
    // console.log(`LessonLog: setState:${LessonLogState[state]}`)
    lessonLogState = state
}

/**
 * デコーダー
 *
 * @param {*} data
 * @param {EncodingType} enctype
 * @returns {Promise<any>}
 */
async function decoder(data: any, enctype: EncodingType): Promise<any> {
    return new Promise((resolve, reject) => {
        try {
            switch (+enctype) {
                case EncodingType.deflate:
                    var zlib = require('zlib')
                    var buffer = Buffer.from(data, 'base64')
                    zlib.inflateRaw(buffer, function (err: any, result: any) {
                        if (err) {
                            console.log('展開失敗', err)
                            return reject()
                        }
                        // console.log('Inflated:', result.toString())
                        const json = result.toString()
                        logs = JSON.parse(json).target
                        return resolve(logs)
                    })
                    break
                case EncodingType.identity:
                    return resolve(data)
                default:
                    console.log(`LessonLog: Error : 未対応エンコードが指定されました ${enctype}`)
                    return reject()
            }
        } catch (err) {
            console.log(`LessonLog: ${err}`)
            reject()
        }
    })
}
/**
 * 次のデータを取得
 * - タイマーからコールされる
 * - { t:N, v:"X,Y,Z" } -> { f:N, v:{x:X,y:Y,z:Z} } に変換して返す.
 * @returns {*}
 */
function getNext(): any {
    if (logs === null) {
        // ログがロードされていない
        return null
    }
    if (VideoControl.isSeeking()) {
        return null
    }
    if (index >= logs.length) {
        console.log('LessonLog: replay end')
        return 'end'
    }
    const log: LessonLog = logs[index++]
    // return log;
    if (log.v === undefined) {
        return null
    }
    const arr = log.v.split(',')
    return { f: log.t, v: { x: arr[0], y: arr[1], z: arr[2] } }
}
