import clsx from 'clsx'
import { isUndefined, isNull } from 'util'

import React from 'react'

// redux
import { useAppDispatch } from '../store'
import { useHomePageUrl } from '../modules/AppModule'
import { updateUserMspaEvaluation } from '../modules/StaffModule'

// for validation
import { useForm } from 'react-hook-form'

// Material-UI
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Container, Grid, Box, Paper, FormControlLabel } from '@material-ui/core'

// Users
import { MspaEvaluation, MspaEvaluationId, MspaLevel, Lesson, LessonStatus } from '../Types'
import { MainButton } from '../components/Buttons'
import { InputCheckBox, InputTextField, InputRating } from '../components/Inputs'
import StaffUserInfoCell from '../components/StaffUserInfoCell'
import TitleBar from './TitleBar'
import RadarChart from './RadarChart'
import StaffUserGrowthMSPA from './StaffUserGrowthMSPA'
import { useHistory, useParams } from 'react-router-dom'
import { useFacilityUserById } from 'modules/FacilityModule'
import { fetchLessonsByUser } from 'modules/LessonModule'
// スタイルを適用する
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            padding: 0,
            width: 960,
            margin: '0 auto',
            // backgroundColor: "#00FF00",
        },
        container: {
            display: 'flex',
            justifyContent: 'left',
            // alignItems: 'stretch',
            border: '1px solid #6E6E6E',
            backgroundColor: theme.palette.common.white,
            paddingBottom: 10,
        },
        left_paper: {
            // margin: theme.spacing(1),
            padding: theme.spacing(1, 2),
            textAlign: 'center',
            width: '42%',
            // width: 350,
            flexShrink: 0,

            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'center',
        },
        right_paper: {
            width: '58%',
            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'space-between',
            //     justifyContent: 'center',
            //     alignItems: 'stretch',
        },
        radar_paper: {
            // height: '20%',
            width: '100%',
        },
        radar: {
            marginTop: 0,
            padding: 20,
            height: '100%',
            position: 'relative',
        },

        box: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        checkbox: {
            width: 170,
        },
        bottom_container: {
            display: 'flex',
            margin: '20px 0',
        },
        comment: {
            width: '100%',
            flex: 1,
            marginRight: 20,
            '&:last-child': {
                marginRight: 0,
            },
        },
        submit: {
            fontSize: 'calc(20px)', // default: 16px
            margin: theme.spacing(3, 0, 2), // 上下マージン.
            padding: theme.spacing(1, 0, 1, 0), // パディング
        },
        form: {
            width: '100%',
            marginTop: 5,
        },
        btnDialog: {
            marginTop: 18,
        },
        staffUserInfo: {
            padding: '35px 0 23px',
        },
    }),
)

// 1,2,...9 => 1,1.5,...5
const convertRatingToValue = (rating: number | null) => (isNull(rating) ? rating : (rating - 1) / 2 + 1)
// 1,1.5,...5 => 1,2...9
const convertValueToRating = (value: number | null) => (isNull(value) ? value : (value - 1) * 2 + 1)

// MSPA評価指標定義テーブル.
const mspa_table: { mspa_evaluation_id: MspaEvaluationId; label: string }[] = [
    {
        mspa_evaluation_id: MspaEvaluationId.communication,
        label: 'コミュニケーション',
    },
    {
        mspa_evaluation_id: MspaEvaluationId.group_adaptability,
        label: '集団適応力',
    },
    { mspa_evaluation_id: MspaEvaluationId.empathy, label: '共感性' },
    { mspa_evaluation_id: MspaEvaluationId.commitment, label: 'こだわり' },
    { mspa_evaluation_id: MspaEvaluationId.sense, label: '感覚' },
    {
        mspa_evaluation_id: MspaEvaluationId.repetitive_exercise,
        label: '反復運動',
    },
    {
        mspa_evaluation_id: MspaEvaluationId.oversized_movement,
        label: '粗大運動',
    },
    {
        mspa_evaluation_id: MspaEvaluationId.fine_coordination,
        label: '微細協調運動',
    },
    { mspa_evaluation_id: MspaEvaluationId.carelessness, label: '不注意' },
    { mspa_evaluation_id: MspaEvaluationId.hyperactivity, label: '多動性' },
    { mspa_evaluation_id: MspaEvaluationId.impulsivity, label: '衝動性' },
    { mspa_evaluation_id: MspaEvaluationId.sleep_rhythm, label: '睡眠リズム' },
    { mspa_evaluation_id: MspaEvaluationId.learning, label: '学習' },
    {
        mspa_evaluation_id: MspaEvaluationId.language_development_history,
        label: '言語発達歴',
    },
]

type LocalState = {
    note: string
    characteristic: string
}

/**
 * MSPA 評価指標
 * 設定項目: 全て表示
 * レーダー: 設定値の存在する項目のみ
 * @export
 * @returns
 */
export default function StaffUserMSPA() {
    const dispatch = useAppDispatch()
    const classes = useStyles()

    const homePageUrl = useHomePageUrl()
    const history = useHistory()
    const { userId } = useParams()
    const user = useFacilityUserById(userId)
    const [userLessons, setUserLessons] = React.useState<Lesson[]>([])

    // redirect to list page if id isn't loaded
    if (userId && isUndefined(user)) {
        handleClose()
    }

    const { register, handleSubmit, errors } = useForm<LocalState>()

    const user_id = user?.user_id

    // MSPA評価指標を取得設定
    const mspaLevels =
        isUndefined(user) || isUndefined(user.mspa_evaluation) || isUndefined(user.mspa_evaluation.mspa_levels) ? [] : user.mspa_evaluation.mspa_levels

    // 表示用データに加工
    const levels = mspa_table.map((row) => {
        const target = mspaLevels.find((v) => {
            return v.mspa_evaluation_id === row.mspa_evaluation_id
        })
        return {
            mspa_id: row.mspa_evaluation_id,
            label: row.label,
            checked: true,
            value: isUndefined(target) ? 0 : (target.level - 1) / 2 + 1,
        }
    })

    const [state, setState] = React.useState({
        levels: levels,
        characteristic:
            isUndefined(user) || isUndefined(user.mspa_evaluation) || isUndefined(user.mspa_evaluation.characteristic)
                ? ''
                : user.mspa_evaluation.characteristic,
        note: isUndefined(user) || isUndefined(user.mspa_evaluation) || isUndefined(user.mspa_evaluation.note) ? '' : user.mspa_evaluation.note,
    })
    const radarItems = state.levels
        .filter((item) => item.value >= 1)
        .map((item) => {
            return { label: item.label, value: item.value }
        })

    // 送信.
    // TODO: 現在 MSPA評価指標値は useForm の対象外としているため、state から情報を取得しているが、統合したい.
    const onSubmit = (data: LocalState) => {
        console.log('MSPA送信')
        const mspa_levels: MspaLevel[] = state.levels
            .filter((item) => item.value >= 1)
            .map((item) => {
                return {
                    level: (item.value - 1) * 2 + 1,
                    mspa_evaluation_id: item.mspa_id,
                }
            })
        var mspaEvaluation: MspaEvaluation = {
            mspa_levels: mspa_levels,
            characteristic: data.characteristic,
            note: data.note,
        }
        if (user_id !== undefined) {
            dispatch(
                updateUserMspaEvaluation({
                    user_id: user_id,
                    mspaEvaluation: mspaEvaluation,
                }),
            ).then(() => handleClose())
        }
    }
    function handleClose() {
        history.push(`${homePageUrl}/facility-users`)
    }
    // チェックボックス変更
    const handleChange = (mspa_id: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const levels = state.levels.map((row) => {
            if (row.mspa_id === mspa_id) {
                row.checked = event.target.checked
            }
            return row
        })
        setState({ ...state, levels: levels })
    }
    const handleChangeRating = (mspa_id: string, value: number | null) => {
        const levels = state.levels.map((row) => {
            if (row.mspa_id === mspa_id) {
                row.value = isNull(value) ? 1 : Math.min(Math.max(value, 1), 5)
            }
            return row
        })
        setState({ ...state, levels: levels })
    }

    const [openDialog, setOpenDialog] = React.useState(false)

    const handleClickDialog = () => {
        setOpenDialog(!openDialog)
    }

    const useEffectOnMount = (effect: React.EffectCallback) => {
        React.useEffect(effect, [])
    }
    useEffectOnMount(() => {
        handleFetchUserLessons()
    })
    const handleFetchUserLessons = () => {
        dispatch(
            fetchLessonsByUser({
                user_id: userId,
                start: '-',
                end: '-',
            }),
        ).then((res) => {
            const data = res.payload as Lesson[]
            if (data && data.length > 0) {
                setUserLessons(data)
            }
        })
    }
    const isUserDuringTraining = () => {
        if (user && userLessons && userLessons.length) {
            const lessonDuringTraining = userLessons.find((lessonItem: Lesson) => {
                return lessonItem.status === LessonStatus.progressing
            })
            return lessonDuringTraining ? true : false
        }
        return false
    }

    return (
        <React.Fragment>
            {/* <Typography>てすと</Typography> */}
            <Grid container className={classes.root}>
                <Grid item xs={12} className={classes.staffUserInfo}>
                    <StaffUserInfoCell user={user} active={!!isUserDuringTraining()} />
                </Grid>
                <Grid item xs={12}>
                    <TitleBar bgBlue label="要支援度（MSPA評価指標）" />
                </Grid>
                <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
                    <Grid item xs={12}>
                        <Box className={classes.container}>
                            <Paper square className={classes.left_paper}>
                                {state.levels.map((item) => (
                                    <Box className={classes.box} key={item.mspa_id}>
                                        {/* チェックボックス＋ラベル */}
                                        <FormControlLabel
                                            control={
                                                <InputCheckBox
                                                    checked={item.checked}
                                                    onChange={handleChange(item.mspa_id)}
                                                    // value="CheckboxA"
                                                />
                                            }
                                            label={item.label}
                                            className={classes.checkbox}
                                        />
                                        {/* レーティング */}
                                        <InputRating
                                            // disabled={!item.checked}
                                            id={'rating' + item.mspa_id}
                                            name={'rating' + item.mspa_id}
                                            value={convertValueToRating(item.value)}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>, newValue: number | null) => {
                                                handleChangeRating(item.mspa_id, convertRatingToValue(newValue))
                                            }}
                                            size="large"
                                            max={9}
                                        />
                                    </Box>
                                ))}
                                <MainButton className={classes.btnDialog} fullWidth onClick={handleClickDialog}>
                                    選択した指標の成長記録を表示
                                </MainButton>
                                <StaffUserGrowthMSPA
                                    user_id={user_id}
                                    mspa_ids={state.levels
                                        .filter((row) => {
                                            return row.checked
                                        })
                                        .map((row) => {
                                            return row.mspa_id
                                        })}
                                    open={openDialog}
                                    onClose={handleClickDialog}
                                />
                            </Paper>
                            <Paper square className={classes.right_paper}>
                                <Box className={classes.radar}>
                                    <RadarChart data={radarItems} />
                                </Box>
                                <Box className={classes.radar_paper}>
                                    <RadarDescription />
                                </Box>
                            </Paper>
                        </Box>
                    </Grid>

                    {/* コメント入力欄 */}
                    <Grid item xs={12}>
                        <Paper square className={classes.bottom_container}>
                            <InputTextField
                                className={classes.comment}
                                margin="none"
                                fullWidth
                                id="characteristic"
                                name="characteristic"
                                label="得意分野、特長とその程度"
                                defaultValue={state.characteristic}
                                multiline
                                rows={7}
                                inputRef={register({
                                    //  required: true,
                                })}
                                error={Boolean(errors.characteristic)}
                                helperText={errors.characteristic && '得意分野、特長とその程度は必須です'}
                            />
                            <InputTextField
                                className={classes.comment}
                                margin="none"
                                fullWidth
                                id="note"
                                name="note"
                                label="その他の特記すべき情報"
                                defaultValue={state.note}
                                multiline
                                rows={7}
                                inputRef={register({
                                    //  required: true
                                })}
                                error={Boolean(errors.note)}
                                helperText={errors.note && 'その他の特記すべき情報は必須です'}
                            />
                        </Paper>
                    </Grid>
                    <Footer />
                </form>
            </Grid>
        </React.Fragment>
    )
    function Footer() {
        return (
            <Container component="main" maxWidth="xs">
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        {/* 更新. */}
                        <MainButton
                            fullWidth
                            type="submit"
                            // onClick={onSubmit}
                        >
                            更新
                        </MainButton>
                    </Grid>
                    <Grid item xs={6}>
                        {/* 閉じる. */}
                        <MainButton fullWidth onClick={handleClose}>
                            閉じる
                        </MainButton>
                    </Grid>
                </Grid>
            </Container>
        )
    }
}
// レーダー説明用のスタイルを適用する
const useStyles2 = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: '8px',
            width: '100%',
            // display: 'flex',
            // flexFlow: 'column',
            // justifyContent: 'space-between',
            //             alignItems: 'center',
        },
        row: {
            display: 'flex',
            flexFlow: 'row',
            justifyContent: 'space-between',
        },
        item: {
            flex: 1,
            width: '100%',
            padding: 4,
            marginRight: 8,
            fontSize: 10,
            '&:last-child': {
                marginRight: 0,
            },
        },
        bar: {
            paddingTop: 1,
            paddingBottom: 1,
            backgroundColor: '#A8A8A8',
            color: '#fff',
            fontWeight: 'bold',
            marginBottom: 6,
            textAlign: 'center',
        },
        box: {
            backgroundColor: '#F6F6F6',
            display: 'flex',
            alignItems: 'center',
        },
    }),
)
// レーダー説明.
function RadarDescription() {
    const classes = useStyles2()
    return (
        <Box className={classes.root}>
            <Box className={classes.row}>
                <Paper square variant="outlined" className={clsx(classes.item, classes.bar)}>
                    1
                </Paper>
                <Paper square variant="outlined" className={clsx(classes.item, classes.bar)}>
                    2
                </Paper>
                <Paper square variant="outlined" className={clsx(classes.item, classes.bar)}>
                    3
                </Paper>
                <Paper square variant="outlined" className={clsx(classes.item, classes.bar)}>
                    4
                </Paper>
                <Paper square variant="outlined" className={clsx(classes.item, classes.bar)}>
                    5
                </Paper>
            </Box>
            <Box className={classes.row}>
                <Paper square variant="outlined" className={clsx(classes.item, classes.box)}>
                    気になる点はない
                </Paper>
                <Paper square variant="outlined" className={clsx(classes.item, classes.box)}>
                    多少気になる点はあるが、通常の生活環境において困らない
                </Paper>
                <Paper square variant="outlined" className={clsx(classes.item, classes.box)}>
                    本人の工夫や、業務の多少の配慮で集団生活に適応（上司、担任など責任ある立場の人が把握し配慮する程度）
                </Paper>
                <Paper square variant="outlined" className={clsx(classes.item, classes.box)}>
                    大幅な個別の配慮で集団生活に適応（上司、担任等の支援のみでは困難）
                </Paper>
                <Paper square variant="outlined" className={clsx(classes.item, classes.box)}>
                    集団の流れに入るより、個人がより快適な生活を送れるような支援が優先される。
                </Paper>
            </Box>
        </Box>
    )
}
