// import * as Const from '../Const'
// import { trace } from '../common/index'
import clsx from 'clsx'
import { isUndefined } from 'util'
import React from 'react'

import { useAppDispatch } from '../store'
import { useFacility, useProfile } from '../modules/AppModule'
import { useFacilityLessons, fetchFacilityLessons, setFacilityLessons } from '../modules/FacilityModule'
import { deleteLesson, updateLessonStatus } from 'modules/LessonModule'
import { getImageCacheContext } from 'modules/ImageCacheProvider'
import { getDialogContext } from 'modules/DialogProvider'

// Material-UI
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Grid, Box, TableContainer, Paper, Typography } from '@material-ui/core'
import { AddCircle } from '@material-ui/icons'

import { useHistory, useRouteMatch } from 'react-router-dom'

import {
    MultiCheckboxColumnFilter,
    MultiCheckboxWithStaffAvatarAndLoggedInStaffOnTopColumnFilter,
    MultiCheckboxWithUserAvatarColumnFilter,
    MultiLessonStatusWithoutDefaultFinishStatusCheckboxColumnFilter,
} from './react-table/filters'
import ReactTable from './react-table/ReactTable'

import { getLessonStatusText } from 'modules/DataUtil'
import { formatDateTime } from 'modules/Util'

// User
import { Lesson, LessonStatus, LessonType, Role, Staff } from '../Types'
import TitleBar from '../components/TitleBar'
import { AddButton } from '../components/Buttons'
import { StripedTablePaper } from './Tables'
import AvatarLabel from './AvatarLabel'
import MenuAction from './MenuAction'
import { useListStyles } from 'Styles'
import Popup from './Popup'
import StaffInstructionEdit from './StaffInstructionEdit'
import ReservationEdit from './ReservationEdit'
import useShowError from 'hooks/useShowError'

// スタイルを適用する
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            justifyContent: 'center',
            alignItems: 'center',
            width: 960,
            margin: '35px auto 0',
        },
        panel: {
            padding: theme.spacing(0),
        },
        table: {
            marginBottom: 20,
        },
        lessonStatus: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        request: {
            backgroundColor: '#CDEEF3',
            color: '#5A5A5A',
        },
        reserved: {
            backgroundColor: '#CDEEF3',
            color: '#5A5A5A',
        },
        finish: {
            backgroundColor: '#B6B6B6',
            color: '#FFFFFF',
        },
        progressing: {
            backgroundColor: '#05A9C2',
            color: '#FFFFFF',
        },
    }),
)

type StaffInstructionListProps = {}
export default function StaffInstructionList(props: StaffInstructionListProps) {
    const dispatch = useAppDispatch()
    const classes = useStyles()
    const listClasses = useListStyles()
    const { showDialog } = React.useContext(getDialogContext())

    const history = useHistory()
    const match = useRouteMatch()
    const listPageUrl = match.url

    const facility = useFacility()
    const facility_id = isUndefined(facility) || isUndefined(facility.facility_id) ? '' : facility.facility_id
    const staffProfile = useProfile() as Staff
    const staffProfileId = isUndefined(staffProfile) || isUndefined(staffProfile.staff_id) ? '' : staffProfile.staff_id
    const isManagerStaff = staffProfile?.role_id === Role.manager

    const [isLoading, setIsLoading] = React.useState(false)
    const [openPopup, setOpenPopup] = React.useState(false)
    const [recordForEdit, setRecordForEdit] = React.useState<Lesson | null>(null)

    //
    const imageCache = React.useContext(getImageCacheContext())

    const getLessonList = React.useCallback(() => {
        if (!facility_id) return
        setIsLoading(true)
        dispatch(
            fetchFacilityLessons({
                facility_id: facility_id,
                start: '-',
                end: '-',
            }),
        ).then((res) => {
            const users = res.payload
                .filter((lesson: Lesson) => {
                    return lesson.user !== null && lesson.user !== undefined
                })
                .map((lesson: Lesson) => {
                    return lesson.user
                })
            imageCache.addUsers(users)
            const staffs = res.payload.map((lesson: Lesson) => {
                return lesson.staff
            })
            imageCache.addStaffs(staffs)
            setIsLoading(false)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [facility_id])

    React.useEffect(
        () => getLessonList(),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dispatch, facility_id],
    )

    const lessons = useFacilityLessons()

    const handleClickCell = (id: string) => {
        console.log('Clicked')
        history.push(`${listPageUrl}/${id}`)
    }

    // [承認]
    const handleApproveLesson = React.useCallback(
        (lessonId: string) => {
            let param = {
                lesson_id: lessonId,
                status: LessonStatus.reserved,
            }
            dispatch(updateLessonStatus(param)).then(() => {
                let newLessons = [...lessons].map((lesson) => (lesson.lesson_id === lessonId ? { ...lesson, status: LessonStatus.reserved } : lesson))
                dispatch(setFacilityLessons(newLessons))
            })
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [lessons],
    )

    // [削除]
    const handleDeleteLessonClick = React.useCallback(
        (lessonId: string) => {
            console.log('*** 削除')

            showDialog({
                title: '本当に削除してもよろしいですか？',
                message: 'この操作は戻せません',
                items: [
                    {
                        label: 'はい',
                        onClickHandler: () => {
                            dispatch(deleteLesson(lessonId)).then(() => {
                                let newLessons = lessons.filter((newLesson) => newLesson.lesson_id !== lessonId)
                                dispatch(setFacilityLessons(newLessons))
                            })
                        },
                    },
                    { label: 'いいえ' },
                ],
            })
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [lessons],
    )

    const columns = React.useMemo(
        () => [
            {
                Header: '受講状況',
                widthPx: '140px',
                accessor: 'status',
                disableSortBy: true,
                Filter: MultiLessonStatusWithoutDefaultFinishStatusCheckboxColumnFilter,
                filter: 'multiple',
                Cell: ({ value, row }: any) => {
                    const staffId = row.original.staff.staff_id
                    const lessonId = row.original.lesson_id
                    const lessonType = row.original.lesson_type
                    const { showError, ErrorMsg } = useShowError('担当以外の予約の承認はできません。')
                    return (
                        <>
                            <StripedTablePaper
                                square
                                className={clsx(classes.lessonStatus, classes[value as LessonStatus])}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    if (value !== LessonStatus.request || lessonType === LessonType.concurrent) return
                                    if (isManagerStaff || staffProfileId === staffId) {
                                        handleApproveLesson(lessonId)
                                    } else {
                                        showError()
                                    }
                                    return
                                }}
                            >
                                {getLessonStatusText(value)}
                            </StripedTablePaper>
                            {ErrorMsg}
                        </>
                    )
                },
            },
            {
                Header: 'ユーザ名',
                widthPx: '180px',
                accessor: 'user.user_name',
                disableSortBy: true,
                Filter: MultiCheckboxWithUserAvatarColumnFilter,
                filter: 'multiple',
                Cell: ({ value, row }: any) => {
                    const lessonType = row.original.lesson_type
                    return (
                        <Box style={{ padding: 4 }}>
                            {lessonType !== LessonType.concurrent ? (
                                <AvatarLabel title={value} type="avatar_on_right" url={imageCache.getUserImage(row.original.user)} />
                            ) : (
                                <AvatarLabel title="☆一斉指導" type="avatar_on_right_group" />
                            )}
                        </Box>
                    )
                },
            },
            {
                Header: 'カリキュラム名・トレーニング名',
                widthPx: '276px',
                accessor: 'course.course_name',
                disableSortBy: true,
                Filter: MultiCheckboxColumnFilter,
                filter: 'multiple',
                Cell: ({ value, row }: any) => {
                    const trainingName = row.original.training.training_name
                    return (
                        <StripedTablePaper square>
                            {value}&nbsp;&nbsp;&nbsp;&nbsp;{trainingName}
                        </StripedTablePaper>
                    )
                },
            },
            {
                Header: '支援スタッフ',
                widthPx: '180px',
                accessor: 'staff.staff_name',
                disableSortBy: true,
                Filter: MultiCheckboxWithStaffAvatarAndLoggedInStaffOnTopColumnFilter,
                filter: 'multiple',
                Cell: ({ value, row }: any) => {
                    return (
                        <Box style={{ padding: 4 }}>
                            {<AvatarLabel title={value} type="avatar_on_right" url={imageCache.getStaffImage(row.original.staff)} />}
                        </Box>
                    )
                },
            },
            {
                Header: '予約日時',
                widthPx: '130px',
                accessor: 'start',
                sortType: 'basic',
                disableFilters: true,
                Cell: ({ value }: any) => {
                    return (
                        <Box style={{ padding: 4 }}>
                            <Typography variant="h5">{formatDateTime(value)}</Typography>
                        </Box>
                    )
                },
            },
            {
                Header: '編集',
                widthPx: '54px',
                disableSortBy: true,
                disableFilters: true,
                Cell: ({ row }: any) => {
                    const lesson = row.original
                    const lessonStatus = lesson.status
                    const staffId = row.original.staff.staff_id
                    return (
                        staffProfileId === staffId && (
                            <MenuAction
                                menuList={[
                                    { label: '編集', disable: lessonStatus !== LessonStatus.reserved, onClick: () => openInPopup(lesson) },
                                    {
                                        label: '削除',
                                        disable: lessonStatus !== LessonStatus.reserved,
                                        onClick: () => handleDeleteLessonClick(lesson.lesson_id),
                                    },
                                ]}
                            />
                        )
                    )
                },
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [handleApproveLesson, handleDeleteLessonClick, imageCache, staffProfileId, isManagerStaff],
    )

    const initialState = React.useMemo(() => {
        return {
            sortBy: [{ id: 'start', asc: true }],
            // hiddenColumns: ['staff.staff_name'],
        }
    }, [])

    const handleAfterAddOrEdit = () => {
        setRecordForEdit(null)
        closePopup()
        // Re render lesson list
        getLessonList()
    }

    const openInPopup = (item: Lesson) => {
        setRecordForEdit(item)
        setOpenPopup(true)
    }

    const closePopup = () => {
        setOpenPopup(false)
    }

    return (
        <React.Fragment>
            <Grid container className={classes.root}>
                <Grid item xs={12}>
                    <Box className={classes.panel}>
                        <TitleBar
                            label="指導リスト"
                            bgBlue
                            right={
                                <AddButton
                                    icon={<AddCircle fontSize="inherit" />}
                                    onClickHandler={() => {
                                        setOpenPopup(true)
                                        setRecordForEdit(null)
                                    }}
                                />
                            }
                            endRight
                        />
                        <Grid container className={listClasses.wrapper}>
                            <TableContainer component={Paper} className={classes.table}>
                                <ReactTable
                                    columns={columns}
                                    data={lessons}
                                    initialState={initialState}
                                    accessor="lesson_id"
                                    isLoading={isLoading}
                                    onCellClick={handleClickCell}
                                />
                            </TableContainer>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
            {recordForEdit?.lesson_type !== LessonType.individual ? (
                <Popup title="一斉指導予約" openPopup={openPopup} setOpenPopup={setOpenPopup}>
                    <StaffInstructionEdit recordForEdit={recordForEdit} onAfterAddOrEditHandler={handleAfterAddOrEdit} onCloseHandler={closePopup} />
                </Popup>
            ) : (
                <Popup title="指導予約" openPopup={openPopup} setOpenPopup={setOpenPopup}>
                    <ReservationEdit recordForEdit={recordForEdit} onAfterAddOrEditHandler={handleAfterAddOrEdit} onCloseHandler={closePopup} />
                </Popup>
            )}
        </React.Fragment>
    )
}
