// import * as Const from '../Const'
// import {trace} from '../common/index'
import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Grid, TableHead } from '@material-ui/core'
import { Facility } from '../Types'
import { RoundedButton } from '../components/Buttons'
import { StripedTableRow, StripedTableCell } from './Tables'
import { formatDateTime } from '../modules/Util'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // ボタン(トレーニング設定, 職員一覧)
        button: {
            width: '80%',
            height: '80%',
        },
        row: {
            '&:hover': {
                cursor: 'pointer',
            },
        },
    }),
)

/**
 * Props
 *
 * @interface FacilityCellProps
 */
interface FacilityCellProps {
    data: Facility
    onClickHandler?: (event: React.MouseEvent<unknown, MouseEvent>) => void
    onClickTrainingSettingHandler?: (event: React.MouseEvent<unknown, MouseEvent>) => void
    onClickStaffHandler?: (event: React.MouseEvent<unknown, MouseEvent>) => void
}
export interface TrainingSetting {
    min_level: number // 最低レベル.
    max_level: number // 最高レベル.
    min_license: number // 最低ライセンス.
    max_license: number // 最高ライセンス.
}

/**
 * 施設セル.
 * @export
 * @param {FacilityCellProps} props
 * @returns セルのJSX 要素
 */
export function FacilityCell(props: FacilityCellProps) {
    const classes = useStyles()
    // セルがクリックされた.
    const handleClick = (event: React.MouseEvent<unknown, MouseEvent>) => {
        if (props.onClickHandler !== undefined) {
            props.onClickHandler(event)
        }
    }
    // トレーニング設定.
    const handleClickTrainingSetting = (event: React.MouseEvent<unknown, MouseEvent>) => {
        if (props.onClickTrainingSettingHandler !== undefined) {
            props.onClickTrainingSettingHandler(event)
        }
    }
    // 職員一覧
    const handleClickStaff = (event: React.MouseEvent<unknown, MouseEvent>) => {
        if (props.onClickStaffHandler !== undefined) {
            props.onClickStaffHandler(event)
        }
    }
    return (
        <StripedTableRow className={classes.row} onClick={(event) => handleClick(event)}>
            <StripedTableCell>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        {props.data.facility_name}
                    </Grid>
                    <Grid item xs={12}>
                        {props.data.address}
                    </Grid>
                </Grid>
            </StripedTableCell>
            <StripedTableCell align="center">{props.data.manager_name}</StripedTableCell>
            <StripedTableCell align="center">
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        {formatDateTime(props.data.created_at)}
                    </Grid>
                    <Grid item xs={12}>
                        {formatDateTime(props.data.updated_at)}
                    </Grid>
                </Grid>
            </StripedTableCell>
            <StripedTableCell align="center">
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <RoundedButton className={classes.button} variant="outlined" onClick={(event) => handleClickTrainingSetting(event)}>
                            トレーニング設定
                        </RoundedButton>
                    </Grid>
                    <Grid item xs={12}>
                        <RoundedButton className={classes.button} variant="outlined" onClick={(event) => handleClickStaff(event)}>
                            職員一覧
                        </RoundedButton>
                    </Grid>
                </Grid>
            </StripedTableCell>
        </StripedTableRow>
    )
}

/**
 * 施設セルヘッダ
 *
 * @export
 * @param {FacilityCellProps} props
 * @returns
 */
export function FacilityHeaderCell() {
    //   const handleClick = (event: React.MouseEvent<unknown, MouseEvent>) => {
    //     props.onClickHandler(event);
    //   };
    return (
        <TableHead>
            <StripedTableRow>
                <StripedTableCell width="40%" align="center">
                    施設名 <br />
                    住所
                </StripedTableCell>
                <StripedTableCell width="20%" align="center">
                    管理者氏名
                </StripedTableCell>
                <StripedTableCell width="20%" align="center">
                    作成日時 <br />
                    更新日時
                </StripedTableCell>
                <StripedTableCell align="center"></StripedTableCell>
            </StripedTableRow>
        </TableHead>
    )
}
