enum AppEnv {
    production = 'production', // AWS 本番環境
    staging = 'staging', // AWS 評価環境
    development = 'development', // AWS 開発環境
    localhost = 'localhost', // ローカル環境.
    sandbox = 'sandbox', // Sandbox環境.
}

export const APP_ENV = process.env.REACT_APP_ENV || ''

export const APP_API_BASE_URL = process.env.REACT_APP_API_URL || ''
export const APP_API_KEY =
    APP_ENV === AppEnv.production
        ? 'DrwtGiLtrQ9pBOJfdmYNCa5dNXerf2tk5r51VQGF' // 本番
        : 'DrwtGiLtrQ9pBOJfdmYNCa5dNXerf2tk5r51VQGF' // 評価

export const APP_WEBSOCKET_BASE_URL = process.env.REACT_APP_WEBSOCKET_URL || ''

export const APP_CHIME_BASE_URL = process.env.REACT_APP_CHIME_URL || ''

console.info('##### ENV:', APP_ENV)
console.info('##### API:', APP_API_BASE_URL)
console.info('##### WSS:', APP_WEBSOCKET_BASE_URL)
console.info('##### RTC:', APP_CHIME_BASE_URL)
