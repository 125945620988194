import { FormHelperText } from '@material-ui/core'
import React from 'react'

function useShowError(errorMsg: string, fadeOutTime = 2000) {
    const [error, setError] = React.useState<boolean>(false)
    React.useEffect(() => {
        if (!error) return
        setTimeout(function () {
            setError(false)
        }, fadeOutTime)
    }, [error, fadeOutTime])

    return { showError: () => setError(true), ErrorMsg: <FormHelperText error>{error && errorMsg}</FormHelperText> }
}

export default useShowError
