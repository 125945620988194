// 指導リストからの編集
// レッスン登録されたものもここで編集可能にする。（うまく統合できない場合は、一斉指導のみ編集可）
// - 単一ユーザ選択：通常レッスン（トレーニング登録されたもの）
// - 複数ユーザ選択: 一斉指導（指導リストで登録されたもの）：ユーザ一人の場合もあり。
// 表示要素
// <TITLE>
// ------------
// <カリキュラム>
// <トレーニング> :カリキュラム変更時リスト更新、初期化
// [<ユーザ>]:複数選択
// <予約日時>
//
// import * as Const from '../Const'
// import { trace } from '../common/index'
import clsx from 'clsx'
import { isUndefined, isNull } from 'util'

import React from 'react'

// Redux & Reducer Modules
import { useAppDispatch } from 'store'
import { useFacility, useProfile } from '../modules/AppModule'
import { createLesson, updateLesson, LessonParam } from '../modules/LessonModule'
import { fetchCoursesByFacilityId } from '../modules/CourseModule'
import { fetchTrainingById } from '../modules/TrainingModule'

// for validation
import { useForm, Controller } from 'react-hook-form'

// functional
import * as DateUtil from 'date-fns'

import { Container, Grid, MenuItem, Typography, Box } from '@material-ui/core'

// User
import { OptionValue, Lesson, Course, Training, Staff, User, LessonStatus, LessonType } from '../Types'
import { MainButton } from '../components/Buttons'
import { InputTextField } from './Inputs'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import './reactdatepicker.css'
import { getImageCacheContext } from 'modules/ImageCacheProvider'
import { fetchUsersByFacilityId } from 'modules/UserModule'
import { useStyles } from './Staff/Instruction/Stylex'

//----------------------------------------------------------------------------------------
// date-fns
// 日付の書式.
// function formatDate(date: Date): string {
//     return DateUtil.format(new Date(), 'yyyy-MM-dd')
// }
// // 時刻の書式
// function formatTime(date: Date): string {
//     return DateUtil.format(new Date(), 'HH:mm')
// }

type LocalState = {
    courses: Course[]
    courseOptions: OptionValue[]
    trainingOptions: OptionValue[]
    userOptions: OptionValue[]
    users: User[]
    course_id: string
}
type FormState = {
    lesson_id: string
    course_id: string
    training_id: string
    staff_id: string
    user_id: string
    user_ids: []
}

type ReservationEditProps = {
    onClickHandler?: (log: Lesson | undefined) => void
    onCloseHandler?: () => void
    onAfterAddOrEditHandler: () => void
    recordForEdit: Lesson | null
}

type FooterProps = {
    onCloseHandler?: () => void
    isSubmitting: boolean
}
function Footer(props: FooterProps) {
    const handleClose = () => {
        if (props.onCloseHandler !== undefined) {
            props.onCloseHandler()
        }
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={6}>
                {/* 更新. */}
                <MainButton type="submit" fullWidth disabled={props.isSubmitting}>
                    登録
                </MainButton>
            </Grid>
            <Grid item xs={6}>
                {/* 閉じる. */}
                <MainButton fullWidth onClick={handleClose}>
                    閉じる
                </MainButton>
            </Grid>
        </Grid>
    )
}
//----------------------------------------------------------------------------------------
export default function StaffInstructionEdit(props: ReservationEditProps) {
    const { recordForEdit, onAfterAddOrEditHandler, onCloseHandler } = props
    const isAddMode = !recordForEdit
    const imageCache = React.useContext(getImageCacheContext())
    const classes = useStyles()
    const dispatch = useAppDispatch()
    const profile = useProfile()
    const facility = useFacility()

    const [isLoadingLessions, setIsLoadingLessions] = React.useState(false)

    const [selectedDate, setSelectedDate] = React.useState<Date>(() => new Date(new Date().setHours(10, 0, 0, 0)))
    const [state, setState] = React.useState<LocalState>({
        courses: [],
        courseOptions: [],
        trainingOptions: [],
        userOptions: [],
        users: [],
        course_id: '',
    })

    // form validation
    const {
        register,
        handleSubmit,
        errors,
        formState: { isSubmitting },
        control,
        reset,
        setValue,
    } = useForm<FormState>({
        defaultValues: {
            lesson_id: '',
            course_id: '',
            training_id: '',
            staff_id: '',
            user_id: '',
            user_ids: [],
        },
    })

    React.useEffect(() => {
        if (recordForEdit !== null) {
            // Edit mode
            const users = lessonUsers(recordForEdit)
            const trainingId = getLessonTrainingId(recordForEdit)
            setValue('lesson_id', getLessonId(recordForEdit))
            setValue('course_id', getLessonCourseId(recordForEdit))
            setValue('training_id', trainingId)
            setValue('staff_id', getLessonStaffId(recordForEdit))
            setValue('user_id', getLessonUserId(recordForEdit))
            setValue('user_ids', getUserIds(users))
            setSelectedDate(new Date(getLessonStart(recordForEdit)))
            setState({
                ...state,
                users: users,
            })
            setup(recordForEdit)
        } else {
            setup()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recordForEdit])

    if (isAddMode) {
        let staffProfile = profile as Staff
        let staffId = isUndefined(staffProfile) || isUndefined(staffProfile.staff_id) ? '' : staffProfile.staff_id
        setValue('staff_id', staffId)
    }

    async function setup(lesson?: Lesson) {
        try {
            const facility_id = isUndefined(facility) || isUndefined(facility.facility_id) ? '' : facility.facility_id

            console.log('## Lesson:', lesson)

            let resUsers
            let resCourses
            try {
                ;[resCourses, resUsers] = await Promise.all([
                    // コース情報の取得.（施設のみ)
                    dispatch(fetchCoursesByFacilityId(facility_id)),
                    // 施設職員情報の取得
                    dispatch(fetchUsersByFacilityId(facility_id)),
                ])
            } catch (err) {
                console.error(err)
                throw err // １つでもエラーになったら、関数を抜ける
            }
            imageCache.addUsers(resUsers.payload)

            // 全てが正常データを取得できたとき、以下を実行
            const courses: Course[] = resCourses.payload
            const users: User[] = resUsers.payload

            console.log('#### ', courses, users)

            // ユーザオプション.
            const training = lesson?.training

            let trainingLessions: Array<Lesson> = []
            if (training && training.training_id) {
                setIsLoadingLessions(true)
                trainingLessions = await getTrainingLessions(training)
                setIsLoadingLessions(false)
            }
            const userOptions = users.map((user) => {
                let userLabel: string = ''
                if (training && training.training_id) {
                    userLabel = getUserLabel(training, user, trainingLessions)
                }

                return {
                    label: getUserName(user),
                    value: getUserId(user),
                    optionLabel: userLabel,
                }
            })
            // コースオプション.
            const courseOptions = courses.map((course) => {
                return {
                    label: getCourseName(course),
                    optionLabel: '', //finished === 0 ? '未受講' : `${finished}/${trainingSum}受講済み`,
                    value: getCourseId(course),
                }
            })
            console.log('コースオプション', courseOptions)
            // トレーニングオプション
            const course_id = getLessonCourseId(lesson)
            const trainingOptions = getTrainingOptions(courses, course_id)
            // スタッフオプション.

            setState({
                ...state,
                courses: courses,
                users: users,
                userOptions: userOptions,
                courseOptions: courseOptions,
                trainingOptions: trainingOptions,
            })
        } catch (err) {
            return
        }
    }

    // トレーニングオプションの取得.
    function getTrainingOptions(courses: Course[], course_id: string) {
        console.log('##### getTrainingOptions', course_id)
        // コース情報を検索.
        const course = courses.find((course) => course.course_id === course_id)
        console.log(course)
        // コースに属するトレーニング情報.(append TestTrainings)
        const trainings = [...getCourseTrainings(course), ...getCourseTestTrainings(course)]

        console.log(trainings)

        // オプションバリュー。
        const options: OptionValue[] = trainings.map((training) => {
            let option = ''
            if (training.option_user !== undefined) {
                switch (training.option_user.lesson_status) {
                    case LessonStatus.finish:
                        option = training.option_user.lesson_start
                        break
                    case LessonStatus.request:
                        option = '承認待ち'
                        break
                    case LessonStatus.reserved:
                        option = '予約中'
                        break
                    default:
                        option = training.option_user.lesson_status
                }
            }
            return {
                label: getTrainingName(training),
                value: getTrainingId(training),
                optionLabel: option,
            }
        })
        console.log('Training Options:', options)
        return options
    }

    const handleDateChange = (date: Date | null) => {
        if (isNull(date)) {
            console.log('date is null')
            date = new Date()
        } else {
            console.log('DATE', DateUtil.format(date, 'yyyy-MM-dd'))
        }
        setSelectedDate(date)
    }
    const handleTimeChange = (date: Date | null) => {
        if (isNull(date)) {
            console.log('time is null')
            date = new Date()
        } else {
            console.log('TIME:', DateUtil.format(date, 'HH:mm'))
        }
        setSelectedDate(date)
    }
    // 更新された コース選択、トレーニング選択.
    const handleCourseChange = (event: React.ChangeEvent<{ value: string }>) => {
        const course_id = event.target.value
        const options = getTrainingOptions(state.courses, course_id)
        // Reset value of training_id when course was changed
        setValue('training_id', '')
        setState({
            ...state,
            course_id: course_id,
            trainingOptions: options,
        })
    }
    // トレーニング選択 → ユーザオプション更新
    const handleTrainingChange = async (event: React.ChangeEvent<{ value: string }>) => {
        const training_id = event.target.value
        const course = state.courses.find((course) => course.course_id === state.course_id)
        if (course === undefined) {
            return
        }
        const training = course.trainings.find((training) => training.training_id === training_id)

        let trainingLessions: Array<Lesson> = []
        if (training && training.training_id) {
            setIsLoadingLessions(true)
            trainingLessions = await getTrainingLessions(training)
            setIsLoadingLessions(false)
        }

        // ユーザオプション.
        const userOptions = state.users.map((user) => {
            let userLabel: string = ''
            if (training && training.training_id) {
                userLabel = getUserLabel(training, user, trainingLessions)
            }
            return {
                label: getUserName(user),
                value: getUserId(user),
                optionLabel: userLabel,
            }
        })

        setState({
            ...state,
            userOptions: userOptions,
        })

        // const options = getTrainingOptions(state.courses, course_id)
        // // Reset value of training_id when course was changed
        // reset({ training_id: '' })
        // setState({
        //     ...state,
        //     course_id: course_id,
        //     trainingOptions: options,
        // })
    }

    const getTrainingLessions = async (training: Training) => {
        let trainingLessions: Array<Lesson> = []
        if (training && training.training_id) {
            const res = await dispatch(fetchTrainingById({ id: training.training_id, query: { withs: 'lessons' } }))
            if (res.payload && res.payload.length) {
                trainingLessions = res.payload[0].lessons
            }
        }
        return trainingLessions
    }

    /**
     *　登録ボタンが押された時の処理.
     * react-hooks-from 処理後
     * select 系は material-ui の ref 対象とならないので、state から取得する.
     * @param {React.FormEvent} event
     * @returns
     */
    const onSubmit = async (data: FormState) => {
        console.log('=== 送信処理', data)

        const param: LessonParam = {
            lesson_type: LessonType.concurrent,
            lesson_id: data.lesson_id,
            staff_id: data.staff_id,
            user_id: data.user_id,
            // Use data of React-hook-form for handling validation
            course_id: data.course_id,
            training_id: data.training_id,
            user_ids: data.user_ids,

            start: DateUtil.format(selectedDate, 'yyyy-MM-dd HH:mm'),
            status: LessonStatus.reserved,
        }

        console.log('送信パラメータ', param)

        if (!param.lesson_id) {
            await dispatch(createLesson(param))
        } else {
            await dispatch(updateLesson(param))
        }
        reset()
        onAfterAddOrEditHandler()
    }

    function handleClose() {
        if (!isUndefined(onCloseHandler)) {
            onCloseHandler()
        }
    }

    // -----------------------
    // Render
    // -----------------------
    return (
        <React.Fragment>
            <Container component="main">
                <div className={classes.paper}>
                    {/* {state.showWarning ? <Result /> : null} */}

                    <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
                        <input type="hidden" name="lesson_id" ref={register} />
                        <input type="hidden" name="staff_id" ref={register} />
                        {/* カリキュラム */}
                        <Controller
                            render={({ onChange, value }) => (
                                <InputTextField
                                    className={classes.input}
                                    margin="none"
                                    fullWidth
                                    required
                                    label="カリキュラム"
                                    value={value}
                                    onChange={(e: any) => {
                                        onChange(e.target.value)
                                        handleCourseChange(e)
                                    }}
                                    error={Boolean(errors.course_id)}
                                    helperText={errors.course_id && errors.course_id.message}
                                    select
                                    SelectProps={{
                                        MenuProps: {
                                            className: classes.menuList,
                                            getContentAnchorEl: null,
                                        },
                                    }}
                                >
                                    {state.courseOptions.map((option, index) => (
                                        <MenuItem key={index} value={option.value} className={classes.menuItem}>
                                            <Typography>{option.label}</Typography>
                                            <Typography>{option.optionLabel}</Typography>
                                        </MenuItem>
                                    ))}
                                </InputTextField>
                            )}
                            name="course_id"
                            rules={{ required: 'カリキュラムは必須です' }}
                            control={control}
                        />
                        {/* トレーニング. */}
                        <Controller
                            render={({ onChange, value }) => (
                                <InputTextField
                                    className={classes.input}
                                    margin="none"
                                    fullWidth
                                    required
                                    value={value}
                                    label="トレーニング"
                                    error={Boolean(errors.training_id)}
                                    helperText={errors.training_id && errors.training_id.message}
                                    select
                                    onChange={(e: any) => {
                                        onChange(e.target.value)
                                        handleTrainingChange(e)
                                    }}
                                    SelectProps={{
                                        MenuProps: {
                                            className: classes.menuList,
                                            getContentAnchorEl: null,
                                        },
                                    }}
                                >
                                    {state.trainingOptions.map((option, index) => (
                                        <MenuItem key={index} value={option.value} className={classes.menuItem}>
                                            <Typography className={classes.trainingName}>{option.label}</Typography>
                                            <Typography>{option.optionLabel}</Typography>
                                        </MenuItem>
                                    ))}
                                </InputTextField>
                            )}
                            name="training_id"
                            rules={{ required: 'トレーニングは必須です' }}
                            control={control}
                        />
                        {/* ユーザー一覧. */}
                        <Controller
                            as={
                                <InputTextField
                                    className={classes.input}
                                    margin="none"
                                    fullWidth
                                    required
                                    label="ユーザ"
                                    error={Boolean(errors.user_id)}
                                    helperText={errors.user_id && errors.user_id.message}
                                    select
                                    disabled={isLoadingLessions}
                                    SelectProps={{
                                        multiple: true,
                                        MenuProps: {
                                            className: classes.menuList,
                                            getContentAnchorEl: null,
                                        },
                                        renderValue: (selected: any) => {
                                            const newSelected = [...(selected as string[])].map((value) => {
                                                const item = state.userOptions.find((userOption) => userOption.value === value)
                                                if (isUndefined(item)) return ''
                                                return item.label
                                            })
                                            return newSelected.join(', ')
                                        },
                                    }}
                                >
                                    {state.userOptions.map((option, index) => (
                                        <MenuItem key={index} value={option.value} className={classes.menuItem}>
                                            <Typography>{option.label}</Typography>
                                            <Typography>{option.optionLabel}</Typography>
                                        </MenuItem>
                                    ))}
                                </InputTextField>
                            }
                            name="user_ids"
                            rules={{ required: 'ユーザは必須です' }}
                            control={control}
                        />

                        <Box>
                            <Typography className={classes.label}>予約日時</Typography>
                            <Box display="flex" marginTop="5px">
                                <Box marginRight="20px">
                                    <DatePicker
                                        id="date"
                                        name="date"
                                        className={clsx(classes.picker, classes.datePicker)}
                                        selected={selectedDate}
                                        onChange={handleDateChange}
                                        locale="ja"
                                        dateFormat="yyyy/MM/dd"
                                        dateFormatCalendar={'yyyy年 MMM'}
                                        renderCustomHeader={({
                                            date,
                                            decreaseMonth,
                                            increaseMonth,
                                            prevMonthButtonDisabled,
                                            nextMonthButtonDisabled,
                                            decreaseYear,
                                            increaseYear,
                                            prevYearButtonDisabled,
                                            nextYearButtonDisabled,
                                        }) => (
                                            <div className={classes.headerCalendar}>
                                                <Box>
                                                    <button
                                                        type="button"
                                                        onClick={decreaseYear}
                                                        disabled={prevYearButtonDisabled}
                                                        className={classes.buttonCalendar}
                                                    >
                                                        «
                                                    </button>
                                                    <button
                                                        type="button"
                                                        onClick={decreaseMonth}
                                                        disabled={prevMonthButtonDisabled}
                                                        className={classes.buttonCalendar}
                                                    >
                                                        ‹
                                                    </button>
                                                </Box>
                                                {DateUtil.getYear(date)}年{DateUtil.getMonth(date) + 1}月
                                                <Box>
                                                    <button
                                                        type="button"
                                                        onClick={increaseMonth}
                                                        disabled={nextMonthButtonDisabled}
                                                        className={classes.buttonCalendar}
                                                    >
                                                        ›
                                                    </button>
                                                    <button
                                                        type="button"
                                                        onClick={increaseYear}
                                                        disabled={nextYearButtonDisabled}
                                                        className={classes.buttonCalendar}
                                                    >
                                                        »
                                                    </button>
                                                </Box>
                                            </div>
                                        )}
                                        popperPlacement="none"
                                    />
                                </Box>
                                <Box>
                                    <DatePicker
                                        id="time"
                                        name="time"
                                        className={clsx(classes.picker, classes.timePicker)}
                                        selected={selectedDate}
                                        onChange={handleTimeChange}
                                        locale="ja"
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={30}
                                        dateFormat="HH:mm"
                                        timeFormat="HH:mm"
                                        popperPlacement="none"
                                    />
                                </Box>
                            </Box>
                        </Box>

                        <div className={classes.footer}>
                            <Footer onCloseHandler={handleClose} isSubmitting={isSubmitting} />
                        </div>
                    </form>
                </div>
            </Container>
        </React.Fragment>
    )
}

//----------------------------------------------------------------------------------------
function getCourseId(course: Course): string {
    return isUndefined(course) || isUndefined(course.course_id) ? '' : course.course_id
}
function getCourseTrainings(course: Course | undefined): Training[] {
    return isUndefined(course) || isUndefined(course.trainings) ? [] : course.trainings
}
function getCourseTestTrainings(course: Course | undefined): Training[] {
    return isUndefined(course) || isUndefined(course.test_trainings) ? [] : course.test_trainings
}

function getTrainingId(training: Training): string {
    return isUndefined(training) || isUndefined(training.training_id) ? '' : training.training_id
}
function getLessonId(lesson: Lesson): string {
    return isUndefined(lesson) || isUndefined(lesson.lesson_id) ? '' : lesson.lesson_id
}
function getLessonStart(lesson: Lesson): string {
    return isUndefined(lesson) || isUndefined(lesson.start) ? '' : lesson.start
}
function getStaffId(staff: Staff): string {
    return isUndefined(staff) || isUndefined(staff.staff_id) ? '' : staff.staff_id
}
function getCourseName(course: Course): string {
    return isUndefined(course) || isUndefined(course.course_name) ? '' : course.course_name
}
function getTrainingName(training: Training): string {
    return isUndefined(training) || isUndefined(training.training_name) ? '' : training.training_name
}
// function getStaffName(staff: Staff): string {
//     return isUndefined(staff) || isUndefined(staff.staff_name) ? '' : staff.staff_name
// }
function getUserId(user: User): string {
    return isUndefined(user) || isUndefined(user.user_id) ? '' : user.user_id
}
function getUserIds(users: User[]): [] {
    return users === undefined || users.length === 0 ? [] : ((users.map((user) => user.user_id) as unknown) as [])
}
function getUserName(user: User): string {
    return isUndefined(user) || isUndefined(user.user_name) ? '' : user.user_name
}

function getLessonUserId(lesson: Lesson | undefined): string {
    return isUndefined(lesson) || isUndefined(lesson.user) ? '' : getUserId(lesson.user)
}

function getLessonCourseId(lesson: Lesson | undefined): string {
    return isUndefined(lesson) || isUndefined(lesson.course) ? '' : getCourseId(lesson.course)
}
// function getLessonCourseName(lesson: Lesson): string {
//     return isUndefined(lesson) || isUndefined(lesson.course)
//         ? ''
//         : getCourseName(lesson.course)
// }
function getLessonTrainingId(lesson: Lesson): string {
    return isUndefined(lesson) || isUndefined(lesson.training) ? '' : getTrainingId(lesson.training)
}
// function getLessonTrainingName(lesson: Lesson): string {
//     return isUndefined(lesson) || isUndefined(lesson.training)
//         ? ''
//         : getTrainingName(lesson.training)
// }
function getLessonStaffId(lesson: Lesson): string {
    return isUndefined(lesson) || isUndefined(lesson.staff) ? '' : getStaffId(lesson.staff)
}
// function getLessonStaffName(lesson: Lesson): string {
//     return isUndefined(lesson) || isUndefined(lesson.staff)
//         ? ''
//         : getStaffName(lesson.staff)
// }
// function getLessonStatus(lesson: Lesson): string {
//     return isUndefined(lesson) || isUndefined(lesson.status)
//         ? undefined
//         : lesson.status
// }
function lessonUsers(lesson: Lesson): User[] {
    return lesson?.users === undefined ? [] : lesson?.users
}
function isMeetUserLevel(training: Training | undefined, user: User): boolean {
    // console.log( `${training?.training_id}: ${training?.min_level} - ${training?.max_level}` )
    // console.log( `${user.user_id}: ${user.min_level} - ${user.max_level}` )
    if (training === undefined) {
        return true
    }
    if (training.min_level > user.max_level) return false
    if (training.max_level < user.min_level) return false
    return true
}

function getUserLabel(training: Training, user: User, trainingLessions: Lesson[]) {
    let userLabel: string = ''
    let userLabels = []
    if (trainingLessions.length) {
        const isFinishIndividual = trainingLessions.find(
            (trainingLession) =>
                trainingLession.lesson_type === LessonType.individual &&
                trainingLession.status === LessonStatus.finish &&
                trainingLession.user_id === user.user_id,
        )
        const isFinishConcurrent = trainingLessions.find(
            (trainingLession) =>
                trainingLession.lesson_type === LessonType.concurrent &&
                trainingLession.status === LessonStatus.finish &&
                trainingLession.lesson_users &&
                trainingLession.lesson_users.find((lessonUser) => lessonUser.user_id === user.user_id),
        )
        if (isFinishIndividual) {
            userLabels.push('個別受講済み')
        }
        if (isFinishConcurrent) {
            userLabels.push('一斉受講済み')
        }
    }
    if (!isMeetUserLevel(training, user)) {
        userLabels.push('レベル対象外')
    }
    userLabel = userLabels.join(', ')
    return userLabel
}
