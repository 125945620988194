//
// 管理者ポータル.
// トレーニング管理.
//
// import * as Const from '../Const'
import { trace } from '../common/index'
import React from 'react'

// User's Component.
import TitleBar from './TitleBar'

import SearchBar2 from './SearchBar2'
import { NewButton } from './Buttons'

import { Box } from '@material-ui/core'
import { useRouteMatch, useHistory, Switch, Route } from 'react-router-dom'
import { Information, InformationType } from 'Types'
import { usePortal } from 'modules/AppModule'
import InformationList from './InformationList'
import InformationEdit from './InformationEdit'

type UserSettingProps = {}

export default function InformationSetting(props: UserSettingProps) {
    trace('### InformationSetting')

    const history = useHistory()
    const match = useRouteMatch()
    const listPageUrl = match.url

    const portal = usePortal();

    const informationType = getInformationType( portal );
    function getInformationType( portal: string ) {
        switch ( portal ) {
            case 'staff':   return InformationType.FACILITY;
            case 'admin':   return InformationType.SYSTEM;
        }
        return InformationType.SYSTEM;
    }

    // タイトルバー：新規作成
    const handleClickNew = () => {
        history.push(`${listPageUrl}/create`)
    }

    const handleClickCell = (information: Information) => {
        history.push(`${listPageUrl}/${information?.id}`)
    }

    const handleClose = () => {
        history.push(listPageUrl)
    }

    // CSSを適用する。
    // const classes = useStyles();
    return (
        <div style={{ width: 960, margin: '35px auto' }}>
            <Switch>
                <Route
                    exact
                    path={listPageUrl}
                    render={() => (
                        <>
                            <Box marginBottom="30px">
                                <SearchBar2 right={<NewButton onClickHandler={handleClickNew}>新規登録</NewButton>} />
                            </Box>
                            <TitleBar label={`お知らせ一覧`} bgBlue />
                            <InformationList type={informationType} onClickHandler={handleClickCell} />
                        </>
                    )}
                />
                <Route
                    path={`${listPageUrl}/create`}
                    render={() => (
                        <>
                            <TitleBar bgBlue label="お知らせ登録" />
                            <InformationEdit type={informationType} onCloseHandler={handleClose} />
                        </>
                    )}
                />
                <Route
                    path={`${listPageUrl}/:informationId`}
                    render={() => (
                        <>
                            <TitleBar bgBlue label="お知らせ詳細" />
                            <InformationEdit type={informationType} onCloseHandler={handleClose} />
                        </>
                    )}
                />
            </Switch>
        </div>
    )
}
