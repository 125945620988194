import React from 'react'
import { useAppDispatch } from 'store'
import { Staff, User } from 'Types'
import { useImageCache, ImageCacheEntry, addImageCache } from './ImageModule'
import * as DateUtil from 'date-fns'
type Props = {
    children: React.ReactNode
}

const context = React.createContext({
    imageCache: [] as ImageCacheEntry[],
    getUserImage: (user?: User) => ({} as string),
    addUsers: (users: User[]) => {},
    getStaffImage: (staff?: Staff) => ({} as string),
    addStaffs: (staffs: Staff[]) => {},
})

export function getImageCacheContext() {
    return context
}

export default function ImageCacheProvider({ children }: Props) {
    const dispatch = useAppDispatch()
    const ImageCacheContext = getImageCacheContext()
    const imageCache = useImageCache()

    // User.
    const getUserImage = (user?: User): string => {
        const entry = imageCache.find((item) => {
            return item.type === 'user' && item.name === user?.user_id
        })
        return entry?.data ? entry?.data : ''
    }
    const addUsers = (users: User[]) => {
        try {
            const entries = users
                .map((user: User) => {
                    let ts = 0
                    if (user.updated_at !== undefined) {
                        const date: Date = DateUtil.parse(user.updated_at, 'yyyy-MM-dd HH:mm:ss', new Date())
                        ts = DateUtil.getUnixTime(date)
                    }
                    const entry: ImageCacheEntry = {
                        type: 'user',
                        name: user.user_id,
                        data: user.icon,
                        etag: ts,
                    }
                    return entry
                })
                .filter((item: ImageCacheEntry) => {
                    return item.name !== undefined && item.data !== undefined
                })
            dispatch(addImageCache(entries))
        } catch (error) {
            console.log(error)
        }
    }

    // Staff
    const getStaffImage = (staff?: Staff): string => {
        const entry = imageCache.find((item) => {
            return item.type === 'staff' && item.name === staff?.staff_id
        })
        return entry?.data ? entry?.data : ''
    }
    const addStaffs = (staffs: Staff[]) => {
        try {
            const entries = staffs
                .map((staff: Staff) => {
                    let ts = 0
                    if (staff.updated_at !== undefined) {
                        const date: Date = DateUtil.parse(staff.updated_at, 'yyyy-MM-dd HH:mm:ss', new Date())
                        ts = DateUtil.getUnixTime(date)
                    }
                    const entry: ImageCacheEntry = {
                        type: 'staff',
                        name: staff.staff_id,
                        data: staff.icon,
                        etag: ts,
                    }
                    return entry
                })
                .filter((item: ImageCacheEntry) => {
                    return item.name !== undefined && item.data !== undefined
                })
            dispatch(addImageCache(entries))
        } catch (error) {
            console.log(error)
        }
    }

    const providerValue = {
        imageCache,
        getUserImage: getUserImage,
        addUsers: addUsers,
        getStaffImage: getStaffImage,
        addStaffs: addStaffs,
    }
    return <ImageCacheContext.Provider value={providerValue}>{children}</ImageCacheContext.Provider>
}
