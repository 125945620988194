// import * as Const from '../Const'
// import { trace } from '../common/index'
import * as WS from 'modules/WebSocket'
import React from 'react'
import { isUndefined } from 'util'

// Redux & Reducer Modules

// Material-UI
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Typography, Box, Paper, Grid, Button } from '@material-ui/core'
import SmallAvatar from './SmallAvatar'

// User
import AvatarLabel from './AvatarLabel'
// import { useLessonById } from 'modules/LessonModule'
import { useParams } from 'react-router-dom'
import { fetchUserLessonByStatus } from 'modules/UserModule'
import { useAppDispatch } from 'store'
import { Lesson, LessonStatus, LessonType, User } from 'Types'
import { useProfile } from 'modules/AppModule'
import LevelBar from './LevelBar'
import * as VideoController from 'modules/VideoController'

// スタイルを適用する
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: 10,
            marginBottom: 25,
            justifyContent: 'center',
            alignItems: 'center',
            border: '1px solid #707070',
            borderRadius: 0,
            boxShadow: '1px 1px 2px #999595',
        },
        user_element: {
            // float: 'left',
        },
        circle: {
            width: 20,
            height: 20,
            borderRadius: '50%',
            backgroundColor: theme.palette.primary.main,
        },
        circle_offline: {
            backgroundColor: theme.palette.secondary.main,
        },

        // バッジ.
        badge: {
            color: theme.palette.warning.light,
            transform: `translate(0px,0px) scale(1.2)`,
        },
        container: {
            justifyContent: 'center',
            textAlign: 'center',
            alignItems: 'center',
            // height: "100%",
        },
        avatar: {
            margin: '0px',
            width: 30,
            height: 30,
        },
        smallAvatar: {
            margin: '0px',
            width: 20,
            height: 20,
            fontSize: '12px',
        },
        avatar_name: {
            marginLeft: '10px',
        },
        time: {
            borderColor: theme.palette.common.black,
            textAlign: 'center',
            padding: '20px,220px',
        },
        chip: {
            width: '100%',
            // width: "80px",
            fontWeight: 'bold',
            color: theme.palette.common.black,
            // backgroundColor: "#ff0000",
        },
        offline: {
            backgroundColor: theme.palette.secondary.main,
        },
        active: {
            backgroundColor: theme.palette.primary.main,
        },

        detail: {
            display: 'flex',
            alignItems: 'center',
        },
        detail_item: {
            marginLeft: 20,
        },
        right: {
            marginLeft: 'auto',
        },
        checkbox: {
            marginLeft: 10,
            width: 180,
            flexShrink: 0,
        },
        handicapRoot: {
            // backgroundColor: "#ff0000",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        handicapPanel: {
            // backgroundColor: "#ff8080",
            display: 'inline-block',
        },
        handicapCheckboxGroup: {
            // backgroundColor: "#0000ff",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'left',
        },
        handicapCheckbox: {
            // backgroundColor: "#ff00ff",
            marginLeft: 10,
            flexShrink: 0,
        },
        no_lesson: {
            paddingLeft: theme.spacing(2),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            textAlign: 'center',
            height: '70px',
            width: '100%',
            marginBottom: 25,
        },
        training: {
            background: '#CDEEF3',
            marginTop: 5,
        },
        label: {
            background: '#fff',
            borderRadius: 10,
            margin: '10px',
            padding: '5px',
        },
        training_name: {
            margin: '5px 0',
            padding: '5px',
            fontSize: 20,
            color: '#5A5A5A',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        avatar_on_right: {
            fontSize: 15,
            color: '#5A5A5A',
            display: 'inherit',
            padding: '0px 10px 0 5px',
        },
        level_container: {
            display: 'flex',
            margin: '8px 0',
        },
        level: {
            textAlign: 'center',
            width: 30,
            height: 30,
            lineHeight: '30px',
            marginRight: 10,
            backgroundColor: '#FFFFFF',
            color: '#B6B6B6',
            fontSize: '14px',
            userSelect: 'none',
            boxShadow: '1px 1px 1px #0000004D',
        },

        box_levelbar: {
            display: 'flex',
            alignItems: 'center',
            marginTop: theme.spacing(1),
            marginRight: 10,
            padding: theme.spacing(0),
        },
        levelBar: {
            '&>div>div': {
                fontSize: 14,
                color: '#B6B6B6',
            },
        },

        training_time: {
            display: 'flex',
            fontSize: 15,
            margin: '15px 0 0 3px',
            color: '#5A5A5A',
        },
        stop_button_container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        stop_button: {
            width: '80%',
            color: '#fff',
            background: '#D02927',
            borderRadius: '20px',
            border: 0,
            '&:hover': {
                background: '#D02927',
            },
        },
    }),
)
export default function UserCell() {
    console.log('UserCell')
    // CSSを適用する。
    const classes = useStyles()
    const dispatch = useAppDispatch()

    // const lesson: Lesson | undefined = undefined
    // const { lessonId } = useParams()
    const [lesson, setLesson] = React.useState<Lesson | undefined>(undefined) //useLessonById(lessonId))

    const user = useProfile() as User
    React.useEffect(() => {
        const user_id = user?.user_id
        if (lesson === undefined && user_id !== undefined) {
            dispatch(fetchUserLessonByStatus({ user_id: user_id, status: LessonStatus.progressing })).then((res) => {
                const lessons = res.payload
                if (lessons && lessons.length !== 0) {
                    sortLessons(lessons)
                    const lesson = lessons[0]
                    setLesson(lesson)
                    WS.start(lesson.lesson_type, lesson.lesson_id, 'user', user?.user_name, user?.user_id, true)
                } else {
                    console.log('受講中なし')
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, user])

    React.useEffect(() => {
        return function cleanup() {
            WS.stop()
        }
    }, [])

    const onActivate = () => {
        console.log('アクティベートされた')
        const user_id = user?.user_id
        if (user_id !== undefined) {
            dispatch(fetchUserLessonByStatus({ user_id: user_id, status: LessonStatus.progressing })).then((res) => {
                const lessons = res.payload
                if (lessons.length !== 0) {
                    sortLessons(lessons)
                    const lesson = lessons[0]
                    setLesson(lesson)
                    // WS.start(lesson.lesson_id, 'user', user?.user_name)
                } else {
                    console.log('受講中なし')
                }
            })
        }
    }
    const onDeactivate = () => {
        console.log('デアクティベートされた')
        setLesson(undefined)
    }

    const handleClickStop = (event: any) => {
        // 強制中断を送信.
        WS.sendCommand('terminate', '')

        // ビデオ再生を停止
        VideoController.doPause()

        // アクティブレッスンを解除.
        setLesson(undefined)
    }

    /**
     * Sort lessons order by lesson_type asc (individual -> concurrent)
     * @param lessons
     */
    const sortLessons = (lessons: Lesson[]) => {
        lessons.sort((a, b) => {
            if (isUndefined(a?.lesson_type) || isUndefined(b?.lesson_type)) return 0
            return a.lesson_type - b.lesson_type
        })
    }

    const NoLesson = (
        <Paper variant="outlined" elevation={3} className={classes.no_lesson}>
            <AvatarLabel type="offline" title="現在は受講していません" />
        </Paper>
    )
    const LessonInfo = (
        <Paper elevation={3} className={classes.root}>
            <Grid container className={classes.container} spacing={1}>
                <Grid item xs={12}>
                    <AvatarLabel title="受講中のトレーニング" type="active" />
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1} className={classes.training}>
                        <Grid item xs={2}>
                            <Box className={classes.label}>トレーニング名</Box>
                            <Box className={classes.label}>スタッフ</Box>
                        </Grid>
                        <Grid item xs={3}>
                            <Box className={classes.training_name}>{lesson?.training?.training_name}</Box>
                            <Box display="flex" justifyContent="left" alignItems="center">
                                <Typography variant="h5" className={classes.avatar_on_right}>
                                    {lesson?.staff?.staff_name}
                                </Typography>
                                <SmallAvatar style={{ height: 30, width: 30 }} />
                            </Box>
                        </Grid>
                        <Grid item xs={5}>
                            <Box className={classes.box_levelbar}>
                                <Box className={classes.levelBar}>
                                    <LevelBar
                                        min={lesson?.training?.min_level !== undefined ? lesson?.training?.min_level : 0}
                                        max={lesson?.training?.max_level !== undefined ? lesson?.training?.max_level : 0}
                                        readonly
                                        size="normal"
                                    />
                                </Box>
                            </Box>
                            <Box className={classes.training_time}>
                                <VideoTime onActivate={onActivate} onDeactivate={onDeactivate} />
                            </Box>
                        </Grid>
                        <Grid item xs={2} className={classes.stop_button_container}>
                            {lesson?.lesson_type === LessonType.individual && (
                                <Button variant="outlined" className={classes.stop_button} onClick={handleClickStop}>
                                    すぐに停止
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    )
    const Content = lesson?.status === LessonStatus.progressing ? LessonInfo : NoLesson
    console.log('>>> Lesson#', lesson)
    return <React.Fragment>{Content}</React.Fragment>
}
type VideoTimeProps = {
    onActivate?: () => void
    onDeactivate?: () => void
}
function VideoTime(props: VideoTimeProps) {
    const [currentTime, setCurrentTime] = React.useState<number>(0)
    const { lessonId } = useParams()

    const onMessage = (message: string) => {
        // console.log(`Message:${message}`)
        const json = JSON.parse(message)
        if (json.command === 'posture') {
            updateTime(json.data.frame)
        } else if (json.command === 'enter') {
            console.log('##### activate')
            if (props.onActivate !== undefined) {
                props.onActivate()
            }
        } else if (json.command === 'leave') {
            console.log('##### deactivate')
            if (props.onDeactivate !== undefined) {
                props.onDeactivate()
            }
        } else {
            console.log('>UserCell', json)
        }
    }
    function updateTime(msec: number) {
        let sec = Math.floor(msec / 1000)
        if (sec !== currentTime) {
            setCurrentTime(sec)
        }
    }

    function formatTime(sec: number | undefined): string {
        if (sec === undefined) {
            return ''
        }
        let str = ''
        const hour = Math.floor(sec / (60 * 60))
        if (hour > 0) {
            str = str + `${hour}時間`
            sec = sec - hour * 60 * 60
        }
        const minute = Math.floor(sec / 60)
        if (minute > 0) {
            str = str + `${minute}分`
            sec = sec - minute * 60
        }
        str = str + `${sec}秒`
        return str
    }
    React.useEffect(() => {
        console.log('### WebSocket: Set Callback')
        let callback_key = 'UserCell'
        if (lessonId === undefined) {
            callback_key = 'UserCell_UserTop'
        }
        WS.setCallback({ key: callback_key, onMessage: onMessage })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <>{formatTime(currentTime)}</>
}
