import { Box, Table, TableBody, TableHead } from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import { DefaultColumnFilter } from 'components/react-table/filters'
import { StripedTableCell, StripedTableRow } from 'components/Tables'
import { OPTION_CONCURRENT } from 'Const'
import React from 'react'
import { useFilters, useSortBy, useTable } from 'react-table'
import { User } from 'Types'

export default function ReactTable({
    columns,
    data,
    initialState,
    accessor,
    onCellClick,
    isLoading,
    TitleCell,
}: {
    columns: any[]
    data: any[]
    initialState?: { [key: string]: any }
    accessor: string
    onCellClick: (id: string) => void
    isLoading?: boolean
    TitleCell?: JSX.Element
}) {
    const defaultColumn = React.useMemo(
        () => ({
            Filter: DefaultColumnFilter,
        }),
        [],
    ) as {}

    const filterTypes = React.useMemo(
        () => ({
            multiple: (rows: any, id: any, filterValue: any) => {
                return rows.filter((row: any) => {
                    const rowValue = row.values[id]
                    if (id[0] === 'user.user_name') {
                        if (rowValue === undefined) {
                            if (filterValue.includes(OPTION_CONCURRENT.value)) {
                                return true
                            }
                            // Lesson has many users
                            // Show other rows which has users belong to concurrent when selecting same user
                            const users = row.original.users ?? []
                            if (users.length) {
                                return filterValue.some((value: string) => {
                                    return users.some((user: User) => user.user_name === value)
                                })
                            }
                        }
                        return filterValue.includes(rowValue)
                    }
                    return rowValue !== undefined ? filterValue.includes(rowValue) : true
                })
            },
        }),
        [],
    ) as {}

    const { getTableProps, getTableBodyProps, headers, headerGroups, rows, prepareRow }: any = useTable(
        {
            columns,
            data,
            defaultColumn,
            filterTypes,
            initialState: initialState ?? {},
            disableSortRemove: true,
        } as any,
        useFilters,
        useSortBy,
    )

    const handleCellClick = (id: string) => {
        console.log('handleCellClick: ', id)
        onCellClick(id)
    }

    // Only show outside filters when having hidden columns
    const OutSideFilters = initialState && initialState.hiddenColumns && (
        <Box marginBottom="12px" display="flex" justifyContent="flex-end">
            {initialState.hiddenColumns.map((column: string) => headers.find((header: any) => header?.id === column).render('Filter'))}
        </Box>
    )

    return (
        <>
            {OutSideFilters}
            {TitleCell && <Box marginBottom="8px">{TitleCell}</Box>}
            <Table {...getTableProps()}>
                <TableHead>
                    {headerGroups.map((headerGroup: any) => (
                        <StripedTableRow {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column: any) => (
                                <StripedTableCell {...column.getHeaderProps(column.getSortByToggleProps())} align="center" width={column.widthPx}>
                                    <Box display="inline-flex" alignItems="center">
                                        {column.render('Header')}
                                        {column.isSorted ? column.isSortedDesc ? <ArrowDropDownIcon /> : <ArrowDropUpIcon /> : ''}
                                        {column.canFilter ? column.render('Filter') : null}
                                    </Box>
                                </StripedTableCell>
                            ))}
                        </StripedTableRow>
                    ))}
                </TableHead>
                <TableBody {...getTableBodyProps()} style={{ pointerEvents: isLoading ? 'none' : 'inherit' }}>
                    {rows.map((row: any) => {
                        prepareRow(row)
                        return (
                            <StripedTableRow {...row.getRowProps()} onClick={() => handleCellClick(row.original[accessor])}>
                                {row.cells.map((cell: any) => {
                                    return <StripedTableCell {...cell.getCellProps()}>{cell.render('Cell')}</StripedTableCell>
                                })}
                            </StripedTableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </>
    )
}
