import React from 'react'
import { isUndefined } from 'util'
import { useProfile } from 'modules/AppModule'
import { OPTION_ALL, OPTION_CONCURRENT } from 'Const'
import { LessonStatus, LessonType, Staff } from 'Types'
import { getImageCacheContext } from 'modules/ImageCacheProvider'
import FilterList from 'components/react-table/FilterList'
import OutSideFilterList from 'components/react-table/OutSideFilterList'
import SmallAvatar from 'components/SmallAvatar'

export function DefaultColumnFilter({ column: { filterValue, setFilter } }: any) {
    return (
        <input
            value={filterValue || ''}
            onChange={(e) => {
                setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
            }}
        />
    )
}

export function MultiCheckboxWithStaffAvatarColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }: any) {
    const imageCache = React.useContext(getImageCacheContext())
    // Calculate the options for filtering using the preFilteredRows
    const { options, avatarLabels } = React.useMemo(() => {
        const options = new Set([OPTION_ALL.value])
        const avatarLabels: { [key: string]: JSX.Element } = {}
        preFilteredRows.forEach((row: any) => {
            options.add(row.values[id])
            avatarLabels[row.values[id]] = (
                <SmallAvatar
                    src={imageCache.getStaffImage(row.original.staff)}
                    style={{
                        display: 'inline-flex',
                        width: 20,
                        height: 20,
                    }}
                />
            )
        })
        return { options: [...(options.values() as any)], avatarLabels }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, preFilteredRows])

    if (filterValue === undefined) {
        setFilter(options)
    }

    return <FilterList optionValues={options} avatarLabels={avatarLabels} filterValue={filterValue} setFilter={setFilter} />
}

export function MultiCheckboxWithStaffAvatarAndLoggedInStaffOnTopColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }: any) {
    const imageCache = React.useContext(getImageCacheContext())
    const staffProfile = useProfile() as Staff
    const staffProfileName = isUndefined(staffProfile) || isUndefined(staffProfile.staff_name) ? '' : staffProfile.staff_name
    // Calculate the options for filtering using the preFilteredRows
    const { options, avatarLabels } = React.useMemo(() => {
        const options = [OPTION_ALL.value]
        const avatarLabels: { [key: string]: JSX.Element } = {}
        preFilteredRows.forEach((row: any) => {
            const value = row.values[id]
            if (value === staffProfileName) {
                options.unshift(value)
            } else {
                options.push(value)
            }
            avatarLabels[value] = (
                <SmallAvatar
                    src={imageCache.getStaffImage(row.original.staff)}
                    style={{
                        display: 'inline-flex',
                        width: 20,
                        height: 20,
                    }}
                />
            )
        })
        const uniqueOptions = Array.from(new Set(options))
        return { options: uniqueOptions, avatarLabels }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, preFilteredRows])

    if (filterValue === undefined) {
        setFilter(options)
    }

    return <FilterList optionValues={options} avatarLabels={avatarLabels} filterValue={filterValue} setFilter={setFilter} />
}

export function OutSideMultiCheckboxWithStaffAvatarColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }: any) {
    const imageCache = React.useContext(getImageCacheContext())
    // Calculate the options for filtering using the preFilteredRows
    const { options, avatarLabels } = React.useMemo(() => {
        const options = new Set([OPTION_ALL.value])
        const avatarLabels: { [key: string]: JSX.Element } = {}
        preFilteredRows.forEach((row: any) => {
            options.add(row.values[id])
            avatarLabels[row.values[id]] = (
                <SmallAvatar
                    src={imageCache.getStaffImage(row.original.staff)}
                    style={{
                        display: 'inline-flex',
                        width: 20,
                        height: 20,
                    }}
                />
            )
        })
        return { options: [...(options.values() as any)], avatarLabels }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, preFilteredRows])

    if (filterValue === undefined) {
        setFilter(options)
    }

    return <OutSideFilterList optionValues={options} avatarLabels={avatarLabels} filterValue={filterValue} setFilter={setFilter} />
}

export function MultiCheckboxWithUserAvatarColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }: any) {
    const imageCache = React.useContext(getImageCacheContext())
    // Calculate the options for filtering using the preFilteredRows
    const { options, avatarLabels } = React.useMemo(() => {
        const options = new Set([OPTION_ALL.value, OPTION_CONCURRENT.value])
        const avatarLabels: { [key: string]: JSX.Element } = {}
        preFilteredRows.forEach((row: any) => {
            const value = row.values[id]
            if (row.original && row.original.users && row.original.users.length) {
                row.original.users.forEach((user: any) => {
                    options.add(user.user_name)
                    avatarLabels[user.user_name] = (
                        <SmallAvatar
                            src={imageCache.getUserImage(user)}
                            style={{
                                display: 'inline-flex',
                                width: 20,
                                height: 20,
                            }}
                        />
                    )
                })
            }
            if (!value) return
            options.add(value)
            avatarLabels[value] = (
                <SmallAvatar
                    src={imageCache.getUserImage(row.original.user)}
                    style={{
                        display: 'inline-flex',
                        width: 20,
                        height: 20,
                    }}
                />
            )
        })
        return { options: [...(options.values() as any)], avatarLabels }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, preFilteredRows])

    if (filterValue === undefined) {
        setFilter(options)
    }

    return <FilterList optionValues={options} avatarLabels={avatarLabels} filterValue={filterValue} setFilter={setFilter} />
}

export function MultiDefaultCheckboxColumnFilter({ column: { filterValue, setFilter, id } }: any) {
    let options: (number | string)[] = []

    switch (id) {
        case 'status':
            options = [OPTION_ALL.value, LessonStatus.finish, LessonStatus.progressing, LessonStatus.reserved, LessonStatus.request]
            break
        case 'lesson_type':
            options = [OPTION_ALL.value, LessonType.concurrent, LessonType.individual]
            break
        default:
            break
    }

    if (filterValue === undefined) {
        setFilter(options)
    }

    return <FilterList id={id} optionValues={options} filterValue={filterValue} setFilter={setFilter} />
}

export function MultiCheckboxColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }: any) {
    // Calculate the options for filtering using the preFilteredRows
    const options = React.useMemo(() => {
        const options = new Set([OPTION_ALL.value])
        preFilteredRows.forEach((row: any) => {
            options.add(row.values[id])
        })
        return [...(options.values() as any)]
    }, [id, preFilteredRows])

    if (filterValue === undefined) {
        setFilter(options)
    }

    return <FilterList id={id} optionValues={options} filterValue={filterValue} setFilter={setFilter} />
}

export function MultiLessonStatusWithoutDefaultFinishStatusCheckboxColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }: any) {
    // Calculate the options for filtering using the preFilteredRows
    let options = React.useMemo(() => {
        const options = new Set([OPTION_ALL.value])
        preFilteredRows.forEach((row: any) => {
            options.add(row.values[id])
        })
        return [...(options.values() as any)]
    }, [id, preFilteredRows])

    if (filterValue === undefined) {
        if (options.includes(LessonStatus.finish)) {
            options = options.filter((option) => option !== LessonStatus.finish && option !== OPTION_ALL.value)
        }
        setFilter(options)
    }

    return <FilterList id={id} optionValues={options} filterValue={filterValue} setFilter={setFilter} />
}
