import React from 'react'
// import { useHistory } from 'react-router-dom'
import { useHomePageUrl } from '../modules/AppModule'

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

import { AppBar, Button, Grid } from '@material-ui/core'
import { Link, useHistory } from 'react-router-dom'
import { getDialogContext } from 'modules/DialogProvider'

/** スタイル定義 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            padding: '0 10px',
            background: 'linear-gradient(0deg, #EAEAEA 10%, #FDFDFD 60%, #FFF 80%)',
        },
        headerNav: {
            width: '960px',
            margin: '0 auto',
            background: 'transparent',
            '& .MuiToolbar-gutters': {
                padding: 0,
            },
        },
        title: {
            flexGrow: 1,
            fontSize: '20px',
            color: 'white',
            fontWeight: 'bold',
        },
        header: {
            height: 64,
            padding: (theme.spacing(18), theme.spacing(1), theme.spacing(18), theme.spacing(1)),
            background: 'transparent url(/assets/images/logo.png) no-repeat center center',
            textAlign: 'center',
            '&:hover': {
                cursor: 'pointer',
            },
            flexGrow: 1,
        },
        grow: {
            flexGrow: 1,
        },

        headerLogo: {
            color: 'inherit',
            marginRight: 20,
        },
        headerTitleStyle: {
            flexGrow: 1,
            color: 'inherit',
        },
        menuButton: {
            color: 'inherit',
            padding: '8px',
        },
        buttongroup: {
            margin: '2, 0, 2',
            padding: '2, 0, 2',
        },
        button: {
            width: '100%',
            height: 64,
            color: 'inherit',
            borderRadius: 'unset',
            borderLeft: '1px solid white',
        },
        menuItem: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textDecoration: 'none',
            height: 64,
            color: 'inherit',
            borderLeft: '1px solid white',
        },
        bar: {
            margin: '0',
            // height: "calc(50px)",
        },
        hamburger: {
            background: '#C5C5C5',
            marginRight: 5,
            color: '#fff',
            height: 40,
            width: 40,
        },
        leftHeader: {
            display: 'flex',
            alignItems: 'center',
        },
    }),
)

type AdminBarProps = {}

export default function MyAppBar(props: AdminBarProps) {
    const classes = useStyles()

    const history = useHistory()
    const homePageUrl = useHomePageUrl() // /admin

    // タイトル.
    // const appTitle = Const.AppConst.NAME

    const { showDialog } = React.useContext(getDialogContext())

    // const [anchorEl, setAnchorEl] = React.useState(null);
    return (
        <div className={classes.root}>
            {/* 上部のバー */}
            <AppBar position="static" aria-label="Global Navi" className={classes.headerNav}>
                {/* <Toolbar> */}
                <div className={classes.bar}>
                    <Grid container spacing={1} alignItems="center" justify="center">
                        <Grid item xs={3} className={classes.leftHeader}>
                            <Link to={homePageUrl} className={classes.header} />
                        </Grid>
                        <Grid item xs={9}>
                            <Grid container alignItems="center" justify="center">
                                <Grid item xs={3}>
                                    <Link to={`${homePageUrl}/trainings`} className={classes.menuItem}>
                                        トレーニング管理
                                    </Link>
                                </Grid>
                                <Grid item xs={2}>
                                    <Link to={`${homePageUrl}/facilities`} className={classes.menuItem}>
                                        施設管理
                                    </Link>
                                </Grid>
                                <Grid item xs={2}>
                                    <Link to={`${homePageUrl}/informations`} className={classes.menuItem}>
                                        お知らせ管理
                                    </Link>
                                </Grid>
                                <Grid item xs={2}>
                                    <Link to={`${homePageUrl}/admins`} className={classes.menuItem}>
                                        管理者管理
                                    </Link>
                                </Grid>
                                <Grid item xs={3}>
                                    {/* <Paper> */}
                                    <Button
                                        className={classes.button}
                                        onClick={() => {
                                            showDialog({
                                                title: 'ログアウトしますか',
                                                message: 'この操作は戻せません',
                                                items: [
                                                    {
                                                        label: 'はい',
                                                        onClickHandler: () => {
                                                            history.push(`${homePageUrl}/logout`)
                                                        },
                                                    },
                                                    { label: 'いいえ' },
                                                ],
                                            })
                                        }}
                                    >
                                        ログアウト
                                    </Button>
                                    {/* </Paper> */}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* </Toolbar> */}
                </div>
            </AppBar>
        </div>
    )
}
