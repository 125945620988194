import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Paper, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 28,
            lineHeight: '28px',
            color: theme.palette.common.white,
            backgroundColor: '#05A9C2',
            fontWeight: 'normal',
        },
    }),
)

type SimpleTitleBarProps = {
    label?: string
}

export default function SimpleTitleBar(props: SimpleTitleBarProps) {
    const classes = useStyles()

    return (
        <Paper square className={classes.root}>
            <Typography variant="h3">{props.label}</Typography>
        </Paper>
    )
}
