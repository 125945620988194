import React from 'react'
import clsx from 'clsx'
import * as DateUtil from 'date-fns'
import jaLocale from 'date-fns/locale/ja'

import { makeStyles } from '@material-ui/core/styles'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import './reactdatepicker.css'
import { Box } from '@material-ui/core'

registerLocale('ja', jaLocale)

const useStyles = makeStyles((theme) => ({
    picker: {
        padding: 10,
        border: '1px solid #B6B6B6',
    },
    datePicker: {
        width: 480,
    },
    buttonCalendar: {
        border: 'none',
        backgroundColor: 'transparent',
        '&:focus': {
            border: 'none',
        },
        '&:hover': {
            cursor: 'pointer',
        },
    },
    headerCalendar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginLeft: 10,
        marginRight: 10,
        marginBottom: 10,
    },
}))

export default function MainDatePicker(props: any) {
    const classes = useStyles()
    // React.useEffect(()=>{
    //     registerLocale("ja", jaLocale);
    // },[])
    return (
        <DatePicker
            className={clsx(classes.picker, classes.datePicker)}
            {...props}
            locale="ja"
            dateFormat="yyyy/MM/dd"
            dateFormatCalendar={'yyyy年 MMM'}
            renderCustomHeader={({
                date,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
                decreaseYear,
                increaseYear,
                prevYearButtonDisabled,
                nextYearButtonDisabled,
            }) => (
                <div className={classes.headerCalendar}>
                    <Box>
                        <button type="button" onClick={decreaseYear} disabled={prevYearButtonDisabled} className={classes.buttonCalendar}>
                            «
                        </button>
                        <button type="button" onClick={decreaseMonth} disabled={prevMonthButtonDisabled} className={classes.buttonCalendar}>
                            ‹
                        </button>
                    </Box>
                    {DateUtil.getYear(date)}年{DateUtil.getMonth(date) + 1}月
                    <Box>
                        <button type="button" onClick={increaseMonth} disabled={nextMonthButtonDisabled} className={classes.buttonCalendar}>
                            ›
                        </button>
                        <button type="button" onClick={increaseYear} disabled={nextYearButtonDisabled} className={classes.buttonCalendar}>
                            »
                        </button>
                    </Box>
                </div>
            )}
            popperPlacement="none"
        />
    )
}
