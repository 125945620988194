//
// データモデル.
//

// =======================================================================
// 列挙型
// =======================================================================
// ポータル種別.
export enum Portal {
    user, // ユーザポータル.
    staff, // 職員ポータル.
    admin, // 管理者ポータル.
}

// レッスンステータス.
export enum LessonStatus {
    request = 'request', // 予約中(承認待ち)
    reserved = 'reserved', // 予約済み(予約確定)
    progressing = 'progressing', // (レッスン中)
    finish = 'finish', // 受講済み(完了)
}
// レッスンタイプ.
export enum LessonType {
    individual = 0, // 通常（個別）
    concurrent, // 一斉指導
}
// 権限ID
export enum Role {
    unknown, // 未設定.
    staff, // 一般職員.
    manager, // 施設管理者.
}

// 性別.
export enum Gender {
    unknown, // 未設定.
    male, // 男性.
    female, // 女性.
}

// 障害種類.
export enum Handicap {
    physical, // 身体障害: Physical disability
    intellectual, // 知的障害 : intellectual disability
    mental, // 精神障害 : Mental disorder
    developmental, // 発達障害: Developmental disorder
    adhd, // 注意欠如多動性障害: attention-deficit/hyperactivity disorder)
    autism, // 自閉症: autism spectrum disorder➔自閉スペクトラム症
    asperger, // アスペルガー症候群. Asperger's syndrome
    learning, // 学習障害: Learning disability
}

// MSPA 指標.
export enum MspaEvaluationId {
    communication = 'mspa0001', // コミュニケーション
    group_adaptability = 'mspa0002', // 集団適応力
    empathy = 'mspa0003', // 共感性
    commitment = 'mspa0004', // こだわり
    sense = 'mspa0005', // 感覚
    repetitive_exercise = 'mspa0006', // 反復運動
    oversized_movement = 'mspa0007', // 粗大運動
    fine_coordination = 'mspa0008', // 微細協調運動
    carelessness = 'mspa0009', // 不注意
    hyperactivity = 'mspa0010', // 多動性
    impulsivity = 'mspa0011', // 衝動性
    sleep_rhythm = 'mspa0012', // 睡眠リズム
    learning = 'mspa0013', // 学習
    language_development_history = 'mspa0014', // 言語発達歴
}

export enum Trend {
    unknown, // -
    flat, // →
    flatup, // ↗️
    up, // ↑
    flatdown, // ↘︎
    down, // ↓
}

// StaffTop
export enum NarrowCondition {
    all, // すべて
    favorite, // 担当（お気に入り）
    inTraining, // トレーニング中
    offline, // その他
    reserved, // 予約あり
    request, // 予約承認待ち
    noReservation, // 予約なし
}
export enum StatusUser {
    all, // 全て
    inTraining, // トレーニング中
    offline, // その他
}

// トレーニング指導
export enum FavoriteUser {
    all, // 全て
    favorite, // 担当のみ
    noFavorite, // 担当以外
}

// テスト回答方法.
export enum ExaminationAnswerMethod {
    portal, // ポータルから.
    action, // 所作.
    voice, // 音声認識.
    eye_tracking,
}

export enum Display {
    hide, // 表⽰しない
    show, // 表⽰する
}

// =======================================================================
//
// =======================================================================
// Option選択肢
export type OptionValue = {
    value: any
    label: string
    optionLabel?: string
}
// =======================================================================
// データモデル.
// =======================================================================
// 認証
export type Auth = {
    account: string
    password?: string
    facility_id: string
}

// 日付
export type DateField = Partial<{
    created_at: string
    updated_at: string
}>

// 更新時付与(PUTでの更新時に必要な情報)
// DB でプライマリである ID そのものを変更する際に、検索用キーが必要。
// DB でプライマリキーを追加設定すれば解決するが、IDの二重管理となるため、採用しない.
export type OriginalField = {
    original_id: string
}

/**
 *  管理者
 */
export type Admin = Partial<
    {
        admin_id: string // 管理者ID
        admin_name: string // 管理者氏名.
        admin_ruby?: string // 管理者ふりがな.
        password: string // パスワード.
    } & DateField
>

/**
 * 施設.
 */
export type Facility = Partial<
    {
        facility_id: string // 施設ID.
        facility_name: string // 施設名.
        facility_ruby: string // 施設名ふりがな.
        manager_id: string // 管理者ID.
        manager_name: string // 管理者氏名
        zip_code: string // 郵便番号.
        address: string // 住所.
        tel: string // 電話番号.
        fax: string // FAX番号.
        mail: string // メールアドレス.
        url: string // ホームページ.
        max_staffs: number // 最大職員数
        max_users: number // 最大ユーザ数
        training_setting: TrainingSetting // トレーニング設定.
        staff: Staff[] // 所属職員.
    } & DateField
>

// お知らせ種別.
export enum InformationType {
    FACILITY = 0, // 施設のお知らせ
    SYSTEM, // システム管理者からのお知らせ
}
// お知らせ重要度
export enum InformationImportance {
    LOW = 0, // 低.
    NORMAL = 1, // 通常.
    HIGH = 2, // 高.
    EMERGENCY = 3, // 緊急.
    DEFAULT = NORMAL,
}
// お知らせ
export type Information = {
    id?: number
    type: InformationType // お知らせ種別
    importance: InformationImportance // お知らせ重要度
    facility_id: string | null // 施設ID.
    author: string | null // 登録者ID
    release_date: string // リリース日時
    expire_date: string // 有効期限日時
    title: string // 表示タイトル
    content: string // 表示内容
} & DateField

/**
 * トレーニング設定
 */
export type TrainingSetting = {
    min_level: number // 最低レベル.
    max_level: number // 最高レベル.
    min_license: number // 最低ライセンス.
    max_license: number // 最高ライセンス.
}
/**
 * スタッフ
 */
export type Staff = Partial<
    {
        staff_id: string // 職員ID
        staff_name: string // 氏名.
        staff_ruby: string // ふりがな.
        job_title: string // 役職
        tel: string // 電話番号.
        mail: string // メールアドレス.
        role_id: Role // 権限ID.
        show_comment_of_parent: Display // 保護者からのコメント
        password: string // パスワード.
        icon: string // アイコン(url or base64)
        icon_url: string // アイコン名
        attendances: Attendance[] // 出退勤記録[].
        facility_id: string

        favorite_users: User[] // ユーザ[].
        lessons: Lesson[] // 指導予約[]
        facility: Facility // 所属施設
        calendar_id: string // パスワード
    } & DateField
>

/**
 * 出退勤
 */
export type Attendance = {
    // staff_id: string            // 職員ID.
    date: string // 日付
    enter_time?: string // 出勤時刻.
    leave_time?: string // 退勤時刻.
    content?: string // 勤務内容.
}

/**
 * ユーザ
 */
export type User = {
    user_id?: string // ユーザID.
    user_name: string // 氏名.
    user_ruby?: string // 振り仮名.
    gender_id: number // 性別.
    birth_date?: string // 生年月日.
    parent_1: string // 保護者#1
    parent_2: string // 保護者#2
    tel: string // 電話番号.
    mail: string // メールアドレス.
    handicaps: string[] // 障害種類.
    min_level: number // 最低レベル.
    max_level: number // 最高レベル.
    password: string // パスワード.
    icon?: string // アイコン(url or base64)
    icon_url?: string // アイコン名
    course?: Course // 受講中コース.
    last_course_date?: string // 最終受講日.
    facility_id: string // 最終受講日.

    lessons: Lesson[] // レッスン
    mspa_evaluation?: MspaEvaluation // MSPA 評価
} & DateField

export type Examination = {
    id?: string // テストID
    key?: string // radom key
    time: string // 動画時刻	HH:MM:SS
    question: string // 	設問
    options: OptionValue[] // 選択肢
    correct: number // 正答
}

export type TrainingTag = {
    facility_id: string | null
    tag: string
}
/**
 * トレーニング
 */
export type Training = {
    training_id?: string // トレーニングID.
    training_name: string // トレーニング名.
    content_url: string // コンテンツURL.
    duration: number // 所用時間.
    description: string // 詳細説明.
    min_level: number // 最低レベル
    max_level: number // 最高レベル.
    required_license: number // 必要ライセンス.
    tags: TrainingTag[] // タグ.
    mspa_expectations: MspaExpectation[] // MSPA 期待効果.
    examinations: Examination[]
    // options.
    option_user?: {
        lesson_id: string
        lesson_start: string
        lesson_status: string
    }
} & DateField

/**
 * コース(カリキュラム)
 */
export type Course = {
    course_id?: string // コースID,
    course_name: string // コース名.
    description: string // 詳細説明.
    min_level: number // 最低レベル.
    max_level: number // 最高レベル.
    trainings: Training[] // トレーニング一覧
    test_trainings?: Training[] // テスト.
} & DateField

/**
 *  レッスン （トレーニング履歴、予約等.）
 */
export type Lesson = Partial<{
    lesson_id: string
    lesson_type: LessonType // レッスン種別
    user: User // ユーザ.
    user_id: string
    users: User[] // 拡張ユーザ.
    staff: Staff // スタッフ.
    training: Training // トレーニング.
    course: Course // コース(カリキュラム)
    start: string // 開始日時.
    end: string // 終了日時.
    status: LessonStatus // 状態.
    growth_records: GrowthRecord[] // 成長記録.
    examination_answers: ExaminationAnswer[] // テスト回答.
    lesson_comments: LessonComment[]
    lesson_users: LessonsUser[]
}>
export type LessonsUser = {
    id: string
    lesson_id: string
    user_id: string
}

export type LessonsByUsers = {
    user_id: string
    lessons: Lesson[]
}

export type LessonComment = {
    lesson_id: string
    user_id: string
    comment_prev: string
    comment_current: string
    comment_next: string
    comment_of_parent: string
}

// テスト回答
export type ExaminationAnswer = {
    user_id: string
    examination_id: string
    answer: number
    method: ExaminationAnswerMethod
}

/**
 *  MSPA 評価
 */
export type MspaEvaluation = Partial<{
    mspa_levels: MspaLevel[] // MSPA 要支援度.
    characteristic: string // 得意分野、特長とその程度
    note: string // その他の特記すべき事項
}>

/**
 * MSPA 要支援度
 */
export type MspaLevel = {
    mspa_evaluation_id: MspaEvaluationId // MSPA 評価指標.
    mspa_title?: string
    level: number // 要支援度 (1..5 div:0.5)
}
/**
 * MSPA期待効果
 */
export type MspaExpectation = {
    mspa_evaluation_id: MspaEvaluationId // MSPA 評価指標.
    expectation: number // 期待効果.
}
/**
 * 成長記録.
 */
export type GrowthRecord = {
    user_id: string
    mspa_evaluation_id: MspaEvaluationId // MSPA 評価指標.
    comment: string // コメント
    achievement: number // 達成度(1..10)

    mspa_title?: string //MSPA 項目名
}

// -----------------------------------------------------------
export type Task = {
    id: number
    title: string
    done: boolean
}

export enum OrderBy {
    asc,
    desc,
}

// Sortable training list
export enum TrainingSortable {
    training_name = 'training_name',
    level = 'level',
    updated_at = 'updated_at',
}

// useSortableData
export type SortConfig = {
    id: string
    direction: OrderBy
} | null

export type Filter = {
    orderBy: string
    sortBy: string
    search: string
}
export type FilterSorting = {
    orderBy: string
    sortBy: string
}
