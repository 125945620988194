import isEmail from 'validator/lib/isEmail'
import isDate from 'validator/lib/isDate'
import { USER_IMPORT_FIELDS } from 'config/main'
import { User } from 'Types'

interface DataFilter {
    users: User[]
}

function validateUser(data: any, users: User[]) {
    return !data['user_id']
        ? `ID${messages.required}`
        : !/^[A-Za-z.\-_\d]{6,16}$/.test(data['user_id'])
        ? '英数字または記号『.-_』6〜16文字で入力してください。'
        : users.find((user) => user.user_id === data['user_id'])
        ? `${getFieldLabel('user_id')}データベースに存在します`
        : true
}

export const validate = (data: any, dataFilter: DataFilter) => {
    return {
        user_id: validateUser(data, dataFilter.users),
        user_name: data['user_name'] ? true : `${getFieldLabel('user_name')}${messages.required}`,
        user_ruby: !data['user_ruby'] ? 'ふりがなは必須です' : data['user_ruby'].length > 20 ? 'ふりがなは20文字以内にして下さい。' : true,
        gender_id: [0, 1, 2].indexOf(data['gender_id']) === -1 ? `${getFieldLabel('gender_id')}無効です` : true,
        birth_date: !data['birth_date'] ? true : isDate(data['birth_date']) ? true : `${getFieldLabel('birth_date')}無効です`,
        parent_1: data['parent_1'] ? true : `${getFieldLabel('parent_1')}${messages.required}`,
        parent_2: true,
        tel: !data['tel']
            ? `${getFieldLabel('tel')}${messages.required}`
            : /^0\d{2,3}-?\d{1,4}-?\d{4}$/.test(data['tel'])
            ? true
            : '正しい電話番号を入力してください。',
        mail: !data['mail'] ? `${getFieldLabel('tel')}${messages.required}` : isEmail(data['mail']) ? true : `${getFieldLabel('mail')}無効です`,
        min_level: !data['min_level']
            ? `${getFieldLabel('min_level')}${messages.required}`
            : /^([1-9]|10)$/.test(data['min_level'])
            ? true
            : `${getFieldLabel('min_level')}無効です`,
        max_level: !data['max_level']
            ? `${getFieldLabel('max_level')}${messages.required}`
            : !/^([1-9]|10)$/.test(data['max_level'])
            ? `${getFieldLabel('max_level')}無効です`
            : data['max_level'] < data['min_level']
            ? `トレーニングレベル(以下)はトレーニングレベル(以上)と同じにするか、小さくしてください`
            : true,
        password: !data['password']
            ? `${getFieldLabel('password')}${messages.required}`
            : /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)[A-Za-z\d]{8,16}$/.test(data['password'])
            ? true
            : `英大文字・英小文字・数字をそれぞれ一つ以上含む、8〜16文字で入力してください`,
        password_confirm: data['password_confirm'] !== data['password'] ? `パスワードが一致しません` : true,
    }
}

export const getFieldLabel = (item: string) => {
    const field: any = USER_IMPORT_FIELDS.find((field) => field.field === item)
    return field ? field.label : item
}

const messages = {
    required: 'は必須です',
    invalid: '無効です',
}
