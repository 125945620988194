import React from 'react'
import { Box } from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import { Filter, FilterSorting } from 'Types'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

export type Direction = 'asc' | 'desc'

interface Props {
    label: string
    field: string
    filter: Filter
    onRequestSort: (newSorting: FilterSorting) => void
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        box: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    }),
)
export function HeadCellItemLabel({ label, field, filter, onRequestSort }: Props) {
    const handleSorting = () => {
        const newSorting = {
            orderBy: field,
            sortBy: filter.orderBy !== field ? 'desc' : filter.sortBy === 'asc' ? 'desc' : 'asc',
        }
        onRequestSort(newSorting)
    }
    const classes = useStyles()

    return (
        <Box className={classes.box} onClick={handleSorting}>
            {label}
            {filter.orderBy === field ? filter.sortBy === 'desc' ? <ArrowDropDownIcon /> : <ArrowDropUpIcon /> : ''}
        </Box>
    )
}
