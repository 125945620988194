import React from 'react'
import clsx from 'clsx'
import { Badge, Avatar } from '@material-ui/core'
import { Theme, withStyles, createStyles } from '@material-ui/core/styles'
import { isUndefined } from 'util'

const StyledBadge = withStyles((theme: Theme) =>
    createStyles({
        badge: {
            left: '-15%',
            top: '-13%',
            color: theme.palette.warning.light,
            transform: `translate(0px,0px) scale(1)`,
        },
    }),
)(Badge)

// Props
type FavoriteStaff = {
    icon: string | undefined
    avatar_class: string
    favorite: boolean
    disableFavorite?: boolean
    onFavoriteChange?: (favorite: boolean) => void
}

export default function FavoriteStaff(props: FavoriteStaff) {
    const [favorite, setFavorite] = React.useState(false)

    React.useEffect(() => {
        setFavorite(props.favorite)
    }, [props.favorite])

    const handleFavoriteChange = (event: React.MouseEvent<unknown, MouseEvent>, favorite: boolean) => {
        if (!isUndefined(props.disableFavorite) && props.disableFavorite) return
        event.stopPropagation()
        setFavorite(favorite)
        if (props.onFavoriteChange) {
            props.onFavoriteChange(favorite)
        }
    }

    return (
        <StyledBadge
            color="default"
            badgeContent={
                favorite ? (
                    <img onClick={(e) => handleFavoriteChange(e, !favorite)} src="/assets/images/star.png" alt="" />
                ) : (
                    <img onClick={(e) => handleFavoriteChange(e, !favorite)} src="/assets/images/gray_star.png" alt="" />
                )
            }
            overlap="circle"
            anchorOrigin={{
                horizontal: 'left',
                vertical: 'top',
            }}
        >
            {isUndefined(props.icon) ? (
                <Avatar className={clsx(props.avatar_class)} />
            ) : (
                <Avatar src={props.icon} className={clsx(props.avatar_class)} />
            )}
        </StyledBadge>
    )
}
