export const USER_IMPORT_FIELDS = [
    { field: 'user_id', isRequired: true, label: 'ユーザID', rules: [] }, // user id,
    { field: 'user_name', isRequired: true, label: '氏名', rules: [] }, // full name,
    { field: 'user_ruby', isRequired: true, label: 'ふりがな', rules: [] }, // Furigana,
    { field: 'gender_id', isRequired: false, label: '性別', rules: [] }, // sex: 男性:1, 女性:2, 回答しない: 0
    { field: 'birth_date', isRequired: false, label: '生年月日', rules: [] }, // birtday,
    { field: 'parent_1', isRequired: true, label: '保護者名1', rules: [] }, // parent name 1,
    { field: 'parent_2', isRequired: false, label: '保護者名2', rules: [] }, // parent name 2,
    { field: 'tel', isRequired: true, label: '電話番号', rules: [] }, // phone number,
    { field: 'mail', isRequired: true, label: 'メールアドレス', rules: [] }, // mail address,
    { field: 'min_level', isRequired: false, label: 'トレーニングレベル(以上)', rules: [] }, // min training level,1-10
    { field: 'max_level', isRequired: false, label: 'トレーニングレベル(以下)', rules: [] }, // max training level,1-10
    { field: 'password', isRequired: true, label: 'パスワード', rules: [] }, // password,
    { field: 'password_confirm', isRequired: true, label: 'パスワード(確認)', rules: [] }, // password confirm,
]

export const STAFF_IMPORT_FIELDS = [
    { field: 'staff_id', isRequired: true, label: '職員ID', rules: ['required'] },
    { field: 'staff_name', isRequired: true, label: '氏名', rules: ['required'] },
    { field: 'staff_ruby', isRequired: true, label: 'ふりがな', rules: ['required'] },
    { field: 'job_title', isRequired: false, label: '役職', rules: [] },
    { field: 'tel', isRequired: false, label: '電話番号', rules: [] },
    { field: 'mail', isRequired: true, label: 'メールアドレス', rules: [] },
    { field: 'calendar_id', isRequired: false, label: 'カレンダーID', rules: [] },
    { field: 'role_id', isRequired: true, label: '権限', rules: ['required'] }, // 一般職員:1 管理者:2
    { field: 'show_comment_of_parent', isRequired: true, label: '保護者からのコメント', rules: ['required'] }, // 表⽰しない: 0; 表⽰する: 1
    { field: 'password', isRequired: true, label: 'パスワード', rules: ['required'] },
    { field: 'password_confirm', isRequired: true, label: 'パスワード(確認)', rules: ['required'] },
]
