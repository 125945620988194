// import * as Const from '../Const'
import { trace } from '../common/index'

import React from 'react'
import { useAppDispatch } from '../store'
import { Grid, TableContainer, Table, TableBody } from '@material-ui/core'
import PaginationActions from './PaginationActions'

import { AdminCell, AdminHeaderCell } from './AdminCell'
import { Admin } from '../Types'
import { useListStyles } from '../Styles'

import { useAdminItems, fetchAdmins } from '../modules/AdminModule'

/**
 * プロパティ
 *
 * @interface AdminListProps
 */
interface AdminListProps {
    onClickHandler?: (item: Admin) => void
}

export default function AdminList(props: AdminListProps) {
    trace('###AdminList')
    const dispatch = useAppDispatch()
    const classes = useListStyles()

    // 初回ロード.
    React.useEffect(() => {
        dispatch(fetchAdmins())
    }, [dispatch])

    const admins = useAdminItems()

    // セルがクリックされた.
    const handleClick = (event: React.MouseEvent<unknown>, item: Admin) => {
        // trace('Cell selected:' + item.admin_name)

        if (props.onClickHandler !== undefined) {
            props.onClickHandler(item)
        }
    }
    // ### Pagination.
    // 現在のページ
    const [page, setPage] = React.useState(0)
    // １ページあたりの行数.
    const [rowsPerPage, setRowsPerPage] = React.useState(10)
    // ページが変更された.
    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage)
    }
    // ページ行数が変更された.
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<{
            name?: string | undefined
            value: any
        }>,
    ) => {
        trace('行数変更')
        if (typeof event.target.value !== 'number') {
            return
        }

        setRowsPerPage(event.target.value)
        setPage(0)
    }

    return (
        <Grid container className={classes.wrapper}>
            <Grid item xs={12}>
                <TableContainer>
                    <Table aria-label="customized table">
                        <AdminHeaderCell />
                        <TableBody>
                            {(rowsPerPage > 0 ? admins.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : admins).map((item, index) => {
                                return <AdminCell key={index} data={item} onClickHandler={(event) => handleClick(event, item)} />
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item xs={12} className={classes.listfooter}>
                <PaginationActions
                    count={admins.length}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    options={[
                        { key: '5', value: 5 },
                        { key: '10', value: 10 },
                        { key: '25', value: 25 },
                        { key: '50', value: 50 },
                        { key: '全て', value: admins.length },
                    ]}
                />
            </Grid>
        </Grid>
    )
}
