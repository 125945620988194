// ### Import
import { isUndefined } from 'util'
import React from 'react'
import clsx from 'clsx'
// Material-UI
import { TextField, makeStyles, IconButton } from '@material-ui/core'

//### Import MyClass
import { Examination } from '../Types'
import { LightStripedTableRow, LightStripedTableCell } from './Tables'

const useStyles = makeStyles((theme) => ({
    tableCell: {
        verticalAlign: 'top',
        padding: 0,
    },
    input: {
        width: '100%',
        padding: '5px 20px',
    },
    time: {
        '& input': {
            textAlign: 'center',
        },
    },
}))

type TrainingTestCellProps = {
    item: Examination
    onChangeHandler?: (value: Examination) => void
    onRemoveHandler?: () => void
}
export function TrainingTestCell(props: TrainingTestCellProps) {
    const classes = useStyles()
    const [value, setValue] = React.useState<Examination>(props.item)

    const handleChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log('changed:', name, event.target.value)
        let lvalue: any
        if (name === 'options') {
            lvalue = event.target.value.split('\n').map((option, index) => {
                return { label: option, value: 1 + index }
            })
        } else {
            lvalue = event.target.value
        }
        const newValue = { ...value, [name]: lvalue }
        setValue(newValue)
        console.log(newValue)
        if (!isUndefined(props.onChangeHandler)) {
            props.onChangeHandler(newValue)
        }
    }

    const handleRemove = () => {
        if (props.onRemoveHandler) {
            props.onRemoveHandler()
        }
    }

    return (
        <LightStripedTableRow>
            <LightStripedTableCell className={classes.tableCell}>
                <TextField
                    className={clsx(classes.input, classes.time)}
                    id="time"
                    name="time"
                    defaultValue={value.time}
                    onChange={handleChange('time')}
                    InputProps={{
                        disableUnderline: true,
                    }}
                />
            </LightStripedTableCell>
            <LightStripedTableCell className={classes.tableCell}>
                <TextField
                    className={classes.input}
                    multiline
                    defaultValue={value.question}
                    id="question"
                    name="question"
                    onChange={handleChange('question')}
                    InputProps={{
                        disableUnderline: true,
                    }}
                />
            </LightStripedTableCell>
            <LightStripedTableCell className={classes.tableCell}>
                <TextField
                    className={classes.input}
                    multiline
                    rows={Math.max(4, value.options.length)}
                    defaultValue={value.options
                        .map((option) => {
                            return option.label
                        })
                        .join('\n')}
                    id="options"
                    name="options"
                    onChange={handleChange('options')}
                    InputProps={{
                        disableUnderline: true,
                    }}
                />
            </LightStripedTableCell>
            <LightStripedTableCell className={classes.tableCell}>
                <TextField
                    className={classes.input}
                    defaultValue={value.correct}
                    id="correct"
                    name="correct"
                    onChange={handleChange('correct')}
                    InputProps={{
                        disableUnderline: true,
                    }}
                />
            </LightStripedTableCell>
            <LightStripedTableCell>
                <IconButton aria-label="remove an item" onClick={handleRemove}>
                    <img src="/assets/images/remove-icon.png" alt="remove-icon" />
                </IconButton>
            </LightStripedTableCell>
        </LightStripedTableRow>
    )
}
