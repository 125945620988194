// import * as Const from '../Const'
// import { trace } from '../common/index'
import clsx from 'clsx'
import React from 'react'
import moment from 'moment'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Typography, Grid, Paper } from '@material-ui/core'
import { HeadCellItemLabel } from './bases/HeadCellItemLabel'
import { User, Filter, FilterSorting } from '../Types'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        grow: {
            flexGrow: 1,
        },
        layout: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
        cell: {
            height: 'calc(50px)',
            textAlign: 'center',
            borderRadius: 0,
            background: '#F0F0F0',
            '&:hover': {
                cursor: 'pointer',
            },
        },
        container: {
            height: '100%',
            alignItems: 'center',
        },
        column: {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: '1px solid #fff',
        },
        header: {
            height: 'calc(40px)',
            color: '#ffffff',
            borderRadius: 0,
            background: 'transparent linear-gradient(0deg, #5A5A5A 0%, #5F5F5F 7%, #707070 34%, #7A7A7A 64%, #7E7E7E 100%)',
        },
        headerItem: {
            display: 'block',
            width: '100%',
            textAlign: 'center',
        },
        name: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            textAlign: 'left',
            alignItems: 'center',
        },
        date: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            textAlign: 'center',
        },
        fontSize12: {
            fontSize: 12,
        },
        fontSize15: {
            fontSize: 15,
        },
        cursor: {
            cursor: 'pointer',
        },
    }),
)

/**
 * 動画時間の整形.
 *
 * @param {number} value
 * @returns
 */
// function formatDuration(value: number) {
//     const hour = Math.floor(value / 60)
//     const minute = value % 60
//     return (hour > 0 ? hour.toString() + '時間' : '') + minute.toString() + '分'
// }

/**
 * Props
 *
 * @interface UserListCellProps
 */
type UserListCellProps = {
    data: User
    onClickHandler?: (event: React.MouseEvent<unknown, MouseEvent>) => void
}

/**
 * トレーニングセル.
 * @export
 * @param {UserListCellProps} props
 * @returns セルのJSX 要素
 */
export function UserListCell(props: UserListCellProps) {
    const classes = useStyles()
    const handleClick = (event: React.MouseEvent<unknown, MouseEvent>) => {
        if (props.onClickHandler !== undefined) {
            props.onClickHandler(event)
        }
    }
    return (
        <Paper className={clsx(classes.layout, classes.cell)} onClick={(event) => handleClick(event)}>
            <Grid container spacing={1} className={classes.container}>
                <Grid item xs={3} className={classes.column}>
                    <Typography className={clsx(classes.name, classes.fontSize15)} variant="h4">
                        {props.data.user_id}
                    </Typography>
                </Grid>
                <Grid item xs={3} className={classes.column}>
                    <Typography className={clsx(classes.name, classes.fontSize15)} variant="h4">
                        {props.data.user_name}
                    </Typography>
                </Grid>
                <Grid item xs={3} className={classes.column}>
                    <Typography className={clsx(classes.date, classes.fontSize15)} variant="h5">
                        {moment(props.data.created_at).format('YYYY/MM/DD HH:mm')}
                    </Typography>
                </Grid>
                <Grid item xs={3} className={classes.column}>
                    <Typography className={clsx(classes.date, classes.fontSize15)} variant="h5">
                        {moment(props.data.updated_at).format('YYYY/MM/DD HH:mm')}
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    )
}

/**
 * Props
 *
 * @interface HeaderCellProps
 */
type HeaderCellProps = {
    onRequestSort: (newSorting: FilterSorting) => void
    filter: Filter
}

/**
 * 施設セルヘッダ
 *
 * @export
 * @param {HeaderCellProps} props
 * @returns
 */
export function UserListHeaderCell(props: HeaderCellProps) {
    const classes = useStyles()
    return (
        <Paper className={clsx(classes.layout, classes.header)}>
            <Grid container spacing={1} className={classes.container}>
                <Grid item xs={3} className={clsx(classes.column, classes.cursor)}>
                    <Typography className={clsx(classes.headerItem, classes.fontSize12)} variant="subtitle1">
                        <HeadCellItemLabel label="ユーザID" field={'user_id'} filter={props.filter} onRequestSort={props.onRequestSort} />
                    </Typography>
                </Grid>
                <Grid item xs={3} className={clsx(classes.column, classes.cursor)}>
                    <Typography className={clsx(classes.headerItem, classes.fontSize12)} variant="subtitle1">
                        <HeadCellItemLabel label="氏名" field={'user_name'} filter={props.filter} onRequestSort={props.onRequestSort} />
                    </Typography>
                </Grid>
                <Grid item xs={3} className={clsx(classes.column, classes.cursor)}>
                    <Typography className={clsx(classes.headerItem, classes.fontSize12)} variant="subtitle1">
                        <HeadCellItemLabel label="作成日時" field={'created_at'} filter={props.filter} onRequestSort={props.onRequestSort} />
                    </Typography>
                </Grid>
                <Grid item xs={3} className={clsx(classes.column, classes.cursor)}>
                    <Typography className={clsx(classes.headerItem, classes.fontSize12)} variant="subtitle1">
                        <HeadCellItemLabel label="更新日時" field={'updated_at'} filter={props.filter} onRequestSort={props.onRequestSort} />
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    )
}
