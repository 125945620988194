// import * as Const from '../Const'
// import { trace } from '../common/index'
import React from 'react'

import { useAppDispatch } from '../store'
import { fetchCoursesByFacilityId } from '../modules/CourseModule'
import { useFacility } from '../modules/AppModule'

import { Grid, TableContainer, Table, TableBody } from '@material-ui/core'

import { CourseCell, CourseHeaderCell } from './CourseCell'
import { Course, Filter, FilterSorting } from '../Types'
import { useListStyles } from '../Styles'
import PaginationActions from './PaginationActions'
import { isUndefined } from 'util'
import { filterItems } from '../utils/table'

/**
 * トレーニング一覧プロパティ
 *
 * @interface CourseListProps
 */
interface CourseListProps {
    onClickHandler?: (item: Course) => void
}

export default function CourseList(props: CourseListProps) {
    const facility = useFacility()
    const facility_id = isUndefined(facility) || isUndefined(facility.facility_id) ? '' : facility.facility_id

    const dispatch = useAppDispatch()
    const classes = useListStyles()
    const [filter, setFilter] = React.useState<Filter>({
        orderBy: 'updated_at',
        sortBy: 'desc',
        search: '',
    })

    const [items, setItems] = React.useState<Course[]>([])
    React.useEffect(() => {
        if (isUndefined(facility_id)) return
        dispatch(fetchCoursesByFacilityId(facility_id)).then((res) => {
            // console.log('Response:', res)
            const courses = res.payload as Course[]
            const sorted = courses.slice().sort((a, b) => {
                if (isUndefined(a.updated_at) || isUndefined(b.updated_at)) return 0
                if (a.updated_at > b.updated_at) return -1
                if (a.updated_at < b.updated_at) return 1
                return 0
            })
            setItems(sorted)
        })
    }, [dispatch, facility_id])

    /**
     * セルがクリックされた
     */
    const handleClick = (event: React.MouseEvent<unknown>, item: Course) => {
        // console.log('list selected:' + item.course_name)
        if (!isUndefined(props.onClickHandler)) {
            props.onClickHandler(item)
        }
    }
    // ### Pagination.
    // 現在のページ
    const [page, setPage] = React.useState(0)
    // １ページあたりの行数.
    const [rowsPerPage, setRowsPerPage] = React.useState(10)
    // ページが変更された.
    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage)
    }
    // ページ行数が変更された.
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<{
            name?: string | undefined
            value: any
        }>,
    ) => {
        if (typeof event.target.value !== 'number') {
            return
        }

        setRowsPerPage(event.target.value)
        setPage(0)
    }

    const handleSorting = (newSorting: FilterSorting) => {
        setFilter((previous) => ({
            ...previous,
            ...newSorting,
        }))
    }

    // レンダリング.
    return (
        <React.Fragment>
            <Grid container className={classes.wrapper}>
                <Grid item xs={12}>
                    <TableContainer>
                        <Table aria-label="customized table">
                            <CourseHeaderCell onRequestSort={handleSorting} filter={filter} />
                            <TableBody>
                                {(rowsPerPage > 0 ? filterItems(items, filter).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : items).map(
                                    (item, index) => {
                                        return <CourseCell key={index} data={item} onClickHandler={(event) => handleClick(event, item)} />
                                    },
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={12} className={classes.listfooter}>
                    <PaginationActions
                        count={items.length}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        options={[
                            { key: '5', value: 5 },
                            { key: '10', value: 10 },
                            { key: '25', value: 25 },
                            { key: '50', value: 50 },
                            { key: '全て', value: items.length },
                        ]}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    )
}
