// import { trace } from '../common/trace'
import { isUndefined } from 'util'
import React from 'react'

import { useProfile, useHomePageUrl } from '../modules/AppModule'

// Material-UI
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { AppBar, Paper, Toolbar, Typography } from '@material-ui/core'

// User
import { Staff, Role } from '../Types'
// import AvatarLabel from '../components/AvatarLabel'
import PulldownButton from './PulldownButton'
import { useHistory } from 'react-router-dom'
import { getDialogContext } from 'modules/DialogProvider'

/** スタイル定義 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            padding: '0 10px',
            background: 'linear-gradient(0deg, #EAEAEA 10%, #FDFDFD 60%, #FFF 80%)',
        },
        title: {
            flexGrow: 1,
            fontSize: '20px',
            color: 'black',
            fontWeight: 'bold',
        },
        headerNav: {
            width: '960px',
            margin: '0 auto',
            background: 'transparent',
            '& .MuiToolbar-gutters': {
                padding: 0,
            },
        },
        header: {
            width: 'calc(800px)',
            height: 64,
            padding: (theme.spacing(18), theme.spacing(1), theme.spacing(18), theme.spacing(1)),
            textAlign: 'center',
            background: 'transparent url(/assets/images/logo.png) no-repeat center center',
            '&:hover': {
                cursor: 'pointer',
            },
        },
        grow: {
            flexGrow: 1,
        },

        headerLogo: {
            color: 'inherit',
            marginRight: 20,
        },
        headerTitleStyle: {
            flexGrow: 1,
            color: 'inherit',
        },
        menuButton: {
            color: 'inherit',
            padding: '8px',
        },
        drawerList: {
            width: 200,
            height: '100%',
        },
        buttongroup: {
            margin: '2, 0, 2',
            padding: '2, 0, 2',
        },
        pulldown: {
            width: '230px',
            background: 'transparent',
        },
        icon_button: {
            background: '#C5C5C5',
            marginRight: 5,
            color: '#fff',
            height: 40,
            width: 40,
        },
    }),
)

export default function StaffBar() {
    const classes = useStyles()
    // const appTitle = 'RealizeVR'

    const homePageUrl = useHomePageUrl()
    const history = useHistory()

    let profile = useProfile() as Staff
    if (isUndefined(profile)) {
        profile = { staff_name: '' }
    }
    // if (isUndefined(profile.staff_name)) {
    //     profile.staff_name = ''
    // }
    // console.log( "####権限:", profile);

    type optionType = {
        restricted: boolean
        label: string
        url: string
    }

    const training_options: optionType[] = [
        {
            restricted: false,
            label: 'ユーザリスト',
            url: `${homePageUrl}/facility-users`,
        },
        {
            restricted: false,
            label: '指導リスト',
            url: `${homePageUrl}/instructions`,
        },
        {
            restricted: false,
            label: Number(profile.role_id) === Role.manager ? 'トレーニング設定' : 'トレーニング検索',
            url: `${homePageUrl}/trainings`,
        },
        {
            restricted: true,
            label: 'カリキュラム設定',
            url: `${homePageUrl}/courses`,
        },
    ]
    const attendance_options = [
        {
            restricted: false,
            label: '勤怠登録',
            url: `${homePageUrl}/attendances/edit`,
        },
        {
            restricted: true,
            label: '職員勤怠確認',
            url: `${homePageUrl}/attendances`,
        },
    ]
    const profile_options: optionType[] = [
        {
            restricted: true,
            label: 'ユーザ管理',
            url: `${homePageUrl}/users`,
        },
        {
            restricted: true,
            label: '職員管理',
            url: `${homePageUrl}/staffs`,
        },
        {
            restricted: true,
            label: 'お知らせ管理',
            url: `${homePageUrl}/informations`,
        },
        {
            restricted: false,
            label: 'エクスポート',
            url: `${homePageUrl}/export`,
        },
        {
            restricted: false,
            label: 'プロフィール編集',
            url: `${homePageUrl}/profile`,
        },
        {
            restricted: false,
            label: 'ログアウト',
            url: `${homePageUrl}/logout`,
        },
    ]
    const { showDialog } = React.useContext(getDialogContext())
    return (
        <div className={classes.root}>
            {/* 上部のバー */}
            <AppBar position="static" aria-label="Global Navi" className={classes.headerNav}>
                <Toolbar>
                    <Paper
                        className={classes.header}
                        onClick={() => {
                            history.push(homePageUrl)
                        }}
                    >
                        <Typography variant="h6" className={classes.title}>
                            {/* {appTitle} */}
                            &nbsp;
                        </Typography>
                    </Paper>
                    {/* プルダウンメニュー */}
                    <PulldownButton
                        className={classes.pulldown}
                        title={'トレーニング指導'}
                        options={training_options
                            .filter((item) => {
                                return !item.restricted || Number(profile.role_id) === Role.manager
                            })
                            .map((item) => {
                                return { label: item.label, url: item.url }
                            })}
                        onClickHandler={(url: string) => {
                            history.push(url)
                        }}
                    />
                    <PulldownButton
                        className={classes.pulldown}
                        title={'勤怠管理'}
                        options={attendance_options
                            .filter((item) => {
                                return !item.restricted || Number(profile.role_id) === Role.manager
                            })
                            .map((item) => {
                                return { label: item.label, url: item.url }
                            })}
                        onClickHandler={(url: string) => {
                            history.push(url)
                        }}
                    />
                    <div className={classes.grow} />
                    <PulldownButton
                        className={classes.pulldown}
                        icon={profile.icon}
                        title={profile.staff_name === undefined ? '' : profile.staff_name}
                        options={profile_options
                            .filter((item) => {
                                return !item.restricted || Number(profile.role_id) === Role.manager
                            })
                            .map((item) => {
                                return { label: item.label, url: item.url }
                            })}
                        onClickHandler={(url: string) => {
                            switch (url) {
                                case `${homePageUrl}/logout`:
                                    showDialog({
                                        title: 'ログアウトしますか',
                                        message: 'この操作は戻せません',
                                        items: [
                                            {
                                                label: 'はい',
                                                onClickHandler: () => {
                                                    history.push(`${homePageUrl}/logout`)
                                                },
                                            },
                                            { label: 'いいえ' },
                                        ],
                                    })
                                    return
                            }
                            history.push(url)
                        }}
                    />
                </Toolbar>
            </AppBar>
        </div>
    )
}
// function StaffAvatar() {
//     let profile = useProfile() as Staff
//     if ( isUndefined(profile) ) {
//         profile = { staff_name: '', icon: undefined }
//     }
//     const [state] = React.useState({
//         staff_name: isUndefined( profile ) || isUndefined( profile.staff_name ) ? "" : profile.staff_name,
//         staff_icon_url: isUndefined( profile ) || isUndefined( profile.icon ) ? "" : profile.icon,
//     })

//     return (
//         <AvatarLabel type="avatar" title={state.staff_name} url={state.staff_icon_url}/>
//     )
// }
