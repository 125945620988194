import clsx from 'clsx'
import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Box, Typography } from '@material-ui/core'
import { COLOR } from '../Const'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        smallCircle: {
            width: 15,
            height: 15,
            borderRadius: '50%',
        },
        grey: {
            backgroundColor: COLOR.GREY,
        },
        blue: {
            backgroundColor: COLOR.BLUE,
        },
        lightBlue: {
            backgroundColor: COLOR.LIGHT_BLUE,
        },
        white: {
            backgroundColor: COLOR.WHITE,
            border: '1px solid #707070',
        },
        title: {
            fontSize: 12,
            marginLeft: 4,
        },
    }),
)

type LabelWithCircleProps = {
    title: string
    color: 'grey' | 'blue' | 'light_blue' | 'white'
}

export default function LabelWithCircle(props: LabelWithCircleProps) {
    const classes = useStyles()
    let circleColor

    switch (props.color) {
        case 'grey':
            circleColor = classes.grey
            break
        case 'blue':
            circleColor = classes.blue
            break
        case 'light_blue':
            circleColor = classes.lightBlue
            break
        default:
            circleColor = classes.white
            break
    }

    return (
        <Box display="flex" justifyContent="left" alignItems="center" marginRight="15px">
            <Box className={clsx(classes.smallCircle, circleColor)}></Box>
            <Typography variant="h5" className={classes.title}>
                {props.title}
            </Typography>
        </Box>
    )
}
