//
// 管理者ポータル.
// 施設管理.
//
// import * as Const from '../Const'
// import { trace } from '../common/index'
import React from 'react'

import { Typography, Box } from '@material-ui/core'
// User's Component.
import TitleBar from './TitleBar'

import FacilityList from './FacilityList'
import FacilityEdit from './FacilityEdit'

import TrainingSettingEdit2 from './TrainingSettingEdit2'
import SimpleTitleBar from './SimpleTitlebar'
import { NewButton } from './Buttons'
import SearchBar2 from './SearchBar2'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom'
import FacilityStaffContent from './FacilityStaffContent'
import { isUndefined, isNullOrUndefined } from 'util'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: 960,
            margin: '0 auto',
        },
    }),
)

// Local state
// type LocalState = {
//     item?: Facility // データ.
// }

/**
 * 施設コンテナ
 * @export
 * @returns
 */
export default function FacilityContent() {
    // console.log('### FacilityContent')

    const classes = useStyles()

    const history = useHistory()
    const match = useRouteMatch()
    const listPageUrl = match.url

    // 検索バー：新規作成
    const handleClickNew = () => {
        history.push(`${listPageUrl}/create`)
    }
    // [閉じる]
    const handleClose = () => {
        history.push(listPageUrl)
    }

    const getFacilityName = (location: any) => {
        return isNullOrUndefined(location.state) || isUndefined(location.state.facility) || isUndefined(location.state.facility.facility_name)
            ? ''
            : location.state.facility.facility_name
    }

    return (
        <React.Fragment>
            <Switch>
                {/* 一覧コンポーネント */}
                <Route
                    exact
                    path={listPageUrl}
                    render={() => (
                        <>
                            <Box className={classes.container}>
                                <Box marginBottom="30px">
                                    <SearchBar2 right={<NewButton onClickHandler={handleClickNew}>新規登録</NewButton>} />
                                </Box>
                                <TitleBar bgBlue label="施設一覧" />
                            </Box>
                            <FacilityList />
                        </>
                    )}
                />
                <Route
                    path={`${listPageUrl}/create`}
                    render={() => (
                        <>
                            <SimpleTitleBar label="施設登録" />
                            <FacilityEdit onCloseHandler={handleClose} />
                        </>
                    )}
                />
                {/* 編集コンポーネント */}
                <Route
                    exact
                    path={`${listPageUrl}/:facilityId`}
                    render={() => (
                        <>
                            <SimpleTitleBar label="施設詳細" />
                            <FacilityEdit onCloseHandler={handleClose} />
                        </>
                    )}
                />
                {/* トレーニング設定 */}
                <Route
                    path={`${listPageUrl}/:facilityId/training-setting`}
                    render={({ location }: any) => {
                        const facilityName = getFacilityName(location)
                        return (
                            <>
                                {facilityName !== '' && <FacilityTitle>{facilityName}</FacilityTitle>}
                                <SimpleTitleBar label="施設トレーニング設定" />
                                <TrainingSettingEdit2 onCloseHandler={handleClose} />
                            </>
                        )
                    }}
                />
                <Route path={`${listPageUrl}/:facilityId/staffs`} render={() => <FacilityStaffContent />} />
            </Switch>
        </React.Fragment>
    )
}

type FacilityTitleProps = {
    children?: React.ReactNode
}
function FacilityTitle(props: FacilityTitleProps) {
    return (
        <div
            style={{
                margin: '0 auto 20px',
                width: 960,
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <Typography variant="h4">{props.children} </Typography>
        </div>
    )
}
