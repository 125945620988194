//
// 管理者ポータル.
// トレーニング管理.
//
// import * as Const from '../Const'
// import { trace } from '../common/index'
import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

// User's Component.
import TitleBar from './TitleBar'

// トレーニング一覧
import CourseList from './CourseList'
import CourseEdit from './CourseEdit'
import { Box } from '@material-ui/core'
import { NewButton } from './Buttons'
import SearchBar2 from './SearchBar2'
import { useRouteMatch, useHistory, Switch, Route } from 'react-router-dom'
import { Course } from 'Types'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: '35px auto',
            width: 960,
        },
    }),
)

type CourseSettingProps = {}

export default function CourseSetting(props: CourseSettingProps) {
    // console.log('### CourseSetting')

    const classes = useStyles()

    const history = useHistory()
    const match = useRouteMatch()
    const listPageUrl = match.url

    // タイトルバー：新規作成
    const handleClickNew = () => {
        history.push(`${listPageUrl}/create`)
    }

    const handleClickCell = (course: Course) => {
        history.push(`${listPageUrl}/${course?.course_id}`)
    }

    const handleClose = () => {
        history.push(listPageUrl)
    }

    // CSSを適用する。
    // const classes = useStyles();
    return (
        <div className={classes.root}>
            <Switch>
                <Route
                    exact
                    path={listPageUrl}
                    render={() => (
                        <>
                            <Box marginBottom="20px">
                                <SearchBar2 right={<NewButton onClickHandler={handleClickNew}>新規登録</NewButton>} />
                            </Box>
                            <TitleBar bgBlue label="カリキュラム一覧" />
                            <CourseList onClickHandler={handleClickCell} />
                        </>
                    )}
                />
                <Route
                    path={`${listPageUrl}/create`}
                    render={() => (
                        <>
                            <TitleBar label="カリキュラム登録" bgBlue />
                            <CourseEdit onCloseHandler={handleClose} />
                        </>
                    )}
                />
                <Route
                    path={`${listPageUrl}/:courseId`}
                    render={() => (
                        <>
                            <TitleBar label="カリキュラム詳細" bgBlue />
                            <CourseEdit onCloseHandler={handleClose} />
                        </>
                    )}
                />
            </Switch>
        </div>
    )
}
