// import * as Const from '../Const'
// import { trace } from '../common/index'
// import clsx from 'clsx'
import React, { useState } from 'react'

import { useAppDispatch } from '../store'
import { useProfile, useFacility, useHomePageUrl } from '../modules/AppModule'
import { fetchFavoriteUsersByStaffId, addFavoriteUser, removeFavoriteUser } from '../modules/StaffModule'
import { useFacilityUsers, fetchFacilityUsersWithLessons, getFacilityLessons } from '../modules/FacilityModule'
import { updateAttendanceEnter, updateAttendanceLeave, fetchAttendanceByStaffId } from '../modules/AttendanceModule'

// Material-UI
import { Box, Paper, Button, Grid } from '@material-ui/core'

// functional
import * as DateUtil from 'date-fns'

// User's component
import { Staff, User, Attendance, Lesson, NarrowCondition, LessonStatus, LessonsUser } from '../Types'
import StaffSearchBar from '../components/StaffSearchBar'
import AvatarLabel from '../components/AvatarLabel'
import TitleBarHome from './TitleBarHome'
import { DoublePrevButton, DoubleNextButton } from './Buttons'
import StaffTopUserCell from '../components/StaffTopUserCell'
import { isUndefined } from 'util'
import MainDatePicker from './MainDatePicker'
import { useHistory } from 'react-router-dom'
import StaffInformation from '../components/StaffInformation'
import { getImageCacheContext } from 'modules/ImageCacheProvider'
// スタイルを適用する
import { useStyles } from '../components/Staff/Stylex'

export default function StaffTop() {
    const dispatch = useAppDispatch()
    const classes = useStyles()

    const homePageUrl = useHomePageUrl()
    const history = useHistory()

    const [date, setDate] = React.useState(new Date())
    const [state, setState] = React.useState<{
        disable_enter: boolean
        disable_leave: boolean
    }>({
        disable_enter: true,
        disable_leave: true,
    })
    const [filter, setFilter] = useState({
        searchText: '',
        narrowCondition: NarrowCondition.all,
    })

    const facility = useFacility()
    const facility_id = isUndefined(facility) || isUndefined(facility.facility_id) ? '' : facility.facility_id

    let profile = useProfile() as Staff
    const staff_id = isUndefined(profile) || isUndefined(profile.staff_id) ? '' : profile.staff_id

    const imageCache = React.useContext(getImageCacheContext())
    const [duringLessons, setDuringLessons] = React.useState<Lesson[]>([])

    const useEffectOnMount = (effect: React.EffectCallback) => {
        React.useEffect(effect, [])
    }

    useEffectOnMount(() => {
        dispatch(
            getFacilityLessons({
                facility_id: facility_id,
                query: {
                    status: LessonStatus.progressing,
                },
            }),
        ).then((res) => {
            setDuringLessons(res.payload)
        })
    })

    React.useEffect(() => {
        const dateStr = DateUtil.format(date, 'yyyyMMdd')

        if (isUndefined(facility_id) || facility_id === '') {
            return
        }
        if (staff_id === '') {
            return
        }
        // ユーザレッスンを取得.
        dispatch(
            fetchFacilityUsersWithLessons({
                facility_id: facility_id,
                user_id: '-',
                start: dateStr,
                end: dateStr,
            }),
        ).then((res) => {
            // キャッシュ登録.
            imageCache.addUsers(res.payload)

            // 本日の勤怠登録情報.
            const date = new Date()
            const year = DateUtil.getYear(date)
            const month = 1 + DateUtil.getMonth(date)
            const dateStr = DateUtil.format(date, 'yyyy-MM-dd')

            dispatch(
                fetchAttendanceByStaffId({
                    staff_id: staff_id,
                    year: year,
                    month: month,
                }),
            ).then((res) => {
                const todayItem: Attendance = res.payload?.find((item: Attendance) => item.date === dateStr)
                // console.log('勤怠', todayItem)
                if (isUndefined(todayItem)) {
                    setState((state) => {
                        return {
                            ...state,
                            disable_enter: false,
                            disable_leave: true,
                        }
                    })
                } else if (isUndefined(todayItem.enter_time) || todayItem.enter_time === '') {
                    setState((state) => {
                        return {
                            ...state,
                            disable_enter: false,
                            disable_leave: true,
                        }
                    })
                } else if (isUndefined(todayItem.leave_time) || todayItem.leave_time === '') {
                    setState((state) => {
                        return {
                            ...state,
                            disable_enter: true,
                            disable_leave: false,
                        }
                    })
                } else {
                    setState((state) => {
                        return {
                            ...state,
                            disable_enter: true,
                            disable_leave: true,
                        }
                    })
                }
            })
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, date, facility_id, staff_id])

    // Favorite Users.
    const [favoriteUsers, setFavoriteUsers] = React.useState<string[]>([])
    React.useEffect(() => {
        if (staff_id === undefined || staff_id === '') return
        dispatch(fetchFavoriteUsersByStaffId(staff_id)).then((res) => {
            setFavoriteUsers(res.payload)
        })
    }, [dispatch, staff_id])
    // for example FavoriteUser API call
    // dispatch(addFavoriteUser({ staff_id: staff_id, user_id: user_id }))
    // dispatch(removeFavoriteUser({ staff_id: staff_id, user_id: user_id}))

    let facilityUsers = useFacilityUsers()

    function getLessons(user: User): Lesson[] | undefined {
        if (isUndefined(user) || isUndefined(user.lessons) || user.lessons.length === 0) return undefined
        return user.lessons
    }

    // Filter
    const isLessonFound = (lessons: Lesson[] | undefined, status: LessonStatus): boolean => {
        if (isUndefined(lessons)) return false
        for (let i = 0; i < lessons.length; i++) {
            if (!isUndefined(lessons[i]) && lessons[i].status === status) {
                return true
            }
        }
        return false
    }

    const isUserDuringTraining = (user: User) => {
        if (user && duringLessons && duringLessons.length) {
            const lessonDuringTraining = duringLessons.find((lessonItem: Lesson) => {
                if (!lessonItem) {
                    return false
                }
                return lessonItem.user_id
                    ? lessonItem.user_id === user.user_id
                    : lessonItem.lesson_users && lessonItem.lesson_users.find((lessonUser: LessonsUser) => lessonUser.user_id === user.user_id)
                    ? true
                    : false
            })
            return lessonDuringTraining ? true : false
        }
        return false
    }

    const getFacilityUsersFilters = () => {
        const facilityUsersFilters = [...facilityUsers]
        return facilityUsersFilters.filter((facilityUser) => {
            const lessons = getLessons(facilityUser)
            const isUserNameFound = facilityUser.user_name.indexOf(filter.searchText) !== -1
            switch (filter.narrowCondition) {
                case NarrowCondition.favorite:
                    return isUserNameFound && favoriteUsers.includes(facilityUser.user_id || '')
                case NarrowCondition.inTraining:
                    return isUserNameFound && isUserDuringTraining(facilityUser)
                case NarrowCondition.offline:
                    return isUserNameFound && !isUserDuringTraining(facilityUser)
                case NarrowCondition.reserved:
                    return isUserNameFound && isLessonFound(lessons, LessonStatus.reserved)
                case NarrowCondition.request:
                    return isUserNameFound && isLessonFound(lessons, LessonStatus.request)
                case NarrowCondition.noReservation:
                    return isUserNameFound && isUndefined(lessons)
                default:
                    return isUserNameFound
            }
        })
    }

    // Search
    const handleSearch = (filter: { searchText: string; narrowCondition: NarrowCondition }) => {
        setFilter({
            searchText: filter.searchText,
            narrowCondition: filter.narrowCondition,
        })
    }

    const handleClickUserCell = (user: User) => {
        history.push(`${homePageUrl}/facility-users/${user?.user_id}/summary`)
    }

    // 次月.
    const handleNext = () => {
        const newDate = DateUtil.addDays(date, 1)
        setDate(newDate)
    }
    const handlePrev = () => {
        const newDate = DateUtil.addDays(date, -1)
        setDate(newDate)
    }

    const handleChangeDate = (selectedDate: any) => {
        setDate(selectedDate)
    }

    const handleFavoriteChange = (user: User) => (favorite: boolean) => {
        if (isUndefined(user) || isUndefined(user.user_id)) return
        let newFavoriteUsers = favoriteUsers
        if (favorite) {
            dispatch(addFavoriteUser({ staff_id: staff_id, user_id: user.user_id })).then(() => {
                if (isUndefined(user) || isUndefined(user.user_id)) return
                newFavoriteUsers.push(user.user_id)
                setFavoriteUsers(newFavoriteUsers)
            })
        } else {
            dispatch(removeFavoriteUser({ staff_id: staff_id, user_id: user.user_id })).then(() => {
                if (isUndefined(user) || isUndefined(user.user_id)) return
                newFavoriteUsers = newFavoriteUsers.filter((user_id) => user_id !== user.user_id)
                setFavoriteUsers(newFavoriteUsers)
            })
        }
    }

    return (
        <React.Fragment>
            <Box display="flex" className={classes.root}>
                <Box className={classes.left}>
                    <CalendarArea />
                </Box>
                <Box className={classes.right}>
                    <Paper elevation={3} className={classes.paper}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Grid className={classes.notice}>
                                    <StaffInformation />
                                </Grid>

                                <Box className={classes.searchbar}>
                                    <StaffSearchBar onClickHandler={handleSearch} />
                                </Box>
                                {/* <Paper variant="outlined" > */}
                            </Grid>
                        </Grid>
                    </Paper>
                </Box>
            </Box>
            <Box>
                <Grid container className={classes.content}>
                    <Grid item xs={12} className={classes.titleBar}>
                        <TitleBarHome
                            label={DateUtil.format(date, 'yyyy年M月d日')}
                            left={<DoublePrevButton onClickHandler={handlePrev} />}
                            right={<DoubleNextButton onClickHandler={handleNext} />}
                        />
                    </Grid>

                    <Grid container className={classes.listItem}>
                        {getFacilityUsersFilters().map((item: User, index) => {
                            return (
                                <Grid
                                    item
                                    xs={4}
                                    key={index}
                                    style={{
                                        maxWidth: '304px',
                                        margin: index % 3 === 1 ? '30px 24px 0 24px' : '30px 0 0 0',
                                    }}
                                >
                                    <StaffTopUserCell
                                        key={item.user_id}
                                        favoriteUsers={favoriteUsers}
                                        item={item}
                                        onClickHandler={() => handleClickUserCell(item)}
                                        onFavoriteChange={handleFavoriteChange(item)}
                                        bgColor={isUserDuringTraining(item) ? classes.userCellBgColor1 : classes.userCellBgColor2}
                                    />
                                </Grid>
                            )
                        })}
                        <Grid item xs={12}>
                            <Box display="flex" className={classes.guide}>
                                <Box className={classes.guide_item}>
                                    <AvatarLabel title="トレーニング中" type="active" />
                                </Box>
                                <Box className={classes.guide_item}>
                                    <AvatarLabel title="その他" type="offline" />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </React.Fragment>
    )

    // カレンダー.
    function CalendarArea() {
        // 出勤ボタンのクリックイベントハンドラ
        const handleClickEnter = () => {
            if (!isUndefined(profile) && !isUndefined(profile.staff_id)) {
                console.log(`# ENTER: ${profile.staff_id}: ${date}`)
                dispatch(updateAttendanceEnter(profile.staff_id))
                setState({
                    ...state,
                    disable_enter: true,
                    disable_leave: false,
                })
            }
        }
        // 退勤ボタンのクリックイベントハンドラ
        const handleClickLeave = () => {
            if (!isUndefined(profile) && !isUndefined(profile.staff_id)) {
                console.log(`# LEAVE: ${profile.staff_id}: ${date}`)
                dispatch(updateAttendanceLeave(profile.staff_id))
                setState({ ...state, disable_enter: true, disable_leave: true })
            }
        }
        return (
            <Paper elevation={3} className={classes.paper_calendar}>
                <Grid container spacing={0}>
                    <Grid item xs={6}>
                        <Button
                            variant="outlined"
                            onClick={handleClickEnter}
                            className={classes.attendance_button}
                            // 出勤ボタンの表示制御
                            disabled={state.disable_enter}
                        >
                            出勤
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant="outlined"
                            onClick={handleClickLeave}
                            className={classes.quit_button}
                            // 退勤ボタンの表示制御
                            disabled={state.disable_leave}
                        >
                            退出
                        </Button>
                    </Grid>
                </Grid>
                <Box className={classes.calendar}>
                    <MainDatePicker inline selected={date} onChange={handleChangeDate} />
                </Box>
            </Paper>
        )
    }
}
