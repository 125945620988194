import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            padding: '10px 0',
            // backgroundColor: "#00FF00",
            width: 960,
            margin: '0 auto',
        },
        growth_record: {
            width: 960,
            margin: '0 auto',
        },
        staff_user_cell: {
            marginTop: 35,
        },
        video_container: {
            width: 960,
            margin: '20px auto 30px auto',
        },
        view: {
            display: 'flex',
            justifyContent: 'center',
            // alignItems: 'center',
        },
        left_view: {
            width: '100%',
            // height: 400,
        },
        right_view: {
            width: '75%',
            marginLeft: 10,
        },
        video_on_right: {
            border: '2px solid #5a5a5a',
            padding: 0,
            borderRadius: 0,
            height: 372,
        },

        //
        box: {
            display: 'flex',
            justifyContent: 'space-between',
            // backgroundColor: '#80ffff',
            // height: 40,
            borderBottom: '1px solid #fff',
        },
        boxBgColor1: {
            backgroundColor: '#F0F0F0',
        },
        boxBgColor2: {
            backgroundColor: '#E9E9E9',
        },
        header: {
            color: '#fff',
            fontWeight: 'bold',
            minHeight: 35,
            marginTop: 4,
            '& div:first-child': {
                alignItems: 'center',
                justifyContent: 'center',
            },
            background: 'linear-gradient(0deg, #5a5a5a 10%, #707070 80%, #5F5F5F 100%)',
        },
        checkbox: {
            marginLeft: 10,
            // backgroundColor: '#ff0000',
            width: 180,
            flexShrink: 0,
            //            maxWidth: 200,
            borderRight: '1px solid #fff',
            padding: '5px 0',
            height: 35,
            display: 'flex',
        },
        trend: {
            marginLeft: 10,
            width: 80,
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            // backgroundColor: '#ff0000',
            borderRight: '1px solid #fff',
            padding: '5px 0',
            height: 35,
        },
        trendColor: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 25,
            height: 25,
            borderRadius: '50%',
            backgroundColor: '#ececec',
            color: '##404040',
            fontWeight: 'bold',
            boxShadow: '1px 1px 5px #b3b3b3',
        },
        comment: {
            flex: 1,
            borderRight: '1px solid #fff',
            padding: '5px 10px',
            display: 'flex',
            justifyContent: 'center',
            '& .MuiInput-underline:before': {
                borderBottom: 0,
            },
            '& .MuiInputBase-root.Mui-disabled': {
                boxShadow: 'none',
            },
            '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                borderBottom: 0,
            },
            '& .MuiInput-underline:after': {
                borderBottom: 0,
            },
        },
        textfield: {
            height: 40,
            background: '#fff',
            boxShadow: '1px 1px 1px #b3b3b3',
            padding: '0 10px',
        },
        disabled: {
            color: '#c0c0c0',
            backgroundColor: '#e0e0e0',
        },
        achievement: {
            marginLeft: 10,
            width: 100,
            display: 'flex',
            padding: 5,
        },
        pulldown: {
            width: 100,
            // height: 40,
            // backgroundColor: '#0080ff',
        },
        slider: {
            width: 160,
            marginLeft: 6,
            color: theme.palette.grey[700], // スライダーの色.
        },
        video: {
            alignSelf: 'flex-start',
        },
        videochat: {
            alignSelf: 'flex-start',
        },
        control_panel: {
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
        },
        video_controller: {
            // backgroundColor: '#8080ff',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            height: 30,
            flexShrink: 0,
            margin: '20px 0',
            '& .MuiIconButton-root': {
                padding: 0,
                boxShadow: '1px 1px 1px #b3b3b3',
                height: 42,
                width: 42,
            },
            '& .MuiIconButton-root:first-child': {
                marginRight: 15,
            },
        },

        center: {
            backgroundColor: '8080ff',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
        },
        left: {
            backgroundColor: 'ff8080',
            alignItems: 'center',
            justifyContent: 'left',
            textAlign: 'center',
        },
        right: {
            backgroundColor: '80ff80',
            alignItems: 'center',
            justifyContent: 'right',
            textAlign: 'center',
        },
        mute: {},
        button_area: {
            marginTop: 10,
            marginBottom: 10,
            display: 'flex',
            justifyContent: 'center',
            padding: theme.spacing(1),
        },

        footer: {
            marginTop: theme.spacing(2),
            marginBottom: 40,
        },
        form_control: {
            width: '100%',
            minWidth: 100,
            paddingRight: 10,
            '& .MuiInputBase-root': {
                height: 40,
                background: '#fff',
                boxShadow: '1px 1px 1px #b3b3b3',
                padding: '0 10px',
            },
            '& .MuiInputBase-root.Mui-disabled': {
                background: '#e0e0e0',
                boxShadow: 'none',
            },
            '& .MuiInput-underline:before': {
                borderBottom: 0,
            },
            '& .MuiInput-underline:after': {
                borderBottom: 0,
            },
            '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                borderBottom: 0,
            },
        },
        submit_button: {
            background: 'linear-gradient(0deg, #EAEAEA 10%, #FDFDFD 60%, #FFF 80%)',
            border: '1px solid #6e6e6e',
        },
        undo_button: {
            background: 'linear-gradient(0deg, #EAEAEA 10%, #FDFDFD 60%, #FFF 80%)',
            border: '1px solid #6e6e6e',
        },
        stop_teaching: {
            background: 'linear-gradient(0deg, #5a5a5a 10%, #707070 80%, #5F5F5F 100%)',
            border: '1px solid #6e6e6e',
            color: '#fff',
            marginRight: 15,
            '& .MuiTypography-h4': {
                fontSize: 15,
            },
        },
        return: {
            background: 'linear-gradient(0deg, #EAEAEA 10%, #FDFDFD 60%, #FFF 80%)',
            border: '1px solid #6e6e6e',
            '& .MuiTypography-h4': {
                fontSize: 15,
            },
        },
        header_title: {
            marginBottom: 10,
            color: '#5a5a5a',
            fontWeight: 'bold',
            fontSize: '15px',
        },
        title_label: {
            display: 'flex',
            alignItems: 'center',
            width: '10%',
            borderRight: '1px solid #fff',
        },
        title_video: {
            display: 'flex',
            alignItems: 'center',
            padding: '4px 8px',
            marginLeft: 6,
            width: '90%',
        },
        cell: {
            verticalAlign: 'top',
        },
        userLesson: {
            marginBottom: 30,
        },
        listUserLesson: {
            marginTop: 4,
        },
        radioForm: {
            marginLeft: 10,
        },
        radio: {
            display: 'flex',
            alignItems: 'flex-start',
            '& .MuiIconButton-root': {
                padding: '0 4px 4px',
                '&:hover': {
                    backgroundColor: 'unset',
                },
            },
        },
        // Navigate Icon
        navigate_cell: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            // background: '#ff0000',
            width: '100%',
            height: 60,
            border: 'solid 1px',
        },
        navigate: {
            margin: 4,
        },
        navigate_left: {
            // position: 'absolute',
            // left: 0,
        },
        navigate_right: {
            // position: 'absolute',
            // right: 0,
        },
        pagination_cell: {
            display: 'flex',
            marginTop: 20,
            justifyContent: 'center',
            alignItems: 'center',
            // background: '#ff0000',
            width: '100%',
            height: 60,
            border: 'solid 1px',
        },

        pagination_ul: {
            listStyle: 'none',
            padding: 0,
            margin: 0,
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
        },

        wrap_container: {
            // background: '#e0e0ff',
            display: 'flex',
            // flexDirection: 'column',
            // flexWrap: 'wrap',
            margin: 0,
            // justifyContent: 'flex-start',
            // alignItems: 'center',
            // maxWidth: 410,
            maxHeight: 360,
            flexGrow: 1,
            flexBasis: 0,
            overflowY: 'scroll',
        },
        wrap_cell: {
            width: 200,
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
        },
        lessonComment: {
            marginTop: 20,
        },
    }),
)
