import React from 'react'
import { Box, Checkbox, createStyles, FormControlLabel, FormGroup, makeStyles, Menu, Theme } from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { OPTION_ALL } from 'Const'
import { getOptionLabelById } from 'modules/Util'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formGroup: {
            paddingLeft: 12,
        },
        formControlLabel: {
            '& .MuiFormControlLabel-label': {
                display: 'inline-block',
                width: '100%',
            },
        },
        labelGroup: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        avatarLabel: {
            marginLeft: 10,
        },
        select: {
            width: 220,
            height: 34,
            border: '1px solid gray',
            borderRadius: '4px',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            '&:hover': {
                cursor: 'pointer',
            },
        },
        menu: {
            borderBottom: '1px solid gray',
            borderLeft: '1px solid gray',
            borderRight: '1px solid gray',
        },
        boxValue: {
            padding: 4,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
        },
    }),
)

interface FilterListIconProps {
    id?: string
    optionValues: (number | string)[]
    avatarLabels?: { [key: string]: JSX.Element }
    filterValue: (number | string)[]
    setFilter: (value: (number | string)[]) => void
}

export default function OutSideFilterList({ id, avatarLabels, optionValues, filterValue, setFilter }: FilterListIconProps) {
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

    const handlePopperClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const onChange = (e: any) => {
        let value = e.target.name.toString()
        // convert to number if a string is a valid number (Ex: '0' to 0)
        if (!isNaN(+value)) {
            value = +value
        }

        if (filterValue && filterValue.includes(value)) {
            if (value === OPTION_ALL.value) {
                setFilter([])
            } else {
                setFilter(filterValue.filter((val: number | string) => val !== value && val !== OPTION_ALL.value))
            }
        } else {
            if (value === OPTION_ALL.value) {
                setFilter(optionValues)
            } else {
                if (filterValue.length + 1 === optionValues.length - 1) {
                    setFilter([...filterValue, value, OPTION_ALL.value])
                } else {
                    setFilter([...filterValue, value])
                }
            }
        }
    }

    return (
        <Box display="inline-block" marginLeft="10px">
            <Box>担当</Box>
            <Box className={classes.select} onClick={handlePopperClick}>
                <Box className={classes.boxValue}>
                    {filterValue?.includes(OPTION_ALL.value) ? OPTION_ALL.label : filterValue?.map((val) => getOptionLabelById(val, id)).join(', ')}
                </Box>
                <ArrowDropDownIcon />
            </Box>
            <Menu
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                PaperProps={{
                    style: {
                        border: '1px solid gray',
                        minWidth: 220,
                        minHeight: 34,
                        maxHeight: 350,
                    },
                }}
            >
                <FormGroup className={classes.formGroup}>
                    {optionValues.map((option: any, index: any) => {
                        const optionLabel = getOptionLabelById(option, id)

                        return (
                            <FormControlLabel
                                key={index}
                                className={classes.formControlLabel}
                                control={<Checkbox checked={filterValue?.includes(option)} onChange={onChange} name={option} color="default" />}
                                label={
                                    <Box className={classes.labelGroup}>
                                        <Box>{optionLabel}</Box>
                                        {avatarLabels && avatarLabels[option] && <Box className={classes.avatarLabel}>{avatarLabels[option]}</Box>}
                                    </Box>
                                }
                            />
                        )
                    })}
                </FormGroup>
            </Menu>
        </Box>
    )
}
