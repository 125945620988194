import React from 'react'
import clsx from 'clsx'
import { isUndefined } from 'util'
import { formatDateTime } from 'modules/Util'
import { getLessonStatusText } from 'modules/DataUtil'

import { Lesson, LessonStatus, LessonType, Staff } from 'Types'

import { Box, createStyles, Grid, makeStyles, Paper, TableContainer, Theme, Typography } from '@material-ui/core'
import { AddCircle } from '@material-ui/icons'
import GroupIcon from '@material-ui/icons/Group'

// import { useProfile } from 'modules/AppModule'
import { deleteLesson, fetchLessonsByUser, updateLessonStatus } from 'modules/LessonModule'
import { useAppDispatch } from 'store'
import { getImageCacheContext } from 'modules/ImageCacheProvider'
import { getDialogContext } from 'modules/DialogProvider'

import { useHistory, useRouteMatch } from 'react-router-dom'
import { MultiCheckboxColumnFilter, MultiCheckboxWithStaffAvatarColumnFilter } from 'components/react-table/filters'
import { useListStyles } from 'Styles'

import AvatarLabel from './AvatarLabel'
import { AddButton } from './Buttons'
import MenuAction from './MenuAction'
import ReactTable from './react-table/ReactTable'
import SmallAvatar from './SmallAvatar'
import { StripedTablePaper } from './Tables'
import TitleBar from './TitleBar'
import Popup from './Popup'
import ReservationEdit from './ReservationEdit'
import { usePortal, useProfile } from 'modules/AppModule'
import StaffInstructionEdit from './StaffInstructionEdit'
import useStaffRole from 'hooks/useStaffRole'
import useShowError from 'hooks/useShowError'
//----------------------------------------------------------------------------------------
// スタイルを適用する
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        panel: {
            padding: theme.spacing(0),
        },
        table: {
            marginBottom: 20,
        },
        lessonStatus: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        request: {
            backgroundColor: '#CDEEF3',
            color: '#5A5A5A',
        },
        reserved: {
            backgroundColor: '#CDEEF3',
            color: '#5A5A5A',
        },
        finish: {
            backgroundColor: '#B6B6B6',
            color: '#FFFFFF',
        },
        progressing: {
            backgroundColor: '#05A9C2',
            color: '#FFFFFF',
        },
    }),
)

type UserTrainingListProps = {
    user_id?: string
    onClickHandler?: (log: Lesson | undefined) => void
    onCloseHandler?: () => void
    onGetDefaultCourseId?: (courseId: string) => void
}
//----------------------------------------------------------------------------------------
export default function UserTrainingList(props: UserTrainingListProps) {
    const { user_id, onGetDefaultCourseId } = props
    const classes = useStyles()
    const listClasses = useListStyles()
    const dispatch = useAppDispatch()
    const history = useHistory()
    const match = useRouteMatch()
    const imageCache = React.useContext(getImageCacheContext())
    const { showDialog } = React.useContext(getDialogContext())
    const listPageUrl = match.url
    const portal = usePortal()
    const profile = useProfile()
    const { isStaff, isManagerStaff } = useStaffRole()
    let staffProfileId = ''
    if (portal === 'staff') {
        const staffProfile = profile as Staff
        staffProfileId = isUndefined(staffProfile) || isUndefined(staffProfile.staff_id) ? '' : staffProfile.staff_id
    }

    const [lessons, setLessons] = React.useState<Lesson[]>([])
    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    const [openPopup, setOpenPopup] = React.useState(false)
    const [recordForEdit, setRecordForEdit] = React.useState<Lesson | null>(null)

    const getLessonList = React.useCallback(() => {
        if (!isUndefined(user_id)) {
            setIsLoading(true)
            dispatch(
                fetchLessonsByUser({
                    user_id: user_id,
                    start: '-',
                    end: '-',
                }),
            ).then((res) => {
                const lessons = res.payload as Lesson[]
                console.log('>>> lessons', lessons)
                const users = res.payload.map((lesson: Lesson) => {
                    return lesson.user
                })
                imageCache.addUsers(users)
                const staffs = res.payload.map((lesson: Lesson) => {
                    return lesson.staff
                })
                imageCache.addStaffs(staffs)

                if (lessons && lessons.length > 0) {
                    // sort - desc 'start'
                    var sorted = lessons.slice().sort((a, b) => {
                        if (isUndefined(a.start) || isUndefined(b.start)) {
                            return 0
                        }
                        if (a.start > b.start) return -1
                        if (a.start < b.start) return 1
                        return 0
                    })

                    setLessons(sorted)
                    // Get latest lesson reservation's course
                    const defaultCourseId = sorted[0]?.course?.course_id ?? ''
                    if (onGetDefaultCourseId) {
                        onGetDefaultCourseId(defaultCourseId)
                    }
                }
                setIsLoading(false)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user_id, onGetDefaultCourseId])

    React.useEffect(
        () => getLessonList(),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dispatch, user_id],
    )

    const handleCellClick = React.useCallback(
        (lessonId: string) => {
            if (portal === 'user') {
                history.push(`${listPageUrl}/lessons/${lessonId}/detail`)
            }
            if (portal === 'staff') {
                history.push(`${listPageUrl}/instructions/${lessonId}`)
            }
        },
        [history, listPageUrl, portal],
    )

    const handleDeleteClick = React.useCallback(
        (lessonId: string) => {
            showDialog({
                title: '本当に削除してもよろしいですか？',
                message: 'この操作は戻せません',
                items: [
                    {
                        label: 'はい',
                        onClickHandler: () => {
                            dispatch(deleteLesson(lessonId)).then(() => {
                                const newLessons = lessons.filter((newLesson) => newLesson.lesson_id !== lessonId)
                                setLessons(newLessons)
                            })
                        },
                    },
                    { label: 'いいえ' },
                ],
            })
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [lessons],
    )

    const handleApproveLessonClick = React.useCallback(
        (lessonId: string) => {
            const param = {
                lesson_id: lessonId,
                status: LessonStatus.reserved,
            }
            dispatch(updateLessonStatus(param)).then(() => {
                const newLessons = [...lessons].map((lesson) => (lesson.lesson_id === lessonId ? { ...lesson, status: LessonStatus.reserved } : lesson))
                setLessons(newLessons)
            })
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [lessons],
    )

    const columns = React.useMemo(
        () => [
            {
                Header: '受講状況',
                widthPx: '164px',
                accessor: 'status',
                disableSortBy: true,
                Filter: MultiCheckboxColumnFilter,
                filter: 'multiple',
                Cell: ({ value, row }: any) => {
                    const staffId = row.original.staff.staff_id
                    const lessonId = row.original.lesson_id
                    const lessonType = row.original.lesson_type
                    const { showError, ErrorMsg } = useShowError('担当以外の予約の承認はできません。')
                    return (
                        <>
                            <StripedTablePaper
                                square
                                className={clsx(classes.lessonStatus, classes[value as LessonStatus])}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    if (value !== LessonStatus.request || lessonType === LessonType.concurrent || !isStaff) return
                                    if (isManagerStaff || staffProfileId === staffId) {
                                        handleApproveLessonClick(lessonId)
                                    } else {
                                        showError()
                                    }
                                    return
                                }}
                            >
                                {getLessonStatusText(value)}
                            </StripedTablePaper>
                            {ErrorMsg}
                        </>
                    )
                },
            },
            {
                Header: '種別',
                widthPx: '66px',
                accessor: 'lesson_type',
                disableSortBy: true,
                Filter: MultiCheckboxColumnFilter,
                filter: 'multiple',
                Cell: ({ value }: any) => {
                    return (
                        <Box textAlign="center">
                            {value === LessonType.concurrent && (
                                <SmallAvatar style={{ height: 30, width: 30, display: 'inline-flex' }}>
                                    <GroupIcon />
                                </SmallAvatar>
                            )}
                        </Box>
                    )
                },
            },
            {
                Header: 'カリキュラム名・トレーニング名',
                widthPx: '296px',
                accessor: 'course.course_name',
                disableSortBy: true,
                Filter: MultiCheckboxColumnFilter,
                filter: 'multiple',
                Cell: ({ value, row }: any) => {
                    const trainingName = row.original.training.training_name
                    return (
                        <StripedTablePaper square>
                            {value}&nbsp;&nbsp;&nbsp;&nbsp;{trainingName}
                        </StripedTablePaper>
                    )
                },
            },
            {
                Header: '支援スタッフ',
                widthPx: '190px',
                accessor: 'staff.staff_name',
                disableSortBy: true,
                Filter: MultiCheckboxWithStaffAvatarColumnFilter,
                filter: 'multiple',
                Cell: ({ value, row }: any) => {
                    return (
                        <Box style={{ padding: 10 }}>
                            <AvatarLabel title={value} type="avatar_on_right" url={imageCache.getStaffImage(row.original.staff)} />
                        </Box>
                    )
                },
            },
            {
                Header: '予約日時',
                widthPx: '194px',
                accessor: 'start',
                sortType: 'basic',
                disableFilters: true,
                Cell: ({ value }: any) => {
                    return (
                        <Box style={{ padding: 10 }}>
                            <Typography variant="h5">{formatDateTime(value)}</Typography>
                        </Box>
                    )
                },
            },
            {
                Header: '編集',
                widthPx: '50px',
                disableSortBy: true,
                disableFilters: true,
                Cell: ({ row }: any) => {
                    const lesson = row.original
                    const lessonStatus = lesson.status
                    const lessonType = lesson.lesson_type
                    const staffId = row.original.staff.staff_id
                    return (
                        (portal === 'user' || staffProfileId === staffId) && (
                            <MenuAction
                                menuList={[
                                    {
                                        label: '編集',
                                        disable: lessonStatus !== LessonStatus.reserved && (portal === 'user' ? lessonStatus !== LessonStatus.request : true),
                                        onClick: () => openInPopup(lesson),
                                    },
                                    {
                                        label: '削除',
                                        disable: lessonStatus !== LessonStatus.reserved && (portal === 'user' ? lessonStatus !== LessonStatus.request : true),
                                        onClick: () => handleDeleteClick(lesson.lesson_id),
                                    },
                                ]}
                                canOpen={portal === 'user' ? lessonType !== LessonType.concurrent : true}
                            />
                        )
                    )
                },
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [handleApproveLessonClick, handleDeleteClick, imageCache, portal, staffProfileId, isStaff, isManagerStaff],
    )

    const initialState = React.useMemo(() => {
        return {
            sortBy: [{ id: 'start', desc: true }],
        }
    }, [])

    const handleAfterAddOrEdit = () => {
        setRecordForEdit(null)
        closePopup()
        // Render lesson list
        getLessonList()
    }

    const openInPopup = (item: Lesson) => {
        setRecordForEdit(item)
        setOpenPopup(true)
    }

    const closePopup = () => {
        setOpenPopup(false)
    }

    return (
        <Box className={classes.panel}>
            <TitleBar
                label="トレーニングリスト"
                bgBlue
                right={
                    <AddButton
                        icon={<AddCircle fontSize="inherit" />}
                        onClickHandler={() => {
                            setOpenPopup(true)
                            setRecordForEdit(null)
                        }}
                    />
                }
                endRight
            />
            <Grid container className={listClasses.wrapper}>
                <TableContainer component={Paper} className={classes.table}>
                    <ReactTable
                        columns={columns}
                        data={lessons}
                        initialState={initialState}
                        accessor="lesson_id"
                        isLoading={isLoading}
                        onCellClick={handleCellClick}
                    />
                </TableContainer>
            </Grid>
            {recordForEdit?.lesson_type === LessonType.concurrent ? (
                <Popup title="一斉指導予約" openPopup={openPopup} setOpenPopup={setOpenPopup}>
                    <StaffInstructionEdit recordForEdit={recordForEdit} onAfterAddOrEditHandler={handleAfterAddOrEdit} onCloseHandler={closePopup} />
                </Popup>
            ) : (
                <Popup title="指導予約" openPopup={openPopup} setOpenPopup={setOpenPopup}>
                    <ReservationEdit recordForEdit={recordForEdit} onAfterAddOrEditHandler={handleAfterAddOrEdit} onCloseHandler={closePopup} />
                </Popup>
            )}
        </Box>
    )
}
