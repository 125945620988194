//
// Authorization Token
//
import { base64Encode } from 'modules/Util'

export function getAccessToken() {
    const saved_auth = localStorage.getItem('token')
    const auth = saved_auth ? JSON.parse(saved_auth) : '{}'
    return auth?.access_token
    // return {}
}

// リフレッシュトークンを取得.
export function getRefreshToken() {
    const saved_auth = localStorage.getItem('token')
    const auth = saved_auth ? JSON.parse(saved_auth) : '{}'
    return auth?.refresh_token
    // return {}
}

export function getToken() {
    localStorage.getItem('token')
}
export function setToken(token: string) {
    localStorage.setItem('token', token)
}

export function removeToken() {
    localStorage.removeItem('token')
}

// [DEBUG] トークン認証の有効期間を指定して確認する場合は、ここで
export function getCustomExpire() {
    return ''
    // return '?tokenExpire=10,10m'
}

// クライアント認証 (Authorization: Basic)
export function getClientKey() {
    return base64Encode('Realize:atZnG8eUUt1GOA3i')
}
