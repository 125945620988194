// import * as Const from '../Const'
import { isUndefined } from 'util'

import { trace } from '../common/index'

import React from 'react'
import { useAppDispatch } from '../store'
import { fetchStaffByFacilityId, useStaffItems } from '../modules/StaffModule'

import { Grid, TableContainer, Table, TableBody } from '@material-ui/core'

import { Staff, Filter, FilterSorting } from '../Types'
import { useListStyles } from '../Styles'
import { FacilityStaffListCell, FacilityStaffListHeaderCell } from './FacilityStaffListCell'
import PaginationActions from './PaginationActions'
import { useParams } from 'react-router-dom'
import { useFacility } from 'modules/AppModule'
import { getImageCacheContext } from 'modules/ImageCacheProvider'
import { filterItems } from '../utils/table'

/**
 * プロパティ
 *
 * @interface FacilityStaffListProps
 */
type FacilityStaffListProps = {
    onClickHandler?: (item: Staff) => void
}

export default function FacilityStaffList(props: FacilityStaffListProps) {
    const imageCache = React.useContext(getImageCacheContext())

    const dispatch = useAppDispatch()
    const classes = useListStyles()
    const [filter, setFilter] = React.useState<Filter>({
        orderBy: 'updated_at',
        sortBy: 'desc',
        search: '',
    })

    let selectedFacilityId = ''
    const facility = useFacility()
    const { facilityId } = useParams()
    if (!isUndefined(facilityId)) {
        // /admin/facilities/:facilityId/staffs
        selectedFacilityId = facilityId
    } else {
        // /staff/staffs
        selectedFacilityId = isUndefined(facility) || isUndefined(facility.facility_id) ? '' : facility.facility_id
    }

    // 初回ロード.
    React.useEffect(() => {
        dispatch(fetchStaffByFacilityId(selectedFacilityId)).then((res) => {
            const staffs = res.payload
            imageCache.addStaffs(staffs)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, selectedFacilityId])

    let items = useStaffItems()
    if (isUndefined(items)) items = []

    // console.log( "##################", items );

    // セルがクリックされた.
    const handleClick = (event: React.MouseEvent<unknown>, item: Staff) => {
        // trace('Cell selected:' + item.staff_name)
        // 編集へ
        if (props.onClickHandler !== undefined) {
            props.onClickHandler(item)
        }
    }

    // ### Pagination.
    // 現在のページ
    const [page, setPage] = React.useState(0)
    // １ページあたりの行数.
    const [rowsPerPage, setRowsPerPage] = React.useState(10)
    // ページが変更された.
    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage)
    }
    // ページ行数が変更された.
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<{
            name?: string | undefined
            value: any
        }>,
    ) => {
        trace('行数変更')
        if (typeof event.target.value !== 'number') {
            return
        }

        setRowsPerPage(event.target.value)
        setPage(0)
    }

    const handleSorting = (newSorting: FilterSorting) => {
        setFilter((previous) => ({
            ...previous,
            ...newSorting,
        }))
    }

    return (
        <React.Fragment>
            <Grid container className={classes.content}>
                <Grid item xs={12}>
                    <TableContainer>
                        <Table aria-label="customized table">
                            <FacilityStaffListHeaderCell onRequestSort={handleSorting} filter={filter} />
                            <TableBody>
                                {(rowsPerPage > 0 ? filterItems(items, filter).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : items).map(
                                    (item: Staff, index: number) => {
                                        return <FacilityStaffListCell key={index} data={item} onClickHandler={(event) => handleClick(event, item)} />
                                    },
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={12} className={classes.listfooter}>
                    <PaginationActions
                        count={items.length}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        options={[
                            { key: '5', value: 5 },
                            { key: '10', value: 10 },
                            { key: '25', value: 25 },
                            { key: '50', value: 50 },
                            { key: '全て', value: items.length },
                        ]}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    )
}
