import React from 'react'
import clsx from 'clsx'
import { Checkbox, Theme, TextField, createStyles, makeStyles, Box, Typography, RadioProps, Radio, withStyles } from '@material-ui/core'
import { Rating, IconContainerProps } from '@material-ui/lab'
import { isNull } from 'util'
import { COLOR } from 'Const'

const useTextFieldStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: '6px 0 0',
            '&.Mui-error > *': {
                borderColor: 'red',
            },
            '&.Mui-focused.Mui-error > *': {
                borderColor: 'transparent',
                boxShadow: '0 0 0 1px red',
            },
        },
        input: {
            position: 'relative',
            borderColor: '#6E6E6E',
            backgroundColor: theme.palette.common.white,
            border: '1px solid #ced4da',
            fontSize: 16,
            width: '100%',
            padding: '10px 12px',
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            '&:focus': {
                borderColor: theme.palette.primary.main,
            },
        },
        label: {
            fontSize: 18,
            color: 'black',
        },
    }),
)

export const InputTextField = (props: any) => {
    const classes = useTextFieldStyles()
    return (
        <TextField
            {...props}
            InputProps={{
                classes: {
                    root: classes.root,
                    input: classes.input,
                },
                disableUnderline: true,
            }}
            InputLabelProps={{
                classes: {
                    root: classes.label,
                },
                shrink: true,
            }}
        />
    )
}

export const GreyLabelInputField = withStyles((theme: Theme) =>
    createStyles({
        root: {
            marginBottom: 20,
            '& .MuiInputBase-input': {
                border: 'none',
                boxShadow: '1px 1px 2px #0000004D',
                '&.Mui-disabled': {
                    opacity: 0.6,
                },
            },
            '& label.MuiInputLabel-root': {
                fontSize: 16,
                padding: '5px 10px',
                display: 'block',
                width: '100%',
                transform: 'translate(0, -2.5px) scale(1)',
                transformOrigin: 'top left',
                background: 'transparent linear-gradient(0deg, #5A5A5A 0%, #5F5F5F 7%, #707070 34%, #7A7A7A 64%, #7E7E7E 100%)',
                color: theme.palette.common.white,
                boxShadow: '1px 1px 2px #0000004D',
                '&.Mui-error': {
                    color: '#f44336',
                },
            },
        },
    }),
)(InputTextField)

export const InputCheckBox = (props: any) => {
    return (
        <Checkbox
            {...props}
            color="default"
            checkedIcon={<img src="/assets/images/checked.png" alt="" />}
            icon={<img src="/assets/images/non-checked.png" alt="" />}
        />
    )
}

const useRatingStyles = makeStyles((theme: Theme) =>
    createStyles({
        icon: {
            marginRight: 2,
            '&>div': {
                backgroundColor: 'currentColor',
                height: 20,
                width: 20,
                lineHeight: 20,
                borderRadius: 5,
                fontSize: 20,
                textAlign: 'center',
                border: '1px solid #707070',
                '&>p': {
                    fontWeight: 'bold',
                },
            },
        },
        iconEmpty: {
            color: '#F0F0F0',
            '&>div>p': {
                color: '#B6B6B6 !important',
            },
        },
        iconFilled: {
            color: '#CDEEF3',
            '&>div>p': {
                color: '#05A9C2 !important',
            },
        },
        iconHover: {
            color: '#bfe6ec',
            '&>div>p': {
                color: '#02a0b8 !important',
            },
        },
    }),
)

const IconContainer = (props: IconContainerProps) => {
    const { value, ...other } = props
    // 1,2,...9 => 1,1.5,...5
    const convertRatingToValue = (rating: number | null) => (isNull(rating) ? rating : (rating - 1) / 2 + 1)
    const ratingValue = convertRatingToValue(value)
    return (
        <div {...other}>
            <Box>
                <Typography>{ratingValue === parseInt(ratingValue + '', 10) ? ratingValue : ''}</Typography>
            </Box>
        </div>
    )
}

export const InputRating = (props: any) => {
    const classes = useRatingStyles()
    return <Rating {...props} classes={classes} IconContainerComponent={IconContainer} />
}

const useSelectStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: '6px 0 0',
            '&.Mui-error > *': {
                borderColor: 'red',
            },
            '&.Mui-focused.Mui-error > *': {
                borderColor: 'transparent',
                boxShadow: '0 0 0 1px red',
            },
            '& .MuiSelect-icon': {
                boxShadow: 'none !important',
            },
            '& .MuiSelect-iconOpen': {
                transform: 'none',
                zIndex: 9999,
            },
        },
        input: {
            position: 'relative',
            borderColor: '#6E6E6E',
            backgroundColor: theme.palette.common.white,
            border: '1px solid #ced4da',
            fontSize: 16,
            width: '100%',
            padding: '10px 12px',
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            '&:focus': {
                borderColor: theme.palette.primary.main,
            },
            '&.MuiSelect-select:focus': {
                borderRadius: 0,
                backgroundColor: '#FFFFFF',
            },
        },
        label: {
            fontSize: 18,
            color: 'black',
        },
        menuList: {
            '& .MuiPaper-rounded': {
                borderRadius: 'unset',
            },
            '& .MuiMenu-list': {
                border: '1px solid #B6B6B6',
                borderRadius: 'unset',
                maxHeight: 330,
                overflowY: 'scroll',
            },
            '& .MuiList-padding': {
                padding: 0,
            },
        },
    }),
)

export const SelectTextField = (props: any) => {
    const classes = useSelectStyles()
    return (
        <TextField
            {...props}
            select
            InputProps={{
                classes: {
                    root: classes.root,
                    input: classes.input,
                },
                disableUnderline: true,
            }}
            InputLabelProps={{
                classes: {
                    root: classes.label,
                },
                shrink: true,
            }}
            SelectProps={{
                MenuProps: {
                    className: classes.menuList,
                    getContentAnchorEl: null,
                },
            }}
        />
    )
}

const useRadioStyles = makeStyles({
    root: {
        padding: '9px 9px 9px 0',
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: '50%',
        width: 20,
        height: 20,
        border: '1px solid black',
        position: 'relative',
        'input:disabled ~ &': {
            opacity: 0.5,
        },
    },
    checkedIcon: {
        '&:before': {
            borderRadius: '50%',
            width: 14,
            height: 14,
            backgroundColor: COLOR.BLUE,
            content: '""',
            position: 'absolute',
            top: 2,
            right: 2,
        },
    },
})

export const BlueRadio = (props: RadioProps) => {
    const classes = useRadioStyles()

    return (
        <Radio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            {...props}
        />
    )
}
