import React from 'react'
import { createStyles, makeStyles, Menu, MenuItem, Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        btnPopper: {
            height: 30,
            width: 30,
            borderRadius: '50%',
            border: '1px solid gray',
            '&:hover': {
                cursor: 'pointer',
            },
        },
    }),
)

interface MenuActionProps {
    menuList: {
        label: string
        disable?: boolean
        onClick: () => void
    }[]
    canOpen?: boolean
}

export default function MenuAction({ menuList, canOpen = true }: MenuActionProps) {
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

    const handlePopperClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget)
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
    }

    return (
        <>
            <button type="button" className={classes.btnPopper} onClick={handlePopperClick}>
                ...
            </button>
            <Menu
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                keepMounted
                open={Boolean(anchorEl) && canOpen}
                onClose={handleMenuClose}
                onClick={(event: any) => {
                    event.stopPropagation()
                }}
            >
                {menuList.map((menu, index) => (
                    <MenuItem
                        key={index}
                        onClick={() => {
                            setAnchorEl(null)
                            menu.onClick()
                        }}
                        disabled={menu.disable}
                    >
                        {menu.label}
                    </MenuItem>
                ))}
            </Menu>
        </>
    )
}
