import React from 'react'
import clsx from 'clsx'

import * as WS from 'modules/WebSocket'
import { useSnackbar } from 'notistack'
import { Lesson, LessonStatus, LessonType } from 'Types'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Box, Grid, Slider, IconButton, Typography } from '@material-ui/core'
import { getVideoStateContext } from 'modules/VideoStateProvider'

import {
    PlayArrow as PlayIcon,
    Pause as PauseIcon,
    Forward10Outlined as ForwardIcon,
    Replay10Outlined as RewindIcon,
    FastRewind as FastRewindIcon,
    // FastForward as FastForwardIcon,
} from '@material-ui/icons'

import * as VideoController from 'modules/VideoController'
import * as LessonLogProvider from 'modules/LessonLogProvider'
// import { useAppDispatch } from 'store'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            padding: '10px 0',
            width: 960,
            margin: '0 auto',
        },
        box: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderBottom: '1px solid #fff',
            width: '100%',
        },
        header: {
            color: '#fff',
            fontWeight: 'bold',
            paddingTop: 12,
            paddingBottom: 12,
            height: 35,
            marginTop: 4,
            background: 'linear-gradient(0deg, #5a5a5a 10%, #707070 80%, #5F5F5F 100%)',
        },
        thead: {
            color: '#5A5A5A',
            marginTop: 4,
            borderTop: '1px solid #e2e2e2',
            background: 'linear-gradient(0deg, #E3E3E3 10%, #FDFDFD 60%, #FFF 80%)',
            border: '1px solid #E2E2E2',
        },
        cell: {
            display: 'table',
            border: '1px solid #E2E2E2',
            borderTop: 0,
        },
        slider: {
            width: 160,
            marginLeft: 6,
            color: theme.palette.grey[700],
        },
        video: {
            alignSelf: 'flex-start',
        },
        video_seek: {
            width: 300,
            color: '#fff',
            height: 5,
            margin: '0 2px',
            '& .MuiSlider-rail': {
                height: 4,
            },
            '& .MuiSlider-track': {
                height: 4,
            },
            '& .MuiSlider-thumb': {
                marginTop: -4,
            },
        },
        video_slider: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        video_current_time: {
            marginRight: 3,
            fontSize: 10,
            textAlign: 'right',
        },
        video_total_time: {
            marginLeft: 3,
            fontSize: 10,
            textAlign: 'left',
        },
        right: {
            backgroundColor: '80ff80',
            alignItems: 'center',
            justifyContent: 'right',
            textAlign: 'center',
        },
        video_icons: {
            color: '#fff',
            padding: '0 3px',
        },
        disabled: {
            pointerEvents: 'none',
            opacity: 0.6,
        },
    }),
)
export enum VideoControlMode {
    local, // ローカル動作
    replay, // ログリプレイ.
    remote, // リモート（相手優先）
    concurrent, // 一斉（自分優先）
}

type VideoControlPanelProps = {
    lesson?: Lesson
    from?: string
    name?: string
    client_id?: string
    onLoadedVideo?: (duration: number) => void
    lesson_status?: LessonStatus
    mode?: VideoControlMode
    onStart?: () => void
    onSetLessonStatus?: (status: LessonStatus) => void // Update global state
}

var intervalObj: number | null = null

export function onExit() {
    // タイマー処理停止.
    stopSeekTimer()
    // ビデオコントローラ終了.
    VideoController.end()
    // リプレイ終了
    LessonLogProvider.end()
}

function stopSeekTimer() {
    console.log('### STOP seek timer')
    if (intervalObj !== null) {
        clearInterval(intervalObj)
        intervalObj = null
    }
}

export default function VideoControlPanel(props: VideoControlPanelProps) {
    // console.log( "### VideoControlPanel", props.name, props.client_id)

    // const dispatch = useAppDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const classes = useStyles()

    const { Play, Pause, isPlaying } = React.useContext(getVideoStateContext())

    // シークバー.
    const [seek, setSeek] = React.useState<{ max: number; frame: number }>({ max: 9999999999, frame: 0 })
    const [slider, setSlider] = React.useState<{ dragging: boolean; value: number }>({
        dragging: false,
        value: 0,
    })

    React.useEffect(() => {
        // console.log( "######### useEffect", props)
        VideoController.start()
        const video = VideoController.getVideoPlayer()
        if (video !== null) {
            console.log('video:setup event')
            // 動画のロード完了
            video.onloadeddata = function (event: any) {
                console.log('video:loaded')
                const duration = video.duration
                console.log(`### DURATION: ${duration}`)
                if (props.onLoadedVideo !== undefined) {
                    props.onLoadedVideo(duration)
                }
                // シークバー初期化.
                setSeekBar(duration * 1000, 0)

                console.log('###  WebSocket 開始', props)

                const lessonType = props.lesson?.lesson_type === undefined ? LessonType.individual : props.lesson?.lesson_type

                console.log('レッスン種別', props.lesson?.lesson_type, lessonType)

                const lessonId = props.lesson?.lesson_id
                if (lessonId === undefined) return

                // WebSocket 開始.
                if (props.lesson?.lesson_id === undefined) return
                if (props.from === undefined) return
                if (isRemoteMode()) {
                    console.log('---- WebSocket start')
                    WS.start(lessonType, props.lesson?.lesson_id, props.from, props.name === undefined ? '名無し' : props.name, props.client_id)
                    WS.setCallback({ key: 'VideoControlPanel', onMessage: onMessage, onConnect: onConnect })
                }
            }
            // 動画終了検出.
            video.onended = function (event: any) {
                console.log('video:end')
                // WebSocket 停止.
                // 再生状態をリセット.
                VideoController.doPause()
                Pause()
            }

            LessonLogProvider.registerEndListener(onLessonLogEnd)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        return function cleanup() {
            WS.stop()
            onExit()
        }
    }, [])

    function onLessonLogEnd() {
        console.log('レッスンログ終了')
        if (LessonLogProvider.isAvailable()) {
            showNotice('これ以上ログデータがありません', 'info')
        } else {
            VideoController.doPause()
            Pause()
            showNotice('レッスンログの再生が終了しました', 'info')
        }
    }

    function setSeekBar(max: number, frame: number) {
        if (!slider.dragging) {
            setSeek({ ...seek, max: max, frame: frame })
        }
    }
    function isManualMode() {
        return !!(isLocalMode() || isReplayMode() || isConcurrentMode())
    }
    function isConcurrentMode() {
        return props.mode === VideoControlMode.concurrent
    }
    function isLocalMode() {
        return props.mode === VideoControlMode.local || props.mode === undefined
    }
    function isRemoteMode() {
        return props.mode === VideoControlMode.remote || isConcurrentMode()
    }
    function isReplayMode() {
        return props.mode === VideoControlMode.replay
    }
    function showNotice(message: string, variant: any) {
        console.log(`Notice:${message}`)
        enqueueSnackbar(message, { variant })
    }
    const onConnect = () => {
        console.log('WS: 接続完了 → 参加者取得')

        setTimeout(() => {
            WS.sendCommand('attendees', '')
        }, 1000)
    }

    const onMessage = (message: string) => {
        // console.log(`Message:${message}`)
        const json = JSON.parse(message)

        // TODO: Update lesson status by status command from user app
        if (json?.data?.from === 'vr' && props?.lesson?.lesson_type === LessonType.individual) {
            if (json.command === 'enter') {
                setLessonStatus(LessonStatus.progressing)
            }
            if (json.command === 'leave') {
                setLessonStatus(LessonStatus.finish)
            }
        }

        // WebSocket からコマンドがあるときは、マニュアル操作不可.
        // isManualMode() = false;

        if (json.command === 'message') {
            showNotice(json.data, 'info')
            // } else if (json.command === 'enter') {
            //     showNotice(`${json.data.name}さんが入室しました`, 'info')
            // } else if (json.command === 'leave') {
            //     showNotice(`${json.data.name}さんが退室しました`, 'info')
        } else if (json.command === 'attendees') {
            console.log('参加者一覧')
        } else if (json.command === 'terminate') {
            // showNotice('中断要求がありました', 'info')
            VideoController.doPause()
            Pause()
        } else if (json.command === 'play') {
            // showNotice('再生要求がありました', 'info')
            VideoController.doPlay()
            Play()
        } else if (json.command === 'pause') {
            // showNotice('一時停止要求がありました', 'info')
            VideoController.doPause()
            Pause()
        } else if (json.command === 'seek') {
            // showNotice('シーク要求がありました', 'info')
            const sec = json.data
            VideoController.doSeek(sec / 1000.0)
        } else if (json.command === 'rewind') {
            // showNotice('RWD 要求がありました', 'info')
            VideoController.doSeekOffset(-10.0)
        } else if (json.command === 'forward') {
            // showNotice('FWD 要求がありました', 'info')
            VideoController.doSeekOffset(10.0)
        } else if (json.command === 'posture') {
            const frame = json.data.frame // 再生位置(単位:ミリ秒)
            const camera = json.data.camera // 視線ベクトル (オイラー角) {x:number, y:number, z:number}

            // In concurrent type, prevent user portal receive posture command from user app before staff portal start lesson
            if (props?.lesson?.lesson_type === LessonType.concurrent && frame < 500) return

            // 再生が開始していないときは、開始する.
            if (!VideoController.isStarted() && !VideoController.isSeeking()) {
                // console.log( `######## seek to ${frame/1000.0}` )
                Play()
                VideoController.doSeekAndPlay(frame / 1000.0)
                // シークタイマー開始.
                startSeekTimer()
            } else {
                if (!VideoController.isSeeking()) {
                    const diff = VideoController.getCurrentPosition() * 1000 - frame
                    if (diff > 0 || diff < -3000) {
                        // if (Math.abs(diff) > 2000) {
                        console.log(`再シーク:${Math.round(VideoController.getCurrentPosition() * 1000 - frame)}ms`)
                        VideoController.doSeek(frame / 1000.0)
                    }
                }
            }
            // シークタイマー開始.
            if (intervalObj === null) {
                startSeekTimer()
            }
            // setSeekBar(json.data.total, frame)

            VideoController.doPosture(camera)
        } else if (json.command === 'status') {
            setLessonStatus(json.data)
        }
    }
    // -----------------------------------------------------------
    // Seek bar
    // -----------------------------------------------------------
    function startSeekTimer() {
        console.log('### START seek timer')
        // 起動中なら停止.
        if (intervalObj !== null) {
            clearInterval(intervalObj)
        }
        intervalObj = setInterval(() => {
            // console.log( 'seek timer working' )
            // console.log( `seek: ${VideoController.getCurrentPosition() * 1000}`)
            setSeekBar(VideoController.getDuration() * 1000, VideoController.getCurrentPosition() * 1000)
        }, 1000 / 30)
    }

    function getTimeText(value: number) {
        if (value === 9999999999) return '--:--'
        const seconds = Math.round(value / 1000)
        const minute = Math.floor(seconds / 60)
        const second = seconds - minute * 60
        return '' + minute + ':' + ('00' + second).slice(-2)
    }

    // -----------------------------------------------------------
    //  UI operation
    // -----------------------------------------------------------
    const handlePlay = (event: any) => {
        if (isPlaying) {
            if (canSendWSCommand()) {
                WS.sendCommand('pause', '')
            }
            if (isManualMode()) {
                VideoController.doPause()
                Pause()
                stopSeekTimer()
                if (isReplayMode()) {
                    LessonLogProvider.stop()
                }
            }
        } else {
            if (props.onStart !== undefined) {
                props.onStart()
            }
            if (isReplayMode() && !LessonLogProvider.isAvailable()) {
                if (LessonLogProvider.hasData()) {
                    showNotice('レッスンログは終了しています', 'info')
                } else {
                    showNotice('再生できません', 'info')
                }
                return
            }
            if (canSendWSCommand()) {
                WS.sendCommand('play', '')
            }
            if (isManualMode()) {
                VideoController.doPlay()
                Play()
                if (isReplayMode()) {
                    LessonLogProvider.start()
                }
                startSeekTimer()
            }
        }
    }

    const handleFastRewind = (event: any) => {
        if (canSendWSCommand()) {
            WS.sendCommand('seek', 0)
        }
        if (isManualMode()) {
            VideoController.doSeek(0)
        }

        LessonLogProvider.rewind()
    }
    const handleRewind = (sec: number) => (event: any) => {
        if (canSendWSCommand()) {
            WS.sendCommand('rewind', 0)
        }
        if (isManualMode()) {
            VideoController.doSeekOffset(-10.0)
        }
    }
    const handleForward = (sec: number) => (event: any) => {
        if (canSendWSCommand()) {
            WS.sendCommand('forward', 0)
        }
        if (isManualMode()) {
            VideoController.doSeekOffset(10.0)
        }
    }
    // const handleFastForward = (event: any) => {
    //     WS.sendCommand('seek', -1)
    //     if (isManualMode()) {
    //         VideoController.doSeek(-1)
    //     }
    // }

    // seek-bar control
    // change slider position.
    const handleChangeSlider = (event: any, newValue: number | number[]) => {
        const value = newValue as number
        console.log(`seek:${value}`)
        setSeek({ ...seek, frame: value })

        if (canSendWSCommand()) {
            WS.sendCommand('seek', value)
        }
        if (isManualMode()) {
            const video = VideoController.getVideoPlayer()
            video.currentTime = value / 1000
        }
    }
    // begin drag
    const handleMouseDown = (event: any) => {
        // console.log('###Mouse Down')
        setSlider({ ...slider, dragging: true })
    }
    // end drag
    const handleMouseUp = (event: any) => {
        // console.log(`###Mouse Up:${seek.frame}`)
        setSlider({ ...slider, dragging: false })

        // mouse up, so send seek position by websocket
        // WS.sendCommand('seek', seek.frame)
        // if (isManualMode()) {
        //     const video = VideoController.getVideoPlayer()
        //     video.currentTime = seek.frame / 1000
        // }
    }

    /**
     * Individual type, user and staff can send command after started by user app
     * Concurrent type, staff can send command (video control's user is disabled)
     */
    const canSendWSCommand = () => {
        return (props?.mode === VideoControlMode.remote && VideoController.isStarted()) || isConcurrentMode()
    }

    /**
     * Update lesson status in global state (redux)
     * @param status
     */
    const setLessonStatus = (status: LessonStatus) => {
        if (props?.onSetLessonStatus && props?.lesson?.status !== status) {
            props.onSetLessonStatus(status)
        }
    }

    return (
        <Grid container>
            <Box
                className={clsx(
                    classes.box,
                    classes.header,
                    props.from === 'user' && props.lesson?.lesson_type === LessonType.concurrent && !isReplayMode() && classes.disabled,
                )}
            >
                <Box>
                    <IconButton aria-label="play" className={classes.video_icons} onClick={handlePlay}>
                        {isPlaying ? <PauseIcon fontSize="small" /> : <PlayIcon fontSize="small" />}
                    </IconButton>
                </Box>
                <Box>
                    <IconButton aria-label="fast rewind" className={classes.video_icons} onClick={handleFastRewind}>
                        <FastRewindIcon fontSize="small" />
                    </IconButton>
                </Box>
                <Box>
                    <IconButton aria-label="rewind" className={classes.video_icons} onClick={handleRewind(10)}>
                        <RewindIcon fontSize="small" />
                    </IconButton>
                </Box>
                <Box>
                    <div className={classes.video_slider}>
                        <Typography className={classes.video_current_time}>{getTimeText(seek.frame)}</Typography>
                        <IconButton aria-label="slider" className={clsx(classes.video_icons, classes.video_seek)}>
                            <Slider
                                min={0}
                                max={seek.max}
                                value={seek.frame}
                                className={classes.video_seek}
                                onMouseDown={handleMouseDown}
                                onMouseUp={handleMouseUp}
                                onChange={handleChangeSlider}
                            />
                        </IconButton>
                        <Typography className={classes.video_total_time}>{getTimeText(seek.max)}</Typography>
                    </div>
                </Box>
                <Box>
                    <IconButton aria-label="forward" className={classes.video_icons} onClick={handleForward(10)}>
                        <ForwardIcon fontSize="small" />
                    </IconButton>
                </Box>
                {/* <Box>
                    <IconButton aria-label="fast forward" className={classes.video_icons} onClick={handleFastForward}>
                        <FastForwardIcon fontSize="small" />
                    </IconButton>
                </Box> */}
            </Box>
        </Grid>
    )
}
