// import * as Const from '../Const'
// import { trace } from '../common/index'
import React from 'react'

// Material-UI
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Typography, Box, Paper, Grid, Card } from '@material-ui/core'

// Functional
import * as DateUtil from 'date-fns'

// User
import { User } from '../Types'
import * as DataUtil from '../modules/DataUtil'
import AvatarLabel from './AvatarLabel'
import { RoundedButton } from '../components/Buttons'
import FavoriteStaff from './FavoriteStaff'
import { isUndefined } from 'util'
import { getImageCacheContext } from 'modules/ImageCacheProvider'

// スタイル定義
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: '10px 0',
            // height: '100px',
            // overflow: 'hidden',

            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'transparent',
            '&:hover': {
                cursor: 'pointer',
            },
        },
        user_element: {
            // float: 'left',
        },
        circle: {
            width: 20,
            height: 20,
            borderRadius: '50%',
            backgroundColor: theme.palette.primary.main,
        },
        circle_offline: {
            backgroundColor: theme.palette.secondary.main,
        },

        // バッジ.
        badge: {
            color: theme.palette.warning.light,
            transform: `translate(0px,0px) scale(1.2)`,
        },
        container: {
            justifyContent: 'center',
            textAlign: 'center',
            alignItems: 'center',
            // height: "100%",
            // backgroundColor: "#f0f",
        },
        avatar: {
            margin: '0px',
            width: 60,
            height: 60,
            borderRadius: 0,
        },
        smallAvatar: {
            margin: '0px',
            width: 20,
            height: 20,
            fontSize: '12px',
        },
        avatar_name: {
            marginLeft: '10px',
        },
        time: {
            borderColor: theme.palette.common.black,
            textAlign: 'center',
            padding: '20px,220px',
        },
        chip: {
            width: '100%',
            // width: "80px",
            fontWeight: 'bold',
            color: theme.palette.common.black,
            // backgroundColor: "#ff0000",
        },
        offline: {
            backgroundColor: theme.palette.secondary.main,
        },
        active: {
            backgroundColor: theme.palette.primary.main,
        },

        detail: {
            display: 'flex',
            alignItems: 'center',
        },
        detail_item: {
            float: 'left',
            marginLeft: 12,
        },
        mspa_btn: {
            background: 'linear-gradient(0deg, #EAEAEA 10%, #FDFDFD 60%, #FFF 80%)',
            boxShadow: '0px 1px 1px #cacaca',
        },
        label: {
            borderRadius: 20,
            padding: '3px 0',
        },
    }),
)
type LocalState = {
    user_name: string // ユーザ名.
    course_name: string // 受講中コース.
    last_lesson_date: string // 最終受講日.
    favorite: boolean // お気に入り
    icon?: string // アイコンURL
    user_ruby: string // hiragana名.
}

type StaffUserListCellProps = {
    item?: User
    favoriteUsers?: string[]
    // セルクリックのハンドラ.
    onClickCellHandler?: (item: User) => void
    // MSPA評価ボタンのハンドラ
    onClickEvaluationHandler?: (item: User) => void
}

export default function StaffUserListCell(props: StaffUserListCellProps) {
    const imageCache = React.useContext(getImageCacheContext())
    // CSSを適用する。
    const classes = useStyles()

    const item = isUndefined(props.item) ? DataUtil.initUser() : props.item

    function getUserName(user: User): string {
        return user && user.user_name ? user.user_name : '----'
    }
    function getLastLesson(user: User): string {
        return user && user.last_course_date ? DateUtil.format(DateUtil.parse(user.last_course_date, 'yyyy-MM-dd HH:mm:ss', new Date()), 'yyyy/MM/dd') : 'なし'
    }
    function getCourseName(user: User): string {
        return user && user.course && user.course.course_name ? user.course.course_name : '----'
    }
    function getUserRuby(user: User): string {
        return user && user.user_ruby ? user.user_ruby : '----'
    }
    function getIconUrl(user: User): string {
        return imageCache.getUserImage(user)
    }

    const [state, setState] = React.useState<LocalState>({
        user_name: getUserName(item),
        course_name: getCourseName(item),
        last_lesson_date: getLastLesson(item), // 最終受講日.
        favorite: false, // お気に入り
        icon: getIconUrl(item), // アイコンURL
        user_ruby: getUserRuby(item), // hiragana名
    })

    React.useEffect(() => {
        if (!isUndefined(props.favoriteUsers) && props.favoriteUsers.includes(item.user_id || '')) {
            setState({
                ...state,
                favorite: true,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.favoriteUsers])

    const handleClickCell = () => {
        console.log('Cell Clicked')
        if (!isUndefined(props.onClickCellHandler)) {
            props.onClickCellHandler(item)
        }
    }
    const handleClickEvaluation = (event: React.BaseSyntheticEvent, item: User) => {
        console.log('MSPA CLicked')
        event.preventDefault()
        event.stopPropagation() // イベントバブリングの抑止

        if (!isUndefined(props.onClickEvaluationHandler)) {
            props.onClickEvaluationHandler(item)
        }
    }
    return (
        <Paper elevation={3} className={classes.root} onClick={handleClickCell}>
            <Grid container className={classes.container} spacing={1}>
                <Grid item xs={1}>
                    <FavoriteStaff favorite={state.favorite} disableFavorite icon={state.icon} avatar_class={classes.avatar} />
                </Grid>
                <Grid item xs={9}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Box className={classes.detail}>
                                <Grid item xs={2}>
                                    <Card className={classes.label}>氏名</Card>
                                </Grid>
                                <Grid item xs={10}>
                                    <AvatarLabel fontBold title={state.user_name} higaranaName={'　（' + state.user_ruby + '）'} type="no_photo" />
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box className={classes.detail}>
                                <Grid item xs={2}>
                                    <Card className={classes.label}>最新受講日</Card>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography variant="subtitle1" className={classes.detail_item}>
                                        {state.last_lesson_date}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Card className={classes.label}>受講中</Card>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="subtitle1" className={classes.detail_item}>
                                        {state.course_name}
                                    </Typography>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={2}>
                    <RoundedButton className={classes.mspa_btn} fullWidth onClick={(event) => handleClickEvaluation(event, item)}>
                        MSPA評価
                    </RoundedButton>
                </Grid>
            </Grid>
        </Paper>
    )
}
