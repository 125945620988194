// 職員モジュール
import React from 'react'
import { useSelector } from 'react-redux'
import {
    createSlice,
    // PayloadAction,
    createAsyncThunk,
} from '@reduxjs/toolkit'
import { RootState } from '../rootReducer'
import * as ApiClient from './ApiClient'
import {
    Staff,
    // User,
    OriginalField,
    MspaEvaluation,
} from '../Types'
import { useEffect, useState } from 'react'
import { useAppDispatch } from 'store'
import { getImageCacheContext } from './ImageCacheProvider'

// ステート定義
type StaffState = {
    staff: Staff[]
}

// ステート初期値.
const initialState: StaffState = {
    staff: [],
}
// React Thunk

// お気に入り取得.
export const fetchFavoriteUsersByStaffId = createAsyncThunk('staff/fetchFavoriteUsers', async (staff_id: string, thunkAPI) => {
    const url = `/staff/${staff_id}/favorites`
    const response = await ApiClient.get(url)
    return response.data
})
// お気に入り追加.
export const addFavoriteUser = createAsyncThunk('staff/addFavorite', async (arg: { staff_id: string; user_id: string }, thunkAPI) => {
    const url = `/staff/${arg.staff_id}/favorites`
    const param = {
        includes: [arg.user_id],
    }
    console.log(url, param)
    const response = await ApiClient.patch(url, param)
    console.log(response)
    return response.data
})
// お気に入り削除.
export const removeFavoriteUser = createAsyncThunk('staff/removeFavorite', async (arg: { staff_id: string; user_id: string }, thunkAPI) => {
    const url = `/staff/${arg.staff_id}/favorites`
    const param = {
        excludes: [arg.user_id],
    }
    const response = await ApiClient.patch(url, param)
    return response.data
})

export const importStaffs = createAsyncThunk('user/importStaffs', async (arg: { data: any[] }, thunkAPI) => {
    const response = await ApiClient.post('staff/import', { data: arg.data })
    return response
})

// 施設の職員を取得.
export const fetchStaffByFacilityId = createAsyncThunk('staff/fetchStaffByFacilityId', async (facility_id: string, thunkAPI) => {
    const response = await ApiClient.get('/facilities/' + facility_id + '/staff/')
    return response.data
})
// 指定IDの職員を取得.
export const fetchStaffById = createAsyncThunk('staff/fetchById', async (id: string, thunkAPI) => {
    const response = await ApiClient.get('/staff/' + id)
    return response.data
})
// 全ての職員.
export const fetchStaff = createAsyncThunk('staff/fetchAll', async (arg, thunkAPI) => {
    const response = await ApiClient.get('/staff')
    return response.data
})

// 全ての職員.
export const fetchAllStaff = createAsyncThunk('staff/fetchAllStaff', async (arg, thunkAPI) => {
    const response = await ApiClient.get('/staff')
    return response.data
})
/**
 * 作成
 * method: POST
 * path: /staff
 */
export const createStaff = createAsyncThunk('staff/create', async (staff: Staff, thunkAPI) => {
    const response = await ApiClient.post('/staff', staff)
    return { ...response, item: staff }
})

// 更新.
// method: PUT
// path: /staff/:id
// @note id の更新にも対応。
// @params  { original_id, staff }
//  original_id: オリジナルのID.（検索用）
//  staff: 更新する内容
export const updateStaffById = createAsyncThunk('staff/updateById', async (item: Staff & OriginalField, thunkAPI) => {
    const staff = excludeOriginalField(item)
    const response = await ApiClient.put('/staff/' + item.original_id, staff)
    return { ...response, item: staff }
})
function excludeOriginalField(item: Staff & OriginalField): Staff {
    console.log('exclude前: ', item)
    let state: Staff = {}
    for (const [key, value] of Object.entries(item)) {
        if (key !== 'original_id') {
            state = { ...state, [key]: value }
        }
    }
    console.log('exclude結果: ', state)
    return state
}

// 削除
// @path: /staff/:id
export const deleteStaff = createAsyncThunk('staff/delete', async (id: string, thunkAPI) => {
    const response = await ApiClient.delete_('/staff/' + id)
    return { ...response, id: id }
})
// MSPA更新
export const updateUserMspaEvaluation = createAsyncThunk(
    'staff/updateUserMspaEvaluation',
    async (arg: { user_id: string; mspaEvaluation: MspaEvaluation }, thunkAPI) => {
        const url = `/users/${arg.user_id}/mspa`
        console.log('URL', url)
        console.log('MSPA', arg.mspaEvaluation)
        const response = await ApiClient.patch(url, arg.mspaEvaluation)
        console.log('RESPONSE', response)
        return response
    },
)

// Slice を作成.
const StaffModule = createSlice({
    name: 'staff',
    initialState,
    reducers: {
        // コンテンツページ変更.
        // changeContentPage(state: StaffState, action: PayloadAction<string>) {
        //     state.contentPage = action.payload
        // },
        // // 設定ページ変更.
        // changeSettingPage(state: StaffState, action: PayloadAction<string>) {
        //     state.settingPage = action.payload
        // },
        // // 選択中アイテム.
        // setSelectedStaff(state: StaffState, action: PayloadAction<User | undefined>) {
        //     state.selectedItem = action.payload
        // },
    },
    extraReducers: (builder) => {
        // fetch
        builder.addCase(fetchStaff.fulfilled, (state, action) => {
            // console.log( 'fulfilled' );
            return { ...state, staff: action.payload }
        })
        builder.addCase(fetchStaff.pending, (state, action) => {
            // console.log('pending')
        })
        builder.addCase(fetchStaff.rejected, (state, action) => {
            // console.log('rejected')
        })
        // fetch by id
        builder.addCase(fetchStaffById.fulfilled, (state, action) => {
            // console.log('fulfilled')
            // return { ...state, staff: staff }
        })
        builder.addCase(fetchStaffById.pending, (state, action) => {
            // console.log('pending')
        })
        builder.addCase(fetchStaffById.rejected, (state, action) => {
            // console.log('rejected')
        })
        // fetch by facility id
        builder.addCase(fetchStaffByFacilityId.fulfilled, (state, action) => {
            // console.log('fulfilled')
            const staffs = action.payload.map((staff: Staff) => {
                return { ...staff, icon: undefined }
            })
            return { ...state, staff: staffs }
        })
        builder.addCase(fetchStaffByFacilityId.pending, (state, action) => {
            // console.log('pending')
        })
        builder.addCase(fetchStaffByFacilityId.rejected, (state, action) => {
            // console.log('rejected')
        })

        // add
        builder.addCase(createStaff.fulfilled, (state, action) => {
            console.log('fulfilled:', action.payload)
            const staff = state.staff.filter((t) => t.staff_id !== action.payload.item.staff_id)
            state.staff = [...staff, action.payload.item]
            // state.settingPage = 'list'
        })
        builder.addCase(createStaff.pending, (state, action) => {
            console.log('pending')
        })
        builder.addCase(createStaff.rejected, (state, action) => {
            console.log('rejected')
        })
        // update
        builder.addCase(updateStaffById.fulfilled, (state, action) => {
            console.log('fulfilled:', action.payload)
            // return {　...state,　staff: action.payload　}
            let staff = state.staff.filter((t) => t.staff_id !== action.payload.item.staff_id)
            state.staff = [...staff, action.payload.item]
            // state.settingPage = 'list'
        })
        builder.addCase(updateStaffById.pending, (state, action) => {
            console.log('pending')
        })
        builder.addCase(updateStaffById.rejected, (state, action) => {
            console.log('rejected')
        })
        // delete
        builder.addCase(deleteStaff.fulfilled, (state, action) => {
            state.staff = state.staff.filter((t) => t.staff_id !== action.payload.id)
            // state.settingPage = 'list'
        })
        builder.addCase(deleteStaff.pending, (state, action) => {
            console.log('pending')
        })
        builder.addCase(deleteStaff.rejected, (state, action) => {
            console.log('rejected')
        })

        builder.addCase(updateUserMspaEvaluation.fulfilled, (state, action) => {
            console.log('fulfilled:', action.payload)
            // state.contentPage = 'staff_user_list'
        })
    },
})

export const useStaffItems = () => {
    const imageCache = React.useContext(getImageCacheContext())

    const { staff } = useSelector((state: RootState) => state.staff)
    const staffs = staff.map((item: Staff) => {
        return { ...item, icon: imageCache.getStaffImage(item) }
    })
    return staffs

    // return staff.filter((item) => {
    //     // TODO:: 施設と連携とれたら整理する
    //     return true //item.facility && item.facility.facility_id === facility_id;
    // })
    // //    return useSelector((state: ReturnType<typeof StaffModule.reducer>) => state.staff);
}
export const useStaffById = (id: string) => {
    return useSelector((state: RootState) => state.staff.staff.find((item: Staff) => item?.staff_id === id))
}
// export const useContentPage = () => {
//     return useSelector((state: RootState) => state.staff.contentPage)
//     //    return useSelector((state: ReturnType<typeof StaffModule.reducer>) => state.staff);
// }
// export const useSettingPage = () => {
//     return useSelector((state: RootState) => state.staff.settingPage)
//     //    return useSelector((state: ReturnType<typeof StaffModule.reducer>) => state.staff);
// }
// export const useSelectedStaff = () => {
//     return useSelector((state: RootState) => state.staff.selectedItem)
// }

export const useIsMaxCreatedStaff = () => {
    const dispatch = useAppDispatch()
    const [isMax, setIsMax] = useState<boolean>(false)
    const {
        app: { facility },
        staff: { staff },
    } = useSelector((state: RootState) => state)
    const facilityId = facility?.facility_id
    const maxStaff = facility?.max_staffs
    const totalStaff = staff.length

    useEffect(() => {
        if (facilityId) {
            dispatch(fetchStaffByFacilityId(facilityId))
        }
    }, [dispatch, facilityId])
    useEffect(() => {
        if (maxStaff) {
            setIsMax(totalStaff >= maxStaff)
        }
    }, [maxStaff, totalStaff])

    return isMax
}

// Action Creatorsをエクスポート
// export const {
// changeContentPage, changeSettingPage, setSelectedStaff
// } = StaffModule.actions

// Slice をエクスポートする
export default StaffModule
