// 管理者モジュール
import { useSelector } from 'react-redux'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../rootReducer'

export enum ChatPage {
    HOME = 'home',
    DEVICE = 'device',
    MEETING = 'meeting',
}

type ChatState = {
    page: ChatPage
    chatId: string
}

// ステート初期値.
const initialState: ChatState = {
    page: ChatPage.HOME,
    chatId: '',
}

// Slice を作成.
const ChatModule = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        startChat(state: ChatState, action: PayloadAction<string>) {
            console.log( "### Start Chat" );
            state.chatId = action.payload
            state.page = ChatPage.MEETING
        },
        endChat(state: ChatState, action: PayloadAction<any>) {
            console.log( "### END Chat" );
            state.page = ChatPage.HOME
        },
    },
    extraReducers: (builder) => {},
})

export const useChatState = () => {
    return useSelector((state: RootState) => state.chat)
}
export const useChatPage = () => {
    return useSelector((state: RootState) => state.chat.page)
}
export const useChatId = () => {
    return useSelector((state: RootState) => state.chat.chatId)
}

export const { startChat, endChat } = ChatModule.actions

export default ChatModule
