// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React, { useEffect } from 'react'
// import { useHistory } from 'react-router-dom'
import { useMeetingManager, useNotificationDispatch, Severity, ActionType } from 'amazon-chime-sdk-component-library-react'
import { useAppDispatch } from 'store'
import { endChat } from 'modules/ChatModule'

// import routes from '../constants/routes'

const NoMeetingRedirect: React.FC = ({ children }) => {
    // const history = useHistory()
    const appDispatch = useAppDispatch()
    const dispatch = useNotificationDispatch()
    const meetingManager = useMeetingManager()

    const payload: any = {
        severity: Severity.INFO,
        message: 'No meeting found, please enter a valid meeting Id',
        autoClose: true,
    }

    useEffect(() => {
        if (!meetingManager.meetingSession) {
            dispatch({
                type: ActionType.ADD,
                payload: payload,
            })
            // history.push(routes.HOME);
            appDispatch(endChat(null))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <>{children}</>
}

export default NoMeetingRedirect
