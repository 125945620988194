// import * as Const from '../Const'
// import { trace } from '../common/index'
import clsx from 'clsx'
import React from 'react'

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Typography, Grid, Paper } from '@material-ui/core'

import { Information } from '../Types'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        grow: {
            flexGrow: 1,
        },
        layout: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
        cell: {
            height: '100%',
            minHeight: 'calc(80px)',
            textAlign: 'center',
            borderRadius: 0,
            background: '#F0F0F0',
            '&:hover': {
                cursor: 'pointer',
            },
        },
        container: {
            height: '100%',
            alignItems: 'center',
        },
        column: {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: '1px solid #fff',
            // overflow: 'auto',
            // backgroundColor: '#ff0000',
        },
        header: {
            height: 'calc(40px)',
            color: '#ffffff',
            borderRadius: 0,
            background: 'transparent linear-gradient(0deg, #5A5A5A 0%, #5F5F5F 7%, #707070 34%, #7A7A7A 64%, #7E7E7E 100%)',
        },
        label: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            textAlign: 'left',
        },
        id: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            textAlign: 'left',
            whiteSpace: 'pre-line',
        },
        date: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            textAlign: 'center',
            whiteSpace: 'pre-line',
        },
        title: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            textAlign: 'left',
            whiteSpace: 'pre-line',
            height: '100%',
            padding: 10,
        },
        body: {
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            textAlign: 'left',
            whiteSpace: 'pre-line',
            height: '100%',
            paddingTop: 10,
            paddingBottom: 20,
            paddingLeft: 10,
            paddingRight: 10,
        },
        fontSize12: {
            fontSize: 12,
        },
        fontSize15: {
            fontSize: 15,
        },
        ellipsis: {
            display: 'block',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            marginBottom: 2,
            '&:last-child': {
                marginBottom: 0,
            },
        },
    }),
)

/**
 * 動画時間の整形.
 *
 * @param {number} value
 * @returns
 */
// function formatDuration(value: number) {
//     const hour = Math.floor(value / 60)
//     const minute = value % 60
//     return (hour > 0 ? hour.toString() + '時間' : '') + minute.toString() + '分'
// }

/**
 * Props
 *
 * @interface InformationListCellProps
 */
type InformationListCellProps = {
    data: Information
    onClickHandler?: (event: React.MouseEvent<unknown, MouseEvent>) => void
}

/**
 * お知らせセル.
 * @export
 * @param {InformationListCellProps} props
 * @returns セルのJSX 要素
 */
export function InformationListCell(props: InformationListCellProps) {
    const classes = useStyles()
    const handleClick = (event: React.MouseEvent<unknown, MouseEvent>) => {
        if (props.onClickHandler !== undefined) {
            props.onClickHandler(event)
        }
    }
    return (
        <Paper className={clsx(classes.layout, classes.cell)} onClick={(event) => handleClick(event)}>
            <Grid container spacing={1} className={classes.container}>
                <Grid item xs={1} className={classes.column}>
                    <Typography className={clsx(classes.id, classes.fontSize15)} variant="h4">
                        {props.data.id}
                    </Typography>
                </Grid>
                <Grid item xs={3} className={classes.column}>
                    <Typography className={clsx(classes.date, classes.fontSize15)} variant="h4">
                        <div>{props.data.release_date}</div>
                        <div>〜</div>
                        <div>{props.data.expire_date}</div>
                    </Typography>
                </Grid>
                <Grid item xs={3} className={classes.column}>
                    <Typography className={clsx(classes.title, classes.fontSize15)} variant="h5">
                        {props.data.title}
                    </Typography>
                </Grid>
                <Grid item xs={5} className={classes.column}>
                    <Typography className={clsx(classes.body, classes.fontSize15)} variant="h5">
                        {props.data.content.split('\n').map(
                            (item, index) =>
                                item !== '' && (
                                    <span className={classes.ellipsis} key={index}>
                                        {item}
                                    </span>
                                ),
                        )}
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    )
}

/**
 * 施設セルヘッダ
 *
 * @export
 * @param {UserCellProps} props
 * @returns
 */
export function InformationListHeaderCell() {
    const classes = useStyles()
    return (
        <Paper className={clsx(classes.layout, classes.header)}>
            <Grid container spacing={1} className={classes.container}>
                <Grid item xs={1} className={classes.column}>
                    <Typography className={clsx(classes.id, classes.fontSize12)} variant="subtitle1">
                        ID
                    </Typography>
                </Grid>
                <Grid item xs={3} className={classes.column}>
                    <Typography className={clsx(classes.date, classes.fontSize12)} variant="subtitle1">
                        掲出期間
                    </Typography>
                </Grid>
                <Grid item xs={3} className={classes.column}>
                    <Typography className={clsx(classes.label, classes.fontSize12)} variant="subtitle1">
                        タイトル
                    </Typography>
                </Grid>
                <Grid item xs={5} className={classes.column}>
                    <Typography className={clsx(classes.label, classes.fontSize12)} variant="subtitle1">
                        本文
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    )
}
