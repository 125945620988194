import * as THREE from 'three'

let _isStarted = false
let _isSeeking = false
let _isPlaying = false

export function start() {
    _isStarted = false
    _isSeeking = false
    _isPlaying = false

    const video = getVideoPlayer()
    if (video) {
        video.addEventListener('seeking', onSeeking)
        video.addEventListener('seeked', onSeeked)
        video.addEventListener('play', onPlay)
        video.addEventListener('pause', onPause)
    }
}
export function end() {
    const video = getVideoPlayer()
    if (video) {
        video.removeEventListener('seeking', onSeeking)
        video.removeEventListener('seeked', onSeeked)
        video.removeEventListener('play', onPlay)
        video.removeEventListener('pause', onPause)
    }
}
// Event Listeners.
function onPlay(event: any) {
    console.log('video:play')
    _isPlaying = true
}
function onPause(event: any) {
    console.log('video:pause')
    _isPlaying = false
}
function onSeeking(event: any) {
    console.log('video:seeking')
    _isSeeking = true
}
function onSeeked(event: any) {
    console.log('video:seeked')
    _isSeeking = false
}

// DOM
export const getVideoPlayer = () => {
    return getElement('video')
}
const getElement = (id: string) => {
    const iframe = document.getElementById('iframe') as any
    if (iframe !== null && iframe.contentWindow !== null) {
        // iframe での実装.
        const el = iframe.contentWindow.document.getElementById(id)
        return el
    } else {
        // 組み込みの場合.
        const el = document.getElementById(id) as any
        return el
    }
}

//----------------------------------------------------------------
// 開始しているか.
export function isStarted() {
    return _isStarted
}
// 再生中か.
export function isPlaying() {
    return _isPlaying
}
// シーク中か
export function isSeeking() {
    return _isSeeking
}
// 現在の再生位置を取得.
export function getCurrentPosition() {
    const video = getVideoPlayer()
    if (video !== null) {
        return video.currentTime
    }
    return 0
}
export function getDuration() {
    const video = getVideoPlayer()
    if (video !== null) {
        return video.duration
    }
    return 1000
}
// 再生.
export function doPlay() {
    const video = getVideoPlayer()
    if (video !== null) {
        video.play()
        // video.muted = false
    }
    _isStarted = true
}
// 一時停止.
export function doPause() {
    const video = getVideoPlayer()
    if (video !== null) {
        video.pause()
    }
}
/**
 * シーク
 *
 * @export
 * @param {number} position 再生開始位置(単位:秒)
 */
export function doSeek(position: number) {
    const video = getVideoPlayer()
    if (video !== null) {
        if (position < 0) {
            position = video.duration - 0.2 // 最終フレームの表示を行う為、全長の 0.2 秒前から再生
        }
        video.currentTime = position
    }
}
/**
 * 相対シーク
 *
 * @export
 * @param {number} offset   オフセット (単位:秒)
 */
export function doSeekOffset(offset: number) {
    const video = getVideoPlayer()
    if (video !== null) {
        video.currentTime += offset
    }
}

/**
 * シーク&再生
 *
 * @export
 * @param {number} position 再生開始位置(単位:秒)
 */
export function doSeekAndPlay(position: number) {
    const video = getVideoPlayer()
    if (video !== null) {
        if (!_isSeeking) {
            console.log('シーク＆再生')
            video.currentTime = position
            _isSeeking = true

            // 完了時にリスナーを削除するため、イベントプロパティは利用しない.
            video.addEventListener('seeked', onSeekedAndPlay)
        }
    }
}

function onSeekedAndPlay(event: any) {
    console.log('動画が探していた再生位置を見つけました。')
    const video = getVideoPlayer()
    if (video !== null) {
        video.play()
    }
    _isStarted = true
    _isSeeking = false
    // リスナーを削除.
    video.removeEventListener('seeked', onSeekedAndPlay)
}

export function doPosture(camera: any) {
    var euler = convertRotation2(camera)
    const sphere = getElement('videosphere')
    if (sphere !== null) {
        // euler.pitch = json.data.camera.x
        // euler.yaw = json.data.camera.y - 180;
        // euler.roll = json.data.camera.z
        euler.x = 0
        euler.z = 0
        euler.y = euler.y - 180
        sphere.setAttribute('rotation', `${euler.x} ${euler.y} ${euler.z}`)
    }
}

function deg2rad(deg: number): number {
    return (deg / 180) * Math.PI
}
// function rad2deg(rad: number): number {
//     return (rad * 180) / Math.PI
// }
const convertRotation2 = (v: any) => {
    const euler: THREE.Euler = new THREE.Euler()
    euler.x = Math.sin(deg2rad(v.y)) * v.x
    euler.y = v.y
    euler.z = Math.cos(deg2rad(v.y)) * v.z
    return euler
}
// var m00: number
// var m01: number
// var m02: number
// var m03: number
// var m10: number
// var m11: number
// var m12: number
// var m13: number
// var m20: number
// var m21: number
// var m22: number
// var m23: number
// var m30: number
// var m31: number
// var m32: number
// var m33: number
// const convertRotation = (vector: any) => {
//     // vector.x = 0;
//     // vector.y = 45;
//     // vector.z = 0;
//     var rx = deg2rad(vector.x)
//     var ry = deg2rad(vector.y - 180)
//     var rz = deg2rad(vector.z)
//     var m = new THREE.Matrix4()
//     m.identity()
//     m.multiply(new THREE.Matrix4().makeRotationX(rz))
//     m.multiply(new THREE.Matrix4().makeRotationZ(rx))
//     m.multiply(new THREE.Matrix4().makeRotationY(ry))

//     m00 = m.elements[0]
//     m01 = m.elements[4]
//     m02 = m.elements[8]
//     m03 = m.elements[12]
//     m10 = m.elements[0 + 1]
//     m11 = m.elements[4 + 1]
//     m12 = m.elements[8 + 1]
//     m13 = m.elements[12 + 1]
//     m20 = m.elements[0 + 2]
//     m21 = m.elements[4 + 2]
//     m22 = m.elements[8 + 2]
//     m23 = m.elements[12 + 2]
//     m30 = m.elements[0 + 3]
//     m31 = m.elements[4 + 3]
//     m32 = m.elements[8 + 3]
//     m33 = m.elements[12 + 3]

//     console.log('Matrix:', m)

//     var ret = getRotationXYZ()

//     function getRotationXYZ() {
//         var z = Math.atan2(m01, m00)
//         var y = Math.asin(-m02)
//         var x = Math.asin(m12 / Math.cos(y))
//         if (m22 < 0) {
//             z = Math.PI / 2 - z
//         }
//         return { x: x, y: y, z: z }
//     }
//     console.log('deg', vector.x, vector.y, vector.z)
//     console.log('rad', rx, ry, rz)
//     console.log('ret:', ret.x, ret.y, ret.z)
//     console.log('ret:', rad2deg(ret.x), rad2deg(ret.y), rad2deg(ret.z))

//     const yaw = rad2deg(ret.y)
//     const pitch = 0 //-vector.x
//     const roll = 0 //-vector.z
//     return { pitch: pitch, yaw: yaw, roll: roll }
// }
