// 管理者モジュール
import { useSelector } from 'react-redux'
import {
    createSlice,
    // PayloadAction,
    createAsyncThunk,
} from '@reduxjs/toolkit'
import { RootState } from '../rootReducer'
import * as ApiClient from './ApiClient'
import { Course } from '../Types'

export type CourseParam = {
    course_id?: string
    course_name: string
    description: string
    min_level: number
    max_level: number
    facility_id: string
    training_ids: string[]
    test_training_ids: string[]
}

// 非同期アクション.
export const fetchCourses = createAsyncThunk('course/fetchAll', async (arg, thunkAPI) => {
    const url = `/courses`
    const response = await ApiClient.get(url)
    return response.data
})
// export const fetchCourseById = createAsyncThunk('course/fetchById', async (id: string, thunkAPI) => {
//     const response = await ApiClient.get('/courses/' + id)
//     return response.data
// })
export const fetchCoursesByFacilityId = createAsyncThunk('course/fetchByFacilityId', async (facility_id: string, thunkAPI) => {
    const url = `/facilities/${facility_id}/courses`
    const response = await ApiClient.get(url)
    return response.data
})
export const fetchCoursesByFacilityIdWithUserId = createAsyncThunk(
    'course/fetchByFacilityIdWithUserId',
    async (arg: { facility_id: string; user_id: string }, thunkAPI) => {
        const url = `/facilities/${arg.facility_id}/users/${arg.user_id}/courses`
        const response = await ApiClient.get(url)
        return response.data
    },
)
export const createCourse = createAsyncThunk('course/create', async (param: CourseParam) => {
    const url: string = `/courses`
    console.log('createCourse', url, param)
    const response = await ApiClient.post(url, param)
    return response
})

export const updateCourse = createAsyncThunk('course/update', async (arg: { course_id: string; param: CourseParam }) => {
    const url: string = `/courses/${arg.course_id}`
    console.log('updateCourse', url, arg.param)
    const response = await ApiClient.put(url, arg.param)
    return response
})
export const deleteCourse = createAsyncThunk('course/delete', async (course_id: string) => {
    const url: string = `/courses/${course_id}`
    console.log('deleteCourse', url)
    const response = await ApiClient.delete_(url)
    return response
})

// ステート定義
type CourseState = {
    // page: string
    // selectedItem?: Course
    courses: Course[]
}

// ステート初期値.
const initialState: CourseState = {
    // page: 'list',
    // selectedItem: undefined,
    courses: [],
}

// Slice を作成.
const CourseModule = createSlice({
    name: 'course',
    initialState,
    reducers: {
        // コンテンツページ変更.
        // changeCoursePage(state: CourseState, action: PayloadAction<string>) {
        //     state.page = action.payload
        // },
        // setSelectedCourse(state: CourseState, action: PayloadAction<Course | undefined>) {
        //     state.selectedItem = action.payload
        // },
        // editCourse(state: CourseState, action: PayloadAction<Course>) {
        //     state.selectedItem = action.payload
        //     state.page = 'edit'
        // },
    },
    extraReducers: (builder) => {
        // // fetch
        // builder.addCase(fetchCourses.fulfilled, (state, action) => {
        //     console.log( "fetchCourses.fulfilled" );
        //     return { ...state, courses: action.payload }
        // })
        // builder.addCase(fetchCourses.pending, (state, action) => {})
        // builder.addCase(fetchCourses.rejected, (state, action) => {})
        // fetch by id
        builder.addCase(fetchCoursesByFacilityId.fulfilled, (state, action) => {
            // console.log('fulfilled')
            return { ...state, courses: action.payload }
        })
        builder.addCase(fetchCoursesByFacilityId.pending, (state, action) => {
            // console.log('pending')
        })
        builder.addCase(fetchCoursesByFacilityId.rejected, (state, action) => {
            // console.log('rejected')
        })
    },
})

export const useCourseItems = () => {
    return useSelector((state: RootState) => state.course.courses)
}
// export const useCoursePage = () => {
//     return useSelector((state: RootState) => state.course.page)
// }
// export const useSelectedCourse = () => {
//     return useSelector((state: RootState) => state.course.selectedItem)
// }
export const useCourseById = (id: string) => {
    return useSelector((state: RootState) => state.course.courses.find((course: Course) => course?.course_id === id))
}

// 同期 Action Creatorsをエクスポート
// export const {
// changeCoursePage, setSelectedCourse, editCourse
// } = CourseModule.actions

// Slice をエクスポートする
export default CourseModule
