import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'

interface Props {
    rows: any[]
}
const useStyles = makeStyles({
    tableContainer: {
        maxHeight: '450px',
        overflow: 'scroll',
    },
    table: {
        minWidth: 650,
    },
    lineNumber: {
        minWidth: '100px',
    },
    userId: {
        minWidth: '100px',
    },
})

export function TableRowError({ rows }: Props) {
    const classes = useStyles()
    const limit: number = 10
    const shownRows: any[] = rows.slice(0, limit)

    return (
        <TableContainer component={Paper} className={classes.tableContainer}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.lineNumber}>ライン</TableCell>
                        <TableCell className={classes.userId}>ユーザID</TableCell>
                        <TableCell align="left">エラー</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {shownRows.map((row, index) => (
                        <TableRow key={index}>
                            <TableCell component="th" scope="row">
                                {row.line_number}
                            </TableCell>
                            <TableCell align="left">{row.user_id}</TableCell>
                            <TableCell align="left">
                                {row.errors.map((error: any, index: number) => (
                                    <div key={index}>
                                        <b>{error.label}</b>: {error.message}
                                    </div>
                                ))}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
