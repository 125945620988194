import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            padding: '10px 0',
            width: 960,
            margin: '35px auto 0 auto',
            // backgroundColor: "#00FF00",
        },
        paper_calendar: {
            padding: theme.spacing(0),
            textAlign: 'center',
            width: '100%',
        },
        paper: {
            padding: theme.spacing(0),
            textAlign: 'left',
            height: '270px',
            width: '100%',
        },
        // カレンダー
        calendar: {
            marginTop: '10px',
        },
        // お知らせ
        info: {
            height: '75%',
            margin: '10px',
            padding: '10px',
            border: '1px dotted #0000001f',
        },
        iframe: {
            height: '100%',
            width: '100%',
            border: 'none',
        },
        search: {
            height: '20%',
            padding: '20px',
        },
        searchbar: {
            marginTop: 20,
        },
        guide: {
            margin: '10px 0',
            alignItems: 'center',
            justifyContent: 'flex-end',
        },
        guide_item: {
            marginLeft: '10px',
        },
        textfield: {
            width: '100%',
        },
        left: {
            width: '250px',
            minHeight: '270px',
            flexShrink: 0,
            // backgroundColor: '#0000ff',
            marginRight: '10px',
        },
        right: {
            height: '270px',
            // width: "1000px",
            // backgroundColor: '#ff0000',
            width: '700px',
        },
        content: {
            padding: '10px 0',
        },

        // list: {
        //     maxHeight: 500,
        //     overflow: 'auto',
        // }

        today_button: {
            marginLeft: 10,
            color: '#ffffff',
            borderColor: '#ffffff',
            height: 20,
            width: 30,
        },
        notice: {
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
            height: 235,
        },
        attendance_button: {
            height: '100%',
            width: '95%',
            color: '#fff',
            background: '#6E6E6E',
            borderRadius: '20px',
        },
        quit_button: {
            height: '100%',
            width: '95%',
            color: '#fff',
            background: '#D02927',
            borderRadius: '20px',
        },
        listItem: {
            width: 960,
            margin: '0 auto',
        },
        titleBar: {
            background: '#BBE8EE url(/assets/images/search-bar.png) left center',
            margin: '15px 0 0 0',
        },
        userCellBgColor1: {
            background: '#CDEEF3',
        },
        userCellBgColor2: {
            background: '#E2E2E2',
        },
    }),
)
