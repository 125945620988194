// import * as Const from '../Const'
import { trace } from '../common/index'
// import clsx from 'clsx'
import React from 'react'
import { useHistory, Switch, Route, Redirect } from 'react-router-dom'

// redux
import { useAppDispatch } from '../store'
import { useFacility, useHomePageUrl } from '../modules/AppModule'
import { logout, useAuth } from '../modules/AuthModule'
import { useProfile } from '../modules/AppModule'

// Material-UI
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Grid, Box } from '@material-ui/core'

// User
import { User } from '../Types'
import UserCell from '../components/UserCell'
import UserTrainingList from '../components/UserTrainingList'
import CoursePanel from '../components/CoursePanel'
import TrainingContent from '../components/TrainingContent'
import UserEdit from '../components/UserEdit'
import UserLesson from '../components/UserLesson'
import { isUndefined } from 'util'
import SimpleTitleBar from './SimpleTitlebar'

// スタイルを適用する
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            // flexGrow: 1,
            margin: '35px auto 0',
            width: 960,
        },
        // current lecture status panel.
        paper: {
            padding: theme.spacing(0),
            textAlign: 'left',
            height: '270px',
            flex: 1,
        },
        list: {},
    }),
)

// User Portal Contents.

export default function UserContent() {
    const history = useHistory()
    const homePageUrl = useHomePageUrl()

    const handleClose = () => {
        history.push(homePageUrl)
    }

    return (
        <React.Fragment>
            <Switch>
                <Route exact path={homePageUrl} render={() => <UserTop />} />
                {/* <Route path={`${homePageUrl}/lessons/create`} render={() => <UserReservation />} />
                <Route exact path={`${homePageUrl}/lessons/:lessonId`} render={() => <UserReservation />} /> */}
                <Route path={`${homePageUrl}/trainings`} render={() => <TrainingContent />} />
                <Route
                    path={`${homePageUrl}/profile`}
                    render={() => (
                        <>
                            <Box marginTop="30px">
                                <SimpleTitleBar label="プロフィール編集" />
                            </Box>
                            <UserEdit onCloseHandler={handleClose} />
                        </>
                    )}
                />
                <Route path={`${homePageUrl}/lessons/:lessonId/detail`} render={() => <UserLesson onCloseHandler={handleClose} />} />
                <Route path={`${homePageUrl}/logout`} render={() => <UserLogout />} />
                <Route path="*" render={() => <Redirect to={homePageUrl} />} />
            </Switch>
        </React.Fragment>
    )
}

export function UserLogout() {
    const dispatch = useAppDispatch()

    const homePageUrl = useHomePageUrl()
    const history = useHistory()

    const auth = useAuth()
    const facility = useFacility()
    const path = isUndefined(facility) || isUndefined(facility.facility_id) ? '/user' : `/${facility.facility_id}/user`

    React.useEffect(() => {
        trace('UserLogout')
        if (!auth.authorized) {
            return
        }

        window.history.pushState(null, '', `${homePageUrl}/logout`)
        console.log('ログアウト')
        dispatch(logout())
        // dispatch(resetState())
        history.push(path)
    })
    return <div>ログアウトしています</div>
}

export function UserTop() {
    const classes = useStyles()

    const user = useProfile() as User
    const user_id = React.useMemo(() => {
        return isUndefined(user) ? undefined : user.user_id
    }, [user])
    const facility = useFacility()

    const facilityId = isUndefined(facility) || isUndefined(facility.facility_id) ? undefined : facility.facility_id

    const [defaultCourseId, setDefaultCourseId] = React.useState('')

    // Get default course id through latest lesson reservation
    const getDefaultCourseId = React.useCallback((courseId: string) => {
        setDefaultCourseId(courseId)
    }, [])

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12}>
                <UserCell />
            </Grid>
            {/* カリキュラム. */}
            <Grid item xs={12}>
                <CoursePanel user={user} facilityId={facilityId} defaultCourseId={defaultCourseId} />
            </Grid>
            {/* トレーニング（予約＆ログ） */}
            <Grid item xs={12}>
                <UserTrainingList user_id={user_id} onGetDefaultCourseId={getDefaultCourseId} />
            </Grid>
        </Grid>
    )
}
