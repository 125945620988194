// import * as Const from '../Const'
// import { trace } from '../common/index'
import React from 'react'
import { SnackbarProvider } from 'notistack'

// ルーティング.
import { BrowserRouter, Route, Switch, useRouteMatch } from 'react-router-dom'

// Redux.
import { useAppDispatch } from '../store'
import { useAuth } from 'modules/AuthModule'
import { setProfile, setFacility, changePortal, setHomePageUrl, focusChanged } from 'modules/AppModule'
import { fetchFacilityById, fetchStaffById } from 'modules/AppModule'
import { fetchUserById, fetchUsersByFacilityId } from '../modules/UserModule'
// Material-UI
import { MuiThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

// functional

// 独自に作成したコンポーネントのインポート
import AdminPortal from './AdminPortal'
import StaffContent from './StaffContent'
import UserContent from './UserContent'
import StaffBar from './StaffBar'
import UserBar from './UserBar'
import SignIn from './SignIn'
// import Sample from '../sample/Sample'
// import VideoChat from './VideoChat'

import { staffTheme } from '../Themes'
import { isUndefined } from 'util'
import DialogProvider from 'modules/DialogProvider'
import ImageCacheProvider, { getImageCacheContext } from 'modules/ImageCacheProvider'
import { Staff } from 'Types'
import { enableImageCacheTag } from 'modules/ImageModule'

/** スタイル定義 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            minWidth: 'calc(800px)',
        },
    }),
)

// 施設スタッフポータル.
function StaffPortal() {
    document.title = 'Realize 施設職員ポータル'

    const imageCache = React.useContext(getImageCacheContext())

    const dispatch = useAppDispatch()
    const { url } = useRouteMatch() // /staff | /:facilityId/staff

    // Change portal
    dispatch(changePortal('staff'))
    // Set homepage url
    dispatch(setHomePageUrl(url))

    const auth = useAuth()

    React.useEffect(() => {
        // console.log('LOGIN:', auth)
        let facility_id: string
        let staff_id: string

        if (!isUndefined(auth.auth)) {
            facility_id = isUndefined(auth.auth.facility_id) ? '' : auth.auth.facility_id
            staff_id = isUndefined(auth.auth.account) ? '' : auth.auth.account
        } else {
            facility_id = 'FID0001'
            staff_id = 'Staff0002'
        }
        // 認証済み.
        dispatch(fetchFacilityById(facility_id))
            .then((res) => {
                // console.log('***** 施設:', res)

                let facility = res.payload
                // アイコンを除去&キャッシュ登録.
                imageCache.addStaffs(facility.staff)
                const staff = facility.staff.map((staff: Staff) => {
                    return { ...staff, icon: undefined }
                })
                facility = { ...facility, staff: staff }
                dispatch(setFacility(facility))

                // setup Profile
                dispatch(fetchStaffById(staff_id)).then((res) => {
                    const items = res.payload
                    imageCache.addStaffs(items)
                    if (items && items.length > 0) {
                        const staff = { ...items[0], icon: undefined }
                        dispatch(setProfile(staff))
                    }
                })
            })
            .then(() => {
                dispatch(fetchUsersByFacilityId(facility_id)).then((res) => {
                    const users = res.payload
                    imageCache.addUsers(users)
                })
            })
            .then(() => {
                dispatch(enableImageCacheTag(true))
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, auth])

    return (
        <React.Fragment>
            <StaffBar />
            <StaffContent />
        </React.Fragment>
    )
}
// ユーザポータル.
function UserPortal() {
    document.title = 'Realize ユーザポータル'

    const imageCache = React.useContext(getImageCacheContext())

    const dispatch = useAppDispatch()
    const { url } = useRouteMatch() // /user | /:facilityId/user

    // Change portal
    dispatch(changePortal('user'))
    // Set homepage url
    dispatch(setHomePageUrl(url))

    const auth = useAuth()
    React.useEffect(() => {
        console.log('LOGIN:', auth)

        // ユーザ情報のプロフィール登録.
        let facility_id: string
        let user_id: string

        if (!isUndefined(auth.auth)) {
            facility_id = isUndefined(auth.auth.facility_id) ? '' : auth.auth.facility_id
            user_id = isUndefined(auth.auth.account) ? '' : auth.auth.account
        } else {
            facility_id = 'FID0001'
            user_id = 'User0001'
        }
        dispatch(fetchFacilityById(facility_id))
            .then((res) => {
                // console.log('***** 施設:', res)
                let facility = res.payload
                // アイコンを除去&キャッシュ登録.
                imageCache.addStaffs(facility.staff)
                const staff = facility.staff.map((staff: Staff) => {
                    return { ...staff, icon: undefined }
                })
                facility = { ...facility, staff: staff }
                dispatch(setFacility(facility))

                // setup Profile
                dispatch(fetchUserById(user_id)).then((res) => {
                    const users = res.payload
                    imageCache.addUsers(users)
                    if (users && users.length > 0) {
                        const user = { ...users[0], icon: undefined }
                        dispatch(setProfile(user))
                    }
                })
            })
            .then(() => {
                dispatch(enableImageCacheTag(true))
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, auth])

    return (
        <React.Fragment>
            <UserBar />
            <UserContent />
        </React.Fragment>
    )
}
const theme = staffTheme

function App(props: any) {
    const dispatch = useAppDispatch()

    console.log('##### APP #####')
    const classes = useStyles()

    function getWindowSize() {
        const w = window
        const d = document
        const e = d.documentElement
        const g = d.getElementsByTagName('body')[0]
        const width = w.innerWidth || e.clientWidth || g.clientWidth
        const height = w.innerHeight || e.clientHeight || g.clientHeight
        return {
            width: width,
            height: height,
        }
    }
    const [maxHeight, setMaxHeight] = React.useState<number>(0)
    const handleResize = () => {
        var size = getWindowSize()
        // console.log('your window size', size)
        setMaxHeight(size.height)
    }

    const handleFocus = () => {
        dispatch(focusChanged())
    }

    React.useLayoutEffect(() => {
        window.addEventListener('resize', handleResize)
        window.addEventListener('focus', handleFocus)
        return () => {
            window.removeEventListener('resize', handleResize)
            window.removeEventListener('focus', handleFocus)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div id="body" className={classes.root} style={{ maxHeight: maxHeight, height: maxHeight }}>
            {/* <div> */}
            <MuiThemeProvider theme={theme}>
                <CssBaseline />
                <BrowserRouter>
                    <SnackbarProvider maxSnack={3}>
                        <DialogProvider>
                            <ImageCacheProvider>
                                <Switch>
                                    <PortalRoute />
                                    {/* <Route path='/admin'> */}
                                    {/* {!auth.authorized ? <SignIn next={'/user'}/> : <PortalRoute />} */}
                                    {/* </Route> */}
                                </Switch>
                            </ImageCacheProvider>
                        </DialogProvider>
                    </SnackbarProvider>
                </BrowserRouter>
            </MuiThemeProvider>
        </div>
    )
}

function PortalRoute() {
    const auth = useAuth()
    return (
        <Switch>
            {/* <Route path="/chime">
                <VideoChat />
            </Route>
            <Route path="/sample">
                <Sample />
            </Route> */}
            <Route path="/admin">{!auth.authorized ? <SignIn type="admin" next="/admin" /> : <AdminPortal />}</Route>
            <Route path="/:facility/staff">{!auth.authorized ? <SignIn type="staff" next="/staff" /> : <StaffPortal />}</Route>
            <Route path="/:facility/user">{!auth.authorized ? <SignIn type="user" next="/user" /> : <UserPortal />}</Route>
            <Route path="/staff">{!auth.authorized ? <SignIn type="staff" next="/staff" /> : <StaffPortal />}</Route>
            <Route path="/user">{!auth.authorized ? <SignIn type="user" next="/user" /> : <UserPortal />}</Route>
            {/* // 暫定: RealizeVR ページにリダイレクトさせる. */}
            <Route
                exact
                path="/"
                render={() => {
                    window.location.href = 'https://tasdg.co.jp/realizevr'
                    return null
                }}
            />
            {/* // ルーティングに登録されていないパスは、暫定ページにリダイレクト. */}
            {/* <Route path="*" >NoMatch</Route> */}
            <Route
                path="*"
                render={() => {
                    window.location.href = 'https://tasdg.co.jp/realizevr'
                    return null
                }}
            />
        </Switch>
    )
}

export default App
