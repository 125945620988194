//
// 管理者ポータル.
// トレーニング管理.
//
// import * as Const from '../Const'
import React from 'react'

// User's Component.
import TitleBar from './TitleBar'

// トレーニング一覧
import UserList from './UserList'
import UserEdit from './UserEdit'
import SearchBar2 from './SearchBar2'
import { NewButton } from './Buttons'

import { Box } from '@material-ui/core'
import { useRouteMatch, useHistory, Switch, Route } from 'react-router-dom'
import { User } from 'Types'
import { useFacility } from 'modules/AppModule'
import { fetchUsersByFacilityId, useUserItems } from 'modules/UserModule'
import { useAppDispatch } from 'store'
import { isUndefined } from 'util'
import { getImageCacheContext } from 'modules/ImageCacheProvider'
import { ImportUsers } from 'containers/Staff/User/Create/components/ImportUsers'

type UserSettingProps = {}

export default function UserSetting(props: UserSettingProps) {
    console.log('### UserSetting')
    const imageCache = React.useContext(getImageCacheContext())

    const history = useHistory()
    const match = useRouteMatch()
    const listPageUrl = match.url

    const facility = useFacility()
    const facility_id = isUndefined(facility) || isUndefined(facility.facility_id) ? '' : facility.facility_id
    const dispatch = useAppDispatch()
    // 初回ロード.
    React.useEffect(() => {
        if (facility_id === '') {
            return
        }
        dispatch(fetchUsersByFacilityId(facility_id)).then((res) => {
            const users = res.payload
            imageCache.addUsers(users)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, facility_id])
    const items: User[] = useUserItems()

    const max_users = facility?.max_users === undefined ? 0 : facility?.max_users
    const num_users = items.length

    // タイトルバー：新規作成
    const handleClickNew = () => {
        history.push(`${listPageUrl}/create`)
    }

    const handleClickCell = (user: User) => {
        history.push(`${listPageUrl}/${user?.user_id}`)
    }

    const handleClose = () => {
        history.push(listPageUrl)
    }

    // CSSを適用する。
    // const classes = useStyles();
    return (
        <div style={{ width: 960, margin: '35px auto' }}>
            <Switch>
                <Route
                    exact
                    path={listPageUrl}
                    render={() => (
                        <>
                            <Box marginBottom="30px">
                                <SearchBar2
                                    right={
                                        <NewButton disabled={num_users >= max_users} onClickHandler={handleClickNew}>
                                            新規登録
                                        </NewButton>
                                    }
                                />
                            </Box>
                            <TitleBar label={`ユーザ一覧 (現在:${num_users} 上限:${max_users})`} bgBlue />
                            <UserList onClickHandler={handleClickCell} />
                        </>
                    )}
                />
                <Route
                    path={`${listPageUrl}/create`}
                    render={() => (
                        <>
                            <ImportUsers />
                            <TitleBar bgBlue label="ユーザ登録" />
                            <UserEdit onCloseHandler={handleClose} />
                        </>
                    )}
                />
                <Route
                    path={`${listPageUrl}/:userId`}
                    render={() => (
                        <>
                            <TitleBar bgBlue label="ユーザ詳細" />
                            <UserEdit onCloseHandler={handleClose} />
                        </>
                    )}
                />
            </Switch>
        </div>
    )
}
