import React, { useState } from 'react'
import { Controller } from 'react-hook-form'
import { InputAdornment, TextField, IconButton, makeStyles, createStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: '24px !important',
            border: '1px solid #ced4da',
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            '&.Mui-error': {
                borderColor: 'red',
            },
            '&.Mui-focused': {
                borderColor: theme.palette.primary.main,
            },
            '&.Mui-focused.Mui-error': {
                borderColor: 'transparent',
                boxShadow: '0 0 0 1px red',
            },
        },
        input: {
            position: 'relative',
            borderColor: '#6E6E6E',
            backgroundColor: theme.palette.common.white,
            fontSize: 16,
            width: '100%',
            padding: '10px 12px',
        },
        label: {
            fontSize: 18,
            color: 'black',
        },
    }),
)

interface PasswordFieldProps {
    form: any // useForm() react-hook-form
    name: string
    label: string
    defaultValue?: string
    required?: boolean
    rules?: object // validation react-hook-form
    disabled?: boolean
    eye?: boolean // password eye
    className?: string
}

function PasswordField(props: PasswordFieldProps) {
    const classes = useStyles()
    const { form, name, label, defaultValue, required, rules, disabled, eye, className } = props
    const { errors } = form
    const hasError = !!errors[name]
    const errorMsg = errors[name]?.message

    const [showPassword, setShowPassword] = useState(false)

    const toggleShowPassword = () => {
        setShowPassword(!showPassword)
    }

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
    }

    return (
        <Controller
            id={name}
            name={name}
            label={label}
            type={showPassword ? 'text' : 'password'}
            autoComplete="new-password"
            as={<TextField name={name} className={className} />}
            fullWidth
            required={required}
            defaultValue={defaultValue ?? ''}
            control={form.control}
            rules={rules}
            disabled={disabled}
            error={hasError}
            helperText={errorMsg}
            InputProps={{
                classes: {
                    root: classes.root,
                    input: classes.input,
                },
                disableUnderline: true,
                endAdornment: eye && (
                    <InputAdornment position="end">
                        <IconButton aria-label="toggle password visibility" onClick={toggleShowPassword} onMouseDown={handleMouseDownPassword}>
                            {showPassword ? (
                                <img src="/assets/images/eye_open64.png" width="20px" alt="eye_open64" />
                            ) : (
                                <img src="/assets/images/eye_close64.png" width="20px" alt="eye_close64" />
                            )}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
            InputLabelProps={{
                classes: {
                    root: classes.label,
                },
                shrink: true,
            }}
        />
    )
}

export default PasswordField
