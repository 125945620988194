// エラーモジュール
// import * as Const from '../Const'
// import { trace } from '../common/index'

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import { RootState } from '../rootReducer'

// ステート定義
type ErrorState = {
    status?: number
    message?: string
}

// ステート初期値.
const initialState: ErrorState = {
    status: undefined,
    message: undefined,
}

// Slice を作成.
const ErrorModule = createSlice({
    name: 'error',
    initialState,
    reducers: {
        setError(state: ErrorState, action: PayloadAction<string>) {
console.log( "######## SetError", action.payload)
            state.message = action.payload
        },
    },
})

export const useErrorMessage = () => {
    return useSelector((state: RootState) => state.error.message)
}

// Action Creatorsをエクスポート
export const { setError } = ErrorModule.actions

// Slice をエクスポートする
export default ErrorModule
