// ### Import
import clsx from 'clsx'
import React from 'react'
import { trace } from '../common/index'
import { useAppDispatch } from '../store'
import { useFacility, useProfile } from '../modules/AppModule'

import * as DateUtil from 'date-fns'
import jaLocale from 'date-fns/locale/ja'
// for validation
import { useForm } from 'react-hook-form'

// Material-UI
import { Container, TextField, Grid, Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

//### Import MyClass
import * as DataUtil from '../modules/DataUtil'
import { Information, InformationType, Staff } from '../Types'
import { MainButton, DeleteButton } from '../components/Buttons'
import { isUndefined } from 'util'
import AvatarLabel from './AvatarLabel'
import { useParams } from 'react-router-dom'
import { createFacilityInformation, updateFacilityInformation, deleteFacilityInformation, useFacilityInformationById } from 'modules/FacilityModule'

import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import './reactdatepicker.css'
registerLocale('ja', jaLocale)

// スタイル定義.
const useStyles = makeStyles((theme) => ({
    root: {
        width: 960,
        margin: '0 auto',
        position: 'relative',
    },
    deleteButton: {
        position: 'absolute',
        right: 0,
        width: 150,
        background: '#D02927',
        color: '#fff',
        border: 0,
        '&:hover': {
            background: '#D02927',
        },
    },
    container: {
        marginTop: 35,
    },
    paper: {
        marginTop: theme.spacing(0),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    header: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        padding: theme.spacing(2, 0, 2),
    },
    title: {
        fontWeight: 'bold',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    margin: {
        marginTop: theme.spacing(2),
    },
    textField: {
        width: '100%', // Fix IE 11 issue.
    },
    // Warning
    warning: {
        color: '#ff0000',
        marginTop: theme.spacing(2),
    },

    labelName: {
        paddingLeft: 'calc(8px)',
        transform: `translate(-15px,-15px) scale(0.8)`,
        backgroundColor: theme.palette.background.default,
        color: theme.palette.grey[500],

        width: '60px',
    },
    name: {
        margin: '10px 0 25px',
        '& .MuiOutlinedInput-input': {
            padding: 9,
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: 0,
        },
    },
    description: {
        margin: '10px 0 25px',
        '& .MuiOutlinedInput-input': {
            padding: 9,
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: 0,
        },
    },
    multiline: {
        margin: 0,
        padding: 0,
    },
    box_picker: {
        margin: theme.spacing(0, 0, 3), // 上下マージン.
    },
    picker: {
        padding: 10,
        border: '1px solid #B6B6B6',
    },
    datePicker: {
        width: 250,
    },
    timePicker: {
        width: 100,
    },
    headerCalendar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginLeft: 10,
        marginRight: 10,
    },
    buttonCalendar: {
        border: 'none',
        backgroundColor: 'transparent',
        '&:focus': {
            border: 'none',
        },
    },
    label: {
        color: '#05A9C2',
        '&::before': {
            content: '""',
            display: 'block',
            backgroundColor: '#05A9C2',
            width: 16,
            height: 16,
            float: 'left',
            borderRadius: '50%',
            margin: '2px 11px 0 0',
        },
    },
    test: {
        margin: '10px 0 25px',
        '& .MuiOutlinedInput-input': {
            lineHeight: '32px',
            height: 32,
            padding: '0 30px 0 10px',
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: 0,
        },
    },
    footer2: {
        display: 'flex',
        justifyContent: 'center',
    },
    footer: {
        // backgroundColor: "#ff0000",
        margin: theme.spacing(3, 0, 2), // 上下マージン.
        padding: theme.spacing(1, 0, 1, 0), // パディング
    },
}))

// 引数.
interface informationEditProps {
    type: InformationType
    onCloseHandler?: () => void
}

/**
/**
 * お知らせ編集.
 *
 * @returns
 */
export default function InformationEdit(props: informationEditProps) {
    const facility = useFacility()
    const facility_id = props.type === InformationType.SYSTEM ? null : isUndefined(facility) || isUndefined(facility.facility_id) ? '' : facility.facility_id
    const staffProfile = useProfile() as Staff
    const author: string | null = staffProfile?.staff_id === undefined ? null : staffProfile?.staff_id

    const classes = useStyles()
    const dispatch = useAppDispatch()
    const {
        register,
        handleSubmit,
        errors,
        formState: { isSubmitting },
    } = useForm<Information>()

    // path から :informationId を取り出す.
    const { informationId } = useParams()
    const isAddMode = !informationId
    let information = useFacilityInformationById(informationId)

    // redirect to list page if id isn't loaded
    if (informationId && information === undefined) {
        handleClose()
    }

    if (isUndefined(information)) {
        information = DataUtil.initInformation()

        let date: Date = new Date()
        date = DateUtil.addMinutes(date, 60)
        date = DateUtil.setMinutes(date, 0)
        date = DateUtil.setSeconds(date, 0)
        date = DateUtil.setMilliseconds(date, 0)
        const release_date = DateUtil.format(date, 'yyyy-MM-dd HH:mm:ss')

        date = DateUtil.addDays(date, 7 + 1)
        date = DateUtil.setHours(date, 0)
        const expire_date = DateUtil.format(date, 'yyyy-MM-dd HH:mm:ss')

        information = { ...information, release_date: release_date, expire_date: expire_date }
    }
    let release_date = parseDate(information.release_date)
    let expire_date = parseDate(information.expire_date)
    let minDate = release_date

    const [releaseDate, setReleaseDate] = React.useState<Date>(release_date)
    const [expireDate, setExpireDate] = React.useState<Date>(expire_date)
    const [minExpireDate, setMinExpireDate] = React.useState<Date>(DateUtil.addHours(minDate, 1))
    // const [minDate] = React.useState<Date>(release_date)

    function normalizeReleaseDate(date: Date) {
        // リリース日時変更時.
        setReleaseDate(date)

        const limit = DateUtil.addHours(date, 1)
        if (DateUtil.isBefore(expireDate, limit)) {
            setExpireDate(limit)
        }
        setMinExpireDate(limit)
    }

    function normalizeExpireDate(date: Date) {
        // 掲載期限変更時
        setExpireDate(date)
        const limit = DateUtil.addHours(releaseDate, 1)
        if (DateUtil.isBefore(date, limit)) {
            setExpireDate(limit)
        }
        setMinExpireDate(limit)
    }

    const handleDateChange = (name: string) => (date: Date | null) => {
        console.log('Date Picker:', name, date)
        if (date === null) {
            console.log('date is null')
            date = new Date()
        } else {
            console.log('DATE', DateUtil.format(date, 'yyyy-MM-dd'))
        }
        if (name === 'releaseDate') {
            normalizeReleaseDate(date)
        } else {
            normalizeExpireDate(date)
        }
    }
    const handleTimeChange = (name: string) => (date: Date | null) => {
        console.log('Time Picker:', name, date)
        if (date === null) {
            console.log('time is null')
            date = new Date()
        } else {
            console.log('TIME:', DateUtil.format(date, 'HH:mm'))
        }
        if (name === 'releaseTime') {
            normalizeReleaseDate(date)
        } else {
            normalizeExpireDate(date)
        }
    }

    /**
     *　登録ボタンが押された時の処理.
     * @param {React.FormEvent} e
     * @returns
     */
    const onSubmit = async (data: Information) => {
        trace('=== 送信処理')
        const release_date = DateUtil.format(releaseDate, 'yyyy-MM-dd HH:mm:ss')
        const expire_date = DateUtil.format(expireDate, 'yyyy-MM-dd HH:mm:ss')
        // console.log('リリース日時', releaseDate, release_date)
        // console.log('掲載期限', expireDate, expire_date)
        let param: Information = {
            ...data,
            release_date: release_date,
            expire_date: expire_date,
            facility_id: facility_id,
            author: author,
        }
        console.log(param)

        if (informationId === undefined) {
            trace('--新規登録')
            await dispatch(createFacilityInformation(param)).then(() => handleClose())
        } else {
            param = { ...param, id: informationId }
            console.log('--更新', informationId, param)
            await dispatch(updateFacilityInformation(param)).then(() => handleClose())
        }
    }
    function parseDate(dateStr: string) {
        return DateUtil.parse(dateStr, 'yyyy-MM-dd HH:mm:ss', new Date())
    }

    // 削除.
    function handleDelete() {
        trace('--削除')
        if (informationId !== undefined) {
            dispatch(deleteFacilityInformation({ facility_id: '-', id: informationId })).then(() => handleClose())
        }
    }

    /**
     *　閉じるボタンが押された時の処理.
     * - バリデーション
     * - axios で POST
     * @returns
     */
    function handleClose() {
        if (!isUndefined(props.onCloseHandler)) {
            props.onCloseHandler()
        }
    }

    // レンダリング
    return (
        <div className={classes.root}>
            <Container component="main" maxWidth="xs" className={classes.container}>
                {/* 削除. */}
                {!isAddMode && (
                    <DeleteButton fullWidth onClick={handleDelete} className={classes.deleteButton}>
                        削除
                    </DeleteButton>
                )}

                <div className={classes.paper}>
                    <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
                        {/* 名前. */}
                        <AvatarLabel type="active" title="タイトル" />
                        <TextField
                            className={classes.name}
                            variant="outlined"
                            required
                            fullWidth
                            id="title"
                            name="title"
                            defaultValue={information.title}
                            // validation
                            inputRef={register({
                                required: 'タイトルは必須です',
                                maxLength: { value: 20, message: 'タイトルは20文字以内にして下さい。' },
                            })}
                            error={Boolean(errors.title)}
                            helperText={errors.title && errors.title.message}
                        />
                        {/* 説明. */}
                        <AvatarLabel type="active" title="本文" />
                        <TextField
                            className={`${classes.description} `}
                            variant="outlined"
                            required
                            fullWidth
                            id="content"
                            name="content"
                            defaultValue={information.content}
                            InputProps={{
                                className: classes.multiline,
                            }}
                            multiline
                            rows={10}
                            // validation
                            inputRef={register({
                                required: '本文は必須です',
                            })}
                            error={Boolean(errors.content)}
                            helperText={errors.content && errors.content.message}
                        />
                        <Box className={classes.box_picker}>
                            <Typography className={classes.label}>リリース日時</Typography>
                            <Box display="flex" marginTop="5px">
                                <Box marginRight="20px">
                                    <DatePicker
                                        id="releaseDate"
                                        name="releaseDate"
                                        className={clsx(classes.picker, classes.datePicker)}
                                        selected={releaseDate}
                                        onChange={handleDateChange('releaseDate')}
                                        locale="ja"
                                        dateFormat="yyyy/MM/dd"
                                        dateFormatCalendar={'yyyy年 MMM'}
                                        minDate={minDate}
                                        renderCustomHeader={({
                                            date,
                                            decreaseMonth,
                                            increaseMonth,
                                            prevMonthButtonDisabled,
                                            nextMonthButtonDisabled,
                                            decreaseYear,
                                            increaseYear,
                                            prevYearButtonDisabled,
                                            nextYearButtonDisabled,
                                        }) => (
                                            <div className={classes.headerCalendar}>
                                                <Box>
                                                    <button
                                                        type="button"
                                                        onClick={decreaseYear}
                                                        disabled={prevYearButtonDisabled}
                                                        className={classes.buttonCalendar}
                                                    >
                                                        «
                                                    </button>
                                                    <button
                                                        type="button"
                                                        onClick={decreaseMonth}
                                                        disabled={prevMonthButtonDisabled}
                                                        className={classes.buttonCalendar}
                                                    >
                                                        ‹
                                                    </button>
                                                </Box>
                                                {DateUtil.getYear(date)}年{DateUtil.getMonth(date) + 1}月
                                                <Box>
                                                    <button
                                                        type="button"
                                                        onClick={increaseMonth}
                                                        disabled={nextMonthButtonDisabled}
                                                        className={classes.buttonCalendar}
                                                    >
                                                        ›
                                                    </button>
                                                    <button
                                                        type="button"
                                                        onClick={increaseYear}
                                                        disabled={nextYearButtonDisabled}
                                                        className={classes.buttonCalendar}
                                                    >
                                                        »
                                                    </button>
                                                </Box>
                                            </div>
                                        )}
                                        popperPlacement="none"
                                    />
                                </Box>
                                <Box>
                                    <DatePicker
                                        id="releaseTime"
                                        name="releaseTime"
                                        className={clsx(classes.picker, classes.timePicker)}
                                        selected={releaseDate}
                                        onChange={handleTimeChange('releaseTime')}
                                        locale="ja"
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={30}
                                        dateFormat="HH:mm"
                                        timeFormat="HH:mm"
                                        popperPlacement="none"
                                    />
                                </Box>
                            </Box>
                        </Box>
                        <Box className={classes.box_picker}>
                            <Typography className={classes.label}>掲載終了日時</Typography>
                            <Box display="flex" marginTop="5px">
                                <Box marginRight="20px">
                                    <DatePicker
                                        id="expire-date"
                                        name="expire-date"
                                        className={clsx(classes.picker, classes.datePicker)}
                                        selected={expireDate}
                                        onChange={handleDateChange('expire-date')}
                                        locale="ja"
                                        dateFormat="yyyy/MM/dd"
                                        dateFormatCalendar={'yyyy年 MMM'}
                                        minDate={minExpireDate}
                                        renderCustomHeader={({
                                            date,
                                            decreaseMonth,
                                            increaseMonth,
                                            prevMonthButtonDisabled,
                                            nextMonthButtonDisabled,
                                            decreaseYear,
                                            increaseYear,
                                            prevYearButtonDisabled,
                                            nextYearButtonDisabled,
                                        }) => (
                                            <div className={classes.headerCalendar}>
                                                <Box>
                                                    <button
                                                        type="button"
                                                        onClick={decreaseYear}
                                                        disabled={prevYearButtonDisabled}
                                                        className={classes.buttonCalendar}
                                                    >
                                                        «
                                                    </button>
                                                    <button
                                                        type="button"
                                                        onClick={decreaseMonth}
                                                        disabled={prevMonthButtonDisabled}
                                                        className={classes.buttonCalendar}
                                                    >
                                                        ‹
                                                    </button>
                                                </Box>
                                                {DateUtil.getYear(date)}年{DateUtil.getMonth(date) + 1}月
                                                <Box>
                                                    <button
                                                        type="button"
                                                        onClick={increaseMonth}
                                                        disabled={nextMonthButtonDisabled}
                                                        className={classes.buttonCalendar}
                                                    >
                                                        ›
                                                    </button>
                                                    <button
                                                        type="button"
                                                        onClick={increaseYear}
                                                        disabled={nextYearButtonDisabled}
                                                        className={classes.buttonCalendar}
                                                    >
                                                        »
                                                    </button>
                                                </Box>
                                            </div>
                                        )}
                                        popperPlacement="none"
                                    />
                                </Box>
                                <Box>
                                    <DatePicker
                                        id="expire-time"
                                        name="expire-time"
                                        className={clsx(classes.picker, classes.timePicker)}
                                        selected={expireDate}
                                        onChange={handleTimeChange('expire-time')}
                                        locale="ja"
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={30}
                                        dateFormat="HH:mm"
                                        timeFormat="HH:mm"
                                        popperPlacement="none"
                                    />
                                </Box>
                            </Box>
                        </Box>
                        <div className={classes.footer}>
                            <Footer />
                        </div>
                    </form>
                </div>
            </Container>
        </div>
    )

    function Footer() {
        if (isAddMode) {
            return (
                <Grid container spacing={4}>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={6}>
                        {/* 登録. */}
                        <MainButton fullWidth type="submit" disabled={isSubmitting}>
                            登録
                        </MainButton>
                    </Grid>
                    <Grid item xs={3}></Grid>
                </Grid>
            )
        }
        return (
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    {/* 更新. */}
                    <MainButton fullWidth type="submit" disabled={isSubmitting}>
                        更新
                    </MainButton>
                </Grid>
                <Grid item xs={6}>
                    {/* 閉じる. */}
                    <MainButton fullWidth onClick={handleClose}>
                        閉じる
                    </MainButton>
                </Grid>
            </Grid>
        )
    }
}
