//
// SmallAvatar: 小アバター
//
import { withStyles } from '@material-ui/core/styles'

// Material-UI
import { Avatar } from '@material-ui/core'

// SmallAvatar Component
const SmallAvatar = withStyles((theme) => ({
    root: {
        fontSize: '12px',
        width: 22,
        height: 22,
        //   border: `2px solid ${theme.palette.background.paper}`,
    },
}))(Avatar)

export default SmallAvatar
