import { isUndefined } from 'util'

import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

import {
    Box,
    Button,
    ButtonGroup,
    Grow,
    Paper,
    Popper,
    MenuItem,
    MenuList,
    ClickAwayListener,
} from '@material-ui/core'
import { ButtonProps } from '@material-ui/core'
import { ArrowDropDown as ArrowDropDownIcon } from '@material-ui/icons'
import { OptionValue } from '../Types'

// スタイルを適用する
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            //            width: '100%',
            // width: 180,
        },
        button: {
            margin: 0,
            // width: 180,
            // width: '100%',
            height: 40,
        },
        dropdown: {
            justityContent: 'right',
        },
        option: {
            width: 200,
        },
    }),
)

type PulldownProps = {
    onChangeHandler?: (selectedIndex: number) => void
    options: OptionValue[]
} & ButtonProps

export default function Pulldown(props: PulldownProps) {
    const classes = useStyles()

    const [options] = React.useState(props.options)
    const [open, setOpen] = React.useState(false)
    const anchorRef = React.useRef<HTMLDivElement>(null)

    const [selectedIndex, setSelectedIndex] = React.useState(
        findValue(props.defaultValue),
    )

    function findValue(value: any): number {
        const index = props.options.findIndex((item) => item.value === value)
        if (index < 0) return 0
        return index
        // typeof props.defaultValue === 'number'
        // ? props.options.findIndex(
        //         (item) => item.value === props.defaultValue,
        //     )
        // : 0,
    }
    // const handleClick = () => {
    //     console.info(`You clicked ${options[selectedIndex]}`)
    // }

    const handleMenuItemClick = (
        event: React.MouseEvent<HTMLLIElement, MouseEvent>,
        index: number,
    ) => {
        setSelectedIndex(index)
        setOpen(false)
        if (!isUndefined(props.onChangeHandler)) {
            props.onChangeHandler(index)
        }
    }

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen)
    }

    const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return
        }

        setOpen(false)
    }

    const buttonProps = { ...props }
    delete buttonProps['onChangeHandler']
    return (
        <Box>
            <ButtonGroup
                variant="outlined"
                color="default"
                ref={anchorRef}
                aria-label="split button"
                className={classes.button}
            >
                <Button
                    {...buttonProps}
                    onClick={handleToggle}
                    variant="outlined"
                    className={classes.button}
                >
                    {options[selectedIndex].label}
                    {/* <div className={classes.dropdown}><ArrowDropDownIcon /></div> */}
                    <ArrowDropDownIcon />
                </Button>
            </ButtonGroup>
            <Popper
                style={{ zIndex: 5 }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                // disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom'
                                    ? 'center top'
                                    : 'center bottom',
                        }}
                    >
                        <Paper className={classes.option}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="pulldown-menu">
                                    {options.map((option, index) => (
                                        <MenuItem
                                            key={option.value}
                                            selected={index === selectedIndex}
                                            onClick={(event) =>
                                                handleMenuItemClick(
                                                    event,
                                                    index,
                                                )
                                            }
                                        >
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </Box>
    )
}
