import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import clsx from 'clsx'
import { Paper, Box, Tooltip } from '@material-ui/core'

import { LessonStatus, Training } from 'Types'
import { useHomePageUrl } from 'modules/AppModule'
import { useHistory, useRouteMatch } from 'react-router-dom'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        level: {
            textAlign: 'center',
            width: 30,
            height: 30,
            lineHeight: '30px',
            marginRight: 10,
            backgroundColor: '#FFFFFF',
            color: '#5A5A5A',
            fontSize: '15px',
            userSelect: 'none',
            boxShadow: '1px 1px 1px #0000004D',
            '&:hover': {
                cursor: 'pointer',
            },
        },
        testTraining: {
            width: 'auto',
            padding: '0 4px',
        },
        noReservation: {
            backgroundColor: '#FFFFFF',
            color: 'black',
        },
        request: {
            backgroundColor: '#CDEEF3',
            color: '#5A5A5A',
        },
        reserved: {
            backgroundColor: '#CDEEF3',
            color: '#5A5A5A',
        },
        finish: {
            backgroundColor: '#E2E2E2',
            color: '#B6B6B6',
        },
        progressing: {
            backgroundColor: '#05A9C2',
            color: '#FFFFFF',
        },
        popper: {
            top: '-1px !important',
            left: '18px !important',
        },
        testPopper: {
            top: '-1px !important',
            left: '36px !important',
        },
        tooltip: {
            fontSize: '15px',
            color: '#5A5A5A',
            backgroundColor: '#F7F7F7',
            borderRadius: 'unset',
            boxShadow: '0px 3px 6px #0000004D',
            margin: 0,
        },
    }),
)

interface TrainingStatusBarProps {
    trainings: Training[]
    testTrainings: Training[]
}

export default function TrainingStatusBar(props: TrainingStatusBarProps) {
    const classes = useStyles()
    const homePageUrl = useHomePageUrl()
    const history = useHistory()
    const { url } = useRouteMatch()

    // Get first test training
    const testTraining = props.testTrainings[0]
    const testTrainingId = testTraining?.training_id
    const testTrainingName = testTraining?.training_name
    const testTrainingStatus = testTraining?.option_user?.lesson_status

    const handleTrainingClick = (trainingId: string | undefined) => {
        if (!trainingId) return
        history.push({
            pathname: `${homePageUrl}/trainings/${trainingId}`,
            state: { backUrl: url },
        })
    }

    return (
        <React.Fragment>
            <Box className={classes.root}>
                {props.trainings.map((training, index) => {
                    const trainingStatus = training?.option_user?.lesson_status
                    return (
                        <Tooltip
                            title={training.training_name}
                            placement="top-start"
                            classes={{
                                popper: classes.popper,
                                tooltip: classes.tooltip,
                            }}
                            key={index}
                        >
                            <Paper
                                variant="outlined"
                                className={clsx(
                                    classes.level,
                                    trainingStatus === undefined
                                        ? classes.noReservation
                                        : trainingStatus === LessonStatus.request
                                        ? classes.request
                                        : trainingStatus === LessonStatus.progressing
                                        ? classes.progressing
                                        : trainingStatus === LessonStatus.reserved
                                        ? classes.reserved
                                        : trainingStatus === LessonStatus.finish
                                        ? classes.finish
                                        : classes.noReservation,
                                )}
                                onClick={() => handleTrainingClick(training?.training_id)}
                            >
                                {index + 1}
                            </Paper>
                        </Tooltip>
                    )
                })}
                {testTrainingId && (
                    <Tooltip
                        title={testTrainingName}
                        placement="top-start"
                        classes={{
                            popper: classes.testPopper,
                            tooltip: classes.tooltip,
                        }}
                    >
                        <Paper
                            variant="outlined"
                            className={clsx(
                                classes.level,
                                classes.testTraining,
                                testTrainingStatus === LessonStatus.request
                                    ? classes.request
                                    : testTrainingStatus === LessonStatus.progressing
                                    ? classes.progressing
                                    : testTrainingStatus === LessonStatus.reserved
                                    ? classes.reserved
                                    : testTrainingStatus === LessonStatus.finish
                                    ? classes.finish
                                    : classes.noReservation,
                            )}
                            onClick={() => handleTrainingClick(testTrainingId)}
                        >
                            テスト
                        </Paper>
                    </Tooltip>
                )}
            </Box>
        </React.Fragment>
    )
}
