import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        error: {
            color: 'red',
        },
        paper: {
            position: 'absolute',
            width: '800px',
            maxWidth: '100vw',
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #9e9e9e',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            borderRadius: '15px',
            minHeight: '250px',
        },
        modal: {
            top: `${50}%`,
            left: `${51}%`,
            transform: `translate(-${50}%, -${51}%)`,
        },
        modalTitle: {
            borderBottom: '2px solid',
            paddingBottom: '.5rem',
        },
        modalFooter: {
            marginTop: '1rem',
        },
        button: {
            height: '32px',
            '& h4': {
                fontSize: 14,
            },
        },
        buttonItem: {
            textAlign: 'center',
        },
        buttonGroup: {
            width: '250px',
            marginLeft: 'auto',
            textAlign: 'center',
        },
        buttonImport: {
            fontSize: 'calc(20px)', // default: 16px
            display: 'inline-block',
            width: '150px',
            textAlign: 'center',
            height: '35px',
            cursor: 'pointer',
            lineHeight: '20px',
            '&:hover': {
                textDecoration: 'none',
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },
        },
        buttonDownloadTemplate: {
            fontSize: 'calc(17px)', // default: 16px
            color: '#2196f3',
            textDecoration: 'underline',
        },
        link: {
            color: '#2196f3',
        },
        inputFile: {
            display: 'none',
        },
        formLabel: {
            fontSize: 'calc(16px)', // default: 16px
            marginBottom: '.5rem',
            display: 'inline-block',
        },
        fileInput: {
            fontSize: 'calc(14px)', // default: 16px
            cursor: 'pointer',
            border: '1px dashed',
            padding: '5px 10px',
            borderRadius: '5px',
            width: '100%',
            display: 'block',
        },
    }),
)
