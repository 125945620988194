import { isUndefined } from 'util'

import React from 'react'

import { useAppDispatch } from '../store'
import { patchFacilitySetting, useFacilityById } from '../modules/FacilityModule'

// material-UI
import { Box, Typography, MenuItem } from '@material-ui/core'

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

// User
import { Facility, TrainingSetting } from '../Types'
import { SelectTextField } from './Inputs'
import { MainButton } from './Buttons'
import { useParams } from 'react-router-dom'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: 960,
            margin: '50px auto',
        },
        form: {
            width: 450,
            margin: '0 auto',
        },
        cell: {
            display: 'flex',
            marginRight: -30,
            marginBottom: 40,
        },
        item: {
            marginRight: 30,
            display: 'flex',
            alignItems: 'flex-end',
        },
        input: {
            width: 160,
            marginRight: 11,
            '& .MuiInputBase-input': {
                border: 'none',
                boxShadow: '1px 1px 2px #0000004D',
            },
        },
        button: {
            width: 170,
        },
    }),
)

function makeOptions(min: number, max: number) {
    var options: { value: number; label: string }[] = []
    for (var i = min; i <= max; i++) {
        options.push({ value: i, label: `${i}` })
    }
    return options
}

type LocalState = {
    min_level: number
    max_level: number
    min_license: number
    max_license: number
}

interface TrainingSettingEdit2Props {
    onCloseHandler?: () => void
}

/**
 * トレーニング設定.
 *
 * @export
 * @returns
 */
export default function TrainingSettingEdit2(props: TrainingSettingEdit2Props) {
    const classes = useStyles()
    const dispatch = useAppDispatch()

    const { facilityId } = useParams()
    const selectedItem = useFacilityById(facilityId)

    // redirect to list page if id isn't loaded
    if (facilityId && isUndefined(selectedItem)) {
        handleClose()
    }

    const facility_id = isUndefined(selectedItem) || isUndefined(selectedItem.facility_id) ? undefined : selectedItem.facility_id

    const [state, setState] = React.useState<LocalState>({
        min_level: getSettingLevel(selectedItem)[0],
        max_level: getSettingLevel(selectedItem)[1],
        min_license: getSettingLicense(selectedItem)[0],
        max_license: getSettingLicense(selectedItem)[1],
    })

    function getSettingLevel(facility?: Facility): number[] {
        return isUndefined(selectedItem) || isUndefined(selectedItem.training_setting)
            ? [1, 10]
            : [selectedItem.training_setting.min_level, selectedItem.training_setting.max_level]
    }
    function getSettingLicense(facility?: Facility): number[] {
        return isUndefined(selectedItem) || isUndefined(selectedItem.training_setting)
            ? [1, 10]
            : [selectedItem.training_setting.min_license, selectedItem.training_setting.max_license]
    }

    // 送信.
    function onSubmit() {
        const item: TrainingSetting = {
            min_level: state.min_level,
            max_level: state.max_level,
            min_license: state.min_license,
            max_license: state.max_license,
        }
        if (!isUndefined(facility_id)) {
            dispatch(patchFacilitySetting({ facility_id: facility_id, item: item })).then(() => handleClose())
        }
    }

    function handleClose() {
        if (props.onCloseHandler) {
            props.onCloseHandler()
        }
    }

    const handleChange = (prop: keyof LocalState) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = +event.target.value
        switch (prop) {
            case 'min_level':
                if (newValue > state.max_level) {
                    setState({ ...state, min_level: state.max_level, max_level: newValue })
                } else {
                    setState({ ...state, min_level: newValue })
                }
                break
            case 'max_level':
                if (newValue < state.min_level) {
                    setState({ ...state, min_level: newValue, max_level: state.min_level })
                } else {
                    setState({ ...state, max_level: newValue })
                }
                break
            case 'min_license':
                if (newValue > state.max_license) {
                    setState({ ...state, min_license: state.max_license, max_license: newValue })
                } else {
                    setState({ ...state, min_license: newValue })
                }
                break
            case 'max_license':
                if (newValue < state.min_license) {
                    setState({ ...state, min_license: newValue, max_license: state.min_license })
                } else {
                    setState({ ...state, max_license: newValue })
                }
                break
        }
    }

    // プルダウンパターン.
    const PulldownContent = (
        <Box className={classes.form}>
            <Box className={classes.cell}>
                <Box className={classes.item}>
                    <SelectTextField
                        className={classes.input}
                        id="min_level"
                        name="min_level"
                        label="レベル"
                        defaultValue={state.min_level}
                        value={state.min_level}
                        onChange={handleChange('min_level')}
                    >
                        {makeOptions(1, 10).map((option) => (
                            <MenuItem key={option.label} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </SelectTextField>
                    <Typography variant="h5">以上</Typography>
                </Box>
                <Box className={classes.item}>
                    <SelectTextField
                        className={classes.input}
                        id="max_level"
                        name="max_level"
                        defaultValue={state.max_level}
                        value={state.max_level}
                        onChange={handleChange('max_level')}
                    >
                        {makeOptions(1, 10).map((option) => (
                            <MenuItem key={option.label} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </SelectTextField>
                    <Typography variant="h5">以下</Typography>
                </Box>
            </Box>
            <Box className={classes.cell}>
                <Box className={classes.item}>
                    <SelectTextField
                        className={classes.input}
                        id="min_license"
                        name="min_license"
                        label="ライセンス"
                        defaultValue={state.min_license}
                        value={state.min_license}
                        onChange={handleChange('min_license')}
                    >
                        {makeOptions(1, 15).map((option) => (
                            <MenuItem key={option.label} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </SelectTextField>
                    <Typography variant="h5">以上</Typography>
                </Box>
                <Box className={classes.item}>
                    <SelectTextField
                        className={classes.input}
                        id="max_license"
                        name="max_license"
                        defaultValue={state.max_license}
                        value={state.max_license}
                        onChange={handleChange('max_license')}
                    >
                        {makeOptions(1, 15).map((option) => (
                            <MenuItem key={option.label} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </SelectTextField>
                    <Typography variant="h5">以下</Typography>
                </Box>
            </Box>
        </Box>
    )

    return (
        <React.Fragment>
            <Box className={classes.container}>
                {PulldownContent}

                {/* Footer */}
                <Box
                    display="flex"
                    style={{
                        width: 370,
                        margin: '40px auto',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <MainButton className={classes.button} type="submit" onClick={onSubmit}>
                        設定
                    </MainButton>
                    <MainButton className={classes.button} onClick={handleClose}>
                        閉じる
                    </MainButton>
                </Box>
            </Box>
        </React.Fragment>
    )
}
