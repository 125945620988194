//
// TitleBar: タイトルバー
//
//
//
// import * as Const from '../Const'
// import { trace } from '../common/index'
import React from 'react'
import clsx from 'clsx'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Grid, Paper, Typography } from '@material-ui/core'

// スタイルを適用する
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            backgroundColor: '#000000',
            alignItems: 'center',
            justifyContent: 'center',
        },
        container: {
            minHeight: 40,
            color: '#ffffff',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
        },
        item: {
            // backgroundColor: "#0000ff",
        },
        icon: {
            // color: theme.palette.common.white,
        },
        bgBlue: {
            backgroundColor: '#05A9C2',
        },
        bgBlack: {
            background: 'transparent linear-gradient(0deg, #5A5A5A 0%, #5F5F5F 7%, #707070 34%, #7A7A7A 64%, #7E7E7E 100%)',
        },
        endRight: {
            textAlign: 'end',
        },
    }),
)

type TitleBarProps = {
    label?: string
    left?: JSX.Element
    center?: JSX.Element
    right?: JSX.Element
    bgBlue?: boolean
    bgBlack?: boolean
    endRight?: boolean

    onClickHandler?: (event: React.MouseEvent<unknown, MouseEvent>) => void
}

export default function TitleBar(props: TitleBarProps) {
    const classes = useStyles()

    return (
        <Paper variant="outlined" square elevation={0} className={clsx(classes.root, props.bgBlue && classes.bgBlue, props.bgBlack && classes.bgBlack)}>
            <Grid container className={classes.container}>
                {/* Left space */}
                <Grid item xs={3} className={classes.item}>
                    {/* <div>{"　"}</div> */}
                    {props.left !== undefined ? props.left : null}
                </Grid>

                {/* Center : Title Label */}
                <Grid item xs={6} className={classes.item}>
                    {props.center !== undefined ? props.center : <Typography variant="h3">{props.label}</Typography>}
                </Grid>

                {/* Right: Additional Component */}
                <Grid item xs={3} className={clsx(classes.item, props.endRight && classes.endRight)}>
                    {props.right !== undefined ? props.right : null}
                </Grid>
            </Grid>
        </Paper>
    )
}
