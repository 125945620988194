import React from 'react'
import { makeStyles, createStyles, Theme, Box, Typography } from '@material-ui/core'
import { useParams, Switch, Route, useRouteMatch, useHistory } from 'react-router-dom'
import { useFacilityById } from 'modules/FacilityModule'
import { MainButton, NewButton } from './Buttons'
import TitleBar from './TitleBar'
import SimpleTitleBar from './SimpleTitlebar'
import FacilityStaffList from './FacilityStaffList'
import FacilityStaffEdit from './FacilityStaffEdit'
import SearchBar2 from './SearchBar2'
import { Staff } from 'Types'
import { isUndefined } from 'util'
import { useHomePageUrl } from 'modules/AppModule'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: 960,
            margin: '0 auto',
        },
    }),
)

export default function FacilityStaffContent() {
    const classes = useStyles()

    const homePageUrl = useHomePageUrl()
    const history = useHistory()
    const match = useRouteMatch()
    const listPageUrl = match.url
    const { facilityId } = useParams()
    const facility = useFacilityById(facilityId)

    // redirect to list page if id isn't loaded
    if (facilityId && isUndefined(facility)) {
        handleClose()
    }

    const facilityName = isUndefined(facility) || isUndefined(facility.facility_name) ? '' : facility.facility_name

    // [閉じる]
    function handleClose() {
        history.push(`${homePageUrl}/facilities`)
    }
    // 職員: 閉じる.
    const handleCloseStaff = () => {
        history.push(listPageUrl)
    }
    // 職員一覧: 追加ボタン：新規作成
    const handleClickStaffNew = () => {
        history.push(`${listPageUrl}/create`)
    }
    const handleClickStaffEdit = (staff: Staff) => {
        history.push(`${listPageUrl}/${staff?.staff_id}`)
    }

    return (
        <React.Fragment>
            <Switch>
                <Route
                    exact
                    path={`${homePageUrl}/facilities/:facilityId/staffs`}
                    render={() => (
                        <>
                            <Box className={classes.container}>
                                <Box marginBottom="30px">
                                    <SearchBar2
                                        left={
                                            <Box display="flex" alignItems="center" color="black">
                                                <Typography variant="h4">{facilityName}&nbsp;&nbsp;</Typography>
                                                <MainButton onClick={handleClose}>戻る</MainButton>
                                            </Box>
                                        }
                                        right={<NewButton onClickHandler={handleClickStaffNew}>新規登録</NewButton>}
                                    />
                                </Box>
                                <TitleBar bgBlue label="職員一覧" />
                            </Box>
                            {!isUndefined(facility) && !isUndefined(facility.facility_id) ? (
                                <FacilityStaffList onClickHandler={handleClickStaffEdit} />
                            ) : (
                                <div className={classes.container}>施設が見つかりません</div>
                            )}
                        </>
                    )}
                />
                <Route
                    path={`${homePageUrl}/facilities/:facilityId/staffs/create`}
                    render={() => (
                        <>
                            {facilityName && <FacilityTitle>{facilityName}</FacilityTitle>}
                            <SimpleTitleBar label="職員登録" />
                            <FacilityStaffEdit onCloseHandler={handleCloseStaff} />
                        </>
                    )}
                />
                <Route
                    path={`${homePageUrl}/facilities/:facilityId/staffs/:staffId`}
                    render={() => (
                        <>
                            {facilityName && <FacilityTitle>{facilityName}</FacilityTitle>}
                            <SimpleTitleBar label="職員詳細" />
                            <FacilityStaffEdit onCloseHandler={handleCloseStaff} />
                        </>
                    )}
                />
            </Switch>
        </React.Fragment>
    )
}

type FacilityTitleProps = {
    children?: React.ReactNode
}
function FacilityTitle(props: FacilityTitleProps) {
    return (
        <div
            style={{
                margin: '0 auto 20px',
                width: 960,
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <Typography variant="h4">{props.children} </Typography>
        </div>
    )
}
