// import * as Const from '../Const'
// import { trace } from '../common/index'
import React from 'react'
import { isUndefined } from 'util'

// import {
//     ArrowDropDown as ArrowDropDownIcon,
// } from '@material-ui/icons/';
import { ClickAwayListener, Grid, Button, ButtonGroup, Popper, Paper, Grow, MenuItem, MenuList, Avatar } from '@material-ui/core'

import { StandardProps } from '@material-ui/core'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

export type PulldownOption = {
    label: string // 表示テキスト.
    url: string
}

// MenuButton のプロパティ.
export interface PullDownProps extends StandardProps<React.HTMLAttributes<HTMLDivElement>, PullDownClassKey> {
    /** タイトル文字列 */
    title: string
    /** アイコン base64 or url*/
    icon?: string
    // プルダウン項目
    options: PulldownOption[]

    onClickHandler?: (value: string) => void
}
export type PullDownClassKey = 'root' | 'rounded' | 'outlined' | 'className'

// スタイル定義.
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        menuButton: {
            color: 'inherit',
            width: '230px',
            height: '64px',
            background: 'none',
            borderRadius: 0,
            borderLeft: '1px solid #fff',
        },
        avatar: {
            width: theme.spacing(3),
            height: theme.spacing(3),
            margin: '8px',
        },
        menuItem: {
            '&:focus': {
                // フォーカス時。
                backgroundColor: theme.palette.common.white,
                '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                    color: theme.palette.common.white,
                },
            },
            '&:last-child': {
                borderBottomLeftRadius: 7,
                borderBottomRightRadius: 7,
                boxShadow: '0px 2px 2px #e3e3e3',
            },
            backgroundColor: '#D9F2F6',
            color: '#05A9C2',
            border: '1px solid #fff',
            width: 230,
            zIndex: 10,
        },
        menuList: {
            padding: 0,
        },
    }),
)

export default function PulldownButton(props: PullDownProps) {
    // スタイルを適用する。
    const classes = useStyles()

    const [open, setOpen] = React.useState(false)
    const anchorRef = React.useRef<HTMLDivElement>(null)
    // const [selectedIndex, setSelectedIndex] = React.useState(1)

    const title = props.title
    const iconWrapper = !isUndefined(props.icon) ? <Avatar src={props.icon} className={classes.avatar} /> : null
    const options = props.options

    // const handleClick = () => {
    //     console.info(`You clicked ${options[selectedIndex]}`);
    // };

    const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
        // setSelectedIndex(index)
        setOpen(false)
        if (props.onClickHandler !== undefined) {
            props.onClickHandler(props.options[index].url)
        }
    }

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen)
    }

    const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return
        }

        setOpen(false)
    }

    return (
        <Grid container direction="column" alignItems="center" className={props.className}>
            <Grid item xs={12}>
                <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button">
                    <Button className={classes.menuButton} onClick={handleToggle}>
                        {title}
                        {iconWrapper}
                    </Button>
                </ButtonGroup>
                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition className={classes.menuItem}>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin: placement === 'bottom' ? 'left top' : 'left bottom',
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList id="split-button-menu" className={classes.menuList}>
                                        {options.map((option, index) => (
                                            <MenuItem
                                                key={index}
                                                // disabled={index === 2}
                                                // selected={index === selectedIndex}
                                                onClick={(event) => handleMenuItemClick(event, index)}
                                                className={classes.menuItem}
                                            >
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </Grid>
        </Grid>
    )
}
