// APPモジュール
import React from 'react'
// import { trace } from '../common/index'

import { useSelector } from 'react-redux'
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from '../rootReducer'
import * as ApiClient from './ApiClient'

import {
    Admin,
    Facility,
    Staff,
    User,
    // Course, Training, Lesson
} from '../Types'
import { getImageCacheContext } from './ImageCacheProvider'

// ステート定義
type AppState = {
    portal: string // ポータル種別.
    homePageUrl: string
    theme: string // Material-UI テーマ

    // 表示制御.
    isDrawerOpen: boolean // ドロワー開閉状態
    isNeedLogin: boolean
    //
    facility?: Facility // 施設情報.
    profile?: Admin | Staff | User // ログインユーザ情報.
}

// ステート初期値.
const initialState: AppState = {
    portal: 'admin',
    homePageUrl: '',
    // contentPage: 'trainingPage',
    theme: 'staff',

    isDrawerOpen: false,
    isNeedLogin: false,

    facility: undefined,
    profile: undefined,
}

// 施設プロフィールを取得.
export const fetchFacilityById = createAsyncThunk('app/fetchFacilityById', async (id: string, thunkAPI) => {
    const response = await ApiClient.get(`/facilities/${id}`)
    return response.data
})
// 職員プロフィールを取得.
export const fetchStaffById = createAsyncThunk('app/fetchStaffById', async (id: string, thunkAPI) => {
    const url = `/staff/${id}`
    const response = await ApiClient.get(url)
    return response.data
})

// Slice を作成.
const AppModule = createSlice({
    name: 'app',
    initialState,
    reducers: {
        focusChanged(state: AppState, action: PayloadAction<undefined>) {},
        setNeedLogin(state: AppState, action: PayloadAction<boolean>) {
            state.isNeedLogin = action.payload
        },
        // ポータル変更.
        changePortal(state: AppState, action: PayloadAction<string>) {
            state.portal = action.payload

            localStorage.setItem('theme', action.payload)
            // console.log('set theme:' + action.payload)
        },
        setHomePageUrl(state: AppState, action: PayloadAction<string>) {
            state.homePageUrl = action.payload
        },
        // toggleDrawer(state: AppState, action: PayloadAction<boolean>) {
        //     state.isDrawerOpen = !state.isDrawerOpen
        //     console.log('$$$ Drawer:' + state.isDrawerOpen)
        // },
        setProfile(state: AppState, action: PayloadAction<Admin | Staff | User>) {
            state.profile = action.payload
        },
        setFacility(state: AppState, action: PayloadAction<Facility>) {
            let facility = action.payload
            state.facility = facility
        },
    },
    extraReducers: (builder) => {
        // PROFILE.
        // fetch by id
        builder.addCase(fetchFacilityById.fulfilled, (state, action) => {
            // console.log('fulfilled')
            const facilities = action.payload
            if (Array.isArray(facilities)) {
                state.facility = facilities[0]
            } else {
                state.facility = facilities
            }
            // console.log('### Facility', state.facility)
            return state
        })
        builder.addCase(fetchFacilityById.pending, (state, action) => {
            // console.log('pending')
        })
        builder.addCase(fetchFacilityById.rejected, (state, action) => {
            // console.log('rejected')
        })
        // fetch by id
        builder.addCase(fetchStaffById.fulfilled, (state, action) => {
            // console.log('fulfilled')
            const staff: Staff[] = action.payload

            // TODO:  ENUM を明示的な意味を持つ文字列に変更する.
            // PHP が 数値を文字列としてJSONエンコードしてしまうため.
            // staff[0].role_id = Number(staff[0].role_id )

            state = { ...state, profile: staff[0] }
            return state
        })
        builder.addCase(fetchStaffById.pending, (state, action) => {
            // console.log('pending')
        })
        builder.addCase(fetchStaffById.rejected, (state, action) => {
            // console.log('rejected')
        })
    },
})

export const usePortal = () => {
    return useSelector((state: RootState) => state.app.portal)
}
export const useHomePageUrl = () => {
    return useSelector((state: RootState) => state.app.homePageUrl)
}
export const useTheme = () => {
    return useSelector((state: RootState) => state.app.theme)
}
// export const useDrawer = () => {
//     return useSelector((state: RootState) => state.app.isDrawerOpen)
// }

export const useProfile = () => {
    const profile = useSelector((state: RootState) => state.app.profile)
    const imageCache = React.useContext(getImageCacheContext())

    const portal = usePortal()
    if (portal === 'staff') {
        return { ...profile, icon: imageCache.getStaffImage(profile as Staff) }
    } else if (portal === 'user') {
        return { ...profile, icon: imageCache.getUserImage(profile as User) }
    }
    return profile
}

export const useFacility = () => {
    return useSelector((state: RootState) => state.app.facility)
}

export const useNeedLogin = () => {
    return useSelector((state: RootState) => state.app.isNeedLogin)
}

// Action Creatorsをエクスポート
export const {
    // changeContentPage,
    changePortal,
    setHomePageUrl,
    // toggleDrawer,
    setProfile,
    setFacility,

    focusChanged,
    setNeedLogin,
} = AppModule.actions

// Slice をエクスポートする
export default AppModule
