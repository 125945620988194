import React from 'react'

type Props = {
    children: React.ReactNode
}

const videoStateContext = React.createContext({
    Reset: () => {},
    Play: () => {},
    Pause: () => {},
    isPlaying: false,
    setPlay: (flag: boolean) => {},
})

export function getVideoStateContext() {
    return videoStateContext
}

export default function VideoStateProvider({ children }: Props) {
    const [videoState, setVideoState] = React.useState(false)

    const Context = getVideoStateContext()

    const reset = () => {
        setVideoState(false)
    }

    const play = () => {
        setVideoState(true)
    }
    const pause = () => {
        setVideoState(false)
    }
    const setPlay = (flag: boolean) => {
        setVideoState(flag)
    }
    const providerValue = {
        Reset: reset,
        Play: play,
        Pause: pause,
        isPlaying: videoState,
        setPlay: setPlay,
    }
    return <Context.Provider value={providerValue}>{children}</Context.Provider>
}
