// import * as Const from '../Const'
// import { trace } from '../common/index'
// import clsx from 'clsx'
import React from 'react'

// Material-UI
import { makeStyles, createStyles, withStyles, Theme } from '@material-ui/core/styles'
import { Button, IconButton, ButtonProps, Typography, SvgIcon } from '@material-ui/core'
import {
    AddCircleOutline as AddIcon, // 追加
    RemoveCircle as RemoveIcon, // 追加
    Settings as SettingIcon, // 設定
    NavigateNext as NextIcon, // ＞
    NavigateBefore as PrevIcon, // ＜
    ArrowDropDownCircle as ExpandIcon, // 拡張用下矢印
    // ExpandMore as ExpandIcon, // 拡張用下矢印
} from '@material-ui/icons'
import clsx from 'clsx'

import { getDialogContext } from 'modules/DialogProvider'

// スタイルを適用する
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        add: {
            color: '#ffffff',
        },
        remove: {
            color: theme.palette.secondary.main,
        },
        setting: {
            color: '#ffffff',
        },
        next: {
            color: '#ffffff',
        },
        prev: {
            color: '#ffffff',
        },
        today: {
            marginLeft: 10,
            color: '#ffffff',
            borderColor: '#ffffff',
            height: 20,
            width: 30,
        },
        today2: {
            marginLeft: 20,
            color: '#05A9C2',
            borderColor: '#ffffff',
            height: 20,
            width: 30,
            background: '#fff',
            display: 'flex',
            borderRadius: 10,
            '&:hover': {
                background: '#05A9C2',
                color: '#ffffff',
            },
        },
        expand: {
            color: theme.palette.primary.main,
        },
        double_next: {
            color: '#6a6a6a',
            background: '#fff',
            width: 30,
            height: 30,
        },
        double_prev: {
            color: '#6a6a6a',
            background: '#fff',
            width: 30,
            height: 30,
        },
        hide: {
            visibility: 'hidden',
        },
        show: {
            visibility: 'visible',
        },
        main: {
            color: '#5a5a5a',
            background:
                'transparent linear-gradient(0deg, #B4B4B5 0%, #BCBCBD 1%, #CDCDCD 2%, #D9D9D9 4%, #E0E0E0 7%, #E2E2E2 14%, #EAEAEA 20%, #F6F6F6 33%, #FDFDFD 51%, #FFFFFF 100%)',
            border: '1px solid #6E6E6E',
        },
        delete: {
            color: '#5a5a5a',
            background:
                'transparent linear-gradient(0deg, #B4B4B5 0%, #BCBCBD 1%, #CDCDCD 2%, #D9D9D9 4%, #E0E0E0 7%, #E2E2E2 14%, #EAEAEA 20%, #F6F6F6 33%, #FDFDFD 51%, #FFFFFF 100%)',
            border: '1px solid #6E6E6E',
        },
        search: {
            minWidth: '140px !important',
            color: theme.palette.common.white,
            backgroundColor: '#05A9C2',
            border: 'unset',
            borderRadius: '4px !important',
            '&:hover': {
                backgroundColor: '#05A9C2',
            },
            '& h4': {
                display: 'flex',
            },
        },
    }),
)

/*****************************************************************************
 *  追加
 *****************************************************************************/
type AddButtonProps = {
    onClickHandler?: (event: React.MouseEvent<unknown, MouseEvent>) => void
    icon?: JSX.Element
}

export function AddButton(props: AddButtonProps) {
    const classes = useStyles()

    // AddButton Clicked!!
    const handleClick = (event: React.MouseEvent<unknown, MouseEvent>) => {
        // trace('- Add Button clicked')
        if (props.onClickHandler !== undefined) {
            props.onClickHandler(event)
        }
    }
    return (
        <IconButton className={classes.add} aria-label="add an item" onClick={(event) => handleClick(event)}>
            {props.icon ? props.icon : <AddIcon fontSize="inherit" />}
        </IconButton>
    )
}
/*****************************************************************************
 *  削除
 *****************************************************************************/
// type RemoveButtonProps = {
//     onClickHandler?: (event: React.MouseEvent<unknown, MouseEvent>) => void
// }

export function RemoveButton(props: ButtonProps) {
    const classes = useStyles()

    // // AddButton Clicked!!
    // const handleClick = (event: React.MouseEvent<unknown, MouseEvent>) => {
    //     // trace('- Remove Button clicked')
    //     if (props.onClickHandler !== undefined) {
    //         props.onClickHandler(event)
    //     }
    // }
    return (
        <IconButton
            className={classes.remove}
            aria-label="remove an item"
            // onClick={(event) => handleClick(event)}
            onClick={props.onClick}
        >
            <RemoveIcon fontSize="inherit" />
        </IconButton>
    )
}
/*****************************************************************************
 *  設定
 *****************************************************************************/
type SettingButtonProps = {
    onClickHandler?: (event: React.MouseEvent<unknown, MouseEvent>) => void
}

export function SettingButton(props: SettingButtonProps) {
    const classes = useStyles()

    // SettingButton Clicked!!
    const handleClick = (event: React.MouseEvent<unknown, MouseEvent>) => {
        // trace('- Setting Button clicked')
        if (props.onClickHandler !== undefined) {
            props.onClickHandler(event)
        }
    }
    return (
        <IconButton className={classes.setting} aria-label="setting" onClick={(event) => handleClick(event)}>
            <SettingIcon fontSize="inherit" />
        </IconButton>
    )
}
/*****************************************************************************
 *  ＞
 *****************************************************************************/
type NextButtonProps = {
    onClickHandler?: (event: React.MouseEvent<unknown, MouseEvent>) => void
}

export function NextButton(props: NextButtonProps) {
    const classes = useStyles()

    // SettingButton Clicked!!
    const handleClick = (event: React.MouseEvent<unknown, MouseEvent>) => {
        // trace('- Setting Button clicked')
        if (props.onClickHandler !== undefined) {
            props.onClickHandler(event)
        }
    }
    return (
        <IconButton className={classes.next} aria-label="next" onClick={(event) => handleClick(event)}>
            <NextIcon fontSize="inherit" />
        </IconButton>
    )
}
/*****************************************************************************
 *  ＜
 *****************************************************************************/
type PrevButtonProps = {
    onClickHandler?: (event: React.MouseEvent<unknown, MouseEvent>) => void
}

export function PrevButton(props: PrevButtonProps) {
    const classes = useStyles()

    // SettingButton Clicked!!
    const handleClick = (event: React.MouseEvent<unknown, MouseEvent>) => {
        // trace('- Setting Button clicked')
        if (props.onClickHandler !== undefined) {
            props.onClickHandler(event)
        }
    }
    return (
        <IconButton className={classes.prev} aria-label="prev" onClick={(event) => handleClick(event)}>
            <PrevIcon fontSize="inherit" />
        </IconButton>
    )
}
/*****************************************************************************
 *  ＜
 *****************************************************************************/
type TodayButtonProps = {
    onClickHandler?: (event: React.MouseEvent<unknown, MouseEvent>) => void
    // label: string
} & ButtonProps

export function TodayButton(props: TodayButtonProps) {
    const classes = useStyles()

    // SettingButton Clicked!!
    const handleClick = (event: React.MouseEvent<unknown, MouseEvent>) => {
        // trace('- Setting Button clicked')
        if (props.onClickHandler !== undefined) {
            props.onClickHandler(event)
        }
    }
    return (
        <Button variant="outlined" className={classes.today} onClick={(event) => handleClick(event)}>
            {props.children}
        </Button>
    )
}

type TodayButtonProps2 = {
    onClickHandler?: (event: React.MouseEvent<unknown, MouseEvent>) => void
    // label: string
} & ButtonProps

export function TodayButton2(props: TodayButtonProps2) {
    const classes = useStyles()

    // SettingButton Clicked!!
    const handleClick = (event: React.MouseEvent<unknown, MouseEvent>) => {
        // trace('- Setting Button clicked')
        if (props.onClickHandler !== undefined) {
            props.onClickHandler(event)
        }
    }
    return (
        <Button variant="outlined" className={classes.today2} onClick={(event) => handleClick(event)}>
            {props.children}
        </Button>
    )
}
/*****************************************************************************
 *  ＜
 *****************************************************************************/
type ExpandButtonProps = {
    onClickHandler?: (event: React.MouseEvent<unknown, MouseEvent>) => void
}

export function ExpandButton(props: ExpandButtonProps) {
    const classes = useStyles()

    // Clicked!!
    const handleClick = (event: React.MouseEvent<unknown, MouseEvent>) => {
        // trace('- Expand Button clicked')
        if (props.onClickHandler !== undefined) {
            props.onClickHandler(event)
        }
    }
    return (
        <IconButton className={classes.expand} onClick={(event) => handleClick(event)}>
            <ExpandIcon />
        </IconButton>
    )
}

type DropDownButtonProps = {
    onClickHandler?: (event: React.MouseEvent<unknown, MouseEvent>) => void
    show?: boolean
    arrowUp?: boolean
    newStyle?: {}
}

export function DropDownButton(props: DropDownButtonProps) {
    const classes = useStyles()

    // Clicked!!
    const handleClick = (event: React.MouseEvent<unknown, MouseEvent>) => {
        // trace('- Dropdown Button clicked')
        if (props.onClickHandler !== undefined) {
            props.onClickHandler(event)
        }
    }
    const visibility = props.show ? classes.show : classes.hide
    return (
        <IconButton className={clsx(classes.expand, visibility, props.newStyle)} onClick={(event) => handleClick(event)}>
            {props.arrowUp ? <img src="/assets/images/icon-dropdown-up.png" alt="" /> : <img src="/assets/images/icon-dropdown.png" alt="" />}
        </IconButton>
    )
}

type NewButtonProps = {
    onClickHandler?: (event: React.MouseEvent<unknown, MouseEvent>) => void
} & ButtonProps

export function NewButton(props: NewButtonProps) {
    const classes = useStyles()

    // Clicked!!
    const handleClick = (event: React.MouseEvent<unknown, MouseEvent>) => {
        // trace('- New Button clicked')
        if (props.onClickHandler !== undefined) {
            props.onClickHandler(event)
        }
    }
    let buttonProps = { ...props }
    delete buttonProps.onClickHandler
    return (
        <RoundedButton {...buttonProps} variant="contained" className={clsx(props.className, classes.main)} onClick={(event) => handleClick(event)}>
            <Typography variant="h4">{props.children}</Typography>
        </RoundedButton>
    )
}

/*****************************************************************************
 *  Styled Component.
 *****************************************************************************/
// Rounded Button
export const RoundedButton = withStyles((theme) => ({
    root: {
        height: 40,
        borderRadius: 20,
        minWidth: 150,
    },
}))(Button)

// Submit Button
export const SubmitButton = (props: ButtonProps) => {
    return (
        <RoundedButton {...props} variant="contained" color="primary" type="submit" style={{ color: '#fff' }}>
            <Typography variant="h4">{props.children}</Typography>
        </RoundedButton>
    )
}
export const CancelButton = (props: ButtonProps) => {
    return (
        <RoundedButton {...props} variant="contained" color="secondary" style={{ color: '#fff' }}>
            <Typography variant="h4">{props.children}</Typography>
        </RoundedButton>
    )
}
export const CloseButton = (props: ButtonProps) => {
    return (
        <RoundedButton {...props} variant="contained" color="default" style={{}}>
            <Typography variant="h4">{props.children}</Typography>
        </RoundedButton>
    )
}
export const MainButton = (props: ButtonProps) => {
    const classes = useStyles()
    return (
        <RoundedButton {...props} variant="contained" className={clsx(props.className, classes.main)}>
            <Typography variant="h4">{props.children}</Typography>
        </RoundedButton>
    )
}
export const SearchButton = (props: ButtonProps) => {
    const classes = useStyles()
    return (
        <RoundedButton {...props} variant="contained" className={clsx(props.className, classes.search)}>
            <Typography variant="h4">
                {props.children}&nbsp;
                <img src="/assets/images/search.png" alt="" />
            </Typography>
        </RoundedButton>
    )
}

export const DeleteButton = (props: ButtonProps) => {
    const classes = useStyles()
    const { showDialog } = React.useContext(getDialogContext())

    return (
        <RoundedButton
            {...props}
            variant="contained"
            className={clsx(props.className, classes.delete)}
            onClick={() =>
                showDialog({
                    title: '本当に削除してもよろしいですか？',
                    message: 'この操作は戻せません',
                    items: [
                        {
                            label: 'はい',
                            onClickHandler: props.onClick,
                        },
                        { label: 'いいえ' },
                    ],
                })
            }
        >
            <Typography variant="h4">{props.children}</Typography>
        </RoundedButton>
    )
}
/*****************************************************************************
 *  ＞＞
 *****************************************************************************/
type DoubleNextButtonProps = {
    onClickHandler?: (event: React.MouseEvent<unknown, MouseEvent>) => void
}

export function DoubleNextButton(props: DoubleNextButtonProps) {
    const classes = useStyles()

    // SettingButton Clicked!!
    const handleClick = (event: React.MouseEvent<unknown, MouseEvent>) => {
        // trace('- Setting Button clicked')
        if (props.onClickHandler !== undefined) {
            props.onClickHandler(event)
        }
    }
    return (
        <IconButton className={classes.double_next} aria-label="double-next" onClick={(event) => handleClick(event)}>
            <SvgIcon>
                <path
                    fill="currentColor"
                    d="M5.59,7.41L7,6L13,12L7,18L5.59,16.59L10.17,12L5.59,7.41M11.59,7.41L13,6L19,12L13,18L11.59,16.59L16.17,12L11.59,7.41Z"
                />
            </SvgIcon>
        </IconButton>
    )
}
/*****************************************************************************
 *  ＜＜
 *****************************************************************************/
type DoublePrevButtonProps = {
    onClickHandler?: (event: React.MouseEvent<unknown, MouseEvent>) => void
}

export function DoublePrevButton(props: DoublePrevButtonProps) {
    const classes = useStyles()

    // SettingButton Clicked!!
    const handleClick = (event: React.MouseEvent<unknown, MouseEvent>) => {
        // trace('- Setting Button clicked')
        if (props.onClickHandler !== undefined) {
            props.onClickHandler(event)
        }
    }
    return (
        <IconButton className={classes.double_prev} aria-label="double-prev" onClick={(event) => handleClick(event)}>
            <SvgIcon>
                <path
                    fill="currentColor"
                    d="M18.41,7.41L17,6L11,12L17,18L18.41,16.59L13.83,12L18.41,7.41M12.41,7.41L11,6L5,12L11,18L12.41,16.59L7.83,12L12.41,7.41Z"
                />
            </SvgIcon>
        </IconButton>
    )
}
