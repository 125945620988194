// import * as AppConfig from '../config'
import axios from 'axios'
import imageCompression from 'browser-image-compression'

// axios.defaults.headers.post['Access-Control-Request-Method'] = 'GET,POST,PUT,DELETE,PATCH,HEAD'
// axios.defaults.headers.post['Access-Control-Request-Headers'] = 'Content-Type,x-api-key'

// axios.defaults.headers.post['Content-Type'] = 'application/json; charset=utf-8'
// axios.defaults.headers.put['Content-Type'] = 'application/json; charset=utf-8'
// axios.defaults.headers.patch['Content-Type'] = 'application/json; charset=utf-8'
// axios.defaults.headers.common['x-api-key'] = AppConfig.APP_API_KEY;

// axios.defaults.responseType = 'json'

// const AWS_ACCESS_KEY = process.env.AWS_ACCESS_KEY;
// const AWS_SECRET_KEY = process.env.AWS_SECRET_KEY;
// const BUCKET = process.env.BUCKET;

/**
 * 画像を S3 にアップロードする.
 * [方法] 署名付きURLを取得して、PUT
 * @export
 * @param {string} name 登録するファイル名
 * @param {File} file 送信するファイルオブジェクト
 * @returns {(Promise<string>)} URL | error message
 */
export function upload(url: string, file: File): Promise<string> {
    return new Promise((resolve, reject) => {
        const signedUrl = url

        console.log('Success: SignedUrl', signedUrl)
        const options = {
            maxSizeMB: 0.02,
            maxWidthOrHeight: 100,
        }
        imageCompression(file, options)
            .then((resizedImage) => {
                // 画像アップロード.
                uploadImage(signedUrl, resizedImage)
                    .then((res) => {
                        console.log('画像アップロード完了')
                        resolve('success')
                    })
                    .catch((error) => {
                        console.log('画像アップロード失敗')
                        reject(error)
                    })
            })
            .catch((error) => {
                console.log('Resize image failed!')
                console.log(error.message)
                reject(error)
            })
    })
}
// 画像を署名付きURLに PUT する
export function uploadImage(url: string, file: any): Promise<any> {
    const options = {
        headers: {
            'Content-Type': file.type,
            // ACL: 'public-read',
        },
    }
    return axios.put(url, file, options)
}

export function makeBinaryImage(file: File | null, isCompress: boolean) {
    if (file === null) {
        return Promise.reject(new Error('no file'))
    }
    return new Promise((resolve, reject) => {
        if (isCompress) {
            const options = {
                maxSizeMB: 0.02,
                maxWidthOrHeight: 100,
            }
            imageCompression(file, options).then((resizedImage) => {
                var reader = new FileReader()
                reader.onload = (e) => {
                    console.log('image read end')
                    resolve(reader.result)
                }
                reader.readAsDataURL(resizedImage)
            })
        } else {
            var reader = new FileReader()
            reader.onload = (e) => {
                console.log('image read end')
                resolve(reader.result)
            }
            reader.readAsDataURL(file)
        }
    })
}
