// import * as Const from '../Const'
// import {trace} from '../common/index'
import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Typography, TableHead } from '@material-ui/core'
import { Staff, Filter, FilterSorting } from '../Types'
import * as DataUtil from '../modules/DataUtil'
import { StripedTableRow, StripedTableCell } from './Tables'
import { formatDateTime } from '../modules/Util'
import { HeadCellItemLabel } from './bases/HeadCellItemLabel'
import clsx from 'clsx'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        row: {
            '&:hover': {
                cursor: 'pointer',
            },
        },
        bold: {
            fontWeight: 'bold',
        },
        headerItem: {
            display: 'block',
            width: '100%',
            textAlign: 'center',
        },
        headerCell: {
            cursor: 'pointer',
        },
    }),
)

/**
 * Props
 *
 * @interface FacilityStaffListCellProps
 */
interface FacilityStaffListCellProps {
    data: Staff
    onClickHandler?: (event: React.MouseEvent<unknown, MouseEvent>) => void
}

/**
 * 管理者セル.
 * @export
 * @param {FacilityStaffListCellProps} props
 * @returns セルのJSX 要素
 */
export function FacilityStaffListCell(props: FacilityStaffListCellProps) {
    const classes = useStyles()
    const handleClick = (event: React.MouseEvent<unknown, MouseEvent>) => {
        if (props.onClickHandler !== undefined) {
            props.onClickHandler(event)
        }
    }
    return (
        <StripedTableRow className={classes.row} onClick={(event) => handleClick(event)}>
            <StripedTableCell align="center">
                <Typography variant="h5">{props.data.staff_id}</Typography>
            </StripedTableCell>
            <StripedTableCell align="center">
                <Typography variant="h5">{DataUtil.roleText(props.data.role_id)}</Typography>
            </StripedTableCell>
            <StripedTableCell align="center">
                <Typography className={classes.bold} variant="h5">
                    {props.data.staff_name}
                </Typography>
            </StripedTableCell>
            <StripedTableCell align="center">
                <Typography variant="h5">{formatDateTime(props.data.created_at)}</Typography>
            </StripedTableCell>
            <StripedTableCell align="center">
                <Typography variant="h5">{formatDateTime(props.data.updated_at)}</Typography>
            </StripedTableCell>
        </StripedTableRow>
    )
}

/**
 * Props
 *
 * @interface HeaderCellProps
 */
type HeaderCellProps = {
    onRequestSort: (newSorting: FilterSorting) => void
    filter: Filter
}
/**
 * 管理者セルヘッダ
 *
 * @export
 * @param {HeaderCellProps} props
 * @returns
 */
export function FacilityStaffListHeaderCell(props: HeaderCellProps) {
    const classes = useStyles()

    return (
        <TableHead>
            <StripedTableRow>
                <StripedTableCell width="20%" align="center" className={clsx(classes.headerCell)}>
                    <HeadCellItemLabel label="職員ID" field={'staff_id'} filter={props.filter} onRequestSort={props.onRequestSort} />
                </StripedTableCell>
                <StripedTableCell width="20%" align="center" className={clsx(classes.headerCell)}>
                    <HeadCellItemLabel label="権限" field={'role_id'} filter={props.filter} onRequestSort={props.onRequestSort} />
                </StripedTableCell>
                <StripedTableCell width="20%" align="center" className={clsx(classes.headerCell)}>
                    <HeadCellItemLabel label="氏名" field={'staff_name'} filter={props.filter} onRequestSort={props.onRequestSort} />
                </StripedTableCell>
                <StripedTableCell width="20%" align="center" className={clsx(classes.headerCell)}>
                    <HeadCellItemLabel label="作成日時" field={'created_at'} filter={props.filter} onRequestSort={props.onRequestSort} />
                </StripedTableCell>
                <StripedTableCell align="center" className={clsx(classes.headerCell)}>
                    <HeadCellItemLabel label="更新日時" field={'updated_at'} filter={props.filter} onRequestSort={props.onRequestSort} />
                </StripedTableCell>
            </StripedTableRow>
        </TableHead>
    )
}
