import React from 'react'
import clsx from 'clsx'
import * as WS from 'modules/WebSocket'
// Material-UI
import { Typography, Box, Paper, Grid, FormControl, RadioGroup, FormControlLabel, Radio, Container } from '@material-ui/core'

import TitleBar from '../components/TitleBar'
import { MainButton } from './Buttons'
import { Examination, ExaminationAnswerMethod, LessonStatus, LessonType, User } from 'Types'
import Video360 from './Video360'
import VideoChat from './VideoChat'
import VideoControlPanel from './VideoControlPanel'
import * as VideoControlPanelTask from './VideoControlPanel'
import { useParams } from 'react-router-dom'
import {
    patchLessonExaminationAnswer,
    useLessonById,
    setLessonExaminationAnswer,
    setLessonMessage,
    putLessonCommentAndGrowthRecord,
    setLessonCommentAndRecords,
    setLessonStatus,
} from 'modules/LessonModule'
import { isUndefined } from 'util'
import { useAppDispatch } from 'store'
import { unwrapResult } from '@reduxjs/toolkit'
import UserCell from 'components/UserCell'
import * as LessonLogProvider from 'modules/LessonLogProvider'
import * as DataUtil from 'modules/DataUtil'
import { getDialogContext } from 'modules/DialogProvider'
import { useProfile } from 'modules/AppModule'
import { GreyLabelInputField } from './Inputs'
import { useSnackbar } from 'notistack'
import VideoStateProvider from 'modules/VideoStateProvider'
import { useStyles } from './User/UserLesson/Stylex'

type StaffInstructionProps = {
    onCloseHandler?: () => void
}

export default function UserLesson(props: StaffInstructionProps) {
    const dispatch = useAppDispatch()
    const { showDialog } = React.useContext(getDialogContext())
    const { enqueueSnackbar } = useSnackbar()

    const { lessonId } = useParams()
    const lesson = useLessonById(lessonId)

    // TODO: 一斉指導では、user_id を動的に管理する
    const user = useProfile() as User
    const userId = user?.user_id === undefined ? '' : user?.user_id

    // redirect to list page if id isn't loaded
    if (lessonId && isUndefined(lesson)) {
        handleClose()
    }

    React.useEffect(() => {
        if (lessonId && isUndefined(lesson)) {
            return
        }
        if (lesson?.lesson_type !== LessonType.concurrent) {
            const message = DataUtil.getUserLessonMessage(lesson?.status)
            dispatch(setLessonMessage(message))
        } else {
            dispatch(setLessonMessage(''))
            return
        }

        // レッスンログ
        if (lessonId && lesson?.status === LessonStatus.finish) {
            const name = lessonId
            LessonLogProvider.load(name)
                .then((res) => {
                    dispatch(setLessonMessage('ユーザのレッスンを再生してください'))
                    // 再生はマニュアル操作で行う為、ここでは再生開始しない. (VideoControlPanelで処理する)
                })
                .catch((err) => {
                    console.log('LessonLog:', err)
                    dispatch(setLessonMessage('レッスンログがありません'))
                    // レッスンログが見つからない.
                    showDialog({
                        title: '',
                        message: 'このレッスンは正常終了していないため、レッスン状況を再現することができません。\n視聴した動画の確認のみ可能です。',
                        items: [{ label: 'OK' }],
                    })
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lessonId])

    console.log(`Lesson:${lessonId}`, lesson)

    const [commentOfParent, setCommentOfParent] = React.useState(
        () => lesson?.lesson_comments?.find((lessonComment) => lessonComment.user_id === userId)?.comment_of_parent ?? '',
    )

    let examinations: Examination[] & { answer?: number } = lesson?.training?.examinations === undefined ? [] : lesson?.training?.examinations
    const examinationAnswers = lesson?.examination_answers === undefined ? [] : lesson?.examination_answers

    // Add answer's property if it's existed
    if (examinations.length && examinationAnswers.length) {
        examinations = examinations.map((examination) => {
            for (const examinationAnswer of examinationAnswers) {
                if (examinationAnswer.user_id === userId && examinationAnswer.examination_id === examination.id) {
                    return {
                        ...examination,
                        answer: examinationAnswer.answer,
                    }
                }
            }
            return examination
        })
    }

    // 映像の説明.
    function getVideoTitleByStatus() {
        switch (lesson?.status) {
            case LessonStatus.finish:
                return 'ユーザが視聴した映像'
            case LessonStatus.progressing:
                return 'ユーザが視聴中の映像'
            case LessonStatus.request:
                return 'ユーザが予約中の映像'
            case LessonStatus.reserved:
                return 'ユーザが予約済みの映像'
        }
        return 'ユーザが視聴中の映像'
    }

    function getVideoControlMode() {
        switch (lesson?.status) {
            case LessonStatus.finish:
                return VideoControlPanelTask.VideoControlMode.replay
            case LessonStatus.progressing:
                return VideoControlPanelTask.VideoControlMode.remote
            case LessonStatus.request:
                return VideoControlPanelTask.VideoControlMode.remote
            case LessonStatus.reserved:
                return VideoControlPanelTask.VideoControlMode.remote
        }
        return VideoControlPanelTask.VideoControlMode.local
    }

    const handleSetLessonStatus = (status: LessonStatus) => {
        dispatch(
            setLessonStatus({
                lesson_id: lessonId,
                status: status,
            }),
        )
    }

    const classes = useStyles()
    return (
        <React.Fragment>
            <Grid container spacing={1} className={classes.root}>
                <Grid item xs={12}>
                    <UserCell />
                </Grid>
                <Grid item xs={12} className={classes.video_container}>
                    <Box className={classes.view}>
                        <Paper>
                            <Typography variant="h5" className={classes.header_title}>
                                {getVideoTitleByStatus()}
                            </Typography>

                            <Box className={clsx(classes.box, classes.header)}>
                                <Box className={classes.title_label}>
                                    <Typography variant="subtitle1">title</Typography>
                                </Box>
                                <Box className={classes.title_video}>
                                    <Typography variant="subtitle1">{lesson?.training?.training_name}</Typography>
                                </Box>
                            </Box>

                            <Box className={classes.video}>
                                <Video360 height="325" url={lesson?.training?.content_url} />
                            </Box>
                            <Box className={classes.control_panel}>
                                <VideoStateProvider>
                                    <VideoControlPanel
                                        mode={getVideoControlMode()}
                                        lesson={lesson}
                                        from="user"
                                        name={user?.parent_1}
                                        client_id={user?.user_id}
                                        onSetLessonStatus={handleSetLessonStatus}
                                    />
                                </VideoStateProvider>
                            </Box>
                        </Paper>
                        {lesson?.lesson_type === LessonType.individual ? (
                            <Paper className={classes.right_view}>
                                <Typography variant="h5" className={classes.header_title}>
                                    オンライン指導
                                </Typography>
                                <Paper className={classes.video_on_right}>
                                    <Box className={classes.videochat}>
                                        <VideoChat lesson_id={lesson?.lesson_id} />{' '}
                                    </Box>
                                </Paper>
                            </Paper>
                        ) : null}
                    </Box>
                </Grid>
                <Grid item xs={12} className={clsx(classes.growth_record, lesson?.lesson_type === LessonType.concurrent && classes.disabled)}>
                    <TitleBar bgBlack label="テスト" />
                    <Box className={clsx(classes.box, classes.thead)}>
                        <Box className={classes.time_header}>
                            <Typography variant="subtitle1">時間</Typography>
                        </Box>
                        <Box className={classes.question_header}>
                            <Typography variant="subtitle1">設問</Typography>
                        </Box>
                        <Box className={classes.correct_answer_header}>
                            <Typography variant="subtitle1">正解</Typography>
                        </Box>
                        <Box className={classes.user_response_header}>
                            <Typography variant="subtitle1">ユーザの回答</Typography>
                        </Box>
                    </Box>
                    {examinations.map((row, index) => (
                        <UserLessonCell
                            key={index}
                            lessonId={lessonId}
                            lessonType={lesson?.lesson_type}
                            userId={userId}
                            row={row}
                            bgColor={index % 2 === 0 ? classes.boxBgColor1 : classes.boxBgColor2}
                        />
                    ))}
                </Grid>

                {/* Comment fields */}
                <Grid item xs={12} className={classes.lessonComment}>
                    <GreyLabelInputField
                        fullWidth
                        label="本⽇の受講状況"
                        name="comment_of_parent"
                        placeholder="A先生のことは大好きなので今日の受講は昨日から楽しみにしていて、昨日は遅くまで起きていました。少し寝不足です。本日もオンラインで顔が見えると興奮してしまい、始めるまでの時間が多くかかってしまいました。Oculusをつけるとスイッチが入り、集中していてしっかりと質問にも答えていました。ゲーム感覚で学ぶことが本人の意欲につながっています。子どものペースに合わせてくれて感謝しています。次回も楽しみにしています。"
                        value={commentOfParent}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCommentOfParent(e.target.value)}
                        multiline
                    />
                </Grid>
            </Grid>
            <Container component="main" maxWidth="xs" className={classes.footer}>
                <Footer></Footer>
            </Container>
        </React.Fragment>
    )

    function handleClose() {
        VideoControlPanelTask.onExit()
        WS.stop()
        if (!isUndefined(props.onCloseHandler)) {
            props.onCloseHandler()
        }
    }

    function handleSubmit(e: React.FormEvent) {
        e.preventDefault()
        const param = {
            lesson_id: lessonId,
            user_id: userId,
            comment_of_parent: commentOfParent,
        }
        dispatch(putLessonCommentAndGrowthRecord({ lesson_id: lessonId, item: param }))
            .then((res) => {
                if (res.payload === undefined) {
                    showNotice('登録に失敗しました', 'error')
                    return
                }
                showNotice('登録しました', 'info')
                dispatch(setLessonCommentAndRecords({ lesson_id: lessonId, item: param }))
            })
            .catch((error) => {
                showNotice('登録に失敗しました', 'error')
            })
    }

    function showNotice(message: string, variant: any) {
        enqueueSnackbar(message, { variant })
    }

    // Footer
    function Footer() {
        return (
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    {/* 更新. */}
                    <MainButton onClick={handleSubmit}>登録</MainButton>
                </Grid>
                <Grid item xs={6}>
                    {/* 元に戻す. */}
                    <MainButton onClick={handleClose}>戻る</MainButton>
                </Grid>
            </Grid>
        )
    }
}

// UserLessonCell
type UserLessonCellProps = {
    lessonId: any
    lessonType: LessonType | undefined
    userId: any
    row: Examination & { answer?: number }
    bgColor: string
}

function UserLessonCell(props: UserLessonCellProps) {
    const dispatch = useAppDispatch()

    const [value, setValue] = React.useState(props.row.answer || 0)

    // submit answer
    // lesson_id: lesson id
    // examination_id: examination.id
    // answer: option.value selected by user
    // method: portal (const)
    const handleSubmit = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (props.lessonId === undefined || props.row.id === undefined || props.lessonType === LessonType.concurrent) {
            return
        }
        const value = +(event.target as HTMLInputElement).value
        setValue(value)
        dispatch(
            patchLessonExaminationAnswer({
                lesson_id: props.lessonId as string,
                user_id: props.userId as string,
                examination_id: props.row.id,
                item: { answer: value, method: ExaminationAnswerMethod.portal },
            }),
        )
            .then(unwrapResult)
            .then((res) => {
                console.log(res)
                if (isUndefined(props.lessonId) || isUndefined(props.row.id)) return
                dispatch(
                    setLessonExaminationAnswer({
                        lesson_id: props.lessonId as string,
                        item: { user_id: props.userId, examination_id: props.row.id, answer: value, method: ExaminationAnswerMethod.portal },
                    }),
                )
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const classes = useStyles()
    return (
        <Box className={clsx(classes.box, classes.cell, props.bgColor)}>
            <Box className={classes.time_row}>
                <Typography variant="subtitle1">{props.row.time}</Typography>
            </Box>
            <Box className={classes.question_row}>
                <Typography variant="subtitle1">{props.row.question}</Typography>
            </Box>
            <Box className={classes.correct_answer_row}>
                <Typography variant="subtitle1">{props.row.correct}</Typography>
            </Box>
            <Box className={classes.user_response_row}>
                <FormControl className={classes.radioForm} component="fieldset">
                    <RadioGroup aria-label="response" name="response" value={value} onChange={handleSubmit}>
                        {props.row.options.map((option) => {
                            return <FormControlLabel className={classes.radio} value={option.value} control={<Radio />} label={option.label} />
                        })}
                    </RadioGroup>
                </FormControl>
            </Box>
        </Box>
    )
}
