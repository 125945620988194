import * as Const from '../Const'
import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { red, purple, blue, teal, green } from '@material-ui/core/colors'
import clsx from 'clsx'
import { Grid, Paper } from '@material-ui/core'
import { isUndefined } from 'util'
// スタイルを適用する
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        level: {
            textAlign: 'center',
            width: 30,
            height: 30,
            lineHeight: '30px',
            backgroundColor: '#000000',
            float: 'left',
            fontSize: '10px',
            fontWeight: 'bold',
            userSelect: 'none',
            color: '#B6B6B6',
            borderRadius: 0,
        },
        bigLevel: {
            fontSize: 16,
            width: 30,
            height: 30,
            lineHeight: '30px',
            marginLeft: 0,
            marginRight: 10,
            borderRadius: 'unset',
        },
        levelOff: { backgroundColor: '#ffffff' },
        levelC1: { color: '#fff', backgroundColor: green[200] },
        levelC2: { color: '#fff', backgroundColor: teal[200] },
        levelC3: { color: '#fff', backgroundColor: blue[200] },
        levelC4: { color: '#fff', backgroundColor: purple[200] },
        levelC5: { color: '#fff', backgroundColor: red[200] },
    }),
)

// プロパティ.
// <LevelBar min=1 max=3 />
type LevelProps = {
    readonly?: boolean
    min: number
    max: number
    maxCount?: number
    onChangeHandler?: (levels: number[]) => void
    bigLevel?: boolean
}
export default function LevelBar2(props: LevelProps) {
    const classes = useStyles()
    const [readonly] = React.useState<boolean>(props.readonly !== undefined ? props.readonly : false)
    const [minValue, setMinValue] = React.useState<number>(props.min)
    const [maxValue, setMaxValue] = React.useState<number>(props.max)
    const [maxCount] = React.useState<number>(props.maxCount !== undefined ? props.maxCount : Const.Setting.LEVEL_MAX)
    const [start, setStart] = React.useState<number>(0)
    const [end, setEnd] = React.useState<number>(0)

    const handleMouseDown = (event: React.MouseEvent, value: number) => {
        event.preventDefault()
        // trace('mouse down at ' + value)
        setStart(value)
        setEnd(value)
        setMinValue(value)
        setMaxValue(value)
    }
    const handleMouseUp = (event: React.MouseEvent, value: number) => {
        event.preventDefault()
        if (start > 0) {
            // trace('mouse up   at ' + value)
            // if ( value > start ) {
            //     setStart( start );
            //     setEnd( value );
            // }
        }
        setStart(0)
        setEnd(0)
    }
    const handleMouseOver = (event: React.MouseEvent, value: number) => {
        event.preventDefault()
        if (start > 0 && end > 0) {
            // trace('mouse move at ' + value)
            setEnd(value)
            const min = Math.min(start, value)
            const max = Math.max(start, value)
            setMinValue(min)
            setMaxValue(max)
            // trace(min + '-' + max)
            if (!isUndefined(props.onChangeHandler)) {
                props.onChangeHandler([min, max])
            }
        }
    }
    const handleOnClick = (event: any, value: number) => {
        event.preventDefault()
        if (!isUndefined(props.onChangeHandler)) {
            props.onChangeHandler([value, value])
        }
    }
    // 表示内容。
    var list = []
    const levelBarItems: { level: number; style: string }[] = [
        { level: 1, style: classes.levelC1 },
        { level: 2, style: classes.levelC1 },
        { level: 3, style: classes.levelC2 },
        { level: 4, style: classes.levelC2 },
        { level: 5, style: classes.levelC3 },
        { level: 6, style: classes.levelC3 },
        { level: 7, style: classes.levelC4 },
        { level: 8, style: classes.levelC4 },
        { level: 9, style: classes.levelC5 },
        { level: 10, style: classes.levelC5 },
        { level: 11, style: classes.levelC1 },
        { level: 12, style: classes.levelC1 },
        { level: 13, style: classes.levelC2 },
        { level: 14, style: classes.levelC2 },
        { level: 15, style: classes.levelC3 },
        { level: 16, style: classes.levelC3 },
        { level: 17, style: classes.levelC4 },
        { level: 18, style: classes.levelC4 },
        { level: 19, style: classes.levelC5 },
        { level: 20, style: classes.levelC5 },
    ]
    const data = levelBarItems.filter((item) => {
        return item.level <= maxCount
    })
    for (var i in data) {
        const value = data[i].level
        const style =
            value >= minValue && value <= maxValue
                ? clsx(classes.level, props.bigLevel && classes.bigLevel, data[i].style)
                : clsx(classes.level, props.bigLevel && classes.bigLevel, classes.levelOff)

        if (readonly) {
            list.push(
                <Paper
                    key={i}
                    variant="outlined"
                    className={style}
                    onSelect={() => {
                        return false
                    }}
                >
                    {data[i].level}
                </Paper>,
            )
        } else {
            list.push(
                <Paper
                    key={i}
                    variant="outlined"
                    className={style}
                    onMouseDown={(event) => handleMouseDown(event, value)}
                    onMouseOver={(event) => handleMouseOver(event, value)}
                    onMouseUp={(event) => handleMouseUp(event, value)}
                    onClick={(event) => handleOnClick(event, value)}
                    onSelect={() => {
                        return false
                    }}
                >
                    {data[i].level}
                </Paper>,
            )
        }
    }

    // レンダリング.
    return (
        <React.Fragment>
            <Grid container className={classes.root}>
                {list}
            </Grid>
        </React.Fragment>
    )
}
