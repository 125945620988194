import * as AppConfig from '../config'

import axios, { AxiosError } from 'axios'
import * as AuthUtil from 'modules/AuthUtil'
import store from 'store'
import { setNeedLogin } from './AppModule'

axios.defaults.baseURL = AppConfig.APP_API_BASE_URL

// axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'
// axios.defaults.headers.post['Access-Control-Request-Method'] = 'GET,POST,PUT,DELETE,PATCH,HEAD'
// axios.defaults.headers.post['Access-Control-Request-Headers'] = 'Content-Type,authorization, x-api-key'
// axios.defaults.headers.get['Access-Control-Request-Headers'] = 'Content-Type,authorization, x-api-key'

// axios.defaults.headers.common['Content-Type'] = 'application/json; charset=utf-8'
axios.defaults.headers.post['Content-Type'] = 'application/json; charset=utf-8'
axios.defaults.headers.put['Content-Type'] = 'application/json; charset=utf-8'
axios.defaults.headers.patch['Content-Type'] = 'application/json; charset=utf-8'
axios.defaults.headers.common['x-api-key'] = AppConfig.APP_API_KEY

axios.defaults.responseType = 'json'

type ApiResponse = {
    isSuccess: boolean // 成功したか.
    error?: AxiosError // エラー発生時.
    data?: any // 返却データ
    result?: {
        status_code: number
        error_message: string
    }
}

// FIXME::
// インターセプタを利用してエラーをハンドリングする.
// ただし、現時点では、 store.dispatch で TypeError: Cannot read property 'reducer' of undefined が発生するためペンディング
const client = axios.create()

// // 成功の場合は何もしない
// const onSuccess = (response: AxiosResponse) => response

// // エラーの場合はストアにエラーをセットする
// const onError = (err: AxiosError) => {
//     console.log( "onError", err.toJSON() );
//     //   const { status, data } = err.response;
//     //   store.dispatch(setError( err.message ));
//     // エラーをスローしたい場合は...
//     // Promise.reject(err);
// }
// client.interceptors.response.use(onSuccess, onError)

/**
 * GET Request
 *
 * @export
 * @param {string} path
 * @returns {Promise<ApiResponse>}
 */
export async function get(path: string): Promise<ApiResponse> {
    const etag = store.getState().imageCache.enableTag ? store.getState().imageCache.etag : 0

    // console.log(`get ${path}`)
    let _error
    for (let i = 0; i < 2; i++) {
        try {
            path = path.indexOf('?') !== -1 ? `${path}&` : `${path}?`
            const response = await _get(path + `etag=${etag}`)
            return createSuccessPromise(response.data)
        } catch (error) {
            _error = error
            console.log('ERROR', error.response)
            if (error.response.status !== 401) {
                break
            }
            await tokenRefresh()
        }
    }
    console.log('@Error', _error.response)
    return createFailurePromise(_error.response)
}

async function _get(path: string): Promise<any> {
    return new Promise((resolve, reject) => {
        const token = AuthUtil.getAccessToken()
        // console.log( "token", token );
        client
            .get(path, {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
                // data: {}, // axios は 通常 GETで Content-Type は送信しないため、付与する。（サーバがContent-Type 必須などへの対策）
            })
            .then((response) => {
                // console.log( "_get", response )
                resolve(response)
            })
            .catch((error) => {
                // console.log( "_get", error.toJSON() )
                reject(error)
            })
    })
}

/**
 * PUT Request
 * idempotent
 * @export
 * @param {string} path
 * @param {object} [params={}]
 * @returns {Promise<ApiResponse>}
 */
export async function put(path: string, params: object = {}): Promise<ApiResponse> {
    let _error
    for (let i = 0; i < 3; i++) {
        try {
            console.log(`${path}:${i}`)
            const response = await _put(path, params)
            console.log('@Response', response)
            return createSuccessPromise(response.data)
        } catch (error) {
            _error = error
            console.log('ERROR', error.response)
            if (error.response.status !== 401) {
                break
            }
            await tokenRefresh()
        }
    }
    console.log('@Error', _error.response)
    return createFailurePromise(_error.response)
}

async function _put(path: string, params: any): Promise<any> {
    return new Promise((resolve, reject) => {
        const token = AuthUtil.getAccessToken()
        const json = JSON.stringify(params)
        client
            .put(path, json, {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            })
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

/**
 * POST Request
 * non-idempotent
 * @export
 * @param {string} path
 * @param {object} [params={}]
 * @returns {Promise<ApiResponse>}
 */
export async function post(path: string, params: object = {}): Promise<ApiResponse> {
    let _error
    for (let i = 0; i < 3; i++) {
        try {
            console.log(`${path}:${i}`)
            const response = await _post(path, params)
            console.log('@Response', response)
            return createSuccessPromise(response.data)
        } catch (error) {
            _error = error
            console.log('ERROR', error.response)
            if (error.response.status !== 401) {
                break
            }
            await tokenRefresh()
        }
    }
    console.log('@Error', _error.response)
    return createFailurePromise(_error.response)
}

async function _post(path: string, params: any): Promise<any> {
    return new Promise((resolve, reject) => {
        const token = AuthUtil.getAccessToken()
        const json = JSON.stringify(params)
        client
            .post(path, json, {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            })
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

/**
 * PATCH Request
 *
 * @export
 * @param {string} path
 * @param {object} [params={}]
 * @returns {Promise<ApiResponse>}
 */
export async function patch(path: string, params: object = {}): Promise<ApiResponse> {
    let _error
    for (let i = 0; i < 3; i++) {
        try {
            console.log(`${path}:${i}`)
            const response = await _patch(path, params)
            console.log('@Response', response)
            return createSuccessPromise(response.data)
        } catch (error) {
            _error = error
            console.log('ERROR', error.response)
            if (error.response.status !== 401) {
                break
            }
            await tokenRefresh()
        }
    }
    console.log('@Error', _error.response)
    return createFailurePromise(_error.response)
}

async function _patch(path: string, params: any): Promise<any> {
    return new Promise((resolve, reject) => {
        const token = AuthUtil.getAccessToken()
        const json = JSON.stringify(params)
        client
            .patch(path, json, {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            })
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

/**
 * DELETE Request
 * idempotent ただし、リソースが無くなっている場合は 404
 * @export
 * @param {string} path
 * @param {object} [params={}]
 * @returns {Promise<ApiResponse>}
 */
export async function delete_(path: string): Promise<ApiResponse> {
    let _error
    for (let i = 0; i < 3; i++) {
        try {
            console.log(`${path}:${i}`)
            const response = await _delete(path)
            console.log('@Response', response)
            return createSuccessPromise(response.data)
        } catch (error) {
            _error = error
            console.log('ERROR', error.response)
            if (error.response.status !== 401) {
                break
            }
            await tokenRefresh()
        }
    }
    console.log('@Error', _error.response)
    return createFailurePromise(_error.response)
}
async function _delete(path: string): Promise<any> {
    return new Promise((resolve, reject) => {
        const token = AuthUtil.getAccessToken()
        client
            .delete(path, {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            })
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

/**
 * 通信成功時の Promise
 *
 * @param {*} data
 * @returns {Promise<ApiResponse>}
 */
function createSuccessPromise(data: any): Promise<ApiResponse> {
    // console.log( "API Response:", { ...data, isSuccess: true } )
    return Promise.resolve<ApiResponse>({ ...data, isSuccess: true })
}

/**
 * 通信失敗時の Promise
 *
 * @param {*} data
 * @returns {Promise<ApiResponse>}
 */
function createFailurePromise(error: any): Promise<ApiResponse> {
    console.error('AxiosError:', error)
    return Promise.reject(error)
    // return Promise.resolve<ApiResponse>({ ...error, isSuccess: false })
}

async function tokenRefresh() {
    // console.log('リフレッシュトークン処理')
    return new Promise(async (resolve, reject) => {
        try {
            const base64 = AuthUtil.getClientKey()
            const refresh_token = AuthUtil.getRefreshToken()
            const config = {
                headers: {
                    Authorization: 'Basic ' + base64,
                },
            }
            const path = '/auth/token' + AuthUtil.getCustomExpire()
            await client
                .post(
                    path,
                    JSON.stringify({
                        grant_type: 'refresh_token',
                        refresh_token: refresh_token,
                    }),
                    config,
                )
                .then((response) => {
                    // console.log('RefreshToken', response)
                    AuthUtil.setToken(JSON.stringify(response.data.data))
                    return resolve()
                })
                .catch((error) => {
                    console.log('RefreshToken Error', error.response)

                    // リフレッシュトークンの有効期間をすぎた時は、強制的に再ログイン.
                    store.dispatch(setNeedLogin(true))

                    return reject()
                })
        } catch (error) {
            console.log('RefreshToken Error', error)
            reject()
        }
    })
}
