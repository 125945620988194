import React from 'react'

import { Box, Avatar, Typography } from '@material-ui/core'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

import { MainButton } from '../components/Buttons'
import { isUndefined, isNull } from 'util'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        bigAvatar: {
            width: 60,
            height: 60,
        },
        box: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        },

        avatar_base: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 120,
            height: 120,
            padding: 0,
            borderRadius: '50%',
            border: '1px solid #888',
            flexShrink: 0,
            margin: '28px 0',
            position: 'relative',
        },
        button_area: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            width: 185,
            margin: '10px 0',
        },
        button: {
            height: 30,
            '& h4': {
                fontSize: 16,
            },
            '& *': {
                cursor: 'pointer',
            },
        },
        avatar: {
            width: 115,
            height: 115,
            flexShrink: 0,
        },
    }),
)

type PreviewInputProps = {
    onChangeHandler?: (event: React.ChangeEvent<HTMLInputElement> | null) => void
    src?: string
}

export default function PreviewInput2(props: PreviewInputProps) {
    const classes = useStyles()
    const [filename, setFilename] = React.useState('')
    const [imageData, setImageData] = React.useState<any>(props.src)
    const fileInput = React.createRef

    const onFileChange = (event: React.ChangeEvent<HTMLInputElement> ) => {
        if (!isUndefined(props.onChangeHandler)) {
            props.onChangeHandler(event)
        }

        const files = event.target.files
        if (files !== null && files.length > 0) {
            var file = files[0]
            var reader = new FileReader()
            reader.onload = (e) => {
                setFilename(file.name)
                if (!isNull(reader.result)) {
                    setImageData(reader.result)
                }
            }
            reader.readAsDataURL(file)
        } else {
            setImageData(null)
            setFilename('')
        }
    }

    let resetButton = null

    let preview = (
        <div className={classes.avatar_base}>
            <Avatar variant="circle" className={classes.avatar} src={imageData} />
        </div>
    )
    if (imageData !== null && imageData !== '' && !isUndefined(imageData)) {
        resetButton = (
            <Box margin="10px 0">
                <MainButton fullWidth variant="outlined" className={classes.button} onClick={resetInput}>
                    リセットする
                </MainButton>
            </Box>
        )
    }
    return (
        <Box className={classes.box}>
            <Typography variant="h5">アイコン</Typography>
            {preview}
            <Box className={classes.button_area}>
                <MainButton fullWidth variant="outlined" className={classes.button}>
                    ファイルを選択する
                    <input
                        style={{
                            opacity: 0,
                            appearance: 'none',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                        }} // デフォルトのスタイルを表示しない.
                        type="file"
                        accept="image/*"
                        ref={fileInput}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            onFileChange(e)
                        }}
                    />
                </MainButton>
                {resetButton}
            </Box>
            <Box style={{ textAlign: 'center', width: '100%', overflow: 'hidden' }}>
                {filename === '' && isUndefined(props.src) ? 'ファイルが選択されていません' : <Typography>{filename}</Typography>}
            </Box>
        </Box>
    )

    function resetInput() {
        // fileInput = React.createRef
        setImageData(null)
        setFilename('')

        if (!isUndefined(props.onChangeHandler)) {
            props.onChangeHandler(null)
        }
    }
}
