import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // 見出し.
        fullname: {
            fontSize: '11px',
            paddingLeft: '10px',
            background:
                'linear-gradient(0deg, #EAEAEA 10%, #FDFDFD 60%, #FFF 80%)',
            width: '100%',
            borderRadius: '50px',
            border: '1px solid #6e6e6e',
            textAlign: 'left',
        },
    }),
)

// Props
type FullnameProps = {
    fullname: string
}

export default function FullnameLabel(props: FullnameProps) {
    const classes = useStyles()

    return (
        <React.Fragment>
            <div className={classes.fullname}>{props.fullname}</div>
        </React.Fragment>
    )
}
