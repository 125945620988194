import React from 'react'
import { OrderBy, SortConfig } from 'Types'

const useSortableData = (items: any, config: SortConfig = null) => {
    const [sortConfig, setSortConfig] = React.useState(config)

    const sortedItems = React.useMemo(() => {
        let sortableItems = [...items]
        if (sortConfig !== null) {
            sortableItems.sort((a, b) => {
                if (sortConfig.id === 'level') {
                    if (a['max_level'] === undefined || b['max_level'] === undefined || a['min_level'] === undefined || b['max_level'] === undefined) return 0
                    if (sortConfig.direction === OrderBy.asc) {
                        if (a['min_level'] < b['min_level']) {
                            return -1
                        }
                        if (a['min_level'] > b['min_level']) {
                            return 1
                        }
                        if (a['max_level'] < b['max_level']) {
                            return -1
                        }
                        if (a['max_level'] > b['max_level']) {
                            return 1
                        }
                    }
                    if (sortConfig.direction === OrderBy.desc) {
                        if (a['max_level'] > b['max_level']) {
                            return -1
                        }
                        if (a['max_level'] < b['max_level']) {
                            return 1
                        }
                        if (a['min_level'] > b['min_level']) {
                            return -1
                        }
                        if (a['min_level'] < b['min_level']) {
                            return 1
                        }
                    }
                    return 0
                }
                if (a[sortConfig.id] < b[sortConfig.id]) {
                    return sortConfig.direction === OrderBy.asc ? -1 : 1
                }
                if (a[sortConfig.id] > b[sortConfig.id]) {
                    return sortConfig.direction === OrderBy.asc ? 1 : -1
                }
                return 0
            })
        }
        return sortableItems
    }, [items, sortConfig])

    const requestSort = (id: string, direction: OrderBy | null = null) => {
        if (direction) {
            setSortConfig({ id, direction })
        } else {
            let direction = OrderBy.asc
            if (sortConfig && sortConfig.id === id && sortConfig.direction === OrderBy.asc) {
                direction = OrderBy.desc
            }
            setSortConfig({ id, direction })
        }
    }

    return { items: sortedItems, requestSort, sortConfig }
}

export default useSortableData
