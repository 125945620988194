// レッスンモジュール
import { isUndefined } from 'util'

import { useSelector } from 'react-redux'
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from '../rootReducer'

import * as ApiClient from './ApiClient'
import { ExaminationAnswerMethod, GrowthRecord, Lesson, LessonStatus, ExaminationAnswer, LessonType } from '../Types'
import { initLessonComment } from './DataUtil'

// ステート定義
type LessonState = {
    // page: string
    // selectedLesson?: Lesson
    lessons: Lesson[]
    // lessonPage: string
    lessonMessage: string | undefined
}

// 非同期アクション.

export type LessonParam = {
    lesson_type: LessonType
    lesson_id?: string
    user_id?: string
    user_ids?: string[]
    course_id: string
    training_id: string
    staff_id: string
    start: string
    end?: string
    status: string
}

export type LessonCommentAndRecordsParam = {
    lesson_id: string
    user_id: string
    comment_prev?: string
    comment_current?: string
    comment_next?: string
    growth_records?: GrowthRecord[]
}

// 全て取得.
export const fetchLessons = createAsyncThunk('lesson/fetchAll', async (arg, thunkAPI) => {
    const response = await ApiClient.get('/lessons')
    return response.data
})
// export const fetchLessonById = createAsyncThunk('staff/fetchById', async (id: string, thunkAPI) => {
//     const response = await ApiClient.get('/lessons/' + id)
//     return response.data
// })
/**
 * 期間指定
 * method: GET
 * path: /lessons/period/:start:/:end
 * params:
 *      arg: {
 *          start: string
 *          end: string
 *      }
 */
export const fetchLessonsByPeriod = createAsyncThunk('lesson/fetchByUserId', async (arg: { start: string; end: string | undefined }, thunkAPI) => {
    let url = `/lessons/period/${arg.start}`
    if (arg.end !== undefined) {
        url = `${url}/${arg.end}`
    }
    const response = await ApiClient.get(url)
    return response.data
})

/**
 * ユーザごと.
 * method: GET
 * path: /lessons/users/:user_id/:start:/:end
 * params:
 *      arg: {
 *          user_id: string
 *          start: string
 *          end: string
 *      }
 */
export const fetchLessonsByUser = createAsyncThunk('lesson/fetchByUser', async (arg: { user_id: string; start: string; end: string }, thunkAPI) => {
    // let url = `/lessons/users/${arg.user_id}/${arg.start}`
    // if (!isUndefined(arg.end)) {
    //     url = `${url}/${arg.end}`
    // }
    const url = `/lessons/users/${arg.user_id}`
    const response = await ApiClient.get(url)
    return response.data
})

/**
 * ユーザごと.ステータス指定.
 * method: GET
 * path: /lessons/users/:user_id/:start:/:end
 * params:
 *      arg: {
 *          user_id: string
 *          start: string
 *          end: string
 *      }
 */
export const fetchLessonByUserWithStatus = createAsyncThunk(
    'lesson/fetchLessonByUserWithStatus',
    async (arg: { user_id: string; status: LessonStatus }, thunkAPI) => {
        const url = `/users/${arg.user_id}/status/${arg.status}`
        const response = await ApiClient.get(url)
        return response.data
    },
)

// レッスンステータス更新.
export const patchLessonStatus = createAsyncThunk('lesson/patchLessonStatus', async (arg: { lesson_id: string; status: LessonStatus }, thunkAPI) => {
    const url = `/lessons/${arg.lesson_id}/status/${arg.status}`
    const response = await ApiClient.patch(url)
    console.log(response)
    return response.data
})

export const patchLessonGrowthRecord = createAsyncThunk(
    'lesson/patchLessonGrowthRecord',
    async (arg: { lesson_id: string; items: GrowthRecord[] }, thunkAPI) => {
        const url = `/lessons/${arg.lesson_id}/records`
        const items = arg.items

        console.log(url, JSON.stringify(items))
        const response = await ApiClient.patch(url, items)
        console.log(response)
        return response.data
    },
)

export const putLessonCommentAndGrowthRecord = createAsyncThunk(
    'lesson/putLessonCommentAndGrowthRecord',
    async (arg: { lesson_id: string; item: LessonCommentAndRecordsParam }, thunkAPI) => {
        const url = `/lessons/${arg.lesson_id}/comment/records`
        const response = await ApiClient.put(url, arg.item)
        console.log(response)
        return response.data
    },
)

// send answer for examination;
export const patchLessonExaminationAnswer = createAsyncThunk(
    'lesson/patchLessonExaminationAnswer',
    async (arg: { lesson_id: string; user_id: string; examination_id: string; item: { answer: number; method: ExaminationAnswerMethod } }, thunkAPI) => {
        const url = `/lessons/${arg.lesson_id}/examinations/${arg.examination_id}/answer`
        const items = { ...arg.item, user_id: arg.user_id }
        console.log(url, JSON.stringify(items))
        const response = await ApiClient.patch(url, items)
        console.log(response)
        return response.data
    },
)

export const fetchLessonMeeting = createAsyncThunk('lesson/meeting', async (lesson_id: string, thunkAPI) => {
    const url = `/lessons/${lesson_id}/meeting`
    const response = await ApiClient.get(url)
    return response.data
})
export const fetchLessonMeetingNew = createAsyncThunk('lesson/meeting/new', async (lesson_id: string, thunkAPI) => {
    const url = `/lessons/${lesson_id}/meeting/new`
    const response = await ApiClient.get(url)
    return response.data
})

/**
 * 作成
 * method: POST
 * path: /lesson
 */
export const createLesson = createAsyncThunk('lesson/create', async (item: LessonParam, thunkAPI) => {
    const response = await ApiClient.post('/lessons', item)
    return response.data
})

// 更新.
// method: PUT
// path: /lesson/:id
// @params lesson: 更新する内容
export const updateLesson = createAsyncThunk('lesson/update', async (item: LessonParam, thunkAPI) => {
    const url = `/lessons/${item.lesson_id}`
    const response = await ApiClient.put(url, item)
    return response.data
})

// 削除
// @path: /lesson/:id
export const deleteLesson = createAsyncThunk('lesson/delete', async (lesson_id: string, thunkAPI) => {
    const response = await ApiClient.delete_(`/lessons/${lesson_id}`)
    return response.data
})

/**
 * Update status
 * @method PATCH
 * @param lesson
 */
export const updateLessonStatus = createAsyncThunk('lesson/update-status', async (item: { lesson_id: string; status: string }, thunkAPI) => {
    const url = `/lessons/${item.lesson_id}/status/${item.status}`
    const response = await ApiClient.patch(url)
    return response.data
})

/**
 * Get Lesson Logs
 * @method GET
 * @param lesson_Id
 */
export const getLessonLogs = createAsyncThunk('lesson/getLessonLogs', async (lesson_id: string, thunkAPI) => {
    const url = `/lessons/${lesson_id}/log`
    const response = await ApiClient.get(url)
    return response.data
})

// ステート初期値.
const initialState: LessonState = {
    // page: 'list',
    // selectedLesson: undefined,
    lessons: [],
    // lessonPage: 'list',
    lessonMessage: undefined,
}

// Slice を作成.
const LessonModule = createSlice({
    name: 'lesson',
    initialState,
    reducers: {
        // コンテンツページ変更.
        // changeLessonPage(state: LessonState, action: PayloadAction<string>) {
        //     state.page = action.payload
        // },
        // setSelectedLesson(state: LessonState, action: PayloadAction<Lesson | undefined>) {
        //     state.selectedLesson = action.payload
        // },
        // editLesson(state: LessonState, action: PayloadAction<Lesson>) {
        //     state.selectedLesson = action.payload
        //     state.page = 'edit'
        // },
        setLessons(state: LessonState, action: PayloadAction<Lesson[] | []>) {
            state.lessons = action.payload
        },
        // Set examination answer into lessons
        setLessonExaminationAnswer(state: LessonState, action: PayloadAction<{ lesson_id: string; item: ExaminationAnswer }>) {
            const { lesson_id, item } = action.payload

            let newLessons = [...state.lessons]
            newLessons = newLessons.map((lesson) => {
                if (lesson.lesson_id !== lesson_id) return lesson
                let newExaminationAnswers = isUndefined(lesson.examination_answers) ? [] : [...lesson.examination_answers]
                const examinationAnswerIndex = newExaminationAnswers.findIndex(
                    (examinationAnswer) => examinationAnswer.examination_id === item.examination_id && examinationAnswer.user_id === item.user_id,
                )
                if (examinationAnswerIndex > -1) {
                    newExaminationAnswers[examinationAnswerIndex] = item
                } else {
                    newExaminationAnswers.push(item)
                }
                return {
                    ...lesson,
                    examination_answers: newExaminationAnswers,
                }
            })

            state.lessons = newLessons
        },
        // Set lesson comments and growth records into lessons
        setLessonCommentAndRecords(state: LessonState, action: PayloadAction<{ lesson_id: string; item: LessonCommentAndRecordsParam }>) {
            const { lesson_id, item } = action.payload

            let records: any[] | undefined = undefined
            if (item.growth_records) {
                records = item.growth_records
                delete item.growth_records
            }
            let newLessons = [...state.lessons]
            newLessons = newLessons.map((lesson) => {
                if (lesson.lesson_id !== lesson_id) return lesson
                // Update lesson comment
                let newLessonComments = isUndefined(lesson.lesson_comments) ? [] : [...lesson.lesson_comments]
                const lessonCommentIndex = newLessonComments.findIndex(
                    (lessonComment) => lessonComment.lesson_id === item.lesson_id && lessonComment.user_id === item.user_id,
                )
                const newItem = { ...initLessonComment(), ...item }
                if (lessonCommentIndex > -1) {
                    newLessonComments[lessonCommentIndex] = newItem
                } else {
                    newLessonComments.push(newItem)
                }
                // Update growth records
                let newGrowthRecords: any[] = isUndefined(lesson.growth_records) ? [] : [...lesson.growth_records]
                if (records) {
                    newGrowthRecords = newGrowthRecords.filter(
                        (newGrowthRecord) => newGrowthRecord.lesson_id !== lesson_id && newGrowthRecord.user_id !== item.user_id,
                    )
                    newGrowthRecords = [...newGrowthRecords, ...records]
                }
                return {
                    ...lesson,
                    lesson_comments: newLessonComments,
                    growth_records: newGrowthRecords,
                }
            })

            state.lessons = newLessons
        },
        setLessonMessage(state: LessonState, action: PayloadAction<string | undefined>) {
            state.lessonMessage = action.payload
        },
        setLessonStatus(state: LessonState, action: PayloadAction<{ lesson_id: string; status: LessonStatus }>) {
            const { lesson_id, status } = action.payload
            state.lessons = state.lessons.map((lesson) => {
                if (lesson.lesson_id !== lesson_id) return lesson
                return {
                    ...lesson,
                    status: status,
                }
            })
        },
    },
    extraReducers: (builder) => {
        // fetch
        builder.addCase(fetchLessons.fulfilled, (state, action) => {
            // console.log( 'fulfilled' );
            return { ...state, lessons: action.payload }
        })
        builder.addCase(fetchLessons.pending, (state, action) => {
            // console.log('pending')
        })
        builder.addCase(fetchLessons.rejected, (state, action) => {
            // console.log('rejected')
        })
        // fetch by id
        // builder.addCase(fetchLessonById.fulfilled, (state, action) => {
        //     // console.log( 'fulfilled' );
        //     // return { ...state, lessons: action.payload }
        //     console.log('fetchLessonById: ', action.payload)
        // })
        // builder.addCase(fetchLessonById.pending, (state, action) => {
        //     console.log('pending')
        // })
        // builder.addCase(fetchLessonById.rejected, (state, action) => {
        //     console.log('rejected')
        // })
        // fetch by id
        builder.addCase(fetchLessonsByPeriod.fulfilled, (state, action) => {
            // console.log('fulfilled')
            return { ...state, lessons: action.payload }
        })
        builder.addCase(fetchLessonsByPeriod.pending, (state, action) => {
            // console.log('pending')
        })
        builder.addCase(fetchLessonsByPeriod.rejected, (state, action) => {
            // console.log('rejected')
        })
        // fetch by id
        builder.addCase(fetchLessonsByUser.fulfilled, (state, action) => {
            // console.log('fulfilled###', action.payload)
            // アイコンを保持しない.
            const lessons = action.payload.map((lesson: Lesson) => {
                const user = lesson.user ? { ...lesson.user, icon: undefined } : {}
                const staff = lesson.staff ? { ...lesson.staff, icon: undefined } : {}
                return { ...lesson, user: user, staff: staff }
            })
            return { ...state, lessons: lessons }
        })
        builder.addCase(fetchLessonsByUser.pending, (state, action) => {
            // console.log('pending')
        })
        builder.addCase(fetchLessonsByUser.rejected, (state, action) => {
            // console.log('rejected')
        })
        // add
        builder.addCase(createLesson.fulfilled, (state, action) => {
            // console.log('createLesson.fulfilled:', action.payload)
            // const lessons = state.lessons.filter(
            //     (t) => t.lesson_id !== action.payload.item.lesson_id,
            // )
            // state.lessons = [...lessons, action.payload.item]
            // state.page = 'list'
        })
        builder.addCase(createLesson.pending, (state, action) => {
            // console.log('pending')
        })
        builder.addCase(createLesson.rejected, (state, action) => {
            // console.log('rejected')
        })
        // update
        builder.addCase(updateLesson.fulfilled, (state, action) => {
            console.log('updateLesson.fulfilled:', action.payload)
            // return {　...state,　lessons: action.payload　}
            // let lessons = state.lessons.filter(
            //     (t) => t.lesson_id !== action.payload.item.lesson_id,
            // )
            // state.lessons = [...lessons, action.payload.item]
            // state.page = 'list'
        })
        builder.addCase(updateLesson.pending, (state, action) => {
            console.log('pending')
        })
        builder.addCase(updateLesson.rejected, (state, action) => {
            console.log('rejected')
        })
        // delete
        builder.addCase(deleteLesson.fulfilled, (state, action) => {
            console.log('deleteLesson.fulfilled')
            // console.log(action.payload)
            // state.lessons = state.lessons.filter(
            //     (t) => t.lesson_id !== action.payload.id,
            // )
            // state.page = 'list'
        })
        builder.addCase(deleteLesson.pending, (state, action) => {
            console.log('pending')
        })
        builder.addCase(deleteLesson.rejected, (state, action) => {
            console.log('rejected')
        })
        // update status
        builder.addCase(updateLessonStatus.fulfilled, (state, action) => {
            console.log('fulfilled', action.payload)
        })
        builder.addCase(updateLessonStatus.pending, (state, action) => {
            console.log('pending')
        })
        builder.addCase(updateLessonStatus.rejected, (state, action) => {
            console.log('rejected')
        })
    },
})

export const useLessons = () => {
    return useSelector((state: RootState) => state.lesson.lessons)
}

export const useLessonsOrderByStartDesc = () => {
    const lessons = useSelector((state: RootState) => state.lesson.lessons)
    lessons.slice().sort((a, b) => {
        if (a.start === undefined || b.start === undefined) {
            return 0
        }
        if (a.start > b.start) return -1
        if (a.start < b.start) return 1
        return 0
    })
    return lessons
}

export const useLessonMessage = () => {
    return useSelector((state: RootState) => state.lesson.lessonMessage)
}

// export const useLessonPage = () => {
//     return useSelector((state: RootState) => state.lesson.page)
// }
// export const useSelectedLesson = () => {
//     return useSelector((state: RootState) => state.lesson.selectedLesson)
// }
export const useLessonById = (id: string) => {
    return useSelector((state: RootState) => state.lesson.lessons.find((lesson: Lesson) => lesson?.lesson_id === id))
}

// Action Creatorsをエクスポート
export const {
    // changeLessonPage, setSelectedLesson, editLesson,
    setLessons,
    setLessonExaminationAnswer,
    setLessonCommentAndRecords,
    setLessonMessage,
    setLessonStatus,
} = LessonModule.actions

// Slice をエクスポートする
export default LessonModule

//################################################################
