// 認証モジュール
// import * as Const from '../Const'
// import { trace } from '../common/index'

import { useSelector } from 'react-redux'
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from '../rootReducer'
import * as AuthClient from './AuthClient'
import { Auth, Role } from '../Types'

// ステート定義
type AuthState = {
    authorized: boolean // 認証済みか.
    role: Role // 権限.
    auth?: Auth
}

// ステート初期値.
const initialState: AuthState = {
    authorized: false,
    role: Role.unknown,
    auth: undefined,
}
// React Thunk
export const login = createAsyncThunk('auth/login', async (arg: { mode: string; auth: Auth }, thunkAPI) => {
    let scopes = [arg.mode]
    if (arg.auth.facility_id) {
        scopes.push(arg.auth.facility_id)
    }
    const mode = 'token'
    // let scopes = [arg.auth.facility_id]
    // const mode = arg.mode;

    const params = {
        grant_type: 'password',
        username: arg.auth.account,
        password: arg.auth.password,
        scope: scopes?.join(','),
    }
    console.log('##LOGIN:', params)
    try {
        const response = await AuthClient.post(`/auth/${mode}`, params)
        return { ...response, auth: arg.auth }
    } catch (error) {
        throw new Error(JSON.stringify(error))
    }
})

// Slice を作成.
const AuthModule = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        // ログアウト.
        logout(state: AuthState, action: PayloadAction<Auth | undefined>) {
            // トークンを削除.
            localStorage.removeItem('token')
            state.authorized = false
        },
    },
    extraReducers: (builder) => {
        // login
        builder.addCase(login.fulfilled, (state, action: any) => {
            console.log('login.fulfilled', action.payload)
            if (action?.payload?.error !== undefined) {
                state = {
                    ...state,
                    authorized: false,
                    auth: undefined,
                }
                return state
            }

            // トークンをローカルストレージに保存.
            localStorage.setItem('token', JSON.stringify(action.payload.data))

            state = {
                ...state,
                authorized: action.payload.isSuccess,
                auth: { ...action.payload.auth, password: undefined },
            }
            return state
        })
        builder.addCase(login.rejected, (state, action: any) => {
            console.log('# login rejected')
        })
    },
})

export const useAuth = () => {
    return useSelector((state: RootState) => state.auth)
}
export const useAuthorized = () => {
    return useSelector((state: RootState) => state.auth.authorized)
}

// Action Creatorsをエクスポート
export const { logout } = AuthModule.actions

// Slice をエクスポートする
export default AuthModule
