// ### Import
import React from 'react'
import clsx from 'clsx'

// Material-UI
import { Paper, Tabs, Tab, Box } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
    paper: {
        width: '100%',
        backgroundColor: 'transparent',
        border: 'unset',
    },
    tabPanel: {
        backgroundColor: '#5A5A5A',
    },
    tabs: {
        '& .MuiTabs-indicator': {
            display: 'none',
        },
    },
    tab: {
        color: '#5A5A5A',
        fontSize: 16,
        fontWeight: 'bold',
        borderTopLeftRadius: 6,
        borderTopRightRadius: 6,
        backgroundColor: '#B6B6B6',
        marginRight: 6,
        '&.Mui-selected': {
            color: '#FFFFFF',
            backgroundColor: '#5A5A5A',
        },
    },
    disabled: {
        pointerEvents: 'none',
        opacity: 0.6,
    },
}))

//### Import MyClass
// タブ.
type TabPanelProps = {
    children?: React.ReactNode
    index: any
    value: any
    disabled?: boolean
}

function TabPanel(props: TabPanelProps) {
    const classes = useStyles()
    const { children, value, index, disabled, ...other } = props

    return (
        <div
            className={clsx(classes.tabPanel, disabled && classes.disabled)}
            style={{ width: '100%' }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p="20px">
                    <Box>{children}</Box>
                </Box>
            )}
        </div>
    )
}

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

type TabComponentProps = {
    items: {
        label: string
        content: any
    }[]
    disabled?: boolean
}
export function TabComponent(props: TabComponentProps) {
    const classes = useStyles()
    const [value, setValue] = React.useState(0)
    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue)
    }
    return (
        <React.Fragment>
            <Paper square variant="outlined" className={classes.paper}>
                <Tabs className={classes.tabs} value={value} onChange={handleTabChange} aria-label="simple tabs example">
                    {props.items.map((item, index) => {
                        return <Tab className={classes.tab} key={index} label={item.label} {...a11yProps(index)} />
                    })}
                </Tabs>
            </Paper>
            {props.items.map((item, index) => {
                return (
                    <TabPanel key={index} value={value} index={index} disabled={props.disabled}>
                        {item.content}
                    </TabPanel>
                )
            })}
        </React.Fragment>
    )
}
