import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            padding: '10px 0',
            width: 960,
            margin: '0 auto',
        },
        growth_record: {
            width: 960,
            margin: '0 auto',
        },
        video_container: {
            width: 960,
            margin: '20px auto 30px auto',
        },
        view: {
            display: 'flex',
            justifyContent: 'center',
        },
        right_view: {
            width: '75%',
            marginLeft: 10,
        },
        box: {
            display: 'flex',
            justifyContent: 'space-between',
            borderBottom: '1px solid #fff',
            width: '100%',
        },
        boxBgColor1: {
            backgroundColor: '#fff',
        },
        boxBgColor2: {
            backgroundColor: '#F5F5F5',
        },
        header: {
            color: '#fff',
            fontWeight: 'bold',
            minHeight: 35,
            marginTop: 4,
            background: 'linear-gradient(0deg, #5a5a5a 10%, #707070 80%, #5F5F5F 100%)',
        },
        thead: {
            color: '#5A5A5A',
            marginTop: 4,
            borderTop: '1px solid #e2e2e2',
            background: 'linear-gradient(0deg, #E3E3E3 10%, #FDFDFD 60%, #FFF 80%)',
            border: '1px solid #E2E2E2',
        },
        time_header: {
            width: '10%',
            flexShrink: 0,
            borderRight: '1px solid #E2E2E2',
            padding: '5px 0',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        question_header: {
            width: '40%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRight: '1px solid #E2E2E2',
            padding: '5px 0',
        },
        correct_answer_header: {
            flex: 1,
            width: '10%',
            borderRight: '1px solid #E2E2E2',
            padding: '5px 0',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '& .MuiInput-underline:before': {
                borderBottom: 0,
            },
            '& .MuiInputBase-root.Mui-disabled': {
                boxShadow: 'none',
            },
            '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                borderBottom: 0,
            },
            '& .MuiInput-underline:after': {
                borderBottom: 0,
            },
        },
        user_response_header: {
            padding: '5px 0',
            width: '40%',
            display: 'flex',
            justifyContent: 'center',
        },
        time_row: {
            marginLeft: 10,
            width: '10%',
            flexShrink: 0,
            borderRight: '1px solid #E2E2E2',
            display: 'table-cell',
            padding: '6px 10px',
        },
        question_row: {
            marginLeft: 10,
            width: '40%',
            display: 'table-cell',
            borderRight: '1px solid #E2E2E2',
            padding: '6px 10px',
        },
        correct_answer_row: {
            flex: 1,
            borderRight: '1px solid #E2E2E2',
            padding: '6px 10px',
            display: 'table-cell',
            width: '10%',
            '& .MuiInput-underline:before': {
                borderBottom: 0,
            },
            '& .MuiInputBase-root.Mui-disabled': {
                boxShadow: 'none',
            },
            '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                borderBottom: 0,
            },
            '& .MuiInput-underline:after': {
                borderBottom: 0,
            },
        },
        user_response_row: {
            width: '40%',
            display: 'table-cell',
            padding: '6px 10px',
        },
        cell: {
            display: 'table',
            border: '1px solid #E2E2E2',
            borderTop: 0,
        },
        video: {
            alignSelf: 'flex-start',
        },
        control_panel: {
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
        },
        right: {
            backgroundColor: '80ff80',
            alignItems: 'center',
            justifyContent: 'right',
            textAlign: 'center',
        },
        footer: {
            marginTop: theme.spacing(2),
            marginBottom: 40,
        },
        header_title: {
            marginBottom: 10,
            color: '#5a5a5a',
            fontSize: '15px',
        },
        title_label: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '10%',
            borderRight: '1px solid #fff',
        },
        title_video: {
            display: 'flex',
            alignItems: 'center',
            padding: '4px 8px',
            marginLeft: 6,
            width: '90%',
        },
        radioForm: {
            marginLeft: 10,
        },
        radio: {
            display: 'flex',
            alignItems: 'flex-start',
            '& .MuiIconButton-root': {
                padding: '0 4px 4px',
            },
        },

        video_on_right: {
            border: '2px solid #5a5a5a',
            padding: 0,
            borderRadius: 0,
            height: 372,
        },
        videochat: {
            alignSelf: 'flex-start',
            height: '100%',
        },
        lessonComment: {
            marginTop: 20,
        },
        return: {
            background: 'linear-gradient(0deg, #EAEAEA 10%, #FDFDFD 60%, #FFF 80%)',
            border: '1px solid #6e6e6e',
            '& .MuiTypography-h4': {
                fontSize: 15,
            },
        },
        button_area: {
            marginTop: 10,
            marginBottom: 10,
            display: 'flex',
            justifyContent: 'center',
            padding: theme.spacing(1),
        },
        disabled: {
            pointerEvents: 'none',
            opacity: 0.6,
        },
    }),
)
