// import * as Const from '../Const'
// import { trace } from '../common/index'
import clsx from 'clsx'
import { isUndefined } from 'util'

import React from 'react'

// Redux & Reducer Modules
import { useProfile } from '../modules/AppModule'

// Material-UI
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Typography, Box, Badge, Paper, Grid, Avatar, Collapse, FormControlLabel } from '@material-ui/core'

import * as DataUtil from '../modules/DataUtil'
import { DropDownButton } from './Buttons'
import { InputCheckBox } from './Inputs'
import { useAppDispatch } from 'store'
import { fetchFavoriteUsersByStaffId } from 'modules/StaffModule'
import { Staff, User } from 'Types'
import { patchUserHandicap } from 'modules/UserModule'
import { getImageCacheContext } from 'modules/ImageCacheProvider'

// Conditions
const ENABLE_EDIT_CHECKBOX = true // enable edit checkbox
const CHECKBOX_ON_WITH_PARENT = true // if the checkbox turned on, the children work with the parent.
const CHECKBOX_OFF_WITH_PARENT = false // if the checkbox turned off, the children work with the parent.

// スタイルを適用する
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            // height: '100px',
            // overflow: 'hidden',

            justifyContent: 'center',
            alignItems: 'center',
        },
        user_element: {
            // float: 'left',
        },
        circle: {
            width: 20,
            height: 20,
            borderRadius: '50%',
            backgroundColor: theme.palette.primary.main,
        },
        circle_offline: {
            backgroundColor: theme.palette.secondary.main,
        },

        // バッジ.
        badge: {
            top: 4,
            left: 8,
            transform: 'scale(1) translate(-50%, -50%)',
        },
        container: {
            textAlign: 'center',
            alignItems: 'center',
            height: '100%',
            padding: 10,
            position: 'relative',
        },
        avatarItem: {
            marginRight: 29,
        },
        avatar: {
            margin: '0px',
            width: 80,
            height: 80,
        },
        smallAvatar: {
            margin: '0px',
            width: 20,
            height: 20,
            fontSize: '12px',
        },
        avatar_name: {
            marginLeft: '10px',
        },
        time: {
            borderColor: theme.palette.common.black,
            textAlign: 'center',
            padding: '20px,220px',
        },
        chip: {
            width: '100%',
            // width: "80px",
            fontWeight: 'bold',
            color: theme.palette.common.black,
            // backgroundColor: "#ff0000",
        },
        offline: {
            // backgroundColor: theme.palette.secondary.main,
            backgroundColor: '#E2E2E2',
        },
        active: {
            // backgroundColor: theme.palette.primary.main,
            backgroundColor: '#CDEEF3',
        },

        detail: {
            display: 'flex',
            alignItems: 'center',
        },
        detail_item: {
            marginLeft: 20,
        },
        right: {
            marginLeft: 'auto',
        },
        checkbox: {
            marginLeft: 10,
            width: 180,
            flexShrink: 0,
        },
        handicapRoot: {
            // backgroundColor: "#ff0000",
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            background:
                'transparent linear-gradient(0deg, #B4B4B5 0%, #BCBCBD 1%, #CDCDCD 2%, #D9D9D9 4%, #E0E0E0 7%, #E2E2E2 14%, #EAEAEA 20%, #F6F6F6 33%, #FDFDFD 51%, #FFFFFF 100%)',
            padding: '6px 110px 16px',
        },
        handicapPanel: {
            // backgroundColor: "#ff8080",
            display: 'inline-block',
        },
        handicapCheckboxGroup: {
            // backgroundColor: "#0000ff",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'left',
        },
        handicapCheckbox: {
            // backgroundColor: "#ff00ff",
            marginLeft: 10,
            flexShrink: 0,
        },
        userInfoItem: {
            display: 'flex',
            alignItems: 'center',
            padding: '6px 0',
        },
        userInfoLabel: {
            width: 90,
            height: 20,
            lineHeight: '20px',
            borderRadius: theme.spacing(2),
        },
        userInfoDetail: {
            flexGrow: 1,
        },
        userInfoName: {
            marginLeft: theme.spacing(3),
        },
        dropdownBtn: {
            position: 'absolute',
            right: theme.spacing(2),
            bottom: theme.spacing(1),
        },
    }),
)
type Value = {
    name: string
    ruby: string
    age: number
    gender: string
    icon: string
    parent1: string
    parent2: string
    handicaps: string[]
    favorite: boolean
}

interface StaffUserInfoCellProps {
    user: User | undefined
    active: Boolean
}

export default function StaffUserInfoCell(props: StaffUserInfoCellProps) {
    const imageCache = React.useContext(getImageCacheContext())

    const dispatch = useAppDispatch()
    const [expanded, setExpanded] = React.useState(false)
    const handleExpandClick = () => {
        setExpanded(!expanded)
    }

    // CSSを適用する。
    const classes = useStyles()
    const { user } = props
    const { active } = props
    const profile = useProfile() as Staff
    const staff_id = isUndefined(profile) || isUndefined(profile.staff_id) ? '' : profile.staff_id
    const [values, setValues] = React.useState<Value>({
        name: '',
        ruby: '',
        age: 0,
        gender: '',
        icon: '',
        parent1: '',
        parent2: '',
        handicaps: [],
        favorite: false,
    })

    React.useEffect(() => {
        if (isUndefined(user)) return
        if (staff_id === '') return

        dispatch(fetchFavoriteUsersByStaffId(staff_id)).then((res) => {
            if (staff_id === undefined) return
            const favoriteUsers = res.payload
            // console.log('###FAVORITE', res)
            setValues({
                name: isUndefined(user.user_name) ? '' : user.user_name,
                ruby: isUndefined(user.user_ruby) ? '' : user.user_ruby,
                age: isUndefined(user.birth_date) ? 0 : DataUtil.getAge(user.birth_date),
                gender: isUndefined(user.gender_id) ? '' : DataUtil.getGenderName(user.gender_id),
                icon: imageCache.getUserImage(user),
                parent1: isUndefined(user.parent_1) ? '' : user.parent_1,
                parent2: isUndefined(user.parent_2) ? '' : user.parent_2,
                handicaps: getHandicaps(user.handicaps),
                favorite: favoriteUsers?.includes(user.user_id || ''),
            })
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, user, staff_id])

    function getHandicaps(obj: any) {
        if (obj === undefined) {
            return []
        }
        if (Array.isArray(obj)) {
            return obj
        }
        if (typeof obj === 'string') {
            return obj.split(',')
        }
        return []
    }

    if (isUndefined(user)) return <div>ユーザが見つかりません</div>

    // ハンディキャップがあるかどうか.
    function hasHandicap(handicap: string): boolean {
        return values.handicaps.indexOf(handicap) !== -1
    }

    const handicapHierarchy = [
        { label: '身体障害', name: 'physical', checked: hasHandicap('physical'), children: null, parent: null },
        { label: '知的障害', name: 'intellectual', checked: hasHandicap('intellectual'), children: null, parent: null },
        { label: '精神障害', name: 'mental', checked: hasHandicap('mental'), children: null, parent: null },
        {
            label: '発達障害',
            name: 'developmental',
            checked: hasHandicap('developmental'),
            children: [
                { label: '注意欠如多動性障害', name: 'adhd', checked: hasHandicap('adhd'), children: null, parent: 'developmental' },
                { label: '自閉症', name: 'autism', checked: hasHandicap('autism'), children: null, parent: 'developmental' },
                { label: 'アスペルガー症候群', name: 'asperger', checked: hasHandicap('asperger'), children: null, parent: 'developmental' },
                { label: '学習障害', name: 'learning', checked: hasHandicap('learning'), children: null, parent: 'developmental' },
            ],
            parent: null,
        },
    ]
    function getParentCheckbox(name: string) {
        const parent = handicapHierarchy.find((item) => {
            if (item.children) {
                return item.children.find((child) => {
                    if (child.name === name) {
                        return child.parent
                    }
                    return null
                })
            }
            if (item.name === name) {
                return item.parent
            }
            return null
        })
        return parent
    }
    const handleChangeCheckBox = (name: any) => (e: any) => {
        if (!ENABLE_EDIT_CHECKBOX) {
            return
        }

        console.log('### Checked: ', name, e.target.checked ? 'true' : 'false')
        // this.setState({ isTrue: e.target.checked });
        let items: string[] = values.handicaps
        if (e.target.checked) {
            items = [...items, name]
            if (CHECKBOX_ON_WITH_PARENT) {
                // 親をチェック.
                const parent = getParentCheckbox(name)
                if (parent) {
                    if (items.indexOf(parent.name) === -1) {
                        items = [...items, parent.name]
                    }
                }
            }
        } else {
            // TOPレベルの場合、子のチェックを外す.
            if (CHECKBOX_OFF_WITH_PARENT) {
                const target = handicapHierarchy.find((item) => item.name === name)
                console.log('target:', target)

                if (target && target.children) {
                    target.children.map((child) => {
                        items = items.filter((item) => item !== child.name)
                        return child
                    })
                }
            }
            items = items.filter((item) => item !== name)
        }
        console.log('handicaps', values.handicaps, items)
        setValues({ ...values, handicaps: items })

        // 更新.
        dispatch(patchUserHandicap({ user_id: user.user_id, handicaps: items }))
            .then((res) => {})
            .catch((err) => {
                console.log('Handicap 更新エラー')
            })
    }

    return (
        <React.Fragment>
            <Paper square={true} elevation={3} className={clsx(classes.root, active ? classes.active : classes.offline)}>
                <Grid container className={classes.container}>
                    <Grid item className={classes.avatarItem}>
                        {/* <Paper variant="outlined"> */}
                        <Badge
                            color="default"
                            badgeContent={values.favorite ? <img src="/assets/images/star.png" alt="" /> : <img src="/assets/images/gray_star.png" alt="" />}
                            classes={{
                                badge: classes.badge,
                            }}
                        >
                            {isUndefined(values.icon) || user.icon === '' ? (
                                <Avatar variant="square" className={classes.avatar} />
                            ) : (
                                <Avatar src={values.icon} variant="square" className={classes.avatar} />
                            )}
                        </Badge>
                        {/* </Paper> */}
                    </Grid>
                    <Grid item xs={9}>
                        <Grid container>
                            <Grid item xs={12} className={classes.userInfoItem}>
                                <Paper className={classes.userInfoLabel}>氏名</Paper>
                                <Typography className={classes.userInfoName} variant="h3">
                                    {values.name}
                                </Typography>
                            </Grid>
                            <Grid item xs className={classes.userInfoItem}>
                                <Paper className={classes.userInfoLabel}>年齢</Paper>
                                <Typography className={classes.userInfoDetail} variant="subtitle1">
                                    {values.age} 歳
                                </Typography>
                            </Grid>
                            <Grid item xs className={classes.userInfoItem}>
                                <Paper className={classes.userInfoLabel}>性別</Paper>
                                <Typography className={classes.userInfoDetail} variant="subtitle1">
                                    {values.gender}
                                </Typography>
                            </Grid>
                            <Grid item xs className={classes.userInfoItem}>
                                <Paper className={classes.userInfoLabel}>保護者1</Paper>
                                <Typography className={classes.userInfoDetail} variant="subtitle1">
                                    {values.parent1}
                                </Typography>
                            </Grid>
                            <Grid item xs className={classes.userInfoItem}>
                                <Paper className={classes.userInfoLabel}>保護者2</Paper>
                                <Typography className={classes.userInfoDetail} variant="subtitle1">
                                    {values.parent2}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <DropDownButton newStyle={classes.dropdownBtn} show={!expanded} onClickHandler={handleExpandClick} />
                </Grid>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <Paper square={true} className={classes.handicapRoot}>
                        <Box className={classes.handicapPanel}>
                            {handicapHierarchy.map((row, index) => {
                                return (
                                    <Box className={classes.handicapCheckboxGroup} key={index}>
                                        <Box className={classes.handicapCheckbox}>
                                            <FormControlLabel
                                                control={
                                                    <InputCheckBox
                                                        checked={row.checked}
                                                        onChange={handleChangeCheckBox(row.name)}
                                                        // disabled
                                                    />
                                                }
                                                label={row.label}
                                            />
                                        </Box>
                                        {row.children ? (
                                            <React.Fragment>
                                                <Typography>（</Typography>
                                                {row.children.map((child) => {
                                                    return (
                                                        <Box className={classes.handicapCheckbox} key={child.label}>
                                                            <FormControlLabel
                                                                control={<InputCheckBox checked={child.checked} onChange={handleChangeCheckBox(child.name)} />}
                                                                label={child.label}
                                                            />
                                                        </Box>
                                                    )
                                                })}
                                                <Typography>）</Typography>
                                            </React.Fragment>
                                        ) : null}
                                    </Box>
                                )
                            })}
                        </Box>
                        <DropDownButton newStyle={classes.dropdownBtn} show arrowUp onClickHandler={handleExpandClick} />
                    </Paper>
                </Collapse>
            </Paper>
        </React.Fragment>
    )
}
