import { LessonType } from 'Types'
import * as AppConfig from '../config'

// WebSocket
var ws: WebSocket | null = null
var intervalObj: number | null = null
var currentRoomId: string | null = null

const ACK_INTERVAL = 1000 * 60 // Idle 対策としてのパケット送出間隔(msec)

// callbacks
type WsCallback = {
    key: string
    onMessage: (message: string) => void
    onConnect?: () => void
    onDisconnect?: () => void
}
var _callbacks: WsCallback[] = []

// WebSocket
// import socketio from 'socket.io-client'
// const socket = socketio.connect('http://localhost:8080')

// console.log('### WS Start', socket );
// //このコンポーネントがDOMによって読み込まれた後の処理を設定する
// socket.on('chatMessage',(obj:any) => {
//     console.log(obj)
//   })
export function useWebSocket(): WebSocket | null {
    return ws
}

// websocket の　idle 対策に、ACK パケットを定期的に送出する.
// （本来の ACK とは利用方法が異なるが）
function startAck() {
    console.log('### START ack timer')
    // 起動中なら停止.
    if (intervalObj !== null) {
        clearInterval(intervalObj)
    }
    intervalObj = setInterval(() => {
        sendCommand('ack', undefined)
    }, ACK_INTERVAL)
}

function stopAck() {
    console.log('### STOP ack timer')
    if (intervalObj !== null) {
        clearInterval(intervalObj)
    }
}

/**
 * WebSocket 通信開始
 *
 * @export
 * @param {string} type         レッスン種別(個別|一斉)
 * @param {string} room_id      ルームID(=レッスンID)
 * @param {string} from         接続元識別文字列( 'user'|'staff' )
 * @param {string} name         接続者名(任意)
 * @param {string} client_id    staff_id | user_id.
 * @param {boolean} is_internal true value to inform this connection only for getting information
 */
export function start(type: LessonType, room_id: string, from: string, name: string, client_id: string | undefined, is_internal: boolean = false) {
    // 既に開始している.
    if (ws !== null) {
        // prevent it remake connection
        if (currentRoomId === room_id) return
        ws.close()
    }

    // wss サーバに接続.
    ws = new WebSocket(AppConfig.APP_WEBSOCKET_BASE_URL)
    currentRoomId = room_id

    // 接続完了イベント.
    ws.addEventListener('open', (e: any) => {
        console.log('WebSocket: open')
        sendCommand('enter', { room: room_id, type: type, id: client_id === undefined ? '' : client_id, from: from, name: name, is_internal: is_internal })
        _callbacks.map((callback) => {
            if (callback.onConnect) {
                callback.onConnect()
            }
            startAck()
            return callback
        })
    })
    // 切断イベント
    ws.addEventListener('close', (e: any) => {
        console.log('WebSocket: disconnect')
        _callbacks.map((callback) => {
            if (callback.onDisconnect) {
                callback.onDisconnect()
            }
            return callback
        })
    })

    // 受信イベント
    ws.addEventListener('message', (e: any) => {
        // console.log( "ws:receive:", e.data )
        // console.log( "message", _callbacks.length )
        const message = e.data
        const json = JSON.parse(message)
        if (json.command !== 'posture') {
            console.log('###### ws:receive', json)
        }

        _callbacks.map((callback) => {
            if (callback.onMessage) {
                callback.onMessage(message)
            }
            return callback
        })
    })

    // エラー受信イベント
    ws.addEventListener('error', (e: any) => {
        console.log('ws:receive:', e.data)
        console.log('message', _callbacks.length)
        // const message = e.data

        // const json = JSON.parse(message)
        // console.log('ws:receive', json)
        // _callbacks.map((callback) => {
        //     if (callback.onMessage) {
        //         callback.onMessage(message)
        //     }
        //     return callback
        // })
    })
}

export function stop() {
    stopAck()

    _callbacks = []

    if (ws === null) {
        return
    }
    // ws.removeEventListener( 'open', doOpen);
    // ws.removeEventListener( 'close', doClose );
    // ws.removeEventListener( 'message', doMessage );
    ws.close()
    ws = null
    currentRoomId = null
}

// コールバックの設定.
// 後着優先
export function setCallback(callback: WsCallback) {
    // 既に登録済みのキーを削除.
    const cbs = _callbacks.filter((cb) => cb.key !== callback.key)
    // あらためて追加
    _callbacks = [...cbs, callback]
}

export function sendCommand(command: string, data: any) {
    if (ws !== null) {
        const message = JSON.stringify({ action: 'sendmessage', data: { command: command, data: data } })
        ws.send(message)
        console.log('ws:send:', message)
    }
}
