import React from 'react'
import { Radar } from 'react-chartjs-2'

type RadarItem = {
    label: string
    value: number
}
type RadarChartProps = {
    data: RadarItem[]
}
export default function RadarChart(props: RadarChartProps) {
    const colors = props.data.map((row, index) => {
        return index & 1 ? '#c5c5c5' : '#e2e2e2'
    })
    const labels = props.data.map((row) => row.label)
    const values = props.data.map((row) => row.value)
    const notations: { [key: number]: string } = {
        0: '',
        0.5: '',
        1: '❶',
        1.5: '',
        2: '❷',
        2.5: '',
        3: '❸',
        3.5: '',
        4: '❹',
        4.5: '',
        5: '❺',
    }
    return (
        <Radar
            data={{
                labels: labels,
                datasets: [
                    {
                        label: 'MSPA評価指標',
                        backgroundColor: 'transparent',
                        borderColor: '#05A9C2',
                        borderWidth: 5,
                        pointRadius: 0,
                        data: values,
                    },
                ],
            }}
            width={400}
            options={{
                maintainAspectRatio: false,
                legend: {
                    display: false,
                },
                scale: {
                    gridLines: {
                        color: colors,
                        lineWidth: 2,
                    },
                    pointLabels: {
                        fontWeight: 'bold',
                        fontSize: 14,
                        fontColor: 'black',
                    },
                    ticks: {
                        callback: function (value: number) {
                            return notations[value]
                        },
                        beginAtZero: true,
                        stepSize: 0.5,
                        max: 5,
                        display: true,
                        fontSize: 23,
                        showLabelBackdrop: false,
                        fontColor: '#a8a8a8',
                        z: 1,
                    },
                },
                plugins: {
                    datalabels: {
                        display: false,
                    },
                },
            }}
        />
    )
}
