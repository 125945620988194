import React from 'react'
import clsx from 'clsx'
import {
    Dialog,
    DialogContent,
    Box,
    TableContainer,
    TableHead,
    Theme,
    createStyles,
    withStyles,
    Paper,
    makeStyles,
    DialogActions,
    Table,
    TableRow,
    TableCell,
    TableBody,
} from '@material-ui/core'
import { MainButton } from './Buttons'
import { fetchLessonByUserWithStatus } from 'modules/LessonModule'
import { Lesson, LessonStatus } from 'Types'
import { useAppDispatch } from 'store'

const useDialogStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogPaper: {
            maxWidth: 960,
        },
        dialogContent: {
            padding: 6,
            '&:first-child': {
                paddingTop: 6,
            },
        },
        dialogAction: {
            justifyContent: 'center',
        },
        dialogItem: {
            marginBottom: 30,
        },
        header: {
            display: 'flex',
            marginBottom: 10,
        },
        headerLeft: {
            width: 'calc(87.5% - 10px)',
            marginRight: 10,
            border: '1px solid #707070',
        },
        headerRight: {
            width: '12.5%',
            textAlign: 'center',
            border: '1px solid #707070',
        },
        headerTitle: {
            color: theme.palette.common.white,
            backgroundColor: '#05A9C2',
        },
        headerItem: {
            padding: '2px 10px',
        },
        headerContent: {
            fontSize: '16px',
        },
    }),
)

const StyledPaper = withStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
            minHeight: 27,
            padding: '2px 10px',
            boxShadow: '1px 1px 2px #0000004D',
        },
    }),
)(Paper)

const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        root: {
            border: '1px solid',
            borderColor: theme.palette.common.white,
            padding: '4px 6px',
        },
        head: {
            border: '1px solid',
            borderColor: theme.palette.common.white,
            background: 'transparent linear-gradient(0deg, #5A5A5A 0%, #5F5F5F 7%, #707070 34%, #7A7A7A 64%, #7E7E7E 100%)',
            color: theme.palette.common.white,
        },
        body: {
            fontSize: 14,
        },
    }),
)(TableCell)

const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: '#F0F0F0',
            },
            '&:nth-of-type(even)': {
                backgroundColor: '#E9E9E9',
            },
        },
    }),
)(TableRow)

export interface StaffUserGrowthMSPAProps {
    open: boolean
    onClose: () => void
    user_id?: string
    mspa_ids: string[] // MSPA ID
}

export default function StaffUserGrowthMSPA(props: StaffUserGrowthMSPAProps) {
    const { onClose, open, user_id, mspa_ids } = props
    const handleClose = () => onClose()

    const dispatch = useAppDispatch()
    const [lessons, setLessons] = React.useState<Lesson[]>([])
    React.useEffect(() => {
        if (user_id !== undefined) {
            dispatch(fetchLessonByUserWithStatus({ user_id: user_id, status: LessonStatus.finish })).then((res) => {
                const lessons = res.payload as Lesson[]
                setLessons(lessons)
            })
        }
    }, [dispatch, user_id])

    const classes = useDialogStyles()

    return (
        <Dialog
            onClose={handleClose}
            open={open}
            scroll="body"
            fullWidth
            classes={{
                paper: classes.dialogPaper,
            }}
        >
            <DialogContent
                classes={{
                    root: classes.dialogContent,
                }}
            >
                {lessons
                    .sort((lessonA: any, lessonB: any) => {
                        return new Date(lessonB.start).getTime() - new Date(lessonA.start).getTime()
                    })
                    .map((value, index) => (
                        <Box className={classes.dialogItem} key={index}>
                            <Box className={classes.header}>
                                <Box className={classes.headerLeft}>
                                    <Box className={clsx(classes.headerItem, classes.headerTitle)}>{value.start}</Box>
                                    <Box className={clsx(classes.headerItem, classes.headerContent)}>{value.training?.training_name}</Box>
                                </Box>
                                <Box className={classes.headerRight}>
                                    <Box className={clsx(classes.headerItem, classes.headerTitle)}>担当</Box>
                                    <Box className={clsx(classes.headerItem, classes.headerContent)}>{value.staff?.staff_name}</Box>
                                </Box>
                            </Box>
                            <TableContainer component={Paper}>
                                <Table aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell width="6.25%" align="center">
                                                達成度
                                            </StyledTableCell>
                                            <StyledTableCell width="16.67%" align="center">
                                                MSPA評価指標
                                            </StyledTableCell>
                                            <StyledTableCell align="center">コメント</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {value.growth_records === undefined
                                            ? null
                                            : value.growth_records
                                                  .filter((row) => {
                                                      // console.log( "######", row.mspa_title, mspa_ids)
                                                      return mspa_ids.indexOf(row.mspa_evaluation_id) >= 0 && row.user_id === user_id
                                                  })
                                                  .map((row, index) => (
                                                      <StyledTableRow key={index}>
                                                          <StyledTableCell align="center">{row.achievement}</StyledTableCell>
                                                          <StyledTableCell>{row.mspa_title}</StyledTableCell>
                                                          <StyledTableCell>
                                                              <StyledPaper square>{row.comment}</StyledPaper>
                                                          </StyledTableCell>
                                                      </StyledTableRow>
                                                  ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    ))}
            </DialogContent>
            <DialogActions classes={{ root: classes.dialogAction }}>
                <MainButton onClick={handleClose}>閉じる</MainButton>
            </DialogActions>
        </Dialog>
    )
}
