// ### Import
import * as Const from '../Const'
import { trace } from '../common/index'
import React from 'react'
import { useAppDispatch } from '../store'
import { createCourse, updateCourse, deleteCourse, CourseParam, useCourseById } from '../modules/CourseModule'
import { useFacility } from '../modules/AppModule'
import { fetchTrainings } from '../modules/TrainingModule'

// for validation
import { Controller, useFieldArray, useForm } from 'react-hook-form'

// Material-UI
import { Container, TextField, MenuItem, Grid, Paper, Typography, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

//### Import MyClass
import * as DataUtil from '../modules/DataUtil'
import { OptionValue, Course, Training } from '../Types'
import LevelBar2 from './LevelBar2'
import { MainButton, RoundedButton, DeleteButton } from '../components/Buttons'
import { isUndefined } from 'util'
import SmallAvatar from '../components/SmallAvatar'
import { unwrapResult } from '@reduxjs/toolkit'
import AvatarLabel from './AvatarLabel'
import { useParams } from 'react-router-dom'
import { isNotEmptyString } from 'modules/Util'

// スタイル定義.
const useStyles = makeStyles((theme) => ({
    root: {
        width: 960,
        margin: '0 auto',
        position: 'relative',
    },
    deleteButton: {
        position: 'absolute',
        right: 0,
        width: 150,
        background: '#D02927',
        color: '#fff',
        border: 0,
        '&:hover': {
            background: '#D02927',
        },
    },
    container: {
        marginTop: 35,
    },
    paper: {
        marginTop: theme.spacing(0),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    header: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        padding: theme.spacing(2, 0, 2),
    },
    title: {
        fontWeight: 'bold',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    margin: {
        marginTop: theme.spacing(2),
    },
    textField: {
        width: '100%', // Fix IE 11 issue.
    },
    // Warning
    warning: {
        color: '#ff0000',
        marginTop: theme.spacing(2),
    },

    levelbar2: {
        marginBottom: 20,
        justifyContent: 'center',
        // paddingTop: theme.spacing(2),
        height: 'calc(50px)',
        backgroundColor: 'transparent',
        borderColor: theme.palette.grey[400],
        borderWidth: '1px',
        '&:hover': {
            borderColor: 'black', //`${theme.palette.primary.main} !important`,
            borderWidth: '1px',
        },
        '&$focused': {
            borderColor: `${theme.palette.primary.main} !important`,
            borderWidth: '2px',
        },
        display: 'flex',
        flexDirection: 'column',
    },
    labelName: {
        paddingLeft: 'calc(8px)',
        transform: `translate(-15px,-15px) scale(0.8)`,
        backgroundColor: theme.palette.background.default,
        color: theme.palette.grey[500],

        width: '60px',
    },
    name: {
        margin: '10px 0 25px',
        '& .MuiOutlinedInput-input': {
            padding: 9,
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: 0,
        },
    },
    description: {
        margin: '10px 0 25px',
        '& .MuiOutlinedInput-input': {
            padding: 9,
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: 0,
        },
    },
    training: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 15,
        marginTop: 8,
    },
    trainingSelection: {
        '& .MuiOutlinedInput-root': {
            lineHeight: '32px',
            height: 32,
            borderRadius: 0,
        },
        '& .MuiInputBase-input': {
            padding: '0 30px 0 10px',
        },
    },
    addTo: {
        background: '#6E6E6E',
        color: '#fff',
        height: 26,
    },
    addToContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 25,
    },
    test: {
        margin: '10px 0 25px',
        '& .MuiOutlinedInput-input': {
            lineHeight: '32px',
            height: 32,
            padding: '0 30px 0 10px',
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: 0,
        },
    },
    footer: {
        display: 'flex',
        justifyContent: 'center',
    },
}))

// 編集要素.
type LocalState = {
    trainingOptions: OptionValue[] // オプション選択肢.
    test_trainings: string[] // test training.
    level: number[] // レベル範囲.
}

interface CourseEditProps {
    onCloseHandler?: () => void
}

/**
/**
 * トレーニング編集.
 *
 * @returns
 */
export default function CourseEdit(props: CourseEditProps) {
    const facility = useFacility()
    const facility_id = isUndefined(facility) || isUndefined(facility.facility_id) ? '' : facility.facility_id

    const classes = useStyles()
    const dispatch = useAppDispatch()

    // 現在のコース.
    const { courseId } = useParams()
    const isAddMode = !courseId
    let course = useCourseById(courseId)

    const { register, control, handleSubmit, errors, reset, watch } = useForm<Course & { training_ids: string[] }>({
        defaultValues: {
            training_ids: isAddMode ? [''] : [],
        },
    })
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'training_ids',
    })
    const watchTrainingIds = watch('training_ids')

    // redirect to list page if id isn't loaded
    if (courseId && isUndefined(course)) {
        handleClose()
    }

    if (isUndefined(course)) {
        course = DataUtil.initCourse()
    }

    React.useEffect(() => {
        if (course?.trainings.length) {
            const newTrainings = [...course.trainings]
            const trainingIds =
                newTrainings.length === 0 ? [''] : newTrainings.map((training) => (isUndefined(training.training_id) ? '' : training.training_id))
            reset({
                training_ids: trainingIds,
            })
        }
    }, [reset, course])

    const [state, setState] = React.useState<LocalState>({
        trainingOptions: [],
        test_trainings:
            isUndefined(course.test_trainings) || course.test_trainings.length === 0
                ? ['']
                : course.test_trainings?.map((training) => (isUndefined(training.training_id) ? '' : training.training_id)),
        level: [course.min_level, course.max_level],
    })

    React.useEffect(() => {
        // 洗濯用トレーニング一覧を取得.
        dispatch(fetchTrainings(facility_id))
            .then(unwrapResult)
            .then((res) => {
                const trainings = res as Training[]
                const options = trainings
                    .filter((item) => !(isUndefined(item) || isUndefined(item.training_id) || isUndefined(item.training_name)))
                    .map((training) => {
                        return makeTrainingOption(training)
                    })
                setState((state) => {
                    return { ...state, trainingOptions: options }
                })
                // console.log( "options", options)
            })
            .catch((error) => {})
    }, [dispatch, facility_id])
    function makeTrainingOption(training: Training): OptionValue {
        if (isUndefined(training) || isUndefined(training.training_id) || isUndefined(training.training_name)) {
            return { label: '', value: '--' }
        }
        return { label: training.training_name, value: training.training_id }
    }

    /**
     *　登録ボタンが押された時の処理.
     * @param {React.FormEvent} e
     * @returns
     */
    const onSubmit = (data: Course & { training_ids: string[] }) => {
        trace('=== 送信処理')
        trace(data)
        trace(state)

        // Remove empty value
        const trainingIds = data.training_ids.filter((id) => id)

        const param: CourseParam = {
            course_id: course?.course_id,
            course_name: data.course_name,
            description: data.description,
            min_level: state.level[0],
            max_level: state.level[1],
            facility_id: facility_id,
            training_ids: trainingIds,
            test_training_ids: state.test_trainings,
        }
        console.log('# Course Param', param)

        if (isUndefined(param) || isUndefined(param.course_id)) {
            trace('--新規登録')
            dispatch(createCourse(param)).then(() => handleClose())
        } else {
            trace('--更新')
            dispatch(updateCourse({ course_id: param.course_id, param: param })).then(() => handleClose())
        }
    }

    // 削除.
    function handleDelete() {
        trace('--削除')
        const course_id = course?.course_id
        if (!isUndefined(course_id)) {
            dispatch(deleteCourse(course_id)).then(() => handleClose())
        }
    }

    /**
     *　閉じるボタンが押された時の処理.
     * - バリデーション
     * - axios で POST
     * @returns
     */
    function handleClose() {
        if (!isUndefined(props.onCloseHandler)) {
            props.onCloseHandler()
        }
    }

    const handleChangeTestTraining = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(`change ${index}`, event.target.value)

        const test_training_id = event.target.value
        const test_trainings = state.test_trainings.map((item, idx) => {
            if (index === idx) {
                return test_training_id
            }
            return item
        })
        setState({ ...state, test_trainings: test_trainings })
    }

    // ライセンス数のオプション.
    const license_options: { value: number; label: string }[] = []
    for (var i = 1; i <= Const.Setting.LICENSE_MAX; i++) {
        license_options.push({ value: i, label: i.toString() })
    }

    const handleChangeLevel = (levels: number[]) => {
        console.log('levels: ', levels)
        setState({ ...state, level: levels })
    }

    // レンダリング
    return (
        <div className={classes.root}>
            <Container component="main" maxWidth="xs" className={classes.container}>
                {/* 削除. */}
                {!isAddMode && (
                    <DeleteButton fullWidth onClick={handleDelete} className={classes.deleteButton}>
                        削除
                    </DeleteButton>
                )}

                <div className={classes.paper}>
                    <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
                        {/* 名前. */}
                        <AvatarLabel type="active" title="カリキュラム名" />
                        <TextField
                            className={classes.name}
                            variant="outlined"
                            required
                            fullWidth
                            id="course_name"
                            name="course_name"
                            defaultValue={course.course_name}
                            // validation
                            inputRef={register({
                                required: 'タイトルは20文字以内にして下さい。', // 必須
                                maxLength: 'タイトルは20文字以内にして下さい。', // 20文字まで
                                validate: isNotEmptyString,
                            })}
                            error={Boolean(errors.course_name)}
                            helperText={errors.course_name && errors.course_name.message}
                        />
                        {/* 説明. */}
                        <AvatarLabel type="active" title="説明" />
                        <TextField
                            className={classes.description}
                            variant="outlined"
                            required
                            fullWidth
                            id="description"
                            name="description"
                            defaultValue={course.description}
                            multiline
                            rows={4}
                            // validation
                            inputRef={register({
                                required: '説明は必須です',
                                validate: isNotEmptyString,
                            })}
                            error={Boolean(errors.description)}
                            helperText={errors.description && errors.description.message}
                        />
                        {/* レベル */}
                        <AvatarLabel type="active" title="レベル" />
                        <Paper elevation={0} className={classes.levelbar2}>
                            <LevelBar2 onChangeHandler={handleChangeLevel} min={course.min_level} max={course.max_level} />
                        </Paper>

                        {/* トレーニング */}
                        <AvatarLabel type="active" title="トレーニング" />
                        {fields.map((item, index) => {
                            return (
                                <div key={item.id} className={classes.training} style={{ width: index > 0 ? '111%' : '100%' }}>
                                    <SmallAvatar
                                        style={{
                                            backgroundColor: 'transparent',
                                            color: '#5a5a5a',
                                            marginRight: 10,
                                            fontSize: 20,
                                        }}
                                    >
                                        {1 + index}
                                    </SmallAvatar>
                                    <Controller
                                        as={
                                            <TextField className={classes.trainingSelection} variant="outlined" fullWidth select>
                                                {state.trainingOptions.map((option, index) => {
                                                    return (
                                                        <MenuItem key={index} value={option.value} disabled={watchTrainingIds.indexOf(option.value) >= 0}>
                                                            {option.label}
                                                        </MenuItem>
                                                    )
                                                })}
                                            </TextField>
                                        }
                                        name={`training_ids[${index}]`}
                                        defaultValue={item.value}
                                        control={control}
                                        rules={{ required: index === 0 ? 'トレーニングを選択してください。' : false }}
                                        error={index === 0 && Boolean(errors.training_ids && errors.training_ids[0])}
                                        helperText={index === 0 && errors.training_ids && errors.training_ids[0]?.message}
                                    />
                                    {index > 0 ? (
                                        <IconButton aria-label="remove an item" onClick={() => remove(index)}>
                                            <img src="/assets/images/remove-icon.png" alt="" />
                                        </IconButton>
                                    ) : null}
                                </div>
                            )
                        })}
                        <div className={classes.addToContainer}>
                            <RoundedButton
                                className={classes.addTo}
                                variant="outlined"
                                onClick={() => {
                                    append({ value: '' })
                                }}
                            >
                                <Typography variant="h4">追加</Typography>
                            </RoundedButton>
                        </div>

                        {/* テスト. */}
                        <AvatarLabel type="active" title="テスト" />
                        {state.test_trainings.map((training_id, index) => {
                            return (
                                <TextField
                                    key={index}
                                    className={classes.test}
                                    variant="outlined"
                                    required
                                    fullWidth
                                    name="test_training"
                                    value={training_id}
                                    onChange={handleChangeTestTraining(index)}
                                    select
                                >
                                    {state.trainingOptions.map((option, index) => {
                                        return (
                                            <MenuItem key={index} value={option.value} disabled={state.test_trainings.indexOf(option.value) >= 0}>
                                                {option.label}
                                            </MenuItem>
                                        )
                                    })}
                                </TextField>
                            )
                        })}

                        <Footer />
                    </form>
                </div>
            </Container>
        </div>
    )

    function Footer() {
        if (isAddMode) {
            return (
                <Grid container spacing={4} className={classes.footer}>
                    <Grid item xs={6}>
                        {/* 登録. */}
                        <MainButton fullWidth type="submit">
                            登録
                        </MainButton>
                    </Grid>
                </Grid>
            )
        }
        return (
            <Grid container spacing={1} style={{ display: 'flex', justifyContent: 'center' }}>
                <Grid item xs={5}>
                    {/* 更新. */}
                    <MainButton fullWidth type="submit">
                        更新
                    </MainButton>
                </Grid>
                <Grid item xs={5}>
                    {/* 閉じる. */}
                    <MainButton fullWidth onClick={handleClose}>
                        閉じる
                    </MainButton>
                </Grid>
            </Grid>
        )
    }
}
