import * as AppConfig from '../config'

import axios, { AxiosError } from 'axios'
import * as AuthUtil from 'modules/AuthUtil'

axios.defaults.baseURL = AppConfig.APP_API_BASE_URL

// axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'
// axios.defaults.headers.post['Access-Control-Request-Method'] = 'GET,POST,PUT,DELETE,PATCH,OPTIONS'
// axios.defaults.headers.post['Access-Control-Request-Headers'] = 'Content-Type,authorization,x-api-key'
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded; charset=utf-8'
// axios.defaults.headers.post['Content-Type'] = 'application/json; charset=utf-8'
axios.defaults.headers.common['x-api-key'] = AppConfig.APP_API_KEY

axios.defaults.responseType = 'json'

export type ApiResponse = {
    isSuccess: boolean // 成功したか.
    error?: AxiosError // エラー発生時.
    data?: any // 返却データ
    status?: number // ステータスコード.
}

// FIXME::
// インターセプタを利用してエラーをハンドリングする.
// ただし、現時点では、 store.dispatch で TypeError: Cannot read property 'reducer' of undefined が発生するためペンディング
const client = axios.create()

// // 成功の場合は何もしない
// const onSuccess = (response: AxiosResponse) => {
//     console.log('onSuccess', response.status)
//     return response
// }
// // エラーの場合はストアにエラーをセットする
// const onError = (err: AxiosError) => {
//     console.log('onError:', err.toJSON())
//     //   const { status, data } = err.response;
//     //   store.dispatch(setError( err.message ));
//     // エラーをスローしたい場合は...
//     //   Promise.reject(err);
// }

// client.interceptors.response.use(onSuccess, onError)

/**
 * POST Request
 * non-idempotent
 * @export
 * @param {string} path
 * @param {object} [params={}]
 * @returns {Promise<ApiResponse>}
 */
export async function post(path: string, params: object = {}): Promise<ApiResponse> {
    try {
        const base64 = AuthUtil.getClientKey()
        const config = {
            headers: {
                Authorization: 'Basic ' + base64,
            },
        }
        console.log('config', config)
        const response = await _post(path, params, config)
        return createSuccessPromise(response.data)
    } catch (error) {
        let result
        console.log('### AuthClient ERROR:', error)
        if (error.response) {
            // console.log( "- response", error.response )
            if (error.response?.data?.error) {
                result = error.response.data.error.result
            } else if (error.response?.data) {
                result = error.response.data.result
            } else {
                result = error.response
            }
        } else if (error.message) {
            // console.log( "- message", error.message )
            result = { status_code: 0, error_message: error.message }
        } else {
            result = { status_code: 0, error_message: 'unknown', error: error }
        }
        // console.log( "# result", result )
        return createFailurePromise(result)
    }
}

async function _post(path: string, params: any, config: any): Promise<any> {
    return new Promise((resolve, reject) => {
        // // JSON の場合.
        // const body = JSON.stringify(params)
        // www-form-urlencoded の場合.
        const body = require('querystring').stringify(params)
        // console.log('Auth body', body)

        // [DEBUG] トークンの有効期限を設定する.
        path = path + AuthUtil.getCustomExpire()
        client
            .post(path, body, config)
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

/**
 * 通信成功時の Promise
 *
 * @param {*} data
 * @returns {Promise<ApiResponse>}
 */
function createSuccessPromise(data: any): Promise<ApiResponse> {
    return Promise.resolve<ApiResponse>({ ...data, isSuccess: true })
}

/**
 * 通信失敗時の Promise
 *
 * @param {*} data
 * @returns {Promise<ApiResponse>}
 */
function createFailurePromise(error: AxiosError): Promise<ApiResponse> {
    return Promise.reject(error)
    // return Promise.resolve<ApiResponse>({...error, isSuccess: false })
}
