/**
 * イメージ管理
 */
import { createAsyncThunk } from '@reduxjs/toolkit'
import * as ApiClient from './ApiClient'

import { useSelector } from 'react-redux'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../rootReducer'

// アップロード先を取得.
export const getSignedUrl = createAsyncThunk('/upload', async (filetype: string, thunkAPI) => {
    const url = `/upload/${filetype}`
    const response = await ApiClient.get(url)
    return response.data
})
export type ImageCacheEntry = {
    type: string // 種別.
    name?: string // 名称
    data?: string // データ
    etag: number // etag.
}

// ステート定義
type ImageCacheState = {
    enableTag: boolean
    etag: number // etag
    items: ImageCacheEntry[] // base64 イメージ.
}

// ステート初期値.
const initialState: ImageCacheState = {
    enableTag: false,
    etag: 0,
    items: [],
}

// Slice を作成.
const ImageCacheModule = createSlice({
    name: 'imageCache',
    initialState,
    reducers: {
        enableImageCacheTag(state: ImageCacheState, action: PayloadAction<boolean>) {
            state.enableTag = action.payload
        },
        addImageCache(state: ImageCacheState, action: PayloadAction<ImageCacheEntry[]>) {
            const entrys = action.payload
            // console.log('*** cache entries', entrys)
            let items = state.items

            entrys.map((entry: ImageCacheEntry) => {
                // 既存レコードを削除.
                items = items.filter((item) => {
                    return item.name !== entry.name
                })
                // 追加.
                items = [...items, entry]
                if (entry.etag > state.etag) {
                    state.etag = entry.etag
                }
                return entry
            })
            state.items = items
        },
    },
    // extraReducers: (builder) => {
    // fetch
    // builder.addCase(fetchFacilities.fulfilled, (state, action) => {
    //     // console.log( 'fulfilled' );
    //     return { ...state, facilities: action.payload }
    // })
    // builder.addCase(fetchFacilities.pending, (state, action) => {
    //     console.log('pending')
    // })
    // builder.addCase(fetchFacilities.rejected, (state, action) => {
    //     console.log('rejected')
    // })
    // },
})

// 画像一覧の取得.
export const useImageCache = () => {
    return useSelector((state: RootState) => state.imageCache.items)
}
export const useImageCacheByName = (type: string, name: string | undefined) => {
    return useSelector((state: RootState) => {
        const entry = state.imageCache.items.find((item) => {
            return item.type === type && item.name === name
        })
        return entry?.data
    })
}
export const useImageCacheTag = () => {
    return useSelector((state: RootState) => state.imageCache.etag)
}

// Action Creatorsをエクスポート
export const { addImageCache, enableImageCacheTag } = ImageCacheModule.actions

// Slice をエクスポートする
export default ImageCacheModule
