import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(2),
            justifyContent: 'center',
            alignItems: 'center',
        },
        paper: {
            margin: '0 auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: 480,
        },
        form: {
            width: '100%', // Fix IE 11 issue.
            marginTop: theme.spacing(4),
        },
        footer: {
            // backgroundColor: "#ff0000",
            margin: theme.spacing(3, 0, 2), // 上下マージン.
            padding: theme.spacing(1, 0, 1, 0), // パディング
        },
        menuItem: {
            display: 'flex',
            justifyContent: 'space-between',
            minHeight: 32,
            padding: '8px 30px 8px 12px',
            borderBottom: '1px solid #B6B6B6',
            '&:last-child': {
                borderBottom: 'none',
            },
        },
        menuList: {
            '& .MuiPaper-rounded': {
                borderRadius: 'unset',
            },
            '& .MuiMenu-list': {
                border: '1px solid #B6B6B6',
                borderRadius: 'unset',
                width: 480,
            },
            '& .MuiList-padding': {
                padding: 0,
            },
        },
        input: {
            marginBottom: theme.spacing(2),
            '& .MuiSelect-select': {
                fontSize: theme.typography.body1.fontSize,
                lineHeight: theme.typography.body1.lineHeight,
                minHeight: 32,
                boxSizing: 'border-box',
                padding: '8px 30px 8px 12px',
                borderColor: '#B6B6B6',
            },
            '& .MuiSelect-icon': {
                boxShadow: 'none !important',
                position: 'absolute',
                top: 12,
            },
            '& .MuiSelect-iconOpen': {
                transform: 'none',
                zIndex: 9999,
            },
            '& .MuiInputLabel-root': {
                display: 'block',
                color: '#05A9C2',
                '&.Mui-error': {
                    color: '#f44336',
                },
                '&::before': {
                    content: '""',
                    display: 'block',
                    backgroundColor: '#05A9C2',
                    width: 22,
                    height: 22,
                    float: 'left',
                    borderRadius: '50%',
                    margin: '-2px 16px 0 0',
                },
            },
        },
        box: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        picker: {
            padding: 10,
            border: '1px solid #B6B6B6',
        },
        datePicker: {
            width: 250,
        },
        timePicker: {
            width: 100,
        },
        headerCalendar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginLeft: 10,
            marginRight: 10,
        },
        buttonCalendar: {
            border: 'none',
            backgroundColor: 'transparent',
            '&:focus': {
                border: 'none',
            },
        },
        label: {
            color: '#05A9C2',
            '&::before': {
                content: '""',
                display: 'block',
                backgroundColor: '#05A9C2',
                width: 16,
                height: 16,
                float: 'left',
                borderRadius: '50%',
                margin: '2px 11px 0 0',
            },
        },
        trainingName: {
            width: 300,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    }),
)
