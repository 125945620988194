//
// 管理者ポータル.
// トレーニング管理.
//
// import * as Const from '../Const'
import { trace } from '../common/index'
import React from 'react'
import { useTrainingItems } from '../modules/TrainingModule'
import { usePortal } from '../modules/AppModule'
import { OrderBy, Training, TrainingSortable } from '../Types'

// User's Component.
import { NewButton } from './Buttons'
import SearchInput2 from './SearchInput2'
import TitleBar from './TitleBar'
import SearchBar2 from './SearchBar2'

// トレーニング一覧
import TrainingList from './TrainingList'
import TrainingEdit from './TrainingEdit'
import { Box } from '@material-ui/core'
import { Switch, Route, useHistory, useRouteMatch } from 'react-router-dom'
import useStaffRole from 'hooks/useStaffRole'
import useSortableData from 'hooks/useSortableData'

export default function TrainingContent() {
    trace('### TrainingContent')

    const portal = usePortal()
    const { isGeneralStaff } = useStaffRole()

    const history = useHistory()
    const { url } = useRouteMatch()
    const listPageUrl = url === '/admin' ? '/admin/trainings' : url

    let trainingItems = useTrainingItems()
    const [trainings, setTrainings] = React.useState<Training[]>(trainingItems)
    const { items, requestSort, sortConfig } = useSortableData(trainings, { id: TrainingSortable.updated_at, direction: OrderBy.desc })

    React.useEffect(() => {
        setTrainings(trainingItems)
        requestSort(TrainingSortable.updated_at, OrderBy.desc)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trainingItems])

    // トレーニング検索バー：新規作成
    const handleClickNew = () => {
        history.push({
            pathname: `${listPageUrl}/create`,
            state: { backUrl: url },
        })
    }

    const handleClickCell = (item: Training) => {
        history.push({
            pathname: `${listPageUrl}/${item?.training_id}`,
            state: { backUrl: url },
        })
    }

    const handleClose = (backUrl: string) => {
        history.push(backUrl)
    }

    // Filter training list
    const handleSearch = (filters: { textSearch: string; minLevel: number; maxLevel: number }) => {
        const { textSearch, minLevel, maxLevel } = filters
        let newSearch = trainingItems.filter((item, index) => {
            let isTrainingNameExisted = false
            let isTagNameExisted = false
            let isLevelMatched = true

            // Search by training_name
            if (item.training_name.indexOf(textSearch) !== -1) {
                isTrainingNameExisted = true
            }

            // Search by tag name
            for (let i = 0; i < item.tags.length; i++) {
                if (item.tags[i].tag.indexOf(textSearch) !== -1) {
                    isTagNameExisted = true
                    break
                }
            }

            // Search by level
            if (portal === 'user' || isGeneralStaff) {
                isLevelMatched = minLevel <= item.max_level && maxLevel >= item.min_level
            }

            return (isTrainingNameExisted || isTagNameExisted) && isLevelMatched
        })

        setTrainings(newSearch)
    }

    const getTags = () => {
        const tags: string[] = []
        trainingItems.forEach((training) => {
            if (training.tags === undefined) return
            training.tags.forEach((tag) => {
                if (!tag.tag) return
                tags.push(tag.tag)
            })
        })
        const uniqueTags = Array.from(new Set(tags))

        return uniqueTags
    }

    // CSSを適用する。
    // const classes = useStyles();
    return (
        <React.Fragment>
            <Box width="960px" margin="30px auto 0">
                <Switch>
                    {/* 一覧コンポーネント */}
                    <Route
                        exact
                        path={['/admin', `${listPageUrl}`]}
                        render={() => (
                            <>
                                <Box marginBottom="30px">
                                    {portal === 'user' || portal === 'staff' ? (
                                        <SearchBar2 left={<SearchInput2 onClickHandler={handleSearch} tags={getTags()} />} />
                                    ) : (
                                        <SearchBar2
                                            left={<SearchInput2 onClickHandler={handleSearch} tags={getTags()} />}
                                            right={<NewButton onClickHandler={handleClickNew}>新規登録</NewButton>}
                                        />
                                    )}
                                </Box>
                                <TitleBar bgBlue label="トレーニング一覧" />
                                <TrainingList items={items} onClickHandler={handleClickCell} requestSort={requestSort} sortConfig={sortConfig} />
                            </>
                        )}
                    />
                    {/* トレーニング管理コンポーネント */}
                    <Route
                        path={`${listPageUrl}/create`}
                        render={() => (
                            <>
                                <TitleBar bgBlue label="トレーニング登録" />
                                <TrainingEdit onCloseHandler={handleClose} />
                            </>
                        )}
                    />
                    {/* 編集コンポーネント */}
                    <Route
                        path={`${listPageUrl}/:trainingId`}
                        render={() => (
                            <>
                                <TitleBar bgBlue label="トレーニング詳細" />
                                <TrainingEdit onCloseHandler={handleClose} />
                            </>
                        )}
                    />
                </Switch>
            </Box>
        </React.Fragment>
    )
}
