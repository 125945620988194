// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react';

import {
  Flex,
  // Heading,
  // PrimaryButton,
  // useMeetingManager
} from 'amazon-chime-sdk-component-library-react';

// import { useAppState } from '../../providers/AppStateProvider';
import { StyledList } from './Styled';

const MeetingDetails = () => {
  // const { meetingId,  } = useAppState();
  // const manager = useMeetingManager();

  return (
    <Flex container layout="fill-space-centered">
    {/* <Flex mb="2rem" mr={{ md: '2rem' }} px="1rem"> */}
      <Flex >
        <StyledList>
          {/* <div><dt>Lesson ID</dt><dd>{meetingId}</dd></div>
          <div><dt>Hosted region</dt><dd>{manager.meetingRegion}</dd></div> */}
          <div>オンライン指導</div>
        </StyledList>
        {/* <PrimaryButton
          label={theme === 'light' ? 'Dark mode' : 'Light mode'}
          onClick={toggleTheme}
        ></PrimaryButton> */}
      </Flex>
    </Flex>
  );
};

export default MeetingDetails;
