import React from 'react'
import clsx from 'clsx'
import { isUndefined } from 'util'
// Material-UI
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { SvgIcon } from '@material-ui/core'

// User Types.
import { Trend } from '../Types'

// User Components.

type TrendMarkProps = {
    trend: Trend
} & JSX.IntrinsicElements['input']

export function TrendMark(props: TrendMarkProps) {
    // スタイルを適用する
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                flexGrow: 1,
                padding: '10px',
                // backgroundColor: "#00FF00",
            },
            trendColor_enable: {
                transform: 'scale(1)',
                transition: 'all 0.3s ease',
                '&:hover': {
                    transform: 'scale(1.2) rotate(360deg)',
                },
            },
        }),
    )
    const classes = useStyles()

    function getTrendText(trend: Trend) {
        const className = clsx(
            props.className,
            props.disabled ? '' : classes.trendColor_enable,
        )
        switch (trend) {
            case Trend.up:
                // return '↑'
                return (
                    <Typography
                        variant="h3"
                        style={{ background: '#FBE7F1' }}
                        className={className}
                    >
                        <SvgIcon>
                            <path
                                fill="#EB86B9"
                                d="M13,20H11V8L5.5,13.5L4.08,12.08L12,4.16L19.92,12.08L18.5,13.5L13,8V20Z"
                            />
                        </SvgIcon>
                    </Typography>
                )
            case Trend.flatup:
                // return '↗️'
                return (
                    <Typography
                        variant="h3"
                        style={{ background: '#CDEEF3' }}
                        className={className}
                    >
                        <SvgIcon>
                            <path
                                fill="#05A9C2"
                                d="M5,17.59L15.59,7H9V5H19V15H17V8.41L6.41,19L5,17.59Z"
                            />
                        </SvgIcon>
                    </Typography>
                )
            case Trend.flat:
                // return '→'
                return (
                    <Typography
                        variant="h3"
                        style={{ background: '#FAEED7' }}
                        className={className}
                    >
                        <SvgIcon>
                            <path
                                fill="#E5AC39"
                                d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z"
                            />
                        </SvgIcon>
                    </Typography>
                )
            case Trend.flatdown:
                // return '↘︎'
                return (
                    <Typography
                        variant="h3"
                        style={{ background: '#CDEEF3' }}
                        className={className}
                    >
                        <SvgIcon>
                            <path
                                fill="#05A9C2"
                                d="M5,6.41L6.41,5L17,15.59V9H19V19H9V17H15.59L5,6.41Z"
                            />
                        </SvgIcon>
                    </Typography>
                )
            case Trend.down:
                // return '↓'
                return (
                    <Typography
                        variant="h3"
                        style={{ background: '#FBE7F1' }}
                        className={className}
                    >
                        <SvgIcon>
                            <path
                                fill="#EB86B9"
                                d="M11,4H13V16L18.5,10.5L19.92,11.92L12,19.84L4.08,11.92L5.5,10.5L11,16V4Z"
                            />
                        </SvgIcon>
                    </Typography>
                )
            case Trend.unknown:
                // return '−'
                return (
                    <Typography
                        variant="h3"
                        style={{ background: '#E2E2E2' }}
                        className={className}
                    >
                        <SvgIcon>
                            <path fill="#5A5A5A" d="M19,13H5V11H19V13Z" />
                        </SvgIcon>
                    </Typography>
                )
        }
        // return 'ー'
        return (
            <Typography
                variant="h3"
                style={{ background: '#E2E2E2' }}
                className={className}
            >
                <SvgIcon>
                    <path fill="#5A5A5A" d="M19,13H5V11H19V13Z" />
                </SvgIcon>
            </Typography>
        )
    }

    return getTrendText(props.trend)
}

// ======================================================================================
// for InputExpectation ( Click Event, disabled Color )

type Figure = {
    type: Trend
    background: string
    fill: string
    d: string
}

const figures: Figure[] = [
    {
        type: Trend.up,
        background: '#FBE7F1',
        fill: '#EB86B9',
        d:
            'M13,20H11V8L5.5,13.5L4.08,12.08L12,4.16L19.92,12.08L18.5,13.5L13,8V20Z',
    },
    {
        type: Trend.flatup,
        background: '#CDEEF3',
        fill: '#05A9C2',
        d: 'M5,17.59L15.59,7H9V5H19V15H17V8.41L6.41,19L5,17.59Z',
    },
    {
        type: Trend.flat,
        background: '#FAEED7',
        fill: '#E5AC39',
        d:
            'M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z',
    },
    {
        type: Trend.flatdown,
        background: '#CDEEF3',
        fill: '#05A9C2',
        d: 'M5,6.41L6.41,5L17,15.59V9H19V19H9V17H15.59L5,6.41Z',
    },
    {
        type: Trend.down,
        background: '#FBE7F1',
        fill: '#EB86B9',
        d:
            'M11,4H13V16L18.5,10.5L19.92,11.92L12,19.84L4.08,11.92L5.5,10.5L11,16V4Z',
    },
    {
        type: Trend.unknown,
        background: '#E2E2E2',
        fill: '#5A5A5A',
        d: 'M19,13H5V11H19V13Z',
    },
    // // return 'ー'
    // return (
    //     <Typography
    //         variant="h3"
    //         style={{ background: '#E2E2E2' }}
    //         className={className}
    //     >
    //         <SvgIcon>
    //             <path fill="#5A5A5A" d="M19,13H5V11H19V13Z" />
    //         </SvgIcon>
    //     </Typography>
    // )
]

type TrendMark2Props = {
    select: boolean
    trend: Trend
    onClickHandler?: (event: React.MouseEvent<any, MouseEvent>) => void
} & JSX.IntrinsicElements['input']

export function TrendMark2(props: TrendMark2Props) {
    // スタイルを適用する
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                flexGrow: 1,
                padding: '10px',
                // backgroundColor: "#00FF00",
            },
            trend_selected: {
                background: '#f00',
                transform: 'scale(1.4)',
                transition: 'all 0.3s ease',
                '&:hover': {
                    background: '#fcf',
                    transform: 'scale(1.4)',
                },
            },
            trend_normal: {
                background: '#e2e2e2',
                transform: 'scale(1)',
                transition: 'all 0.3s ease',
                '&:hover': {
                    background: '#fcf',
                    transform: 'scale(1.4)',
                },
            },
            trend_disable: {
                background: '#e2e2e2',
            },
        }),
    )
    const classes = useStyles()

    const className = clsx(
        props.className,
        props.disabled
            ? classes.trend_disable
            : props.select
            ? classes.trend_selected
            : classes.trend_normal,
    )

    const handleClick = (event: React.MouseEvent<any>) => {
        if (!isUndefined(props.onClickHandler)) {
            props.onClickHandler(event)
        }
    }

    let figure = figures.find((figure) => figure.type === props.trend)
    if (isUndefined(figure)) {
        // // return 'ー'
        return (
            <Typography
                variant="h3"
                style={{ background: '#E2E2E2' }}
                className={className}
            >
                <SvgIcon>
                    <path fill="#5A5A5A" d="M19,13H5V11H19V13Z" />
                </SvgIcon>
            </Typography>
        )
    }

    return (
        <Typography
            variant="h3"
            // style={{ background: props.disabled ? "#E2E2E2" : props.select ? "#f00" : figure.background }}
            className={className}
            onClick={handleClick}
        >
            <SvgIcon>
                <path
                    fill={props.disabled ? '#A9A9A9' : figure.fill}
                    d={figure.d}
                />
            </SvgIcon>
        </Typography>
    )
}
