import React from 'react'
import { isUndefined } from 'util'
import moment from 'moment'
import * as DateUtil from 'date-fns'
import * as DataUtil from '../modules/DataUtil'
import { useAppDispatch } from 'store'
import { useFacility, useProfile } from 'modules/AppModule'
import { fetchUserExport } from 'modules/UserModule'
import { Controller, useForm } from 'react-hook-form'
import { Facility, User, Staff, OptionValue } from 'Types'
import { OPTION_ALL } from 'Const'
import { Box, createStyles, makeStyles, Theme, MenuItem, Typography, Button, FormHelperText } from '@material-ui/core'
import { fetchFacilityUserOptions } from '../modules/FacilityModule'
import TitleBar from './TitleBar'
import { InputTextField } from './Inputs'
import MainDatePicker from './MainDatePicker'
import { MainButton } from './Buttons'
const XlsxPopulate = require('xlsx-populate')

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: '35px auto',
            width: 960,
        },
        paper: {
            width: 650,
            margin: '80px auto 0',
        },
        form: {
            width: '100%',
            flexGrow: 1,
            marginTop: theme.spacing(4),
        },
        menuList: {
            '& .MuiPaper-rounded': {
                borderRadius: 'unset',
            },
            '& .MuiMenu-list': {
                border: '1px solid #B6B6B6',
                borderRadius: 'unset',
                width: 300,
            },
            '& .MuiList-padding': {
                padding: 0,
            },
        },
        selection: {
            width: 300,
            marginBottom: theme.spacing(2),
            '& .MuiSelect-select': {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                minHeight: 32,
                boxSizing: 'border-box',
                padding: '8px 30px 8px 12px',
                borderColor: '#B6B6B6',
            },
            '& .MuiSelect-icon': {
                boxShadow: 'none !important',
                position: 'absolute',
                top: 12,
            },
            '& .MuiSelect-iconOpen': {
                transform: 'none',
                zIndex: 9999,
            },
            '& .MuiInputLabel-root': {
                display: 'block',
                color: '#05A9C2',
            },
        },
        menuItem: {
            display: 'flex',
            justifyContent: 'space-between',
            minHeight: 32,
            padding: '8px 30px 8px 12px',
            borderBottom: '1px solid #B6B6B6',
            '&:last-child': {
                borderBottom: 'none',
            },
        },
        optionLabel: {
            lineHeight: 1.2,
        },
        datePicker: {
            display: 'block',
            width: 250,
            padding: 10,
            border: '1px solid #B6B6B6',
        },
        label: {
            color: '#05A9C2',
            marginBottom: 4,
        },
        btnSelectDate: {
            marginRight: 6,
            minWidth: 100,
        },
    }),
)

const SHEET = {
    MSPA_EVALUATION: 'MSPA評価シート',
    GROWTH_RECORD_LIST: '成長記録リスト',
}

const COLOR = {
    LIGHT_BLUE: 'CDEEF3',
    YELLOW: 'FFFF00',
    GRAY: 'B6B6B6',
    WHITE: 'FFFFFF',
}

const LESSON_START_ROW = 17
const MAX_LESSON_ROWS_WITH_STYLES = 670
const BLANK_ROW = 1

enum PERIOD_DATE {
    LAST_WEEK = 'last_week',
    LAST_MONTH = 'last_month',
    HALF_A_YEAR = 'half_a_year',
}

type FormState = {
    user_id: string
    staff_id: string
    from_date: any
    to_date: any
}

type LocalState = {
    users: any
    userOptions: OptionValue[]
    staffOptions: OptionValue[]
}

const initialValue = {
    user_id: '',
    staff_id: OPTION_ALL.value,
    from_date: new Date(),
    to_date: new Date(),
}

function StaffExport() {
    const classes = useStyles()
    const dispatch = useAppDispatch()
    const facility = useFacility()
    const facilityId = getFacilityId(facility)
    const facilityName = getFacilityName(facility)
    const staffProfile = useProfile() as Staff
    const isShowCommentOfParent = !!getStaffShowCommentOfParent(staffProfile)
    const staffProfileName = getStaffName(staffProfile)
    const [state, setState] = React.useState<LocalState>({
        users: [],
        userOptions: [],
        staffOptions: [],
    })
    const {
        control,
        handleSubmit,
        errors,
        formState: { isSubmitting },
        setValue,
        setError,
        reset,
        watch,
        getValues,
    } = useForm<FormState>({
        defaultValues: initialValue,
    })

    React.useEffect(() => {
        if (!facilityId) return
        dispatch(fetchFacilityUserOptions(facilityId)).then((res) => {
            if (res.payload) {
                const users = res.payload
                const userOptions = getUserOptions(users)
                setState({
                    ...state,
                    users: users,
                    userOptions: userOptions,
                })
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [facilityId])

    const handleDateSelect = (type: PERIOD_DATE) => {
        switch (type) {
            case PERIOD_DATE.LAST_WEEK:
                setValue('from_date', moment().subtract(1, 'weeks').startOf('isoWeek').toDate())
                setValue('to_date', moment().subtract(1, 'weeks').endOf('isoWeek').toDate())
                break
            case PERIOD_DATE.LAST_MONTH:
                setValue('from_date', moment().subtract(1, 'months').startOf('month').toDate())
                setValue('to_date', moment().subtract(1, 'months').endOf('month').toDate())
                break
            case PERIOD_DATE.HALF_A_YEAR:
            default:
                setValue('from_date', moment().subtract(6, 'months').startOf('month').toDate())
                setValue('to_date', moment().subtract(1, 'months').endOf('month').toDate())
                break
        }
    }

    // Switch from_date and to_date
    if (watch('to_date')) {
        const toDate = watch('to_date')
        const fromDate = getValues('from_date')
        if (fromDate && toDate < fromDate) {
            setValue('from_date', toDate)
            setValue('to_date', fromDate)
        }
    }

    const handleUserChange = (userId: string) => {
        const user = state.users.find((user: User) => user.user_id === userId)
        if (!user) return
        const staffOptions = getStaffOptions(user.staffs)
        reset({ staff_id: OPTION_ALL.value })
        setState({
            ...state,
            staffOptions: staffOptions,
        })
    }

    const onSubmit = async (data: FormState) => {
        if (data.staff_id === OPTION_ALL.value && state.staffOptions.length === 0) {
            setError('staff_id', {
                type: 'manual',
                message: 'ユーザのデータが存在しません。',
            })
            return
        }
        let staffIds = [data.staff_id]
        if (data.staff_id === OPTION_ALL.value) {
            staffIds = state.staffOptions.map((staffOption) => staffOption.value)
        }
        const param = {
            user_id: data.user_id,
            staff_ids: staffIds,
            start: DateUtil.format(data.from_date, 'yyyyMMdd'),
            end: DateUtil.format(data.to_date, 'yyyyMMdd'),
        }
        console.log('StaffExport-fetchUserExport-param: ', param)
        await dispatch(fetchUserExport(param)).then(async (res) => {
            console.log('StaffExport-fetchUserExport-res: ', res.payload)
            const user = res.payload
            if (user) {
                await handleExportExcel(user, isShowCommentOfParent, facilityName, staffProfileName)
            }
        })
        reset(initialValue)
    }

    return (
        <Box className={classes.root}>
            <TitleBar bgBlue label="エクスポート" />
            <Box className={classes.paper}>
                <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                    <Box display="flex" justifyContent="space-between">
                        <Controller
                            render={({ onChange, value }) => (
                                <InputTextField
                                    className={classes.selection}
                                    fullWidth
                                    select
                                    label="ユーザ選択"
                                    value={value}
                                    onChange={(e: any) => {
                                        onChange(e.target.value)
                                        handleUserChange(e.target.value)
                                    }}
                                    SelectProps={{
                                        MenuProps: {
                                            className: classes.menuList,
                                            getContentAnchorEl: null,
                                        },
                                    }}
                                    error={Boolean(errors.user_id)}
                                    helperText={errors.user_id && errors.user_id.message}
                                >
                                    {state.userOptions.map((option, index) => {
                                        return (
                                            <MenuItem key={index} value={option.value} className={classes.menuItem}>
                                                <Typography className={classes.optionLabel}>{option.label}</Typography>
                                                <Typography className={classes.optionLabel}>{option.optionLabel}</Typography>
                                            </MenuItem>
                                        )
                                    })}
                                </InputTextField>
                            )}
                            name="user_id"
                            control={control}
                            rules={{
                                required: 'ユーザ選択トは必須です',
                            }}
                        />
                        <Controller
                            as={
                                <InputTextField
                                    className={classes.selection}
                                    fullWidth
                                    select
                                    SelectProps={{
                                        MenuProps: {
                                            className: classes.menuList,
                                            getContentAnchorEl: null,
                                        },
                                    }}
                                >
                                    <MenuItem value={OPTION_ALL.value} className={classes.menuItem}>
                                        <Typography className={classes.optionLabel}>{OPTION_ALL.label}</Typography>
                                    </MenuItem>
                                    {state.staffOptions.map((option, index) => {
                                        return (
                                            <MenuItem key={index} value={option.value} className={classes.menuItem}>
                                                <Typography className={classes.optionLabel}>{option.label}</Typography>
                                                <Typography className={classes.optionLabel}>{option.optionLabel}</Typography>
                                            </MenuItem>
                                        )
                                    })}
                                </InputTextField>
                            }
                            label="職員選択"
                            name="staff_id"
                            defaultValue=""
                            control={control}
                            rules={{
                                required: '職員選択トは必須です',
                            }}
                            error={Boolean(errors.staff_id)}
                            helperText={errors.staff_id && errors.staff_id.message}
                        />
                    </Box>
                    <Box>
                        <Typography className={classes.label}>期間選択</Typography>
                        <Box display="inline-block">
                            <Controller
                                render={({ onChange, value }) => (
                                    <MainDatePicker className={classes.datePicker} name="from_date" selected={value} onChange={onChange} />
                                )}
                                name="from_date"
                                control={control}
                                rules={{ required: '期間選択トは必須です' }}
                            />
                        </Box>
                        <Box display="inline-block" margin="0 10px">
                            〜
                        </Box>
                        <Box display="inline-block">
                            <Controller
                                render={({ onChange, value }) => (
                                    <MainDatePicker className={classes.datePicker} name="to_date" selected={value} onChange={onChange} />
                                )}
                                name="to_date"
                                control={control}
                                rules={{ required: '期間選択トは必須です' }}
                            />
                        </Box>
                        <FormHelperText error={Boolean(errors.from_date || errors.to_date)}>
                            {errors.from_date ? errors.from_date.message : errors.to_date ? errors.to_date.message : ''}
                        </FormHelperText>
                        <Box display="flex" marginTop="16px">
                            <Button className={classes.btnSelectDate} variant="contained" onClick={() => handleDateSelect(PERIOD_DATE.LAST_WEEK)}>
                                先週
                            </Button>
                            <Button className={classes.btnSelectDate} variant="contained" onClick={() => handleDateSelect(PERIOD_DATE.LAST_MONTH)}>
                                先月
                            </Button>
                            <Button className={classes.btnSelectDate} variant="contained" onClick={() => handleDateSelect(PERIOD_DATE.HALF_A_YEAR)}>
                                半年
                            </Button>
                        </Box>
                    </Box>
                    <Box display="flex" justifyContent="center" marginTop="40px">
                        <MainButton type="submit" disabled={isSubmitting}>
                            エクスポート
                        </MainButton>
                    </Box>
                </form>
            </Box>
        </Box>
    )
}

async function handleExportExcel(user: any, isShowCommentOfParent: boolean, facilityName: string, staffProfileName: string) {
    const MSPALevels = user?.mspa_evaluation?.mspa_levels ?? []
    const file = await fetch(`/templates/user_template_${MSPALevels.length}.xlsx`)
    const ab = await file.arrayBuffer()
    XlsxPopulate.fromDataAsync(ab).then((workbook: any) => {
        // ========== Modify the MSPA評価シート workbook ==========
        const SHEET_MSPA_EVALUATION = workbook.sheet(SHEET.MSPA_EVALUATION)
        // Modify header
        modifyHeaderCells(SHEET_MSPA_EVALUATION, user, facilityName, staffProfileName)
        // Modify chart
        for (const [index, MSPALevel] of MSPALevels.entries()) {
            SHEET_MSPA_EVALUATION.cell(`B${17 + index * 2}`).value(MSPALevel?.mspa_title ?? '')
            SHEET_MSPA_EVALUATION.cell(`I${17 + index * 2}`).value(convertRatingToValue(MSPALevel?.level ?? ''))
        }
        // Modify note
        SHEET_MSPA_EVALUATION.cell('B57').value(user?.mspa_evaluation?.characteristic ?? '')
        SHEET_MSPA_EVALUATION.cell('AB57').value(user?.mspa_evaluation?.note ?? '')

        // ========== Modify the 成長記録リスト workbook ==========
        const SHEET_GROWTH_RECORD_LIST = workbook.sheet(SHEET.GROWTH_RECORD_LIST)
        // Modify header
        modifyHeaderCells(SHEET_GROWTH_RECORD_LIST, user, facilityName, staffProfileName)

        // Check export with styles or not to prevent "too many cell formats" issue on MSExcel
        const lessons = user?.lessons ?? []
        const totalLessonsRowsExcludeBlankRow = lessons.reduce((accumulator: any, currentValue: any) => {
            const growthRecords = currentValue.growth_records ?? []
            const growthRecordLength = growthRecords.length ? growthRecords.length : 1
            return accumulator + calcTotalOneLessonRows(isShowCommentOfParent, growthRecordLength)
        }, LESSON_START_ROW)
        console.log('StaffExport-totalLessonsRowsExcludeBlankRow: ', totalLessonsRowsExcludeBlankRow)
        if (totalLessonsRowsExcludeBlankRow <= MAX_LESSON_ROWS_WITH_STYLES) {
            setLessonCells(SHEET_GROWTH_RECORD_LIST, lessons, isShowCommentOfParent)
        } else {
            setLessonCellsWithoutStyles(SHEET_GROWTH_RECORD_LIST, lessons, isShowCommentOfParent)
        }

        workbook.activeSheet(SHEET.MSPA_EVALUATION)
        // Write to file.
        workbook.outputAsync().then(function (blob: any) {
            const fileName = `帳票_${staffProfileName}_${user?.user_name ?? ''}_${DateUtil.format(new Date(), 'yyyyMMdd')}`
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                // If IE, you must uses a different method.
                window.navigator.msSaveOrOpenBlob(blob, `${fileName}.xlsx`)
            } else {
                var url = window.URL.createObjectURL(blob)
                var a: any = document.createElement('a')
                document.body.appendChild(a)
                a.href = url
                a.download = `${fileName}.xlsx`
                a.click()
                window.URL.revokeObjectURL(url)
                document.body.removeChild(a)
            }
        })
    })
}

function modifyHeaderCells(SHEET: any, user: any, facilityName: string, staffProfileName: string) {
    SHEET.cell('B2').value(facilityName)
    SHEET.cell('AM6').value(staffProfileName)
    SHEET.cell('E9').value(user?.user_name ?? '')
    SHEET.cell('E10').value(DataUtil.getAge(user?.birth_date ?? 0))
    SHEET.cell('E11').value(DataUtil.getGenderName(user?.gender_id ?? 0))
    SHEET.cell('AD9').value(user?.parent_1 ?? '')
    SHEET.cell('AD10').value(user?.parent_2 ?? '')
}

function setLessonCells(SHEET_GROWTH_RECORD_LIST: any, lessons: any[], isShowCommentOfParent: boolean) {
    let startRow = LESSON_START_ROW
    for (const lesson of lessons) {
        // Set lesson information label
        SHEET_GROWTH_RECORD_LIST.range(`B${startRow}:E${startRow + 1}`)
            .value('ステータス')
            .style({
                horizontalAlignment: 'center',
                fill: COLOR.LIGHT_BLUE,
                border: true,
            })
            .merged(true)
        SHEET_GROWTH_RECORD_LIST.range(`B${startRow + 4}:E${startRow + 4}`)
            .value('レベル')
            .style({
                horizontalAlignment: 'center',
                fill: COLOR.LIGHT_BLUE,
                border: true,
            })
            .merged(true)
        SHEET_GROWTH_RECORD_LIST.range(`F${startRow}:J${startRow}`)
            .value('カリキュラム名')
            .style({
                horizontalAlignment: 'center',
                fill: COLOR.LIGHT_BLUE,
                border: true,
            })
            .merged(true)
        SHEET_GROWTH_RECORD_LIST.range(`F${startRow + 1}:J${startRow + 1}`)
            .value('トレーニング名')
            .style({
                horizontalAlignment: 'center',
                fill: COLOR.LIGHT_BLUE,
                border: true,
            })
            .merged(true)
        SHEET_GROWTH_RECORD_LIST.range(`F${startRow + 2}:J${startRow + 2}`)
            .value('説明')
            .style({
                horizontalAlignment: 'center',
                fill: COLOR.LIGHT_BLUE,
                border: true,
            })
            .merged(true)
        SHEET_GROWTH_RECORD_LIST.range(`F${startRow + 3}:J${startRow + 3}`)
            .value('タグ')
            .style({
                horizontalAlignment: 'center',
                fill: COLOR.LIGHT_BLUE,
                border: true,
            })
            .merged(true)
        SHEET_GROWTH_RECORD_LIST.range(`F${startRow + 4}:J${startRow + 4}`)
            .value('担当者名')
            .style({
                horizontalAlignment: 'center',
                fill: COLOR.LIGHT_BLUE,
                border: true,
            })
            .merged(true)
        SHEET_GROWTH_RECORD_LIST.range(`F${startRow + 5}:J${startRow + 5}`)
            .value('予約日時')
            .style({
                horizontalAlignment: 'center',
                fill: COLOR.LIGHT_BLUE,
                border: true,
            })
            .merged(true)
        // Set lesson information value
        SHEET_GROWTH_RECORD_LIST.range(`B${startRow + 2}:E${startRow + 3}`)
            .value(DataUtil.getLessonStatusText(lesson?.status ?? ''))
            .style({
                horizontalAlignment: 'center',
                border: true,
            })
            .merged(true)
        SHEET_GROWTH_RECORD_LIST.range(`B${startRow + 5}:C${startRow + 5}`)
            .value(lesson?.training?.min_level ?? 0)
            .style({
                horizontalAlignment: 'center',
                border: true,
            })
            .merged(true)
        SHEET_GROWTH_RECORD_LIST.range(`D${startRow + 5}:E${startRow + 5}`)
            .value(lesson?.training?.max_level ?? 0)
            .style({
                horizontalAlignment: 'center',
                border: true,
            })
            .merged(true)
        SHEET_GROWTH_RECORD_LIST.range(`K${startRow}:AY${startRow}`)
            .value(lesson?.course?.course_name ?? '')
            .style({
                border: true,
            })
            .merged(true)
        SHEET_GROWTH_RECORD_LIST.range(`K${startRow + 1}:AY${startRow + 1}`)
            .value(lesson?.training?.training_name ?? '')
            .style({
                border: true,
            })
            .merged(true)
        SHEET_GROWTH_RECORD_LIST.range(`K${startRow + 2}:AY${startRow + 2}`)
            .value(lesson?.training?.description ?? '')
            .style({
                border: true,
            })
            .merged(true)
        SHEET_GROWTH_RECORD_LIST.range(`K${startRow + 3}:AY${startRow + 3}`)
            .value(lesson?.training?.tags?.map((tag: any) => tag?.tag ?? '')?.join(', ') ?? '')
            .style({
                border: true,
            })
            .merged(true)
        SHEET_GROWTH_RECORD_LIST.range(`K${startRow + 4}:AY${startRow + 4}`)
            .value(lesson?.staff?.staff_name ?? '')
            .style({
                border: true,
            })
            .merged(true)
        SHEET_GROWTH_RECORD_LIST.range(`K${startRow + 5}:AY${startRow + 5}`)
            .value(lesson?.start ?? '')
            .style({
                border: true,
            })
            .merged(true)
        // Set prev and current lesson comment
        SHEET_GROWTH_RECORD_LIST.range(`B${startRow + 6}:G${startRow + 9}`)
            .value('前回の課題')
            .style({
                horizontalAlignment: 'center',
                fill: COLOR.LIGHT_BLUE,
                border: true,
            })
            .merged(true)
        SHEET_GROWTH_RECORD_LIST.range(`H${startRow + 6}:AY${startRow + 9}`)
            .value(lesson?.comment?.comment_prev ?? '')
            .style({
                border: true,
                verticalAlignment: 'top',
            })
            .merged(true)
        SHEET_GROWTH_RECORD_LIST.range(`B${startRow + 10}:G${startRow + 13}`)
            .value('受講状況')
            .style({
                horizontalAlignment: 'center',
                fill: COLOR.LIGHT_BLUE,
                border: true,
            })
            .merged(true)
        SHEET_GROWTH_RECORD_LIST.range(`H${startRow + 10}:AY${startRow + 13}`)
            .value(lesson?.comment?.comment_current ?? '')
            .style({
                border: true,
                verticalAlignment: 'top',
            })
            .merged(true)
        // Set lesson growth records label
        SHEET_GROWTH_RECORD_LIST.range(`B${startRow + 14}:D${startRow + 14}`)
            .value('達成度')
            .style({
                horizontalAlignment: 'center',
                fill: COLOR.GRAY,
                fontSize: 12,
                fontColor: COLOR.WHITE,
                border: true,
            })
            .merged(true)
        SHEET_GROWTH_RECORD_LIST.range(`E${startRow + 14}:L${startRow + 14}`)
            .value('MSPA評価指標')
            .style({
                horizontalAlignment: 'center',
                fill: COLOR.GRAY,
                fontColor: COLOR.WHITE,
                border: true,
            })
            .merged(true)
        SHEET_GROWTH_RECORD_LIST.range(`M${startRow + 14}:P${startRow + 14}`)
            .value('期待効果')
            .style({
                horizontalAlignment: 'center',
                fill: COLOR.GRAY,
                fontColor: COLOR.WHITE,
                border: true,
            })
            .merged(true)
        SHEET_GROWTH_RECORD_LIST.range(`Q${startRow + 14}:AY${startRow + 14}`)
            .value('コメント')
            .style({
                horizontalAlignment: 'center',
                fill: COLOR.GRAY,
                fontColor: COLOR.WHITE,
                border: true,
            })
            .merged(true)
        // Set lesson growth records value
        const growthRecords = lesson.growth_records ?? []
        // Add blank row if having no lesson growth records
        const growthRecordLength = growthRecords.length ? growthRecords.length : 1
        if (growthRecords.length === 0) {
            SHEET_GROWTH_RECORD_LIST.range(`B${startRow + 15}:D${startRow + 16}`)
                .value('')
                .style({
                    horizontalAlignment: 'center',
                    border: true,
                })
                .merged(true)
            SHEET_GROWTH_RECORD_LIST.range(`E${startRow + 15}:L${startRow + 16}`)
                .value('')
                .style({
                    horizontalAlignment: 'center',
                    border: true,
                })
                .merged(true)
            SHEET_GROWTH_RECORD_LIST.range(`M${startRow + 15}:P${startRow + 16}`)
                .value('')
                .style({
                    horizontalAlignment: 'center',
                    border: true,
                })
                .merged(true)
            SHEET_GROWTH_RECORD_LIST.range(`Q${startRow + 15}:AY${startRow + 16}`)
                .value('')
                .style({
                    verticalAlignment: 'center',
                    border: true,
                })
                .merged(true)
        }
        for (const [index, growthRecord] of growthRecords.entries()) {
            SHEET_GROWTH_RECORD_LIST.range(`B${startRow + 15 + index * 2}:D${startRow + 16 + index * 2}`)
                .value(growthRecord?.achievement ?? 0)
                .style({
                    horizontalAlignment: 'center',
                    border: true,
                })
                .merged(true)
            SHEET_GROWTH_RECORD_LIST.range(`E${startRow + 15 + index * 2}:L${startRow + 16 + index * 2}`)
                .value(growthRecord?.mspa_title ?? '')
                .style({
                    horizontalAlignment: 'center',
                    border: true,
                })
                .merged(true)
            SHEET_GROWTH_RECORD_LIST.range(`M${startRow + 15 + index * 2}:P${startRow + 16 + index * 2}`)
                .value(getExpectationIcon(growthRecord?.expectation ?? ''))
                .style({
                    horizontalAlignment: 'center',
                    border: true,
                })
                .merged(true)
            SHEET_GROWTH_RECORD_LIST.range(`Q${startRow + 15 + index * 2}:AY${startRow + 16 + index * 2}`)
                .value(growthRecord?.comment)
                .style({
                    verticalAlignment: 'center',
                    border: true,
                })
                .merged(true)
        }
        // Set next lesson comment and comment of parent
        SHEET_GROWTH_RECORD_LIST.range(`B${startRow + 15 + growthRecordLength * 2}:G${startRow + 18 + growthRecordLength * 2}`)
            .value('次回への課題')
            .style({
                horizontalAlignment: 'center',
                fill: COLOR.LIGHT_BLUE,
                border: true,
            })
            .merged(true)
        SHEET_GROWTH_RECORD_LIST.range(`H${startRow + 15 + growthRecordLength * 2}:AY${startRow + 18 + growthRecordLength * 2}`)
            .value(lesson?.comment?.comment_next ?? '')
            .style({
                border: true,
                verticalAlignment: 'top',
            })
            .merged(true)
        if (isShowCommentOfParent) {
            SHEET_GROWTH_RECORD_LIST.range(`B${startRow + 19 + growthRecordLength * 2}:G${startRow + 22 + growthRecordLength * 2}`)
                .value('保護者からのコメント')
                .style({
                    horizontalAlignment: 'center',
                    fill: COLOR.LIGHT_BLUE,
                    border: true,
                })
                .merged(true)
            SHEET_GROWTH_RECORD_LIST.range(`H${startRow + 19 + growthRecordLength * 2}:AY${startRow + 22 + growthRecordLength * 2}`)
                .value(lesson?.comment?.comment_of_parent ?? '')
                .style({
                    border: true,
                    verticalAlignment: 'top',
                })
                .merged(true)
        }
        startRow = startRow + calcTotalOneLessonRows(isShowCommentOfParent, growthRecordLength) + BLANK_ROW
    }
}

function setLessonCellsWithoutStyles(SHEET_GROWTH_RECORD_LIST: any, lessons: any[], isShowCommentOfParent: boolean) {
    let startRow = LESSON_START_ROW
    for (const lesson of lessons) {
        // Set lesson information label
        SHEET_GROWTH_RECORD_LIST.range(`B${startRow}:E${startRow + 1}`)
            .value('ステータス')
            .merged(true)
        SHEET_GROWTH_RECORD_LIST.range(`B${startRow + 4}:E${startRow + 4}`)
            .value('レベル')
            .merged(true)
        SHEET_GROWTH_RECORD_LIST.range(`F${startRow}:J${startRow}`).value('カリキュラム名').merged(true)
        SHEET_GROWTH_RECORD_LIST.range(`F${startRow + 1}:J${startRow + 1}`)
            .value('トレーニング名')
            .merged(true)
        SHEET_GROWTH_RECORD_LIST.range(`F${startRow + 2}:J${startRow + 2}`)
            .value('説明')
            .merged(true)
        SHEET_GROWTH_RECORD_LIST.range(`F${startRow + 3}:J${startRow + 3}`)
            .value('タグ')
            .merged(true)
        SHEET_GROWTH_RECORD_LIST.range(`F${startRow + 4}:J${startRow + 4}`)
            .value('担当者名')
            .merged(true)
        SHEET_GROWTH_RECORD_LIST.range(`F${startRow + 5}:J${startRow + 5}`)
            .value('予約日時')
            .merged(true)
        // Set lesson information value
        SHEET_GROWTH_RECORD_LIST.range(`B${startRow + 2}:E${startRow + 3}`)
            .value(DataUtil.getLessonStatusText(lesson?.status ?? ''))
            .merged(true)
        SHEET_GROWTH_RECORD_LIST.range(`B${startRow + 5}:C${startRow + 5}`)
            .value(lesson?.training?.min_level ?? 0)
            .merged(true)
        SHEET_GROWTH_RECORD_LIST.range(`D${startRow + 5}:E${startRow + 5}`)
            .value(lesson?.training?.max_level ?? 0)
            .merged(true)
        SHEET_GROWTH_RECORD_LIST.range(`K${startRow}:AY${startRow}`)
            .value(lesson?.course?.course_name ?? '')
            .merged(true)
        SHEET_GROWTH_RECORD_LIST.range(`K${startRow + 1}:AY${startRow + 1}`)
            .value(lesson?.training?.training_name ?? '')
            .merged(true)
        SHEET_GROWTH_RECORD_LIST.range(`K${startRow + 2}:AY${startRow + 2}`)
            .value(lesson?.training?.description ?? '')
            .merged(true)
        SHEET_GROWTH_RECORD_LIST.range(`K${startRow + 3}:AY${startRow + 3}`)
            .value(lesson?.training?.tags?.map((tag: any) => tag?.tag ?? '')?.join(', ') ?? '')
            .merged(true)
        SHEET_GROWTH_RECORD_LIST.range(`K${startRow + 4}:AY${startRow + 4}`)
            .value(lesson?.staff?.staff_name ?? '')
            .merged(true)
        SHEET_GROWTH_RECORD_LIST.range(`K${startRow + 5}:AY${startRow + 5}`)
            .value(lesson?.start ?? '')
            .merged(true)
        // Set prev and current lesson comment
        SHEET_GROWTH_RECORD_LIST.range(`B${startRow + 6}:G${startRow + 9}`)
            .value('前回の課題')
            .merged(true)
        SHEET_GROWTH_RECORD_LIST.range(`H${startRow + 6}:AY${startRow + 9}`)
            .value(lesson?.comment?.comment_prev ?? '')
            .merged(true)
        SHEET_GROWTH_RECORD_LIST.range(`B${startRow + 10}:G${startRow + 13}`)
            .value('受講状況')
            .merged(true)
        SHEET_GROWTH_RECORD_LIST.range(`H${startRow + 10}:AY${startRow + 13}`)
            .value(lesson?.comment?.comment_current ?? '')
            .merged(true)
        // Set lesson growth records label
        SHEET_GROWTH_RECORD_LIST.range(`B${startRow + 14}:D${startRow + 14}`)
            .value('達成度')
            .merged(true)
        SHEET_GROWTH_RECORD_LIST.range(`E${startRow + 14}:L${startRow + 14}`)
            .value('MSPA評価指標')
            .merged(true)
        SHEET_GROWTH_RECORD_LIST.range(`M${startRow + 14}:P${startRow + 14}`)
            .value('期待効果')
            .merged(true)
        SHEET_GROWTH_RECORD_LIST.range(`Q${startRow + 14}:AY${startRow + 14}`)
            .value('コメント')
            .merged(true)
        // Set lesson growth records value
        const growthRecords = lesson.growth_records ?? []
        // Add blank row if having no lesson growth records
        const growthRecordLength = growthRecords.length ? growthRecords.length : 1
        if (growthRecords.length === 0) {
            SHEET_GROWTH_RECORD_LIST.range(`B${startRow + 15}:D${startRow + 16}`)
                .value('')
                .merged(true)
            SHEET_GROWTH_RECORD_LIST.range(`E${startRow + 15}:L${startRow + 16}`)
                .value('')
                .merged(true)
            SHEET_GROWTH_RECORD_LIST.range(`M${startRow + 15}:P${startRow + 16}`)
                .value('')
                .merged(true)
            SHEET_GROWTH_RECORD_LIST.range(`Q${startRow + 15}:AY${startRow + 16}`)
                .value('')
                .merged(true)
        }
        for (const [index, growthRecord] of growthRecords.entries()) {
            SHEET_GROWTH_RECORD_LIST.range(`B${startRow + 15 + index * 2}:D${startRow + 16 + index * 2}`)
                .value(growthRecord?.achievement ?? 0)
                .merged(true)
            SHEET_GROWTH_RECORD_LIST.range(`E${startRow + 15 + index * 2}:L${startRow + 16 + index * 2}`)
                .value(growthRecord?.mspa_title ?? '')
                .merged(true)
            SHEET_GROWTH_RECORD_LIST.range(`M${startRow + 15 + index * 2}:P${startRow + 16 + index * 2}`)
                .value(getExpectationIcon(growthRecord?.expectation ?? ''))
                .merged(true)
            SHEET_GROWTH_RECORD_LIST.range(`Q${startRow + 15 + index * 2}:AY${startRow + 16 + index * 2}`)
                .value(growthRecord?.comment)
                .merged(true)
        }
        // Set next lesson comment and comment of parent
        SHEET_GROWTH_RECORD_LIST.range(`B${startRow + 15 + growthRecordLength * 2}:G${startRow + 18 + growthRecordLength * 2}`)
            .value('次回への課題')
            .merged(true)
        SHEET_GROWTH_RECORD_LIST.range(`H${startRow + 15 + growthRecordLength * 2}:AY${startRow + 18 + growthRecordLength * 2}`)
            .value(lesson?.comment?.comment_next ?? '')
            .merged(true)
        if (isShowCommentOfParent) {
            SHEET_GROWTH_RECORD_LIST.range(`B${startRow + 19 + growthRecordLength * 2}:G${startRow + 22 + growthRecordLength * 2}`)
                .value('保護者からのコメント')
                .merged(true)
            SHEET_GROWTH_RECORD_LIST.range(`H${startRow + 19 + growthRecordLength * 2}:AY${startRow + 22 + growthRecordLength * 2}`)
                .value(lesson?.comment?.comment_next ?? '')
                .merged(true)
        }
        startRow = startRow + calcTotalOneLessonRows(isShowCommentOfParent, growthRecordLength) + BLANK_ROW
    }
}

function getUserOptions(users: User[]): OptionValue[] {
    return [...users].map((user) => {
        return {
            value: getUserId(user),
            label: getUserName(user),
            optionLabel: getUserParent(user),
        }
    })
}

function getStaffOptions(staffs: Staff[]): OptionValue[] {
    return [...staffs].map((staff) => {
        const staffId = getStaffId(staff)
        return {
            value: staffId,
            label: getStaffName(staff),
            optionLabel: staffId,
        }
    })
}

function getFacilityId(facility?: Facility) {
    return isUndefined(facility) || isUndefined(facility.facility_id) ? '' : facility.facility_id
}

function getFacilityName(facility?: Facility) {
    return isUndefined(facility) || isUndefined(facility.facility_name) ? '' : facility.facility_name
}

function getUserName(user: User): string {
    return isUndefined(user) || isUndefined(user.user_name) ? '' : user.user_name
}

function getUserParent(user: User): string {
    return isUndefined(user) || isUndefined(user.parent_1) ? '' : user.parent_1
}

function getUserId(user: User): string {
    return isUndefined(user) || isUndefined(user.user_id) ? '' : user.user_id
}

function getStaffName(staff: Staff): string {
    return isUndefined(staff) || isUndefined(staff.staff_name) ? '' : staff.staff_name
}

function getStaffId(staff: Staff): string {
    return isUndefined(staff) || isUndefined(staff.staff_id) ? '' : staff.staff_id
}

function getStaffShowCommentOfParent(staff: Staff): number {
    return isUndefined(staff) || isUndefined(staff.show_comment_of_parent) ? 0 : staff.show_comment_of_parent
}

function convertRatingToValue(rating?: number) {
    return !!rating ? (rating - 1) / 2 + 1 : ''
}

function getExpectationIcon(value: number) {
    return value === 3 ? '◎' : value === 2 ? '○' : value === 1 ? '△' : ''
}

function calcTotalOneLessonRows(isShowCommentOfParent: boolean, growthRecordLength: number) {
    const totalDefaultRows = 19
    const totalCommentOfParentRows = 4
    const totalGrowthRecordsRows = growthRecordLength * 2
    return totalDefaultRows + (isShowCommentOfParent ? totalCommentOfParentRows : 0) + totalGrowthRecordsRows
}

export default StaffExport
