// import * as Const from '../Const'
// import { trace } from '../common/index'
// import clsx from 'clsx'
import { isUndefined } from 'util'

// import moment from 'moment'
import * as DateUtil from 'date-fns'

// import 'react-calendar/dist/Calendar.css';
// import './calendar.css'

import React from 'react'
import { useProfile, useFacility } from '../modules/AppModule'

// Material-UI
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Typography, Box, Grid } from '@material-ui/core'

import Pulldown from '../components/Pulldown'
import TitleBar from './TitleBar'
import AttendanceList from './AttendanceList'
import { DoublePrevButton, DoubleNextButton, TodayButton2 } from './Buttons'
import { Staff, OptionValue } from '../Types'
// スタイルを適用する
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            padding: '10px',
            // backgroundColor: "#00FF00",
        },
        content: {
            width: 960,
            margin: '35px auto 20px',
        },
        pulldown: {
            backgroundColor: '#fff', //theme.palette.background.default,
        },
        staffName: {
            float: 'left',
            marginTop: 11,
            marginRight: 10,
        },
    }),
)
type LocalState = {
    staff_id: string
    date: Date
}

type AttendanceContentProps = {
    editable?: boolean
}

export default function AttendanceContent(props: AttendanceContentProps) {
    const classes = useStyles()

    // 施設情報.
    const facility = useFacility()

    // 検索バーからの対応.
    // 自身の場合.
    const staff = useProfile() as Staff

    const [state, setState] = React.useState<LocalState>({
        staff_id: staff !== undefined && staff.staff_id !== undefined ? staff.staff_id : '',
        date: new Date(),
    })
    const [selectedValue, setSelectedValue] = React.useState<string>(state.staff_id)
    const [staffOptions, setStaffOptions] = React.useState<OptionValue[]>([])

    const staff_id = isUndefined(staff) || isUndefined(staff.staff_id) ? '' : staff.staff_id

    React.useEffect(() => {
        if (isUndefined(facility) || isUndefined(facility.staff)) {
            console.log('!!!!! no facility')
            return
        }
        setStaffOptions(
            facility.staff.map((staff, index) => {
                const name = isUndefined(staff) || isUndefined(staff.staff_name) ? index.toString() : staff.staff_name
                const value = isUndefined(staff) || isUndefined(staff.staff_id) ? index.toString() : staff.staff_id
                return { value: value, label: name }
            }),
        )
        setState((state) => {
            return { ...state, staff_id: staff_id }
        })

        setSelectedValue(staff_id)
    }, [props.editable, facility, staff_id])

    // 次月.
    const handleNext = () => {
        const newDate = DateUtil.addMonths(state.date, 1)
        console.log(DateUtil.format(newDate, 'yyyy年MM月'), DateUtil.getMonth(newDate))
        setState({ ...state, date: newDate })
    }
    const handlePrev = () => {
        const newDate = DateUtil.addMonths(state.date, -1)
        setState({ ...state, date: newDate })
    }
    const handleToday = () => {
        const newDate = new Date()
        console.log(DateUtil.format(newDate, 'yyyy年MM月'), DateUtil.getMonth(newDate))
        setState({ ...state, date: newDate })
    }
    return (
        <Box>
            <Grid container className={classes.content}>
                {!props.editable ? (
                    <Grid item xs={12}>
                        <StaffSelect />
                    </Grid>
                ) : null}
                <Grid item xs={12}>
                    <TitleBar
                        center={
                            <Box
                                display="flex"
                                style={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <DoublePrevButton onClickHandler={handlePrev} />
                                <Typography style={{ padding: '0 30px' }} variant="h3">
                                    {DateUtil.format(state.date, 'yyyy年M月')}
                                </Typography>
                                <DoubleNextButton onClickHandler={handleNext} />
                            </Box>
                        }
                        bgBlue
                        left={<TodayButton2 onClickHandler={handleToday}>今月</TodayButton2>}
                    />
                </Grid>
                <Grid item xs={12}>
                    <AttendanceList staff_id={state.staff_id} date={state.date} editable={props.editable} />
                </Grid>
            </Grid>
        </Box>
    )
    function StaffSelect() {
        console.log('options: ', staffOptions)

        // console.log('### Facility', facility)

        if (staffOptions.length === 0) return null

        const handleChange = (index: number) => {
            console.log('### Changed', index)
            setSelectedValue(staffOptions[index].value)
            if (isUndefined(facility) || isUndefined(facility.staff) || isUndefined(facility.staff)) {
                return
            }
            const staff = facility.staff[index]
            if (isUndefined(staff) || isUndefined(staff.staff_id)) {
                return
            }
            setState({ ...state, staff_id: staff.staff_id })
        }
        return (
            <div style={{ width: 238, float: 'right', marginBottom: 20 }}>
                <Box className={classes.staffName}>職員氏名</Box>
                <Pulldown
                    onChangeHandler={handleChange}
                    defaultValue={selectedValue}
                    className={classes.pulldown}
                    style={{
                        width: 180,
                        backgroundColor: '#fff',
                    }}
                    options={staffOptions}
                />
            </div>
        )
    }
}
