import React from 'react'
import { withStyles, createStyles, TableCell, TableRow, Theme, Paper, Box } from '@material-ui/core'
import { OrderBy, SortConfig } from 'Types'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'

export const StripedTableRow = withStyles((theme: Theme) =>
    createStyles({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: '#F0F0F0',
            },
            '&:nth-of-type(even)': {
                backgroundColor: '#E9E9E9',
            },
            'tbody &:hover': {
                cursor: 'pointer',
            },
        },
    }),
)(TableRow)

export const StripedTableCell = withStyles((theme: Theme) =>
    createStyles({
        root: {
            border: '1px solid',
            borderColor: theme.palette.common.white,
            padding: 10,
        },
        head: {
            border: '1px solid',
            borderColor: theme.palette.common.white,
            background: 'transparent linear-gradient(0deg, #5A5A5A 0%, #5F5F5F 7%, #707070 34%, #7A7A7A 64%, #7E7E7E 100%)',
            color: theme.palette.common.white,
        },
        body: {
            fontSize: 14,
        },
    }),
)(TableCell)

export const StripedTablePaper = withStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
            minHeight: 40,
            padding: '4px 10px',
            boxShadow: '1px 1px 2px #0000004D',
        },
    }),
)(Paper)

export const LightStripedTableRow = withStyles((theme: Theme) =>
    createStyles({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: '#FFFFFF',
            },
            '&:nth-of-type(even)': {
                backgroundColor: '#F5F5F5',
            },
        },
    }),
)(TableRow)

export const LightStripedTableCell = withStyles((theme: Theme) =>
    createStyles({
        root: {
            border: '1px solid',
            borderColor: '#E2E2E2',
            padding: 10,
        },
        head: {
            border: '1px solid',
            borderColor: '#E2E2E2',
            color: '#5a5a5a',
            background:
                'transparent linear-gradient(0deg, #B4B4B5 0%, #BCBCBD 1%, #CDCDCD 2%, #D9D9D9 4%, #E0E0E0 7%, #E2E2E2 14%, #EAEAEA 20%, #F6F6F6 33%, #FDFDFD 51%, #FFFFFF 100%)',
        },
        body: {
            fontSize: 14,
        },
    }),
)(TableCell)

interface TblSortLabel {
    label: string
    id: string
    sortConfig: SortConfig
}

export const TblSortLabel = ({ label, id, sortConfig }: TblSortLabel) => {
    const getSortConfigDirection = () => {
        if (!sortConfig) {
            return
        }
        return sortConfig.id === id ? sortConfig.direction : undefined
    }

    return (
        <Box display="inline-flex">
            {label} {getSortConfigDirection() === OrderBy.asc ? <ArrowDropUpIcon /> : getSortConfigDirection() === OrderBy.desc ? <ArrowDropDownIcon /> : null}
        </Box>
    )
}
