//
// 管理者ポータル.
//
//
// import * as Const from '../Const'
import { trace } from '../common/index'
import { isUndefined } from 'util'
import React from 'react'
import { useHistory, useRouteMatch, Switch, Route, Redirect } from 'react-router-dom'

import { useAppDispatch } from '../store'
import { fetchAdminById } from '../modules/AdminModule'

import { logout, useAuth } from '../modules/AuthModule'
import { setProfile, changePortal, setHomePageUrl, useHomePageUrl } from 'modules/AppModule'

// functional

// Material-UI
// import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import { Typography, Box } from '@material-ui/core'

// import {
//   Paper,
// } from '@material-ui/core';

// User's Component.
import AdminBar from './AdminBar'

import TrainingContent from './TrainingContent'
import FacilityContent from './FacilityContent'
import AdminContent from './AdminContent'
import InformationSetting from './InformationSetting'

// スタイルを適用する
// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     root: {
//       flexGrow: 1,
//       padding: "10px",
//     },
//   })
// );
export default function AdminPortal() {
    document.title = 'Realize システム管理'

    trace('### AdminPortal')
    const dispatch = useAppDispatch()

    const match = useRouteMatch()
    const homePageUrl = match.url // "/admin"

    // Change portal
    dispatch(changePortal('admin'))
    // Set homepage url
    dispatch(setHomePageUrl(homePageUrl))

    const auth = useAuth()

    React.useEffect(() => {
        console.log('LOGIN:', auth)
        // ユーザ情報のプロフィール登録.
        let admin_id = isUndefined(auth.auth) || isUndefined(auth.auth.account) || auth.auth.account === '' ? 'admin0001' : auth.auth.account
        dispatch(fetchAdminById(admin_id)).then((res) => {
            if (res.payload) {
                const admins = res.payload
                console.log(admins[0])
                dispatch(setProfile(admins[0]))
            }
        })
    }, [dispatch, auth])

    return (
        <React.Fragment>
            {/* 管理者メニューバー */}
            <AdminBar />
            {/* コンテント */}
            <Box marginTop="30px">
                <Switch>
                    {/* トレーニング管理コンポーネント */}
                    <Route exact path={homePageUrl} render={() => <TrainingContent />} />
                    <Route path={`${homePageUrl}/trainings`} render={() => <TrainingContent />} />
                    {/* 施設管理のコンポーネント */}
                    <Route path={`${homePageUrl}/facilities`} render={() => <FacilityContent />} />
                    {/* 施設管理のコンポーネント */}
                    <Route path={`${homePageUrl}/informations`} render={() => <InformationSetting />} />
                    {/* 管理者管理のコンポーネント */}
                    <Route path={`${homePageUrl}/admins`} render={() => <AdminContent />} />
                    {/* Logout */}
                    <Route path={`${homePageUrl}/logout`} render={() => <AdminLogOut />} />
                    <Route path="*" render={() => <Redirect to={homePageUrl} />} />
                </Switch>
            </Box>
        </React.Fragment>
    )
}
function AdminLogOut() {
    const dispatch = useAppDispatch()

    const homePageUrl = useHomePageUrl()
    const history = useHistory()

    const auth = useAuth()
    React.useEffect(() => {
        if (!auth.authorized) {
            return
        }
        window.history.pushState(null, '', `${homePageUrl}/logout`)
        dispatch(logout())
        // dispatch(resetState())
        history.push(homePageUrl)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, history, auth])
    return <Typography variant="h1">ログアウトしています</Typography>
}
