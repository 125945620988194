// 見出しテキストラベル

// import * as Const from '../Const'
// import { trace } from '../common/index'
// import clsx from 'clsx'
import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

// Material-UI コンポーネント
// import {
//     Grid,
//     Box,
//     Paper,
//     Button,
//     Avatar,
// } from '@material-ui/core'
//
// Material-UIアイコン取得
// import {
//     Star as StarIcon
// } from '@material-ui/icons'

// スタイルを適用する
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // 見出し.
        headline: {
            fontSize: '125%',
            paddingLeft: '10px',
            marginBottom: '5px',
            paddingTop: '5px',
            paddingBottom: '5px',
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
            borderBottom: '2px solid rgba(0, 0, 0, 0.23)',
            background:
                'linear-gradient(0deg, #EAEAEA 10%, #FDFDFD 60%, #FFF 80%)',
        },
    }),
)

// Props
type HeadlineProps = {
    title: string // タイトル.
}

export default function HeadlineLabel(props: HeadlineProps) {
    // CSSを適用する。
    const classes = useStyles()
    return (
        <React.Fragment>
            <div className={classes.headline}>{props.title}</div>
        </React.Fragment>
    )
}
