import { createMuiTheme } from '@material-ui/core/styles'

import { red as adminColor, green as staffColor, lightBlue as userColor } from '@material-ui/core/colors'

// 独自のテーマを作成する
// const shadows: string[] = Array(25).fill('none')
export const adminTheme = createMuiTheme({
    shadows: [
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
    ],
    palette: {
        //type: 'dark', // ダークテーマ
        primary: adminColor,
    },
    typography: {
        fontFamily: ['Noto Sans', 'sans-serif'].join(','),
        fontSize: 12,
        h1: { fontSize: '1.75rem' },
        h2: { fontSize: '1.5rem' },
        h3: { fontSize: '1.25rem' },
        h4: { fontSize: '1.125rem' },
        h5: { fontSize: '1rem' },
        h6: { fontSize: '0.75rem' },
        subtitle1: { fontize: '1.5rem', fontWeightBold: true },
        subtitle2: { fontize: '1.25rem' },
        body1: { fontize: '1.0rem', fontWeightBold: true },
        body2: { fontize: '1.0rem' },
        caption: { fontize: '1.0rem' },
        button: { fontize: '1.25rem', fontWeightBold: true },
        overline: { fontize: '1.25rem' },
    },
})
export const staffTheme = createMuiTheme({
    shadows: [
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
    ],
    palette: {
        //type: 'dark', // ダークテーマ
        primary: staffColor,
    },
    typography: {
        fontFamily: ['Noto Sans', 'sans-serif'].join(','),
        fontSize: 12,
        h1: { fontSize: '1.75rem' },
        h2: { fontSize: '1.5rem' },
        h3: { fontSize: '1.25rem' },
        h4: { fontSize: '1.125rem' },
        h5: { fontSize: '1rem' },
        h6: { fontSize: '0.75rem' },
        subtitle1: { fontize: '1.55rem', fontWeightBold: true },
        subtitle2: { fontize: '1.25rem' },
        body1: { fontize: '1.0rem', fontWeightBold: true },
        body2: { fontize: '1.0rem' },
        caption: { fontize: '1.0rem' },
        button: { fontize: '1.25rem', fontWeightBold: true },
        overline: { fontize: '1.25rem' },
    },
})

export const userTheme = createMuiTheme({
    shadows: [
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
    ],
    palette: {
        //type: 'dark', // ダークテーマ
        primary: userColor,
    },
    typography: {
        fontFamily: ['Noto Sans', 'sans-serif'].join(','),
        fontSize: 12,
        h1: { fontSize: '1.75rem' },
        h2: { fontSize: '1.5rem' },
        h3: { fontSize: '1.25rem' },
        h4: { fontSize: '1.125rem' },
        h5: { fontSize: '1rem' },
        h6: { fontSize: '0.75rem' },
        subtitle1: { fontize: '1.55rem', fontWeightBold: true },
        subtitle2: { fontize: '1.25rem' },
        body1: { fontize: '1.0rem', fontWeightBold: true },
        body2: { fontize: '1.0rem' },
        caption: { fontize: '1.0rem' },
        button: { fontize: '1.25rem', fontWeightBold: true },
        overline: { fontize: '1.25rem' },
    },
})
