// 定数定義ファイル
// usage:
//  import * as Const from '../Const'
// code:
//  Const.AppConst.NAME

export class AppConst {
    static NAME = 'Realize' // アプリケーション名.
}
export class Setting {
    static LEVEL_MAX = 10 // 最大レベル.
    static LICENSE_MAX = 15 // 最大ライセンス数.
}

export const COLOR = {
    GREY: '#B6B6B6',
    BLUE: '#05A9C2',
    LIGHT_BLUE: '#CDEEF3',
    WHITE: '#FFFFFF',
}

export const OPTION_ALL = {
    value: 'all',
    label: 'すベて',
}

export const OPTION_CONCURRENT = {
    value: 'concurrent',
    label: '一斉指導',
}
