// import * as Const from '../Const'
import { trace } from '../common/index'
import React from 'react'

import { useAppDispatch } from '../store'
import { fetchTrainings, fetchTrainingsWithUserId } from '../modules/TrainingModule'

import { Grid, TableContainer, Table, TableBody } from '@material-ui/core'

import { TrainingListCell, TrainingListHeaderCell } from './TrainingListCell'
import { SortConfig, Training, TrainingSortable, User } from '../Types'
import { useListStyles } from '../Styles'
import PaginationActions from './PaginationActions'
import { useFacility, usePortal, useProfile } from 'modules/AppModule'
import { isUndefined } from 'util'

/**
 * トレーニング一覧プロパティ
 *
 * @interface TrainingListProps
 */
interface TrainingListProps {
    onClickHandler?: (item: Training) => void
    items: Training[]
    sortConfig: SortConfig
    requestSort: (key: TrainingSortable) => void
}

export default function TrainingList(props: TrainingListProps) {
    const dispatch = useAppDispatch()
    const listClasses = useListStyles()

    const facility = useFacility()
    const facility_id = isUndefined(facility) || isUndefined(facility.facility_id) ? null : facility.facility_id

    const portal = usePortal()
    const user = useProfile() as User
    const user_id = user?.user_id === undefined ? null : user.user_id

    // 初回ロード.
    React.useEffect(() => {
        console.log('*#*#**#*#')
        if (portal === 'user') {
            dispatch(fetchTrainingsWithUserId({ facility_id: facility_id, user_id: user_id }))
        } else {
            dispatch(fetchTrainings(facility_id))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, facility_id, user_id])

    /**
     * セルがクリックされた
     */
    const handleClick = (event: React.MouseEvent<unknown>, item: Training) => {
        trace('list selected:' + item.training_name)
        if (!isUndefined(props.onClickHandler)) {
            props.onClickHandler(item)
        }
    }
    // ### Pagination.
    // 現在のページ
    const [page, setPage] = React.useState(0)
    // １ページあたりの行数.
    const [rowsPerPage, setRowsPerPage] = React.useState(10)
    // ページが変更された.
    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage)
    }
    // ページ行数が変更された.
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<{
            name?: string | undefined
            value: any
        }>,
    ) => {
        trace('行数変更')
        if (typeof event.target.value !== 'number') {
            return
        }

        setRowsPerPage(event.target.value)
        setPage(0)
    }

    React.useEffect(() => {
        setPage(0)
    }, [props.items])

    // レンダリング.
    return (
        <React.Fragment>
            <Grid container className={listClasses.wrapper}>
                <Grid item xs={12}>
                    <TableContainer>
                        <Table aria-label="customized table">
                            <TrainingListHeaderCell requestSort={props.requestSort} sortConfig={props.sortConfig} />
                            <TableBody>
                                {(rowsPerPage > 0 ? props.items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : props.items).map((item) => {
                                    return <TrainingListCell key={item.training_id} data={item} onClickHandler={(event) => handleClick(event, item)} />
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={12} className={listClasses.listfooter}>
                    <PaginationActions
                        count={props.items.length}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        options={[
                            { key: '5', value: 5 },
                            { key: '10', value: 10 },
                            { key: '25', value: 25 },
                            { key: '50', value: 50 },
                            { key: '全て', value: props.items.length },
                        ]}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    )
}
