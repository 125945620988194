// import * as Const from '../Const'
import { trace } from '../common/index'
import clsx from 'clsx'
import React from 'react'
import * as DateUtil from 'date-fns'
import jaLocale from 'date-fns/locale/ja'

import { useForm } from 'react-hook-form'
import { useAppDispatch } from '../store'
import { putAttendances } from '../modules/AttendanceModule'

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Box, TextField } from '@material-ui/core'

import { Attendance } from '../Types'
import { isUndefined } from 'util'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            // padding: "4px",
        },
        container: {
            display: 'flex',
            width: '100%',
            '& .MuiInput-underline:before': {
                borderBottom: 0,
            },
            borderBottom: '1px solid #fff',
        },
        header_container: {
            display: 'flex',
            height: 'calc(40px)',
            width: '100%',
            background: 'transparent linear-gradient(0deg, #5A5A5A 0%, #5F5F5F 7%, #707070 34%, #7A7A7A 64%, #7E7E7E 100%)',
        },
        header_cell: {
            margin: theme.spacing(0),
            paddingTop: theme.spacing(0),
            paddingLeft: theme.spacing(2),
            paddingBottom: theme.spacing(0),
            paddingRight: theme.spacing(2),
            textAlign: 'center',
            color: '#ffffff',
            fontWeight: 'bold',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
        header_time: {
            width: 100,
            borderRight: '1px solid #fff',
        },
        header_date: {
            width: 100,
            pointerEvents: 'none',
            borderRight: '1px solid #fff',
        },
        header_content: {
            flex: 1,
            borderRight: '1px solid #fff',
        },
        cell: {
            height: 27,
        },
        readonly: {
            backgroundColor: theme.palette.grey[200],
            color: '#000', //theme.palette.primary.dark,
        },
        input_date_container: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRight: '1px solid #fff',
            width: 100,
        },
        input_date: {
            pointerEvents: 'none',
            '&:disabled': {
                color: '#000', //theme.palette.primary.dark,
                backgroundColor: theme.palette.grey[200],
            },
        },
        input_time: {
            width: 70,
            boxShadow: '1px 1px 3px #9b9b9b',
            background: '#fff',
        },
        input_time_container: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRight: '1px solid #fff',
            padding: '7px 0',
            width: 100,
            '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                borderBottom: 0,
            },
            '& .MuiInput-underline:after': {
                borderBottom: 0,
            },
        },
        input_content: {
            flex: 1,
            boxShadow: '1px 1px 3px #9b9b9b',
            padding: '0 5px',
            background: '#fff',
        },
        input_content_container: {
            display: 'flex',
            alignItems: 'center',
            borderRight: '1px solid #fff',
            padding: 5,
            width: 660,
            '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                borderBottom: 0,
            },
            '& .MuiInput-underline:after': {
                borderBottom: 0,
            },
        },
        nochanged: {},
        // 変更があったときのセル背景色.
        changed: {
            backgroundColor: '#eef',
        },
        textField: {
            padding: 5,
            borderRight: '1px solid #fff',
            display: 'table-cell',
        },
        bgColor1: {
            background: '#eeeeee',
        },
        bgColor2: {
            background: '#CDEEF3',
        },
    }),
)

/**
 * Props
 *
 * @interface AttendanceCellProps
 */
interface AttendanceCellProps {
    editable?: boolean
    staff_id: string
    item: Attendance
    onClickHandler?: (event: React.MouseEvent<unknown, MouseEvent>) => void
}
function dateText(dateStr: string) {
    const date = DateUtil.parse(dateStr, 'yyyy-MM-dd', new Date())
    return DateUtil.format(date, 'MM/dd', { locale: jaLocale })
}
/**
 * 勤怠セル.
 * @export
 * @param {AttendanceCellProps} props
 * @returns セルのJSX 要素
 */
export function AttendanceCell(props: AttendanceCellProps) {
    const dispatch = useAppDispatch()
    const classes = useStyles()

    const [staff_id] = React.useState<string>(props.staff_id)
    const [item, setItem] = React.useState<Attendance>(props.item)
    const [backupItem, setBackupItem] = React.useState<Attendance>(props.item)

    // props.item に変更があったときはステートを更新して再レンダリング.
    React.useEffect(() => {
        setItem(props.item)
    }, [props.item])

    const { register, handleSubmit, errors, getValues, setError, clearErrors } = useForm<Attendance>({
        defaultValues: {
            date: item.date,
            enter_time: item.enter_time,
            leave_time: item.leave_time,
            content: item.content,
        },
    })

    const handleClick = (event: React.MouseEvent<unknown, MouseEvent>) => {
        if (props.onClickHandler !== undefined) {
            props.onClickHandler(event)
        }
    }
    const handleChange = (prop: keyof Attendance) => (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log('changed')
        let value: string = event.target.value
        // switch( prop ) {
        //     case 'enter_time':
        //     case 'leave_time':
        //             const ret = validation_time( value )
        //         if ( ret ) {
        //             clearErrors( prop );
        //         } else {
        //             setError( prop, {type:"", message:"エラー"} )
        //             return;
        //         }
        // }
        setItem({ ...item, [prop]: value })
    }

    // Validation : Time format
    function validation_time(timeStr: string | undefined): string | undefined {
        if (isUndefined(timeStr)) return undefined

        // 削除したとき.
        if (timeStr === '') return timeStr

        // 日付書式チェック.
        let hour: number
        let minute: number
        const match = timeStr.match(/^(\d{1,2}):(\d{1,2})$/)
        console.log(match)
        if (match !== null) {
            hour = parseInt(match[1])
            minute = parseInt(match[2])
        } else {
            let ret = timeStr.match(/^\d{3,4}$/)
            if (ret === null) {
                console.log('not match', timeStr)
                return undefined
            }
            hour = parseInt(timeStr.slice(0, -2))
            minute = parseInt(timeStr.slice(-2))
        }
        if (hour < 0 || hour >= 36 || minute < 0 || minute > 59) return undefined
        let result = hour + ':' + ('00' + minute.toString()).slice(-2)
        return result
    }

    // validation: 'content'
    // 200文字制限
    function validation_content(content: string | undefined): string | undefined {
        if (isUndefined(content) || (content.length && content.trim() === '')) return undefined
        if (content.length > 200) {
            return content.slice(0, 200)
        }
        return content
    }

    function isChanged(prop: keyof Attendance) {
        return staff_id === props.staff_id && item.date === props.item.date && item[prop] !== props.item[prop]
    }
    function changedColor(prop: keyof Attendance): string {
        return isChanged(prop) ? classes.changed : classes.nochanged
    }
    const onFocus = (prop: keyof Attendance) => () => {
        setBackupItem({ ...backupItem, [prop]: getValues(prop) })
    }
    // フォーカスがはずれたとき.
    const onBlur = (prop: keyof Attendance) => (data: Attendance) => {
        trace('=== 送信処理')

        // データが不正なときは送信しない.
        if (isUndefined(data) || isUndefined(data[prop])) return

        let attendance: Attendance = { ...data }
        console.log(attendance)

        // バリデーション
        let ret
        switch (prop) {
            case 'enter_time':
            case 'leave_time':
                ret = validation_time(data[prop])
                console.log('正規化', ret)
                if (isUndefined(ret)) {
                    setError(prop, { type: 'manual', message: 'エラー' })
                    setItem({ ...item, [prop]: backupItem[prop] })
                    return
                }
                attendance[prop] = ret // 送信パラメータ更新.
                setItem({ ...item, [prop]: ret }) // 表示更新.
                clearErrors(prop)
                break
            case 'content':
                ret = validation_content(data[prop])
                if (isUndefined(ret)) {
                    setError(prop, { type: '', message: 'エラー' })
                    setItem({ ...item, [prop]: backupItem[prop] })
                    return
                }
                attendance[prop] = ret
                clearErrors(prop)
                break
        }

        if (backupItem[prop] === data[prop]) {
            console.log('変更なし')
            return
        }
        console.log('変更あり：送信', attendance)

        dispatch(putAttendances({ staff_id: props.staff_id, item: attendance }))
    }

    return (
        <Box className={classes.root} onClick={(event) => handleClick(event)}>
            <form
                className={classes.container}
                // className={classes.form}
                noValidate
                // onSubmit={handleSubmit(onSubmit)}
            >
                <Box className={classes.input_date_container}>
                    <input type="hidden" name="date" value={item.date} ref={register} />
                    <TextField
                        id="date"
                        name="date"
                        value={dateText(props.item.date)}
                        InputProps={{
                            className: clsx(classes.input_date, classes.cell, classes.readonly),
                        }}
                        inputProps={{
                            min: 0,
                            style: { textAlign: 'center', color: '#000' },
                        }}
                        disabled
                    />
                </Box>
                <Box className={classes.input_time_container}>
                    <TextField
                        id="enter_time"
                        name="enter_time"
                        value={item.enter_time}
                        autoComplete="off"
                        onChange={handleChange('enter_time')}
                        InputProps={{
                            className: clsx(
                                classes.input_time,
                                classes.cell,
                                item.enter_time && !props.editable ? classes.bgColor2 : !props.editable ? classes.bgColor1 : '',
                                changedColor('enter_time'),
                                !props.editable ? classes.readonly : '',
                            ),
                            readOnly: !props.editable,
                        }}
                        disabled={!props.editable}
                        inputProps={{
                            min: 0,
                            style: { textAlign: 'center', color: '#000' },
                        }}
                        // validation
                        inputRef={register({
                            // pattern: {
                            //     value: /^\d{1,2}:?\d{1,2}$/ ,
                            //     message: 'エラー',
                            // },
                        })}
                        error={Boolean(errors.enter_time)}
                        helperText={errors.enter_time && errors.enter_time.message}
                        onBlur={handleSubmit(onBlur('enter_time'))}
                        onFocus={onFocus('enter_time')}
                    />
                </Box>
                <Box className={classes.input_time_container}>
                    <TextField
                        id="leave_time"
                        name="leave_time"
                        value={item.leave_time}
                        autoComplete="off"
                        onChange={handleChange('leave_time')}
                        InputProps={{
                            className: clsx(
                                classes.input_time,
                                item.leave_time && !props.editable ? classes.bgColor2 : !props.editable ? classes.bgColor1 : '',
                                classes.cell,
                                changedColor('leave_time'),
                                !props.editable ? classes.readonly : '',
                            ),
                            readOnly: !props.editable,
                        }}
                        disabled={!props.editable}
                        inputProps={{
                            min: 0,
                            style: { textAlign: 'center', color: '#000' },
                        }}
                        // validation
                        inputRef={register({
                            // pattern: {
                            //     value: /^\d{1,2}:?\d{2}$/,
                            //     message: 'エラー',
                            // },
                        })}
                        error={Boolean(errors.leave_time)}
                        // helperText={errors.leave_time && errors.leave_time.message}
                        onBlur={handleSubmit(onBlur('leave_time'))}
                        onFocus={onFocus('leave_time')}
                    />
                </Box>
                <Box className={classes.input_content_container}>
                    <TextField
                        // variant="outlined"
                        id="content"
                        name="content"
                        value={item.content === null ? '' : item.content}
                        onChange={handleChange('content')}
                        className={clsx(
                            classes.input_content,
                            !props.editable ? classes.bgColor1 : '',
                            classes.cell,
                            changedColor('content'),
                            !props.editable ? classes.readonly : '',
                        )}
                        disabled={!props.editable}
                        inputProps={{ min: 0, style: { color: '#000' } }}
                        InputProps={{
                            className: clsx(classes.cell),
                            readOnly: !props.editable,
                        }}
                        // validation
                        inputRef={register({
                            // maxLength: {
                            //     value: 200,
                            //     message: '200文字以内で入力してください',
                            // },
                        })}
                        error={Boolean(errors.content)}
                        // helperText={errors.content && errors.content.message}
                        onBlur={handleSubmit(onBlur('content'))}
                        onFocus={onFocus('content')}
                    />
                </Box>
            </form>
        </Box>
    )
}

/**
 * 管理者セルヘッダ
 *
 * @export
 * @returns
 */
export function AttendanceHeaderCell() {
    const classes = useStyles()
    //   const handleClick = (event: React.MouseEvent<unknown, MouseEvent>) => {
    //     props.onClickHandler(event);
    //   };
    return (
        <Box className={classes.root}>
            <Box className={classes.header_container}>
                <Box className={clsx(classes.header_date, classes.header_cell)}>日付</Box>
                <Box className={clsx(classes.header_time, classes.header_cell)}>出勤</Box>
                <Box className={clsx(classes.header_time, classes.header_cell)}>退勤</Box>
                <Box className={clsx(classes.header_content, classes.header_cell)}>勤務内容</Box>
            </Box>
        </Box>
    )
}
