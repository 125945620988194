// 施設モジュール
import {
    isUndefined,
    // , isNull
} from 'util'

import { useSelector } from 'react-redux'
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from '../rootReducer'
import * as ApiClient from './ApiClient'
import { Facility, TrainingSetting, Staff, User, Lesson, OriginalField, Information, ExaminationAnswer, GrowthRecord, LessonStatus } from '../Types'
import { initLessonComment } from './DataUtil'
import qs from 'query-string'

// ステート定義
type FacilityState = {
    // page: string // コンテンツページ.
    // staffPage: string // 職員ページ.

    // selectedItem?: Facility // 選択中アイテム.
    // selectedStaff?: Staff // 選択中スタッフ.

    facilities: Facility[] // 施設一覧.

    facilityUsers: User[] // 施設ユーザ.

    facilityLessons: Lesson[] // レッスン（レッスン付きのユーザ情報から生成しても良いが、ベット取得することにする)

    facilityInformations: Information[] // 施設お知らせ
}

// ステート初期値.
const initialState: FacilityState = {
    // page: 'list',
    // staffPage: 'list',

    // selectedItem: undefined,
    facilities: [],

    facilityUsers: [],
    facilityLessons: [],
    facilityInformations: [],
}

export type LessonCommentAndRecordsParam = {
    lesson_id: string
    user_id: string
    comment_prev?: string
    comment_current?: string
    comment_next?: string
    growth_records?: GrowthRecord[]
}

// React Thunk

// 施設.

export const fetchFacilities = createAsyncThunk('facility/fetchAll', async (arg, thunkAPI) => {
    const response = await ApiClient.get('/facilities')
    return response.data
})

export const fetchFacilityById = createAsyncThunk('facility/fetchById', async (facility_id: string, thunkAPI) => {
    const response = await ApiClient.get(`/facilities/${facility_id}`)
    return response.data
})

export type getFacilityLessonsParam = {
    facility_id: string
    query: Object | null
}
export const getFacilityLessons = createAsyncThunk('facility/getFacilityLessons', async (arg: getFacilityLessonsParam, thunkAPI) => {
    var url = `/facilities/${arg.facility_id}/lessons`
    let queryString = qs.stringify(arg.query ? arg.query : {})
    const response = await ApiClient.get(`${url}?${queryString}`)
    return response.data
})

/**
 * 施設ID生成
 * method: GET
 * path: /facilities/id
 */
export const generateFacilityId = createAsyncThunk('facility/id', async (arg, thunkAPI) => {
    const url = 'facilities/id'
    const response = await ApiClient.get(url)
    return response.data
})

// 施設ユーザ(レッスンつき
export type fetchFacilityUsersWithLessonsParam = {
    facility_id: string
    user_id?: string
    start?: string
    end?: string
}
export const fetchFacilityUsersWithLessons = createAsyncThunk(
    'facility/fetchFacilityUsersWithLessons',
    async (arg: fetchFacilityUsersWithLessonsParam, thunkAPI) => {
        var url = `/facilities/${arg.facility_id}`
        if (!isUndefined('user_id')) {
            url = `${url}/users/${arg.user_id}`
        }
        url = `${url}/lessons`
        if (!isUndefined('start')) {
            url = `${url}/${arg.start}`
        }
        if (!isUndefined('end')) {
            url = `${url}/${arg.end}`
        }
        // console.log( "###URL:", url );
        const response = await ApiClient.get(url)
        // console.log( "###RES:", response );
        return response.data
    },
)
// 施設ユーザ(レッスンつき
export type fetchFacilityLessonsParam = {
    facility_id: string
    start?: string
    end?: string
}
export const fetchFacilityLessons = createAsyncThunk('facility/fetchFacilityLessons', async (arg: fetchFacilityLessonsParam, thunkAPI) => {
    var url = `/facilities/${arg.facility_id}/lessons`
    if (!isUndefined('start')) {
        url = `${url}/${arg.start}`
    }
    if (!isUndefined('end')) {
        url = `${url}/${arg.end}`
    }
    // console.log( "###URL:", url );
    const response = await ApiClient.get(url)
    // console.log( "###RES:", response );
    return response.data
})

/**
 * 作成
 * method: POST
 * path: /facilities
 */
export const createFacility = createAsyncThunk('facility/create', async (item: Facility, thunkAPI) => {
    const response = await ApiClient.post('/facilities', item)
    return { ...response, item: item }
})

// 更新.
// method: PUT
// path: /facilities/:id
// @note id の更新にも対応。
// @params  { original_id, T }
//  original_id: オリジナルのID.（検索用）
//  item: 更新する内容
export const updateFacilityById = createAsyncThunk('facilities/updateById', async (item: Facility & OriginalField, thunkAPI) => {
    const newItem = excludeOriginalField(item)
    const response = await ApiClient.put('/facilities/' + item.original_id, newItem)
    return { ...response, item: newItem }
})
function excludeOriginalField(item: Facility & OriginalField): Facility {
    console.log('exclude前: ', item)
    let state: Facility = {}
    for (const [key, value] of Object.entries(item)) {
        if (key !== 'original_id') {
            state = { ...state, [key]: value }
        }
    }
    console.log('exclude結果: ', state)
    return state
}

// 削除
// @path: /facilities/:id
export const deleteFacility = createAsyncThunk('facility/delete', async (id: string, thunkAPI) => {
    const response = await ApiClient.delete_('/facilities/' + id)
    return { ...response, id: id }
})

/**
 * 職員登録
 * method: POST
 * path: /facilities/:facility_id/staff
 */
export const createFacilityStaff = createAsyncThunk('facility/createFacilityStaff', async (arg: { facility_id: string; item: Staff }, thunkAPI) => {
    try {
        const response = await ApiClient.post(`/facilities/${arg.facility_id}/staff`, arg.item)
        return { ...response, item: arg.item }
    } catch (error) {
        if (error.status === 419) {
            throw new Error(error.data.result.error_message)
        }
        throw new Error()
    }
})
// 更新.
// method: PUT
// path: /facilities/:facility_id/staff/:staff_id
// @note id の更新にも対応。
// @params  { original_id, T }
//  original_id: オリジナルのID.（検索用）
//  item: 更新する内容
export const updateFacilityStaffById = createAsyncThunk(
    'facilities/updateFacilityStaffById',
    async (arg: { facility_id: string; item: Staff & OriginalField }, thunkAPI) => {
        const newItem = excludeOriginalField(arg.item)
        const url = `/facilities/${arg.facility_id}/staff/${arg.item.original_id}`
        console.log(url, '\n', newItem)

        try {
            const response = await ApiClient.put(url, newItem)
            return { ...response, item: newItem }
        } catch (error) {
            if (error.status === 419) {
                throw new Error(error.data.result.error_message)
            }
            throw new Error()
        }
    },
)
// 職員削除
// @path: /facilities/:facility_id/staff/:staff_id
// @param arg : {}
export const deleteFacilityStaff = createAsyncThunk('facility/deleteFacilityStaff', async (arg: { facility_id: string; staff_id: string }, thunkAPI) => {
    const response = await ApiClient.delete_(`/facilities/${arg.facility_id}/staff/${arg.staff_id}`)
    return { ...response, id: arg.staff_id }
})

// トレーニング設定更新.
// @method: PATCH
// @path: /facilities/:facility_id/settings
// @param arg : {}
export const patchFacilitySetting = createAsyncThunk(
    'facility/updateFacilitySetting',
    async (arg: { facility_id: string; item: TrainingSetting }, thunkAPI) => {
        const url = `/facilities/${arg.facility_id}/settings`
        const response = await ApiClient.patch(url, arg.item)
        return response.data
    },
)
// === お知らせ
// お知らせ：取得.
export const fetchFacilityInformationsByFacilityId = createAsyncThunk(
    'facility/fetchFacilityInformationsByFacilityId',
    async (facility_id: string | null, thunkAPI) => {
        const url = facility_id ? `/facilities/${facility_id}/informations` : `/facilities/-/informations`
        const response = await ApiClient.get(url)
        return response.data
    },
)
// お知らせ：作成.
export const createFacilityInformation = createAsyncThunk('facility/createFacilityInformation', async (item: Information, thunkAPI) => {
    const url = `/facilities/${item.facility_id}/informations`
    const response = await ApiClient.post(url, item)
    return response.data
})
// お知らせ：更新.
export const updateFacilityInformation = createAsyncThunk('facility/updateFacilityInformation', async (item: Information, thunkAPI) => {
    const response = await ApiClient.put(`/facilities/${item.facility_id}/informations/${item.id}`, item)
    return response.data
})
// お知らせ：削除
export const deleteFacilityInformation = createAsyncThunk('facility/deleteFacilityInformation', async (arg: { facility_id: string; id: number }, thunkAPI) => {
    const response = await ApiClient.delete_(`/facilities/${arg.facility_id}/informations/${arg.id}`)
    return response.data
})

// Export: Facility user options
export const fetchFacilityUserOptions = createAsyncThunk('facility/fetchFacilityUserOptions', async (facility_id: string, thunkAPI) => {
    const response = await ApiClient.get(`/facilities/${facility_id}/user-options`)
    return response.data
})

// Slice を作成.
const FacilityModule = createSlice({
    name: 'facility',
    initialState,
    reducers: {
        // FacilityLessons
        setFacilityLessons(state: FacilityState, action: PayloadAction<Lesson[] | []>) {
            state.facilityLessons = action.payload
        },
        // Set examination answer into lessons
        setFacilityLessonExaminationAnswer(state: FacilityState, action: PayloadAction<{ lesson_id: string; item: ExaminationAnswer }>) {
            const { lesson_id, item } = action.payload

            let newLessons = [...state.facilityLessons]
            newLessons = newLessons.map((lesson) => {
                if (lesson.lesson_id !== lesson_id) return lesson
                let newExaminationAnswers = isUndefined(lesson.examination_answers) ? [] : [...lesson.examination_answers]
                const examinationAnswerIndex = newExaminationAnswers.findIndex(
                    (examinationAnswer) => examinationAnswer.examination_id === item.examination_id && examinationAnswer.user_id === item.user_id,
                )
                if (examinationAnswerIndex > -1) {
                    newExaminationAnswers[examinationAnswerIndex] = item
                } else {
                    newExaminationAnswers.push(item)
                }
                return {
                    ...lesson,
                    examination_answers: newExaminationAnswers,
                }
            })

            state.facilityLessons = newLessons
        },
        // Set lesson comments and growth records into lessons
        setFacilityLessonCommentAndRecords(state: FacilityState, action: PayloadAction<{ lesson_id: string; item: LessonCommentAndRecordsParam }>) {
            const { lesson_id, item } = action.payload

            let records: any[] | undefined = undefined
            if (item.growth_records) {
                records = item.growth_records
                delete item.growth_records
            }
            let newLessons = [...state.facilityLessons]
            newLessons = newLessons.map((lesson) => {
                if (lesson.lesson_id !== lesson_id) return lesson
                // Update lesson comment
                let newLessonComments = isUndefined(lesson.lesson_comments) ? [] : [...lesson.lesson_comments]
                const lessonCommentIndex = newLessonComments.findIndex(
                    (lessonComment) => lessonComment.lesson_id === item.lesson_id && lessonComment.user_id === item.user_id,
                )
                const newItem = { ...initLessonComment(), ...item }
                if (lessonCommentIndex > -1) {
                    newLessonComments[lessonCommentIndex] = newItem
                } else {
                    newLessonComments.push(newItem)
                }
                // Update growth records
                let newGrowthRecords: any[] = isUndefined(lesson.growth_records) ? [] : [...lesson.growth_records]
                if (records) {
                    newGrowthRecords = newGrowthRecords.filter(
                        (newGrowthRecord) => newGrowthRecord.lesson_id !== lesson_id && newGrowthRecord.user_id !== item.user_id,
                    )
                    newGrowthRecords = [...newGrowthRecords, ...records]
                }
                return {
                    ...lesson,
                    lesson_comments: newLessonComments,
                    growth_records: newGrowthRecords,
                }
            })

            state.facilityLessons = newLessons
        },
        setFacilityLessonStatus(state: FacilityState, action: PayloadAction<{ lesson_id: string; status: LessonStatus }>) {
            const { lesson_id, status } = action.payload
            state.facilityLessons = state.facilityLessons.map((lesson) => {
                if (lesson.lesson_id !== lesson_id) return lesson
                return {
                    ...lesson,
                    status: status,
                }
            })
        },
    },
    extraReducers: (builder) => {
        // fetch
        builder.addCase(fetchFacilities.fulfilled, (state, action) => {
            // console.log( 'fulfilled' );
            return { ...state, facilities: action.payload }
        })
        builder.addCase(fetchFacilities.pending, (state, action) => {
            console.log('pending')
        })
        builder.addCase(fetchFacilities.rejected, (state, action) => {
            console.log('rejected')
        })
        // fetch by id
        builder.addCase(fetchFacilityById.fulfilled, (state, action) => {
            console.log('fulfilled')
            // return { ...state, facilities: facilities }
        })
        builder.addCase(fetchFacilityById.pending, (state, action) => {
            console.log('pending')
        })
        builder.addCase(fetchFacilityById.rejected, (state, action) => {
            console.log('rejected')
        })

        // fetch by id
        builder.addCase(generateFacilityId.fulfilled, (state, action) => {
            return action.payload.data
        })
        builder.addCase(generateFacilityId.pending, (state, action) => {})
        builder.addCase(generateFacilityId.rejected, (state, action) => {
            console.log('rejected')
        })
        // fetch facility/user/lesson
        builder.addCase(fetchFacilityUsersWithLessons.fulfilled, (state, action) => {
            // アイコンを保持しない.
            const users = action.payload.map((user: User) => {
                const lessons = user.lessons.map((lesson) => {
                    return { ...lesson, user: { ...lesson.user, icon: undefined }, staff: { ...lesson.staff, icon: undefined } }
                })

                return { ...user, icon: undefined, lessons: lessons }
            })
            return { ...state, facilityUsers: users }
        })
        builder.addCase(fetchFacilityUsersWithLessons.pending, (state, action) => {})
        builder.addCase(fetchFacilityUsersWithLessons.rejected, (state, action) => {
            // console.log('rejected')
        })
        // fetch facility/lessons
        builder.addCase(fetchFacilityLessons.fulfilled, (state, action) => {
            // アイコンを保持しない.
            const lessons = action.payload.map((lesson: Lesson) => {
                const user = lesson.user ? { ...lesson.user, icon: undefined } : {}
                const staff = lesson.staff ? { ...lesson.staff, icon: undefined } : {}
                return { ...lesson, user: user, staff: staff }
            })
            return { ...state, facilityLessons: lessons }
        })
        builder.addCase(fetchFacilityLessons.pending, (state, action) => {
            // console.log('pending')
        })
        builder.addCase(fetchFacilityLessons.rejected, (state, action) => {
            // console.log('rejected')
        })
        // add
        builder.addCase(createFacility.fulfilled, (state, action) => {
            // console.log('fulfilled:', action.payload)
            const facilities = state.facilities.filter((t) => t.facility_id !== action.payload.item.facility_id)
            state.facilities = [...facilities, action.payload.item]
        })
        builder.addCase(createFacility.pending, (state, action) => {
            // console.log('pending')
        })
        builder.addCase(createFacility.rejected, (state, action) => {
            // console.log('rejected')
        })
        // update
        builder.addCase(updateFacilityById.fulfilled, (state, action) => {
            // console.log('fulfilled:', action.payload)
            // return {　...state,facilities: action.payload　}
            let facilities = state.facilities.filter((t) => t.facility_id !== action.payload.item.facility_id)
            state.facilities = [...facilities, action.payload.item]
            // state.facilityPage = 'list';
        })
        builder.addCase(updateFacilityById.pending, (state, action) => {
            // console.log('pending')
        })
        builder.addCase(updateFacilityById.rejected, (state, action) => {
            // console.log('rejected')
        })
        // delete
        builder.addCase(deleteFacility.fulfilled, (state, action) => {
            // console.log('fulfilled')
            // console.log(action.payload)
            state.facilities = state.facilities.filter((t) => t.facility_id !== action.payload.id)
            // state.page = 'list'
        })
        builder.addCase(deleteFacility.pending, (state, action) => {
            // console.log('pending')
        })
        builder.addCase(deleteFacility.rejected, (state, action) => {
            // console.log('rejected')
        })

        // ####################################
        // 施設職員.
        // ####################################
        builder.addCase(createFacilityStaff.fulfilled, (state, action) => {
            // console.log('fulfilled')
            // console.log(action.payload)
            // state.page = 'staff_list'
        })
        builder.addCase(createFacilityStaff.pending, (state, action) => {
            // console.log('pending')
        })
        builder.addCase(createFacilityStaff.rejected, (state, action) => {
            // console.log('rejected')
        })
        builder.addCase(updateFacilityStaffById.fulfilled, (state, action) => {
            // console.log('fulfilled')
            // console.log(action.payload)
            // state.page = 'staff_list'
        })
        builder.addCase(updateFacilityStaffById.pending, (state, action) => {
            // console.log('pending')
        })
        builder.addCase(updateFacilityStaffById.rejected, (state, action) => {
            // console.log('rejected')
        })
        builder.addCase(deleteFacilityStaff.fulfilled, (state, action) => {
            // console.log('fulfilled')
            // console.log(action.payload)
            // state.page = 'staff_list'
        })
        builder.addCase(deleteFacilityStaff.pending, (state, action) => {
            // console.log('pending')
        })
        builder.addCase(deleteFacilityStaff.rejected, (state, action) => {
            // console.log('rejected')
        })

        // fetch facility/:facility_id/informations
        builder.addCase(fetchFacilityInformationsByFacilityId.fulfilled, (state, action) => {
            // console.log('fulfilled')
            return { ...state, facilityInformations: action.payload }
        })
        builder.addCase(fetchFacilityInformationsByFacilityId.pending, (state, action) => {
            // console.log('pending')
        })
        builder.addCase(fetchFacilityInformationsByFacilityId.rejected, (state, action) => {
            // console.log('rejected')
        })
    },
})
// 施設一覧の取得.
export const useFacilities = () => {
    return useSelector((state: RootState) => state.facility.facilities)
}
// 施設ページ.
// export const useFacilityPage = () => {
//     return useSelector((state: RootState) => state.facility.page)
// }
// 施設職員ページ.
// export const useFacilityStaffPage = () => {
//     return useSelector((state: RootState) => state.facility.staffPage)
// }
// export const useSelectedFacility = () => {
//     return useSelector((state: RootState) => state.facility.selectedItem)
// }
export const useFacilityById = (id: string) => {
    return useSelector((state: RootState) => state.facility.facilities.find((facility: Facility) => facility?.facility_id === id))
}
export const useFacilityStaffs = (facility_id: string) => {
    return useSelector((state: RootState) => {
        const facility = state.facility.facilities.find((item: Facility) => item.facility_id === facility_id)
        return facility?.staff
    })
}
// export const useSelectedStaff = () => {
//     return useSelector((state: RootState) => state.facility.selectedStaff)
// }
export const useFacilityUsers = () => {
    return useSelector((state: RootState) => state.facility.facilityUsers)
}
export const useFacilityUserById = (id: string) => {
    return useSelector((state: RootState) => state.facility.facilityUsers.find((facilityUser: User) => facilityUser?.user_id === id))
}
export const useFacilityLessons = () => {
    return useSelector((state: RootState) => state.facility.facilityLessons)
}
export const useFacilityLessonById = (id: string) => {
    return useSelector((state: RootState) => state.facility.facilityLessons.find((facilityLesson: Lesson) => facilityLesson?.lesson_id === id))
}
export const useFacilityInformations = () => {
    return useSelector((state: RootState) => state.facility.facilityInformations)
}
export const useFacilityInformationById = (id: string) => {
    const informationId = parseInt(id)
    return useSelector((state: RootState) => {
        console.log('お知らせ一覧', state.facility.facilityInformations)
        return state.facility.facilityInformations.find((info: Information) => {
            return info?.id === informationId
        })
    })
}
// Action Creatorsをエクスポート
export const {
    // changeFacilityPage,
    // changeFacilityStaffPage,
    // showStaffList,

    // editFacility,
    // editTrainingSetting,
    // editFacilityStaff,
    // setSelectedFacility,
    // setSelectedStaff,
    setFacilityLessons,
    setFacilityLessonExaminationAnswer,
    setFacilityLessonCommentAndRecords,
    setFacilityLessonStatus,
} = FacilityModule.actions

// Slice をエクスポートする
export default FacilityModule
