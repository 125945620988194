// import * as Const from '../Const'
import { trace } from '../common/index'
import React from 'react'
import { useAppDispatch } from '../store'
import { fetchFacilities } from '../modules/FacilityModule'

import { Grid, TableContainer, Table, TableBody } from '@material-ui/core'
import { FacilityCell, FacilityHeaderCell } from './FacilityCell'
import { Facility } from '../Types'
import PaginationActions from './PaginationActions'
import { useListStyles } from '../Styles'
import { isUndefined } from 'util'
import { useHistory, useRouteMatch } from 'react-router-dom'

/**
 * 施設一覧.
 *
 * @export
 * @returns
 */
export default function FacilityList() {
    const classes = useListStyles()
    const dispatch = useAppDispatch()

    const history = useHistory()
    const match = useRouteMatch()
    const listPageUrl = match.url

    const [facilities, setFacilities] = React.useState<Facility[]>([])

    // 初回ロード.
    React.useEffect(() => {
        dispatch(fetchFacilities()).then((res) => {
            let sorted = sortDataByUpdatedAt(res.payload)
            setFacilities(sorted)
        })
    }, [dispatch])

    // Sort data - desc 'updated_at'
    const sortDataByUpdatedAt = (data: Facility[]) => {
        return data.slice().sort((a, b) => {
            if (isUndefined(a.updated_at) || isUndefined(b.updated_at)) {
                return 0
            }
            if (a.updated_at > b.updated_at) return -1
            if (a.updated_at < b.updated_at) return 1
            return 0
        })
    }

    // セルがクリックされた
    const handleClick = (event: React.MouseEvent<unknown>, facility: Facility) => {
        trace('Cell clicked:' + facility.facility_name)
        history.push(`${listPageUrl}/${facility?.facility_id}`)
    }

    // [トレーニング設定]がクリックされた.
    const handleClickTrainingSetting = (event: React.MouseEvent<unknown>, facility: Facility) => {
        event.stopPropagation() // イベントバブリングの抑止
        history.push({
            pathname: `${listPageUrl}/${facility?.facility_id}/training-setting`,
            state: {
                facility: facility,
            },
        })
    }

    // [スタッフ一覧]がクリックされた.
    const handleClickStaff = (event: React.MouseEvent<unknown>, facility: Facility) => {
        event.stopPropagation() // イベントバブリングの抑止
        history.push(`${listPageUrl}/${facility?.facility_id}/staffs`)
    }

    // ### Pagination.
    // 現在のページ
    const [page, setPage] = React.useState(0)
    // １ページあたりの行数.
    const [rowsPerPage, setRowsPerPage] = React.useState(10)
    // ページが変更された.
    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage)
    }
    // ページ行数が変更された.
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<{
            name?: string | undefined
            value: any
        }>,
    ) => {
        trace('行数変更')
        if (typeof event.target.value !== 'number') {
            return
        }

        setRowsPerPage(event.target.value)
        setPage(0)
    }

    // レンダリング.
    return (
        <Grid container className={classes.content}>
            <Grid item xs={12}>
                <TableContainer>
                    <Table aria-label="customized table">
                        <FacilityHeaderCell />
                        <TableBody>
                            {(rowsPerPage > 0 ? facilities.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : facilities).map((facility, index) => {
                                return (
                                    <FacilityCell
                                        key={index}
                                        data={facility}
                                        onClickHandler={(event) => handleClick(event, facility)}
                                        onClickTrainingSettingHandler={(event) => handleClickTrainingSetting(event, facility)}
                                        onClickStaffHandler={(event) => handleClickStaff(event, facility)}
                                    />
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>

            <Grid item xs={12} className={classes.listfooter}>
                <PaginationActions
                    count={facilities.length}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    options={[
                        { key: '5', value: 5 },
                        { key: '10', value: 10 },
                        { key: '25', value: 25 },
                        { key: '50', value: 50 },
                        { key: '全て', value: facilities.length },
                    ]}
                />
            </Grid>
        </Grid>
    )
}
