import React from 'react'

import { Button, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import store from 'store'
import { setNeedLogin, useNeedLogin } from './AppModule'
import { logout } from './AuthModule'
// スタイル定義.
const useStyles = makeStyles((theme) => ({
    root: {
        width: 400,
    },
}))

type Props = {
    children: React.ReactNode
}
export type DialogItemParam = {
    label: string
    onClickHandler?: (event: any) => void
}
type DialogParam = {
    title: string
    message: string
    items: DialogItemParam[]
}

const context = React.createContext({
    showDialog: (desc: DialogParam) => {},
    closeDialog: () => {},
})

export function getDialogContext() {
    return context
}

export default function DialogProvider({ children }: Props) {
    const [isOpen, setOpen] = React.useState(false)
    const [title, setTitle] = React.useState('')
    const [description, setDescription] = React.useState('')
    const [items, setItems] = React.useState<DialogItemParam[]>([])

    // const dispatch = useAppDispatch()
    const DialogContext = getDialogContext()
    const isNeedLogin = useNeedLogin()

    React.useEffect(() => {
        if (isNeedLogin) {
            showDialog({
                title: "再ログインが必要です'",
                message: 'ログイン画面に遷移します',
                items: [
                    {
                        label: 'OK',
                        onClickHandler: () => {
                            store.dispatch(setNeedLogin(false))
                            store.dispatch(logout())
                        },
                    },
                ],
            })
        }
    }, [isNeedLogin])

    const showDialog = (desc: DialogParam) => {
        setTitle(desc.title)
        setDescription(desc.message)
        setItems(desc.items)
        setOpen(true)
    }
    const closeDialog = () => {
        setOpen(false)
    }
    const providerValue = {
        closeDialog: closeDialog,
        showDialog: showDialog,
    }
    return (
        <DialogContext.Provider value={providerValue}>
            {children}
            <AlertDialog />
            {/* {isOpen ? <AlertDialog /> : null} */}
        </DialogContext.Provider>
    )

    function AlertDialog() {
        const classes = useStyles()
        const handleClick = (item: DialogItemParam) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            setOpen(false)
            if (item.onClickHandler !== undefined) {
                console.log('onClickHandler')
                item.onClickHandler(event)
            }
        }
        return (
            <Dialog
                classes={{
                    paper: classes.root,
                }}
                open={isOpen}
                onClose={() => {
                    setOpen(false)
                }} // ダイアログ外をクリックしたときの挙動
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" disableTypography>
                    <Typography variant="h3">{title}</Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    {items.map((item, index) => (
                        <Button key={index} variant="outlined" onClick={handleClick(item)} color="primary">
                            {item.label}
                        </Button>
                    ))}
                </DialogActions>
            </Dialog>
        )
    }
}
