// import * as Const from '../Const'
// import {trace} from '../common/index'
import React from 'react'
import {
    makeStyles,
    createStyles,
    useTheme,
    Theme,
} from '@material-ui/core/styles'
import { IconButton, Select, MenuItem } from '@material-ui/core'

import {
    FirstPage as FirstPageIcon,
    KeyboardArrowLeft as PrevIcon,
    KeyboardArrowRight as NextIcon,
    LastPage as LastPageIcon,
} from '@material-ui/icons'

const useStyles1 = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            textAlign: 'right',
            flexShrink: 0,
            marginLeft: theme.spacing(6.5),
        },
        select: {
            minWidth: 100,
            marginTop: theme.spacing(0),
            textAlign: 'center',
        },
        text: {
            marginLeft: theme.spacing(1),
        },
    }),
)
interface PaginationActionsProps {
    count: number
    page: number
    rowsPerPage: number
    options: { key: string; value: number }[]
    onChangePage: (
        event: React.MouseEvent<HTMLButtonElement>,
        newPage: number,
    ) => void
    onChangeRowsPerPage: (
        event: React.ChangeEvent<{
            name?: string | undefined
            value: unknown
        }>,
    ) => void
}

export default function PaginationActions(props: PaginationActionsProps) {
    const classes = useStyles1()
    const theme = useTheme()
    const {
        count,
        page,
        rowsPerPage,
        options,
        onChangePage,
        onChangeRowsPerPage,
    } = props

    const handleFirstPageButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        onChangePage(event, 0)
    }

    const handleBackButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        onChangePage(event, page - 1)
    }

    const handleNextButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        onChangePage(event, page + 1)
    }

    const handleLastPageButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
    }
    const handleRowsPerPageChanged = (
        event: React.ChangeEvent<{
            name?: string | undefined
            value: unknown
        }>,
        child: React.ReactNode,
    ) => {
        onChangeRowsPerPage(event)
    }
    return (
        <React.Fragment>
            <div className={classes.root}>
                ページごとの行数:
                <Select
                    value={rowsPerPage}
                    onChange={handleRowsPerPageChanged}
                    displayEmpty
                    className={classes.select}
                    inputProps={{ 'aria-label': 'rows per page' }}
                >
                    {options.map((option, index) => {
                        return (
                            <MenuItem key={option.key} value={option.value}>
                                {option.key}
                            </MenuItem>
                        )
                    })}
                    ;
                </Select>
                {'　　'}
                {1 + page * rowsPerPage}-
                {Math.min(count, (1 + page) * rowsPerPage)} of {count}
                {'　'}
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0}
                    aria-label="first page"
                >
                    {theme.direction === 'rtl' ? (
                        <LastPageIcon />
                    ) : (
                        <FirstPageIcon />
                    )}
                </IconButton>
                <IconButton
                    onClick={handleBackButtonClick}
                    disabled={page === 0}
                    aria-label="previous page"
                >
                    {theme.direction === 'rtl' ? <NextIcon /> : <PrevIcon />}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="next page"
                >
                    {theme.direction === 'rtl' ? <PrevIcon /> : <NextIcon />}
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="last page"
                >
                    {theme.direction === 'rtl' ? (
                        <FirstPageIcon />
                    ) : (
                        <LastPageIcon />
                    )}
                </IconButton>
            </div>
        </React.Fragment>
    )
}
