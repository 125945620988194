// トレーニングモジュール
import { useSelector } from 'react-redux'
import {
    createSlice,
    // PayloadAction,
    createAsyncThunk,
} from '@reduxjs/toolkit'
import { RootState } from '../rootReducer'
import * as ApiClient from './ApiClient'
import { Training, TrainingTag, MspaExpectation, Examination } from '../Types'
import { isNullOrUndefined } from 'util'
import qs from 'query-string'

// ステート定義
type TrainingState = {
    trainings: Training[]
}

// React Thunk
export const fetchTrainingById = createAsyncThunk('training/fetchById', async (arg: { id: string; query: Object | null }, thunkAPI) => {
    let queryString = qs.stringify(arg.query ? arg.query : {})
    const response = await ApiClient.get(`/trainings/${arg.id}?${queryString}`)
    return response.data
})

export const fetchTrainingByIdWithFacilityId = createAsyncThunk(
    'training/fetchByIdWithFacilityId',
    async (arg: { facility_id: string; training_id: string }, thunkAPI) => {
        const response = await ApiClient.get(`facilities/${arg.facility_id}/trainings/${arg.training_id}`)
        return response.data
    },
)
export const fetchTrainings = createAsyncThunk('training/fetchAll', async (facility_id: string | null | undefined, thunkAPI) => {
    const url = isNullOrUndefined(facility_id) ? '/trainings' : `/facilities/${facility_id}/trainings`
    const response = await ApiClient.get(url)
    return response.data
})
export const fetchTrainingsWithUserId = createAsyncThunk(
    'training/fetchByFacilityIdWithUserId',
    async (arg: { facility_id: string | null; user_id: string | null }, thunkAPI) => {
        let url = ''
        if (arg.facility_id !== null) {
            url = url + `/facilities/${arg.facility_id}`
        }
        if (arg.user_id !== null) {
            url = url + `/users/${arg.user_id}`
        }
        url = url + `/trainings`
        console.log(`URL:${url}`)
        const response = await ApiClient.get(url)
        return response.data
    },
)

export const getLastLesson = createAsyncThunk('training/getLastLesson', async (arg: { user_id: string; training_id: string }, thunkAPI) => {
    const url = `/users/${arg.user_id}/trainings/${arg.training_id}/last`
    const response = await ApiClient.get(url)
    return response.data
})

/**
 * 作成 管理者のみ
 * method: POST
 * path: /training
 */
export const createTraining = createAsyncThunk('training/create', async (item: Training, thunkAPI) => {
    const url = '/trainings'
    console.log(`POST ${url}`, item)
    const response = await ApiClient.post(url, item)
    return { ...response, item: item }
})

// 更新.
// method: PUT
// path: /training/:id
// @params training: 更新する内容
export const updateTraining = createAsyncThunk('training/update', async (arg: { facility_id: string | null; item: Training }, thunkAPI) => {
    let url = isNullOrUndefined(arg.facility_id) ? '' : `/facilities/${arg.facility_id}`
    url = url + `/trainings/${arg.item.training_id}`
    const response = await ApiClient.put(url, arg.item)
    return { ...response, item: arg.item }
})

// 削除
// @path: /training/:id
export const deleteTraining = createAsyncThunk('training/delete', async (training_id: string, thunkAPI) => {
    const response = await ApiClient.delete_(`/trainings/${training_id}`)
    return { ...response, id: training_id }
})

// ステート初期値.
const initialState: TrainingState = {
    trainings: [],
}

// Slice を作成.
const TrainingModule = createSlice({
    name: 'training',
    initialState,
    reducers: {
        // コンテンツページ変更.
        // changeTrainingPage(state: TrainingState, action: PayloadAction<string>) {
        //     state.page = action.payload
        // },
        // setSelectedTraining(state: TrainingState, action: PayloadAction<Training | undefined>) {
        //     state.selectedItem = action.payload
        // },
        // editTraining(state: TrainingState, action: PayloadAction<Training>) {
        //     state.selectedItem = action.payload
        //     state.page = 'edit'
        // },
    },
    extraReducers: (builder) => {
        // fetch
        builder.addCase(fetchTrainings.fulfilled, (state, action) => {
            // console.log( 'fulfilled' );
            return { ...state, trainings: action.payload }
        })
        builder.addCase(fetchTrainings.pending, (state, action) => {
            // console.log('pending')
        })
        builder.addCase(fetchTrainings.rejected, (state, action) => {
            // console.log('rejected')
        })
        // fetch
        builder.addCase(fetchTrainingsWithUserId.fulfilled, (state, action) => {
            // console.log( 'fulfilled' );
            return { ...state, trainings: action.payload }
        })
        builder.addCase(fetchTrainingsWithUserId.pending, (state, action) => {
            // console.log('pending')
        })
        builder.addCase(fetchTrainingsWithUserId.rejected, (state, action) => {
            // console.log('rejected')
        })
        // fetch by id
        builder.addCase(fetchTrainingById.fulfilled, (state, action) => {
            // console.log('fulfilled')
            // return { ...state, trainings: trainings }
        })
        builder.addCase(fetchTrainingById.pending, (state, action) => {
            // console.log('pending')
        })
        builder.addCase(fetchTrainingById.rejected, (state, action) => {
            // console.log('rejected')
        })
        // add
        builder.addCase(createTraining.fulfilled, (state, action) => {
            // console.log('fulfilled:', action.payload)
            const trainings = state.trainings.filter((t) => t.training_id !== action.payload.item.training_id)
            state.trainings = [...trainings, action.payload.item]
            // state.page = 'list'
        })
        builder.addCase(createTraining.pending, (state, action) => {
            // console.log('pending')
        })
        builder.addCase(createTraining.rejected, (state, action) => {
            // console.log('rejected')
        })
        // update
        builder.addCase(updateTraining.fulfilled, (state, action) => {
            // console.log('fulfilled:', action.payload)
            // return {　...state,　trainings: action.payload　}
            let trainings = state.trainings.filter((t) => t.training_id !== action.payload.item.training_id)
            state.trainings = [...trainings, action.payload.item]
            // state.page = 'list'
        })
        builder.addCase(updateTraining.pending, (state, action) => {
            // console.log('pending')
        })
        builder.addCase(updateTraining.rejected, (state, action) => {
            // console.log('rejected')
        })
        // delete
        builder.addCase(deleteTraining.fulfilled, (state, action) => {
            // console.log('fulfilled')
            // console.log(action.payload)
            state.trainings = state.trainings.filter((t) => t.training_id !== action.payload.id)
            // state.page = 'list'
        })
        builder.addCase(deleteTraining.pending, (state, action) => {
            // console.log('pending')
        })
        builder.addCase(deleteTraining.rejected, (state, action) => {
            // console.log('rejected')
        })
    },
})

export const useTrainingItems = () => {
    return useSelector((state: RootState) => state.training.trainings)
}
export const useTrainingById = (id: string) => {
    return useSelector((state: RootState) => state.training.trainings.find((training: Training) => training?.training_id === id))
}
// export const useTrainingPage = () => {
//     return useSelector((state: RootState) => state.training.page)
// }
// export const useSelectedTraining = () => {
//     return useSelector((state: RootState) => state.training.selectedItem)
// }

// Action Creatorsをエクスポート
// export const {
// changeTrainingPage, setSelectedTraining, editTraining
// } = TrainingModule.actions

// Slice をエクスポートする
export default TrainingModule

//################################################################
/**
 * トレーニングデータの生成.
 *
 * @param {string} id
 * @param {string} name
 * @param {number} duration
 * @param {string} updated_at
 * @param {number} start
 * @param {number} end
 * @returns {Training}
 */
export function createTrainingItem(
    training_id: string, // トレーニングID.
    training_name: string, // トレーニング名.
    duration: number, // 所用時間.
    min_level: number, // 最低レベル
    max_level: number, // 最高レベル.
): Training {
    //  const density = population / size;
    const created_at = '2020/10/31 10:12'
    const updated_at = '2020/10/31 10:12'
    const required_license = 1
    const content_url = ''
    const description = ''
    const tags: TrainingTag[] = []
    const mspa_expectations: MspaExpectation[] = []
    const examinations: Examination[] = []
    return {
        training_id,
        training_name,
        content_url,
        duration,
        description,
        min_level,
        max_level,
        required_license,
        created_at,
        updated_at,
        tags,
        mspa_expectations,
        examinations,
    }
}
