import React, { useState } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import Pulldown2 from './Pulldown2'
import { TextField, Box, Button } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { FavoriteUser, StatusUser } from 'Types'

// スタイルを適用する
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            // flexGrow: 1,
            justifyContent: 'center',
            alignItems: 'flex-end',
        },
        textfield: {
            width: 360,
            marginRight: 'auto',
        },
        input: {
            height: 40,
            verticalAlign: 'center',
            paddingLeft: '10px',
            borderRadius: 5,
        },
        pulldown: {
            marginLeft: 10,
        },
        button: {
            marginLeft: 10,
            margin: 0,
            width: 170,
            height: 40,
            background: '#05A9C2',
            color: '#fff',
        },
    }),
)

type SearchProps = {
    searchText: string
    favoriteUser: FavoriteUser
    status: StatusUser
}

type StaffSearchBarProps = {
    onClickHandler?: (prop: SearchProps) => void
}

export default function StaffUserListSearchBar(props: StaffSearchBarProps) {
    // CSSを適用する。
    const classes = useStyles()
    const [filter, setFilter] = useState({
        searchText: '',
        favoriteUser: FavoriteUser.all,
        status: StatusUser.all,
    })

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter({
            ...filter,
            searchText: e.target.value,
        })
    }

    const handleFavoriteUserChange = (index: FavoriteUser) => {
        setFilter({
            ...filter,
            favoriteUser: index,
        })
    }

    const onChangeStatus = (index: StatusUser) => {
        console.log('index', index)
        setFilter({
            ...filter,
            status: index,
        })
    }

    const handleClickSearch = () => {
        if (props.onClickHandler) {
            props.onClickHandler(filter)
        }
    }

    return (
        <Box className={classes.root}>
            <Box display="flex" flexDirection="column">
                ユーザ名
                <TextField
                    InputProps={{
                        className: classes.input,
                    }}
                    variant="outlined"
                    fullWidth
                    defaultValue=""
                    placeholder="検索テキスト"
                    className={classes.textfield}
                    onChange={handleSearchChange}
                />
            </Box>
            <Box className={classes.pulldown}>
                担当
                <Pulldown2
                    style={{ width: 140 }}
                    options={[
                        { value: FavoriteUser.all, label: '全て' },
                        { value: FavoriteUser.favorite, label: '担当のみ' },
                        { value: FavoriteUser.noFavorite, label: '担当以外' },
                    ]}
                    width={140}
                    defaultValue={filter.favoriteUser}
                    onChangeHandler={handleFavoriteUserChange}
                />
            </Box>
            <Box className={classes.pulldown}>
                状況
                <Pulldown2
                    style={{ width: 260 }}
                    options={[
                        { value: StatusUser.all, label: 'すべて' },
                        { value: StatusUser.inTraining, label: 'トレーニング中' },
                        { value: StatusUser.offline, label: 'その他' },
                    ]}
                    width={260}
                    defaultValue={filter.status}
                    onChangeHandler={onChangeStatus}
                />
            </Box>
            <Box>
                &nbsp;
                <Button variant="outlined" className={classes.button} onClick={handleClickSearch}>
                    検索
                    <SearchIcon />
                </Button>
            </Box>
        </Box>
    )
}
