import { Filter } from '../Types'

export const filterItems = (items: Array<any>, filter: Filter) => {
    const sortedItems = [...items]
    sortedItems.sort((previous, next) => {
        if (previous[filter.orderBy] < next[filter.orderBy]) {
            return filter.sortBy === 'asc' ? -1 : 1
        }
        if (previous[filter.orderBy] > next[filter.orderBy]) {
            return filter.sortBy === 'asc' ? 1 : -1
        }
        return 0
    })
    return sortedItems
}
