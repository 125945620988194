// 管理者モジュール
import { useSelector } from 'react-redux'
import {
    createSlice,
    // PayloadAction,
    createAsyncThunk,
} from '@reduxjs/toolkit'
import { RootState } from '../rootReducer'
import * as ApiClient from './ApiClient'
import { Admin, OriginalField } from '../Types'

// ステート定義
type State = {
    admins: Admin[]
}

// ステート初期値.
const initialState: State = {
    admins: [],
}

// React Thunk
export const fetchAdminById = createAsyncThunk('admin/fetchById', async (id: string, thunkAPI) => {
    const response = await ApiClient.get('/admins/' + id)
    return response.data
})
export const fetchAdmins = createAsyncThunk('admin/fetchAll', async (arg, thunkAPI) => {
    const response = await ApiClient.get('/admins')
    return response.data
})
/**
 * 作成
 * method: POST
 * path: /admin
 */
export const createAdmin = createAsyncThunk('admin/create', async (admin: Admin, thunkAPI) => {
    const response = await ApiClient.post('/admins', admin)
    return { ...response, item: admin }
})

// 更新.
// method: PUT
// path: /admin/:id
// @note id の更新にも対応。
// @params  { original_id, admin }
//  original_id: オリジナルのID.（検索用）
//  admin: 更新する内容
export const updateAdminById = createAsyncThunk('admin/updateById', async (item: Admin & OriginalField, thunkAPI) => {
    const body = excludeOriginalField(item)
    const url = '/admins/' + item.original_id
    console.log('PUT', url, body)
    const response = await ApiClient.put(url, body)
    return { ...response, item: body }
})
function excludeOriginalField(item: Admin & OriginalField): Admin {
    console.log('exclude前: ', item)
    let state: Admin = {}
    for (const [key, value] of Object.entries(item)) {
        if (key !== 'original_id') {
            state = { ...state, [key]: value }
        }
    }
    console.log('exclude結果: ', state)
    return state
}

// 削除
// @path: /admin/:id
export const deleteAdmin = createAsyncThunk('admin/delete', async (id: string, thunkAPI) => {
    const response = await ApiClient.delete_('/admins/' + id)
    return { ...response, id: id }
})

// Slice を作成.
const AdminModule = createSlice({
    name: 'admins',
    initialState,
    reducers: {
        // setSelectedAdmin(state: State, action: PayloadAction<Admin | undefined>) {
        //     state.selectedItem = action.payload
        // },
        // ポータル変更.
        // changePortal(state: State, action: PayloadAction<string>) {
        //     state.portal = action.payload
        // },
        // コンテンツページ変更.
        // changeContentPage(state: State, action: PayloadAction<string>) {
        //     state.contentPage = action.payload
        // },
        // 管理者ページ変更.
        // changeAdminPage(state: State, action: PayloadAction<string>) {
        //     state.adminPage = action.payload
        // },
    },
    extraReducers: (builder) => {
        // fetch
        builder.addCase(fetchAdmins.fulfilled, (state, action) => {
            // console.log( 'fulfilled' );
            return { ...state, admins: action.payload }
        })
        builder.addCase(fetchAdmins.pending, (state, action) => {
            console.log('pending')
        })
        builder.addCase(fetchAdmins.rejected, (state, action) => {
            console.log('rejected')
        })
        // fetch by id
        builder.addCase(fetchAdminById.fulfilled, (state, action) => {
            console.log('fulfilled')
            // return { ...state, admins: admins }
        })
        builder.addCase(fetchAdminById.pending, (state, action) => {
            console.log('pending')
        })
        builder.addCase(fetchAdminById.rejected, (state, action) => {
            console.log('rejected')
        })
        // add
        builder.addCase(createAdmin.fulfilled, (state, action) => {
            console.log('fulfilled:', action.payload)
            const admins = state.admins.filter((t) => t.admin_id !== action.payload.item.admin_id)
            state.admins = [...admins, action.payload.item]
            // state.adminPage = 'list'
        })
        builder.addCase(createAdmin.pending, (state, action) => {
            console.log('pending')
        })
        builder.addCase(createAdmin.rejected, (state, action) => {
            console.log('rejected')
        })
        // update
        builder.addCase(updateAdminById.fulfilled, (state, action) => {
            console.log('fulfilled:', action.payload)
            // return {　...state,　admins: action.payload　}
            let admins = state.admins.filter((t) => t.admin_id !== action.payload.item.admin_id)
            state.admins = [...admins, action.payload.item]
            // state.adminPage = 'list'
        })
        builder.addCase(updateAdminById.pending, (state, action) => {
            console.log('pending')
        })
        builder.addCase(updateAdminById.rejected, (state, action) => {
            console.log('rejected')
        })
        // delete
        builder.addCase(deleteAdmin.fulfilled, (state, action) => {
            console.log('fulfilled')
            console.log(action.payload)
            state.admins = state.admins.filter((t) => t.admin_id !== action.payload.id)
            // state.adminPage = 'list'
        })
        builder.addCase(deleteAdmin.pending, (state, action) => {
            console.log('pending')
        })
        builder.addCase(deleteAdmin.rejected, (state, action) => {
            console.log('rejected')
        })
    },
})

export const useAdminItems = () => {
    const { admins } = useSelector((state: RootState) => state.admins)
    return admins
    //    return useSelector((state: ReturnType<typeof AdminModule.reducer>) => state.admins);
}
// export const useAdminContentPage = () => {
//     return useSelector((state: RootState) => state.admins.contentPage)
//     //    return useSelector((state: ReturnType<typeof AdminModule.reducer>) => state.admins);
// }
// export const useSelectedAdmin = () => {
//     return useSelector((state: RootState) => state.admins.selectedItem)
// }
export const useAdminById = (id: string) => {
    return useSelector((state: RootState) => state.admins.admins.find((admin: Admin) => admin?.admin_id === id))
}

// export const usePortal = () => {
//     return useSelector((state: RootState) => state.admins.portal)
// }
// export const useAdminPage = () => {
//     return useSelector((state: RootState) => state.admins.adminPage)
// }

// Action Creatorsをエクスポート
// export const {
// setSelectedAdmin,
// changeContentPage,
// changePortal,
// changeAdminPage
// } = AdminModule.actions

// Slice をエクスポートする
export default AdminModule
