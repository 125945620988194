// import * as Const from '../Const'
import { trace } from '../common/index'
import React from 'react'

import { useAppDispatch } from '../store'
import { useFacility } from '../modules/AppModule'

import { Grid } from '@material-ui/core'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

import { InformationListCell, InformationListHeaderCell } from './InformationListCell'
import { Information, InformationType } from '../Types'
import PaginationActions from './PaginationActions'
import { isUndefined } from 'util'
import { fetchFacilityInformationsByFacilityId, useFacilityInformations } from 'modules/FacilityModule'

const useListStyles = makeStyles((theme: Theme) =>
    createStyles({
        list: {
            flexGrow: 1,
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
        },
        listheader: {
            flexGrow: 1,
            marginTop: 'calc(0px)',
            marginBottom: 'calc(0px)',
        },
        listitem: {
            flexGrow: 1,
        },
        listfooter: {
            flexGrow: 1,
            marginTop: 'calc(8px)',
            marginBottom: 'calc(0px)',
        },
        scrollable: {
            overflow: 'auto',
        },
    }),
)

/**
 * トレーニング一覧プロパティ
 *
 * @interface InformationListProps
 */
interface InformationListProps {
    onClickHandler?: (item: Information) => void
    type: InformationType
}

export default function InformationList(props: InformationListProps) {
    const facility = useFacility()
    const facility_id = props.type === InformationType.SYSTEM ? null : (isUndefined(facility) || isUndefined(facility.facility_id) ? null : facility.facility_id)

    const dispatch = useAppDispatch()
    const classes = useListStyles()

    // 初回ロード.
    React.useEffect(() => {
        // if (facility_id === undefined || facility_id === null ) {
        //     return
        // }
        dispatch(fetchFacilityInformationsByFacilityId(facility_id))
    }, [dispatch, facility_id])
    const infos = useFacilityInformations();
    const items: Information[] = infos === undefined ? [] : infos.filter((item)=>{
        // 一覧の対象を施設のお知らせのみに絞り込む.
        return item.facility_id === facility_id
    })

    /**
     * セルがクリックされた
     */
    const handleClick = (event: React.MouseEvent<unknown>, item: Information) => {
        trace('list selected:' + item.title)
        if (!isUndefined(props.onClickHandler)) {
            props.onClickHandler(item)
        }
    }
    // ### Pagination.
    // 現在のページ
    const [page, setPage] = React.useState(0)
    // １ページあたりの行数.
    const [rowsPerPage, setRowsPerPage] = React.useState(10)
    // ページが変更された.
    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage)
    }
    // ページ行数が変更された.
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<{
            name?: string | undefined
            value: any
        }>,
    ) => {
        trace('行数変更')
        if (typeof event.target.value !== 'number') {
            return
        }

        setRowsPerPage(event.target.value)
        setPage(0)
    }

    // レンダリング.
    return (
        <React.Fragment>
            <Grid container className={classes.list}>
                <Grid item xs={12} className={classes.listheader}>
                    <InformationListHeaderCell />
                </Grid>
                {(rowsPerPage > 0 ? items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : items).map((item, index) => {
                    return (
                        <Grid item xs={12} className={classes.listitem} key={index}>
                            <InformationListCell data={item} onClickHandler={(event) => handleClick(event, item)} />
                        </Grid>
                    )
                })}
                <Grid item xs={12} className={classes.listfooter}>
                    <PaginationActions
                        count={items.length}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        options={[
                            { key: '5', value: 5 },
                            { key: '10', value: 10 },
                            { key: '25', value: 25 },
                            { key: '50', value: 50 },
                            { key: '全て', value: items.length },
                        ]}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    )
}
