// ### Import
// import * as Const from '../Const'
import { isUndefined, isNullOrUndefined } from 'util'

import React from 'react'

import { useAppDispatch } from '../store'
import { createFacilityStaff, updateFacilityStaffById, deleteFacilityStaff } from '../modules/FacilityModule'
import { useFacility, useProfile, setProfile, useHomePageUrl, usePortal } from '../modules/AppModule'

import { useIsMaxCreatedStaff, useStaffById } from '../modules/StaffModule'

// for validation
import { Controller, useForm } from 'react-hook-form'

// Material-UI
import { Grid, MenuItem, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

//### Import MyClass
import { Staff, Role, OriginalField, Display } from '../Types'
import * as DataUtil from '../modules/DataUtil'
import { DeleteButton, MainButton } from '../components/Buttons'
import PreviewInput2 from '../components/PreviewInput2'
import * as ImageUploader from '../modules/ImageUploader'
import { InputTextField, SelectTextField } from './Inputs'
import { useParams, useRouteMatch } from 'react-router-dom'
import { getSignedUrl } from 'modules/ImageModule'
import { getImageCacheContext } from 'modules/ImageCacheProvider'
import PasswordField from './form-controls/PasswordField'
import { isNotEmptyString } from 'modules/Util'

// スタイル定義.
const useStyles = makeStyles((theme) => ({
    form: {
        width: 730,
        display: 'flex',
        justifyContent: 'space-between',
        margin: '0 auto',
    },
    container: {
        width: 960,
        margin: '45px auto 20px',
    },
    left: {
        width: 480,
    },
    right: {
        width: 205,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    input: {
        marginBottom: 12,
        '& > label': {
            color: '#05A9C2',
        },
        '& input, .MuiSelect-select': {
            borderColor: '#B6B6B6',
        },
    },
    menuList: {
        width: 480,
    },
    btnGenerate: {
        border: 'unset',
    },
    btnDelete: {
        background: '#D02927',
        color: '#FFFFFF',
        border: 'unset',
        '&:hover': {
            background: '#D02927',
        },
    },
}))

type LocalState = {
    showPassword: boolean // パスワードを表示するか.
    showRememberMe: boolean // RememberMe を表示するか.
    password2?: string
}
/**
 * 編集プロパティ
 *
 * @interface FacilityStaffEditProps
 */
interface FacilityStaffEditProps {
    onClickHandler?: (item?: Staff) => void
    onCloseHandler?: () => void
    item?: Staff
}

// 編集タイプ定義
enum EditType {
    new = 'new',
    edit = 'edit',
}

/**
 * トレーニング編集.
 *
 * @returns
 */
export default function FacilityStaffEdit(props: FacilityStaffEditProps) {
    const imageCache = React.useContext(getImageCacheContext())
    console.log('施設職員編集')
    const dispatch = useAppDispatch()
    const portal = usePortal()
    const form = useForm<Staff & LocalState & OriginalField>()
    const {
        register,
        handleSubmit,
        errors,
        watch,
        getValues,
        formState: { isSubmitting },
        setError,
        control,
    } = form

    const homePageUrl = useHomePageUrl()
    const match = useRouteMatch()
    const currentPath = match.path

    // 削除.
    function handleDelete() {
        console.log('##### DELETE #####')

        const staff_id: string | undefined = getValues('staff_id')
        console.log(staff_id)
        if (staff_id) {
            dispatch(
                deleteFacilityStaff({
                    facility_id: facility_id,
                    staff_id: staff_id,
                }),
            ).then(() => handleClose())
        }
        if (props.onClickHandler !== undefined) {
            props.onClickHandler(undefined)
        } else {
            console.log('削除後の遷移が指定されていない')
        }
    }

    // TODO:: 所属する施設情報を２箇所から取得しているので、これをまとめる.
    const { facilityId, staffId } = useParams()
    let facility_id = ''
    let item: undefined | Staff = undefined
    const currentFacility = useFacility()
    const staffProfile = useProfile() as Staff
    let editedStaff = useStaffById(staffId)
    if (!isUndefined(editedStaff)) {
        editedStaff = { ...editedStaff, icon: imageCache.getStaffImage(editedStaff) }
    }
    // redirect to list page if id isn't loaded
    if (staffId && isUndefined(editedStaff)) {
        handleClose()
    }

    item = editedStaff
    if (portal === 'admin') {
        facility_id = facilityId
    }
    if (portal === 'staff' && currentPath === `${homePageUrl}/profile`) {
        item = staffProfile
    }
    if (isUndefined(facility_id) || facility_id === '') {
        facility_id = isUndefined(currentFacility) || isUndefined(currentFacility.facility_id) ? 'FID0001' : currentFacility.facility_id
    }
    const editType = isUndefined(item) ? EditType.new : EditType.edit

    const isMaxCreatedStaff = useIsMaxCreatedStaff()
    if (isUndefined(item)) {
        // Create staff setting page
        if (portal === 'staff' && currentPath === `${homePageUrl}/staffs/create` && isMaxCreatedStaff) {
            handleClose()
        }
        item = DataUtil.initStaff()
    }

    const [values, setValues] = React.useState<Staff>({ ...item, password: '' })
    const [iconFiles, setIconFiles] = React.useState<FileList | null>()
    const profile = useProfile() as Staff

    const [passwordValidation, setPasswordValidation] = React.useState<boolean>(editType === EditType.new ? true : false)
    const watchPassword = watch('password')

    React.useEffect(() => {
        if (editType === EditType.new) {
            return
        }
        if (watchPassword === '') {
            setPasswordValidation(false)
        } else {
            setPasswordValidation(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchPassword])

    // 内容変更.
    const handleChange = (prop: keyof Staff) => (event: React.ChangeEvent<HTMLInputElement> | null) => {
        console.log('changed' + prop + ':' + event?.target.value)
        if (prop === 'icon') {
            if (event === null || event.target.files === null) {
                setIconFiles(null)
                const newValues = { ...values, icon: '', icon_url: '' }
                setValues(newValues)
            } else {
                setIconFiles(event.target.files)
            }
            return
        }
        setValues({ ...values, [prop]: event === null ? null : event.target.value })
        console.log('changed:', values)
    }
    /**
     *　登録ボタンが押された時の処理.
     * react-hooks-from 処理後
     * @param {React.FormEvent} event
     * @returns
     */
    const onSubmit = async (data: Staff & LocalState & OriginalField) => {
        console.log('=== 送信処理', data)

        const original_id = data.original_id

        // 送信対象でない要素を除外.
        let staff = { ...data }

        // console.log('ICON:', values?.icon)

        delete staff.password2
        delete staff.original_id

        if (editType === EditType.edit && data.password === '') {
            delete staff['password']
        }

        console.log(staff)

        const file = isNullOrUndefined(iconFiles) ? null : iconFiles[0]
        await uploadImage(file)
            .then((res) => {
                staff.icon_url = res
            })
            .catch((error) => {
                staff.icon_url = values.icon_url
                console.log(error)
            })
            .finally(async () => {
                // react-hook-form で ref 対象外となっている要素を追加.(onChange で変更されているもの)
                if (isUndefined(staff.role_id)) {
                    staff.role_id = values.role_id
                }
                if (isUndefined(values.staff_id) || values.staff_id.length === 0) {
                    console.log('#### 施設職員: NEW')
                    await dispatch(
                        createFacilityStaff({
                            facility_id: facility_id,
                            item: staff,
                        }),
                    ).then((res: any) => {
                        if (res.payload === undefined && res.error.message === 'Calendar Not Found') {
                            setError('calendar_id', {
                                type: 'manual',
                                message: '選択されたカレンダーIDは正しくありません。',
                            })
                            return
                        }
                        handleClose()
                    })
                } else {
                    console.log('#### 施設職員: UPDATE')
                    await dispatch(
                        updateFacilityStaffById({
                            facility_id: facility_id,
                            item: { ...staff, original_id: original_id },
                        }),
                    ).then(async (res: any) => {
                        if (res.payload === undefined && res.error.message === 'Calendar Not Found') {
                            setError('calendar_id', {
                                type: 'manual',
                                message: '選択されたカレンダーIDは正しくありません。',
                            })
                            return
                        }
                        let icon = values?.icon
                        let newStaff = res.payload.item
                        if (newStaff.staff_id === profile.staff_id) {
                            if (file !== null) {
                                try {
                                    icon = ((await ImageUploader.makeBinaryImage(file, true)) as unknown) as string
                                } catch (e) {
                                    console.log(e)
                                }
                            }
                            console.log('new profile')

                            dispatch(setProfile(newStaff))

                            newStaff = { ...newStaff, icon: icon }
                            imageCache.addStaffs([newStaff])
                        }
                        handleClose()
                    })
                }
            })
    }

    // 画像のアップロード.
    function uploadImage(file: File | null | undefined): Promise<string> {
        return new Promise((resolve, reject) => {
            if (isNullOrUndefined(file)) {
                return reject('no file')
            }
            console.log(file)
            console.log('file.name', file.name)
            console.log('file.type', file.type)

            dispatch(getSignedUrl(file.type))
                .then((res) => {
                    console.log('$$$$ SignedURL', res)
                    const url = res.payload.url
                    const filename = res.payload.name
                    ImageUploader.upload(url, file)
                        .then((res) => {
                            console.log('SUCCESS', res, filename)
                            resolve(filename)
                        })
                        .catch((error) => {
                            console.log('ERROR', error)
                            reject(error)
                        })
                })
                .catch((error) => {
                    console.log('ERROR', error)
                    reject(error)
                })
        })
    }

    /**
     *　閉じるボタンが押された時の処理.
     * - axios で POST
     * @returns
     */
    function handleClose() {
        if (props.onCloseHandler !== undefined) {
            props.onCloseHandler()
        }
    }

    // スタイルの取得.
    const classes = useStyles()

    return (
        <React.Fragment>
            <Box className={classes.container}>
                {currentPath === `${homePageUrl}/profile` ? null : (
                    <ButtonDelete staffId={isUndefined(item) || item.staff_id === '' ? undefined : item.staff_id} onDeleteHandler={handleDelete} />
                )}
                <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
                    {/* {state.showWarning ? <Result /> : null} */}

                    <Box className={classes.left}>
                        {/* オリジナルID */}
                        <input type="hidden" name="original_id" value={values.staff_id} ref={register} />
                        {/* ID. */}
                        {currentPath === `${homePageUrl}/profile` ? (
                            <input type="hidden" name="staff_id" value={values.staff_id} ref={register} />
                        ) : (
                            <InputTextField
                                className={classes.input}
                                required
                                fullWidth
                                id="staff_id"
                                label="職員ID"
                                name="staff_id"
                                autoComplete=""
                                // autoFocus
                                defaultValue={values.staff_id}
                                // onChange={handleChange('title')}

                                // validation
                                inputRef={register({
                                    required: 'IDは必須です',
                                    pattern: {
                                        value: /^[A-Za-z.\-_\d]{6,16}$/,
                                        message: '英数字または記号『.-_』6〜16文字で入力してください',
                                    },
                                })}
                                error={Boolean(errors.staff_id)}
                                helperText={errors.staff_id && errors.staff_id.message}
                            />
                        )}
                        {/* 氏名. */}
                        <InputTextField
                            className={classes.input}
                            required
                            fullWidth
                            id="staff_name"
                            label="氏名"
                            name="staff_name"
                            autoComplete=""
                            // autoFocus
                            defaultValue={values.staff_name}
                            // onChange={handleChange('title')}

                            // validation
                            inputRef={register({
                                required: '氏名は必須です',
                                maxLength: {
                                    value: 20,
                                    message: '氏名は20文字以内にして下さい。',
                                },
                                validate: isNotEmptyString,
                            })}
                            error={Boolean(errors.staff_name)}
                            helperText={errors.staff_name && errors.staff_name.message}
                        />
                        {/* ふりがな */}
                        <InputTextField
                            className={classes.input}
                            required
                            fullWidth
                            id="staff_ruby"
                            label="ふりがな"
                            name="staff_ruby"
                            autoComplete=""
                            // autoFocus
                            defaultValue={values.staff_ruby}
                            // onChange={handleChange('title')}

                            // validation
                            inputRef={register({
                                required: 'ふりがなは必須です',
                                maxLength: {
                                    value: 20,
                                    message: 'ふりがなは20文字以内にして下さい。',
                                },
                                validate: isNotEmptyString,
                            })}
                            error={Boolean(errors.staff_ruby)}
                            helperText={errors.staff_ruby && errors.staff_ruby.message}
                        />
                        {/* 役職. */}
                        <InputTextField
                            className={classes.input}
                            fullWidth
                            id="job_title"
                            label="役職"
                            name="job_title"
                            autoComplete=""
                            // autoFocus
                            defaultValue={values.job_title}
                            // onChange={handleChange('title')}

                            // validation
                            inputRef={register({
                                maxLength: {
                                    value: 20,
                                    message: '役職は20文字以内にして下さい。',
                                },
                            })}
                            error={Boolean(errors.job_title)}
                            helperText={errors.job_title && errors.job_title.message}
                        />
                        {/* 電話番号. */}
                        <InputTextField
                            className={classes.input}
                            fullWidth
                            label="電話番号"
                            id="tel"
                            name="tel"
                            defaultValue={values.tel}
                            // validation
                            inputRef={register({
                                pattern: {
                                    value: /^0\d{2,3}-?\d{1,4}-?\d{4}$/,
                                    message: '正しい電話番号を入力してください。',
                                },
                            })}
                            error={Boolean(errors.tel)}
                            helperText={errors.tel && errors.tel.message}
                        />
                        {/* メールアドレス. */}
                        <InputTextField
                            className={classes.input}
                            fullWidth
                            label="メールアドレス"
                            id="mail"
                            name="mail"
                            defaultValue={values.mail}
                            // validation
                            inputRef={register({
                                pattern: {
                                    value: /^[a-zA-Z0-9-_.]+@[a-zA-Z0-9-_.]+$/,
                                    message: '正しいメールアドレスを入力してください。',
                                },
                            })}
                            error={Boolean(errors.mail)}
                            helperText={errors.mail && errors.mail.message}
                        />
                        {/* カレンダーID. */}
                        <InputTextField
                            className={classes.input}
                            fullWidth
                            label="カレンダーID"
                            name="calendar_id"
                            defaultValue={values.calendar_id}
                            inputRef={register({
                                pattern: {
                                    value: /^[a-zA-Z0-9-_.]+@[a-zA-Z0-9-_.]+$/,
                                    message: '正しいカレンダーIDを入力してください。',
                                },
                            })}
                            error={Boolean(errors.calendar_id)}
                            helperText={errors.calendar_id && errors.calendar_id.message}
                        />
                        {/* 権限. */}
                        {currentPath === `${homePageUrl}/profile` ? (
                            <input type="hidden" name="role_id" value={values.role_id} ref={register} />
                        ) : (
                            <SelectTextField
                                className={classes.input}
                                fullWidth
                                required
                                label="権限"
                                id="role_id"
                                name="role_id"
                                defaultValue={values.role_id}
                                // validation
                                inputRef={register({
                                    required: '権限は必須です', // 必須
                                })}
                                error={Boolean(errors.role_id)}
                                helperText={errors.role_id && errors.role_id.message}
                                select
                                onChange={handleChange('role_id')}
                            >
                                {[
                                    // { value: Role.unknown, label: '<未設定>' },
                                    { value: Role.staff, label: '一般職員' },
                                    { value: Role.manager, label: '管理者' },
                                ].map((option) => (
                                    <MenuItem key={option.label} value={option.value} className={classes.menuList}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </SelectTextField>
                        )}
                        {/* 保護者からのコメント */}
                        {currentPath === `${homePageUrl}/profile` ? (
                            <input type="hidden" name="show_comment_of_parent" value={values.show_comment_of_parent} ref={register} />
                        ) : (
                            <Controller
                                as={
                                    <SelectTextField className={classes.input} fullWidth select>
                                        {[
                                            { value: Display.hide, label: '表⽰しない' },
                                            { value: Display.show, label: '表⽰する' },
                                        ].map((option) => (
                                            <MenuItem key={option.label} value={option.value} className={classes.menuList}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </SelectTextField>
                                }
                                required
                                label="保護者からのコメント"
                                name="show_comment_of_parent"
                                defaultValue={values.show_comment_of_parent}
                                control={control}
                                rules={{
                                    required: '保護者からのコメントは必須です', // 必須
                                }}
                                error={Boolean(errors.show_comment_of_parent)}
                                helperText={errors.show_comment_of_parent && errors.show_comment_of_parent.message}
                            />
                        )}
                        {/* パスワード. */}
                        <PasswordField
                            className={classes.input}
                            form={form}
                            name="password"
                            label={editType === EditType.edit ? '新しいパスワード' : 'パスワード'}
                            defaultValue={values.password}
                            required={editType === EditType.new}
                            rules={
                                passwordValidation
                                    ? {
                                          required: 'パスワードは必須です', // 必須
                                          pattern: {
                                              value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)[A-Za-z\d]{8,16}$/,
                                              message: '英大文字・英小文字・数字をそれぞれ一つ以上含む、8〜16文字で入力してください',
                                          },
                                      }
                                    : { required: false }
                            }
                            eye
                        />
                        {/* パスワード確認. */}
                        <PasswordField
                            className={classes.input}
                            form={form}
                            name="password2"
                            label="パスワード確認"
                            defaultValue={values.password}
                            required={editType === EditType.new}
                            rules={{
                                required: passwordValidation ? 'パスワードを再入力してください' : false, // 必須
                                validate: {
                                    matchesPreviousPassword: (value: string) => {
                                        return value === watchPassword || 'パスワードが一致しません'
                                    },
                                },
                            }}
                            eye
                        />
                    </Box>
                    <Box className={classes.right}>
                        <Box width="100%">
                            <PreviewInput2 onChangeHandler={handleChange('icon')} src={values.icon} />
                        </Box>
                        <Box width="175px" marginBottom="12px">
                            <Footer type={editType} isSubmitting={isSubmitting} onCloseHandler={handleClose} />
                        </Box>
                    </Box>
                </form>
            </Box>
        </React.Fragment>
    )
}

type FooterProps = {
    type: EditType
    isSubmitting: boolean
    onCloseHandler?: (event: React.FormEvent<Element>) => void
}
function Footer(props: FooterProps) {
    const handleClose = (event: React.FormEvent<Element>) => {
        if (props.onCloseHandler) {
            props.onCloseHandler(event)
        }
    }

    switch (props.type) {
        case EditType.new:
            return (
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        {/* 登録. */}
                        <MainButton type="submit" disabled={props.isSubmitting} fullWidth>
                            登録
                        </MainButton>
                    </Grid>
                    <Grid item xs={12}>
                        {/* 閉じる. */}
                        <MainButton fullWidth onClick={handleClose}>
                            閉じる
                        </MainButton>
                    </Grid>
                </Grid>
            )
        default:
            return (
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        {/* 更新. */}
                        <MainButton type="submit" disabled={props.isSubmitting} fullWidth>
                            更新
                        </MainButton>
                    </Grid>
                    <Grid item xs={12}>
                        {/* 閉じる. */}
                        <MainButton fullWidth onClick={handleClose}>
                            閉じる
                        </MainButton>
                    </Grid>
                </Grid>
            )
    }
}

type ButtonDeleteProps = {
    onDeleteHandler?: () => void
    staffId?: string
}

function ButtonDelete(props: ButtonDeleteProps) {
    const classes = useStyles()
    const handleDelete = () => {
        if (props.onDeleteHandler) {
            props.onDeleteHandler()
        }
    }
    if (isUndefined(props.staffId)) {
        return <></>
    }
    return (
        <Box display="flex" justifyContent="flex-end" width="730px" margin="0px auto 10px" padding="0 30px">
            {/* 削除 */}
            <DeleteButton className={classes.btnDelete} onClick={handleDelete}>
                削除
            </DeleteButton>
        </Box>
    )
}
