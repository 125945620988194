// ### Import
// import * as Const from '../Const'
import { isUndefined } from 'util'

import React from 'react'

import { useAppDispatch } from '../store'
import { createAdmin, updateAdminById, deleteAdmin, useAdminById } from '../modules/AdminModule'

// for validation
import { useForm } from 'react-hook-form'

// Material-UI
import { Grid, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

//### Import MyClass
import { Admin, OriginalField } from '../Types'
import { DeleteButton, MainButton } from '../components/Buttons'
import { InputTextField } from './Inputs'
import { useParams } from 'react-router-dom'
import PasswordField from './form-controls/PasswordField'

// スタイル定義.
const useStyles = makeStyles((theme) => ({
    form: {
        width: 480,
        margin: '0 auto',
    },
    container: {
        margin: '45px auto 20px',
    },
    input: {
        marginBottom: 12,
        '& > label': {
            color: '#05A9C2',
        },
        '& input': {
            borderColor: '#B6B6B6',
        },
    },
    btnDelete: {
        background: '#D02927',
        color: '#FFFFFF',
        border: 'unset',
        '&:hover': {
            background: '#D02927',
        },
    },
    footer: {
        // backgroundColor: "#ff0000",
        margin: theme.spacing(2, 0, 2), // 上下マージン.
        padding: theme.spacing(1, 0, 1, 0), // パディング
    },
}))

type LocalState = {
    showPassword: boolean // パスワードを表示するか.
    password2?: string
}
/**
 * 編集プロパティ
 *
 * @interface AdminEditProps
 */
type AdminEditProps = {
    onClickHandler?: (item?: Admin) => void
    onCloseHandler?: () => void
}
// 編集タイプ定義
enum EditType {
    new = 'new',
    edit = 'edit',
}

/**
 * 編集.
 *
 * @returns
 */
export default function AdminEdit(props: AdminEditProps) {
    const dispatch = useAppDispatch()
    const form = useForm<Admin & LocalState & OriginalField>()
    const { register, handleSubmit, errors, watch, getValues } = form

    const { adminId } = useParams()
    let item = useAdminById(adminId)

    // redirect to list page if id isn't loaded
    if (adminId && isUndefined(item)) {
        handleClose()
    }

    const editType = isUndefined(item) ? EditType.new : EditType.edit

    if (isUndefined(item)) {
        item = {
            admin_name: '',
            admin_ruby: '',
        }
    }

    const [values] = React.useState<Admin>({ ...item, password: '' })

    /**
     *　登録ボタンが押された時の処理.
     * react-hooks-from 処理後
     * @param {React.FormEvent} event
     * @returns
     */
    const onSubmit = (data: Admin & LocalState & OriginalField) => {
        console.log('=== 送信処理', data, values)
        //
        let admin: Admin = {
            admin_id: data.admin_id,
            admin_name: data.admin_name,
            admin_ruby: data.admin_ruby,
            password: data.password,
        }
        if (editType === EditType.edit && data.password === '') {
            delete admin['password']
        }

        console.log(admin)

        if (isUndefined(values.admin_id) || values.admin_id.length === 0) {
            console.log('新規')
            dispatch(createAdmin(admin)).then(() => handleClose())
        } else {
            console.log('更新:')
            dispatch(updateAdminById({ ...admin, original_id: data.original_id })).then(() => handleClose())
        }
    }

    /**
     *　閉じるボタンが押された時の処理.
     * - axios で POST
     * @returns
     */
    function handleClose() {
        if (props.onCloseHandler !== undefined) {
            props.onCloseHandler()
        }
    }

    /** 削除ボタン */
    function handleDelete(event: React.FormEvent) {
        const id: string | undefined = getValues('admin_id')
        console.log(id)
        if (id) {
            dispatch(deleteAdmin(id))
        }
        event.preventDefault()
        handleClose()
    }
    const [passwordValidation, setPasswordValidation] = React.useState<boolean>(editType === EditType.new ? true : false)
    const watchPassword = watch('password')

    React.useEffect(() => {
        if (editType === EditType.new) {
            return
        }
        if (watchPassword === '') {
            setPasswordValidation(false)
        } else {
            setPasswordValidation(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchPassword])

    // スタイルの取得.
    const classes = useStyles()

    return (
        <React.Fragment>
            <Box className={classes.container}>
                <div>
                    {/* {state.showWarning ? <Result /> : null} */}

                    <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
                        <ButtonDelete type={editType} onDeleteHandler={handleDelete} />
                        {/* オリジナルID */}
                        <input type="hidden" name="original_id" value={values.admin_id} ref={register} />
                        {/* ID */}
                        {/* {values.admin_id ? <input type="hidden" name="admin_id" value={values.admin_id} ref={register} /> : null} */}
                        <InputTextField
                            className={classes.input}
                            required
                            fullWidth
                            id="admin_id"
                            label="管理者ID"
                            name="admin_id"
                            autoComplete=""
                            // autoFocus
                            defaultValue={values.admin_id}
                            // onChange={handleChange('title')}

                            // validation
                            inputRef={register({
                                required: 'IDは必須です',
                                pattern: {
                                    value: /^[A-Za-z.\-_\d]{6,16}$/,
                                    message: '英数字または記号『.-_』6〜16文字で入力してください',
                                },
                            })}
                            error={Boolean(errors.admin_id)}
                            helperText={errors.admin_id && errors.admin_id.message}
                        />
                        {/* 名前. */}
                        <InputTextField
                            className={classes.input}
                            required
                            fullWidth
                            id="admin_name"
                            label="氏名"
                            name="admin_name"
                            autoComplete=""
                            // autoFocus
                            defaultValue={values.admin_name}
                            // onChange={handleChange('title')}

                            // validation
                            inputRef={register({
                                required: '氏名は必須です',
                                maxLength: {
                                    value: 20,
                                    message: '氏名は20文字以内にして下さい。',
                                },
                            })}
                            error={Boolean(errors.admin_name)}
                            helperText={errors.admin_name && errors.admin_name.message}
                        />

                        {/* パスワード. */}
                        <PasswordField
                            className={classes.input}
                            form={form}
                            name="password"
                            label={editType === EditType.edit ? '新しいパスワード' : 'パスワード'}
                            defaultValue={values.password}
                            required={editType === EditType.new}
                            rules={
                                passwordValidation
                                    ? {
                                          required: 'パスワードは必須です', // 必須
                                          // minLength: { value: 8, message: 'パスワードを入力してください。(8〜16文字の英数記号)'},      // 8文字以上
                                          // maxLength:  { value: 16, message: 'パスワードを入力してください。(8〜16文字の英数記号)'},      // 20文字まで
                                          // pattern: { value: /^\d{3}-\d{4}$/, message: '正しい郵便番号を入力してください。' },
                                          pattern: {
                                              value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)[A-Za-z\d]{8,16}$/,
                                              message: '英大文字・英小文字・数字をそれぞれ一つ以上含む、8〜16文字で入力してください',
                                          },
                                      }
                                    : { required: false }
                            }
                            eye
                        />
                        {/* パスワード確認. */}
                        <PasswordField
                            className={classes.input}
                            form={form}
                            label="パスワード確認"
                            name="password2"
                            defaultValue={values.password}
                            required={editType === EditType.new}
                            rules={{
                                required: passwordValidation ? 'パスワードを再入力してください' : false, // 必須
                                validate: {
                                    matchesPreviousPassword: (value: string) => {
                                        return value === watchPassword || 'パスワードが一致しません'
                                    },
                                },
                            }}
                            eye
                        />
                        <div className={classes.footer}>
                            <Footer type={editType} onCloseHandler={handleClose} />
                        </div>
                    </form>
                </div>
            </Box>
        </React.Fragment>
    )
}

type FooterProps = {
    type: string
    onCloseHandler?: (event: React.FormEvent<Element>) => void
}
function Footer(props: FooterProps) {
    const handleClose = (event: React.FormEvent<Element>) => {
        if (props.onCloseHandler) {
            props.onCloseHandler(event)
        }
    }

    switch (props.type) {
        case 'new':
            return (
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        {/* 登録. */}
                        <MainButton type="submit" fullWidth>
                            登録
                        </MainButton>
                    </Grid>
                    <Grid item xs={6}>
                        {/* 閉じる. */}
                        <MainButton fullWidth onClick={handleClose}>
                            閉じる
                        </MainButton>
                    </Grid>
                </Grid>
            )
        case 'edit':
        default:
            return (
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        {/* 更新. */}
                        <MainButton type="submit" fullWidth>
                            更新
                        </MainButton>
                    </Grid>
                    <Grid item xs={6}>
                        {/* 閉じる. */}
                        <MainButton fullWidth onClick={handleClose}>
                            閉じる
                        </MainButton>
                    </Grid>
                </Grid>
            )
    }
}

type ButtonDeleteProps = {
    onDeleteHandler?: (event: React.FormEvent<Element>) => void
    type: EditType
}

function ButtonDelete(props: ButtonDeleteProps) {
    const classes = useStyles()
    const handleDelete = (event: React.FormEvent<Element>) => {
        if (props.onDeleteHandler) {
            props.onDeleteHandler(event)
        }
    }
    if (props.type === 'new') {
        return <></>
    }
    return (
        <Box display="flex" justifyContent="flex-end" marginBottom="20px">
            {/* 削除 */}
            <DeleteButton className={classes.btnDelete} onClick={handleDelete}>
                削除
            </DeleteButton>
        </Box>
    )
}
