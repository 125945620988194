import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import rootReducer from './rootReducer'
// import logger from 'redux-logger'

const middleware = getDefaultMiddleware({
    thunk: true,
    immutableCheck: true,
    serializableCheck: true,
})

// const middlewares = [...middleware, logger]
const store = configureStore({
    reducer: rootReducer,
    middleware: middleware,
    devTools: true,
})

export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()

export default store
