// ### Import
import * as Const from '../Const'
import { trace } from '../common/index'
import { isUndefined, isNull, isNullOrUndefined } from 'util'
import clsx from 'clsx'

import React from 'react'
import { useAppDispatch } from '../store'
import { createTraining, updateTraining, deleteTraining, getLastLesson, fetchTrainingById, fetchTrainingByIdWithFacilityId } from '../modules/TrainingModule'

// for validation
import { Controller, useForm } from 'react-hook-form'

import LevelBar from './LevelBar'
import Video360 from './Video360'
// Material-UI
import {
    Container,
    MenuItem,
    Grid,
    Paper,
    Box,
    Typography,
    FormControlLabel,
    TableContainer,
    TableHead,
    Table,
    TableRow,
    TableBody,
    FormHelperText,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

//### Import MyClass
import { Training, Examination, MspaExpectation, MspaEvaluationId, TrainingTag, User, Lesson, LessonStatus } from '../Types'
import * as DataUtil from '../modules/DataUtil'
import { DeleteButton, RoundedButton, MainButton } from '../components/Buttons'
import { InputCheckBox } from '../components/Inputs'
// import { InputExpectation } from './InputExpectation'
import { TabComponent } from '../components/TabComponent'
import { TrainingTestCell } from '../components/TrainingTestCell'
import { useFacility, usePortal, useProfile, useHomePageUrl } from 'modules/AppModule'
import { InputTextField, SelectTextField } from './Inputs'
import { LightStripedTableCell, LightStripedTableRow } from './Tables'
import VideoControlPanel from './VideoControlPanel'
import { unwrapResult } from '@reduxjs/toolkit'
import { v4 as uuidv4 } from 'uuid'
import { useParams, useLocation } from 'react-router-dom'
import useStaffRole from 'hooks/useStaffRole'
import VideoStateProvider from 'modules/VideoStateProvider'
import { setLessonMessage } from 'modules/LessonModule'

// スタイル定義.
const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(0),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    header: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        padding: theme.spacing(2, 0, 2),
    },
    title: {
        fontWeight: 'bold',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        fontSize: 'calc(20px)', // default: 16px
        margin: theme.spacing(3, 0, 2), // 上下マージン.
        padding: theme.spacing(1, 0, 1, 0), // パディング
    },
    margin: {
        marginTop: theme.spacing(2),
    },
    withoutLabel: {
        marginTop: theme.spacing(3),
    },
    textField: {
        width: '100%', // Fix IE 11 issue.
    },
    // Warning
    warning: {
        color: '#ff0000',
        marginTop: theme.spacing(2),
    },

    levelBar: {
        display: 'block',
        width: '100%',
        marginBottom: 20,
    },
    labelName: {
        fontSize: 16,
        padding: '5px 10px',
        display: 'block',
        background: 'transparent linear-gradient(0deg, #5A5A5A 0%, #5F5F5F 7%, #707070 34%, #7A7A7A 64%, #7E7E7E 100%)',
        color: theme.palette.common.white,
        boxShadow: '1px 1px 2px #0000004D',
        marginBottom: 18,
    },
    labelError: {
        color: theme.palette.error.main,
    },
    tab_paper: {
        marginTop: theme.spacing(0),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        border: 'unset',
    },
    formTop: {
        padding: 50,
        backgroundColor: '#E2E2E2',
    },
    input: {
        marginBottom: 20,
        '& .MuiInputBase-input': {
            border: 'none',
            boxShadow: '1px 1px 2px #0000004D',
            '&.Mui-disabled': {
                opacity: 0.6,
            },
        },
        '& label.MuiInputLabel-root': {
            fontSize: 16,
            padding: '5px 10px',
            display: 'block',
            width: '100%',
            transform: 'translate(0, -2.5px) scale(1)',
            transformOrigin: 'top left',
            background: 'transparent linear-gradient(0deg, #5A5A5A 0%, #5F5F5F 7%, #707070 34%, #7A7A7A 64%, #7E7E7E 100%)',
            color: theme.palette.common.white,
            boxShadow: '1px 1px 2px #0000004D',
            '&.Mui-error': {
                color: '#f44336',
            },
        },
    },
    smallInput: {
        width: '48.8%',
    },
    btnDelete: {
        background: '#D02927',
        color: '#FFFFFF',
        border: 'unset',
        '&:hover': {
            background: '#D02927',
        },
    },
    footer: {
        marginTop: 20,
        justifyContent: 'center',
    },
}))

// ===================================================
type TrainingEditProps = {
    onCloseHandler?: (backUrl: string) => void
}

interface LocationState {
    backUrl: string
}

// min and max level is greater or equal 1
const isRequiredLevels = (levels: number[]) => {
    if (!Array.isArray(levels) || levels.length !== 2) return false
    return levels[0] + levels[1] > 1
}

const initialTraining = {
    training_id: '',
    training_name: '',
    content_url: '',
    duration: 0,
    description: '',
    min_level: 0,
    max_level: 0,
    required_license: 1,
    created_at: '',
    updated_at: '',
    tags: [],
    mspa_expectations: [],
    examinations: [],
}

/**
 * トレーニング編集.
 *
 * @returns
 */
export default function TrainingEdit(props: TrainingEditProps) {
    const facility = useFacility()
    const portal = usePortal()

    const homePageUrl = useHomePageUrl()
    const location = useLocation<LocationState>()
    const { trainingId } = useParams()
    const [values, setValues] = React.useState<Training>(initialTraining)

    const facility_id = isUndefined(facility) || isUndefined(facility.facility_id) ? null : facility.facility_id
    console.log(`施設:${facility_id}`)

    const profile = useProfile()
    const { isGeneralStaff } = useStaffRole()
    let user_id: string | null = null
    if (portal === 'user') {
        const user = profile as User
        user_id = isUndefined(user) || isUndefined(user.user_id) ? null : user.user_id
    }
    console.log(`ユーザ":${user_id}`)

    const classes = useStyles()
    const dispatch = useAppDispatch()
    const {
        register,
        handleSubmit,
        errors,
        getValues,
        formState: { isSubmitting },
        control,
        setValue,
        reset,
    } = useForm<Training & { levels: any }>()

    React.useEffect(() => {
        if (!trainingId) return
        const getTrainingDetail = async (trainingId: string, facilityId: string | null) => {
            let res: any
            if (portal === 'admin') {
                res = await dispatch(fetchTrainingById({ id: trainingId, query: {} }))
            } else {
                if (!facilityId) return
                res = await dispatch(fetchTrainingByIdWithFacilityId({ facility_id: facilityId, training_id: trainingId }))
            }
            const trainings = res?.payload ?? []
            if (!trainings.length) {
                handleClose()
                return
            }
            const training = trainings[0]
            const trainingWithRandomKey = addRandomKey(training)
            setValues(trainingWithRandomKey)
            // Reset default value in react-hook-form
            reset(training)
        }
        getTrainingDetail(trainingId, facility_id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, reset, trainingId, facility_id])

    React.useEffect(() => {
        dispatch(setLessonMessage(''))
    })

    // Update levels value in react-hook-form
    React.useEffect(() => {
        setValue('levels', [values.min_level, values.max_level])
    }, [setValue, values.min_level, values.max_level])

    const addRandomKey = (item: Training) => {
        let examinations = item.examinations.map((examination: Examination) => {
            return { ...examination, key: uuidv4() }
        })
        return {
            ...item,
            examinations,
        }
    }

    console.log('### Training', values)

    const training_id = values.training_id
    const [lastLesson, setLastLesson] = React.useState<Lesson | null>(null)
    React.useEffect(() => {
        if (isNullOrUndefined(user_id) || isNullOrUndefined(training_id)) {
        } else {
            dispatch(getLastLesson({ user_id, training_id }))
                .then(unwrapResult)
                .then((res) => {
                    console.log(res)
                    if (res !== false) {
                        setLastLesson(res)
                    }
                })
        }
    }, [dispatch, user_id, training_id])
    console.log(`LastLesson: ${lastLesson}`)
    // 内容変更.
    const handleChange = (prop: keyof Training) => (event: React.ChangeEvent<HTMLInputElement>) => {
        trace('changed')
        setValues({ ...values, [prop]: event.target.value })
    }

    /**
     *　登録ボタンが押された時の処理.
     * - バリデーション
     * - axios で POST
     * @param {React.FormEvent} e
     * @returns
     */
    const onSubmit = async (data: Training & { levels: any }) => {
        trace('=== 送信処理')
        console.log('*** 送信:', facility_id, data, values)

        // ### useForm で取得した data 以外の情報をセットする.
        // -- タグ.
        // タグを全て現在の施設のものに変更（管理者タグのオーバーレイ
        let tags = values.tags
            .filter((tag: TrainingTag) => {
                return tag.tag !== '' // 空行を除く.
            })
            .map((tag: TrainingTag) => {
                return { ...tag, facility_id: facility_id }
            })
        // 空のときは、空タグを強制追加.
        if (tags.length === 0) {
            tags = [{ facility_id: facility_id, tag: '' }]
        }

        // Remove random key
        let examinations = values.examinations
        for (let i = 0; i < examinations.length; i++) {
            delete examinations[i].key
        }

        const params = {
            ...data,
            tags: tags,
            min_level: data.levels[0],
            max_level: data.levels[1],
            required_license: values.required_license,
            examinations: examinations,
            mspa_expectations: values.mspa_expectations,
            duration: values.duration,
        }
        delete params.levels
        if (isUndefined(data.training_id) || data.training_id === '') {
            console.log('--新規登録', params)
            delete params.training_id
            await dispatch(createTraining(params)).then(() => handleClose())
        } else {
            console.log('--更新', params)
            await dispatch(updateTraining({ facility_id: facility_id, item: params })).then(() => handleClose())
        }
    }
    /**
     *　閉じるボタンが押された時の処理.
     * - バリデーション
     * - axios で POST
     * @param {React.FormEvent} e
     * @returns
     */
    // function handleClose(e: React.FormEvent) {
    //     e.preventDefault()
    //     dispatch(changeTrainingPage('list'))
    // }
    /** 削除ボタン */
    function handleDelete() {
        console.log('##### DELETE #####')

        const training_id: string | undefined = getValues('training_id')
        console.log(training_id)
        if (training_id) {
            dispatch(deleteTraining(training_id)).then(() => handleClose())
        }
    }

    // テスト項目追加
    function handleAddExamination() {
        setValues({
            ...values,
            examinations: [...values.examinations, { key: uuidv4(), time: '--:--:--', question: '', options: [], correct: 0 }],
        })
    }

    const handleRemoveExamination = (index: number) => {
        const examinations = values.examinations.filter((training, idx) => idx !== index)
        setValues({ ...values, examinations: examinations })
    }

    // テスト項目変更.
    // 管理者ポータルの場合のみ.
    const handleChangeExamination = (index: number) => (value: Examination) => {
        console.log('Exam changed:', value)

        const examinations = values.examinations.map((item, idx) => {
            return index === idx ? value : item
        })
        setValues({
            ...values,
            examinations: examinations,
        })
    }

    // タグ変更.
    const handleChangeTag = (event: React.ChangeEvent<HTMLInputElement>) => {
        const tags = event.target.value.split('\n')
        console.log('TAGs', tags)
        setValues({
            ...values,
            tags: tags.map((tag) => {
                return { facility_id: facility_id, tag: tag }
            }),
        })
    }

    // MSPA 期待効果
    const handleChangeExpectation = (data: any) => {
        console.log('Mspa', data)
        console.log('values:', values)

        const items = data as {
            mspa_id: MspaEvaluationId
            label: string
            checked: boolean
            value: number
        }[]
        const expectations = items
            .filter((item) => item.checked)
            .map((item) => {
                return {
                    training_id: values.training_id,
                    mspa_evaluation_id: item.mspa_id,
                    expectation: item.value,
                }
            })

        console.log(expectations)
        // {mspa_id: "mspa0001", label: "コミュニケーション", checked: true, value: 1}
        // {training_id: "Training0001", mspa_evaluation_id: "mspa0001", expectation: 1}

        setValues({
            ...values,
            mspa_expectations: expectations,
        })
    }

    // ライセンス数のオプション.
    const license_options: { value: number; label: string }[] = []
    for (var i = 1; i <= Const.Setting.LICENSE_MAX; i++) {
        license_options.push({ value: i, label: i.toString() })
    }
    const handleChangeLicense = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(event.target.value)
        setValues({ ...values, required_license: parseInt(event.target.value) })
    }
    // Video event
    const onLoadedVideo = (value: number) => {
        const duration = Math.ceil(value / 60)
        console.log(`### duration:${value}=>${duration}`)
        setValues({ ...values, duration: duration })
    }

    function handleClose() {
        if (!isUndefined(props.onCloseHandler)) {
            const backUrl = isNullOrUndefined(location.state) || isUndefined(location.state.backUrl) ? `${homePageUrl}/trainings` : location.state.backUrl
            props.onCloseHandler(backUrl)
        }
    }

    // レンダリング
    return (
        <React.Fragment>
            <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
                <Box className={classes.formTop}>
                    <ButtonDelete />
                    <div className={classes.paper}>
                        {/* トレーニングID */}
                        <input type="hidden" name="training_id" value={values.training_id} ref={register} />
                        {/* トレーニング名. */}
                        <InputTextField
                            disabled={portal !== 'admin'}
                            className={classes.input}
                            required={portal !== 'user'}
                            fullWidth
                            id="training_name"
                            label="トレーニング名"
                            name="training_name"
                            autoComplete=""
                            autoFocus
                            defaultValue={values.training_name}
                            // validation
                            inputRef={register({
                                required: true, // 必須
                                maxLength: 50, // 50文字まで
                            })}
                            error={Boolean(errors.training_name)}
                            helperText={errors.training_name && 'タイトルは50文字以内にして下さい。'}
                        />
                        {/* 動画URL. */}
                        {/* https://nukohouse.com/vr/video-1920x960a.mp4
                            https://ucarecdn.com/fadab25d-0b3a-45f7-8ef5-85318e92a261/ */}
                        {portal !== 'user' && (
                            <InputTextField
                                disabled={portal !== 'admin'}
                                className={classes.input}
                                required
                                fullWidth
                                id="content_url"
                                label="動画URL"
                                name="content_url"
                                autoComplete=""
                                autoFocus
                                defaultValue={values.content_url}
                                onChange={handleChange('content_url')}
                                // validation
                                inputRef={register({ required: true })}
                                error={Boolean(errors.content_url)}
                                helperText={errors.content_url && '動画URL は必須です'}
                            />
                        )}
                        {/* 動画再生エリア */}
                        <Box width="420" marginBottom="20px">
                            {values.content_url !== undefined && values.content_url !== '' ? (
                                <Box>
                                    <Video360 height="325" url={values.content_url} />
                                    <VideoStateProvider>
                                        <VideoControlPanel onLoadedVideo={onLoadedVideo} />
                                    </VideoStateProvider>
                                </Box>
                            ) : null}
                        </Box>
                        {/* 説明. */}
                        <InputTextField
                            disabled={portal !== 'admin'}
                            className={classes.input}
                            fullWidth
                            id="description"
                            label="説明"
                            name="description"
                            autoComplete=""
                            autoFocus
                            defaultValue={values.description}
                            multiline
                            rows={4}
                            inputRef={register}
                        />
                        {/* レベル */}
                        <Box className={classes.levelBar}>
                            <label className={clsx(classes.labelName, errors.levels?.type === 'validate' && classes.labelError)}>
                                レベル {portal !== 'user' ? '*' : ''}
                            </label>
                            <Controller
                                control={control}
                                name="levels"
                                defaultValue={[values.min_level, values.max_level]}
                                rules={{ validate: isRequiredLevels }}
                                render={({ onChange }) => (
                                    <LevelBar
                                        readonly={portal === 'user' || isGeneralStaff}
                                        bigLevel
                                        onChangeHandler={onChange}
                                        min={isUndefined(values.min_level) ? 0 : values.min_level}
                                        max={isUndefined(values.max_level) ? 0 : values.max_level}
                                    />
                                )}
                            />
                            <FormHelperText error={true}>{errors.levels?.type === 'validate' && 'レベルは必須です'}</FormHelperText>
                        </Box>
                        {portal === 'user' ? (
                            <React.Fragment>
                                <InputTextField
                                    className={classes.input}
                                    disabled
                                    fullWidth
                                    id="staff_id"
                                    label="最終受講日時"
                                    name="staff_id"
                                    autoComplete=""
                                    value={
                                        isNull(lastLesson) ||
                                        isUndefined(lastLesson.start) ||
                                        isUndefined(lastLesson.status) ||
                                        lastLesson.status === LessonStatus.finish
                                            ? ''
                                            : lastLesson.start
                                    }
                                />
                                <InputTextField
                                    className={classes.input}
                                    disabled
                                    fullWidth
                                    id="staff_id"
                                    label="最終予約日時"
                                    name="staff_id"
                                    autoComplete=""
                                    value={
                                        isNull(lastLesson) ||
                                        isUndefined(lastLesson.start) ||
                                        isUndefined(lastLesson.status) ||
                                        lastLesson.status === LessonStatus.reserved
                                            ? ''
                                            : lastLesson.start
                                    }
                                />
                            </React.Fragment>
                        ) : null}
                        {/* ライセンス. */}
                        {portal !== 'user' && (
                            <Box width="100%" display="flex" justifyContent="space-between">
                                {portal === 'admin' && (
                                    <SelectTextField
                                        className={clsx(classes.input, classes.smallInput)}
                                        required
                                        fullWidth
                                        id="required_license"
                                        label="ライセンス"
                                        name="required_license"
                                        defaultValue={values.required_license}
                                        value={values.required_license}
                                        // validation
                                        inputRef={register({ required: true })}
                                        error={Boolean(errors.required_license)}
                                        helperText={errors.required_license && 'ライセンスは必須です'}
                                        onChange={handleChangeLicense}
                                    >
                                        {license_options.map((option) => (
                                            <MenuItem key={option.label} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </SelectTextField>
                                )}
                                {/* タグ. */}
                                <InputTextField
                                    className={clsx(classes.input, portal === 'admin' && classes.smallInput)}
                                    disabled={isGeneralStaff}
                                    // required
                                    fullWidth
                                    id="tags"
                                    label="タグ"
                                    name="tags"
                                    defaultValue={values.tags.map((item) => item.tag).join('\n')}
                                    value={values.tags.map((item) => item.tag).join('\n')}
                                    // onChange={handleChange('tags')}
                                    multiline
                                    // rows={1}
                                    // validation
                                    onChange={handleChangeTag}
                                    // inputRef={register({
                                    // required: 'タグは必須です',
                                    // })}
                                    // error={Boolean(errors.tags)}
                                    // helperText={
                                    //     errors.tags && errors.tags.message
                                    // }
                                />
                            </Box>
                        )}
                    </div>
                </Box>

                {portal !== 'user' && (
                    <Box margin="30px 0">
                        <Paper variant="outlined" className={classes.tab_paper}>
                            {/* タブ. */}
                            <TabComponent
                                disabled={portal !== 'admin'}
                                items={[
                                    {
                                        label: '対応MSPA評価指標/期待効果',
                                        content: <MspaExpectationPanel items={values.mspa_expectations} onChangeHandler={handleChangeExpectation} />,
                                    },
                                    {
                                        label: 'テスト',
                                        content: (
                                            <React.Fragment>
                                                {isUndefined(values.examinations) ? (
                                                    ''
                                                ) : (
                                                    <TableContainer>
                                                        <Table aria-label="customized table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <LightStripedTableCell width="16.3%" align="center">
                                                                        時間
                                                                    </LightStripedTableCell>
                                                                    <LightStripedTableCell width="38.04%" align="center">
                                                                        設問
                                                                    </LightStripedTableCell>
                                                                    <LightStripedTableCell width="31%" align="center">
                                                                        選択肢
                                                                    </LightStripedTableCell>
                                                                    <LightStripedTableCell align="center">正解</LightStripedTableCell>
                                                                    <LightStripedTableCell></LightStripedTableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {values.examinations.map((exam, index) => {
                                                                    return (
                                                                        <TrainingTestCell
                                                                            key={isUndefined(exam) || isUndefined(exam.key) ? index : exam.key}
                                                                            item={exam}
                                                                            onChangeHandler={handleChangeExamination(index)}
                                                                            onRemoveHandler={() => handleRemoveExamination(index)}
                                                                        />
                                                                    )
                                                                })}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                )}
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'flex-end',
                                                    }}
                                                >
                                                    <RoundedButton
                                                        style={{ marginTop: 15, background: 'white' }}
                                                        variant="outlined"
                                                        onClick={handleAddExamination}
                                                    >
                                                        <Typography variant="h4">追加</Typography>
                                                    </RoundedButton>
                                                </div>
                                            </React.Fragment>
                                        ),
                                    },
                                ]}
                            />
                        </Paper>
                    </Box>
                )}

                <Container component="main" maxWidth="xs">
                    <div className={classes.paper}>
                        <Footer />
                    </div>
                </Container>
            </form>
        </React.Fragment>
    )

    function Footer() {
        // FIX: Hide 予約する btn
        // let button = (
        //     <MainButton type="submit" fullWidth disabled={isSubmitting}>
        //         予約する
        //     </MainButton>
        // )
        let button
        if (portal !== 'user' && !isGeneralStaff) {
            button = (
                <MainButton type="submit" fullWidth disabled={isSubmitting}>
                    {isUndefined(values) || values.training_id === '' ? '登録' : '更新'}
                </MainButton>
            )
        }
        return (
            <Grid container spacing={5} className={classes.footer}>
                {button && (
                    <Grid item xs={6}>
                        {/* 更新. */}
                        {button}
                    </Grid>
                )}
                <Grid item xs={6}>
                    {/* 閉じる. */}
                    <MainButton fullWidth onClick={handleClose}>
                        閉じる
                    </MainButton>
                </Grid>
            </Grid>
        )
    }

    function ButtonDelete() {
        if (isUndefined(values) || values.training_id === '' || portal !== 'admin') {
            return <></>
        }
        return (
            <Box display="flex" justifyContent="flex-end" marginBottom="20px">
                {/* 削除 */}
                <DeleteButton className={classes.btnDelete} onClick={handleDelete}>
                    削除
                </DeleteButton>
            </Box>
        )
    }
}

// スタイル定義.
const useStyles2 = makeStyles((theme) => ({
    checkbox: {
        marginLeft: 0,
    },
    tableCell: {
        padding: '0 10px',
    },
    input: {
        paddingBottom: 6,
        '& .MuiInputBase-input': {
            border: 'none',
            boxShadow: '1px 1px 2px #0000004D',
        },
        '& .MuiSelect-select.Mui-disabled': {
            backgroundColor: '#D3D3D3',
        },
    },
    paper: {
        minHeight: 700,
    },
}))

type Level = {
    mspa_id: string
    label: string
    checked: boolean
    value: number
}

type MspaExpectationPanelProps = {
    items: MspaExpectation[]
    onChangeHandler?: (levels: Level[]) => void
}
function MspaExpectationPanel(props: MspaExpectationPanelProps) {
    const classes = useStyles2()
    const mspa_table = DataUtil.getMspaTable()

    const [levels, setLevels] = React.useState<Level[]>([])

    React.useEffect(() => {
        // 表示用データに加工
        const levels = mspa_table.map((row) => {
            const target = props.items.find((v) => {
                return v.mspa_evaluation_id === row.mspa_evaluation_id
            })
            return {
                mspa_id: row.mspa_evaluation_id,
                label: row.label,
                checked: !isUndefined(target),
                value: isUndefined(target) ? 0 : target.expectation,
            }
        })
        setLevels(levels)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.items])

    // チェックボックス変更
    const handleChange = (mspa_id: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const newLevels = levels.map((row) => {
            if (row.mspa_id === mspa_id) {
                row.checked = event.target.checked
            }
            return row
        })
        setLevels(newLevels)

        if (!isUndefined(props.onChangeHandler)) {
            props.onChangeHandler(newLevels)
        }
    }
    // 期待効果更新.
    const handleChangeExpectation = (mspa_id: string, value: number | null) => {
        console.log('handleChangeExpectation', mspa_id, value)

        const newLevels = levels.map((row) => {
            if (row.mspa_id === mspa_id) {
                row.value = isNull(value) ? 1 : Math.min(Math.max(value, 1), 10)
            }
            return row
        })
        setLevels(newLevels)

        if (!isUndefined(props.onChangeHandler)) {
            props.onChangeHandler(newLevels)
        }
    }

    return (
        <Paper square className={classes.paper}>
            <TableContainer>
                <Table aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <LightStripedTableCell width="87.5%" align="center">
                                MSPA評価指標
                            </LightStripedTableCell>
                            <LightStripedTableCell align="center">期待効果</LightStripedTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {levels.map((item) => (
                            <LightStripedTableRow key={item.mspa_id}>
                                <LightStripedTableCell className={classes.tableCell}>
                                    {/* チェックボックス＋ラベル */}
                                    <FormControlLabel
                                        control={
                                            <InputCheckBox
                                                checked={item.checked}
                                                onChange={handleChange(item.mspa_id)}
                                                // value="CheckboxA"
                                            />
                                        }
                                        label={item.label}
                                        className={classes.checkbox}
                                    />
                                </LightStripedTableCell>
                                <LightStripedTableCell className={classes.tableCell}>
                                    {/* 期待効果 */}
                                    {/* <InputExpectation
                                        disabled={!item.checked}
                                        id={'expectation' + item.mspa_id}
                                        name={'expectation' + item.mspa_id}
                                        value={item.value}
                                        onClickHandler={(newValue: number | null) => {
                                            console.log('Clicked', newValue)
                                            handleChangeExpectation(item.mspa_id, newValue)
                                        }}
                                    /> */}
                                    <SelectTextField
                                        fullWidth
                                        className={clsx(classes.input)}
                                        disabled={!item.checked}
                                        id={'expectation' + item.mspa_id}
                                        name={'expectation' + item.mspa_id}
                                        value={item.checked ? item.value : 0}
                                        onChange={(event: React.ChangeEvent<{ value: number | null }>) => {
                                            console.log('Clicked', event.target.value)
                                            handleChangeExpectation(item.mspa_id, event.target.value)
                                        }}
                                    >
                                        {[0, 3, 2, 1].map((value) => (
                                            <MenuItem key={value} value={value}>
                                                {value === 3 ? '↑（高）' : value === 2 ? '↗（中）' : value === 1 ? '→（低）' : '-'}
                                            </MenuItem>
                                        ))}
                                    </SelectTextField>
                                </LightStripedTableCell>
                            </LightStripedTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    )
}
