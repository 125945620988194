// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react'
import {
    UserActivityProvider,
    // useLocalVideo,
    useRemoteVideoTileState,
    RemoteVideo,
    // LocalVideo,
} from 'amazon-chime-sdk-component-library-react'

import { StyledLayout, StyledContent } from './Styled'
// import NavigationControl from '../../containers/Navigation/NavigationControl';
import { useNavigation } from '../../providers/NavigationProvider'
import MeetingControls from '../../containers/MeetingControls'
import useMeetingEndRedirect from '../../hooks/useMeetingEndRedirect'
import MeetingMetrics from '../../containers/MeetingMetrics'
import MeetingDetails from 'chime/containers/MeetingDetails'

const MeetingView = () => {
    const { tiles } = useRemoteVideoTileState()
    useMeetingEndRedirect()
    const { showNavbar, showRoster } = useNavigation()

    let remoteVideos = tiles.map((tileId) => <RemoteVideo tileId={tileId} />)
    if ( remoteVideos.length === 0 ) {
        remoteVideos = [<MeetingDetails />];
    }
    return (
        <UserActivityProvider>
            {/* <StyledLayout showNav={showNavbar} showRoster={showRoster}> */}
            <StyledLayout showNav={showNavbar} showRoster={showRoster}>
                <StyledContent>
                    <MeetingMetrics />
                    {/* この方法だと、自由にレイアウトできないため、個別に処理する */}
                    {/* <VideoTileGrid className="videos" noRemoteVideoView={<MeetingDetails />} /> */}
                    {/* リモートビデオ */}
                    {/* <div style={{ height: 300 }}> */}
                        <>{remoteVideos}</>
                    {/* </div> */}
                    {/* ローカルビデオ */}
                    {/* { remoteVideos.length === 0 ? <LocalVideo /> : null} */}
                    <MeetingControls />
                </StyledContent>
                {/* <NavigationControl /> */}
            </StyledLayout>
        </UserActivityProvider>
    )
}

export default MeetingView
