//
// アバター付きテキストラベル.
//
// import * as Const from '../Const'
// import { trace } from '../common/index'
import clsx from 'clsx'
import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import SmallAvatar from './SmallAvatar'

import { Box, Typography } from '@material-ui/core'
import GroupIcon from '@material-ui/icons/Group'
// Material-UIアイコン取得

// スタイルを適用する
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        offline: {
            backgroundColor: '#6E6E6E',
            width: 15,
            height: 15,
        },
        active: {
            backgroundColor: '#05A9C2',
            width: 15,
            height: 15,
        },
        avatar_name: {
            marginLeft: '10px',
        },
        offline_avatar_name: {
            marginLeft: '3px',
            color: '#6E6E6E',
            fontSize: '12px',
        },
        active_avatar_name: {
            marginLeft: '3px',
            color: '#05A9C2',
            fontSize: '12px',
        },
        no_photo: {
            marginLeft: '10px',
            color: '#5a5a5a',
            fontSize: '20px',
            marginTop: 2,
        },
        avatar_on_right_container: {
            borderRadius: '50px',
            background: '#fff',
            marginTop: 3,
        },
        avatar_on_right: {
            fontSize: '15px',
            borderRadius: '50px',
            display: 'inherit',
            width: '100%',
            padding: '0px 10px',
            border: '1px solid #fff',
        },
        font_bold: {
            fontWeight: 'bold',
        },
    }),
)

type AvatarLabelProps = {
    title: string
    type: 'offline' | 'active' | 'letter' | 'avatar' | 'photo' | 'no_photo' | 'avatar_on_right' | 'avatar_on_right_group'
    letter?: string
    url?: string
    fontBold?: boolean
    higaranaName?: string
}

export default function AvatarLabel(props: AvatarLabelProps) {
    // console.log( "AvatarLabel", props );
    const classes = useStyles()
    switch (props.type) {
        case 'offline':
            return (
                <Box display="flex" justifyContent="left" alignItems="center">
                    <SmallAvatar className={classes.offline}> </SmallAvatar>
                    <Typography variant="h5" className={clsx(classes.offline_avatar_name)}>
                        {props.title}
                    </Typography>
                </Box>
            )

        case 'active':
            return (
                <Box display="flex" justifyContent="left" alignItems="center">
                    <SmallAvatar className={classes.active}> </SmallAvatar>
                    <Typography variant="h5" className={clsx(classes.active_avatar_name)}>
                        {props.title}
                    </Typography>
                </Box>
            )
        case 'letter':
            return (
                <Box display="flex" justifyContent="left" alignItems="center">
                    <SmallAvatar>{props.letter ? props.letter : '?'}</SmallAvatar>
                    <Typography variant="h5" className={clsx(classes.avatar_name)}>
                        {props.title}
                    </Typography>
                </Box>
            )
        case 'avatar':
            return (
                <Box display="flex" justifyContent="left" alignItems="center">
                    <SmallAvatar src={props.url} />
                    <Typography variant="h5" className={clsx(classes.avatar_name, props.fontBold && classes.font_bold)}>
                        {props.title}
                    </Typography>
                </Box>
            )
        case 'photo':
            return (
                <Box display="flex" justifyContent="left" alignItems="center">
                    <SmallAvatar src={props.url} />
                    <Typography variant="h5" className={clsx(classes.avatar_name)}>
                        {props.title}
                    </Typography>
                </Box>
            )
        case 'no_photo':
            return (
                <Box display="flex" justifyContent="left" alignItems="center">
                    <Typography variant="h5" className={clsx(classes.no_photo, props.fontBold && classes.font_bold)}>
                        {props.title}
                    </Typography>
                    {props.higaranaName ? <Typography variant="h5">{props.higaranaName}</Typography> : null}
                </Box>
            )
        case 'avatar_on_right':
            return (
                <Box display="flex" justifyContent="left" alignItems="center" className={classes.avatar_on_right_container}>
                    <Typography variant="h5" className={clsx(classes.avatar_on_right)}>
                        {props.title}
                    </Typography>
                    <SmallAvatar src={props.url} style={{ height: 30, width: 30 }}></SmallAvatar>
                </Box>
            )
        case 'avatar_on_right_group':
            return (
                <Box display="flex" justifyContent="left" alignItems="center" className={classes.avatar_on_right_container}>
                    <Typography variant="h5" className={clsx(classes.avatar_on_right)}>
                        {props.title}
                    </Typography>
                    <SmallAvatar src={props.url} style={{ height: 30, width: 30 }}>
                        <GroupIcon />
                    </SmallAvatar>
                </Box>
            )
    }
}
