// import * as Const from '../Const'
// import { trace } from '../common/index'
// import clsx from 'clsx'

import React from 'react'
import { useHistory, Switch, Route, Redirect } from 'react-router-dom'

// redux
import { useAppDispatch } from '../store'
import { logout, useAuth } from '../modules/AuthModule'
import { useFacility, useHomePageUrl } from '../modules/AppModule'

// Material-UI

// functional
// import 'react-calendar/dist/Calendar.css';
import './calendar.css'

import { Typography } from '@material-ui/core'

import StaffTop from '../components/StaffTop'
import StaffUserList from '../components/StaffUserList'
import StaffUserMSPA from '../components/StaffUserMSPA'
import StaffUserSummary from '../components/StaffUserSummary'
import StaffInstructionList from '../components/StaffInstructionList'
import StaffInstruction from '../components/StaffInstruction'

import CourseSetting from './CourseSetting'
import TrainingContent from './TrainingContent'
import UserSetting from './UserSetting'
import StaffSetting from './StaffSetting'
import AttendanceContent from './AttendanceContent'
import FacilityStaffEdit from '../components/FacilityStaffEdit'
import { isUndefined } from 'util'
// import InformationList from './InformationList'
import InformationSetting from './InformationSetting'
import StaffExport from './StaffExport'

export default function StaffContent() {
    const homePageUrl = useHomePageUrl()
    const history = useHistory()

    let facility = useFacility()
    const facility_id = isUndefined(facility) || isUndefined(facility.facility_id) ? 'FID0001' : facility.facility_id

    const handleClose = () => {
        history.push(homePageUrl)
    }

    return (
        <React.Fragment>
            <Switch>
                <Route exact path={homePageUrl} render={() => <StaffTop />} />
                {/* トレーニング管理 */}
                {/* ユーザリスト */}
                <Route exact path={`${homePageUrl}/facility-users`} render={() => <StaffUserList facility_id={facility_id} />} />
                {/* MSPA評価指標 */}
                <Route path={`${homePageUrl}/facility-users/:userId/evaluation`} render={() => <StaffUserMSPA />} />
                {/* ユーザサマリ */}
                <Route exact path={`${homePageUrl}/facility-users/:userId/summary`} render={() => <StaffUserSummary />} />
                {/* 指導予約(トレーニング予約) */}
                {/* <Route path={`${homePageUrl}/facility-users/:userId/lessons/create`} render={() => <StaffTrainingReservation />} />
                <Route path={`${homePageUrl}/facility-users/:userId/lessons/:lessonId`} render={() => <StaffTrainingReservation />} /> */}
                <Route path={`${homePageUrl}/facility-users/:userId/summary/instructions/:lessonId`} render={() => <StaffInstruction />} />
                {/* 指導リスト */}
                <Route exact path={`${homePageUrl}/instructions`} render={() => <StaffInstructionList />} />
                {/* <Route path={`${homePageUrl}/instructions/create`} render={() => <StaffInstructionReservation />} />
                <Route path={`${homePageUrl}/instructions/lessons/:lessonId`} render={() => <StaffInstructionReservation />} /> */}
                {/* トレーニング画面(オンライン指導) */}
                <Route path={`${homePageUrl}/instructions/:lessonId`} render={() => <StaffInstruction />} />
                {/* トレーニング設定 (管理者のみ) */}
                <Route path={`${homePageUrl}/trainings`} render={() => <TrainingContent />} />
                {/* カリキュラム設定 (管理者のみ) */}
                <Route path={`${homePageUrl}/courses`} render={() => <CourseSetting />} />
                {/* ユーザ管理 */}
                <Route path={`${homePageUrl}/users`} render={() => <UserSetting />} />
                {/* 職員管理 */}
                <Route path={`${homePageUrl}/staffs`} render={() => <StaffSetting />} />
                {/* お知らせ管理 */}
                <Route path={`${homePageUrl}/informations`} render={() => <InformationSetting />} />
                {/* 勤怠管理 */}
                {/* 勤怠登録 */}
                <Route path={`${homePageUrl}/attendances/edit`} render={() => <AttendanceContent editable />} />
                {/* 職員勤怠確認 */}
                <Route path={`${homePageUrl}/attendances`} render={() => <AttendanceContent />} />
                {/* 職員名 */}
                {/* プロファイル編集 */}
                <Route path={`${homePageUrl}/profile`} render={() => <FacilityStaffEdit onCloseHandler={handleClose} />} />
                {/* ログアウト */}
                <Route path={`${homePageUrl}/logout`} render={() => <StaffLogOut />} />
                <Route path={`${homePageUrl}/export`} render={() => <StaffExport />} />
                <Route path="*" render={() => <Redirect to={homePageUrl} />} />
            </Switch>
        </React.Fragment>
    )
}

function StaffLogOut() {
    const dispatch = useAppDispatch()

    const homePageUrl = useHomePageUrl()
    const history = useHistory()

    const auth = useAuth()
    const facility = useFacility()
    const path = isUndefined(facility) || isUndefined(facility.facility_id) ? '/staff' : `/${facility.facility_id}/staff`

    React.useEffect(() => {
        if (!auth.authorized) {
            return
        }
        window.history.pushState(null, '', `${homePageUrl}/logout`)
        dispatch(logout())
        // dispatch(resetState())
        history.push(path)
    })
    return <Typography variant="h1">ログアウトしています</Typography>
}
