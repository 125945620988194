import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Grid, Paper, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            background: 'transparent',
        },
        container: {
            minHeight: 40,
            color: theme.palette.common.black,
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
        },
        label: {
            fontSize: 22,
            color: '#6a6a6a',
        },
        back: {
            textAlign: 'right',
        },
        next: {
            textAlign: 'left',
        },
    }),
)

type TitleBarProps = {
    label?: string
    left?: JSX.Element
    center?: JSX.Element
    right?: JSX.Element

    onClickHandler?: (event: React.MouseEvent<unknown, MouseEvent>) => void
}

export default function TitleBarHome(props: TitleBarProps) {
    const classes = useStyles()

    return (
        <Paper square elevation={0} className={classes.root}>
            <Grid container className={classes.container}>
                {/* Left space */}
                <Grid item xs={1} className={classes.back}>
                    {props.left !== undefined ? props.left : null}
                </Grid>

                {/* Center : Title Label */}
                <Grid item xs={2}>
                    {props.center !== undefined ? (
                        props.center
                    ) : (
                        <Typography variant="h3" className={classes.label}>
                            {props.label}
                        </Typography>
                    )}
                </Grid>

                {/* Right: Additional Component */}
                <Grid item xs={1} className={classes.next}>
                    {props.right !== undefined ? props.right : null}
                </Grid>
            </Grid>
        </Paper>
    )
}
