import { usePortal, useProfile } from 'modules/AppModule'
import { Role, Staff } from 'Types'

function useStaffRole() {
    const portal = usePortal()
    const profile = useProfile() as Staff

    const isStaff = portal === 'staff'

    return {
        isStaff: isStaff,
        isGeneralStaff: Boolean(isStaff && profile?.role_id === Role.staff),
        isManagerStaff: Boolean(isStaff && profile?.role_id === Role.manager),
    }
}

export default useStaffRole
