// import * as Const from '../Const'
// import { trace } from '../common/index'
import React from 'react'
import { isUndefined } from 'util'

// Material-UI
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Paper, Box, MenuItem, Typography } from '@material-ui/core'

// React router
import { useHistory } from 'react-router-dom'

// User Component
import { SettingButton } from './Buttons'
import TitleBar from './TitleBar'
import { SelectTextField } from './Inputs'
import TrainingStatusBar from './TrainingStatusBar'
import LabelWithCircle from './LabelWithCircle'

import { Course, Role, Staff, Training, User } from 'Types'

// Redux
import { useAppDispatch } from 'store'
import { fetchCoursesByFacilityIdWithUserId } from 'modules/CourseModule'
import { useHomePageUrl, usePortal, useProfile } from 'modules/AppModule'
//----------------------------------------------------------------------------------------
// スタイルを適用する
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        panel: {
            padding: theme.spacing(0),
        },

        container: {
            marginTop: 12,
            marginBottom: theme.spacing(2),
            //            margin: theme.spacing(0),
            // padding: theme.spacing(1),
        },

        // Course Panel
        course: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            textAlign: 'left',
            padding: theme.spacing(0),
        },
        select_course: {
            height: '100%',
        },
        box_levelbar: {
            display: 'flex',
            alignItems: 'center',
            marginTop: theme.spacing(2),
            padding: theme.spacing(0),
        },
        levelbar: {
            // alignItems: 'center',
            margin: theme.spacing(0),
            padding: theme.spacing(0),
            // backgroundColor: "#ff0000",
        },

        button: {
            marginLeft: 'auto', // 右寄せ.
            height: 30,
            width: 300,
            border: 'none',
            borderRadius: 'unset',
            boxShadow: '1px 1px 2px #0000004D',
        },
        list: {},
        select: {
            width: 410,
            '& input, .MuiSelect-select': {
                fontSize: 12,
            },
            '& .MuiInputBase-root': {
                padding: 0,
            },
            '& .MuiInputBase-input': {
                border: '1px solid #B6B6B6',
                boxShadow: '1px 1px 1px #0000004D',
                padding: '7px 12px',
            },
        },
        menuList: {
            width: 410,
        },
        noReservation: {
            backgroundColor: '#FFFFFF',
            color: 'black',
        },
        request: {
            backgroundColor: '#CDEEF3',
            color: '#5A5A5A',
        },
        reserved: {
            backgroundColor: '#CDEEF3',
            color: '#5A5A5A',
        },
        finish: {
            backgroundColor: '#E2E2E2',
            color: '#B6B6B6',
        },
        progressing: {
            backgroundColor: '#05A9C2',
            color: '#FFFFFF',
        },
        label: {
            fontSize: 12,
            marginRight: 18,
        },
        courseLabel: {
            fontSize: 12,
            marginBottom: 4,
        },
    }),
)

//----------------------------------------------------------------------------------------
interface CoursePanelProps {
    user?: User
    facilityId?: string
    defaultCourseId: string
}
export default function CoursePanel(props: CoursePanelProps) {
    const { user, facilityId, defaultCourseId } = props

    const classes = useStyles()
    const dispatch = useAppDispatch()
    const portal = usePortal()
    const homePageUrl = useHomePageUrl()
    const history = useHistory()
    const profile = useProfile() as Staff

    const [courses, setCourses] = React.useState<Course[]>([])
    const [courseValue, setCourseValue] = React.useState('')
    const [trainings, setTrainings] = React.useState<Training[]>([])
    const [testTrainings, setTestTrainings] = React.useState<Training[]>([])

    const userId = isUndefined(user) || isUndefined(user.user_id) ? undefined : user.user_id

    // Fetch courses by userId and facilityId
    React.useEffect(() => {
        if (!isUndefined(userId) && !isUndefined(facilityId)) {
            dispatch(fetchCoursesByFacilityIdWithUserId({ facility_id: facilityId, user_id: userId })).then((res) => {
                // filter courses match user's level
                const courses = res.payload
                if (!Array.isArray(courses) || courses.length === 0) return
                const newCourses = [...courses].filter((course: Course) => {
                    return getUserMinLevel(user) <= course.max_level && getUserMaxLevel(user) >= course.min_level
                })
                setCourses(newCourses)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId, facilityId])

    // Set default course value
    React.useEffect(() => {
        setCourseValue(defaultCourseId)
    }, [defaultCourseId])

    // Set trainings, testTrainings value
    React.useEffect(() => {
        setTrainings(getTrainings(courses, courseValue))
        setTestTrainings(getTestTrainings(courses, courseValue))
    }, [courses, courseValue])

    // Get course options
    const courseOptions = courses.map((course) => {
        return {
            value: getCourseId(course),
            label: getCourseName(course),
        }
    })

    // Handle course change
    const handleCourseChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCourseValue(event.target.value)
    }

    // Handle course setting click (staff portal)
    const handleCourseSettingClick = () => {
        // console.log('カリキュラム設定')
        history.push(`${homePageUrl}/courses`)
    }

    return (
        <React.Fragment>
            <Box className={classes.panel}>
                <TitleBar
                    label="カリキュラム"
                    bgBlue
                    right={portal === 'staff' && profile?.role_id === Role.manager ? <SettingButton onClickHandler={handleCourseSettingClick} /> : undefined}
                    endRight
                />
                <Paper square className={classes.container}>
                    <Box className={classes.course}>
                        <div className={classes.select_course}>
                            <Typography variant="h5" className={classes.courseLabel}>
                                カリキュラム名
                            </Typography>
                            <SelectTextField
                                className={classes.select}
                                fullWidth
                                id="course_id"
                                name="course_id"
                                select
                                value={courseValue}
                                onChange={handleCourseChange}
                            >
                                {courseOptions.map((option) => (
                                    <MenuItem key={option.label} value={option.value} className={classes.menuList}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </SelectTextField>
                        </div>
                    </Box>
                    <Box className={classes.box_levelbar}>
                        <Box className={classes.levelbar}>
                            <Box display="flex" marginBottom="6px">
                                <Typography variant="h5" className={classes.label}>
                                    トレーニング
                                </Typography>
                                <LabelWithCircle title="受講済み" color="grey" />
                                <LabelWithCircle title="受講中" color="blue" />
                                <LabelWithCircle title="予約中" color="light_blue" />
                                <LabelWithCircle title="未受講" color="white" />
                            </Box>
                            <TrainingStatusBar trainings={trainings} testTrainings={testTrainings} />
                        </Box>
                    </Box>
                </Paper>
            </Box>
        </React.Fragment>
    )
}

function getUserMinLevel(user?: User): number {
    return isUndefined(user) || isUndefined(user.min_level) ? 1 : user.min_level
}
function getUserMaxLevel(user?: User): number {
    return isUndefined(user) || isUndefined(user.max_level) ? 10 : user.max_level
}
function getCourseId(course?: Course): string {
    return isUndefined(course) || isUndefined(course.course_id) ? '' : course.course_id
}
function getCourseName(course?: Course): string {
    return isUndefined(course) || isUndefined(course.course_name) ? '' : course.course_name
}
const getTrainings = (courses: Course[], courseId: string) => {
    return courses.find((course) => course.course_id === courseId)?.trainings || []
}
const getTestTrainings = (courses: Course[], courseId: string) => {
    return courses.find((course) => course.course_id === courseId)?.test_trainings || []
}
