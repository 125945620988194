import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

/**
 * リストスタイル
 */
export const useListStyles = makeStyles((theme: Theme) =>
    createStyles({
        list: {
            flexGrow: 1,
            paddingTop: 'calc(2px)',
            paddingBottom: theme.spacing(1),
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
        },
        listheader: {
            flexGrow: 1,
            marginTop: 'calc(0px)',
            marginBottom: 'calc(0px)',
        },
        listitem: {
            flexGrow: 1,
            marginTop: 'calc(8px)',
            marginBottom: 'calc(0px)',
        },
        listfooter: {
            flexGrow: 1,
            marginTop: 'calc(8px)',
            marginBottom: 'calc(0px)',
        },
        scrollable: {
            overflow: 'auto',
            // maxHeight: 1000,
        },
        wrapper: {
            marginTop: 8,
        },
        content: {
            width: 960,
            margin: '8px auto 0',
        },
    }),
)
