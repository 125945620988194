import React from 'react'
import { useAppDispatch } from '../store'

// Material-UI
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

// functional
import * as DateUtil from 'date-fns'
import jaLocale from 'date-fns/locale/ja'
import { Information } from 'Types'
// User's component
import HeadlineLabel from '../components/HeadlineLabel'
import { useFacility } from 'modules/AppModule'
import { fetchFacilityInformationsByFacilityId } from 'modules/FacilityModule'

// スタイルを適用する
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: '100%',
            width: '100%',
            border: 'none',
        },
        // お知らせ
        info: {
            height: '82%',
            margin: 0,
            padding: '10px 15px 15px 15px',
            // backgroundColor: '#ff8080',
            // margin: '10px',
            // padding: '15px',
            // border: '1px dotted #0000001f',
        },
        frame: {
            height: '100%',
            width: '100%',
            border: 'none',
            overflow: 'auto',
        },
        block: {
            marginTop: 0,
            marginBottom: 15,
            // backgroundColor: '#8080ff',
        },
        header: {
            display: 'flex',
            fontSize: '15px',
            // color: '#266ca3',    // ネイティブ: 38,108,163.
            // color: '#1b5694', // 汎用: 27 86 148
            color: '#216ba5', // sRGB: 33, 107, 165
            // backgroundColor: '#ff0000',
            padding: 0,
        },
        date: {},
        time: {
            marginLeft: 10,
        },
        title: {
            marginLeft: 10,
        },
        content: {
            // backgroundColor: '#ff0000',
            color: '#000000',
            marginTop: '10px',
            marginLeft: 0,
            whiteSpace: 'pre-line',
            fontSize: '13px',
            lineHeight: '18px',
            wordBreak: 'break-word',
        },
        '@keyframes blinker': {
            from: { opacity: 1 },
            to: { opacity: 0 },
        },
        blink: {
            animation: '$blinker 1.0s infinite alternate',
        },
    }),
)

export default function StaffInformation() {
    const dispatch = useAppDispatch()
    const classes = useStyles()

    const [informations, setInformations] = React.useState([])
    // Favorite Users.
    const facility = useFacility()
    const facility_id = facility?.facility_id === undefined ? '' : facility?.facility_id

    React.useEffect(() => {
        dispatch(fetchFacilityInformationsByFacilityId(facility_id))
            .then((res) => {
                // console.log('お知らせ', res.payload)
                setInformations(res.payload)
            })
            .catch((err) => {
                console.log('お知らせ:失敗', err)
            })
    }, [dispatch, facility_id])
    return (
        <React.Fragment>
            <HeadlineLabel title="お知らせ" />
            <div className={classes.info}>
                {/* <iframe className={classes.iframe} id="information" title="information" src="/information.html"/> */}
                <div className={classes.frame}>
                    {informations &&
                        informations
                            .filter((item: Information) => {
                                const date = new Date()
                                const release_date = parseDate(item.release_date)
                                const expire_date = parseDate(item.expire_date)
                                return isWithinRange(date, release_date, expire_date)
                            })
                            .map((item: Information, index) => {
                                const [date, time] = formatDate(item.release_date)
                                const attr = classes.header
                                // const attr = item.facility_id ? classes.header : clsx(classes.header,classes.blink);
                                return (
                                    <div key={index} className={classes.block}>
                                        <div className={attr}>
                                            <div className={classes.date}>{date}</div>
                                            <div className={classes.time}>{time}</div>
                                            <div key={index} className={classes.title}>
                                                {item.title}
                                            </div>
                                        </div>
                                        <div key={index} className={classes.content}>
                                            {formatContent(item.content)}
                                        </div>
                                    </div>
                                )
                            })}
                </div>{' '}
            </div>
        </React.Fragment>
    )
    function formatContent(content: string): string {
        // 改行コードの変換は、Style で行っているため不要、
        return content
    }
    function formatDate(dateStr: string) {
        const date = parseDate(dateStr)
        return [DateUtil.format(date, 'yyyy.M.d', { locale: jaLocale }), DateUtil.format(date, 'H:mm', { locale: jaLocale })]
    }
    function parseDate(dateStr: string) {
        return DateUtil.parse(dateStr, 'yyyy-MM-dd HH:mm:ss', new Date())
    }
    function isWithinRange(date: Date, start: Date, end: Date) {
        // console.log( "お知らせ日付", date, start, end )
        return DateUtil.isWithinInterval(date, { start: start, end: end })
    }
}
