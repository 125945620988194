//
// 管理者ポータル.
// トレーニング管理.
//
// import * as Const from '../Const'
// import { trace } from '../common/index'
import React from 'react'

// Staff's Component.
import { NewButton } from './Buttons'
import TitleBar from './TitleBar'

// トレーニング一覧
import FacilityStaffList from './FacilityStaffList'
import FacilityStaffEdit from './FacilityStaffEdit'
import { Box } from '@material-ui/core'
import SearchBar2 from './SearchBar2'
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom'
import { Staff } from 'Types'
import { useFacility } from 'modules/AppModule'
import { useStaffItems } from 'modules/StaffModule'
import { ImportStaffs } from 'containers/Staff/Staff/Create/components/ImportStaffs'

type StaffSettingProps = {}

export default function StaffSetting(props: StaffSettingProps) {
    // console.log('### StaffSetting')

    const history = useHistory()
    const match = useRouteMatch()
    const listPageUrl = match.url
    const facility = useFacility()
    const items: Staff[] = useStaffItems()
    const max_staffs = facility?.max_staffs === undefined ? 0 : facility?.max_staffs
    const num_staffs = items.length

    // タイトルバー：新規作成
    const handleClickNew = () => {
        history.push(`${listPageUrl}/create`)
    }

    const handleClickCell = (staff: Staff) => {
        history.push(`${listPageUrl}/${staff?.staff_id}`)
    }

    const handleCloseStaff = () => {
        history.push(listPageUrl)
    }

    // CSSを適用する。
    // const classes = useStyles();
    return (
        <div style={{ width: 960, margin: '35px auto 0' }}>
            <Switch>
                {/* 一覧 */}
                <Route
                    exact
                    path={listPageUrl}
                    render={() => (
                        <>
                            <Box marginBottom="20px">
                                <SearchBar2
                                    right={
                                        <NewButton disabled={num_staffs >= max_staffs} onClickHandler={handleClickNew}>
                                            新規登録
                                        </NewButton>
                                    }
                                />
                            </Box>
                            <TitleBar bgBlue label={`職員一覧　（現在:${num_staffs}人 上限${max_staffs}人）`} />
                            <FacilityStaffList onClickHandler={handleClickCell} />
                        </>
                    )}
                />
                {/* 登録 */}
                <Route
                    path={`${listPageUrl}/create`}
                    render={() => (
                        <>
                            <ImportStaffs />
                            <TitleBar bgBlue label="職員登録" />
                            <FacilityStaffEdit onCloseHandler={handleCloseStaff} />
                        </>
                    )}
                />
                {/* 編集 */}
                <Route
                    path={`${listPageUrl}/:staffId`}
                    render={() => (
                        <>
                            <TitleBar bgBlue label="職員詳細" />
                            <FacilityStaffEdit onCloseHandler={handleCloseStaff} />
                        </>
                    )}
                />
            </Switch>
        </div>
    )
}
