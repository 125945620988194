import React from 'react'
import clsx from 'clsx'

import { useProfile, useHomePageUrl } from '../modules/AppModule'

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { AppBar, Paper, Toolbar, Typography } from '@material-ui/core'

import PulldownButton from './PulldownButton'

// User
import { User } from '../Types'
import { isUndefined } from 'util'
import { useHistory } from 'react-router-dom'
import { getDialogContext } from 'modules/DialogProvider'

/** スタイル定義 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            padding: '0 10px',
            background: 'linear-gradient(0deg, #EAEAEA 10%, #FDFDFD 60%, #FFF 80%)',
        },
        title: {
            flexGrow: 1,
            fontSize: '20px',
            color: 'white',
            fontWeight: 'bold',
        },
        headerNav: {
            width: '960px',
            margin: '0 auto',
            background: 'transparent',
            '& .MuiToolbar-gutters': {
                padding: 0,
            },
        },
        header: {
            width: 225,
            height: 64,
            padding: (theme.spacing(18), theme.spacing(1), theme.spacing(18), theme.spacing(1)),
            textAlign: 'center',
            background: 'transparent url(/assets/images/logo.png) no-repeat center center',
            '&:hover': {
                cursor: 'pointer',
            },
        },
        pulldown: {
            width: '230px',
            background: 'transparent',
        },
        icon_button: {
            background: '#C5C5C5',
            marginRight: 5,
            color: '#fff',
            height: 40,
            width: 40,
        },
        grow: {
            flexGrow: 1,
        },

        headerLogo: {
            color: 'inherit',
            marginRight: 20,
        },
        headerTitleStyle: {
            flexGrow: 1,
            color: 'inherit',
        },
        menuButton: {
            color: 'inherit',
            padding: '8px',
        },
        drawerList: {
            width: 200,
            height: '100%',
        },
        buttongroup: {
            margin: '2, 0, 2',
            padding: '2, 0, 2',
        },
        userProfile: {
            marginLeft: 'auto',
        },
        userTrainingList: {
            borderRight: '1px solid #fff',
        },
    }),
)

export default function UserBar() {
    const classes = useStyles()

    const homePageUrl = useHomePageUrl()
    const history = useHistory()

    const user = useProfile() as User

    const { showDialog } = React.useContext(getDialogContext())
    return (
        <div className={classes.root}>
            {/* 上部のバー */}
            <AppBar position="static" aria-label="Global Navi" className={classes.headerNav}>
                <Toolbar>
                    {/* <TitleLabel /> */}
                    <Paper
                        className={classes.header}
                        onClick={() => {
                            history.push(homePageUrl)
                        }}
                    >
                        <Typography variant="h6" className={classes.title}>
                            &nbsp;
                        </Typography>
                    </Paper>
                    {/* プルダウンメニュー */}
                    <PulldownButton
                        title={'トレーニング検索'}
                        options={[{ label: '検索', url: `${homePageUrl}/trainings` }]}
                        onClickHandler={(url: string) => {
                            history.push(url)
                        }}
                        className={clsx(classes.pulldown, classes.userTrainingList)}
                    />
                    <PulldownButton
                        className={clsx(classes.pulldown, classes.userProfile)}
                        icon={isUndefined(user) || isUndefined(user.icon) ? '' : user.icon}
                        title={isUndefined(user) || isUndefined(user.user_name) ? '' : user.user_name}
                        options={[
                            { label: 'プロフィール', url: `${homePageUrl}/profile` },
                            { label: 'ログアウト', url: `${homePageUrl}/logout` },
                        ]}
                        onClickHandler={(url: string) => {
                            switch (url) {
                                case `${homePageUrl}/logout`:
                                    showDialog({
                                        title: 'ログアウトしますか',
                                        message: 'この操作は戻せません',
                                        items: [
                                            {
                                                label: 'はい',
                                                onClickHandler: () => {
                                                    history.push(`${homePageUrl}/logout`)
                                                },
                                            },
                                            { label: 'いいえ' },
                                        ],
                                    })
                                    return
                            }
                            history.push(url)
                        }}
                    />
                </Toolbar>
            </AppBar>
        </div>
    )
}
