// import * as Const from '../Const'
// import { trace } from '../common/index'
import { isUndefined } from 'util'
import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Typography, TableHead, TableRow, Box } from '@material-ui/core'
import LevelBar from './LevelBar'
import { SortConfig, Training, TrainingSortable } from '../Types'
import { StripedTableRow, StripedTableCell, TblSortLabel } from './Tables'
import * as DateUtil from 'date-fns'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        row: {
            '&:hover': {
                cursor: 'pointer',
            },
        },
        levelBar: {
            '&>div>div': {
                fontSize: 14,
                color: '#B6B6B6',
            },
        },
        cursor: {
            cursor: 'pointer',
        },
    }),
)

/**
 * Props
 *
 * @interface TrainingListCellProps
 */
interface TrainingListCellProps {
    data: Training
    onClickHandler?: (event: React.MouseEvent<unknown, MouseEvent>) => void
}

/**
 * 動画時間の整形.
 *
 * @param {number} value
 * @returns
 */
function formatDuration(value: number | undefined) {
    if (isUndefined(value)) return ''
    const hour = Math.floor(value / 60)
    const minute = value % 60
    return (hour > 0 ? hour.toString() + '時間' : '') + minute.toString() + '分'
}

/**
 * Format yyyy-MM-dd HH:mm:ss to yyyy/MM/dd HH:mm
 *
 * @param {string} dateTime
 */
function formatDateTime(dateTime: string | undefined) {
    if (isUndefined(dateTime)) return ''
    return DateUtil.format(DateUtil.parse(dateTime, 'yyyy-MM-dd HH:mm:ss', new Date()), 'yyyy/MM/dd HH:mm')
}

/**
 * トレーニングリストセル.
 * @export
 * @param {TrainingListCellProps} props
 * @returns セルのJSX 要素
 */
export function TrainingListCell(props: TrainingListCellProps) {
    const classes = useStyles()
    const handleClick = (event: React.MouseEvent<unknown, MouseEvent>) => {
        if (props.onClickHandler !== undefined) {
            props.onClickHandler(event)
        }
    }
    return (
        <StripedTableRow className={classes.row} onClick={(event) => handleClick(event)}>
            <StripedTableCell align="center">
                <Typography variant="h5">{props.data.training_name}</Typography>
            </StripedTableCell>
            <StripedTableCell align="center">
                <Box className={classes.levelBar}>
                    <LevelBar
                        min={props.data.min_level !== undefined ? props.data.min_level : 0}
                        max={props.data.max_level !== undefined ? props.data.max_level : 0}
                        readonly
                    />
                </Box>
            </StripedTableCell>
            <StripedTableCell align="center">
                <Typography variant="h5">{formatDuration(props.data.duration)}</Typography>
            </StripedTableCell>
            <StripedTableCell align="center">
                <Typography variant="h5">{formatDateTime(props.data.updated_at)}</Typography>
            </StripedTableCell>
        </StripedTableRow>
    )
}

interface TrainingListHeaderCellProps {
    sortConfig: SortConfig
    requestSort: (id: TrainingSortable) => void
}

/**
 * トレーニングリストヘッダセル
 *
 * @export
 * @returns
 */
export function TrainingListHeaderCell({ requestSort, sortConfig }: TrainingListHeaderCellProps) {
    const classes = useStyles()

    return (
        <TableHead>
            <TableRow>
                <StripedTableCell width="36.46%" align="center" className={classes.cursor} onClick={() => requestSort(TrainingSortable.training_name)}>
                    <TblSortLabel label="トレーニング名" id={TrainingSortable.training_name} sortConfig={sortConfig} />
                </StripedTableCell>
                <StripedTableCell width="27.08%" align="center" className={classes.cursor} onClick={() => requestSort(TrainingSortable.level)}>
                    <TblSortLabel label="レベル" id={TrainingSortable.level} sortConfig={sortConfig} />
                </StripedTableCell>
                <StripedTableCell width="15.62%" align="center">
                    動画時間
                </StripedTableCell>
                <StripedTableCell align="center" className={classes.cursor} onClick={() => requestSort(TrainingSortable.updated_at)}>
                    <TblSortLabel label="更新日時" id={TrainingSortable.updated_at} sortConfig={sortConfig} />
                </StripedTableCell>
            </TableRow>
        </TableHead>
    )
}
