import React from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import {
    InputBase,
    Box,
    MenuItem,
    Button,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    RadioGroup,
    FormControl,
    FormControlLabel,
} from '@material-ui/core'

import { MainButton, SearchButton } from '../components/Buttons'
import { usePortal } from 'modules/AppModule'
import * as Const from '../Const'
import { BlueRadio, SelectTextField } from './Inputs'
import useStaffRole from 'hooks/useStaffRole'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexGrow: 1,
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
        },
        search: {
            flexGrow: 1,
            maxWidth: 390,
            borderRadius: theme.shape.borderRadius,
            backgroundColor: theme.palette.common.white,
            marginRight: theme.spacing(2),
            border: '1px solid #6E6E6E',
        },
        inputRoot: {
            color: 'black',
            width: '100%',
        },
        inputInput: {
            fontSize: 14,
            padding: 10,
            width: '100%',
        },
        level: {
            color: '#6E6E6E',
            display: 'flex',
            alignItems: 'flex-end',
            marginRight: theme.spacing(2),
            fontSize: 14,
        },
        select: {
            width: 100,
            margin: '0 10px',
            '& .MuiInputBase-root': {
                padding: 0,
                border: '1px solid #6E6E6E',
                borderRadius: 4,
            },
            '& .MuiInputBase-input, .MuiSelect-select:focus': {
                borderRadius: 4,
                border: 0,
            },
        },
        btnTagList: {
            minWidth: 140,
            height: 40,
            color: theme.palette.common.white,
            backgroundColor: '#6E6E6E',
            border: 'unset',
            borderRadius: '4px',
            '&:hover': {
                backgroundColor: '#6E6E6E',
            },
            marginRight: theme.spacing(2),
        },
    }),
)

/**
 * トレーニング編集プロパティ
 *
 * @interface SearchBarProps
 */
interface SearchBarProps {
    onClickHandler?: any // クリックハンドラ.
    searchable?: boolean // 検索可能.
    tags: string[]
}

export default function SearchInput2(props: SearchBarProps) {
    const classes = useStyles()
    const portal = usePortal()
    const { isGeneralStaff } = useStaffRole()

    const [filters, setFilters] = React.useState({
        textSearch: '',
        minLevel: 1,
        maxLevel: Const.Setting.LEVEL_MAX,
    })
    const [isOpenDialog, setIsOpenDialog] = React.useState(false)

    const level_options: { value: number; label: string }[] = []
    for (var i = 1; i <= Const.Setting.LEVEL_MAX; i++) {
        level_options.push({ value: i, label: i.toString() })
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilters({
            ...filters,
            textSearch: event.target.value,
        })
    }

    const handleLevelChange = (level: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = +event.target.value
        if (level === 'minLevel') {
            if (newValue > filters.maxLevel) {
                setFilters({ ...filters, minLevel: filters.maxLevel, maxLevel: newValue })
            } else {
                setFilters({ ...filters, minLevel: newValue })
            }
        }
        if (level === 'maxLevel') {
            if (newValue < filters.minLevel) {
                setFilters({ ...filters, minLevel: newValue, maxLevel: filters.minLevel })
            } else {
                setFilters({ ...filters, maxLevel: newValue })
            }
        }
    }

    const handleClick = () => {
        if (props.onClickHandler) {
            props.onClickHandler(filters)
        }
    }

    const handleDialogClick = () => {
        setIsOpenDialog(!isOpenDialog)
    }

    const handleSetTag = (tag: string) => {
        setFilters({ ...filters, textSearch: tag })
    }

    return (
        <div className={classes.root}>
            <div className={classes.search}>
                <InputBase
                    placeholder="検索テキスト"
                    classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                    }}
                    inputProps={{ 'aria-label': 'search' }}
                    value={filters.textSearch}
                    onChange={handleChange}
                />
            </div>
            <Button variant="contained" className={classes.btnTagList} onClick={handleDialogClick}>
                <Typography variant="h4">タグ一覧</Typography>
            </Button>
            <TagListDialog isOpen={isOpenDialog} onClose={handleDialogClick} tags={props.tags} onSetTag={handleSetTag} />
            {(portal === 'user' || isGeneralStaff) && (
                <Box className={classes.level}>
                    レベル
                    <SelectTextField
                        className={classes.select}
                        name="minLevel"
                        defaultValue={filters.minLevel}
                        value={filters.minLevel}
                        onChange={handleLevelChange('minLevel')}
                    >
                        {level_options.map((option) => (
                            <MenuItem key={option.label} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </SelectTextField>
                    以上
                    <SelectTextField
                        className={classes.select}
                        name="maxLevel"
                        defaultValue={filters.maxLevel}
                        value={filters.maxLevel}
                        onChange={handleLevelChange('maxLevel')}
                    >
                        {level_options.map((option) => (
                            <MenuItem key={option.label} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </SelectTextField>
                    以下
                </Box>
            )}
            <SearchButton variant="outlined" onClick={handleClick}>
                検索
            </SearchButton>
        </div>
    )
}

const useDialogStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogPaper: {
            width: 500,
            maxWidth: 960,
            height: 500,
        },
        dialogContent: {
            padding: 20,
            '&:first-child': {
                paddingTop: 10,
            },
        },
        dialogTitle: {
            padding: '8px 24px',
            background: 'transparent linear-gradient(0deg, #5A5A5A 0%, #5F5F5F 7%, #707070 34%, #7A7A7A 64%, #7E7E7E 100%)',
            '& h2': {
                color: '#FFFFFF',
                fontSize: '14px',
                fontWeight: 'bold',
            },
        },
        dialogAction: {
            justifyContent: 'center',
            padding: 24,
        },
        formControlLabel: {
            margin: 0,
            borderBottom: '1px dotted #E9E9E9',
            '&:first-child': {
                borderTop: '1px dotted #E9E9E9',
            },
            '&:hover': {
                '&>.MuiRadio-root:not(.Mui-checked):not(.Mui-disabled)>.MuiIconButton-label>span:before': {
                    borderRadius: '50%',
                    display: 'block',
                    width: 14,
                    height: 14,
                    backgroundColor: '#E9E9E9',
                    content: '""',
                    position: 'absolute',
                    top: 2,
                    right: 2,
                },
                '&>.MuiRadio-root:not(.Mui-checked):not(.Mui-disabled)+.MuiFormControlLabel-label': {
                    color: '#B6B6B6',
                },
            },
        },
    }),
)

export interface TagListDialogProps {
    isOpen: boolean
    onClose: () => void
    tags: string[]
    onSetTag: (tag: string) => void
}

function TagListDialog({ isOpen, onClose, tags, onSetTag }: TagListDialogProps) {
    const classes = useDialogStyles()

    const [tag, setTag] = React.useState('')

    const handleTagChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTag((e.target as HTMLInputElement).value)
    }

    const handleDialogClose = () => {
        onSetTag(tag)
        setTag('')
        onClose()
    }

    return (
        <Dialog
            onClose={handleDialogClose}
            open={isOpen}
            scroll="paper"
            fullWidth
            classes={{
                paper: classes.dialogPaper,
            }}
        >
            <DialogTitle className={classes.dialogTitle} id="scroll-dialog-title">
                タグ一覧
            </DialogTitle>
            <DialogContent
                classes={{
                    root: classes.dialogContent,
                }}
            >
                <FormControl component="fieldset" fullWidth>
                    <RadioGroup aria-label="tag" name="tag" value={tag} onChange={handleTagChange}>
                        {tags.map((tag, index) => (
                            <FormControlLabel className={classes.formControlLabel} key={index} value={tag} control={<BlueRadio />} label={tag} />
                        ))}
                    </RadioGroup>
                </FormControl>
            </DialogContent>
            <DialogActions classes={{ root: classes.dialogAction }}>
                <MainButton onClick={handleDialogClose}>閉じる</MainButton>
            </DialogActions>
        </Dialog>
    )
}
